import * as React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

// const SvgIcon1 = styled(MuiSvgIcon, {
//   name: "OperationDashboardIcon",
//   shouldForwardProp: (prop) => prop !== "fill",
// })<SvgIconProps>(() => ({
//   fill: "none",
//   stroke: "currentColor",
//   strokeLinecap: "round",
//   strokeLinejoin: "round",
//   strokeWidth: "2.25px",
// }));

// SvgIcon.defaultProps = {
//   viewBox: "0 0 24 24",
//   focusable: "false",
//   "aria-hidden": "true",
// };

const OperationDashboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2246 2H3.90877C2.85895 2 2 2.85 2 3.88889V17.1111C2 18.15 2.85895 19 3.90877 19H9.42578C9.20312 18.3789 9.07031 17.7656 9.02344 17.1111H8.6807V8.61111H13.4526V9.64982C13.8625 9.42899 14.8165 9.15515 15.3614 9.07961V8.61111H18.2246V9.19531C18.9833 9.38735 19.4062 9.54297 20.1333 9.9375V3.88889C20.1333 2.85 19.2744 2 18.2246 2ZM18.2246 6.72222V3.88889H3.90877V6.72222H18.2246ZM6.77193 8.61111H3.90877V17.1111H6.77193V8.61111Z"
      />
      <path d="M20.6466 16.8089C20.6712 16.6293 20.6835 16.4437 20.6835 16.2462C20.6835 16.0547 20.6712 15.8631 20.6404 15.6836L21.8904 14.7378C22.0012 14.654 22.032 14.4924 21.9643 14.3727L20.782 12.3855C20.7081 12.2538 20.5542 12.2119 20.4187 12.2538L18.947 12.8284C18.6392 12.601 18.3128 12.4094 17.9495 12.2658L17.7278 10.7454C17.7032 10.6018 17.58 10.5 17.4323 10.5H15.0677C14.92 10.5 14.803 10.6018 14.7783 10.7454L14.5567 12.2658C14.1933 12.4094 13.8608 12.6069 13.5591 12.8284L12.0874 12.2538C11.952 12.2059 11.798 12.2538 11.7241 12.3855L10.548 14.3727C10.4741 14.4984 10.4988 14.654 10.6219 14.7378L11.8719 15.6836C11.8411 15.8631 11.8165 16.0607 11.8165 16.2462C11.8165 16.4318 11.8288 16.6293 11.8596 16.8089L10.6096 17.7546C10.4988 17.8384 10.468 18 10.5357 18.1197L11.718 20.107C11.7919 20.2386 11.9458 20.2805 12.0813 20.2386L13.553 19.664C13.8608 19.8915 14.1872 20.083 14.5505 20.2267L14.7722 21.747C14.803 21.8907 14.92 21.9924 15.0677 21.9924H17.4323C17.58 21.9924 17.7032 21.8907 17.7217 21.747L17.9433 20.2267C18.3066 20.083 18.6392 19.8915 18.9409 19.664L20.4126 20.2386C20.548 20.2865 20.702 20.2386 20.7759 20.107L21.9581 18.1197C22.032 17.988 22.0012 17.8384 21.8842 17.7546L20.6466 16.8089ZM16.25 18.4011C15.0308 18.4011 14.0333 17.4314 14.0333 16.2462C14.0333 15.0611 15.0308 14.0914 16.25 14.0914C17.4692 14.0914 18.4667 15.0611 18.4667 16.2462C18.4667 17.4314 17.4692 18.4011 16.25 18.4011Z" />
    </SvgIcon>
  );
};

export default OperationDashboardIcon;
