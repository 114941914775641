import { styled } from "@mui/material";
import EditConsole from "app/layout/EditConsole";
import { useState } from "react";

const EditorConsole = () => {
  const EditConsoleWrapper = styled("div", {
    shouldForwardProp: (props) => props !== "isEditConsoleCollapsed",
  })<{ isEditConsoleCollapsed: boolean }>(({ theme }) => ({
    backgroundColor: "#E6F8F7",
    width: isEditConsoleOpen ? "300px" : "75px",
    textAlign: "center",
    boxShadow:
      "0px 5px 5px -3px rgba(1, 55, 61, 0.2), 0px 8px 10px 1px rgba(1, 55, 61, 0.14), 0px 3px 14px 2px rgba(1, 55, 61, 0.12)",
    zIndex: "3",
  }));
  const [isEditConsoleOpen, setIsEditConsoleOpen] = useState(false);
  return (
    <EditConsoleWrapper isEditConsoleCollapsed={isEditConsoleOpen}>
      <EditConsole
        isEditConsoleCollapsed={isEditConsoleOpen}
        toggleEditConsoleCollapsed={() => setIsEditConsoleOpen((prev) => !prev)}
      />
    </EditConsoleWrapper>
  );
};

export default EditorConsole;
