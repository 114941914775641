import DateTime from "app/components/DateTime";
import { SortIcon } from "app/components/SortIcon";
import { useState } from "react";

import { Button, Grid, Modal, Table, Image } from "semantic-ui-react";
import { IDocument, IVersionHistory } from "../../../types/DocumentLibrary";
import { SortCriteria } from "./docLibTypes/DocumentLibraryTypes";
import { collator } from "./utils/DocumentLibraryHelper";

interface IVersionHistoryProps {
  document: IDocument;
  setSortCriteria: (value: SortCriteria) => void;
  setIsAscendingSort: (value: boolean) => void;
  sortCriteria: SortCriteria;
  isAscendingSort: boolean;
  setVHistoryOpen: (value: boolean) => void;
}

const VersionHistory = ({
  document,
  setSortCriteria,
  setIsAscendingSort,
  setVHistoryOpen,
  sortCriteria,
  isAscendingSort,
}: IVersionHistoryProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [enlargeImg, setEnlargeImg] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");

  const versionHistory: IVersionHistory[] = Object.values(
    document.versionHistory ?? []
  );

  const sortListFiles = (a: IVersionHistory, b: IVersionHistory) => {
    switch (isAscendingSort) {
      case true:
        return collator.compare(
          a[sortCriteria] as string,
          b[sortCriteria] as string
        );
      default:
        return collator.compare(
          b[sortCriteria] as string,
          a[sortCriteria] as string
        );
    }
  };

  const getFileType = (version: IVersionHistory) => {
    const fileExtension = version.fileName.split(".").pop();
    switch (fileExtension) {
      case "gif":
      case "jpg":
      case "jpeg":
      case "png":
        setFileType("IMAGE");
        break;
      default:
        setFileType("");
    }
  };

  return (
    <>
      <Modal
        closeIcon
        open={open}
        trigger={
          <button className="version-history-btn">
            {versionHistory.length + 1}
          </button>
        }
        onClose={() => {
          setOpen(false);
          setVHistoryOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
          setVHistoryOpen(true);
        }}
      >
        <Modal.Header>Version History for {document.fileTitle}</Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row centered>
              <Grid.Column>
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        Previous Titles
                        <SortIcon
                          id="fileTitle"
                          setSortCriteria={setSortCriteria}
                          setIsAscendingSort={setIsAscendingSort}
                          sortCriteria={sortCriteria}
                          isAscendingSort={isAscendingSort}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        Previous File Names
                        <SortIcon
                          id="fileName"
                          setSortCriteria={setSortCriteria}
                          setIsAscendingSort={setIsAscendingSort}
                          sortCriteria={sortCriteria}
                          isAscendingSort={isAscendingSort}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        Replaced
                        <SortIcon
                          id="updatedAt"
                          setSortCriteria={setSortCriteria}
                          setIsAscendingSort={setIsAscendingSort}
                          sortCriteria={sortCriteria}
                          isAscendingSort={isAscendingSort}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>Replaced By</Table.HeaderCell>
                      <Table.HeaderCell style={{ textAlign: "center" }}>
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {versionHistory
                      .sort(sortListFiles)
                      .map((version: IVersionHistory, index: number) => (
                        <Table.Row key={index}>
                          <Table.Cell>{version.fileTitle}</Table.Cell>
                          <Table.Cell>{version.fileName}</Table.Cell>
                          <Table.Cell>
                            <DateTime
                              value={version.updatedAt}
                              as={"datetime"}
                            />
                          </Table.Cell>
                          <Table.Cell>{version.updatedBy}</Table.Cell>
                          <Table.Cell textAlign="center">
                            <Button.Group onClick={() => getFileType(version)}>
                              {fileType === "IMAGE" ? (
                                <Modal
                                  closeIcon
                                  open={enlargeImg}
                                  trigger={<Button icon="eye" color="green" />}
                                  onClose={() => setEnlargeImg(false)}
                                  onOpen={() => setEnlargeImg(true)}
                                >
                                  <Modal.Content>
                                    <Image fluid src={version.file} />
                                  </Modal.Content>
                                </Modal>
                              ) : (
                                <Button
                                  icon="eye"
                                  color="green"
                                  onClick={() => window.open(version.file)}
                                />
                              )}
                              <Button
                                as="a"
                                href={version.file}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={version.fileName}
                                icon="download"
                                color="blue"
                              />
                            </Button.Group>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>

        <Modal.Actions>
          <Button
            onClick={() => {
              setOpen(false);
              setVHistoryOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default VersionHistory;
