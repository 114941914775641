import { Box, Button, IconButton, styled, Tooltip, Typography } from "@mui/material";
import CrossCoverLogo from "app/assets/reverse_crosscover_logo_landscape.svg";
import { NavLinksContainer } from "./TopBar";
import { NavLogo } from "./TopBar";

const BannerBar = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 3, 2, 3),
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  color: "white",
  justifyContent: "space-between",
}));

const BannerHeader = () => {
  return (
    <BannerBar>
      <NavLinksContainer>
        <NavLogo to="/launchpads">
          <IconButton color="inherit" aria-label="menu" sx={{ m: 0, p: 0 }}>
            <img src={CrossCoverLogo} alt="CrossCover Logo" height="32px" />
          </IconButton>
        </NavLogo>
      </NavLinksContainer>
      <Box sx={{ display: "flex", alignItems: "center" }}>
       
        <Button
          href="https://primumdigital.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noreferrer"
          sx={{
            mr: 2,
            borderColor: "primary.contrastText",
            color: "primary.contrastText",
            "&:hover": {
              borderColor: "primary.light",
              color: "primary.light",
            },
          }}
          variant="outlined"
        >
          Help Desk
        </Button>
        <Tooltip title={`Build number: ${process.env.REACT_APP_BUILD_NUMBER}`}><Typography>{process.env.REACT_APP_VERSION}</Typography></Tooltip>
      </Box>
    </BannerBar>
  );
};
export default BannerHeader;
