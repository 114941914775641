import { PatientContext } from "features/PatientContext/PatientContext";
import { useContext } from "react";

const usePatient = () => {
  const { state, dispatch, isPatientLoaded, patientSearch, patientCreate } =
    useContext(PatientContext);

  const patientUnlink = () => dispatch({ type: "PATIENT_UNLINK", payload: {} });

  return {
    isPatientLoaded: isPatientLoaded,
    patient: state.patient,
    search: patientSearch,
    unlink: patientUnlink,
    create: patientCreate,
  };
};

export default usePatient;
