import { toastr } from "react-redux-toastr";

import { Issue, IssueRecipient } from "../../../types/Issues";
import useDocuments from "./useDocuments";
import useTenants from "./useTenants";
import { IDynamicNode, INode } from "../../../types/Nodes";
import { useCrossCoverSelector } from "app/store/hooks";

const usePathwayIssues = () => {
  const { activeTenant: selectedTenant } = useTenants();

  const { documents, addDocument, loading } = useDocuments<Issue>(
    `${selectedTenant.id}/Issues/Issues`
  );
  const tenantSettings = useCrossCoverSelector(
    (state) => state.tenants.tenantSettings
  );

  if (tenantSettings === undefined) {
    throw Error("usePathwayIssues: Invalid tenant settings");
  }

  const createRecipients = (email: string, reportName: string) => {
    let recipients: IssueRecipient[] = [];
    const chiefEditorEmail = tenantSettings.chiefEditorEmailAddress;
    if (!chiefEditorEmail) {
      recipients.push({
        email,
        commenterRoles: ["issueReporter"],
        name: reportName,
      });
    }
    if (chiefEditorEmail && chiefEditorEmail === email) {
      recipients.push({
        name: reportName,
        email,
        commenterRoles: ["chiefEditor", "issueReporter"],
      });
    }
    if (chiefEditorEmail && chiefEditorEmail !== email) {
      recipients.push({
        name: "",
        email: chiefEditorEmail,
        commenterRoles: ["chiefEditor"],
      });
      recipients.push({
        email,
        commenterRoles: ["issueReporter"],
        name: reportName,
      });
    }
    return recipients;
  };

  const createIssue = async (
    reportName: string,
    reporterEmail: string,
    issueSummary: string,
    issueDetails: string,
    pathwayId: string,
    nodeSnapshot: INode | IDynamicNode
  ) => {
    if (pathwayId === undefined) {
      throw Error("usePathwayIssues: Invalid pathwayId");
    }
    const recipients = createRecipients(reporterEmail, reportName);
    const issue: Issue = {
      name: reportName,
      email: reporterEmail,
      issue: issueDetails,
      summary: issueSummary,
      pathway: pathwayId,
      recipients: recipients,
      nodeSnapshot: nodeSnapshot,
      resolved: false,
      createdAt: 0,
      createdBy: "",
      updatedAt: 0,
      updatedBy: "",
    };
    await addDocument(issue);
    toastr.success(
      "Success.",
      `Your Issue with ${pathwayId} Pathway has been reported.`
    );
  };

  return {
    issues: documents,
    createIssue,
    loading: loading,
  };
};

export default usePathwayIssues;
