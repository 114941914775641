const useErrorMessages = (
  fileName?: string,
  fileSize?: number,
  fileTitle?: string
) => {
  let errorMessages = {
    matchingName: `The Document Library already contains a file with the name ${fileName}.
           Please rename your file or press Cancel to choose another file.`,

    matchingTitle: `The Document Library already contains a ${
      fileTitle ? "file" : "folder"
    } with this title. Please choose a different title.`,

    matchTitleAtDestination: `This destination already contains that title, 
           please choose a different one.`,

    noFolderTitle: `Please give your folder a title.`,

    noFileTitle: `Please give your file a title.`,

    fileSizeOverLimit: `You can only upload files which are under 50MB in size. Your file is ${fileSize?.toFixed(
      2
    )}MB.`,

    incorrectCharactersTitle: `Please do not include slashes in your folder title.`,

    incorrectFileExtGeneral: `You can only upload videos, images and documents.
     Please make sure your file has one of the following file extensions:
     .mp4, .docx, .doc, .pdf, .xlsx, .xls, .pptx, .txt, .gif, .jpg, .jpeg or .png.`,

    incorrectFileExtImages: `You can only upload images here.
    Please make sure your file has one of the following file extensions:
      .gif, .jpg, .jpeg or .png.`,

    incorrectFileExtVideos: `You can only upload videos here.
    Please make sure your file has the following file extension:
      .mp4.`,

    fileNotUsed:
      "This file could not be found. Please check the file title and try again.",

    incorrectReplacementImg: `This image is being used in a Node Preview and can only be replaced with another image. 
     Please make sure your file has one of the following file extensions:
      .gif, .jpg, .jpeg or .png.`,

    incorrectReplacementVideo: `This vidoe is being used in a Node Preview and can only be replaced with another video. 
     Please make sure your file has the the following file extension:
      .mp4.`,
  };

  return { errorMessages };
};

export default useErrorMessages;
