import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { Dispatch, useEffect, useState } from "react";
import {
  // IButtonBlock,
  IButtonData,
  IChoicesBoxData,
  NodeBlock,
  IDynamicNode,
} from "../../../../../types/Nodes";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  getBlocksThatManipulateRules,
  getNumericValueFromInput,
  getValuesRule,
} from "features/NodeEditorV2/NodeEditorUtils";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import { isScorerBlock } from "features/pathwaybuilder/utils/pathwayHelperV2";

const NodeEditorPanelRuleComponent = ({
  index,
  ruleElement,
  conditionalOperator,
  block,
  dispatch,
  state,
}: {
  index: number;
  ruleElement: any;
  conditionalOperator: string;
  block: NodeBlock | undefined;
  dispatch: Dispatch<DynamicNodeEditorActions>;
  state: IDynamicNode;
}) => {
  const [ruleValues, setRuleValues] = useState<any>({});

  useEffect(() => {
    setRuleValues(getValuesRule(ruleElement, Object.values(state.blocks)));
  }, [ruleElement, state.blocks]);

  const conditionScoreOptions = [
    { text: "is in range", value: "is in range" },
    { text: "is equal to", value: "==" },
    { text: "does not equal", value: "!=" },
    { text: "is greater than or equal to", value: ">=" },
    { text: "is less than or equal to", value: "<=" },
    { text: "is greater than", value: ">" },
    { text: "is less than", value: "<" },
  ];

  const onRuleFieldChange = (type: string, index: number, value?: any) => {
    if (block && block.blockRules.rule) {
      let conditionalOperator = Object.keys(JSON.parse(block.blockRules.rule));

      let newRulesArray: any[] =
        (Object.values(JSON.parse(block.blockRules.rule))[0] as any[]) || [];

      let ruleToChange: any[] = Object.entries(newRulesArray[index])[0];

      const hasMaxValue = ruleToChange[1].length < 3 ? false : true;

      if (type === "clearRuleFields") {
        ruleToChange[0] = "==";
        ruleToChange[1] = [{ var: "" }, ""];
      }

      if (value !== undefined) {
        switch (type) {
          case "selectRuleLevel":
            ruleToChange[1][hasMaxValue ? 1 : 0].var =
              value === "node" ? value : "block";
            break;
          case "selectedBlockName":
            const block = Object.values(state.blocks).find(
              (block) => block.blockId === value
            );

            if (block?.blockType === "choices") {
              ruleToChange[1][hasMaxValue ? 1 : 0].var = isScorerBlock(block)
                ? `${value}.score`
                : value;
              ruleToChange[1][hasMaxValue ? 2 : 1] = isScorerBlock(block)
                ? 0
                : false;
            }

            if (
              block?.blockType === "button" ||
              block?.blockType === "showHide"
            ) {
              const block = Object.values(state.blocks).find(
                (block) => block.blockId === value
              );
              ruleToChange[1][hasMaxValue ? 1 : 0].var = value.concat(
                `.${(block?.blockData as IButtonData).buttonId}`
              );
              ruleToChange[1][hasMaxValue ? 2 : 1] = false;
            }

            break;

          case "ruleType":
            if (value === "contains") {
              ruleToChange[1][hasMaxValue ? 2 : 1] = "";
              ruleToChange[1][
                hasMaxValue ? 1 : 0
              ].var = `${ruleValues.blockId}.contains`;
              ruleToChange[0] = "contains";
            }
            if (value === "score") {
              ruleToChange[1][hasMaxValue ? 2 : 1] = 0;
              ruleToChange[1][hasMaxValue ? 1 : 0].var =
                ruleValues.name === "node"
                  ? `${ruleValues.name}.score`
                  : `${ruleValues.blockId}.score`;
            }
            if (value !== "contains" && value !== "score") {
              ruleToChange[1][hasMaxValue ? 2 : 1] = true;
              const block = Object.values(state.blocks).find(
                (block) => block.blockId === ruleValues.blockId
              );

              let field = (block?.blockData as IChoicesBoxData).fields.find(
                (fieldElement) => fieldElement.fieldId === value
              );

              if (field) {
                value = `${value}.${field.values[0].valueId}`;
              }
              ruleToChange[1][
                hasMaxValue ? 1 : 0
              ].var = `${ruleValues.blockId}.${value}`;
            }
            break;
          case "valueName":
            ruleToChange[1][
              hasMaxValue ? 1 : 0
            ].var = `${ruleValues.blockId}.${ruleValues.fieldId}.${value}`;
            break;
          case "conditionalOperator":
            conditionalOperator = [value];
            break;

          case "condition":
            if (value === "is in range" && ruleToChange[1].length < 3) {
              ruleToChange[1].unshift(ruleToChange[1][1]);
              ruleToChange[1][2] = 0;
            }

            if (value !== "is in range" && ruleToChange[1].length === 3) {
              ruleToChange[1].shift();
            }
            ruleToChange[0] = value === "is in range" ? "<=" : value;
            break;

          case "value":
            ruleToChange[1][hasMaxValue ? 0 : 1] =
              ruleValues.type === "score"
                ? +value
                : ruleValues.type === "toggle"
                ? JSON.parse(value)
                : value;
            break;

          case "maxValue":
            ruleToChange[1][2] = +value;
            break;
        }
      }

      newRulesArray[index] = { [ruleToChange[0]]: [...ruleToChange[1]] };

      const newRuleObject = {
        [conditionalOperator[0]]: newRulesArray,
      };

      dispatch({
        type: "UPDATE_RULES",
        payload: {
          blockId: block.blockId,
          ruleOptions: {
            ...block.blockRules,
            rule: JSON.stringify(newRuleObject),
          },
        },
      });
    }
  };

  const deleteRule = (index: number) => {
    if (block && block.blockRules.rule) {
      let newRulesArray: any[] =
        (Object.values(JSON.parse(block.blockRules.rule))[0] as any[]) || [];
      let conditionalOperator = Object.keys(JSON.parse(block.blockRules.rule));

      newRulesArray.splice(index, 1);

      let hasRules = newRulesArray.length > 0 ? true : false;
      let newRuleObject = null;
      if (hasRules) {
        newRuleObject = {
          [conditionalOperator[0]]: newRulesArray,
        };
      }

      dispatch({
        type: "UPDATE_RULES",
        payload: {
          blockId: block.blockId,
          ruleOptions: {
            ...block.blockRules,
            rule: hasRules ? JSON.stringify(newRuleObject) : null,
            alwaysShow: hasRules ? false : true,
          },
        },
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {index > 0 && ruleValues && (
        <>
          <Divider sx={{ width: "100%", my: 2 }} variant="fullWidth" />
          <ButtonGroup variant="contained" fullWidth sx={{ mb: 1 }}>
            <Button
              variant={conditionalOperator === "and" ? "contained" : "outlined"}
              onClick={() =>
                onRuleFieldChange("conditionalOperator", index, "and")
              }
            >
              and
            </Button>
            <Button
              variant={conditionalOperator === "or" ? "contained" : "outlined"}
              onClick={() =>
                onRuleFieldChange("conditionalOperator", index, "or")
              }
            >
              or
            </Button>
          </ButtonGroup>
        </>
      )}
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel>Select rule level...</InputLabel>
        <Select
          size="small"
          value={ruleValues.name !== "node" ? "block" : "node"}
          label="Select rule level..."
          onChange={(e: SelectChangeEvent<any>) =>
            onRuleFieldChange("selectRuleLevel", index, e.target.value)
          }
        >
          <MenuItem value="node">Node Level Rule</MenuItem>
          <MenuItem value="block">Block Level Rule</MenuItem>
        </Select>
      </FormControl>
      {ruleValues.name !== ("" || "node") && (
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel>Select Block...</InputLabel>{" "}
          <Select
            size="small"
            value={ruleValues.blockId || ""}
            label="Select Block..."
            onChange={(e: SelectChangeEvent<any>) =>
              onRuleFieldChange("selectedBlockName", index, e.target.value)
            }
          >
            {getBlocksThatManipulateRules(
              block?.blockId,
              Object.values(state.blocks)
            ).map((block, index) => (
              <MenuItem key={index} value={block.blockId}>
                {block.blockName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {ruleValues.name !== "" &&
        Object.values(state.blocks).find(
          (block) => block.blockName === ruleValues.name
        )?.blockType === "choices" && (
          <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel>Select rule type...</InputLabel>
            <Select
              size="small"
              label="Select rule type..."
              value={
                (ruleValues.type !== "toggle"
                  ? ruleValues.type
                  : ruleValues.fieldId) || ""
              }
              onChange={(e: SelectChangeEvent<any>) =>
                onRuleFieldChange("ruleType", index, e.target.value)
              }
            >
              <MenuItem value={"score"}>Score</MenuItem>
              <MenuItem value={"contains"}>
                Any toggled fields contains
              </MenuItem>
              {(
                Object.values(state.blocks).find(
                  (block) => block.blockName === ruleValues.name
                )?.blockData as IChoicesBoxData
              )?.fields.map((field, index) => (
                <MenuItem key={index} value={field.fieldId}>
                  {field.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      {ruleValues.name === "node" && (
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel>Select rule type...</InputLabel>
          <Select
            size="small"
            label="Select rule type..."
            value={ruleValues.type}
            onChange={(e: SelectChangeEvent<any>) =>
              onRuleFieldChange("ruleType", index, e.target.value)
            }
          >
            <MenuItem value={"score"}>Score</MenuItem>
          </Select>
        </FormControl>
      )}
      {ruleValues.type === "score" && (
        <>
          <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel>Select an operator...</InputLabel>
            <Select
              size="small"
              value={
                conditionScoreOptions.find((condition) => {
                  if (
                    condition.value === "is in range" &&
                    ruleValues.operator === "<=" &&
                    ruleValues.maxValue !== ""
                  ) {
                    return condition;
                  }
                  return condition.value === ruleValues.operator;
                })?.value
              }
              label="Select an operator..."
              onChange={(e: SelectChangeEvent<any>) =>
                onRuleFieldChange("condition", index, e.target.value)
              }
            >
              {conditionScoreOptions.map((condition, index) => (
                <MenuItem value={condition.value} key={index}>
                  {condition.text}
                </MenuItem>
              ))}
            </Select>

            <TextField
              sx={{ my: 2 }}
              size="small"
              id="outlined"
              label="Enter min value..."
              variant="outlined"
              type="text"
              value={+ruleValues.value}
              error={
                ruleValues.maxValue !== "" &&
                +ruleValues.maxValue < +ruleValues.value
              }
              helperText={
                ruleValues.maxValue !== "" &&
                +ruleValues.maxValue < +ruleValues.value
                  ? "Min value must be greater than max value"
                  : ""
              }
              onChange={(e) => {
                onRuleFieldChange(
                  "value",
                  index,
                  getNumericValueFromInput(e.target.value)
                );
              }}
            />
            {ruleValues.operator === "<=" && ruleValues.maxValue !== "" && (
              <>
                <Typography variant="subtitle1" fontWeight={700}>
                  And
                </Typography>
                <TextField
                  sx={{ my: 2 }}
                  size="small"
                  id="outlined-basic"
                  label="Enter max value..."
                  variant="outlined"
                  type="text"
                  error={
                    ruleValues.maxValue !== "" &&
                    +ruleValues.maxValue < +ruleValues.value
                  }
                  value={ruleValues.maxValue !== "" && +ruleValues.maxValue}
                  helperText={
                    +ruleValues.maxValue < +ruleValues.value
                      ? "Max value must be greater than min value"
                      : ""
                  }
                  onChange={(e) => {
                    onRuleFieldChange(
                      "maxValue",
                      index,
                      getNumericValueFromInput(e.target.value)
                    );
                  }}
                />
              </>
            )}
          </FormControl>
        </>
      )}
      {ruleValues.type === "toggle" && (
        <>
          {Object.values(state.blocks).find(
            (block) => block.blockName === ruleValues.name
          )?.blockType === "choices" && (
            <>
              {ruleValues.hasMultipleValues && (
                <FormControl fullWidth sx={{ my: 1 }}>
                  <InputLabel>Select value...</InputLabel>
                  <Select
                    size="small"
                    value={ruleValues.valueId}
                    label="Select value..."
                    onChange={(e) =>
                      onRuleFieldChange("valueName", index, e.target.value)
                    }
                  >
                    {(
                      Object.values(state.blocks).find(
                        (block) => block.blockName === ruleValues.name
                      )?.blockData as IChoicesBoxData
                    ).fields
                      .find((field) => field.fieldId === ruleValues.fieldId)
                      ?.values.map((value) => (
                        <MenuItem key={value.valueId} value={value.valueId}>
                          {value.text}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {ruleValues.fieldId !== "" && (
            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel>Select type...</InputLabel>
              <Select
                size="small"
                value={ruleValues.value}
                label="Select type..."
                onChange={(e) =>
                  onRuleFieldChange("value", index, e.target.value)
                }
              >
                <MenuItem value="false">Is NOT selected</MenuItem>
                <MenuItem value="true">Is selected</MenuItem>
              </Select>
            </FormControl>
          )}
        </>
      )}
      {ruleValues.type === "contains" && (
        <TextField
          fullWidth
          sx={{ my: 1 }}
          size="small"
          id="outlined"
          label="Enter text..."
          variant="outlined"
          type="text"
          value={ruleValues.value}
          onChange={(e) => onRuleFieldChange("value", index, e.target.value)}
        />
      )}
      {ruleValues.name !== "" && (
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
            color: "#767676",
            fontWeight: "600",
          }}
          onClick={() => onRuleFieldChange("clearRuleFields", index)}
        >
          <RemoveCircleOutlineIcon /> Clear
        </Box>
      )}
      {ruleValues.name === "" && (
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
            color: "#C62828",
            fontWeight: "600",
          }}
          onClick={() => deleteRule(index)}
        >
          <DeleteIcon /> Remove Rule
        </Box>
      )}
    </Box>
  );
};

export default NodeEditorPanelRuleComponent;
