import { Box, FormControl, Switch, TextField, Typography } from "@mui/material";

import {
  Dispatch,
  // , useEffect
  useState,
} from "react";
import { IDynamicNode, NodeBlock } from "../../../../../types/Nodes";
import { ColorResult } from "@hello-pangea/color-picker";
import ColorPickerComponent from "../../../../app/components/ColorPickerComponent";
import ColorContrastCheckComponent from "../../../../app/components/ColorContrastCheckComponent";
import { getManagementPlanText } from "features/NodeEditorV2/NodeEditorUtils";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";
import {
  isRichTextAreaBlock,
  isScorerBlock,
  isYesNoBlock,
} from "features/pathwaybuilder/utils/pathwayHelperV2";

const NodeEditorPanelOptionsContainer = ({
  block,
  dispatch,
  pathwayEditorDispatch,
  pathwayState,
  state,
  createTemplateChecked,
}: {
  block: NodeBlock | undefined;
  dispatch: Dispatch<DynamicNodeEditorActions>;
  pathwayEditorDispatch: Dispatch<PathwayActions>;
  pathwayState: Partial<PathwayState>;
  state: IDynamicNode;
  createTemplateChecked: boolean;
}) => {
  const [isPickingColor, setIsPickingColor] = useState<boolean>(false);

  const onChangeBlockName = (name: string) => {
    if (block) {
      dispatch({
        type: "UPDATE_BLOCK_NAME",
        payload: {
          blockId: block.blockId,
          newBlockName: name,
        },
      });
    }
  };

  const onChangeBlockStyleOption = (type: string, value: ColorResult) => {
    if (block) {
      if (type === "backgroundColor") {
        dispatch({
          type: "UPDATE_BLOCK_OPTIONS",
          payload: {
            blockId: block.blockId,
            blockOptions: {
              ...block.blockOptions,
              backgroundColor: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})`,
            },
          },
        });
      }
      if (type === "borderColor") {
        dispatch({
          type: "UPDATE_BLOCK_OPTIONS",
          payload: {
            blockId: block.blockId,
            blockOptions: {
              ...block.blockOptions,
              borderColor: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})`,
              borderRadius: "5px",
            },
          },
        });
      }
      if (type === "fontColor") {
        dispatch({
          type: "UPDATE_BLOCK_OPTIONS",
          payload: {
            blockId: block.blockId,
            blockOptions: {
              ...block.blockOptions,
              fontColor: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})`,
            },
          },
        });
      }
    }
  };

  const onChangeBlockBehaviorOptions = (type: string, value: boolean) => {
    if (block) {
      if (type === "addToDecisionSummary") {
        dispatch({
          type: "UPDATE_BLOCK_OPTIONS",
          payload: {
            blockId: block.blockId,
            blockOptions: {
              ...block.blockOptions,
              addToDecisionSummary: value,
            },
          },
        });
      }
      if (type === "addToManagementPlan") {
        dispatch({
          type: "UPDATE_BLOCK_OPTIONS",
          payload: {
            blockId: block.blockId,
            blockOptions: {
              ...block.blockOptions,
              addToManagementPlan: value,
            },
          },
        });
      }
      if (type === "addToNodeScore") {
        dispatch({
          type: "UPDATE_BLOCK_OPTIONS",
          payload: {
            blockId: block.blockId,
            blockOptions: {
              ...block.blockOptions,
              addToNodeScore: value,
            },
          },
        });
      }

      const fieldsToUpdate = pathwayState.nodeContext?.fields.filter(
        (fieldNodeContext) => fieldNodeContext.blockId === block.blockId
      );

      if (fieldsToUpdate) {
        fieldsToUpdate.forEach((field) => {
          pathwayEditorDispatch({
            type: "NODE_BLOCK_LOGIC_ON",
            payload: {
              ...field,
              [type]: value,
            },
          });
        });
      }
    }
  };

  const handleReadonlyInTemplate = (toggleValue: boolean) => {
    if (block) {
      dispatch({
        type: "UPDATE_BLOCK_OPTIONS",
        payload: {
          blockId: block.blockId,
          blockOptions: {
            ...block.blockOptions,
            readonlyInTemplate: toggleValue,
          },
        },
      });
    }
  };

  const interactiveBlockStyle = ["richTextArea", "displayVariables"];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography variant="subtitle1" fontWeight={700}>
        Change Block Name:
      </Typography>{" "}
      <FormControl fullWidth sx={{ my: 1 }}>
        <TextField
          sx={{ my: 2, backgroundColor: "white", borderRadius: "5px" }}
          size="small"
          id="outlined-basic"
          label="Insert Block name..."
          variant="outlined"
          error={block?.blockName === ""}
          value={block?.blockName}
          onChange={(e) => onChangeBlockName(e.target.value)}
          inputProps={{
            maxlength: 80,
          }}
        />
      </FormControl>
      <Typography variant="subtitle1" fontWeight={700}>
        Block behaviour:
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography>Add to decision summary</Typography>
        <Switch
          color="primary"
          checked={block?.blockOptions.addToDecisionSummary}
          onChange={(e) =>
            onChangeBlockBehaviorOptions(
              "addToDecisionSummary",
              e.target.checked
            )
          }
        />
      </Box>
      {state.nodeProperties.isEndNodeFunctionalityEnabled &&
        block &&
        isRichTextAreaBlock(block) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography>Add to management plan</Typography>
            <Switch
              color="primary"
              checked={block?.blockOptions.addToManagementPlan}
              disabled={
                getManagementPlanText(state.blocks).length <= 1 &&
                block?.blockOptions.addToManagementPlan
                  ? true
                  : false
              }
              onChange={(e) =>
                onChangeBlockBehaviorOptions(
                  "addToManagementPlan",
                  e.target.checked
                )
              }
            />
          </Box>
        )}
      {block && (isScorerBlock(block) || isYesNoBlock(block)) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography>Add to Node score</Typography>
          <Switch
            color="primary"
            checked={block.blockOptions.addToNodeScore}
            onChange={(e) =>
              onChangeBlockBehaviorOptions("addToNodeScore", e.target.checked)
            }
          />
        </Box>
      )}
      <Typography variant="subtitle1" fontWeight={700}>
        Style:
      </Typography>
      {!isPickingColor && (
        <ColorContrastCheckComponent
          color1={block?.blockOptions.backgroundColor}
          color2={block?.blockOptions.fontColor}
        />
      )}
      {block && interactiveBlockStyle.includes(block.blockType) && (
        <ColorPickerComponent
          text={"Background Color"}
          onPickColor={(color) =>
            onChangeBlockStyleOption("backgroundColor", color)
          }
          selectedColor={block?.blockOptions.backgroundColor ?? "#FFFFF"}
          onOpenPicker={setIsPickingColor}
        />
      )}
      <ColorPickerComponent
        text={"Border Color"}
        onPickColor={(color) => {
          onChangeBlockStyleOption("borderColor", color);
        }}
        selectedColor={block?.blockOptions.borderColor ?? "#FFFFF"}
        onOpenPicker={setIsPickingColor}
      />
      {block && interactiveBlockStyle.includes(block.blockType) && (
        <ColorPickerComponent
          text={"Font Color"}
          onPickColor={(color) => onChangeBlockStyleOption("fontColor", color)}
          selectedColor={block?.blockOptions.fontColor ?? "#FFFFF"}
          onOpenPicker={setIsPickingColor}
        />
      )}
      {createTemplateChecked && (
        <>
          <Typography variant="subtitle1" fontWeight={700}>
            Template options:
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography>Make this block readonly in templates</Typography>
            <Switch
              color="primary"
              checked={block?.blockOptions.readonlyInTemplate}
              onChange={(e) => handleReadonlyInTemplate(e.target.checked)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default NodeEditorPanelOptionsContainer;
