// import { ILinkButtonData } from "./../../../types/Nodes";
import { v4 as uuid4 } from "uuid";
import {
  BlockTypes,
  IButtonData,
  IChoicesBoxData,
  IBlockOptions,
  IBlockRules,
  NodeBlock,
  IBlockPosition,
  INodeBlockBase,
  IDynamicNode,
  IChoicesLogicField,
  IRichTextAreaData,
  NodeBlockData,
  INodeBlock,
  IShowHideData,
} from "../../../types/Nodes";
import _ from "lodash";
import jsonLogic from "json-logic-js";
import {
  INodeContext,
  INodeContextItem,
} from "../../features/hooks/navigatorReducer";
import { isConnectedNode } from "../../features/pathwaybuilder/utils/pathwayHelperV2";
import { INodeViewSettings } from "../../features/NodeViewer/NodeView";

export interface IBlockMove {
  blockId: string;
  sourcePosition: IBlockPosition | undefined;
  destinationPosition: IBlockPosition;
}

export const interactiveBlocksTypes = ["choices", "button", "showHide"];

//Add custom contains function, which reverses the parameters of the string in
jsonLogic.add_operation("contains", function (a, b) {
  if (!a || typeof a.indexOf === "undefined") return false;

  return a.indexOf(b) !== -1;
});

interface ICreateBlockProps<TBlockData> {
  blockType: BlockTypes;
  column?: number;
  row?: number;
  data: TBlockData;
  blockName?: string;
  blockRules?: IBlockRules;
  blockOptions?: IBlockOptions;
  addToManagementPlan?: boolean;
}
export const createNewBlock = <TBlockData extends NodeBlockData>({
  blockType,
  column,
  row,
  data,
  blockName,
  blockOptions,
  blockRules,
  addToManagementPlan,
}: ICreateBlockProps<TBlockData>): INodeBlock<TBlockData> => {
  const block: INodeBlock<TBlockData> = {
    blockId: uuid4(),
    blockType: blockType,
    position: { column: column ?? 0, row: row ?? 0 },
    blockRules: blockRules ?? {
      alwaysShow: true,
      isAdvancedRules: false,
      rule: null,
    },
    blockName: blockName ?? "",
    blockData: data,
    blockOptions: blockOptions ?? {
      backgroundColor: "rgb(255,255,255)",
      borderColor: "rgb(255,255,255)",
      borderRadius: "5px",
      fontColor: "rgb(0,0,0)",
      addToDecisionSummary:
        blockType === "choices" || blockType === "linkButton" ? true : false,
      addToManagementPlan: addToManagementPlan ?? false,
      readonlyInTemplate: false,
      addToNodeScore:
        blockType === "choices" && (data as IChoicesBoxData).type === "scorer"
          ? true
          : false,
    },
  };
  return block;
};

export const moveBlocksRow = (
  sourceIndex: number,
  destinationIndex: number,
  blocks: Record<string, INodeBlockBase>
) => {
  const newBlocksOrder = Object.values(blocks).map((block) => {
    if (
      _.inRange(block.position.row, sourceIndex, destinationIndex) ||
      block.position.row === sourceIndex ||
      block.position.row === destinationIndex
    ) {
      if (block.position.row === sourceIndex) {
        block.position.row = destinationIndex;
        return block;
      }
      if (block.position.row > sourceIndex) {
        block.position.row--;
        return block;
      }
      if (block.position.row >= destinationIndex) {
        block.position.row++;
        return block;
      }
    }
    return block;
  });

  let newBlocksObject = newBlocksOrder.reduce(
    (object: Object, block: INodeBlockBase) => {
      return { ...object, [block.blockId]: block };
    },
    {}
  );

  return newBlocksObject;
};

export const addRowBlock = (
  indexNewRow: number,
  blocks: Record<string, INodeBlockBase>
) => {
  const newBlocksOrder = Object.values(blocks).map((block) => {
    if (block.position.row >= indexNewRow) {
      block.position.row++;
      return block;
    }
    return block;
  });

  let newBlocksObject = newBlocksOrder.reduce(
    (object: Object, block: INodeBlockBase) => {
      return { ...object, [block.blockId]: block };
    },
    {}
  );

  return newBlocksObject;
};

export const removeRowBlock = (
  rowIndex: number,
  blocks: Record<string, INodeBlockBase>
) => {
  const newBlocksArray = Object.values(blocks).filter(
    (block: INodeBlockBase) => block.position.row !== rowIndex
  );
  const newBlocksObject = newBlocksArray.reduce(
    (object: Record<string, INodeBlockBase>, block) => {
      if (block.position.row > rowIndex) {
        block.position.row--;
      }
      return { ...object, [block.blockId]: { ...block } };
    },
    {}
  );
  return newBlocksObject;
};

export const getBlocksThatManipulateRules = (
  currentBlockId: string | undefined,
  blocks: NodeBlock[]
) => {
  const interactiveBlocks = Object.values(blocks).filter((block) => {
    if (block.blockId === currentBlockId) {
      return undefined;
    }
    return interactiveBlocksTypes.includes(block.blockType) ? block : undefined;
  });
  return interactiveBlocks;
};

export const getValuesRule = (ruleElement: any, blocks: NodeBlock[]) => {
  let name: string = "";
  let type: string = "";
  let value: number | boolean = 0;
  let maxValue: number | string = "";
  let field: string = "";
  let operator: string = "";
  let blockId: string = "";
  let fieldId: string = "";
  let valueId: string = "";
  let hasMultipleValues: boolean = false;

  if (ruleElement) {
    operator = Object.keys(ruleElement)[0];
    let ruleValues: any = Object.values(ruleElement)[0];
    let stringVar = ruleValues[0].var;
    let positionValue = ruleValues[1];
    if (ruleValues.length === 3) {
      stringVar = ruleValues[1].var;
      positionValue = ruleValues[0];
      maxValue = ruleValues[2];
    }

    let blockRuleId = stringVar.includes(".")
      ? stringVar.substring(0, stringVar.indexOf("."))
      : stringVar;

    if (blockRuleId === "node") {
      name = "node";
      if (stringVar.includes(".")) {
        type = stringVar.substring(
          stringVar.indexOf(".") + 1,
          stringVar.length
        );
      }
    }

    const blockConnectedToRule = blocks.find(
      (block) => block.blockId === blockRuleId
    );

    if (blockRuleId !== "node") {
      name = blockConnectedToRule?.blockName || "";
      blockId = blockConnectedToRule?.blockId || "";
    }

    if (blockConnectedToRule && blockConnectedToRule.blockType === "choices") {
      type =
        typeof positionValue === "number"
          ? "score"
          : typeof positionValue === "boolean"
          ? "toggle"
          : "contains";
    }
    if (
      blockConnectedToRule &&
      (blockConnectedToRule.blockType === "button" ||
        blockConnectedToRule.blockType === "showHide")
    ) {
      type = "toggle";
    }

    if (stringVar.includes(".") && type !== "score") {
      const hasValueId =
        stringVar.split("").filter((i: string) => i === ".").length > 1
          ? true
          : false;

      fieldId = stringVar.substring(
        stringVar.indexOf(".") + 1,
        hasValueId ? stringVar.lastIndexOf(".") : stringVar.length
      );

      valueId = hasValueId
        ? stringVar.substring(stringVar.lastIndexOf(".") + 1, stringVar.length)
        : "";
      if (blockConnectedToRule?.blockType === "choices") {
        let lengthArrayValues = (
          blockConnectedToRule?.blockData as IChoicesBoxData
        ).fields.find((field: IChoicesLogicField) => field.fieldId === fieldId)
          ?.values.length;

        hasMultipleValues =
          lengthArrayValues && lengthArrayValues > 1 ? true : false;

        if (!hasMultipleValues) {
          valueId =
            (blockConnectedToRule?.blockData as IChoicesBoxData).fields.find(
              (field: IChoicesLogicField) => field.fieldId === fieldId
            )?.values[0].valueId || "";
        }
        field =
          (blockConnectedToRule?.blockData as IChoicesBoxData).fields.find(
            (field: IChoicesLogicField) => field.fieldId === fieldId
          )?.title || "";
      }
      if (blockConnectedToRule?.blockType === "button") {
        field = (blockConnectedToRule.blockData as IButtonData).buttonText;
      }
    }
    value = positionValue;
  }

  return {
    name,
    type,
    value,
    maxValue,
    field,
    operator,
    blockId,
    fieldId,
    valueId,
    hasMultipleValues,
  };
};

export const convertNodeContextToJsonData = (
  nodeContext: INodeContext
): { [blockName: string]: { [field: string]: number | boolean | string } } => {
  const data = nodeContext.fields.reduce(
    (
      objectData: Record<string, any>,
      field: INodeContextItem,
      _,
      referenceArray
    ) => {
      let data = {};

      const multipleFieldInBlock = referenceArray.filter(
        (fieldElement) => field.blockId === fieldElement.blockId
      );

      let totalScoreBlock = 0;
      let concatFieldNames = "";
      let fieldValues = {};

      if (multipleFieldInBlock) {
        multipleFieldInBlock.forEach((fieldBlock) => {
          if (fieldBlock.isToggled) {
            if (typeof fieldBlock.value === "number") {
              totalScoreBlock += +fieldBlock.value;
            }
            concatFieldNames = concatFieldNames.concat(
              fieldBlock.fieldName ?? ""
            );
          }
          if (fieldBlock.valueId && fieldBlock.blockFieldId) {
            fieldValues = {
              ...fieldValues,
              [fieldBlock.blockFieldId]: {
                [`${fieldBlock.valueId}`]: fieldBlock.isToggled,
              },
            };
          }
          if (!fieldBlock.valueId && fieldBlock.blockFieldId) {
            fieldValues = {
              ...fieldValues,
              [fieldBlock.blockFieldId]: fieldBlock.isToggled,
            };
          }
        });
      }

      data = {
        score: totalScoreBlock,
        contains: concatFieldNames,
        ...fieldValues,
      };

      return {
        ...objectData,
        [field.blockId]: data,
      };
      // let value = null;

      // if (field.useScore) {
      //   const multipleToggledScoreFieldInBlock = referenceArray.filter(
      //     (fieldElement) =>
      //       field.blockId === fieldElement.blockId &&
      //       typeof field.value === "number" &&
      //       fieldElement.isToggled
      //   );

      //   if (multipleToggledScoreFieldInBlock.length > 0) {
      //     value = multipleToggledScoreFieldInBlock.reduce(
      //       (tot: number, currValue: any) => {
      //         return (tot += currValue.value);
      //       },
      //       0
      //     );
      //   }
      //   if (multipleToggledScoreFieldInBlock.length === 0) {
      //     value = 0;
      //   }
      // }

      // let data;
      // if (!field.useScore) {
      //   if (field.valueId) {
      //     value = { [field.valueId]: field.value };

      //     if (
      //       field.hasMultipleValues &&
      //       objectData[field.blockId] &&
      //       field.blockFieldId
      //     ) {
      //       let fieldObj = objectData[field.blockId][field.blockFieldId];
      //       value = { ...fieldObj, ...value };
      //     }
      //     data = { [`${field.blockFieldId}`]: value };
      //   }
      //   if (!field.valueId) {
      //     data = { [`${field.blockFieldId}`]: field.value };
      //   }

      //   if (
      //     Object.keys(objectData).includes(field.blockId) &&
      //     typeof data === "object"
      //   ) {
      //     data = { ...objectData[field.blockId], ...data };
      //   }
      // }

      // return {
      //   ...objectData,
      //   [field.blockId]: field.useScore ? value : data,
      // };
    },
    {}
  );

  const totalNodeScore = nodeContext.fields.reduce(
    (tot: number, field: INodeContextItem) => {
      let value = 0;
      if (
        field.addToNodeScore &&
        typeof field.value === "number" &&
        field.isToggled
      ) {
        value = field.value;
      }
      return (tot += value);
    },
    0
  );

  return { ...data, node: { score: totalNodeScore } };
};

export const checkIfRuleIsMet = (
  blockRule: IBlockRules,
  context: INodeContext
) => {
  if (blockRule.alwaysShow) {
    return true;
  }
  if (!blockRule.alwaysShow && blockRule.rule) {
    const data = convertNodeContextToJsonData(context);

    return jsonLogic.apply(JSON.parse(blockRule.rule), data);
  }
};

export const getManagementPlanText = (blocks: Record<string, NodeBlock>) => {
  const arrayContents = Object.values(blocks).reduce(
    // (arrayString: { title: string; content: string }[], block: NodeBlock) => {
    (arrayString: string[], block: NodeBlock) => {
      if (
        block.blockOptions.addToManagementPlan &&
        block.blockType === "richTextArea" &&
        (block.blockData as IRichTextAreaData).type === "richText"
      ) {
        return [
          ...arrayString,
          // {
          // title: block.blockName,
          // content:
          (block.blockData as IRichTextAreaData).content,
          // },
        ];
      }
      return [...arrayString];
    },
    []
  );

  return arrayContents;
};

export const disableNavigation = (
  state: IDynamicNode,
  blockEditInfos: NodeBlock | undefined,
  nodeContext: INodeContext | null,
  block: NodeBlock,
  nodeSettings?: INodeViewSettings
) => {
  let infoDisabledObject: {
    isDisabled: boolean;
    blockRequiredUntoggled: string[];
  } = {
    isDisabled: false,
    blockRequiredUntoggled: [],
  };

  if (state.title === "") {
    infoDisabledObject.isDisabled = true;
    return infoDisabledObject;
  }

  const connectedNode = isConnectedNode(state);

  if (connectedNode && state.nodeProperties.isEndNodeFunctionalityEnabled) {
    const managementPlan = getManagementPlanText(state.blocks);

    if (managementPlan.length === 0) {
      infoDisabledObject.isDisabled = true;
      return infoDisabledObject;
    }

    // need to sort out logic for when there are no links present
  }

  if (
    block.blockRules &&
    nodeContext &&
    !checkIfRuleIsMet(block.blockRules, nodeContext)
  ) {
    infoDisabledObject.isDisabled = true;
    return infoDisabledObject;
  }

  if (blockEditInfos) {
    infoDisabledObject.isDisabled = true;
    return infoDisabledObject;
  }
  const requiredChoicesBlock = Object.values(state.blocks).filter(
    (block) =>
      block.blockType === "choices" &&
      (block.blockData as IChoicesBoxData).isRequired
  );

  if (requiredChoicesBlock && !nodeContext) {
    infoDisabledObject.isDisabled = true;
    return infoDisabledObject;
  }

  if (requiredChoicesBlock && nodeContext) {
    let untoggledBlocks: string[] = [];
    requiredChoicesBlock.forEach((block) => {
      const fieldToggled = nodeContext.fields.find(
        (field) => field.blockId === block.blockId && field.isToggled
      );

      if (infoDisabledObject.isDisabled) {
        if (fieldToggled) {
          untoggledBlocks.push(block.blockName);
        }
      }
      if (!fieldToggled) {
        infoDisabledObject.isDisabled = true;
        untoggledBlocks.push(block.blockName);
      }
    });
    infoDisabledObject.blockRequiredUntoggled = untoggledBlocks;
  }

  infoDisabledObject.isDisabled = false;

  return infoDisabledObject;
};

export const isFieldToggled = (
  type: "button" | "field",
  id: string,
  nodeContext: INodeContext | undefined
) => {
  if (!nodeContext || !id) {
    return false;
  }
  const nodeContextField = nodeContext.fields.find(
    (x) => id === (type === "button" ? x.blockFieldId : x.valueId)
  );

  return nodeContextField?.isToggled ?? false;
};

export const isVarContainedInRule = (
  rule: Record<string, any[]>,
  varId: string
) => {
  for (const value of Object.values(rule)) {
    for (const elem of value) {
      if (Object.hasOwn(elem, "var") && elem["var"].startsWith(varId)) {
        return true;
      }
    }
  }
  return false;
};

export const isSubRule = (item: Record<string, any>) => {
  const [firstProp] = Object.entries(item)[0];
  return ["and", "or", "not"].includes(firstProp);
};

export const cleanRule = (ruleObject: Record<string, any[]>, varId: string) => {
  const resultObj: Record<string, any[]> = {};

  for (const [prop, rules] of Object.entries(ruleObject)) {
    //Rules prop equals "and" and rules equals [{ "==": [{ var: "test" }, true] }]
    for (const ruleItem of rules) {
      //ruleItem equals { "==": [{ var: "test" }, true] }

      if (isSubRule(ruleItem)) {
        const subRule: any = cleanRule(ruleItem, varId);

        resultObj[prop].push(subRule);
        continue;
      }

      if (!isVarContainedInRule(ruleItem, varId)) {
        if (resultObj[prop] === undefined) {
          resultObj[prop] = [];
        }
        resultObj[prop].push(ruleItem);
      }
    }
  }

  return resultObj;
};

export const checkIfRuleHasBlockId = (
  ruleObject: Record<string, any[]>,
  varId: string
) => {
  const resultObj: Record<string, any[]> = {};

  for (const [prop, rules] of Object.entries(ruleObject)) {
    for (const ruleItem of rules) {
      if (isSubRule(ruleItem)) {
        const subRule: any = checkIfRuleHasBlockId(ruleItem, varId);

        resultObj[prop].push(subRule);
        continue;
      }

      if (isVarContainedInRule(ruleItem, varId)) {
        return true;
      }
    }
    return false;
  }

  return resultObj;
};

export const listBlocksConnected = (
  blocks: Partial<INodeBlockBase>[],
  varId: string
) => {
  let result: string[] = [];

  blocks.forEach((block) => {
    const rule = block.blockRules?.rule;

    if (rule && block.blockId) {
      const ruleObject: Record<string, any[]> = JSON.parse(rule);

      if (checkIfRuleHasBlockId(ruleObject, varId)) {
        result.push(block.blockId);
      }
    }
  });

  return result.length > 0 ? result : null;
};

export const addRule = (
  block: NodeBlock,
  logicArray?: (number | Object)[],
  conditional?: string
) => {
  let newRulesArray: any[] = [];
  let conditionalOperator: string = "and";

  if (block.blockRules.rule) {
    newRulesArray = Object.values(JSON.parse(block.blockRules.rule))[0] as any;
    conditionalOperator = Object.keys(JSON.parse(block?.blockRules.rule))[0];
  }

  let newLogic = {
    [conditional ?? "=="]: logicArray ?? [{ var: "" }, ""],
  };

  newRulesArray.push(newLogic);

  const newRuleObject = {
    [conditionalOperator]: newRulesArray,
  };

  return JSON.stringify(newRuleObject);
};
export const getNameBlockWithNumberInstancesOfBlockType = (
  blocks: NodeBlock[],
  block: NodeBlock
) => {
  const blockType =
    block.blockType === "choices"
      ? (block.blockData as IChoicesBoxData).type
      : block.blockType;

  const blockNameCount = Object.values(blocks).reduce((acc, block) => {
    if (block.blockName.includes(blockType)) {
      const number =  +block.blockName.substring(block.blockName.lastIndexOf("-") + 1);
      if (number >= acc) {
        acc = number + 1;
      }
    }
    return acc;
  }, 1);

  return `${blockType}-${blockNameCount}`;
};

export const updateUidsOnBlockData = (
  blockData: IChoicesBoxData | IButtonData | IShowHideData
): IChoicesBoxData | IButtonData | IShowHideData => {
  const valuesToChange = ["groupId", "fieldId", "valueId", "buttonId"];
  let newDataObject: any = {};

  for (const [key, value] of Object.entries(blockData)) {
    if (value instanceof Array) {
      let newArray = [];

      for (const i of value) {
        if (i instanceof Object) {
          const obj = updateUidsOnBlockData(i);
          newArray.push(obj);
        } else {
          newArray.push(i);
        }
      }
      newDataObject[key] = newArray;
      continue;
    }

    newDataObject = {
      ...newDataObject,
      [key]: valuesToChange.includes(key) ? uuid4() : value,
    };
  }

  return newDataObject as IChoicesBoxData | IButtonData | IShowHideData;
};

export const getStartbackDecisionSummary = (
  decisionSummary: INodeContextItem[]
) => {
  const startbackDecisions = decisionSummary.filter(
    (decision) => decision.groupName === "STarTBack" && decision.isToggled
  );

  const sortedStartbackDecisions = startbackDecisions.map((decision, index) => {
    if (decision.order === index) {
      return decision;
    }
    const decisionInOrder = startbackDecisions.find((i) => i.order === index);
    return decisionInOrder;
  });

  return sortedStartbackDecisions;
};

export const getNumericValueFromInput = (inputValue: string) => {
  if (
    (isNaN(+inputValue[inputValue.length - 1]) &&
      inputValue[inputValue.length - 1] !== "-") ||
    (inputValue[inputValue.length - 1] === "-" && inputValue.length > 2)
  ) {
    return inputValue.slice(0, inputValue.length - 1);
  }
  if (inputValue === "") {
    return "0";
  }
  if (inputValue === "0-") {
    return "-1";
  }
  if (/^-?\d+$/.test(inputValue)) {
    return inputValue;
  }
  return "0";
};
