import { List } from "semantic-ui-react";

const SearchPathwaysListItem = ({
  pathwayId,
  onSelect,
}: {
  pathwayId: string;
  onSelect: (pathwayId: string) => void;
}) => {
  return (
    <List.Item
      style={{
        color: "black",
        padding: "10px 15px",
        borderRadius: "0px",
      }}
      onClick={() => onSelect(pathwayId)}
    >
      <List.Content>
        <p>{pathwayId}</p>
      </List.Content>
    </List.Item>
  );
};

export default SearchPathwaysListItem;
