import DOMPurify from "dompurify";
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target that is not _self to target=_blank and set noopener on them
  if ("target" in node && node.getAttribute("target") !== "_self") {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
  }
});
const SafeHtml = ({
  html,
  allowHtml,
}: {
  html: string;
  allowHtml?: boolean;
}) => {
  if (!allowHtml) {
    return <span>{html}</span>;
  }
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html, { ADD_ATTR: ["target"] }),
      }}
    ></span>
  );
};

export default SafeHtml;
