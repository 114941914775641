import {
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import InsertDocumentLibraryModal from "app/components/InsertDocumentLibraryModalV2";
import { useState } from "react";
import {
  IVideoBlock,
  IVideoBlockData,
  NodeBlock,
} from "../../../../types/Nodes";
import { DynamicNodeEditorActions } from "../dynamicNodeEditorReducer";
import CloseIcon from "@mui/icons-material/Close";
import VideoBlock from "../displayBlocks/VideoBlock";

interface INodeVideoEditorProps {
  block: IVideoBlock;
  dispatch?: React.Dispatch<DynamicNodeEditorActions>;

  isEditing: boolean;
  onEdit?: (block: NodeBlock | undefined) => void;
  isPreview: boolean;
}

const YOUTUBE_ID_VALIDATOR = new RegExp(/^[\w~._-]{11}$/);
const VIMEO_ID_VALIDATOR = new RegExp(/^[\d]{8,11}$/);

const parseVimeoLink = (link: string) => {
  let videoId = link;

  //Full Link
  if (videoId.toLowerCase().includes("vimeo.com")) {
    const u = new URL(videoId);

    videoId = u.pathname.substring(1, u.pathname.length) || "";
    return videoId;
  }

  return videoId;
};

const parseYouTubeLink = (link: string) => {
  let videoId = link;

  //Full Link
  if (videoId.toLowerCase().includes("youtube.com")) {
    const u = new URL(videoId);
    videoId = u.searchParams.get("v") || "";
    return videoId;
  }
  //It is just the search params
  if (videoId.includes("=")) {
    const searchParams = new URLSearchParams(videoId);
    videoId = searchParams.get("v") || "";
    return videoId;
  }
  //Probably just the id, so return it
  return videoId;
};

const NodeVideoEditor = ({
  dispatch,
  block,
  isEditing,
  onEdit,
  isPreview,
}: INodeVideoEditorProps) => {
  const [addVideoType, setAddVideoType] = useState<
    "vimeo" | "youtube" | "direct" | null
  >(null);

  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [videoLink, setVideoLink] = useState<string>("");
  const [error, setError] = useState("");

  const onInputChange = ({
    type,
    value,
  }: {
    type: "youtube" | "vimeo" | "direct" | null;
    value: string;
  }) => {
    setError("");
    let videoId = value;

    if (!type) {
      return;
    }

    if (type === "youtube") {
      videoId = parseYouTubeLink(videoId);
      if (!YOUTUBE_ID_VALIDATOR.test(videoId)) {
        setError(`"${videoId}" is not a valid YouTube id`);
      }
    }
    if (type === "vimeo") {
      videoId = parseVimeoLink(videoId);
      if (!VIMEO_ID_VALIDATOR.test(videoId)) {
        setError(`"${videoId}" is not a valid Vimeo id`);
      }
    }

    setVideoLink(videoId);
  };

  const onUpdateVideoLink = (
    type: "youtube" | "vimeo" | "direct" | null,
    videoPointer: string
  ) => {
    if (type === null) {
      setError("");
      setVideoLink("");
      setAddVideoType(type);
      return;
    }

    if (videoPointer && error === "") {
      const blockData: IVideoBlockData = {
        filePointer: { pointer: videoPointer, storageType: type },
      };

      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: blockData,
        },
      });
    }
    onEdit?.(undefined);
    setAddVideoType(null);
    //setIsModalOpen(!isModalOpen);
  };

  // const validation = () => {
  //   if (!block.blockData.filePointer.pointer) {
  //     console.log(`${block.blockName}: No link provided`);
  //     return false;
  //   }

  //   const isLinkUnique = Object.values(blocks).every((x) => {
  //     if (isVideoBlock(x) && x.blockId !== block.blockId) {
  //       return (
  //         x.blockData.filePointer.pointer !==
  //         block.blockData.filePointer.pointer
  //       );
  //     }
  //     return true;
  //   });
  //   if (isLinkUnique) {
  //     console.log(`${block.blockName}: Video is valid`);
  //     return;
  //   }
  //   console.log(`${block.blockName}: video validation - ${isLinkUnique}`);
  // };

  // const postMessage = (blocks: NodeBlock[]) => {
  //   console.log("Post Message:  " + block.blockData?.link);
  // };

  // register && register[block.blockId] = validation;
  const hasEmptyPointer = block.blockData.filePointer.pointer === "";

  return (
    <>
      {!hasEmptyPointer && !isEditing && <VideoBlock block={block} />}
      {(hasEmptyPointer || isEditing) && (
        <Box
          sx={{
            background: !hasEmptyPointer
              ? "rgba(0, 0, 0, 0.8)"
              : "rgb(234, 234, 234)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 3,
            width: "100%",
            height: "100%",
          }}
        >
          <svg
            style={{ marginBottom: "16px" }}
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.66665 16.9999H0.333313V75.3333C0.333313 79.9166 4.08331 83.6666 8.66665 83.6666H67V75.3333H8.66665V16.9999ZM75.3333 0.333252H25.3333C20.75 0.333252 17 4.08325 17 8.66658V58.6666C17 63.2499 20.75 66.9999 25.3333 66.9999H75.3333C79.9166 66.9999 83.6666 63.2499 83.6666 58.6666V8.66658C83.6666 4.08325 79.9166 0.333252 75.3333 0.333252ZM75.3333 58.6666H25.3333V8.66658H75.3333V58.6666ZM42 14.9166V52.4166L67 33.6666L42 14.9166Z"
              fill={!hasEmptyPointer ? "white" : "black"}
              fillOpacity={!hasEmptyPointer ? "1" : "0.54"}
            />
          </svg>
          <Typography
            variant="body3"
            sx={{
              mb: 2,
              color: hasEmptyPointer ? "black" : "white",
            }}
          >
            This is a Video block. You can add videos to this block by importing
            a video from the Document Library, or by adding a Vimeo or YouTube
            video URL.
          </Typography>
          {!isPreview && (
            <span
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 8,
              }}
              onClick={() => !isEditing && onEdit?.(block)}
            >
              <Button
                variant="contained"
                sx={{ mr: 1, background: "#C62828" }}
                onClick={(e) => setAddVideoType("youtube")}
                startIcon={
                  <svg
                    width="21"
                    height="14"
                    viewBox="0 0 21 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.0812 2.1966C19.968 1.77269 19.7474 1.3889 19.443 1.08604C19.1299 0.773862 18.7462 0.550554 18.3281 0.437193C16.7633 0.00433624 10.4942 0.00433634 10.4942 0.00433634C7.88063 -0.0268707 5.26773 0.110386 2.67022 0.415331C2.2521 0.537067 1.86913 0.765365 1.55537 1.07992C1.24708 1.39123 1.02378 1.77511 0.907126 2.19573C0.626908 3.78002 0.490759 5.38867 0.500514 6.99999C0.490516 8.60987 0.626331 10.218 0.907126 11.8043C1.02128 12.2231 1.24375 12.6053 1.55287 12.9139C1.862 13.2226 2.24695 13.4456 2.67022 13.5637C4.25584 13.9957 10.4942 13.9957 10.4942 13.9957C13.111 14.0269 15.7273 13.8896 18.3281 13.5847C18.7462 13.4713 19.1299 13.248 19.443 12.9358C19.7473 12.633 19.9676 12.2492 20.0804 11.8252C20.3679 10.2416 20.5077 8.63225 20.4978 7.02011C20.5194 5.40114 20.3798 3.78423 20.0812 2.19573V2.1966ZM8.5011 9.99414V4.00673L13.7171 7.00087L8.5011 9.99414Z"
                      fill="white"
                    />
                  </svg>
                }
              >
                Youtube
              </Button>
              <Dialog
                onClose={() => setAddVideoType(null)}
                open={addVideoType !== null}
                fullWidth
              >
                <DialogTitle
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>
                    Insert{" "}
                    {addVideoType && addVideoType === "vimeo"
                      ? "Vimeo"
                      : "Youtube"}
                    link
                  </span>
                  <IconButton onClick={() => setAddVideoType(null)}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {error && (
                    <div style={{ color: "red", marginBottom: "16px" }}>
                      {error}
                    </div>
                  )}
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="New Video Link"
                    sx={{ p: 0 }}
                    variant="outlined"
                    onChange={(e) =>
                      onInputChange({
                        type: addVideoType,
                        value: e.target.value,
                      })
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setAddVideoType(null)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    disabled={error === "" && videoLink !== "" ? false : true}
                    onClick={() => onUpdateVideoLink(addVideoType, videoLink)}
                  >
                    Add Video
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="contained"
                sx={{ mr: 1, backgroundColor: "#006ACC" }}
                onClick={(e) => setAddVideoType("vimeo")}
                startIcon={
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.97231 0C2.04859 0 0.5 1.54859 0.5 3.47231V14.5277C0.5 16.4514 2.04859 18 3.97231 18H15.0277C16.9514 18 18.5 16.4514 18.5 14.5277V3.47231C18.5 1.54859 16.9514 0 15.0277 0H3.97231ZM13.2732 3.54544C13.328 3.54517 13.3833 3.54589 13.4392 3.54769C14.7669 3.58618 15.3921 4.44208 15.3151 6.11608C15.2574 7.36677 14.3821 9.07967 12.6888 11.254C10.9379 13.5244 9.45615 14.6593 8.24394 14.6593C7.49352 14.6593 6.85827 13.9668 6.33875 12.5815L5.29981 8.77165C4.91499 7.38626 4.50149 6.69377 4.05892 6.69377C3.96271 6.69377 3.62592 6.89551 3.04867 7.29958L2.44229 6.52052C3.07726 5.96252 3.70306 5.40452 4.31879 4.84652C5.1654 4.11534 5.8001 3.73019 6.22342 3.69171C7.22397 3.5955 7.83977 4.27855 8.07067 5.7409C8.32082 7.31869 8.49421 8.3002 8.59042 8.68504C8.87903 9.99346 9.19639 10.6476 9.54273 10.6476C9.81212 10.6476 10.2162 10.2241 10.7549 9.37746C11.2937 8.53085 11.5821 7.8867 11.6206 7.44415C11.6976 6.71297 11.4091 6.34727 10.7549 6.34727C10.4471 6.34727 10.1297 6.41452 9.80262 6.54921C10.4177 4.55472 11.5747 3.55361 13.2732 3.54546V3.54544Z"
                      fill="white"
                    />
                  </svg>
                }
              >
                Vimeo
              </Button>
              <InsertDocumentLibraryModal
                selectFunction={(value) => {
                  onUpdateVideoLink("direct", value.file);
                }}
                handleUpdateNodePreview={() => {}}
                triggerBtnText="Document Library"
                filterBy={"VIDEO"}
                view={"Grid View"}
              />
            </span>
          )}
        </Box>
      )}
    </>
  );
};

export default NodeVideoEditor;
