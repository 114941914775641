import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useEffect, useRef, useState } from "react";
import { IDynamicNode } from "../../../types/Nodes";
import { IPathwayEpisode } from "../../../types/AnalyticsV2";
import { useImmerReducer } from "use-immer";
import useModules from "./useModules";
import * as Sentry from "@sentry/react";
import useTenantBrowser from "features/hooks/useTenantBrowser";
import {
  getDynamicEmptyNode,
  getStartNode,
} from "features/pathwaybuilder/utils/pathwayHelperV2";
import { getDb } from "app/data/DbProvider";
import { getPathwayPath } from "features/pathwaybuilder/utils/editorConverters";
import {
  PathwayActions,
  PathwayState,
  pathwayReducer,
} from "./navigatorReducer";

// const emptyNodeTemplate: INode = {
//   id: "",
//   image: "",
//   nodeContentArea: "",
//   print: false,
//   question: "",
//   title: "",
//   vimeo: "",
//   youtube: "",
//   widgets: [],
//   controlledElements: [],
//   video: "",
//   createdAt: Date.now(),
//   createdBy: "",
//   updatedAt: Date.now(),
//   updatedBy: "",
//   files: [],
//   actions: [],
//   answers: [],
//   groupNames: [],
//   logicgroups: [],
//   logicfields: [],
//   logicscores: [],
//   ruleOperators: [],
//   ruleValues: [],
//   ruleValuesMin: [],
//   ruleValuesMax: [],
//   ruleNames: [],
//   ruleActions: [],
//   ruleTypes: [],
//   showscores: false,
//   fileNames: [],
// };

const readCountName = (moduleId: string) =>
  moduleId === "Ortho" ? "reads" : `${moduleId}Reads`;

const totalReadCountName = (moduleId: string) =>
  moduleId === "Ortho" ? "totalcount" : `${moduleId}TotalCount`;

export const incrementReadCount = async (
  tenantId: string,
  moduleId: string,
  currentPathway: string
) => {
  const db = firebase.firestore();

  const read = {
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    pathway: currentPathway,
  };
  const increment = {
    [totalReadCountName(moduleId)]: firebase.firestore.FieldValue.increment(1),
  };

  await db
    .collection(`${tenantId}/--readcount--/${readCountName(moduleId)}`)
    .add(read);

  await db.collection(tenantId).doc("--readcount--").update(increment);
};

const usePathwayNavigator = ({
  pathwayId: initialPathwayId,
  tenantId,
  nodeCollection,
  overrideDefaultStartNode,
}: {
  pathwayId: string;
  tenantId: string;
  nodeCollection: string;
  overrideDefaultStartNode?: string;
}) => {
  const firestore = firebase.firestore();
  const { tenantBrowser } = useTenantBrowser();
  const db = getDb();

  const [pathwayNodes, setPathwayNodes] = useState<IDynamicNode[]>([]);
  const [activeNode, setActiveNode] = useState<IDynamicNode>(
    getDynamicEmptyNode()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const episodeRefId = useRef<string>("");
  const { activeModule } = useModules();
  const [state, dispatch] = useImmerReducer<PathwayState, PathwayActions>(
    pathwayReducer,
    {
      route: [],
      activeNode: undefined,
      decisionSummary: [],
      currentPathwayId: initialPathwayId,
      tenantId: tenantId || "",
      nodeContext: { fields: [] },
    }
  );

  const defaultTenant = tenantBrowser === undefined || tenantBrowser === "";

  // add to read count only if user if browsing own tenancy and live environment
  useEffect(() => {
    if (nodeCollection === "livenodes" && defaultTenant) {
      incrementReadCount(tenantId, activeModule?.id ?? "", initialPathwayId);
    }
  }, [
    activeModule?.id,
    defaultTenant,
    initialPathwayId,
    nodeCollection,
    tenantBrowser,
    tenantId,
  ]);

  useEffect(() => {
    const saveEpisode = async () => {
      try {
        if (
          defaultTenant &&
          state.pathwayEpisode !== undefined &&
          state.episodeStatus === "UNSAVED" &&
          nodeCollection === "livenodes"
        ) {
          //New Episode, create inital document
          if (episodeRefId.current === "") {
            const episodeRef = await firestore
              .collection(`${tenantId}/--readcount--/${activeModule?.id}`)
              .add({
                ...state.pathwayEpisode,
                exitTime: firebase.firestore.FieldValue.serverTimestamp(),
              });
            episodeRefId.current = episodeRef.id;
          }

          if (episodeRefId.current !== "") {
            await firestore
              .collection(`${tenantId}/--readcount--/${activeModule?.id}`)
              .doc(episodeRefId.current)
              .set(
                {
                  ...state.pathwayEpisode,
                  exitTime: firebase.firestore.FieldValue.serverTimestamp(),
                },
                { merge: true }
              );
          }
          //We need to reload the episode with the newly populated timestamps
          const episodeRef = await firestore
            .collection(`${tenantId}/--readcount--/${activeModule?.id}`)
            .doc(episodeRefId.current)
            .get();
          const episode = episodeRef.data() as IPathwayEpisode;

          dispatch({
            type: "REPLACE_EPISODE",
            payload: { episode: episode },
          });
        }
      } catch (ex: any) {
        console.error("usePathwayNavigator", ex);
        Sentry.withScope((scope) => {
          scope.setExtra(
            "pathwayEpisode",
            JSON.stringify(state.pathwayEpisode, (k, v) =>
              v === undefined ? "undefinedError" : v
            )
          );
          Sentry.captureException(ex);
        });
      }
    };
    saveEpisode();
  }, [
    activeModule?.id,
    firestore,
    defaultTenant,
    dispatch,
    nodeCollection,
    state.episodeStatus,
    state.pathwayEpisode,
    tenantId,
  ]);

  //Set the active node object
  useEffect(() => {
    const node = pathwayNodes.find((x) => x.id === state.activeNode?.nodeId);

    if (defaultTenant && node !== undefined && nodeCollection === "livenodes") {
      dispatch({ type: "ADD_EPISODE_STEP", payload: { node: node } });
    }

    setActiveNode(node ?? getDynamicEmptyNode());
  }, [defaultTenant, dispatch, nodeCollection, pathwayNodes, state.activeNode]);

  // Get a reference to a mutable copy of the route array
  const routeRef = useRef(state.route);

  // Keep the route array copy up to date
  useEffect(() => {
    routeRef.current = state.route;
  }, [state.route]);

  //Watch pathway nodes
  useEffect(() => {
    if (tenantId && state.currentPathwayId) {
      return db.watchCollection<IDynamicNode>(
        getPathwayPath(tenantId, state.currentPathwayId, nodeCollection),
        (nodes) => {
          setPathwayNodes(nodes);
        }
      );
    }
  }, [db, nodeCollection, state.currentPathwayId, tenantId]);

  //Handle jumping to new pathway
  useEffect(() => {
    const Load = async () => {
      setLoading(true);

      const pathwayId = state.currentPathwayId;

      if (tenantId && pathwayId) {
        if (state.currentPathwayId !== routeRef.current.at(-1)?.pathwayId) {
          const pathwayStartNode = await getStartNode(
            tenantId,
            pathwayId,
            nodeCollection
          );

          //If the route is empty then we are jumping into a pathway. So if a startNode was provided
          //we set it here. The startNode allows overriding where in the pathway starts from, so it
          //be something other than the start node
          let initialNodeId = pathwayStartNode.id ?? "";
          if (overrideDefaultStartNode && !state.activeNode) {
            initialNodeId = overrideDefaultStartNode;
          }

          if (state.activeNode && state.activeNode.nodeId !== "") {
            initialNodeId = state.activeNode.nodeId;
          }

          dispatch({
            type: "MOVE_TO_NEW_PATHWAY",
            payload: {
              nodeId: initialNodeId,
              pathwayId: pathwayId,
            },
          });
        }
      }

      setLoading(false);
    };
    Load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tenantId,
    state.currentPathwayId,
    nodeCollection,
    overrideDefaultStartNode,
    initialPathwayId,
    db,
    dispatch,
  ]);

  return {
    dispatch,
    state,
    activeNode,
    pathwayNodes,
    loading,
    setStartNode: (nodeId: string) =>
      dispatch({
        type: "MOVE_TO_NEW_PATHWAY",
        payload: {
          nodeId: nodeId,
          pathwayId: initialPathwayId,
        },
      }),
  };
};

export default usePathwayNavigator;
