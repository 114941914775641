import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";

import useUserPublicProfile from "features/hooks/useUserPublicProfile";

interface UserNameProps {
  name: string;
  setName: (value: string) => void;
  initials: string;
  setInitials: (value: string) => void;
  isFormComplete?: boolean;
  setIsFormComplete: (value: boolean) => void;
  isAccountSettings?: boolean;
}

const UserName = ({
  name,
  setName,
  initials,
  setInitials,
  isFormComplete,
  setIsFormComplete,
  isAccountSettings,
}: UserNameProps) => {
  const [initialsError, setInitialsError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [toggleInput, setToggleInput] = useState<boolean>(false);

  const { publicProfile, editName } = useUserPublicProfile();

  useEffect(() => {
    let isRequiredFieldsComplete = name.trim() !== "" && initials.trim() !== "";

    let isInitialsValid = /^[a-zA-Z]+$/.test(initials);
    let isNameValid = /^[A-Za-zÀ-ÖØ-öø-ÿ-.' ]+$/.test(name);

    if (isRequiredFieldsComplete && isInitialsValid && isNameValid) {
      setIsFormComplete(true);
      setInitialsError("");
      setNameError("");
    }

    if (!isRequiredFieldsComplete) {
      setIsFormComplete(false);
      setInitialsError("");
      setNameError("");
    }

    if (!isInitialsValid && initials !== "") {
      setIsFormComplete(false);
      setInitialsError(
        "Please only include letters in your avatar with no spaces or special characters."
      );
    }

    if (!isNameValid && name !== "") {
      setIsFormComplete(false);
      setNameError(
        "Please only include letters, hypens, apostrophes and full stops in your name with no special characters."
      );
    }
  }, [initials, name, setIsFormComplete]);

  const resetInput = () => {
    setToggleInput(false);
    setName("");
    setInitials("");
  };

  const editNameAndInitials = () => {
    if (publicProfile?.name) {
      setName(publicProfile.name);
      setInitials(publicProfile.initials);
    }

    if (!publicProfile?.name) {
      setName("");
      setInitials("");
    }

    setToggleInput(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 2,
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography
            variant={isAccountSettings ? "subtitle1" : "h6"}
            component="h3"
            sx={{ fontWeight: 700 }}
          >
            Full Name<span style={{ color: "red" }}>*</span>
          </Typography>
          <Box sx={{ my: 1 }}>
            This must be your real name (no nicknames) as it will be applied to
            medical records and used instead of your email when you perform
            certain actions such as leaving comments or approving pathways.
          </Box>

          {!toggleInput && isAccountSettings && (
            <Typography
              variant="h5"
              component="h3"
              sx={{ fontWeight: 700, overflowWrap: "anywhere" }}
            >
              {publicProfile?.name}
            </Typography>
          )}

          {(!isAccountSettings || toggleInput) && (
            <TextField
              inputProps={{
                maxLength: 80,
              }}
              size="small"
              error={nameError !== ""}
              fullWidth
              required
              label="Name"
              id="name"
              name="name"
              value={name}
              type="text"
              onChange={(e: any) => {
                setName(e.target.value);
              }}
              helperText={nameError}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography
            variant={isAccountSettings ? "subtitle1" : "h6"}
            component="h3"
            sx={{ fontWeight: 700 }}
          >
            Initials<span style={{ color: "red" }}>*</span>
          </Typography>
          <Box sx={{ marginBottom: 1 }}>
            We will use your initials in the avatar.
          </Box>

          {!toggleInput && isAccountSettings && (
            <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
              {publicProfile?.initials}
            </Typography>
          )}

          {(!isAccountSettings || toggleInput) && (
            <TextField
              size="small"
              error={initialsError !== ""}
              inputProps={{
                maxLength: 3,
              }}
              fullWidth
              required
              label="Initials"
              id="initials"
              name="initials"
              value={initials.toLocaleUpperCase()}
              type="text"
              onChange={(e: any) => {
                setInitials(e.target.value);
              }}
              helperText={initialsError}
            />
          )}
        </Box>
      </Box>

      {isAccountSettings && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 1,
            flexWrap: "wrap-reverse",
            justifyContent: "flex-end",
          }}
        >
          {!toggleInput && (
            <Button
              sx={{
                minWidth: "max-content",
                width: "calc((450px - 100%) * 1000)",
                maxWidth: "100%",
              }}
              variant="outlined"
              onClick={() => editNameAndInitials()}
            >
              Edit
            </Button>
          )}
          {toggleInput && (
            <>
              <Button
                sx={{
                  minWidth: "max-content",
                  width: "calc((450px - 100%) * 1000)",
                  maxWidth: "100%",
                }}
                onClick={() => resetInput()}
              >
                Cancel
              </Button>
              <Button
                startIcon={<SaveIcon />}
                sx={{
                  minWidth: "max-content",
                  width: "calc((450px - 100%) * 1000)",
                  maxWidth: "100%",
                }}
                variant="contained"
                disabled={!isFormComplete}
                onClick={() => {
                  editName(name, initials);
                  resetInput();
                }}
              >
                Save Changes
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UserName;
