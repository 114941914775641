import { CopyToClipboard } from "react-copy-to-clipboard";
import { toastr } from "react-redux-toastr";
import { Button, Icon } from "semantic-ui-react";
import dateToString from "app/utils/dateToString";
import { NodeDecisionAction } from "features/hooks/navigatorReducer";

interface ICopyToClipboardViewProps {
  title: string;
  tenantId: string;
  currentPathway: string;
  decisionSummary: NodeDecisionAction[];
  contentArray: string[];
  isDisabled: boolean;
}

const CopyToClipboardView = ({
  title,
  tenantId,
  currentPathway,
  decisionSummary,
  contentArray,
  isDisabled,
}: ICopyToClipboardViewProps) => {
  let copy = "";

  const localDate = dateToString(new Date());

  const div = document.createElement("div");
  div.innerHTML = contentArray.join(" ");
  const lineItems = div.innerText?.split(/\r?\n/) || [];
  const managementPlan = lineItems
    .filter((x) => x)
    .map((x) => `- ${x}`)
    .join("\n");

  const decisionSummaryContent = decisionSummary
    .map((decision) => {
      const header = `- ${decision.header}`;

      const items = decision.items
        .filter((item) => item.isToggled && item.addToDecisionSummary)
        .map((item) => {
          let groupName = "";
          let fieldName = "";
          let valueName = "";
          if (!item.hasMultipleValues && item.groupName) {
            groupName = item.groupName;
          }
          if (item.fieldName) {
            if (groupName !== "") {
              groupName = groupName.concat(" - ");
            }
            fieldName = item.fieldName;
          }
          if (item.hasMultipleValues && item.valueName) {
            fieldName = fieldName.concat(" - ");
            valueName = item.valueName;
          }

          return `\n - ${groupName}${fieldName}${valueName}`;
        })
        .join("");

      const decisionText = `${header} ${items}`;
      return decisionText;
    })
    .join("\n");

  copy = `
CrossCover Export
Date: ${localDate}
Pathway: ${tenantId} ${currentPathway} Pathway
Decision Summary:\n${decisionSummaryContent}
Outcome: ${title}
Management Plan:\n${managementPlan}`;

  return (
    <>
      <CopyToClipboard text={copy} options={{ format: "text/plain" }}>
        <Button
          onClick={() => {
            toastr.success("Summary Copied to Clipboard", "");
          }}
          fluid
          color="blue"
          disabled={isDisabled}
        >
          <Icon name="copy" />
          Copy Summary to Clipboard
        </Button>
      </CopyToClipboard>
    </>
  );
};

export default CopyToClipboardView;
