import { FireStoreProvider, IDBProvider } from "./FireStoreProvider";

let provider: IDBProvider | null = null;

export const getDb = (): IDBProvider => {
  if (provider === null) {
    provider = new FireStoreProvider();
  }
  return provider;
};
