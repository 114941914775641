import { useState } from "react";

import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Typography from "@mui/material/Typography";

import UserName from "../../user/Settings/UserName";
import Avatar from "../../user/Settings/Avatar";
import useUserPublicProfile from "features/hooks/useUserPublicProfile";

interface UserSettingstProps {
  handleNextStep: () => void;
}

const UserSettingsContainer = ({ handleNextStep }: UserSettingstProps) => {
  const [name, setName] = useState<string>("");
  const [initials, setInitials] = useState<string>("");
  const [avatarColour, setAvatarColour] = useState<string | undefined>();
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);

  const { addUserSettings } = useUserPublicProfile();

  const onSubmit = async () => {
    addUserSettings(name, initials, avatarColour);
    handleNextStep();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 700 }}>
          User Settings
        </Typography>

        <Typography variant="caption">
          <span style={{ color: "red" }}>*</span>=required
        </Typography>
      </Box>

      <UserName
        name={name}
        setName={setName}
        initials={initials}
        setInitials={setInitials}
        setIsFormComplete={setIsFormComplete}
      />

      <Avatar
        initials={initials}
        avatarColour={avatarColour}
        setAvatarColour={setAvatarColour}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <Button
          sx={{
            minWidth: "max-content",
            width: "calc((450px - 100%) * 1000)",
            maxWidth: "100%",
          }}
          endIcon={<ArrowForwardIcon />}
          disabled={!isFormComplete}
          onClick={onSubmit}
          variant="contained"
        >
          Complete and Continue
        </Button>
      </Box>
    </Box>
  );
};

export default UserSettingsContainer;
