import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, useCallback, useEffect, useState } from "react";

import {
  Query,
  Builder,
  Utils as QbUtils,
  Fields,
  JsonLogicTree,
} from "react-awesome-query-builder";
import {
  JsonGroup,
  Config,
  ImmutableTree,
  BuilderProps,
} from "react-awesome-query-builder";
import MuiConfig from "react-awesome-query-builder/lib/config/mui";
import "react-awesome-query-builder/lib/css/styles.css";
import {
  IButtonData,
  IChoicesBoxData,
  IShowHideData,
  NodeBlock,
  IDynamicNode,
} from "../../../../../types/Nodes";
import { getBlocksThatManipulateRules } from "features/NodeEditorV2/NodeEditorUtils";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";

const InitialConfig = MuiConfig;

let config: Config = {
  ...InitialConfig,
  settings: {
    ...InitialConfig.settings,
    showNot: false,
  },
  operators: {
    ...InitialConfig.operators,
    like: {
      label: "Contains",
      labelForFormat: "Contains",
      elasticSearchQueryType: "regexp",
      valueSources: ["value"],
      jsonLogic: "contains",
    },
  },
  fields: {
    node: {
      label: "node",
      tooltip: "Group of fields",
      type: "!struct",
      subfields: {
        score: {
          type: "number",
          preferWidgets: ["number"],
          mainWidgetProps: {
            valueLabel: "node.score",
            valuePlaceholder: "0",
          },
          fieldSettings: {
            min: 0,
          },
        },
      },
    },
  },
};

const NodeEditorPannelAdvanceRules = ({
  dispatch,
  block,
  state,
}: {
  dispatch: Dispatch<DynamicNodeEditorActions>;
  block: NodeBlock | undefined;
  state: IDynamicNode;
}) => {
  const queryValue: JsonGroup = {
    id: QbUtils.uuid(),
    type: "group",
  };

  const createCustomSelectorFields = (): Fields => {
    let customFieldsArray: any[] = [];

    const blocksChoices = getBlocksThatManipulateRules(
      block?.blockId,
      Object.values(state.blocks)
    );

    blocksChoices.forEach((block) => {
      let subfieldsObject = {};

      if (block.blockType === "choices") {
        subfieldsObject = {
          score: {
            type: "number",
            preferWidgets: ["number"],
            fieldSettings: {
              min: 0,
            },
          },
          contains: {
            type: "text",
            label: "Any toggled fields",
            operators: ["like"],
          },
        };

        const fieldsObject = (block.blockData as IChoicesBoxData).fields.reduce(
          (newObject: any, field) => {
            let newField = {};
            let subfieldValues = {};

            field.values.forEach((value) => {
              subfieldValues = {
                ...subfieldValues,
                [value.valueId]: {
                  type: "boolean",
                  operators: ["equal"],
                  label:
                    field.values.length > 1
                      ? `${field.title} - ${value.text}`
                      : field.title,
                  valueSources: ["value"],
                },
              };
            });

            newField = {
              ...newField,
              [field.fieldId]: {
                type: "!struct",
                label: block.blockName,
                subfields: subfieldValues,
              },
            };

            return { ...newObject, ...newField };
          },
          {}
        );

        subfieldsObject = { ...subfieldsObject, ...fieldsObject };
      }
      if (block.blockType === "button") {
        subfieldsObject = {
          [(block.blockData as IButtonData).buttonId]: {
            type: "boolean",
            label: (block.blockData as IButtonData).buttonText,
            mainWidgetProps: {
              valueLabel: `${block.blockId}.${
                (block.blockData as IButtonData).buttonId
              }`,
            },
            excludeOperators: ["proximity"],
          },
        };
      }
      if (block.blockType === "showHide") {
        subfieldsObject = {
          [(block.blockData as IShowHideData).buttonId]: {
            type: "boolean",
            label: "show-hide",
            mainWidgetProps: {
              valueLabel: `${block.blockId}.${
                (block.blockData as IShowHideData).buttonId
              }`,
            },
            excludeOperators: ["proximity"],
          },
        };
      }

      customFieldsArray.push({
        [block.blockId]: {
          label: block.blockName,
          type: "!struct",
          subfields: subfieldsObject,
          valueSources: ["value"],
        },
      });
    });

    const customTypeObject: Fields = customFieldsArray.reduce(
      (object: any, field) => {
        return { ...object, ...field };
      },
      {}
    );

    return customTypeObject;
  };

  const updatedConfigWithFields = {
    ...config,
    fields: { ...config.fields, ...createCustomSelectorFields() },
  };

  const [isAdvancedRulesConditionalOpen, setIsAdvancedRulesConditionalOpen] =
    useState<boolean>(false);
  const [localState, setLocalState] = useState({
    tree: QbUtils.checkTree(
      QbUtils.loadTree(queryValue),
      updatedConfigWithFields
    ),
    config: updatedConfigWithFields,
  });

  const onChange = useCallback(
    (immutableTree: ImmutableTree, config: Config) => {
      let newState = localState;
      newState = {
        tree: immutableTree,
        config: config,
      };
      setLocalState(newState);
      if (block) {
        dispatch({
          type: "UPDATE_RULES",
          payload: {
            blockId: block.blockId,
            ruleOptions: {
              ...block.blockRules,
              isAdvancedRules: true,
              rule: JSON.stringify(
                QbUtils.jsonLogicFormat(newState.tree, newState.config).logic
              ),
            },
          },
        });
      }
    },
    [] // eslint-disable-line
  );

  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div className="query-builder-container" style={{ padding: "10px" }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );

  useEffect(() => {
    if (isAdvancedRulesConditionalOpen && block?.blockRules.rule) {
      let newState = localState;
      let initialTree: ImmutableTree | undefined = undefined;

      const initialLogic: JsonLogicTree =
        block?.blockRules.rule && Object.keys(block?.blockRules.rule).length > 0
          ? JSON.parse(block.blockRules.rule)
          : undefined;

      if (initialLogic) {
        initialTree = QbUtils.checkTree(
          QbUtils.loadFromJsonLogic(
            initialLogic,
            updatedConfigWithFields
          ) as ImmutableTree,
          updatedConfigWithFields
        );

        newState = {
          tree: initialTree,
          config: updatedConfigWithFields,
        };
      }

      setLocalState(newState);
    }
  }, [isAdvancedRulesConditionalOpen]); // eslint-disable-line

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setIsAdvancedRulesConditionalOpen(true)}
      >
        {block?.blockRules.isAdvancedRules
          ? "Show Advanced Rules"
          : "Advanced Rules"}
      </Button>
      <Modal
        open={isAdvancedRulesConditionalOpen}
        onClose={() => setIsAdvancedRulesConditionalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60vw",
            height: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            overflowY: "scroll",
            maxHeight: "80vh",
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Advanced Rules Conditional
          </Typography>
          <div>
            <Query
              {...localState.config}
              value={localState.tree}
              onChange={onChange}
              renderBuilder={renderBuilder}
            />
            <div className="query-builder-result">
              <div>
                JsonLogic:{" "}
                <pre>
                  {JSON.stringify(
                    QbUtils.jsonLogicFormat(localState.tree, localState.config)
                  )}
                </pre>
              </div>
            </div>
            <div className="query-builder-result">
              <div>
                Human readable rule:{" "}
                <pre>
                  {JSON.stringify(
                    QbUtils.queryString(
                      localState.tree,
                      localState.config,
                      true
                    )
                  )}
                </pre>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default NodeEditorPannelAdvanceRules;
