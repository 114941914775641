import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {
  Button,
  Icon,
  Modal,
  Form,
  Checkbox,
  Message,
  Dropdown,
  DropdownProps,
  Popup,
} from "semantic-ui-react";

import DropZone from "features/documentLibrary/DropZone";
import { toastr } from "react-redux-toastr";

import { IGalleryImage } from "../../../types/Gallery";
import { getModuleTitles, onUploadFile } from "./helpers/galleryHelpers";
import ImageGroupSelector from "./ImageGroupSelector";
import ImageGroupManager from "./ImageGroupManager";
import { IModule } from "../../../types/CrossCover";

interface LoadGalleryImageProps {
  galleryModules: IModule[];
  galleryItem?: IGalleryImage;
  saveButtonText: string;
  closeButtonText: string;
  title: string;
}

const LoadGalleryImage = ({
  galleryModules,
  galleryItem,
  saveButtonText,
  closeButtonText,
  title,
}: LoadGalleryImageProps) => {
  const [error, setError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [uploadFile, setUploadFile] = useState<any>("");
  const [fileTitle, setFileTitle] = useState<string>("");
  const [isImgHidden, setIsImgHidden] = useState<boolean>(false);
  const [imageModules, setImageModules] = useState<string[]>([]);
  const [imageGroups, setImageGroups] = useState<Record<string, string[]>>({});

  const db = firebase.firestore();

  useEffect(() => {
    // all fields except group names
    let isAllFieldsComplete =
      uploadFile !== "" && fileTitle.trim() !== "" && imageModules.length > 0;

    if (isAllFieldsComplete) {
      setError(false);
    }
  }, [fileTitle, imageModules.length, uploadFile]);

  const onEditForm = () => {
    if (galleryItem) {
      setLoading(false);
      setError(false);
      setOpenModal(true);

      setIsImgHidden(galleryItem.isHidden);
      setFileTitle(galleryItem.imageName);

      if (galleryItem.groups) {
        setImageGroups(
          galleryItem.groups as unknown as Record<string, string[]>
        );
      }

      if (galleryItem.modules) {
        setImageModules(galleryItem.modules);
      }
    }
  };

  const saveFile = async () => {
    // all fields except group names
    const isFormIncomplete =
      (!galleryItem && uploadFile === "") ||
      fileTitle.trim() === "" ||
      imageModules.length === 0;

    if (isFormIncomplete) {
      setError(true);
      return;
    }
    setLoading(true);

    let fileDownloadURL = galleryItem
      ? galleryItem.imageURL
      : await onUploadFile(uploadFile);

    const file = {
      groups: imageGroups,
      modules: imageModules,
      imageName: fileTitle.trim(),
      imageURL: fileDownloadURL,
      isHidden: isImgHidden || false,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: galleryItem
        ? galleryItem.createdAt
        : firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (galleryItem) {
      await db.collection("gallery").doc(galleryItem.id).update(file);
    }

    if (!galleryItem) {
      await db.collection("gallery").add(file);
    }

    setLoading(false);
    resetState();
    setOpenModal(false);
    toastr.success("File Saved", "Your file has been created.");
  };

  const resetState = () => {
    setUploadFile("");
    setFileTitle("");
    setIsImgHidden(false);
    setImageModules([]);
    setImageGroups({});
    setError(false);
  };

  const addNewGroupsToImage = (moduleName: string, groups: string[]) => {
    const groupMap = { ...imageGroups, [moduleName]: [...groups] };

    setImageGroups(groupMap);
  };

  return (
    <>
      {galleryItem ? (
        <Popup
          content="Edit"
          trigger={
            <Button
              icon="write"
              color="teal"
              onClick={() => {
                onEditForm();
              }}
            />
          }
        />
      ) : (
        <Button
          color={"green"}
          onClick={() => {
            resetState();
            setOpenModal(true);
          }}
        >
          <Icon name="image" /> Upload a new Image
        </Button>
      )}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>{title}</Modal.Header>

        <Modal.Content>
          <Form error={error}>
            {error && (
              <Message
                error={error}
                header="Action Forbidden"
                content={`You cannot upload an image with empty fields. The image must have a title and at least one module`}
              />
            )}
            {!galleryItem && !uploadFile && (
              <DropZone
                filterBy={"IMAGE"}
                setUploadFile={setUploadFile}
                setFileName={setFileTitle}
              />
            )}
            <Form.Field>
              <label>Manage Image</label>
              <Checkbox
                label="Hide Image"
                onClick={() => setIsImgHidden(!isImgHidden)}
                checked={isImgHidden}
                size="small"
              />
            </Form.Field>
            <Form.Field>
              <label>Choose Image Title</label>
              <input
                maxLength={120}
                onChange={(e) => setFileTitle(e.target.value)}
                value={fileTitle}
              ></input>
            </Form.Field>
            <Form.Field>
              <label>Select the modules that can use this image</label>
              <Dropdown
                placeholder="Modules"
                value={imageModules}
                onChange={(e, modules: DropdownProps) =>
                  setImageModules(modules.value as string[])
                }
                search
                multiple={true}
                selection
                options={getModuleTitles(galleryModules)}
              />
            </Form.Field>
            <Form.Field>
              {imageModules.length > 0 && (
                <label>
                  Add groups to the
                  {imageModules.length === 1 ? " module" : " modules"}{" "}
                  (optional)
                </label>
              )}

              {imageModules.map((module) => (
                <div
                  key={module}
                  style={{
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                    margin: 4,
                  }}
                >
                  <label style={{ width: "70px" }}>{module}</label>

                  <ImageGroupSelector
                    galleryModules={galleryModules}
                    imageGroups={imageGroups}
                    addGroupsToImage={addNewGroupsToImage}
                    moduleName={module}
                  />

                  <ImageGroupManager
                    galleryModules={galleryModules}
                    key={`manager${module}`}
                    moduleName={module}
                  />
                </div>
              ))}
            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            loading={loading}
            content={saveButtonText}
            positive
            onClick={() => saveFile()}
          />
          <Button
            content={closeButtonText}
            negative
            onClick={() => setOpenModal(false)}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default LoadGalleryImage;
