import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import useWidgetTemplates from "features/hooks/useWidgetTemplates";
import { Timestamp } from "firebase/firestore";
import { useState } from "react";
import { IWidgetTemplate } from "../../../../../types/WidgetTemplates";
import {
  BlockTypes,
  IBlockOptions,
  IBlockRules,
  IDynamicNode,
  NodeBlock,
  NodeBlockData,
} from "../../../../../types/Nodes";
import NodeEditor from "../../NodeEditorV2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { PathwayActions } from "features/hooks/navigatorReducer";
import {
  DynamicNodeEditorActions,
  DynamicNodeEditorState,
} from "../../dynamicNodeEditorReducer";
import {
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import { replaceWidgetTemplateIds } from "./widgetTemplatesHelper";
import ConfirmationModal from "app/components/ConfirmationModal";
import { getDb } from "app/data/DbProvider";
import { toastr } from "react-redux-toastr";
import useUser from "features/hooks/useUser";
import { Tooltip } from "@mui/material";
import {
  isQuestionBlock,
  isStartBackBlock,
} from "features/pathwaybuilder/utils/pathwayHelperV2";

enum SortBy {
  ascTitle,
  descTitle,
  descLastEdit,
  ascLastEdit,
}

interface IWidgetTemplatesPanelProps {
  draggingId: string;
  setShowDroppingPositions: (value: boolean) => void;
  addBlockToEditor: (
    blockType: BlockTypes,
    data: NodeBlockData,
    row?: number,
    column?: number,
    blockRules?: IBlockRules,
    blockName?: string,
    blockOptions?: IBlockOptions,
    blockId?: string
  ) => NodeBlock | undefined;
  editorState: DynamicNodeEditorState;
}

const WidgetTemplatesPanel = ({
  draggingId,
  setShowDroppingPositions,
  addBlockToEditor,
  editorState,
}: IWidgetTemplatesPanelProps) => {
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.ascTitle);
  const [templatePreviewOpen, setTemplatePreviewOpen] = useState(false);
  const [previewDynamicNode, setPreviewDynamicNode] = useState<IDynamicNode>();
  const [templateToDelete, setTemplateToDelete] = useState<IWidgetTemplate>();

  const { widgetTemplates } = useWidgetTemplates();
  const { activeTenantId } = useUser();

  const sortComparator = (a: IWidgetTemplate, b: IWidgetTemplate) => {
    switch (sortBy) {
      case SortBy.descTitle:
        return b.title.toLowerCase().localeCompare(a.title.toLowerCase());
      case SortBy.descLastEdit:
        return (
          (b.updatedAt as Timestamp).toDate().getTime() -
          (a.updatedAt as Timestamp).toDate().getTime()
        );
      case SortBy.ascLastEdit:
        return (
          (a.updatedAt as Timestamp).toDate().getTime() -
          (b.updatedAt as Timestamp).toDate().getTime()
        );
      default:
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    }
  };

  const onSortByChange = (event: SelectChangeEvent) => {
    setSortBy(Number(event.target.value));
  };

  const handleDeleteTemplate = function (widgetTemplate: IWidgetTemplate) {
    setTemplateToDelete(widgetTemplate);
  };

  const deleteTemplate = async () => {
    if (!templateToDelete) {
      return;
    }

    try {
      const db = getDb();
      const collectionPath = `${activeTenantId}/templates/userDefinedWidgetTemplates/`;
      db.deleteDocument(collectionPath, templateToDelete.id);
      toastr.success("Success", "Template Deleted");
    } catch (error: any) {
      toastr.error("Error", "Error deleteing template");
    } finally {
      setTemplateToDelete(undefined);
    }

    setTemplateToDelete(undefined);
  };

  const handleOpenTemplatePreview = (widgetTemplate: IWidgetTemplate) => {
    const dynamicNode: IDynamicNode = {
      ...widgetTemplate,
      nodeProperties: {
        isEndNodeFunctionalityEnabled: false,
        isStartNode: false,
      },
      nodeOrigin: {
        tenantId: "",
        pathwayId: "",
        createdBy: "",
        createdAt: 0,
      },
    };
    setPreviewDynamicNode(dynamicNode);
    setTemplatePreviewOpen(true);
  };

  const handleAddTemplateToEditor = (widgetTemplate: IWidgetTemplate) => {
    let maxRowIndex = 0;
    Object.values(editorState.node.blocks).forEach((block) => {
      const rowIndex = block.position.row;
      maxRowIndex = Math.max(maxRowIndex, rowIndex);
    });

    const newWidgetTemplate = replaceWidgetTemplateIds(widgetTemplate);
    Object.entries(newWidgetTemplate.blocks).forEach(([blockId, block]) => {
      addBlockToEditor(
        block.blockType,
        block.blockData,
        block.position.row + maxRowIndex + 1,
        block.position.column,
        block.blockRules,
        undefined,
        block.blockOptions,
        blockId
      );
    });
    toastr.success(
      "Template Added",
      `Template ${widgetTemplate.title} added to node.`
    );
  };

  const handleCloseTemplatePreview = () => {
    setTemplatePreviewOpen(false);
    setPreviewDynamicNode(undefined);
  };

  const handleMouseEnterPanel = () => {
    // if (draggingId === "") {
    //   setShowDroppingPositions(true);
    // }
  };

  const handleMouseLeavePanel = () => {
    // if (draggingId === "") {
    //   setShowDroppingPositions(false);
    // }
  };

  const renderClone = (
    provided: DraggableProvided,
    _snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
  ) => {
    const draggingTemplate = widgetTemplates.find(
      (widgetTemplate) => widgetTemplate.id === rubric.draggableId
    );
    return (
      <span
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        {draggingTemplate && (
          <Paper
            sx={{
              bgcolor: "white",
              width: "100%",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography fontWeight={600}>{draggingTemplate.title}</Typography>
            </Box>
            <IconButton>
              <VisibilityIcon sx={{ color: "secondary.light" }} />
            </IconButton>
          </Paper>
        )}
      </span>
    );
  };

  /**
   * Validate if a widget template can be added to the node.
   * A node cannot contain two question blocks or two STarTBack blocks.
   * @param widgetTemplate The widget template to validate.
   */
  const validateWidgetTemplate = (widgetTemplate: IWidgetTemplate) => {
    let editorHasQuestion = false;
    let widgetTemplateHasQuestion = false;
    let editorHasSTarTBack = false;
    let widgetTemplateHasSTarTBack = false;

    Object.values(editorState.node.blocks).forEach((block: NodeBlock) => {
      if (isQuestionBlock(block)) editorHasQuestion = true;
      if (isStartBackBlock(block)) editorHasSTarTBack = true;
    });

    Object.values(widgetTemplate.blocks).forEach((block: NodeBlock) => {
      if (isQuestionBlock(block)) widgetTemplateHasQuestion = true;
      if (isStartBackBlock(block)) widgetTemplateHasSTarTBack = true;
    });

    if (editorHasQuestion && widgetTemplateHasQuestion) return false;
    if (editorHasSTarTBack && widgetTemplateHasSTarTBack) return false;

    return true;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Select
          value={sortBy.toString()}
          onChange={onSortByChange}
          variant="standard"
          disableUnderline
        >
          <MenuItem value={SortBy.ascTitle.toString()}>A-Z</MenuItem>
          <MenuItem value={SortBy.descTitle.toString()}>Z-A</MenuItem>
          <MenuItem value={SortBy.descLastEdit.toString()}>Newest</MenuItem>
          <MenuItem value={SortBy.ascLastEdit.toString()}>Oldest</MenuItem>
        </Select>
      </Box>
      <Droppable
        droppableId="widget-template-library"
        isDropDisabled={true}
        renderClone={renderClone}
      >
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            onMouseEnter={handleMouseEnterPanel}
            onMouseLeave={handleMouseLeavePanel}
          >
            {widgetTemplates.sort(sortComparator).map((widgetTemplate, index) =>
              widgetTemplate.id === snapshot.draggingFromThisWith ? (
                <ClonedTemplate
                  key={widgetTemplate.id}
                  title={widgetTemplate.title}
                />
              ) : (
                <Draggable
                  key={widgetTemplate.id}
                  draggableId={widgetTemplate.id}
                  index={index}
                  isDragDisabled={true}
                >
                  {(provided) => (
                    <span
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Paper
                        sx={{
                          bgcolor: "white",
                          width: "100%",
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          {!validateWidgetTemplate(widgetTemplate) ? (
                            <Tooltip title="Your Node currently contains a question block or a STarTBack block. Only one question block and one STarTBack block can be added to a Node. Before you can add your template to your Node. Please delete your existing question block, STarTBack block, or both if they exist.">
                              <AddIcon
                                sx={{
                                  color: "secondary.light",
                                  p: "0",
                                  m: "0",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <IconButton
                              sx={{
                                p: "0",
                                m: "0",
                              }}
                              onClick={() =>
                                handleAddTemplateToEditor(widgetTemplate)
                              }
                            >
                              <AddIcon sx={{ color: "secondary.light" }} />
                            </IconButton>
                          )}
                          <IconButton
                            sx={{
                              p: "0",
                              m: "0",
                            }}
                            onClick={() =>
                              handleOpenTemplatePreview(widgetTemplate)
                            }
                          >
                            <VisibilityIcon sx={{ color: "secondary.light" }} />
                          </IconButton>
                        </Box>
                        <Box sx={{ p: 0.5 }}>
                          <Typography
                            fontWeight={600}
                            sx={{ wordBreak: "break-all" }}
                          >
                            {widgetTemplate.title}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          {/*  */}
                          <ConfirmationModal
                            title={"Delete Template"}
                            message={`Please confirm that you want to delete this template by typing its title: ${widgetTemplate.title}`}
                            label={"Template Title"}
                            confirmText={widgetTemplate.title}
                            buttonText={"Delete Template"}
                            triggerButtonText={""}
                            buttonColor={"red"}
                            onConfirm={deleteTemplate}
                            buttonIcon="delete"
                            customTrigger={
                              <IconButton
                                sx={{
                                  p: "0",
                                  m: "0",
                                }}
                                onClick={() =>
                                  handleDeleteTemplate(widgetTemplate)
                                }
                              >
                                <DeleteIcon sx={{ color: "error.main" }} />
                              </IconButton>
                            }
                          />
                        </Box>
                      </Paper>
                    </span>
                  )}
                </Draggable>
              )
            )}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
      {previewDynamicNode && (
        <Dialog
          open={templatePreviewOpen}
          onClose={handleCloseTemplatePreview}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                Template Preview - {previewDynamicNode.title}
              </Typography>
              <IconButton onClick={handleCloseTemplatePreview}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <NodeEditor
              editorState={{
                node: previewDynamicNode,
                isDirty: false,
                editorMode: "READONLY",
              }}
              pathwayNodes={[]}
              tenantId={""}
              pathwayId={""}
              onSave={() => {}}
              pathwayNavigatorDispatch={(_value: PathwayActions) => {}}
              nodeContext={{ fields: [] }}
              decisionSummary={[]}
              viewType={"view"}
              route={[]}
              setViewType={(_viewType: "view" | "edit") => {}}
              showHeader={false}
              showPreviewEditToggle={false}
              showDecisionSummary={false}
              showNodeInfo={false}
              showNodeEditorSectionHeader={false}
              containerStyles={{
                height: "auto",
                borderBottom: "none",
              }}
              editorDispatch={(_value: DynamicNodeEditorActions) => {}}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

interface IClonedTemplateProps {
  title: string;
}

const ClonedTemplate = ({ title }: IClonedTemplateProps) => {
  return (
    <Paper
      sx={{
        bgcolor: "white",
        width: "100%",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 2,
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography fontWeight={600}>{title}</Typography>
      </Box>
      <IconButton>
        <VisibilityIcon sx={{ color: "secondary.light" }} />
      </IconButton>
    </Paper>
  );
};

export default WidgetTemplatesPanel;
