import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";

import { memo, useState } from "react";
import { IDynamicNode, INode } from "../../../types/Nodes";
import DataObjectIcon from "@mui/icons-material/DataObject";
import SuperAdminGate from "app/common/EditConsole/SuperAdminGate";

interface INodeViewJsonProps {
  node?: INode | IDynamicNode;
  inverse?: boolean;
  title?: string;
}

const PrettyPrintJson = memo(({ data }: { data: any }) => (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
));

const NodeViewJson = ({ node, inverse, title }: INodeViewJsonProps) => {
  const [open, setOpen] = useState(false);

  //const [nodeContent, setNodeContent] = useState<INode | IDynamicNode>();

  // const { activeTenantId } = useUser();
  // const { tenantConfiguration } = useTenants();

  // useEffect(() => {
  //   const load = async () => {
  //     const db = getDb();
  //     if (node) {
  //       setNodeContent(node);
  //     }

  //     if (pathwayId && collectionId && nodeId) {
  //       const activeNode = await db.getDoc<INode>(
  //         getPathwayPath(activeTenantId, pathwayId, collectionId),
  //         nodeId
  //       );
  //       setNodeContent(activeNode);
  //     }
  //   };
  //   load();
  // }, [
  //   activeTenantId,
  //   collectionId,
  //   node,
  //   nodeId,
  //   pathwayId,
  //   tenantConfiguration?.editorVersion,
  // ]);

  return (
    <>
      <SuperAdminGate>
        <Button onClick={() => setOpen(true)}>
          {title}
          <Tooltip title={node?.id}>
            <DataObjectIcon
              sx={{ color: inverse ? "primary.contrastText" : "inherit" }}
            />
          </Tooltip>
        </Button>
        <Dialog
          open={open}
          fullWidth={true}
          onClose={() => setOpen(false)}
          maxWidth="xl"
        >
          <DialogTitle>Node JSON</DialogTitle>
          <DialogContent>
            <PrettyPrintJson data={node} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </SuperAdminGate>
    </>
  );
};

export default NodeViewJson;
