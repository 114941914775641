import { useCallback } from "react";
import Dropzone from "react-dropzone";
import { Icon, Header } from "semantic-ui-react";

import { FilterCriteria } from "./docLibTypes/DocumentLibraryTypes";

interface DropZoneProps {
  filterBy?: FilterCriteria;
  setFileSize?: (value: number) => void;
  setUploadFile: (value: string) => void;
  setFileName: (value: string) => void;
  setFileTitle?: (value: string) => void;
}

const DropZone = ({
  filterBy,
  setFileSize,
  setUploadFile,
  setFileName,
  setFileTitle,
}: DropZoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
        if (setFileSize) {
          setFileSize(acceptedFiles[0].size / Math.pow(10, 6));
        }

        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          setUploadFile(file);
          setFileName(file.name);
          if (setFileTitle) {
            setFileTitle(file.name);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [setFileName, setFileSize, setFileTitle, setUploadFile]
  );

  return (
    <>
      {filterBy === "IMAGE" ? (
        <Dropzone
          multiple={false}
          onDrop={onDrop}
          accept={{
            "image/*": [],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <div
                style={{ padding: 40, textAlign: "center" }}
                {...getRootProps({ refKey: "innerref" })}
              >
                <Icon name="upload" size="huge" />
                <Header content="Drop an image here or click to select an image" />
              </div>
              <input {...getInputProps()} />
            </>
          )}
        </Dropzone>
      ) : (
        <Dropzone multiple={false} onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <>
              <div
                style={{ padding: 40, textAlign: "center" }}
                {...getRootProps({ refKey: "innerref" })}
              >
                <Icon name="upload" size="huge" />
                <Header content="Drop a file here or click to select a file" />
              </div>
              <input {...getInputProps()} />
            </>
          )}
        </Dropzone>
      )}
    </>
  );
};

export default DropZone;
