import SafeHtml from "app/components/SafeHtml";
import { INodeLogic, ILogicField } from "../../../../types/Nodes";
import React, { Dispatch } from "react";
import { Box, Button, Paper } from "@mui/material";
import Segment from "app/components/SemanticUIMirrors/Segement";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import NodeLogicScore from "./NodeLogicScore";
import { INodeContext, PathwayActions } from "features/hooks/navigatorReducer";

const NodeLogicField = ({
  groupId,
  groupName,
  logicField,
  nodeContext,
  showScores,
  allowHtml,
  dispatch,
  alwaysShowScores,
}: {
  groupId: string;
  groupName: string;
  logicField: ILogicField;
  nodeContext: INodeContext;
  showScores: boolean;
  allowHtml?: boolean;
  dispatch: Dispatch<PathwayActions>;
  alwaysShowScores?: boolean;
}) => {
  const isFieldChecked = nodeContext.fields.some(
    (x) =>
      x.name === logicField.title &&
      x.namespace === groupId &&
      x.value === logicField.score
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Button
        variant={isFieldChecked ? "contained" : "outlined"}
        onClick={() =>
          !isFieldChecked
            ? dispatch({
                type: "NODE_LOGIC_ON",
                payload: {
                  namespace: groupId,
                  groupName: groupName,
                  name: logicField.title,
                  value: logicField.score,
                  order: 1,
                },
              })
            : dispatch({
                type: "NODE_LOGIC_OFF",
                payload: {
                  namespace: groupId,
                  groupName: groupName,
                  name: logicField.title,
                  value: logicField.score,
                  order: 1,
                },
              })
        }
        sx={{ mr: 2, borderRadius: 0 }}
      >
        {isFieldChecked ? (
          <CheckBoxOutlinedIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </Button>

      <Button
        sx={{ flex: 1, borderRadius: 0 }}
        variant={isFieldChecked ? "contained" : "outlined"}
        onClick={() =>
          !isFieldChecked
            ? dispatch({
                type: "NODE_LOGIC_ON",
                payload: {
                  namespace: groupId,
                  groupName: groupName,
                  name: logicField.title,
                  value: logicField.score,
                  order: 1,
                },
              })
            : dispatch({
                type: "NODE_LOGIC_OFF",
                payload: {
                  namespace: groupId,
                  groupName: groupName,
                  name: logicField.title,
                  value: logicField.score,
                  order: 1,
                },
              })
        }
      >
        <SafeHtml html={logicField.title} allowHtml={allowHtml} />
        <b>
          {(alwaysShowScores || showScores) &&
            `${parseInt(logicField.score) >= 0 ? " +" : " "}${
              logicField.score
            }`}
        </b>
      </Button>
    </Box>
  );
};

interface INodeLogicViewProps {
  logic: INodeLogic;
  dispatch: Dispatch<PathwayActions>;
  nodeContext: INodeContext;
  allowHtml?: boolean;
  alwaysShowScores?: boolean;
}

const NodeLogicView = ({
  dispatch,
  logic,
  nodeContext,
  allowHtml,
  alwaysShowScores,
}: INodeLogicViewProps) => {
  if (
    Array.isArray(logic.groups) &&
    (logic.groups.length === 0 ||
      logic.groups.every((i) => i.fields.length < 1))
  ) {
    return <></>;
  }
  return (
    <Paper sx={{ p: 2 }}>
      <Segment>
        <b className="label">Select all that apply:</b>
      </Segment>
      {logic.groups?.map((group, index) => (
        <React.Fragment key={group.groupId + index}>
          <Box sx={{ fontWeight: "bold", my: 1 }}>
            <SafeHtml html={group.title} allowHtml={allowHtml}></SafeHtml>
          </Box>
          {group.fields.map((field, fieldIndex) => (
            <NodeLogicField
              showScores={logic.showScores}
              key={fieldIndex}
              groupId={group.groupId}
              groupName={group.title}
              logicField={field}
              nodeContext={nodeContext}
              dispatch={dispatch}
              allowHtml={allowHtml}
              alwaysShowScores={alwaysShowScores}
            />
          ))}
        </React.Fragment>
      ))}
      {(alwaysShowScores || logic.showScores) && (
        <NodeLogicScore nodeContext={nodeContext} />
      )}
    </Paper>
  );
};

export default NodeLogicView;
