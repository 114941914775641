import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material";
import { keyframes } from "@mui/system";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { SvgIconProps } from "@mui/material/SvgIcon";

type LoadingButtonProps = ButtonProps & {
  icon?: React.ReactElement<SvgIconProps> | undefined;
  isLoading: boolean;
  buttonText?: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingButton = (props: LoadingButtonProps) => {
  const { icon, isLoading, buttonText, ...filteredProps } = props;

  const isIconDisplayed = props.icon !== undefined && !props.isLoading;
  return (
    <>
      <Button sx={{ gap: 1 }} {...filteredProps}>
        {isIconDisplayed && props.icon}
        {props.isLoading === true && <AutorenewIcon />}
        {props.buttonText}
        {props.children}
      </Button>
    </>
  );
};

export default styled(
  LoadingButton,
  {}
)((props) => ({
  "& .MuiSvgIcon-root": {
    animation: props.isLoading ? `${spin} 1s infinite linear` : "none",
  },
}));
