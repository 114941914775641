import { getApp, initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";

export function configure() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  initializeApp(firebaseConfig);
  initializeFirestore(getApp(), {
    experimentalAutoDetectLongPolling: true,
  });

  const app = firebase.initializeApp(firebaseConfig);
  const firestore = firebase.firestore();

  firestore.settings({
    experimentalAutoDetectLongPolling: true,
  });

  if (process.env.REACT_APP_USE_FIRESTORE_EMULATOR) {
    console.log("🖥️ - Using the Firestore Emulator");
    firestore.useEmulator("localhost", 8080);
    connectFirestoreEmulator(getFirestore(), "localhost", 8080);
  }

  if (process.env.REACT_APP_USE_FUNCTIONS_EMULATOR) {
    console.log("🖥️ - Using the Functions Emulator");
    firebase.functions().useEmulator("localhost", 5001);
  }

  if (process.env.REACT_APP_USE_AUTH_EMULATOR) {
    console.log("🖥️ - Using the Auth Emulator");
    firebase.auth().useEmulator("http://localhost:9099");
  }
  return app;
}

const exportObj = { configure };

export default exportObj;
