import usePathwayIssues from "features/hooks/usePathwayIssues";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Typography } from "@mui/material";
import { Icon } from "semantic-ui-react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { toastr } from "react-redux-toastr";
import LoadingButton from "app/components/LoadingButton";
import { INode, IDynamicNode } from "../../../types/Nodes";

const ReportSchema = yup.object({
  reporterName: yup.string().required().label("Your name"),
  reporterEmail: yup.string().required().email().label("Your email"),
  issueSummary: yup
    .string()
    .required()
    .label("A summary of the issue you are reporting"),
  issueDetails: yup
    .string()
    .required()
    .label("A description of the issue you are reporting"),
});

const ReportIssueForm = ({
  closeModal,
  pathwayId,
  node,
}: {
  closeModal: () => void;
  pathwayId: string;
  node: INode | IDynamicNode;
}) => {
  const { loading, createIssue } = usePathwayIssues();

  const onSubmit = async (data: {
    reporterName: string;
    reporterEmail: string;
    issueSummary: string;
    issueDetails: string;
  }) => {
    try {
      await createIssue(
        data.reporterName,
        data.reporterEmail,
        data.issueSummary,
        data.issueDetails,
        pathwayId,
        node
      );
      closeModal();
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };

  return (
    <div>
      <FormContainer
        defaultValues={{
          reporterName: "",
          reporterEmail: "",
          issueSummary: "",
          issueDetails: "",
        }}
        onSuccess={(data) => onSubmit(data)}
        resolver={yupResolver(ReportSchema)}
      >
        <Box
          sx={{
            bgcolor: "#2185d0",
            color: "white",
            display: "flex",
            p: 2,
            mb: 2,
            borderRadius: "5px",
          }}
        >
          <ul>
            <li>
              This Form gives you the opportunity to query the content of a
              Pathway
            </li>
            <li>
              The Chief Editor for your Trust will receive an email with your
              Reported Issue
            </li>
            <li>
              This is an important part of clinical governance and you are
              encouraged to do this
            </li>
            <li>
              Front line staff should report any issues or potential clinical
              safety concerns with a Pathway if they are found
            </li>
          </ul>
          <div style={{ flexGrow: 1 }}></div>
          <div>
            <Icon name="doctor" size="huge"></Icon>
          </div>
        </Box>
        <Box
          sx={{
            bgcolor: "#21ba45",
            color: "white",
            display: "flex",
            p: 2,
            mb: 2,
            borderRadius: "5px",
          }}
        >
          <Typography>This is for:</Typography>

          <ul>
            <li>
              Raising an issue with the accuracy of the clinical content in a
              Pathway
            </li>
            <li>Raising an issue with Pathway design and flow</li>
          </ul>
          <div style={{ flexGrow: 1 }}></div>
          <Icon name="check" size="huge"></Icon>
        </Box>
        <Box
          sx={{
            bgcolor: "#f2711c",
            color: "white",
            display: "flex",
            p: 2,
            mb: 2,
            borderRadius: "5px",
          }}
        >
          <Typography>This is NOT for:</Typography>

          <ul>
            <li>
              Obtaining clinical advice for a specific patient. Contact the on
              call team for urgent advice if indicated
            </li>
            <li>
              Reporting a bug with the application. Bugs should be reported via
              email to support@orthopathway.com
            </li>
          </ul>
          <div style={{ flexGrow: 1 }}></div>
          <Icon name="close" size="huge"></Icon>
        </Box>
        <Box
          sx={{
            bgcolor: "#db2828",
            color: "white",
            display: "flex",

            p: 2,
            mb: 2,
            borderRadius: "5px",
          }}
        >
          <Typography>
            You MUST NOT enter any patient information in the fields below
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          <Icon name="warning sign" size="huge" />
        </Box>
        <Typography>You are reporting an issue with the Pathway</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
          <TextFieldElement
            name="reporterName"
            type="text"
            placeholder="Type in your Name"
            // onChange={(e) => setReporterName(e.target.value)}
            // value={reporterName}
            sx={{ mb: 1 }}
          />

          <TextFieldElement
            name="reporterEmail"
            type="email"
            placeholder="Type in your NHS Email address so the Chief Editor can reply to your Reported Issue"
            // onChange={(e) => setReporterEmail(e.target.value)}
            // value={reporterEmail}
            sx={{ mb: 1 }}
          />
          {/* <SelectElement
            name="issueCategory"
            options={[
              {
                label: "Pathway Flow",
                id: "Pathway Flow",
              },
              {
                label: "Missing Information (Pathway)",
                id: "Missing Information (Pathway)",
              },
              {
                label: "Missing Information (Node)",
                id: "Missing Information (Node)",
              },
            ]}
            required
          /> */}
          <TextFieldElement
            name="issueSummary"
            type="text"
            placeholder="Provide a summary of the issue (50 characters max)"
            inputProps={{ maxLength: 50 }}
            // onChange={(e) => setReporterEmail(e.target.value)}
            // value={reporterEmail}
            sx={{ mb: 1 }}
          />
          <TextFieldElement
            multiline
            minRows={5}
            placeholder="Explain the issue. Please enter the name of the Pathway +/- the name of the node with an issue. "
            name={"issueDetails"}
          />
          {/* {error && (
            <Label basic color="red">
              {error}
            </Label>
          )} */}

          <LoadingButton
            type="submit"
            isLoading={loading}
            sx={{ mt: 2 }}
            buttonText="Report Issue"
            variant="outlined"
          />
        </Box>
      </FormContainer>
    </div>
  );
};

export default ReportIssueForm;
