import { Box } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ColorContrastChecker = ({
  color1,
  color2,
}: {
  color1: string | undefined;
  color2: string | undefined;
}) => {
  if (!color1 || !color2) {
    return <></>;
  }
  const splitRGB = (rgb: string) => {
    let c = rgb.slice(rgb.indexOf("(") + 1, rgb.indexOf(")")).split(",");

    return {
      r: +c[0],
      g: +c[1],
      b: +c[2],
    };
  };
  const calculateLuminance = (r: number, g: number, b: number) => {
    var a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };

  const color1Object = splitRGB(color1);
  const color2Object = splitRGB(color2);

  const color1luminance = calculateLuminance(
    color1Object.r,
    color1Object.g,
    color1Object.b
  );
  const color2luminance = calculateLuminance(
    color2Object.r,
    color2Object.g,
    color2Object.b
  );

  const ratio =
    color1luminance > color2luminance
      ? (color2luminance + 0.05) / (color1luminance + 0.05)
      : (color1luminance + 0.05) / (color2luminance + 0.05);

  let isContrastAccepted = ratio < 1 / 3 && ratio < 1 / 4.5 && ratio < 1 / 7;

  if (!isContrastAccepted) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 1,
          border: "2px solid #ff8000",
          borderRadius: "5px",
          background: "#ffe6cc",
          padding: 1,
        }}
      >
        <WarningAmberIcon sx={{ marginRight: "10px" }} />
        <p>
          The contrast between the background colour and the font colour is{" "}
          <strong>NOT </strong>
          high enough to meet accessibility standards.
        </p>
      </Box>
    );
  }
  return <></>;
};

export default ColorContrastChecker;
