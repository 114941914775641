import { useCallback, useEffect, useMemo } from "react";
import { IProfile } from "../../../types/User";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { IModule, IReferenceDocuments } from "../../../types/CrossCover";
import { getDb } from "../data/DbProvider";
import {
  ITenant,
  ITenantConfiguration,
  ITenantSettings,
} from "../../../types/Tenants";
import { toastr } from "react-redux-toastr";
import throttle from "lodash/throttle";
import { useCrossCoverDispatch, useCrossCoverSelector } from "app/store/hooks";
import { updateFirebaseUser, updateUserProfile } from "app/store/userSlice";
import { updateTenantModules } from "app/store/moduleSlice";
import {
  updateActiveTenant,
  updateTenants,
  updateUserTenants,
  updateTenantConfiguration,
  updateTenantSettings,
} from "app/store/tenantSlice";
import {
  updateProviderError,
  updateProviderLoaded,
  updateReferenceDocuments,
} from "app/store/crossCoverSlice";
import { resetState } from "app/store/store";
import { setEditorVersion } from "features/pathwaybuilder/utils/editorConverters";

declare global {
  interface Window {
    lastUserActivityTime: number;
    inactiveTimerId?: ReturnType<typeof setInterval>;
    inactiveWarningOpen: boolean;
  }
}

const MINUTE_IN_MILLISECONDS = 1000 * 60;

const differenceBetweenMillisecondsInMinutes = (
  startTimeInMilliseconds: number,
  endTimeInMilliseconds: number
) => {
  const diff = endTimeInMilliseconds - startTimeInMilliseconds;
  return Math.floor(diff / MINUTE_IN_MILLISECONDS);
};

// export const CrossCoverContext = createContext<ContextState | undefined>(
//   undefined
// );

const whiteListRoles = ["readonly"];

const DEFAULT_USER_INACTIVITY_TIMEOUT_IN_MINUTES = 5;

//export type ContextState = {
//referenceDocs?: IReferenceDocuments;
//firebaseUser: User | null;
//userProfile?: IProfile;
// activeTenant?: ITenant;
// userPermissions: string[];
// userTenants?: ITenant[];
// tenants?: ITenant[];
//modules?: IModule[];
//activeModule?: IModule;
//isProviderLoaded: boolean;
// tenantConfiguration?: ITenantConfiguration;
// tenantSettings?: ITenantSettings;
//providerError?: string;
//SetModule: (id: string) => void;
//SignOut: () => Promise<void>;
//SetActiveTenant: (id: string) => void;
//};

//type CCProviderAction =
//  | { type: "UPDATE_TENANTS"; payload: { tenants: ITenant[] } }
//| { type: "CLEAR_STATE"; payload: {} }
//| { type: "UPDATE_USER"; payload: { user: User | null } }
//| { type: "UPDATE_USER_PROFILE"; payload: { profile: IProfile } }
//| { type: "UPDATE_TENANTS"; payload: { tenants: ITenant[] } }
//| { type: "UPDATE_USER_TENANTS"; payload: { tenants: ITenant[] } }
// | { type: "UPDATE_ACTIVE_TENANT"; payload: { tenant: ITenant } }
//  | { type: "UPDATE_USER_CLAIMS"; payload: { claims: string[] } }
// | {
//    type: "UPDATE_TENANT_CONFIGURATION";
//    payload: { configuration: ITenantConfiguration };
//  }
//| { type: "UPDATE_TENANT_SETTINGS"; payload: { settings: ITenantSettings } }
// | { type: "UPDATE_ACTIVE_MODULE"; payload: { module: IModule } }
// | { type: "RELOAD_ACTIVE_MODULE"; payload: { module: IModule } }
// | { type: "UPDATE_MODULES"; payload: { modules: IModule[] } }
// | { type: "UPDATE_PROVIDER_READY"; payload: { isReady: boolean } }
// | { type: "SET_PROVIDER_ERROR"; payload: { message: string } };

//const emptyContext: ContextState = {
//firebaseUser: null,
//userProfile: undefined,
//activeTenant: undefined,
//userPermissions: [],
//userTenants: undefined,
//tenants: undefined,
//modules: undefined,
//activeModule: undefined,
// isProviderLoaded: false,
//tenantConfiguration: undefined,
//tenantSettings: undefined,
//  providerError: undefined,
//  SetModule: (id: string) => {},
// SignOut: async () => {},
// SetActiveTenant: (id: string) => {},
//};

// const crossCoverProviderReducer = (
//   previousState: ContextState,
//   action: CCProviderAction
// ): ContextState => {
//   switch (action.type) {
//     // case "UPDATE_USER":
//     //   return {
//     //     ...previousState,
//     //     firebaseUser: action.payload.user,
//     //   };
//     // case "UPDATE_USER_PROFILE":
//     //   return {
//     //     ...previousState,
//     //     userProfile: action.payload.profile,
//     //   };
//     // case "UPDATE_TENANTS":
//     //   return {
//     //     ...previousState,
//     //     tenants: action.payload.tenants,
//     //   };
//     // case "UPDATE_USER_TENANTS":
//     //   return {
//     //     ...previousState,
//     //     userTenants: action.payload.tenants,
//     //   };
//     // case "UPDATE_ACTIVE_TENANT":
//     //   return {
//     //     ...previousState,
//     //     activeTenant: action.payload.tenant,
//     //   };
//     // case "UPDATE_ACTIVE_MODULE":
//     //   return {
//     //     ...previousState,
//     //     activeModule: action.payload.module,
//     //   };
//     // case "RELOAD_ACTIVE_MODULE":
//     //   if (previousState.activeModule) {
//     //     const activeModule = previousState.modules?.find(
//     //       (x) => x.id === previousState.activeModule?.id && x.isActive
//     //     );
//     //     return {
//     //       ...previousState,
//     //       activeModule: activeModule,
//     //     };
//     //   }
//     //   return { ...previousState };

//     // case "UPDATE_MODULES":
//     //   //If reload and have an active module then we need to make sure it is keep up to date
//     //   if (previousState.activeModule) {
//     //     const activeModule = previousState.modules?.find(
//     //       (x) => x.id === previousState.activeModule?.id && x.isActive
//     //     );
//     //     return {
//     //       ...previousState,
//     //       modules: action.payload.modules,
//     //       activeModule: activeModule,
//     //     };
//     //   }
//     //   return {
//     //     ...previousState,
//     //     modules: action.payload.modules,
//     //   };

//     case "UPDATE_PROVIDER_READY":
//       return {
//         ...previousState,
//         isProviderLoaded: action.payload.isReady,
//       };
//     case "UPDATE_TENANT_CONFIGURATION":
//       return {
//         ...previousState,
//         tenantConfiguration: action.payload.configuration,
//       };
//     case "UPDATE_TENANT_SETTINGS":
//       return {
//         ...previousState,
//         tenantSettings: action.payload.settings,
//       };
//     case "UPDATE_USER_CLAIMS":
//       return {
//         ...previousState,
//         userPermissions: action.payload.claims,
//       };
//     case "CLEAR_STATE":
//       return { ...emptyContext };
//     case "SET_PROVIDER_ERROR":
//       console.log(`Provider Error:${action.payload.message}`);
//       return {
//         ...previousState,
//         providerError: action.payload.message,
//       };
//     default:
//       throw Error("Invalid action type");
//   }
// };
//let staticProfile: IProfile | null = null;

const CrossCoverProvider = ({ children }: any) => {
  const state = useCrossCoverSelector((state) => state);

  // useEffect(() => {
  //   console.log("Redux State", state);
  // }, [state]);

  const reduxDispatch = useCrossCoverDispatch();

  const logoutUser = useCallback(async () => {
    const auth = getAuth();
    await signOut(auth);
    console.log("LOGOUT");
    reduxDispatch(resetState());
  }, [reduxDispatch]);

  //Subscribe to firebase user change
  useEffect(() => {
    const auth = getAuth();
    console.debug("Current User", auth.currentUser);
    if (auth.currentUser?.isAnonymous) {
      console.debug(auth.currentUser);
      //dispatch({ type: "UPDATE_USER", payload: { user: null } });
      reduxDispatch(updateFirebaseUser(null));
    }
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.debug("Subscribed to user", user);
      // dispatch({ type: "UPDATE_USER", payload: { user: user } });
      reduxDispatch(updateFirebaseUser(user));
    });

    return () => {
      console.debug("Unsubscribing from onAuthStateChanged");
      unsubscribe();
    };
  }, [reduxDispatch]);

  //Load the User Profile
  useEffect(() => {
    if (!state.users.firebaseUser) {
      console.debug("Clear State due to empty user", state.users.firebaseUser);
      reduxDispatch(resetState());
      //dispatch({ type: "CLEAR_STATE", payload: {} });
      //  staticProfile = null;
    }
    if (state.users.firebaseUser) {
      console.debug("Loading user profile");
      const db = getDb();

      const unsubscribe = db.watchDoc<IProfile>(
        "users",
        state.users.firebaseUser.uid,
        (profile) => {
          if (profile.uid !== undefined) {
            console.debug("Found user profile", profile);
            // dispatch({
            //   type: "UPDATE_USER_PROFILE",
            //   payload: { profile: profile },
            // });
            reduxDispatch(updateUserProfile(profile));
          } else {
            console.debug("Incomplete user profile", profile);
          }
        },
        {
          onError: () =>
            reduxDispatch(updateProviderError("User Profile is missing")),
        }
      );
      return unsubscribe;
    }
  }, [reduxDispatch, state.users.firebaseUser]);

  //Load reference documents
  useEffect(() => {
    const db = getDb();
    // const referenceDocuments: IReferenceDocuments = {
    //   privacyPolicy: "",
    //   userManual: "",
    // };

    const loadPublic = async () => {
      const publicRefDocs = await db.getDoc<IReferenceDocuments>(
        "public",
        "referenceDocuments"
      );
      console.debug("Public References", publicRefDocs);

      reduxDispatch(updateReferenceDocuments(publicRefDocs));
    };

    const loadPrivate = async () => {
      const privateRefDocs = await db.getDoc<IReferenceDocuments>(
        "public/referenceDocuments/private",
        "privateReferenceDocuments"
      );
      console.debug("Private References", privateRefDocs);
      reduxDispatch(updateReferenceDocuments(privateRefDocs));
    };

    loadPublic();

    if (state.users.userProfile) {
      loadPrivate();
    }
  }, [reduxDispatch, state.users.userProfile]);

  //Load user roles
  // useEffect(() => {
  //   if (state.activeTenant && state.userProfile) {
  //     console.debug("Subscribing to tenant roles");
  //     const unsubscribe = db.watchDoc<TenantRoles>(
  //       `${state.activeTenant.id}`,
  //       "roles",
  //       (roleSnap) => {
  //         const userTenantRole =
  //           state.userProfile?.access[state.activeTenant?.id || ""].role;
  //         if (userTenantRole) {
  //           const roleMap = roleSnap;
  //           const userTenantClaims = roleMap.roles[userTenantRole].claims;
  //           dispatch({
  //             type: "UPDATE_USER_CLAIMS",
  //             payload: { claims: userTenantClaims },
  //           });
  //         }
  //       }
  //     );
  //     return unsubscribe;
  //   }
  // }, [state.activeTenant, state.userProfile]);

  // Loading user tenants
  useEffect(() => {
    if (state.users.userProfile && state.tenants.tenants) {
      console.debug("Setting to user tenants and default");
      if (
        !Object.values(state.users.userProfile.access).some((x) => x.default)
      ) {
        reduxDispatch(
          updateProviderError("User account has not default tenant")
        );
      }
      const userTenants = Object.values(state.users.userProfile.access).map(
        (userTenant) => {
          const tenantDoc = state.tenants.tenants?.find(
            (x) => x.id === userTenant.id
          );
          console.debug("Search for default tenant in", tenantDoc, userTenant);
          if (tenantDoc && userTenant.default) {
            console.debug(`Default tenant selected: ${tenantDoc.id}`);
            reduxDispatch(updateActiveTenant(tenantDoc));
          }
          return tenantDoc;
        }
      );

      reduxDispatch(
        updateUserTenants(
          userTenants.filter((tenant): tenant is ITenant => !!tenant)
        )
      );
    }
  }, [reduxDispatch, state.tenants.tenants, state.users.userProfile]);

  //Load tenants
  useEffect(() => {
    console.debug("Loading tenants");
    if (state.users.userProfile) {
      const Load = async () => {
        const db = getDb();

        const tenants = await db.getCollection<ITenant>("tenants");

        //dispatch({ type: "UPDATE_TENANTS", payload: { tenants: tenants } });
        reduxDispatch(updateTenants(tenants));
      };
      Load();
    }
  }, [reduxDispatch, state.users.userProfile]);

  //Load all modules and join to tenant record to mark which ones are active for the tenant
  useEffect(() => {
    if (state.tenants.activeTenant && state.tenants.tenants) {
      const Load = async () => {
        const db = getDb();

        const modulesSnapshot = await db.getCollection<IModule>("modules");

        const tenantRecord = state.tenants.tenants?.find(
          (x) => x.id === state.tenants.activeTenant?.id
        );

        const tenantModules = tenantRecord?.modules || [];
        if (
          tenantModules.length === 0 ||
          !tenantModules.some((x) => x.isActive)
        ) {
          reduxDispatch(updateProviderError(`Tenant has no active modules`));
        }

        const modulesForTenant = modulesSnapshot.map((module) => {
          const theModule = tenantModules.find((x) => x.moduleId === module.id);
          return {
            ...module,
            isActive: Boolean(theModule?.isActive),
            order: theModule?.order || module.order || 1,
          };
        });
        reduxDispatch(updateTenantModules(modulesForTenant));
        // dispatch({
        //   type: "UPDATE_MODULES",
        //   payload: { modules: modulesForTenant },
        // });
      };
      Load();
    }
  }, [state.tenants.activeTenant, state.tenants.tenants, reduxDispatch]);

  // useEffect(() => {
  //   if (state.activeTenant && state.tenants) {
  //     const unsubscribe = db.watchCollection<IModule>(
  //       "modules",
  //       (modules) => {
  //         const tenantRecord = state.tenants?.find(
  //           (x) => x.id === state.activeTenant?.id
  //         );

  //         const tenantModules = tenantRecord?.modules || [];
  //         if (
  //           tenantModules.length === 0 ||
  //           !tenantModules.some((x) => x.isActive)
  //         ) {
  //           dispatch({
  //             type: "SET_PROVIDER_ERROR",
  //             payload: {
  //               message: `Tenant has no active modules`,
  //             },
  //           });
  //         }

  //         const modulesForTenant = modules.map((module) => {
  //           const theModule = tenantModules.find(
  //             (x) => x.moduleId === module.id
  //           );
  //           return {
  //             ...module,
  //             isActive: Boolean(theModule?.isActive),
  //             order: theModule?.order || module.order || 1,
  //           };
  //         });

  //         dispatch({
  //           type: "UPDATE_MODULES",
  //           payload: { modules: modulesForTenant },
  //         });
  //       }
  //     );

  //     return unsubscribe;
  //   }
  // }, [state.activeTenant, state.tenants]);

  //Setup Tenant Configuration and settings
  useEffect(() => {
    if (state.tenants.activeTenant) {
      const db = getDb();

      db.watchDoc<ITenantConfiguration>(
        `${state.tenants.activeTenant.id}`,
        "config",
        (config) => {
          reduxDispatch(updateTenantConfiguration(config));
          setEditorVersion(config.editorVersion ?? "V2");
          // dispatch({
          //   type: "UPDATE_TENANT_CONFIGURATION",
          //   payload: { configuration: config },
          // });
        }
      );
      console.debug(state.tenants.activeTenant);
      db.watchDoc<ITenantSettings>(
        `${state.tenants.activeTenant.id}/config/config`,
        "settings",
        (settings) => {
          if (!settings.defaultDevNodesCollection) {
            settings.defaultDevNodesCollection = "nodes";
          }
          reduxDispatch(updateTenantSettings(settings));
          // dispatch({
          //   type: "UPDATE_TENANT_SETTINGS",
          //   payload: { settings: settings },
          // });
        }
      );
    }
  }, [reduxDispatch, state.tenants.activeTenant]);

  useEffect(() => {
    if (state.tenants.tenantConfiguration?.logLevel) {
      firebase.firestore.setLogLevel(
        state.tenants.tenantConfiguration?.logLevel
      );
    }
  }, [state.tenants.tenantConfiguration]);

  const onUserActivity = useMemo(
    () =>
      throttle(() => {
        if (window.inactiveWarningOpen) {
          toastr.removeByType("warning");
          window.inactiveWarningOpen = false;
        }
        localStorage.setItem("lastUserActivityTime", Date.now() + "");
      }, 1000),
    []
  );

  //Enable User Auto Logout - Switch as the user changes tenant
  useEffect(() => {
    if (!state.users.userProfile) {
      return;
    }
    console.log("Auto Time");

    if (
      state.users.userProfile !== undefined &&
      state.tenants.activeTenant !== undefined
    ) {
      if (window.inactiveTimerId) {
        clearInterval(window.inactiveTimerId);
        delete window.inactiveTimerId;
      }

      if (window.inactiveWarningOpen) {
        toastr.removeByType("warning");
        window.inactiveWarningOpen = false;
      }

      window.lastUserActivityTime = Date.now();
      window.inactiveWarningOpen = false;
      const autoLogoutRequired = !whiteListRoles.includes(
        state.users.userProfile?.access[state.tenants.activeTenant?.id || ""]
          ?.role || ""
      );

      //If the firebase user exists then we need to setup up the timer check for inactivity
      if (
        window.inactiveTimerId === undefined &&
        autoLogoutRequired &&
        state.users.firebaseUser
      ) {
        window.inactiveTimerId = setInterval(() => {
          const lastStoredTime =
            parseInt(localStorage.getItem("lastUserActivityTime") ?? "", 10) ||
            Date.now();
          const currentInActivityTime = differenceBetweenMillisecondsInMinutes(
            lastStoredTime,
            Date.now()
          );
          const currentTenantTimeout =
            state.tenants.tenantSettings?.authTimeOut ||
            DEFAULT_USER_INACTIVITY_TIMEOUT_IN_MINUTES;

          //If the users inactivity timeout is less that one minute
          //We show a warning
          if (
            currentInActivityTime >= currentTenantTimeout - 1 &&
            !window.inactiveWarningOpen
          ) {
            const toastrOption = {
              timeOut: 60000,
              position: "top-center",
            };
            window.inactiveWarningOpen = true;
            toastr.warning(
              "Auto log-out",
              "Session closing in 1 minute.",
              toastrOption
            );
          }

          if (currentInActivityTime >= currentTenantTimeout) {
            console.log("Timeout", currentInActivityTime, currentTenantTimeout);
            logoutUser().then(() => {
              if (window.inactiveTimerId) {
                clearInterval(window.inactiveTimerId);
                delete window.inactiveTimerId;
              }
            });
            document.removeEventListener("mousemove", onUserActivity);
          }
          //Check it every 10 seconds
        }, 1000 * 10);
      }
      document.addEventListener("mousemove", onUserActivity);

      return () => {
        document.removeEventListener("mousemove", onUserActivity);
      };
    }
  }, [
    state.users.firebaseUser,
    state.users.userProfile,
    onUserActivity,
    state.tenants.activeTenant,
    state.tenants.tenantSettings?.authTimeOut,
    logoutUser,
  ]);

  //Once all context values are setup, then mark the provider ready
  useEffect(() => {
    if (
      state.tenants.tenants &&
      state.tenants.activeTenant &&
      state.users.userProfile &&
      state.users.firebaseUser &&
      state.modules.tenantModules &&
      state.tenants.tenantSettings
    ) {
      console.log("APP READY", state.tenants.activeTenant);
      if (state.tenants.activeTenant === undefined) {
        // dispatch({
        //   type: "SET_PROVIDER_ERROR",
        //   payload: { message: "User account has not default tenant" },
        // });
      }

      if (state.users.userProfile?.hasActivatedAccount) {
        reduxDispatch(updateProviderLoaded(true));
      }
    }
  }, [
    state.tenants.activeTenant,
    state.users.firebaseUser,
    state.modules.tenantModules,
    state.tenants.tenantSettings,
    state.tenants.tenants,
    state.users.userProfile,
    state.crossCover.providerError,
    reduxDispatch,
  ]);

  return (
    <>
      <>{children}</>
    </>
    // <CrossCoverContextProvider
    //   value={{
    //     ...state,
    //     SignOut: logoutUser,
    //   }}
    // >
    //   {children}
    // </CrossCoverContextProvider>
  );
};

export default CrossCoverProvider;
