import {
  IButtonBlock,
  IButtonData,
  IShowHideBlock,
  IShowHideData,
  ISTarTBackBlock,
} from "./../../../../types/Nodes";
import {
  ILinkButtonBlock,
  NodeBlock,
  ILegacyNodeLogic,
  INode,
  IDynamicNode,
  ILinkButtonData,
  IChoicesBlock,
  IImageBlock,
  IFileBlock,
  IRichTextAreaBlock,
  IRichTextAreaData,
  IFilePointer,
  IVideoBlock,
  IChoicesBoxData,
  INodeRootDoc,
  BlockTypes,
  NodeBlockData,
  INodeBlock,
  IImageBlockData,
} from "../../../../types/Nodes";
import { ITenantPathway } from "../../../../types/Pathway";

// @ts-ignore
// import htmlToDraft from "html-to-draftjs";

//import { getDb } from "app/data/DbProvider";
import { getDb } from "../../../app/data/DbProvider";
import { DocumentTimestampToMilliseconds } from "../../../app/utils/DocumentTimestampToMilliseconds";

import {
  getEditorVersion,
  getMessagesPath,
  getNodeOrderPath,
  getPathwayPath,
} from "./editorConverters";
import { routeItemToAction } from "./pathwayHelper";
import { v4 as uuid4 } from "uuid";
import { ChatMessage } from "../../../../types/Chat";
import { IDocument } from "../../../../types/DocumentLibrary";

import { serverTimestamp } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { getNameBlockWithNumberInstancesOfBlockType } from "../../../features/NodeEditorV2/NodeEditorUtils";

export const isDynamicNode = (
  node: INode | IDynamicNode
): node is IDynamicNode => {
  return (node as IDynamicNode).blocks !== undefined;
};

export const isDynamicNodeArray = (
  nodes: (INode | IDynamicNode)[]
): nodes is IDynamicNode[] => {
  if (nodes.length === 0) {
    return true;
  }
  return (nodes as IDynamicNode[])[0].blocks !== undefined;
};

export const isNode = (node: INode | IDynamicNode): node is INode => {
  return (node as IDynamicNode).blocks === undefined;
};

export const isNodeArray = (
  nodes: (INode | IDynamicNode)[]
): nodes is INode[] => {
  if (nodes.length === 0) {
    return true;
  }
  return (nodes as IDynamicNode[])[0].blocks === undefined;
};

export const getStartNodeIdFromPreloadedData = (
  preLoadNodes: (INode | IDynamicNode)[],
  preLoadedNodeOrder: string[]
) => {
  //If there are no loads then we cannot have a start node
  if (preLoadNodes.length === 0) {
    return "";
  }

  if (isDynamicNodeArray(preLoadNodes)) {
    const startNode = preLoadNodes.find((x) => x.nodeProperties.isStartNode);

    if (startNode) {
      return startNode.id!;
    }
  }

  const nodes = getOrderedNodes([], preLoadNodes);
  if (nodes.length > 0) {
    return nodes[0].id!;
  }
  throw Error("getStartNodeFromPreloadedData: Cannot determine start node");
};

export const getStartNode = async (
  tenantId: string,
  pathwayId: string,
  collectionId: string
): Promise<IDynamicNode> => {
  const db = getDb();

  const nodeQueryResult = await db.getCollection<IDynamicNode>(
    getPathwayPath(tenantId, pathwayId, collectionId),
    {
      where: [
        {
          field: "nodeProperties.isStartNode",
          op: "==",
          filter: true,
        },
      ],
    }
  );
  //If we only get one node back all is good
  if (nodeQueryResult.length === 1) {
    return nodeQueryResult[0];
  }

  //If we got 0 or more than one node back then we need to get the node order
  //and then return the node at position one
  const nodeOrder = await getNodeOrder(tenantId, pathwayId, collectionId);

  if (nodeOrder.length > 0) {
    const node = await db.getDoc<IDynamicNode>(
      getPathwayPath(tenantId, pathwayId, collectionId),
      nodeOrder[0]
    );
    return node;
  }

  //At this point we are really scrapping the barrel to find a start node;
  const pathwayNodes = await db.getCollection<IDynamicNode>(
    getPathwayPath(tenantId, pathwayId, collectionId)
  );
  const nodes = getOrderedNodes([], pathwayNodes) as IDynamicNode[];
  if (nodes.length > 0) {
    return nodes[0];
  }
  //It seems that there are no nodes in the pathway, so just return and empty node
  return getDynamicEmptyNode();
};

/**
 * Finds the ids of nodes that start branches.
 * A node is considered to start a branch if it has no parent nodes and has children nodes.
 * The start node id will always be returned even if it does not start a branch.
 * @param nodes The dynamic nodes to search.
 * @returns The ids of nodes that start branches.
 */
export const getBranchStartNodeIds = (
  nodes: IDynamicNode[],
  startNodeId: string
): string[] => {
  // Find which nodes have parent nodes
  const hasParent: { [childId: string]: boolean } = {};
  for (const node of nodes) {
    const childIds = getInternalLinks(node);
    for (const childId of childIds) {
      hasParent[childId] = true;
    }
  }

  // Return the ids of nodes that start branches and the start node id
  const branchStartNodeIds: Set<string> = new Set([startNodeId]);
  for (const node of nodes) {
    if (!hasParent[node.id!] && isConnectedNode(node)) {
      branchStartNodeIds.add(node.id!);
    }
  }
  return [...branchStartNodeIds];
};

export const getNodeTitle = async (
  tenantId: string,
  //pathwayId: string,
  collectionId: string,
  routeItem: ILinkButtonData
) => {
  //const routeItem = actionToRouteItem(action, actionText);

  const db = getDb();

  //If we have a pathway id but no nodeId then we need to use the start node
  if (routeItem.pathwayId && !routeItem.nodeId) {
    const startNode = await getStartNode(
      tenantId,
      routeItem.pathwayId,
      collectionId
    );
    return startNode.title;
  }
  if (routeItem.nodeId !== "") {
    const node = await db.getDoc<INode>(
      getPathwayPath(tenantId, routeItem.pathwayId, collectionId),
      routeItem.nodeId
    );

    return node.title;
  }
  return "";
};

const _emptyNode: INode = {
  id: "",
  image: "",
  nodeContentArea: "",
  print: false,
  question: "",
  title: "",
  vimeo: "",
  youtube: "",
  widgets: [],
  controlledElements: [],
  video: "",
  createdAt: Date.now(),
  createdBy: "",
  updatedAt: Date.now(),
  updatedBy: "",
  files: [],
  actions: [],
  answers: [],
  groupNames: [],
  logicgroups: [],
  logicfields: [],
  logicscores: [],
  ruleOperators: [],
  ruleValues: [],
  ruleValuesMin: [],
  ruleValuesMax: [],
  ruleNames: [],
  ruleActions: [],
  ruleTypes: [],
  showscores: false,
  fileNames: [],
};

export const getEmptyNode = () => {
  if (getEditorVersion() === "V1") {
    return getEmptyV1Node();
  }
  return getDynamicEmptyNode();
};

export const getEmptyV1Node = (): INode => {
  return JSON.parse(JSON.stringify(_emptyNode)) as INode;
};

const _emptyDynamicNode: IDynamicNode = {
  title: "",
  id: "",
  blocks: {},
  nodeProperties: { isEndNodeFunctionalityEnabled: false, isStartNode: false },
  createdAt: 0,
  createdBy: "",
  updatedAt: 0,
  updatedBy: "",
  nodeOrigin: {
    tenantId: "",
    pathwayId: "",
    createdAt: 0,
    createdBy: "",
  },
};

export const getDynamicEmptyNode = () => {
  return JSON.parse(JSON.stringify(_emptyDynamicNode)) as IDynamicNode;
};

export const emptyLogicNode: Partial<ILegacyNodeLogic> = {
  groupNames: [],
  logicgroups: [],
  logicfields: [],
  logicscores: [],
  showscores: true,
};
export const emptyRuleLogicNode: Partial<ILegacyNodeLogic> = {
  ruleOperators: [],
  ruleValues: [],
  ruleValuesMin: [],
  ruleValuesMax: [],
  ruleNames: [],
  ruleActions: [],
  ruleTypes: [],
};

export const emptyActionsNode: Partial<INode> = {
  ruleOperators: [],
  ruleValues: [],
  ruleValuesMin: [],
  ruleValuesMax: [],
  ruleNames: [],
  ruleActions: [],
  ruleTypes: [],
};

export const EmptyLogic = () => {
  return JSON.parse(JSON.stringify(emptyLogicNode));
};

export const EmptyRuleLogic = () => {
  return JSON.parse(JSON.stringify(emptyRuleLogicNode));
};

export interface IPathwayValidationResult {
  brokenActions: string[];
  danglingNodes: string[];
  unPublishedPathwayAction: string[];
}

const createEdges = (nodes: IDynamicNode[]) => {
  // if (isNodeArray(nodes)) {
  //   const edges: [string, string][] = [];
  //   for (const node of nodes) {
  //     const actions = getInternalLinks(node);
  //     for (const action of actions) {
  //       edges.push([node.id!, action]);
  //     }
  //   }
  //   return edges;
  // }
  // if (isDynamicNodeArray(nodes)) {
  const edges: [string, string][] = [];
  for (const node of nodes) {
    const actions = getInternalLinks(node);

    for (const action of actions) {
      edges.push([node.id!, action]);
    }
  }

  return edges;
  // }
  // return [];
};

function* dfs2(
  adj: Record<string, string[][]>,
  vertex: string = "",
  path: string[][] = []
): any {
  let leaf = true;
  for (let edge of adj[vertex]) {
    if (!path.includes(edge)) {
      yield* dfs2(adj, edge[1], path.concat([edge]));
      leaf = false;
    }
  }
  if (leaf) yield path.concat([[vertex, ""]]);
}

export function pathwayWalk(startVertex: string, nodes: IDynamicNode[]) {
  const edges = createEdges(nodes);

  //Handle empty pathways
  if (edges.length === 0) {
    return [startVertex];
  }
  // Build adjacency list as key/value pairs, where key=vertex,
  //    and value=array of outgoing edges (not just the neighbors)
  const adj: Record<string, string[][]> = {};
  for (let edge of edges) {
    (adj[edge[0]] ??= []).push(edge);
    adj[edge[1]] ??= [];
  }

  //If the we can find the first startVertext the nodeorder
  //probably has not loaded yet
  if (adj[startVertex] === undefined) {
    return [];
  }

  const connectedNodes = new Set<string>();
  // Output paths
  for (let path of dfs2(adj, startVertex)) {
    // console.log(path);
    for (const edge of path) {
      //   console.log("E", edge[0]);
      connectedNodes.add(edge[0]);
    }
  }

  return Array.from(connectedNodes);
  //const c = routes.map((x) => x[0][0]);
  //return routes;
  //   console.log(JSON.stringify(path));
}

export const getDefaultBlockData = (blockType: BlockTypes): NodeBlockData => {
  switch (blockType) {
    case "image":
      const imageData: IImageBlockData = {
        images: [],
      };
      return imageData;
    case "linkButton":
      const linkBlock: ILinkButtonData = {
        nodeId: "I will connect later",
        linkText:
          "This is a Link Button block. Select the Edit button for this block to edit its title and functionality.",
        pathwayId: "",
        isPathwayToPathwayLink: false,
      };
      return linkBlock;
    default:
      throw Error(
        `getDefaultBlockData: Can't provide default data for ${blockType}`
      );
  }
};

export const createBlock = <TDATA extends NodeBlockData>({
  type,
  name,
  data,
  rules,
  addToDecisionSummary,
  addToManagementPlan,
  addToNodeScore,
}: {
  type: BlockTypes;
  name: string;
  data?: TDATA;
  rules?: any;
  addToManagementPlan?: boolean;
  addToDecisionSummary?: boolean;
  addToNodeScore?: boolean;
}): INodeBlock<TDATA> => {
  const newBlock: INodeBlock<TDATA> = {
    blockId: uuid4(),
    blockData: data ?? (getDefaultBlockData(type) as TDATA),
    blockName: name,
    blockType: type,
    blockRules: {
      rule: rules || null,
      alwaysShow: rules ? false : true,
      isAdvancedRules: false,
    },
    position: {
      column: 0,
      row: 0,
    },
    blockOptions: {
      backgroundColor: "rgb(255,255,255)",
      borderColor: "rgb(255,255,255)",
      fontColor: "rgb(0,0,0)",
      addToManagementPlan: addToManagementPlan ?? false,
      addToDecisionSummary: addToDecisionSummary ?? false,
      addToNodeScore: addToNodeScore ?? false,
      borderRadius: "5px",
      readonlyInTemplate: false,
    },
  };

  return newBlock;
};
/**
 * Check if a node exists in an array of nodes
 * @param nodeId
 * @param nodes
 * @returns
 */
export const nodeExists = (nodeId: string, nodes: IDynamicNode[]) => {
  return nodes.some((node) => node.id === nodeId);
};


export const nodeHasStartBack = (node:IDynamicNode)=>{
  return Object.values(node.blocks).some(x=> isStartBackBlock(x))
}

/**
 * Check if a block is a link block
 * @param block any NodeBlocks type
 * @returns boolean
 */
export const isLinkBlock = (block: NodeBlock): block is ILinkButtonBlock =>
  block.blockType === "linkButton";

export const isStartBackBlock = (block: NodeBlock): block is ISTarTBackBlock =>
  block.blockType === "startback";

export const isQuestionBlock = (
  block: NodeBlock
): block is IRichTextAreaBlock =>
  block.blockType === "question" ||
  (block.blockType === "richTextArea" &&
    (block.blockData as IRichTextAreaData)?.type === "question");

export const isChoicesBlock = (block: NodeBlock): block is IChoicesBlock =>
  block.blockType === "choices";

export const isImageBlock = (block: NodeBlock): block is IImageBlock =>
  block.blockType === "image";

export const isVideoBlock = (block: NodeBlock): block is IVideoBlock =>
  block.blockType === "video";

export const isFileBlock = (block: NodeBlock): block is IFileBlock =>
  block.blockType === "files";

export const isRichTextAreaBlock = (
  block: NodeBlock
): block is IRichTextAreaBlock =>
  block.blockType === "richTextArea" &&
  (block.blockData as IRichTextAreaData)?.type !== "question";

export const isScorerBlock = (block: NodeBlock): block is IChoicesBlock =>
  block.blockType === "choices" &&
  (block.blockData as IChoicesBoxData).type === "scorer";

export const isYesNoBlock = (block: NodeBlock): block is IChoicesBlock =>
  block.blockType === "choices" &&
  (block.blockData as IChoicesBoxData).type === "yesNo";

export const isShowHideBlock = (block: NodeBlock): block is IShowHideBlock =>
  block.blockType === "showHide" &&
  (block.blockData as IShowHideData).buttonId !== undefined;

export const isButtonBlock = (block: NodeBlock): block is IButtonBlock =>
  block.blockType === "button" &&
  (block.blockData as IButtonData).buttonId !== undefined &&
  (block.blockData as IButtonData).buttonText !== undefined;

export const remapLinks = (
  idMap: Record<string, string>,
  branch: IDynamicNode[]
) => {
  const branchCopy: IDynamicNode[] = JSON.parse(JSON.stringify(branch));
  for (const nodeCopy of branchCopy) {
    for (const [, block] of Object.entries(nodeCopy.blocks)) {
      if (isLinkBlock(block) && block.blockData !== undefined) {
        if (!block.blockData.isPathwayToPathwayLink) {
          const newNodeId = idMap[block.blockData.nodeId];
          if (newNodeId) {
            block.blockData.nodeId = newNodeId;
          } else {
            block.blockData.nodeId = EMPTY_LINK_TEXT;
          }
        }
      }
    }
  }
  return branchCopy;
};

export const clearLinks = (node: IDynamicNode) => {
  const nodeCopy: IDynamicNode = JSON.parse(JSON.stringify(node));
  for (const [, block] of Object.entries(nodeCopy.blocks)) {
    if (isLinkBlock(block) && block.blockData !== undefined) {
      if (!block.blockData.isPathwayToPathwayLink) {
        block.blockData.nodeId = EMPTY_LINK_TEXT;
      }
    }
  }
  return nodeCopy;
};

export const isEmptyLinkBlock = (blockData: ILinkButtonData | undefined) => {
  if (blockData === undefined) {
    return true;
  }
  return blockData.isPathwayToPathwayLink
    ? blockData.nodeId === EMPTY_LINK_TEXT
    : blockData.nodeId === "" ||
        blockData.nodeId === EMPTY_LINK_TEXT ||
        blockData.pathwayId === "";
};

/**
 * Returns true when a node id is not EMPTY_LINK_TEXT or empty string
 * @param value
 * @returns
 */
export const isValidNodeId = (value: string) => {
  return ![EMPTY_LINK_TEXT, ""].includes(value);
};

/**
 *
 * @param node Return true of  the node has a link block and the link block is not set to empty
 * @returns
 */
export const isConnectedNode = (node: INode | IDynamicNode) => {
  if (isNode(node)) {
    const isActionsNotEmpty = Boolean(
      Array.isArray(node.actions) &&
        node.actions.length &&
        node.actions[0] !== "" &&
        node.actions[0] !== EMPTY_LINK_TEXT
    );
    const isRulesNotEmpty = Boolean(
      Array.isArray(node.ruleActions) &&
        node.ruleActions.length &&
        node.ruleActions[0] !== "" &&
        node.ruleActions[0] !== EMPTY_LINK_TEXT
    );
    //If both of these are empty, return true
    return isActionsNotEmpty || isRulesNotEmpty;
  }

  return (
    Object.values(node.blocks)
      .filter((block) => isLinkBlock(block) || isStartBackBlock(block))
      // .some((block) => !isEmptyLinkBlock(block.blockData as ILinkButtonData));
      .some((block) => {
        if (isStartBackBlock(block)) {
          let isConnected = false;
          block.blockData.widgetRules.forEach((action) => {
            if (isConnected) {
              return;
            }
            isConnected = !isEmptyLinkBlock(action);
          });
          return isConnected;
        }
        return !isEmptyLinkBlock(block.blockData as ILinkButtonData);
      })
  );
};

export const isEndNodeFunctionalityEnabled = (node: INode | IDynamicNode) => {
  if (isNode(node)) {
    const isActionsEmpty = Boolean(
      Array.isArray(node.actions) && node.actions.length && node.actions[0]
    );
    const isRulesEmpty = Boolean(
      Array.isArray(node.ruleActions) &&
        node.ruleActions.length &&
        node.ruleActions[0] !== ""
    );
    return isActionsEmpty && isRulesEmpty;
  }

  return (
    Object.values(node.blocks)
      .filter((block) => isLinkBlock(block))
      .every((block) => isEmptyLinkBlock(block.blockData as ILinkButtonData)) ||
    node.nodeProperties.isEndNodeFunctionalityEnabled
  );
};

/**
 * Returns all node ids from link blocks in a node where
 * isPathwayToPathway link is false and node id is not EMPTY_LINK_TEXT or empty string
 * @param node
 * @returns An array of node ids
 */
export const getInternalLinks = (node: IDynamicNode) => {
  const nodeLinks: string[] = [];
  for (const block of Object.values(node.blocks)) {
    if (isLinkBlock(block) && block.blockData !== undefined) {
      if (
        !block.blockData.isPathwayToPathwayLink &&
        isValidNodeId(block.blockData.nodeId)
      ) {
        nodeLinks.push(block.blockData.nodeId);
      }
    }

    if (isStartBackBlock(block) && block.blockData !== undefined) {
      for (const links of block.blockData.widgetRules) {
        if (!links.isPathwayToPathwayLink && isValidNodeId(links.nodeId)) {
          nodeLinks.push(links.nodeId);
        }
      }
    }
  }

  return nodeLinks;
};

const getOrderedBlocks = (node: IDynamicNode) => {
  return Object.values(node.blocks).sort((a, b) => {
    if (a.position && b.position) {
      const rowPosition = a.position.row - b.position.row;
      if (rowPosition !== 0) {
        return rowPosition;
      }
      return a.position.column - b.position.column;
    }
    return 0;
  });
};

export const getNodeLinks = (node: IDynamicNode, excludeEmpty?: boolean) => {
  const nodeLinks: ILinkButtonData[] = [];
  const orderedBlocks = getOrderedBlocks(node);
  for (const [, block] of Object.entries(orderedBlocks)) {
    if (isLinkBlock(block) && block.blockData !== undefined) {
      if (excludeEmpty && !isEmptyLinkBlock(block.blockData)) {
        nodeLinks.push(block.blockData);
      }
      if (!excludeEmpty) {
        nodeLinks.push(block.blockData);
      }
    }
    if (isStartBackBlock(block) && block.blockData !== undefined) {
      block.blockData.widgetRules.forEach((action) => {
        if (excludeEmpty && !isEmptyLinkBlock(action)) {
          nodeLinks.push(action);
        }
        if (!excludeEmpty) {
          nodeLinks.push(action);
        }
      });
    }
  }
  return nodeLinks;
};

export const getNodeLinksAsActions = (
  node: IDynamicNode,
  excludeEmpty?: boolean
) => {
  const nodeLinks = getNodeLinks(node, excludeEmpty);
  return nodeLinks.map((item) => routeItemToAction(item));
};

export const generateLinkMap = (node: IDynamicNode) => {
  const idMap: Record<string, string[]> = {};
  for (const [, block] of Object.entries(node.blocks)) {
    if (isLinkBlock(block) && block.blockData !== undefined) {
      if (!block.blockData.isPathwayToPathwayLink) {
        if (idMap[node.id ?? ""] === undefined) {
          idMap[node.id ?? ""] = [];
        }
        idMap[node.id ?? ""].push(block.blockData.nodeId);
      }
    }
  }
  return idMap;
};

export const getNodeQuestion = (
  node: INode | IDynamicNode | null | undefined
) => {
  if (!node) {
    return "";
  }
  if (isNode(node)) {
    return node.question;
  }
  for (const block of Object.values(node.blocks)) {
    if (isQuestionBlock(block)) {
      return block.blockData.content;
    }
  }
};

export const getDocument = async (filePointer: IFilePointer) => {
  if (filePointer.storageType === "firebase") {
    const db = getDb();
    const documents = await db.getCollectionGroup<IDocument>(`files`, {
      where: [
        {
          field: "id",
          op: "==",
          filter: filePointer.pointer,
        },
      ],
    });

    return documents.at(0);
  }
  throw Error("Invalid document reference provided");
};

export const convertFilePointerToLink = async (
  tenantId: string,
  filePointer: IFilePointer
) => {
  if (filePointer.storageType === "firebase") {
    const db = getDb();
    const document = await db.getDoc<IDocument>(
      `${tenantId}/Files/files`,
      filePointer.pointer
    );
    return document.file;
  }
  return filePointer.pointer;
};

export const getNodeImages = (
  node: INode | IDynamicNode | null | undefined
): IFilePointer[] => {
  if (!node) {
    return [];
  }
  if (isNode(node)) {
    return [{ pointer: node.image, storageType: "firebase" }];
  }
  const imageLinks: IFilePointer[] = [];
  for (const block of Object.values(node.blocks)) {
    if (isImageBlock(block)) {
      imageLinks.concat(block.blockData.images);
    }
  }
  return imageLinks;
};

export const getNodeFiles = (node: INode | IDynamicNode | null | undefined) => {
  if (!node) {
    return [];
  }
  if (isNode(node)) {
    return node.files.map((file, index) => ({
      pointer: file,
      pointerTitle: node.files[index],
      storageType: "direct",
    })) as IFilePointer[];
  }
  for (const block of Object.values(node.blocks)) {
    if (isFileBlock(block)) {
      return block.blockData.filePointers;
    }
  }

  return [];
};

export const getTextAreas = (
  node: INode | IDynamicNode | null | undefined
): string[] => {
  if (!node) {
    return [];
  }
  if (isNode(node)) {
    return [node.nodeContentArea];
  }
  const textAreaBlocks: string[] = [];
  for (const block of Object.values(node.blocks)) {
    if (isRichTextAreaBlock(block)) {
      textAreaBlocks.push(block.blockData?.content ?? "");
    }
  }
  return textAreaBlocks;
};

export const isNodeWithRules = (
  node: INode | IDynamicNode | undefined | null
) => {
  if (!node) {
    return false;
  }
  if (isNode(node)) {
    return (
      Array.isArray(node.ruleNames) &&
      node.ruleNames.length > 0 &&
      node.ruleNames[0] !== ""
    );
  }
  return Object.values(node.blocks).some(
    (block) => isLinkBlock(block) && block.blockRules.rule !== null
  );
};

export const nodeHasFiles = (node: INode | IDynamicNode | null) => {
  if (!node) {
    return false;
  }
  if (isNode(node)) {
    return node.files && node.files.length > 0 && node.files[0] !== "";
  }

  for (const block of Object.values(node.blocks)) {
    if (isFileBlock(block) && block.blockData.filePointers.length > 0) {
      return true;
    }
  }

  return false;
};

/**
 * Returns an ordered list of nodeIds
 * 1. If there is a specified node order then return nodes in that order
 * 2. If no specified node order exists then nodes are order by created date
 * 3. If a specified node order exists but nodes exist that not included in the list then they are sorted by created date
 * and appended to the end of the list.
 * @param nodeOrder The current orders list of node ids or an empty array if one does not exist
 * @param nodes The list of nodes to be ordered
 * @returns A ordered list of nodeIds
 */
export const generateNodeOrder = (
  nodeOrder: string[] | undefined,
  nodes: (INode | IDynamicNode)[]
): string[] => {
  const filteredDuplicatedNodeOrderId = [...new Set(nodeOrder)];

  const unOrderedNodes = nodes
    .filter((x) => x.id)
    .filter((node) => {
      return !filteredDuplicatedNodeOrderId.includes(node.id || "");
    })
    .sort(
      (a, b) =>
        DocumentTimestampToMilliseconds(a.createdAt) -
        DocumentTimestampToMilliseconds(b.createdAt)
    );

  //Remove any undefined or empty string items from the array
  const newNodeOrder = [
    ...filteredDuplicatedNodeOrderId,
    ...unOrderedNodes.map((x) => x.id ?? ""),
  ].filter((nodeId) => Boolean(nodeId));

  //Special handling to ensure dynamic node property is always first item in the array
  //if we have a startNode then remove it from the array
  //because we are place it at the start of the array
  if (isDynamicNodeArray(nodes)) {
    const startNode = nodes.find((x) => x.nodeProperties.isStartNode);
    //If we don't have a start node or id then nothing we can do.
    if (startNode !== undefined && startNode.id !== undefined) {
      //We have a start node, so lets move it to position 0
      return moveNodeIdInNodeOrder(newNodeOrder, startNode.id, 0);
    }
  }

  return newNodeOrder;
};

/** Get a the node order name based on the collectionName
 * if the collection name is nodes,covidnodes,livenode the predefined node order name
 * is returned otherwise the supplied name plus Order appended is returned
 * @param collectionName The name of the collection
 */
export const getCollectionOrderName = (collectionName: string): string => {
  if (getEditorVersion() === "V2") {
    return "nodeOrder";
  }

  switch (collectionName) {
    case "livenodes":
      return "liveNodeOrder";
    case "nodes":
      return "nodeOrder";
    case "covidnodes":
      return "covidNodeOrder";
    default:
      return `${collectionName}Order`;
  }
};

/** Given a pathway document and collectionName, returns the node order array
 * @param pathway A tenant pathway document
 * @param collectionName A collectionName within the pathway
 */

// export const getNodeOrderFromPathway = (
//   pathway: ITenantPathway,
//   collectionName: string
// ) => {
//   const nodeOrderName = getCollectionOrderName(collectionName);
//   const nodeOrderFromPathway = (pathway as unknown as Record<string, string[]>)[
//     nodeOrderName
//   ];
//   return nodeOrderFromPathway;
// };

/**
 * Returns an ordered list of nodes based on the rules of nodeOrder
 * 1. If there is a specified node order then return nodes in that order
 * 2. If no specified node order exists then nodes are order by created date
 * 3. If a specified node order exists but nodes exist that not included in the list then they are sorted by created date
 * and appended to the end of the list.
 * @param nodeOrder A list of nodeIds in an ordered array
 * @param nodes  A list of all nodes to be ordered
 * @returns An ordered list of nodes
 */
export const getOrderedNodes = (
  nodeOrder: string[] | undefined,
  nodes: (INode | IDynamicNode)[] | undefined
) => {
  const orderedNodeIds = generateNodeOrder(nodeOrder || [], nodes || []);

  const orderedNodes: (INode | IDynamicNode)[] = [];
  for (const nodeId of orderedNodeIds) {
    const node = (nodes || []).find((x) => x.id === nodeId);
    if (node) {
      orderedNodes.push(node);
    }
  }

  return orderedNodes;
};

export const NODES_ROOT_DOC_NAME = "nodesRootDoc";

export const getNodeCollectionRootDoc = async (
  tenantId: string,
  pathwayId: string,
  collectionId: string
) => {
  const db = getDb();

  const nodeRootDoc = await db.getDoc<INodeRootDoc>(
    getNodeOrderPath(tenantId, pathwayId, collectionId),
    NODES_ROOT_DOC_NAME
  );
  return nodeRootDoc;
};

export const getNodeOrder = async (
  tenantId: string,
  pathwayId: string,
  collectionId: string
) => {
  const db = getDb();

  const nodeRootDoc = await db.getDoc<INodeRootDoc>(
    getNodeOrderPath(tenantId, pathwayId, collectionId),
    NODES_ROOT_DOC_NAME
  );
  return nodeRootDoc.nodeOrder ?? [];
};

export const makeStartNode = async (
  tenantId: string,
  pathwayId: string,
  collectionId: string,
  nodeId: string,
  updatingUser: string
) => {
  const db = getDb();
  const nodes = await db.getCollection<IDynamicNode>(
    getPathwayPath(tenantId, pathwayId, collectionId)
  );

  const currentStartNode = nodes
    .filter((x) => x.nodeProperties.isStartNode === true)
    .map((x) => {
      x.nodeProperties.isStartNode = false;
      return x;
    });

  const newStartNode = nodes.find((x) => x.id === nodeId);
  if (newStartNode === undefined) {
    throw Error("makeStartNode: nodeId not found in nodes collection");
  }
  newStartNode.nodeProperties.isStartNode = true;

  //Save the node(s) that have changed
  for (const node of [...currentStartNode, newStartNode]) {
    db.updateDocument(
      getPathwayPath(tenantId, pathwayId, collectionId),
      node,
      updatingUser,
      false
    );
  }
  await reorderNodeOrder(
    tenantId,
    pathwayId,
    collectionId,
    nodeId,
    0,
    updatingUser
  );
};

/**
 * Move a nodeId inside a nodeOrder to a given position,
 * then returns the a copy of the original.
 * @param nodeOrder The node order to change
 * @param nodeId The nodeId to move
 * @param moveToIndex The target index
 * @returns A copy of the original array reordered
 */
export const moveNodeIdInNodeOrder = (
  nodeOrder: string[],
  nodeId: string,
  moveToIndex: number
) => {
  let sourceIndex = nodeOrder.findIndex((x) => x === nodeId);

  const sourceArrayCopy = [...nodeOrder];

  //Move the element in the array
  if (sourceIndex > -1) {
    //Remove the current item from the array
    sourceArrayCopy.splice(sourceIndex, 1);
    //Insert it at the new index
  }
  sourceArrayCopy.splice(moveToIndex, 0, nodeId);
  return sourceArrayCopy;
};

export const reorderNodeOrder = async (
  tenantId: string,
  pathwayId: string,
  collectionId: string,
  nodeId: string,
  insertAtIndex: number,
  updatingUser: string,
  baseNodeOrderToUser?: string[]
) => {
  const db = getDb();

  const nodeCollectionRootDoc = await getNodeCollectionRootDoc(
    tenantId,
    pathwayId,
    collectionId
  );

  if (!Array.isArray(nodeCollectionRootDoc.nodeOrder)) {
    const nodes = await db.getCollection<IDynamicNode>(
      getPathwayPath(tenantId, pathwayId, collectionId)
    );

    const defaultNodeOrder = generateNodeOrder([], nodes);
    nodeCollectionRootDoc.nodeOrder = baseNodeOrderToUser ?? defaultNodeOrder;
  }

  // if (insertAtIndex > nodeCollectionRootDoc.nodeOrder.length) {
  //   throw Error("reorderNodeOrder: InsertAtIndex is outside of the array");
  // }

  const sourceIndex = nodeCollectionRootDoc.nodeOrder.findIndex(
    (x) => x === nodeId
  );

  //It the item current exists in the array, it needs removing
  if (sourceIndex > -1) {
    nodeCollectionRootDoc.nodeOrder.splice(sourceIndex, 1);
  }
  //Move the element in the array
  //If the index is -1 then this id does not exist in the array, so it will be add it at the end
  nodeCollectionRootDoc.nodeOrder.splice(insertAtIndex, 0, nodeId);
  console.log(
    "reorderNodeOrder:",
    getNodeOrderPath(tenantId, pathwayId, collectionId),
    nodeCollectionRootDoc
  );
  await db.updateDocumentById(
    getNodeOrderPath(tenantId, pathwayId, collectionId),
    NODES_ROOT_DOC_NAME,
    nodeCollectionRootDoc,
    updatingUser,
    true
  );

  return nodeCollectionRootDoc.nodeOrder ?? [];
};

export const addToNodeOrder = async (
  tenantId: string,
  pathwayId: string,
  collectionId: string,
  nodeId: string,
  updatingUser: string
) => {
  const db = getDb();

  const nodeCollectionRootDoc = await getNodeCollectionRootDoc(
    tenantId,
    pathwayId,
    collectionId
  );

  if (!Array.isArray(nodeCollectionRootDoc.nodeOrder)) {
    nodeCollectionRootDoc.nodeOrder = [];
  }

  nodeCollectionRootDoc.nodeOrder.push(nodeId);
  console.log(
    "addToNodeOrder:",
    getNodeOrderPath(tenantId, pathwayId, collectionId),
    nodeCollectionRootDoc
  );
  await db.updateDocumentById(
    getNodeOrderPath(tenantId, pathwayId, collectionId),
    NODES_ROOT_DOC_NAME,
    nodeCollectionRootDoc,
    updatingUser,
    true
  );
  return nodeCollectionRootDoc.nodeOrder ?? [];
};

/** Writes a node to firestore and returns the new node */
export const createNode = async (
  targetTenant: string,
  targetPathway: string,
  targetCollection: string,
  node: INode | IDynamicNode,
  createdByUserEmail: string,
  parentId?: string,
  parentAnswerText?: string
): Promise<INode | IDynamicNode> => {
  const db = getDb();
  const newNode = JSON.parse(JSON.stringify(node)) as INode | IDynamicNode;

  const newId = db.generateId(
    getPathwayPath(targetTenant, targetPathway, targetCollection)
  );

  newNode.id = newId;
  newNode.createdBy = createdByUserEmail;
  newNode.createdAt = serverTimestamp();

  if (isDynamicNode(newNode)) {
    newNode.nodeOrigin = {
      createdAt: serverTimestamp(),
      createdBy: createdByUserEmail,
      tenantId: targetTenant,
      pathwayId: targetPathway,
    };
  }

  const updatedNodeOrder = await addToNodeOrder(
    targetTenant,
    targetPathway,
    targetCollection,
    newNode.id,
    createdByUserEmail
  );
  if (isDynamicNode(newNode)) {
    if (updatedNodeOrder.length === 1) {
      newNode.nodeProperties.isStartNode = true;
    }
  }

  await db.updateDocumentById(
    getPathwayPath(targetTenant, targetPathway, targetCollection),
    newId,
    newNode,
    createdByUserEmail,
    true
  );

  if (parentId) {
    await createNodeLink(
      targetTenant,
      targetPathway,
      targetCollection,
      createdByUserEmail,
      parentId,
      parentAnswerText ?? "",
      newId
    );
  }

  return newNode;
};

export const updateNode = async (
  targetTenant: string,
  targetPathway: string,
  targetCollection: string,
  node: INode | IDynamicNode,
  updatedByUserEmail: string
) => {
  const db = getDb();

  if (node.id === undefined) {
    throw Error("Update Node: Cannot update a node with no id");
  }

  await db.updateDocumentById(
    getPathwayPath(targetTenant, targetPathway, targetCollection),
    node.id,
    node,
    updatedByUserEmail,
    false
  );
  return node;
};

const createNodeLinkV1 = async (
  tenantId: string,
  currentPathway: string,
  collection: string,
  currentUserEmail: string,
  parentNodeId: string,
  parentAnswerText: string,
  childNodeId: string
) => {
  const db = getDb();
  const parentNode = await db.getDoc<INode>(
    `${tenantId}/${currentPathway}/${collection}`,
    parentNodeId
  );

  parentNode.actions = [...parentNode.actions, childNodeId];
  parentNode.answers = [...parentNode.answers, parentAnswerText || ""];
  await db.updateDocumentById(
    getPathwayPath(tenantId, currentPathway, collection),
    parentNodeId,
    parentNode,
    currentUserEmail,
    true
  );
};

const createNodeLinkV2 = async (
  tenantId: string,
  currentPathway: string,
  collection: string,
  currentUserEmail: string,
  parentNodeId: string,
  parentAnswerText: string,
  childNodeId: string
) => {
  const db = getDb();
  const parentNode = await db.getDoc<IDynamicNode>(
    getPathwayPath(tenantId, currentPathway, collection),
    parentNodeId
  );

  const linkBlock = createBlock<ILinkButtonData>({
    type: "linkButton",
    name: "",
    data: {
      linkText: parentAnswerText,
      nodeId: childNodeId,
      pathwayId: currentPathway,
      isPathwayToPathwayLink: false,
    },
  });

  linkBlock.blockName = getNameBlockWithNumberInstancesOfBlockType(
    Object.values(parentNode.blocks),
    linkBlock
  );

  if (Object.values(parentNode.blocks).length > 0) {
    linkBlock.position.row = Object.values(parentNode.blocks).length + 1;
  }
  parentNode.blocks[uuid4()] = linkBlock;

  await db.updateDocumentById(
    getPathwayPath(tenantId, currentPathway, collection),
    parentNodeId,
    parentNode,
    currentUserEmail,
    true
  );
};

export const createNodeLink = (
  tenantId: string,
  currentPathway: string,
  collection: string,
  currentUserEmail: string,
  parentNodeId: string,
  parentAnswerText: string,
  childNodeId: string
) => {
  if (getEditorVersion() === "V1") {
    return createNodeLinkV1(
      tenantId,
      currentPathway,
      collection,
      currentUserEmail,
      parentNodeId,
      parentAnswerText,
      childNodeId
    );
  }
  return createNodeLinkV2(
    tenantId,
    currentPathway,
    collection,
    currentUserEmail,
    parentNodeId,
    parentAnswerText,
    childNodeId
  );
};

export const EMPTY_LINK_TEXT = "I will connect later";

const deleteNodeV2 = async (
  tenantId: string,
  currentPathway: string,
  collection: string,
  currentUserEmail: string,
  nodeToDelete: IDynamicNode
) => {
  if (nodeToDelete.id === undefined) {
    throw Error("Delete Node: Invalid node id");
  }
  const db = getDb();

  await db.updateDocumentById(
    getPathwayPath(tenantId, currentPathway, collection),
    nodeToDelete.id,
    { status: "DELETED" },
    currentUserEmail,
    true
  );

  // Update all messages associated with the node to have deleted status
  // const messages = await db.getCollection<ChatMessage>(
  //   getMessagesPath(tenantId, currentPathway, collection)
  // );

  // for (const message of messages) {
  //   if (nodeToDelete.id === message.nodeId) {
  //     await db.updateDocumentById(
  //       getMessagesPath(tenantId, currentPathway, collection),
  //       message.id ?? "",
  //       {
  //         status: "DELETED",
  //       },
  //       currentUserEmail,
  //       true
  //     );
  //   }
  // }

  //Update all node action or ruleActions to be unlinked to stop invalid states
  const nodes = await db.getCollection<IDynamicNode>(
    getPathwayPath(tenantId, currentPathway, collection)
  );

  //If any nodes in the pathway are point to this node, then set the link to
  for (const node of nodes) {
    let nodeHasUpdate = false;
    for (const blockId in node.blocks) {
      const block = node.blocks[blockId];
      if (
        isLinkBlock(block) &&
        block.blockData.isPathwayToPathwayLink === false &&
        block.blockData.nodeId === nodeToDelete.id
      ) {
        block.blockData.nodeId = EMPTY_LINK_TEXT;
        nodeHasUpdate = true;
      }
    }
    if (nodeHasUpdate) {
      await db.updateDocumentById(
        getPathwayPath(tenantId, currentPathway, collection),
        node.id ?? "",
        node,
        currentUserEmail,
        true
      );
    }
  }

  //Remove the node from the node order
  const pathwayDoc = (await db.getDoc<ITenantPathway>(
    getPathwayPath(tenantId),
    currentPathway
  )) as unknown as Record<string, string[]>;

  const nodeOrderName = getCollectionOrderName(collection);
  const nodeOrder = (pathwayDoc && pathwayDoc[nodeOrderName]) || [];
  if (nodeOrder) {
    const newNodeOrder = nodeOrder.filter((x) => x !== nodeToDelete.id);
    await db.updateDocumentById(
      getPathwayPath(tenantId),
      currentPathway,
      { [nodeOrderName]: newNodeOrder },
      currentUserEmail,
      true
    );
  }
};

const deleteNodeV1 = async (
  tenantId: string,
  currentPathway: string,
  collection: string,
  currentUserEmail: string,
  nodeToDelete: INode
) => {
  const db = getDb();
  await db.deleteDocument(
    getPathwayPath(tenantId, currentPathway, collection),
    nodeToDelete.id ?? ""
  );

  // Update all messages associated with the node to have deleted status
  const messages = await db.getCollection<ChatMessage>(
    getMessagesPath(tenantId, currentPathway, collection)
  );

  for (const message of messages) {
    if (nodeToDelete.id === message.nodeId) {
      await db.updateDocumentById(
        getMessagesPath(tenantId, currentPathway, collection),
        message.id ?? "",
        {
          status: "DELETED",
        },
        currentUserEmail,
        true
      );
    }
  }

  //Update all node action or ruleActions to be unlinked to stop invalid states
  const nodes = await db.getCollection<INode>(
    getPathwayPath(tenantId, currentPathway, collection)
  );

  for (const node of nodes) {
    if (
      Array.isArray(node.actions) &&
      node.actions.includes(nodeToDelete.id || "")
    ) {
      node.actions = node.actions.map((x) =>
        x === nodeToDelete.id ? EMPTY_LINK_TEXT : x
      );
    }
    if (
      Array.isArray(node.ruleActions) &&
      node.ruleActions.includes(nodeToDelete.id || "")
    ) {
      node.ruleActions = node.ruleActions.map((x) =>
        x === nodeToDelete.id ? EMPTY_LINK_TEXT : x
      );
    }
    await db.updateDocumentById(
      getPathwayPath(tenantId, currentPathway, collection),
      node.id ?? "",
      node,
      currentUserEmail,
      true
    );
  }

  //Remove the node from the node order
  const pathwayDoc = (await db.getDoc<ITenantPathway>(
    getPathwayPath(tenantId),
    currentPathway
  )) as unknown as Record<string, string[]>;

  const nodeOrderName = getCollectionOrderName(collection);
  const nodeOrder = (pathwayDoc && pathwayDoc[nodeOrderName]) || [];
  if (nodeOrder) {
    const newNodeOrder = nodeOrder.filter((x) => x !== nodeToDelete.id);
    await db.updateDocumentById(
      getPathwayPath(tenantId),
      currentPathway,
      { [nodeOrderName]: newNodeOrder },
      currentUserEmail,
      true
    );
  }
};

export const deleteNode = async (
  tenantId: string,
  currentPathway: string,
  collection: string,
  currentUserEmail: string,
  nodeToDelete: INode | IDynamicNode
) => {
  if (isNode(nodeToDelete)) {
    return deleteNodeV1(
      tenantId,
      currentPathway,
      collection,
      currentUserEmail,
      nodeToDelete
    );
  }
  return deleteNodeV2(
    tenantId,
    currentPathway,
    collection,
    currentUserEmail,
    nodeToDelete
  );
};

export interface INodeLookup {
  tenantId: string;
  pathwayId: string;
  collectionId: string;
  nodeId?: string | string[];
}

export interface ICopyBranch {
  source: INodeLookup;
  target: INodeLookup;
  importUserEmail: string;
  branchStartNode?: string;
  exactNodeIdList?: string[];
  nodeSuffix?: string;
  replaceExisting?: boolean;
}

export const createNodeCopies = (props: {
  nodesToImport: IDynamicNode[];
  importUserEmail: string;
  nodeSuffix?: string;
  generateId: () => string;
  replaceExisting?: boolean;
}) => {
  const nodeIdMap: Record<string, string> = {};
  const importedNodes: IDynamicNode[] = [];
  for (const node of props.nodesToImport) {
    const newNode = JSON.parse(JSON.stringify(node)) as IDynamicNode;
    const newId = props.generateId();
    newNode.id = newId;
    newNode.title = `${node.title}${
      props.nodeSuffix ? ` - ${props.nodeSuffix}` : ""
    }`;
    newNode.createdBy = props.importUserEmail;
    newNode.createdAt = serverTimestamp();
    newNode.updatedAt = serverTimestamp();
    newNode.updatedBy = props.importUserEmail;
    //If we are not replacing the existing pathway then this cannot be the start node
    if (!props.replaceExisting) {
      newNode.nodeProperties.isStartNode = false;
    }
    importedNodes.push(newNode);

    nodeIdMap[node.id ?? ""] = newNode.id ?? "";
  }
  const nodeRemapped = remapLinks(nodeIdMap, importedNodes);

  return nodeRemapped;
};

export const copyNodes = async ({
  source,
  target,
  branchStartNode,
  exactNodeIdList,
  nodeSuffix,
  importUserEmail,
  replaceExisting,
}: ICopyBranch) => {
  console.log(
    source,
    target,
    branchStartNode,
    exactNodeIdList,
    nodeSuffix,
    importUserEmail,
    replaceExisting
  );

  if (branchStartNode !== undefined && exactNodeIdList !== undefined) {
    throw Error("You can either specify a branch or a node list");
  }
  const dbProvider = getDb();
  const sourceNodes = await dbProvider.getCollection<IDynamicNode>(
    getPathwayPath(source.tenantId, source.pathwayId, source.collectionId)
  );

  const nodesToCopy: IDynamicNode[] = [];

  if (Array.isArray(exactNodeIdList)) {
    nodesToCopy.push(
      ...sourceNodes.filter((x) => exactNodeIdList.includes(x.id!))
    );
  }

  if (branchStartNode !== undefined) {
    const nodeIdsInBranch = pathwayWalk(branchStartNode, sourceNodes);
    nodesToCopy.push(
      ...sourceNodes.filter((x) => nodeIdsInBranch.includes(x.id!))
    );
  }

  const nodesToCreate = createNodeCopies({
    nodesToImport: nodesToCopy,
    importUserEmail,
    nodeSuffix,
    generateId: () =>
      dbProvider.generateId(
        getPathwayPath(target.tenantId, target.pathwayId, target.collectionId)
      ),
    replaceExisting: replaceExisting,
  });

  if (replaceExisting) {
    //nodeOrder clean up is handed by nodeOrder delete function
    const existingNodes = await dbProvider.getCollection<IDynamicNode>(
      getPathwayPath(target.tenantId, target.pathwayId, target.collectionId)
    );
    for (const existingNode of existingNodes) {
      console.log(`Deleting:${existingNode.id}`);
      existingNode.status = "DELETED";
      await dbProvider.updateDocument(
        getPathwayPath(target.tenantId, target.pathwayId, target.collectionId),
        existingNode,
        importUserEmail,
        false
      );
    }
  }

  for (const node of nodesToCreate) {
    console.log(`Creating:${node.id}`);
    await dbProvider.updateDocument(
      getPathwayPath(target.tenantId, target.pathwayId, target.collectionId),
      node,
      importUserEmail,
      false
    );
  }

  //Handle Node order updates

  //Get the target nodeOrder
  const targetPathwayRootDoc = await dbProvider.getDoc<INodeRootDoc>(
    getNodeOrderPath(target.tenantId, target.pathwayId, target.collectionId),
    NODES_ROOT_DOC_NAME
  );

  //If the node order does not exist create a default array
  if (!Array.isArray(targetPathwayRootDoc.nodeOrder)) {
    targetPathwayRootDoc.nodeOrder = [];
  }

  //If we are not replacing then just add the nodes to the end
  if (!replaceExisting) {
    targetPathwayRootDoc.nodeOrder?.push(...nodesToCreate.map((x) => x.id!));
  }

  //If we are replacing then we can just copy the node order, if it exists
  // otherwise we will have to create new one.
  if (replaceExisting) {
    const sourcePathwayRootDoc = await dbProvider.getDoc<INodeRootDoc>(
      getNodeOrderPath(source.tenantId, source.pathwayId, source.collectionId),
      NODES_ROOT_DOC_NAME
    );

    targetPathwayRootDoc.nodeOrder = sourcePathwayRootDoc.nodeOrder ?? [];
  }

  await dbProvider.updateDocument(
    getNodeOrderPath(target.tenantId, target.pathwayId, target.collectionId),
    targetPathwayRootDoc,
    importUserEmail,
    true
  );
};

/**
 * Shows a pathway
 * @param tenantId The tenant Id
 * @param pathwayId The pathway to be published
 */
export const publishPathway = async (tenantId: string, pathwayId: string) => {
  const db = firebase.firestore();
  const key = pathwayId;
  const publishedField = {
    [key]: {
      published: true,
    },
  };

  await db
    .collection(tenantId)
    .doc("Published")
    .set(publishedField, { merge: true });
};

/**
 * Hides a pathway
 * @param tenantId The tenant Id
 * @param pathwayId The pathway to be published
 */
export const unPublishPathway = async (tenantId: string, pathwayId: string) => {
  const db = firebase.firestore();
  const key = pathwayId;
  const publishedField = {
    [key]: {
      published: false,
    },
  };

  await db
    .collection(tenantId)
    .doc("Published")
    .set(publishedField, { merge: true });
};
