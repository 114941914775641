import useUser from "features/hooks/useUser";

const SuperAdminGate = ({ children }: { children: any }) => {
  const { profile } = useUser();
  if (profile && profile.superadmin) {
    return <>{children}</>;
  }

  return null;
};

export default SuperAdminGate;
