export const docLibStyles = {
  actionBarBtnContainer: {
    display: "flex",
    alignItems: "center",
  },
  actionBarFilterToggle: {
    height: 32,
  },
  allGridsContainer: {
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
  },
  gridContainer: {
    position: "relative",
    width: 165,
    wordWrap: "break-word",
    margin: 5,
  },
  fileTypeIconContainer: {
    background: "white",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
    borderRadius: 8,
    padding: 46,
    display: "flex",
    justifyContent: "center",
  },
  dropzoneMoveFilesStyle: {
    cursor: "pointer",
  },
  dropzoneAcceptedMove: {
    outline: "none",
    transition: "transform 250ms",
    transform: "scale(1.3)",
  },
  actionBar: {
    minHeight: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropDown: {
    marginLeft: 6,
    zIndex: 100,
  },
  folderPathContainer: {
    display: "flex",
    alignItems: "center",
    padding: 3,
    minHeight: 40,
    position: "relative",
  },
  folderPath: {
    maxWidth: "95%",
    wordWrap: "break-word",
  },
} as const;
