import { useEffect, useState } from "react";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import { IModule } from "../../../types/CrossCover";
import { getActiveGroupsFromModules } from "./helpers/galleryHelpers";

interface ImageGroupSelectorProps {
  moduleName: string;
  imageGroups: Record<string, string[]>;
  addGroupsToImage: (moduleName: string, groups: string[]) => void;
  galleryModules: IModule[];
}

const ImageGroupSelector = ({
  moduleName,
  imageGroups,
  addGroupsToImage,
  galleryModules,
}: ImageGroupSelectorProps) => {
  const [selectorImageGroups, setselectorImageGroups] = useState<string[]>([]);

  useEffect(() => {
    if (imageGroups) {
      for (const imageGroup of Object.entries(imageGroups)) {
        if (imageGroup[0] === moduleName) {
          setselectorImageGroups(imageGroup[1]);
        }
      }
    }
  }, [imageGroups, moduleName]);

  const getGroupsForDropdown = () => {
    if (galleryModules) {
      const groupOptions = getActiveGroupsFromModules(galleryModules)!
        .filter((options) => options.status === "ACTIVE")
        .map((module) => {
          return (
            module.moduleId === moduleName && {
              text: module.groupName,
              value: module.id,
            }
          );
        })
        .filter((x) => x);

      return groupOptions;
    }
  };

  return (
    <Dropdown
      placeholder="Groups"
      value={selectorImageGroups}
      onChange={(e, d) => {
        setselectorImageGroups(d.value as string[]);
        addGroupsToImage(moduleName, d.value as string[]);
      }}
      search
      multiple={true}
      selection
      options={getGroupsForDropdown() as DropdownItemProps[]}
    />
  );
};
export default ImageGroupSelector;
