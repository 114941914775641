import UploadFile from "./UploadFile";
import UploadFolder from "./UploadFolder";

import { docLibStyles } from "./utils/DocumentLibraryStyles";
import { FilterCriteria, viewType } from "./docLibTypes/DocumentLibraryTypes";
import { IDocument, IFolder } from "../../../types/DocumentLibrary";
import ViewType from "app/components/ViewType";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";

interface ActionBarProps {
  documents: IDocument[];
  filterBy?: FilterCriteria;
  folders: IFolder[];
  folderPath: string;
  viewType: viewType;
  setFilterCriteria: (value: FilterCriteria) => void;
  setViewType: (value: viewType) => void;
}

const DocumentLibraryActions = ({
  documents,
  filterBy,
  folders,
  folderPath,
  viewType,
  setFilterCriteria,
  setViewType,
}: ActionBarProps) => {
  return (
    <>
      <div style={docLibStyles.actionBar}>
        <div style={docLibStyles.actionBarBtnContainer}>
          <div style={{ marginRight: "16px" }}>
            <ViewType
              viewType={viewType}
              setViewType={setViewType}
              leftPopuptext="List View"
              rightPopuptext="Grid View"
              iconLeft={<ListIcon />}
              iconRight={<GridViewIcon />}
            />
          </div>

          <UploadFile
            documents={documents}
            filterBy={filterBy}
            folderPath={folderPath}
          />
          <UploadFolder
            folderPath={folderPath}
            folders={folders}
            setFilterCriteria={setFilterCriteria}
          />
        </div>
      </div>
    </>
  );
};

export default DocumentLibraryActions;
