import useUser from "features/hooks/useUser";
import { Redirect, Route } from "react-router-dom";

interface ISecureRouteProps {
  children?: React.ReactNode;
  roles?: string[];
  path: string;
  exact?: boolean;
}
const SecureRoute = ({ children, path, roles, exact }: ISecureRouteProps) => {
  const { profile, isAuthorised } = useUser();
  const redirectTarget = !Boolean(profile?.uid) ? "/login" : "/not-authorised";

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        isAuthorised(roles) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectTarget,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default SecureRoute;
