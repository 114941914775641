import { INode } from "../../../../types/Nodes";
import SafeHtml from "app/components/SafeHtml";
import Segment from "app/components/SemanticUIMirrors/Segement";
import NodeViewJson from "app/components/NodeViewJSON";

interface INodeInfoTenantAndNodeProps {
  node: INode;
  allowHtml?: boolean;
}

const NodeInfoTenantAndNode = ({
  node,
  allowHtml,
}: INodeInfoTenantAndNodeProps) => {
  return (
    <>
      <Segment data-testid="node-preview-header">
        <h3 style={{ textAlign: "center" }}>
          <SafeHtml html={node.title} allowHtml={allowHtml} />
          <NodeViewJson node={node} inverse />
        </h3>
      </Segment>
    </>
  );
};

export default NodeInfoTenantAndNode;
