import SafeHtml from "app/components/SafeHtml";
import { Segment, List } from "semantic-ui-react";
import { INode } from "../../../../types/Nodes";

interface INodeFilesViewProps {
  node: INode;
  allowHtml?: boolean;
}
const NodeFilesView = ({ node, allowHtml }: INodeFilesViewProps) => {
  return (
    <Segment>
      <List>
        {node.files &&
          node.files.map(
            (file, index) =>
              node.fileNames[index] !== " " && (
                <List.Item key={index}>
                  <a
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <SafeHtml
                      html={node.fileNames[index]}
                      allowHtml={allowHtml}
                    />
                  </a>
                </List.Item>
              )
          )}
      </List>
    </Segment>
  );
};

export default NodeFilesView;
