import { Timestamp } from "firebase/firestore";

// Numbers need to be in milliseconds
const dateToString = (date: Date | number | Timestamp) => {
  if (date instanceof Timestamp) {
    date = date.toDate();
  }

  return new Intl.DateTimeFormat("en-GB", {
    dateStyle: "medium",
    timeStyle: "short",
  }).format(date);
};

export default dateToString;
