import { INodeContext } from "features/hooks/navigatorReducer";
import { v4 as uuid4 } from "uuid";

export interface ISTartBackLogicField {
  questionLogic: string;
  type: string;
  logicId: string;
  options: { text: string; value: number; valueId: string }[];
}

export interface ISTarTBackResult {
  totalScore: number;
  subScore: number;
}

// Todo data used in the logic store it here, the rest in firestore
export const STarTBackWidgetInfos: {
  licenceName: string;
  question: string;
  type: string;
  logicFields: ISTartBackLogicField[];
} = {
  licenceName: "STarTBack",
  question:
    "Thinking about the last 2 weeks tick your response to the following questions:",
  type: "Screening Tool",
  logicFields: [
    {
      questionLogic:
        "Has your back pain spread down your leg(s) at some time in the last 2 weeks?",
      logicId: uuid4(),
      options: [
        { text: "No", value: 0, valueId: uuid4() },
        { text: "Yes", value: 1, valueId: uuid4() },
      ],
      type: "yesno",
    },
    {
      questionLogic:
        "Have you had pain in the shoulder or neck at some time in the last 2 weeks?",
      logicId: uuid4(),
      options: [
        { text: "No", value: 0, valueId: uuid4() },
        { text: "Yes", value: 1, valueId: uuid4() },
      ],
      type: "yesno",
    },
    {
      questionLogic:
        "Have you only walked short distances because of your back pain?",
      logicId: uuid4(),
      options: [
        { text: "No", value: 0, valueId: uuid4() },
        { text: "Yes", value: 1, valueId: uuid4() },
      ],
      type: "yesno",
    },
    {
      questionLogic:
        "In the last 2 weeks, have you dressed more slowly than usual because of back pain?",
      logicId: uuid4(),
      options: [
        { text: "No", value: 0, valueId: uuid4() },
        { text: "Yes", value: 1, valueId: uuid4() },
      ],
      type: "yesno",
    },
    {
      questionLogic:
        "Do you think it’s not really safe for a person with a condition like yours to be physically active?",
      logicId: uuid4(),
      options: [
        { text: "No", value: 0, valueId: uuid4() },
        { text: "Yes", value: 1, valueId: uuid4() },
      ],
      type: "yesno",
    },
    {
      questionLogic:
        "Have worrying thoughts been going through your mind a lot of the time?",
      logicId: uuid4(),
      options: [
        { text: "No", value: 0, valueId: uuid4() },
        { text: "Yes", value: 1, valueId: uuid4() },
      ],
      type: "yesno",
    },
    {
      questionLogic:
        "Do you feel that your back pain is terrible and it’s never going to get any better?",
      logicId: uuid4(),
      options: [
        { text: "No", value: 0, valueId: uuid4() },
        { text: "Yes", value: 1, valueId: uuid4() },
      ],
      type: "yesno",
    },
    {
      questionLogic:
        "In general have you stopped enjoying all the things you usually enjoy?",
      logicId: uuid4(),
      options: [
        { text: "No", value: 0, valueId: uuid4() },
        { text: "Yes", value: 1, valueId: uuid4() },
      ],
      type: "yesno",
    },
    {
      questionLogic:
        "Overall, how bothersome has your back pain been in the last 2 weeks?",
      logicId: uuid4(),
      options: [
        { text: "Not at All", value: 0, valueId: uuid4() },
        { text: "Slightly", value: 0, valueId: uuid4() },
        { text: "Moderately", value: 0, valueId: uuid4() },
        { text: "Very Much", value: 1, valueId: uuid4() },
        { text: "Extremely", value: 1, valueId: uuid4() },
      ],
      type: "subjective",
    },
  ],
};

export const calculateTotals = (
  fieldToggled: INodeContext
): ISTarTBackResult => {
  const totalScore = fieldToggled.fields.reduce(
    (tot, item) => tot + +item.value,
    0
  );

  const subScore = fieldToggled.fields
    .filter((field) => field.order >= 4)
    .reduce((total, item) => (total += +item.value), 0);

  return {
    totalScore,
    subScore,
  };
};
