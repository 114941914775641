import "firebase/compat/firestore";
import { Modal, Icon, Header, Form, Message } from "semantic-ui-react";
import DropZone from "./DropZone";
import { FilterCriteria } from "./docLibTypes/DocumentLibraryTypes";
import { IDocument } from "../../../types/DocumentLibrary";
import useUploadFile from "./utils/useUploadFile";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "app/components/LoadingButton";
import useUser from "features/hooks/useUser";

interface UploadFileProps {
  documents: IDocument[];
  filterBy?: FilterCriteria;
  folderPath: string;
}

const UploadFile = ({ documents, filterBy, folderPath }: UploadFileProps) => {
  const { activeTenantId, profile } = useUser();
  if (activeTenantId === undefined) {
    throw Error("FileActions: tenantId is undefined");
  }

  const {
    changeFileName,
    fileName,
    fileTitle,
    formError,
    loading,
    onCancelUpload,
    onSaveUpload,
    setFileName,
    setFileSize,
    setFileTitle,
    setUploadFile,
  } = useUploadFile(profile, activeTenantId, documents, folderPath, filterBy);

  return (
    <>
      <Modal
        closeIcon
        onClose={onCancelUpload}
        trigger={
          <Button variant="contained" startIcon={<UploadFileIcon />}>
            Upload New
          </Button>
        }
      >
        {fileName ? (
          <Modal.Header>File Upload</Modal.Header>
        ) : (
          <DropZone
            filterBy={filterBy}
            setFileSize={setFileSize}
            setUploadFile={setUploadFile}
            setFileName={setFileName}
            setFileTitle={setFileTitle}
          />
        )}

        {fileName && (
          <>
            <Modal.Content>
              <Header as="h5">
                <Icon name="info circle" />
                <Header.Content>
                  Enter a title for your file which will be entered into the
                  title column in the file gallery. Adding a title will allow
                  you to search by title name and make files more recognisable.
                </Header.Content>
              </Header>
              <Form error>
                {formError && <Message error content={formError} />}
                {changeFileName && (
                  <Form.Input
                    label="Change the file name"
                    fluid
                    placeholder={fileName}
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                  />
                )}
                <Form.Input
                  label="Give your file a title"
                  fluid
                  placeholder={fileTitle}
                  value={fileTitle}
                  onChange={(e) => setFileTitle(e.target.value)}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button type="submit" onClick={onCancelUpload}>
                Cancel
              </Button>

              <LoadingButton
                icon={<CheckIcon />}
                isLoading={loading}
                variant="contained"
                onClick={onSaveUpload}
                color="success"
                type="submit"
              >
                Upload
              </LoadingButton>
            </Modal.Actions>
          </>
        )}
      </Modal>
    </>
  );
};

export default UploadFile;
