import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { IProfile } from "../../../types/User";

export type UserContextState = {
  firebaseUser: User | null;
  userProfile?: IProfile;
  userPermissions: string[];
};

const initialState: UserContextState = {
  firebaseUser: null,
  userPermissions: [],
};

export const userSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    updateFirebaseUser: (state, action: PayloadAction<User | null>) => {
      state.firebaseUser = action.payload;
    },
    updateUserProfile: (state, action: PayloadAction<IProfile>) => {
      state.userProfile = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateFirebaseUser, updateUserProfile } = userSlice.actions;

export default userSlice.reducer;
