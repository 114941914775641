import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { AppBar } from "./TopBarMobile";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

const BannerHeaderMobile = () => {
  return (
    <AppBar sx={{ px: 2 }}>
      <div style={{ flex: 1 }}></div>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <IconButton color="inherit" aria-label="menu" sx={{ m: 0 }}>
          <img
            style={{ height: "25px" }}
            src="https://firebasestorage.googleapis.com/v0/b/orthopathwaytestdatabase.appspot.com/o/assets%2Freverse_crosscover_logo_portrait.svg?alt=media&token=3df345b1-dc42-4ad7-8b24-c16a8238ad6e"
            alt="Crosscover logo"
          />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flex: 1,
        }}
      >
        <IconButton
          aria-label="help desk"
          href="https://primumdigital.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noreferrer"
        >
          <HelpOutlineIcon />
        </IconButton>
        <Typography>{process.env.REACT_APP_VERSION}</Typography>
      </Box>
    </AppBar>
  );
};

export default BannerHeaderMobile;
