import React, { useState } from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import { INode, IDynamicNode } from "../../../types/Nodes";
import ReportIssueForm from "../issues/reportIssueForm";

const ReportIssueModal = ({
  node,
  pathwayId,
}: {
  node: INode | IDynamicNode;
  pathwayId: string;
}) => {
  // const logo = {
  //   height: "50px",
  //   width: "auto",
  //   marginLeft: "0px",
  //   position: "relative",
  //   zIndex: "0",
  // };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Modal
      size="small"
      closeIcon="close"
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      trigger={
        <Button color="red" fluid onClick={() => setIsModalOpen(true)}>
          <Icon name="bullhorn" />
          Report Issue
        </Button>
      }
      style={{
        marginTop: "100px",
      }}
    >
      <Modal.Header>
        <Icon name="bullhorn" />
        Report an Issue with this Pathway
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ReportIssueForm
            node={node}
            pathwayId={pathwayId}
            closeModal={() => setIsModalOpen(false)}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default ReportIssueModal;
