import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReferenceDocuments } from "../../../types/CrossCover";

export type CrossCoverContextState = {
  providerError?: string;
  isProviderLoaded: boolean;
  referenceDocuments: IReferenceDocuments;
};

const initialState: CrossCoverContextState = {
  isProviderLoaded: false,
  referenceDocuments: { privacyPolicy: "", userManual: "" },
};

export const crossCoverSlice = createSlice({
  name: "crossCover",
  initialState: initialState,
  reducers: {
    updateProviderError: (state, action: PayloadAction<string>) => {
      state.providerError = action.payload;
    },
    updateProviderLoaded: (state, action: PayloadAction<boolean>) => {
      state.isProviderLoaded = action.payload;
    },
    updateReferenceDocuments: (
      state,
      action: PayloadAction<IReferenceDocuments>
    ) => {
      state.referenceDocuments = {
        ...state.referenceDocuments,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateProviderError,
  updateProviderLoaded,
  updateReferenceDocuments,
} = crossCoverSlice.actions;

export default crossCoverSlice.reducer;
