import { Box } from "@mui/material";
import { IFilePointer, IVideoBlock } from "../../../../types/Nodes";

export const getVideoPlayerSrc = (pointer: IFilePointer) => {
  if (pointer.storageType === "youtube") {
    return [
      `//www.youtube.com/embed/${pointer.pointer}`,
      "?autohide=true",
      `&amp;autoplay=false`,
      `&amp;color=${encodeURIComponent("#444")}`,
      `&amp;hq=true`,
      "&amp;jsapi=false",
      `&amp;modestbranding=false`,
      `&amp;rel=0`,
    ].join("");
  }

  if (pointer.storageType === "vimeo") {
    return [
      `//player.vimeo.com/video/${pointer.pointer}`,
      "?api=false",
      `&amp;autoplay=false`,
      "&amp;byline=false",
      `&amp;color=${encodeURIComponent("#444")}`,
      "&amp;portrait=false",
      "&amp;title=false",
    ].join("");
  }

  return pointer.pointer;
};

const VideoBlock = ({ block }: { block: IVideoBlock }) => {
  // const iframeVideos = ["firebase", "vimeo"];
  const videoSrc = getVideoPlayerSrc(block.blockData.filePointer);
  //Currently all videos are inserted as iframes
  return (
    <Box
      sx={{
        position: "relative",
        pb: "56.25%",
        overflow: "hidden",
        height: 0,
        maxWidth: "100%",
      }}
    >
      <Box sx={{ display: "block" }}>
        {/* {iframeVideos.includes(block.blockData.filePointer.storageType) && ( */}
        <iframe
          width="100%"
          height="100%"
          src={videoSrc}
          style={{
            position: "absolute",
            border: 0,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            margin: 0,
            padding: 0,
          }}
          title={block.blockData.filePointer.pointerTitle}
        />
        {/* )} */}
        {/* {!iframeVideos.includes(block.blockData.filePointer.storageType) && (
        <video controls width="100%" controlsList="nodownload">
          <source src={videoSrc} />
          Sorry, your browser doesn't support embedded videos.
        </video>
      )} */}
      </Box>
    </Box>
  );
};

export default VideoBlock;
