import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NodeViewPopUp from "./NodeViewPopUp";
import { ButtonGroup } from "semantic-ui-react";
import { IDynamicNode, INode } from "../../../../../types/Nodes";

interface IPathwayNodeOptions {
  text: string;
  value: string;
  isStartNode: boolean;
  node?: IDynamicNode | INode;
}

const NodeSelectorPopUp = ({
  linkedNodeId,
  nodeOptions,
  pathway,
  onSelect,
}: {
  linkedNodeId: string;
  nodeOptions: IPathwayNodeOptions[];
  pathway: string;
  onSelect: (nodeId: string) => void;
}) => {
  const [popupNodeOpen, setPopupNodeOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState<
    IPathwayNodeOptions | undefined
  >(undefined);

  const handleOnSelect = () => {
    onSelect(selectedNode?.node?.id ?? "");
    setPopupNodeOpen(false);
    setSelectedNode(undefined);
  };

  const handleOnCancel = () => {
    setPopupNodeOpen(false);
    setSelectedNode(undefined);
  };

  useEffect(() => {
    const connectedOption = nodeOptions.find(
      (option) => option.value === linkedNodeId
    );

    if (connectedOption && !selectedNode) {
      setSelectedNode(connectedOption);
    }
  }, [linkedNodeId, nodeOptions, selectedNode]);

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <TextField
          sx={{ flexGrow: 1, mr: 2 }}
          variant="outlined"
          disabled
          label="Linked to node ..."
          value={selectedNode?.text ?? "I will connect later"}
        ></TextField>
        <Button variant="contained" onClick={() => setPopupNodeOpen(true)}>
          {" "}
          Select Target Node
        </Button>
        <Dialog
          fullWidth
          maxWidth="lg"
          sx={{
            "& .MuiPaper-root": {
              minHeight: "80vh",
              overflowY: "hidden",
              maxHeight: "80vh",
            },
          }}
          open={popupNodeOpen}
          onClose={() => handleOnCancel()}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            id="draggable-dialog-title"
          >
            <h3 style={{ margin: 0 }}>Select Node</h3>
            <IconButton
              aria-label="close"
              onClick={() => handleOnCancel()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ display: "flex", p: 1 }}>
            <Box sx={{ width: "50%", overflowY: "auto" }}>
              <NodeViewPopUp
                node={selectedNode?.node as IDynamicNode}
                open={popupNodeOpen}
                pathway={pathway}
              />
            </Box>
            <Box sx={{ width: "50%", overflowY: "auto", overflowX: "hidden" }}>
              {nodeOptions.map((option: IPathwayNodeOptions, index: number) => (
                <MenuItem
                  selected={
                    (selectedNode && option === selectedNode) ||
                    (!selectedNode && option.value === linkedNodeId)
                  }
                  sx={{ whiteSpace: "unset", wordBreak: "break-all" }}
                  key={index}
                  onClick={() => setSelectedNode(option)}
                >
                  {option.text}
                </MenuItem>
              ))}
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions
            sx={{
              backgroundColor: "rgba(118, 118, 118, 0.08)",
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mr: 1 }}
                onClick={() => handleOnCancel()}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={() => handleOnSelect()}>
                Link To Selected Node
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default NodeSelectorPopUp;
