import { useState } from "react";

import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import useUserPrivateProfile from "features/hooks/useUserPrivateProfile";
import { MFAFactors } from "../../../../types/User";
import RegisterForAuthenticator from "./RegisterForAuthenticator";
import RegisterPhoneNumber from "./RegisterPhoneNumberMFA";
import LoginForm from "./LoginForm";
import useUser from "features/hooks/useUser";

interface ManageMFAProps {
  skipMFAStep?: boolean;
  isAccountSettings?: boolean;
  handleNextStep?: () => void;
}

const ManageMFA = ({
  skipMFAStep,
  handleNextStep,
  isAccountSettings,
}: ManageMFAProps) => {
  const [registrationType, setRegistrationType] = useState<MFAFactors>("");
  const [MFAChangeInProgress, setMFAChangeInProgress] =
    useState<boolean>(false);
  const [recentLogin, setRecentLogin] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { mfaFactorEnabled, disableMFA, addMFAFactor } =
    useUserPrivateProfile();
  const { profile } = useUser();

  const resetMFA = () => {
    setRegistrationType("");
    setMFAChangeInProgress(false);
    setRecentLogin(false);
    setError("");
  };

  const enableMFALater = () => {
    skipMFAStep ? handleNextStep && handleNextStep() : resetMFA();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Typography
        variant={isAccountSettings ? "subtitle1" : "h5"}
        component="h2"
        sx={{ fontWeight: 700, marginBottom: 2 }}
      >
        Multi-Factor Authentication (MFA)
      </Typography>

      {error && (
        <>
          <Alert severity="error">{error}</Alert>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          flex: 1,
        }}
      >
        {!MFAChangeInProgress && (
          <>
            <Alert severity="warning">
              <Typography gutterBottom variant="subtitle1">
                Multi-Factor Authentication (MFA) provides
                <strong> an added layer of security</strong> for your account
                and reduces the chance of unauthorised access to your account,
                even if your password is stolen.
                <br />
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                In addition to a username and password MFA adds the need for you
                to sign in with a One-Time-Passcode (OTP). <br />
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                Multi-Factor Authentication adds security by only allowing
                access to your account with something you know (your password),
                something you have (your phone) and potentially something you
                are (if you use biometrics to access your phone).
                <br />
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                <strong>
                  We highly recommend all Editors and Chief Editors enable MFA
                  to protect your pathways.
                </strong>
              </Typography>
            </Alert>
            <Box
              sx={{
                display: "flex",
                justifyContent: skipMFAStep ? "flex-end" : "flex-start",
                alignItems: "flex-end",
                flex: 1,
                gap: 2,
                flexWrap: "wrap-reverse",
              }}
            >
              {skipMFAStep && (
                <Button
                  sx={{
                    minWidth: "max-content",
                    width: "calc((450px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                  onClick={() => handleNextStep && handleNextStep()}
                >
                  I will enable later
                </Button>
              )}
              {!mfaFactorEnabled && (
                <Button
                  sx={{
                    minWidth: "max-content",
                    width: "calc((450px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                  variant="contained"
                  onClick={() => {
                    setRecentLogin(false);
                    setMFAChangeInProgress(true);
                    setRegistrationType("");
                  }}
                >
                  Enable Multi-Factor Authentication
                </Button>
              )}
            </Box>
          </>
        )}
        {isAccountSettings && !MFAChangeInProgress && mfaFactorEnabled && (
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ fontWeight: "bold" }}>
                Multi-Factor Authentication is{" "}
                {mfaFactorEnabled ? "enabled" : "disabled"} on your account.
              </Box>
              <Button
                variant="outlined"
                onClick={() => {
                  setRecentLogin(false);
                  setMFAChangeInProgress(true);
                }}
              >
                Disable Multi-Factor Authentication
              </Button>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Box sx={{ fontWeight: "bold" }}>Multi-Factor Registered </Box>
              {mfaFactorEnabled === "AUTHENTICATOR"
                ? "Authentication app"
                : "Phone"}
            </Box>
          </Box>
        )}

        {MFAChangeInProgress && !recentLogin && (
          <LoginForm
            sx={{ maxWidth: 400, width: "100%", alignSelf: "center" }}
            userEmail={profile.email}
            changeInProgress={MFAChangeInProgress}
            onLoginComplete={async () => {
              if (mfaFactorEnabled) {
                await disableMFA();
                setMFAChangeInProgress(false);
              }
              setRecentLogin(true);
            }}
          />
        )}

        {MFAChangeInProgress &&
          recentLogin &&
          registrationType === "" &&
          !mfaFactorEnabled && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography variant="h6" component="h3" sx={{ fontWeight: 700 }}>
                Authenticator App Verification
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flex: 1,
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{
                    minWidth: "calc(70% - 8px)",
                    width: "calc((450px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                >
                  Generate verification codes using authenticator apps such as
                  <strong> Google Authenticator</strong> or
                  <strong> Microsoft Authenticator</strong>.
                </Typography>

                <Button
                  sx={{
                    minWidth: "30%",
                    width: "calc((450px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                  variant="contained"
                  onClick={() => setRegistrationType("AUTHENTICATOR")}
                >
                  Register Authenticator
                </Button>
              </Box>
              <Alert severity="success">
                <Typography gutterBottom>
                  <strong>
                    This option is more secure since it doesn’t need a WIFI
                    signal or SMS signal.
                  </strong>
                </Typography>
              </Alert>
              <Divider sx={{ my: 1 }} />
              <Typography variant="h6" component="h3" sx={{ fontWeight: 700 }}>
                SMS Verification
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flex: 1,
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{
                    minWidth: "calc(70% - 8px)",
                    width: "calc((450px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                >
                  Receive verification codes via
                  <strong> SMS</strong> message.
                </Typography>
                <Button
                  sx={{
                    minWidth: "30%",
                    width: "calc((450px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                  variant="contained"
                  onClick={() => setRegistrationType("PHONE")}
                >
                  Register SMS
                </Button>
              </Box>
              <Alert severity="warning">
                <AlertTitle>
                  This option is less secure and relies upon receiving an SMS.
                </AlertTitle>
                1% to 5% of all SMS are lost in transit in the networks which
                may require you to make multiple requests. SMS signal in remote
                areas and inside hospitals isn’t reliable.
              </Alert>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  minWidth: "max-content",
                  width: "calc((450px - 100%) * 1000)",
                  maxWidth: "100%",
                }}
              >
                {skipMFAStep && (
                  <Button onClick={() => handleNextStep && handleNextStep()}>
                    I will enable later
                  </Button>
                )}
              </Box>
            </Box>
          )}

        {registrationType === "PHONE" && recentLogin && MFAChangeInProgress && (
          <Box sx={{ alignSelf: "center" }}>
            <RegisterPhoneNumber
              enableMFALater={enableMFALater}
              setRegistrationType={setRegistrationType}
              onRegistrationComplete={(factor: MFAFactors) => {
                addMFAFactor(factor);
                setMFAChangeInProgress(false);
                if (handleNextStep) {
                  handleNextStep && handleNextStep();
                }
              }}
              setRecentLogin={setRecentLogin}
            />
          </Box>
        )}

        {registrationType === "AUTHENTICATOR" &&
          recentLogin &&
          MFAChangeInProgress && (
            <RegisterForAuthenticator
              enableMFALater={enableMFALater}
              onRegistrationComplete={(factor: MFAFactors) => {
                addMFAFactor(factor);
                setMFAChangeInProgress(false);
                if (handleNextStep) {
                  handleNextStep && handleNextStep();
                }
              }}
              onRegistrationError={(error) => {
                setError(error);
              }}
            />
          )}
      </Box>
    </Box>
  );
};

export default ManageMFA;
