import {
  ILegacyNodeLogic,
  ILinkButtonData,
  ILogicField,
  ILogicRule,
  INode,
  INodeLogic,
  RuleConditions,
  RuleIf,
} from "../../../../types/Nodes";
import { IPathwayPublished } from "../../../../types/Pathway";
import { v4 as uuid } from "uuid";

export const NodeToLogic = (node: INode): INodeLogic => {
  // const logic: INodeLogic = {
  //   groups: [],
  //   rules: [],
  //   showScores: false,
  // };

  if (!Array.isArray(node.logicfields) || node.logicfields.length === 0) {
    return {
      groups: [],
      rules: [],
      showScores: false,
    };
  }

  const logic: INodeLogic = {
    groups: [],
    rules: [],
    showScores: false,
  };

  const groupNumber = new Set(node.logicgroups);

  for (const groupId of node.logicgroups) {
    const existingGroupIndex = logic.groups.findIndex(
      (x) => x.groupId === groupId
    );

    if (existingGroupIndex === -1) {
      logic.groups.push({
        groupId: groupId,
        title: Array.isArray(node.groupNames)
          ? node.groupNames[+groupId] ?? ""
          : "",
        fields: [],
      });
    } else {
      if (
        logic.groups[existingGroupIndex].title === "" &&
        Array.isArray(node.groupNames) &&
        node.groupNames[+groupId] !== ""
      ) {
        logic.groups[existingGroupIndex].title = node.groupNames[+groupId];
      }
    }
  }

  //console.log(JSON.stringify(logic.groups));

  for (const groupId of groupNumber) {
    const fieldsInGroupIndexes = node.logicgroups.reduce<number[]>(
      (acc, element, index) => {
        if (element === groupId) {
          acc.push(index);
        }
        return acc;
      },
      []
    );

    // for (const [indexx, logicfield] of Object.entries(node.logicfields)) {
    for (const logicFieldIndex of fieldsInGroupIndexes) {
      //There is no logic field for this index, so we just skip it
      if (node.logicfields[logicFieldIndex] === "") {
        continue;
      }

      const field: ILogicField = {
        title: node.logicfields[logicFieldIndex],
        score: node.logicscores[logicFieldIndex],
      };
      const groupIndex = logic.groups.findIndex(
        (x) => x.groupId === node.logicgroups[logicFieldIndex]
      );

      logic.groups[groupIndex].fields.push(field);
    }
  }
  //console.log(JSON.stringify(logic.groups));

  //logic.groups.filter((x) => x.fields.length !== 0);

  if (node.ruleNames) {
    for (let i = 0; i < node.ruleNames.length; i++) {
      //Remove empty conditions
      if (node.ruleActions[i] === undefined) {
        continue;
      }
      const nodeAction = actionToRouteItem(
        node.ruleActions[i],
        node.ruleNames[i]
      );

      if (
        node.ruleTypes[i] !== "" &&
        node.ruleOperators[i] !== "" &&
        (node.ruleValues[i] !== "" ||
          node.ruleValuesMin[i] ||
          node.ruleValuesMax[i])
      ) {
        const rule: ILogicRule = {
          if: node.ruleTypes[i] as RuleIf,
          condition: node.ruleOperators[i] as RuleConditions,
          value:
            node.ruleOperators[i] === "is in range"
              ? node.ruleValuesMin[i]
              : node.ruleValues[i],
          maxValue: Array.isArray(node.ruleValuesMax)
            ? node.ruleValuesMax[i]
            : undefined,
          ...{...nodeAction,id:uuid()},
        };

        logic.rules.push(rule);
      }
    }
  }
  logic.showScores = node.showscores ?? false;

  return logic;
};

export const emptyLogicNode: ILegacyNodeLogic = {
  groupNames: [],
  logicgroups: [],
  logicfields: [],
  logicscores: [],
  ruleOperators: [],
  ruleValues: [],
  ruleValuesMin: [],
  ruleValuesMax: [],
  ruleNames: [],
  ruleActions: [],
  ruleTypes: [],
  showscores: true,
};

export const EmptyLogic = () => {
  return JSON.parse(JSON.stringify(emptyLogicNode));
};

export const LogicToNode = (logic: INodeLogic) => {
  const nodeLogic = JSON.parse(
    JSON.stringify(emptyLogicNode)
  ) as ILegacyNodeLogic;

  let indexCount = 0;
  nodeLogic.groupNames[0] = "";
  logic.groups.forEach((group, groupIndex) => {
    nodeLogic.groupNames[+group.groupId] = group.title ?? "";

    group.fields.forEach((field, fieldIndex) => {
      nodeLogic.logicgroups[indexCount] = group.groupId;
      nodeLogic.logicfields[indexCount] = field.title;
      nodeLogic.logicscores[indexCount] = field.score.toString();
      indexCount++;
    });
  });
  //Ensure that we don't have any undefined group name values
  // nodeLogic.groupNames = [...nodeLogic.groupNames].map((x) => (x ? x : ""));
  const maxGroupId = Math.max.apply(
    null,
    logic.groups.map((x) => +x.groupId)
  );
  for (let i = 0; i < maxGroupId; i++) {
    if (nodeLogic.groupNames[i] === undefined) {
      nodeLogic.groupNames[i] = "";
    }
  }

  logic.rules.forEach((rule, index) => {
    const action = routeItemToAction(rule);
    nodeLogic.ruleNames[index] = rule.linkText;
    nodeLogic.ruleOperators[index] = rule.condition;
    nodeLogic.ruleActions[index] = action;
    nodeLogic.ruleValues[index] = rule.value;
    nodeLogic.ruleValuesMin[index] =
      nodeLogic.ruleOperators[index] === "is in range" ? rule.value : "";

    nodeLogic.ruleValuesMax[index] =
      nodeLogic.ruleOperators[index] === "is in range"
        ? rule.maxValue ?? ""
        : "";
    nodeLogic.ruleTypes[index] = rule.if;
  });

  nodeLogic.showscores = logic.showScores;

  return nodeLogic;
};

export const actionToRouteItem = (
  action: string,
  actionText: string
): ILinkButtonData => {
  let pathwayId = "";
  let nodeId = "";

  if (action.substring(0, 2) === "@@") {
    const prefixRemoved = action.slice(2);
    const splitParts = prefixRemoved.split("/");
    pathwayId = splitParts.at(0) ?? "";
    nodeId = splitParts.at(1) ?? "";
  }

  if (action.substring(0, 2) !== "@@") {
    nodeId = action;
  }
  if (nodeId === undefined) {
    // console.log("Node Action", action, nodeId);
  }
  return {
    pathwayId: pathwayId,
    nodeId: nodeId,
    isPathwayToPathwayLink: action.substring(0, 2) === "@@",
    linkText: actionText,
    
  };
};

export const routeItemToAction = (nodeAction: ILinkButtonData) => {
  if (
    nodeAction.pathwayId !== "" &&
    nodeAction.nodeId !== "" &&
    nodeAction.isPathwayToPathwayLink
  ) {
    return `@@${nodeAction.pathwayId}/${nodeAction.nodeId}`;
  }

  if (
    nodeAction.pathwayId !== "" &&
    nodeAction.nodeId === "" &&
    nodeAction.isPathwayToPathwayLink
  ) {
    return `@@${nodeAction.pathwayId}`;
  }

  return nodeAction.nodeId;
};

export const nodeToRouteItems = (node: INode): ILinkButtonData[] => {
  return node.actions.map((action, index) =>
    actionToRouteItem(action, node.answers[index])
  );
};

export const routeItemsToNode = (nodeActions: ILinkButtonData[]) => {
  return nodeActions.map((action) => routeItemToAction(action));
};

// export const getNodeTitleFromAction = async (
//   tenantId: string,
//   //pathwayId: string,
//   collectionId: string,
//   routeItem: INodeAction
// ) => {
//   //const routeItem = actionToRouteItem(action, actionText);

//   const db = getDb();

//   //If we have a pathway id but no nodeId then we need to use the start node
//   if (routeItem.pathwayId && !routeItem.nodeId) {
//     const pathway = (await db.getDoc<ITenantPathway>(
//       getPathwayPath(tenantId),
//       routeItem.pathwayId
//     )) as unknown as Record<string, string[]>;
//     const nodes = await db.getCollection<INode>(
//       getPathwayPath(tenantId, routeItem.pathwayId, collectionId)
//     );

//     const orderName = getCollectionOrderName(collectionId);

//     const nodeOrder = getNodeOrder(pathway[orderName], nodes);

//     //If there is no node 0 it means there are no nodes in the pathway, so we need make sure the nodeId is
//     //an empty string
//     routeItem.nodeId = nodeOrder[0] ?? "";
//   }

//   // console.log(routeItem);
//   if (routeItem.nodeId !== "") {
//     const node = await db.getDoc<INode>(
//       getPathwayPath(tenantId, routeItem.pathwayId, collectionId),
//       routeItem.nodeId
//     );
//     return node.title;
//   }

//   return "";
// };

export interface IPathwayValidationResult {
  brokenActions: string[];
  danglingNodes: string[];
  unPublishedPathwayAction: string[];
}
export const pathwayValidator = (
  pathway: INode[],
  pathwayPublished: Record<string, IPathwayPublished>
): IPathwayValidationResult => {
  const nodeWithBrokenLinks = unconnectedActionsCheck(pathway);
  const danglingNodes = danglingNodeCheck(pathway);
  const unpublishedPathways = pathwayToPathwayCheck(pathway, pathwayPublished);

  const errors: IPathwayValidationResult = {
    brokenActions: [],
    danglingNodes: [],
    unPublishedPathwayAction: [],
  };

  nodeWithBrokenLinks.forEach((node) => errors.brokenActions.push(node.title));
  danglingNodes.forEach((node) => errors.danglingNodes.push(node.title));
  unpublishedPathways.forEach((node) =>
    errors.unPublishedPathwayAction.push(node.title)
  );
  return errors;
};

//Get the difference between two arrays
const arrayDifference = (source: string[], target: string[]): string[] => {
  return source.filter((x) => !target.includes(x));
};

//Simple validation to ensure that created connections. Connect to a valid node
const unconnectedActionsCheck = (pathway: INode[]): INode[] => {
  const nodeIdsInPathway = pathway.map((x) => x.id || "");
  //Remove all pathway to pathway links
  const pathwayActions = getAllPathwayActions(pathway).filter(
    (x) => !x.startsWith("@@")
  );

  const unconnectedActions = arrayDifference(pathwayActions, nodeIdsInPathway);
  const unconnectedActionsSet = [...new Set(unconnectedActions)];

  if (unconnectedActionsSet.length) {
    //If we have differences it means that we have unconnected actions
    //We now need to convert that to a help full message
    const nodesWithIssues: INode[] = [];

    for (const nodeId of unconnectedActionsSet) {
      nodesWithIssues.push(
        ...pathway.filter((x) => x.actions.includes(nodeId))
      );
    }
    return nodesWithIssues;
  }
  return [];
};

//Walks the pathway and then finds all nodes that are unreachable
const danglingNodeCheck = (pathway: INode[]): INode[] => {
  const startNode = pathway[0];

  const nodeIdsInPathway = pathway.map((x) => x.id || "");
  const connectedNodes = pathwayWalk(pathway, startNode.id!);
  const danglingNodes = arrayDifference(nodeIdsInPathway, connectedNodes);
  const danglingNodesSet = [...new Set(danglingNodes)];

  if (danglingNodesSet.length) {
    //If we have differences it means that we have unconnected actions
    //We now need to convert that to a help full message
    const nodesWithIssues = pathway.filter((x) =>
      danglingNodesSet.includes(x.id!)
    );

    return nodesWithIssues;
  }
  return [];
};

//Finds all pathway to pathway link and checks they exist and they are published
const pathwayToPathwayCheck = (
  pathway: INode[],

  published: Record<string, IPathwayPublished>
): INode[] => {
  const pathwayToPathwayActions = getAllPathwayActions(pathway).filter((x) =>
    x.startsWith("@@")
  );
  const actionsWithIssues: string[] = [];
  for (const action of pathwayToPathwayActions) {
    const pathwayName = action.substring(2);
    if (published[pathwayName]?.published !== true) {
      actionsWithIssues.push(action);
    }
  }
  const actionWithIssuesSet: string[] = [...new Set(actionsWithIssues)];
  const nodesWithIssues: INode[] = [];

  for (const nodeId of actionWithIssuesSet) {
    nodesWithIssues.push(...pathway.filter((x) => x.actions.includes(nodeId)));
  }

  return nodesWithIssues;
};

const getAllPathwayActions = (pathway: INode[]): string[] => {
  const nodeActions = pathway
    .flatMap((x) => x.actions)
    .filter((x) => Boolean(x));
  const ruleActions = pathway
    .flatMap((x) => x.ruleActions)
    .filter((x) => Boolean(x));
  return nodeActions.concat(ruleActions);
};

//Walk a pathway and returns all nodes included
export const pathwayWalk = (
  pathway: INode[],

  startNodeId: string
): string[] => {
  const startNode = pathway.find((x) => x.id === startNodeId)!;

  if (startNode) {
    const connectedNodes: string[] = [];
    connectedNodes.push(startNode.id || "");

    for (const action of startNode.actions) {
      if (connectedNodes.includes(action)) {
        const nodeId = pathwayWalk(pathway, action);
        connectedNodes.push(...nodeId);
      }
    }

    return connectedNodes;
  }

  return [];
  //Return the node id
};
