import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PowerSettingsNewRoundedIcon from "@mui/icons-material/PowerSettingsNewRounded";

import useUser from "features/hooks/useUser";
import { Link, useHistory } from "react-router-dom";
// import { IOption } from "app/components/SplitButton2";
import useTenants from "features/hooks/useTenants";
// import SecureLink from "app/components/SecureLink";
// import SearchPathways from "app/components/PathwaySearchDropdown/SearchPathways";
import CrossCoverLogo from "app/assets/reverse_crosscover_logo_landscape.svg";
import { hexToRgb } from "@mui/system";
import SharedAccountGate from "app/components/SharedAccountGate";
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
//0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)

const hexToRgbToValue = (hexValue: string) =>
  hexToRgb(hexValue).replace("rgb(", "").replace(")", "");

const AppBar = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  //boxShadow: theme.shadows[5],
  boxShadow: `0px 3px 5px -1px rgba(${hexToRgbToValue(
    theme.module.colour
  )},0.2), 0px 5px 8px 0px rgba(${hexToRgbToValue(
    theme.module.colour
  )},0.14), 0px 1px 14px 0px rgba(${hexToRgbToValue(
    theme.module.colour
  )},0.12)`,
  //boxShadow: `0px 3px 5px -1px rgb(${hexToRgb(theme.module.colour)},0.8)`,
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2, 3, 2, 3),
  borderBottom: "4px solid #fff",
  borderBottomColor: theme.module.colour,
  zIndex: 10,
}));

export const NavLogo = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "> #logotext": {
    display: "block",
    color: theme.palette.primary.contrastText,
  },
}));

export const NavSeparator = styled("hr")(({ theme }) => ({
  height: "32px",
  border: "0.5px solid rgba(255,255,255,0.5)",
  marginRight: theme.spacing(3),
  marginLeft: theme.spacing(3),
  marginTop: 0,
  marginBottom: 0,
}));

const NavGroup = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const NavLinksContainer = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
}));

const NavIconsContainer = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
}));

const NavLink = styled(Link)(({ theme }) => ({
  marginRight: theme.spacing(3),
  color: "white",
  "&:hover": {
    color: theme.palette.primary.light,
  },
}));

// const NavSearchContainer = styled("div")(({ theme }) => ({
//   borderRadius: "4px",
//   backgroundColor: "rgba(255,255,255, 0.3)",
//   border: "1px solid rgba(255,255,255, 0.5)",
//   display: "flex",
//   flex: "1",
//   alignItems: "center",
//   paddingRight: theme.spacing(2)

// }));

// const PathwaySearchInput = styled("input")(({ theme }) => ({
//   borderRadius: "4px",
//   padding: theme.spacing(1, 2, 1, 2),
//   fontSize: "1rem",
//   lineHeight: "125%",
//   backgroundColor: "rgba(255, 255, 255, 0.0)",
//   border: "none",
//   color: "white",
//   display: "flex",
//   flex: "1",
//   "::placeholder": {
//     color: "white",
//     opacity: "1"
//   }

// }));

const TopBar = () => {
  const { profile, logOut } = useUser();
  const history = useHistory();

  const { activeTenant } = useTenants();

  const onLogout = async () => {
    await logOut();
    history.push("/");
  };

  // let options: IOption[] = [];
  // if (userTenants) {
  //   options = userTenants.map((tenant) => ({
  //     label: tenant.shortName,
  //     value: tenant.id || "",
  //   }));
  // }

  return (
    <AppBar>
      <NavGroup>
        <NavLogo to="/launchpads">
          <IconButton color="inherit" aria-label="menu" sx={{ m: 0, p: 0 }}>
            <img src={CrossCoverLogo} alt="CrossCover Logo" height="32px" />
          </IconButton>
        </NavLogo>

        <NavSeparator />

        <NavLinksContainer>
          <NavLink to="/launchpads">
            <Typography variant="subtitle3">Pathways</Typography>
          </NavLink>
          <NavLink to="/tutorials">
            {" "}
            <Typography variant="subtitle3">Tutorials</Typography>
          </NavLink>
        </NavLinksContainer>
      </NavGroup>

      {/* <SearchPathways
        onSelect={(pathwayId) => {
          history.push(`/pathway/${pathwayId}`);
        }}
      /> */}

      {/* <NavSearchContainer>
        <PathwaySearchInput placeholder="Search pathways..." />
        <SearchOutlinedIcon></SearchOutlinedIcon>
      </NavSearchContainer> */}

      <NavGroup>
        <Typography variant="h6">{activeTenant?.shortName}</Typography>

        {/* {options.length === 1 && ( */}

        {/* )} */}
        {/* {options.length > 1 && (
        <SplitButton
          options={options}
          onOptionSelect={(option) => {
            setActiveTenant(option.value);
          }}
          sx={{ ml: 2, fontSize: "1.8rem", fontWeight: "bold" }}
          defaultOption={activeTenant?.id || ""}
        ></SplitButton>
      )} */}
        <NavSeparator />

        <NavIconsContainer>
          {Boolean(profile) && (
            <div>
                <SharedAccountGate allowed={false}>
                  <Tooltip title="Account Settings">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      component={Link}
                      to="/user-profile"
                      sx={{
                        padding: "8px",
                        "& > svg": {
                          width: "24px",
                          height: "24px",
                        },
                        "&:hover": {
                          color: "primary.light",
                        },
                        margin: "0 8px 0 0",
                      }}
                    >
                      <AccountCircle />
                    </IconButton>
                  </Tooltip>
                  </SharedAccountGate>
              <Tooltip title="Log Out">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={onLogout}
                  color="inherit"
                  sx={{
                    padding: "8px",
                    "& > svg": {
                      width: "24px",
                      height: "24px",
                    },
                    "&:hover": {
                      color: "primary.light",
                    },
                    margin: "0 8px 0 0",
                  }}
                >
                  <PowerSettingsNewRoundedIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}

          <Button
            href="https://primumdigital.atlassian.net/servicedesk/customer/portals"
            target="_blank"
            rel="noreferrer"
            sx={{
              mr: 1,
              ml: 1,
              borderColor: "primary.contrastText",
              color: "primary.contrastText",
              "&:hover": {
                borderColor: "primary.light",
                color: "primary.light",
              },
            }}
            variant="outlined"
          >
            Help Desk
          </Button>
        </NavIconsContainer>

        <Typography>{process.env.REACT_APP_VERSION}</Typography>
      </NavGroup>
    </AppBar>
  );
};

export default TopBar;
