import usePathwayIssues from "features/hooks/usePathwayIssues";
import { NavLink } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
// import FindReplaceIcon from "@mui/icons-material/FindReplace";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ApprovalIcon from "@mui/icons-material/Approval";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BrushIcon from "@mui/icons-material/Brush";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {
  ListItemText,
  MenuItem,
  ListItemIcon,
  Badge,
  useTheme,
  Box,
  Tooltip,
} from "@mui/material";
import SuperAdminGate from "../common/EditConsole/SuperAdminGate";
import BusinessIcon from "@mui/icons-material/Business";
import InsightsIcon from "@mui/icons-material/Insights";
import PermissionGate from "app/components/PermissionGate";
import OperationDashboardIcon from "app/components/OperationDashboardIcon";
interface IEditConsoleProps {
  isEditConsoleCollapsed: boolean;
  toggleEditConsoleCollapsed: () => void;
}

const EditConsole = ({
  isEditConsoleCollapsed: isEditConsoleOpen,
  toggleEditConsoleCollapsed,
}: IEditConsoleProps) => {
  const { issues } = usePathwayIssues();
  const theme = useTheme();
  const unresolvedIssuesNumber = issues
    ? issues.filter((x) => x.resolved === false).length
    : 0;

  const styleListElement = {
    textAlign: "left",
    "& span.MuiTypography-root": {
      fontWeight: "700",
      fontSize: "14px",
    },
    margin: "2px 16px",
    borderRadius: "4px",
    padding: "8px 12px 8px 8px",
    "&:svg": {
      width: "24px",
      height: "24px",
    },
    "&:hover": {
      color: "black",
    },
  };

  const activeListElementStyle = {
    background: theme.palette.primary.main,
    color: "#FFF",
  };

  return (
    <>
      <div
        style={{ marginBottom: "0px", cursor: "pointer" }}
        onClick={() => toggleEditConsoleCollapsed()}
      >
        <Box
          sx={{
            px: 3,
            py: "20px",
            mt: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isEditConsoleOpen && (
            <Box
              sx={{
                fontWeight: "500",
                mr: 1,
              }}
            >
              Dashboards
            </Box>
          )}
          {isEditConsoleOpen && (
            <ArrowBackIosIcon
              sx={{ color: "rgba(0, 0, 0, 0.54)", width: 14, height: 24 }}
            />
          )}
          {!isEditConsoleOpen && (
            <ArrowForwardIosIcon
              sx={{
                color: "rgba(0, 0, 0, 0.54)",
                width: 14,
                height: 24,
                marginLeft: "8px",
              }}
            />
          )}
        </Box>
      </div>
      <div data-testid="editing-console">
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <PermissionGate role={["editor", "chiefEditor"]}>
              <MenuItem
                component={NavLink}
                to="/launchpads"
                exact
                activeStyle={activeListElementStyle}
                sx={styleListElement}
              >
                <Tooltip
                  title="Live Environment"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/launchpads"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <AccountTreeOutlinedIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && (
                  <ListItemText>Live Environment</ListItemText>
                )}
              </MenuItem>
            </PermissionGate>
            <PermissionGate role={["editor", "chiefEditor"]}>
              <MenuItem
                component={NavLink}
                to="/launchpads/development"
                activeStyle={activeListElementStyle}
                sx={styleListElement}
                data-testid="operations"
              >
                <Tooltip
                  title="Development Environment"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/launchpads/development"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <BuildOutlinedIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && (
                  <ListItemText>Development Environment</ListItemText>
                )}
              </MenuItem>
            </PermissionGate>
            <PermissionGate role={["editor", "chiefEditor"]}>
              <MenuItem
                component={NavLink}
                to="/analytics"
                activeStyle={activeListElementStyle}
                sx={styleListElement}
              >
                <Tooltip
                  title="Analytics"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/analytics"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <InsightsIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && <ListItemText>Analytics</ListItemText>}
              </MenuItem>
            </PermissionGate>
            <PermissionGate role={["editor", "chiefEditor"]}>
              <MenuItem
                component={NavLink}
                to="/recent-activity"
                activeStyle={activeListElementStyle}
                sx={styleListElement}
              >
                <Tooltip
                  title="Recent Activity"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color: window.location.pathname.includes(
                        "/recent-activity"
                      )
                        ? "#FFF"
                        : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <HistoryIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && (
                  <ListItemText>Recent Activity</ListItemText>
                )}
              </MenuItem>
            </PermissionGate>
            <PermissionGate role={["editor", "chiefEditor"]}>
              <MenuItem
                component={NavLink}
                to="/operations"
                activeStyle={activeListElementStyle}
                sx={styleListElement}
                data-testid="operations"
              >
                <Tooltip
                  title="Operations"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/operations"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <OperationDashboardIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && <ListItemText>Operations</ListItemText>}
              </MenuItem>
            </PermissionGate>
            <PermissionGate role={["editor", "chiefEditor"]}>
              <MenuItem
                component={NavLink}
                activeStyle={activeListElementStyle}
                sx={styleListElement}
                to="/import"
                data-testid="import-content-edit-console"
              >
                <Tooltip
                  title="Import Content"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/import"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <SaveAltOutlinedIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && (
                  <ListItemText>Import Content</ListItemText>
                )}
              </MenuItem>
            </PermissionGate>

            <MenuItem
              component={NavLink}
              to="/issue-log"
              activeStyle={activeListElementStyle}
              sx={styleListElement}
              data-testid="import-content-edit-console"
            >
              <Tooltip
                title="Issue Log"
                placement="right"
                enterDelay={1}
                leaveDelay={1}
              >
                <ListItemIcon
                  sx={{
                    marginLeft: "2px",
                    color:
                      window.location.pathname === "/issue-log"
                        ? "#FFF"
                        : "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  <Badge
                    badgeContent={unresolvedIssuesNumber}
                    color={unresolvedIssuesNumber === 0 ? "success" : "error"}
                  >
                    <ReportProblemOutlinedIcon />
                  </Badge>
                </ListItemIcon>
              </Tooltip>
              {isEditConsoleOpen && <ListItemText>Issue Log</ListItemText>}
            </MenuItem>

            <PermissionGate role={["chiefEditor"]}>
              <MenuItem
                component={NavLink}
                activeStyle={activeListElementStyle}
                sx={styleListElement}
                to="/manage-users"
                data-testid="manage-users-edit-console"
              >
                <Tooltip
                  title="Manage Users"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/manage-users"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <PeopleAltOutlinedIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && <ListItemText>Manage Users</ListItemText>}
              </MenuItem>
            </PermissionGate>
            <PermissionGate role={["editor", "chiefEditor"]}>
              <MenuItem
                component={NavLink}
                activeStyle={activeListElementStyle}
                sx={styleListElement}
                to="/document-library"
              >
                <Tooltip
                  title="Document Library"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/document-library"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <LibraryBooksIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && (
                  <ListItemText>Document Library</ListItemText>
                )}
              </MenuItem>
            </PermissionGate>
            {/* <PermissionGate role={["chiefEditor"]}>
              <MenuItem
                component={NavLink}
                activeStyle={activeListElementStyle}
                sx={styleListElement}
                to="/find-and-replace"
                data-testid="manage-users-edit-console"
              >
                <Tooltip
                  title="Find and Replace"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/find-and-replace"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <FindReplaceIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && (
                  <ListItemText>Find and Replace</ListItemText>
                )}
              </MenuItem>
            </PermissionGate> */}

            <PermissionGate role={["chiefEditor"]}>
              <MenuItem
                component={NavLink}
                to="/approval-policies"
                activeStyle={activeListElementStyle}
                sx={styleListElement}
              >
                <Tooltip
                  title="Approval Policies"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/approval-policies"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <ApprovalIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && (
                  <ListItemText>Approval Policies</ListItemText>
                )}
              </MenuItem>
            </PermissionGate>
            <PermissionGate role="medicalArtAdmin">
              <MenuItem
                component={NavLink}
                to="/manage-gallery"
                activeStyle={activeListElementStyle}
                sx={styleListElement}
              >
                <Tooltip
                  title="Art Manager"
                  placement="right"
                  enterDelay={1}
                  leaveDelay={1}
                >
                  <ListItemIcon
                    sx={{
                      marginLeft: "2px",
                      color:
                        window.location.pathname === "/manage-gallery"
                          ? "#FFF"
                          : "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    <BrushIcon />
                  </ListItemIcon>
                </Tooltip>
                {isEditConsoleOpen && <ListItemText>Art Manager</ListItemText>}
              </MenuItem>
            </PermissionGate>
            {/* <SuperAdminGate>
                <MenuItem component={Link} to="/nodeeditorv2">
                  <ListItemIcon>
                    <ApprovalIcon />
                  </ListItemIcon>
                  {!isEditConsoleCollapsed && (
                    <ListItemText>Node Editor</ListItemText>
                  )}
                </MenuItem>
              </SuperAdminGate> */}
            <SuperAdminGate>
              <MenuItem
                component={NavLink}
                to="/tenant-manager"
                activeStyle={activeListElementStyle}
                sx={styleListElement}
              >
                <ListItemIcon
                  sx={{
                    marginLeft: "2px",
                    color:
                      window.location.pathname === "/tenant-manager"
                        ? "#FFF"
                        : "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  <BusinessIcon />
                </ListItemIcon>
                {isEditConsoleOpen && (
                  <ListItemText>Tenant Manager</ListItemText>
                )}
              </MenuItem>
            </SuperAdminGate>

            <SuperAdminGate>
              <MenuItem
                component={NavLink}
                to="/superAdminTools"
                activeStyle={activeListElementStyle}
                sx={styleListElement}
              >
                <ListItemIcon
                  sx={{
                    marginLeft: "2px",
                    color:
                      window.location.pathname === "/superAdminTools"
                        ? "#FFF"
                        : "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                {isEditConsoleOpen && (
                  <ListItemText>Super Admin Tools</ListItemText>
                )}
              </MenuItem>
            </SuperAdminGate>
          </Box>
        </div>
      </div>
    </>
  );
};

export default EditConsole;
