import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DecisionSummary from "./components/DecisionSummaryV2";
import { Message } from "semantic-ui-react";
import usePathwayNavigator from "features/hooks/usePathwayNavigatorV2";
import LoadingComponent from "app/layout/LoadingComponent";
import PathwayDisclaimer from "../NodeViewer/PathwayDisclaimer";
import useTenants from "features/hooks/useTenants";
import ReportIssueModal from "../NodeViewer/ReportIssueModal";
import { ModuleContext } from "app/providers/ModuleProvider";
import { Box, Button, useTheme } from "@mui/material";
import PageContainer from "app/layout/PageContainer";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import PatientBanner from "features/PatientContext/PatientBanner";
import ClinicalClerkingForm from "features/PatientContext/ClinicalClerkingForm";
import { DragDropContext } from "react-beautiful-dnd";
import NodeViewer from "./components/NodeEditorComponents/NodeViewer";
import { getDeploymentPath } from "features/pathwaybuilder/utils/editorConverters";

const PathwayViewerV2 = () => {
  const { pathwayId, collectionId, nodeId } = useParams<{
    pathwayId: string;
    collectionId?: string;
    nodeId?: string;
  }>();

  const nhsNumber = "";

  if (pathwayId === undefined) {
    throw Error("Invalid pathway Id");
  }
  const snomedCode = parseInt(pathwayId);

  const [disclaimerAccepted, setDisclaimerAccepted] = useState(Boolean(nodeId));

  const history = useHistory();
  const location = useLocation();

  const { activeTenant } = useTenants();
  const { tenantBrowser } = useContext(ModuleContext);

  const theme = useTheme();

  if (activeTenant === undefined) {
    throw Error("Invalid TenantId");
  }

  //If pathwayId is a number then it is most likely a snomed code. So we need to try an see if we can get a pathway name from that
  if (!isNaN(snomedCode)) {
    //Todo add logic to look up pathway
  }

  if (nhsNumber) {
    //validate nhs number
  }

  let exitLinkPathway = "/launchpads";
  const searchParams = new URLSearchParams(location.search);
  const referer = searchParams.get("referer");

  const isDevelopmentMode = referer !== null;

  if (referer === "table") {
    exitLinkPathway = `/pathway-builder/${pathwayId}`;
  }

  const { dispatch, state, activeNode, pathwayNodes, loading } =
    usePathwayNavigator({
      pathwayId: pathwayId,
      tenantId: tenantBrowser || activeTenant.id || "",
      nodeCollection: collectionId || "livenodes",
      overrideDefaultStartNode: nodeId,
    });

  const db = firebase.firestore();

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (state.tenantId && state.currentPathwayId) {
      const checkForOpenDeployment = async () => {
        const deploymentRef = await db
          .collection(getDeploymentPath(state.tenantId, state.currentPathwayId))
          .where("status", "in", ["Open", "Approved"])
          .limit(1)
          .get();

        if (deploymentRef.empty) {
          setIsEditable(true);
        } else {
          setIsEditable(false);
        }
      };

      checkForOpenDeployment();
    }
  }, [
    state.tenantId,
    state.currentPathwayId,
    isDevelopmentMode,
    db,
    activeNode,
  ]);

  return (
    <PageContainer
      title={`${state.tenantId} ${state.currentPathwayId} Pathway`}
      subBar={<PatientBanner />}
      actionBar={
        <Box>
          {/* <Button
            color="error"
            variant="outlined"
            sx={{ mr: 2 }}
            startIcon={<ExitToAppIcon sx={{ transform: "rotate(180deg)" }} />}
          >
            Exit Pathway
          </Button> */}
        </Box>
      }
    >
      <Box
        sx={{
          mx: 2,
          my: 1,
          p: 2,
        }}
      >
        {loading && <LoadingComponent />}
        {!loading && (
          <>
            {!Boolean(pathwayNodes.length) && (
              <Message warning>No nodes exist in this pathway</Message>
            )}
            {isDevelopmentMode && (
              <Message info>
                You are viewing a development environment pathway
              </Message>
            )}
            {Boolean(pathwayNodes.length) && !disclaimerAccepted && (
              <PathwayDisclaimer
                onAccept={() => {
                  setDisclaimerAccepted(true);
                }}
                onDecline={() => {
                  history.goBack();
                }}
                displayButtons={true}
              />
            )}

            {Boolean(pathwayNodes.length) &&
              disclaimerAccepted &&
              pathwayNodes &&
              activeNode && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 8,
                    flexDirection: theme.isMobileView ? "column" : "row",
                  }}
                >
                  {isDevelopmentMode && !theme.isMobileView && (
                    <Box sx={{ width: 200 }}>
                      <>
                        <Button
                          variant="contained"
                          to={`${exitLinkPathway}/list`}
                          component={Link}
                          sx={{
                            mb: 1,
                            "&:hover": {
                              color: "#fff",
                            },
                          }}
                        >
                          Back to List View
                        </Button>
                        <Button
                          variant="contained"
                          to={`${exitLinkPathway}/tree`}
                          component={Link}
                          sx={{
                            mb: 1,

                            "&:hover": {
                              color: "#fff",
                            },
                          }}
                        >
                          Back to Tree View
                        </Button>
                        {/* <Button
                          variant="contained"
                          to={`${exitLinkPathway}/episode`}
                          component={Link}
                          sx={{
                            mb: 1,

                            "&:hover": {
                              color: "#fff",
                            },
                          }}
                        >
                          Back to Episode View
                        </Button> */}
                        {isEditable && (
                          <Button
                            variant="contained"
                            to={`/pathway-editor/${state.currentPathwayId}/${collectionId}/${activeNode.id}`}
                            component={Link}
                            sx={{
                              mb: 1,
                              "&:hover": {
                                color: "#fff",
                              },
                            }}
                          >
                            Edit Node
                          </Button>
                        )}
                      </>
                    </Box>
                  )}

                  {!isDevelopmentMode && !theme.isMobileView && (
                    <ClinicalClerkingForm />
                  )}
                  {!isDevelopmentMode && (
                    <Box
                      sx={{
                        flex: 2,
                        maxWidth: 900,
                      }}
                    >
                      <DragDropContext onDragEnd={() => null}>
                        <NodeViewer
                          isPreview={true}
                          //state={NodeToBlocks(activeNode)}
                          state={activeNode}
                          pathwayEditorDispatch={dispatch}
                          nodeSettings={{
                            onExitPathway: () => history.push(exitLinkPathway),
                            hideCreatedAt: false,
                          }}
                          pathwayState={state}
                        />
                      </DragDropContext>
                    </Box>
                  )}
                  <Box
                    sx={{
                      flex: 1,
                      maxWidth: theme.isMobileView ? "100%" : 350,
                    }}
                  >
                    <DecisionSummary decisionSummary={state.decisionSummary} />

                    {!isDevelopmentMode && (
                      <ReportIssueModal
                        node={activeNode}
                        pathwayId={state.currentPathwayId}
                      />
                    )}
                  </Box>
                </Box>
              )}
          </>
        )}
      </Box>
    </PageContainer>
  );
};

export default PathwayViewerV2;
