import { Dispatch, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  BlockTypes,
  NodeBlock,
  NodeBlockData,
  IDynamicNode,
} from "../../../../../types/Nodes";

import { Box } from "@mui/material";
// import { isEmpty } from "lodash";
import BlockComponent from "./BlockComponent";
import OnHoverEditMenu from "./OnHoverEditMenu";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";
import { editorMode } from "features/NodeEditorV2/NodeEditorV2";
import { INodeViewSettings } from "features/NodeViewer/NodeView";
import { isChoicesBlock } from "features/pathwaybuilder/utils/pathwayHelperV2";

const RowBlocks = ({
  rowBlocks,
  rowIndex,
  blockDraggingId,
  dispatch,
  onAddBlock,
  state,
  pathwayEditorDispatch,
  nodes,
  isPreview,
  // placeholderProps,
  onDeleteBlock,
  showDroppingPosition,
  pathwayState,
  onEdit,
  blockEditInfos,
  currentEditorMode,
  onSave,
  nodeSettings,
  isShowingBlockRules,
}: {
  rowBlocks: NodeBlock[];
  rowIndex: number;
  blockDraggingId?: string;
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  onAddBlock?: (
    blockType: BlockTypes,
    data: NodeBlockData,
    row?: number,
    column?: number
  ) => NodeBlock | undefined;
  state: IDynamicNode;

  pathwayEditorDispatch: Dispatch<PathwayActions>;
  nodes?: IDynamicNode[];
  isPreview: boolean;
  // placeholderProps: any;
  onDeleteBlock?: (block: NodeBlock, type: string) => void;
  showDroppingPosition?: boolean;
  pathwayState: Partial<PathwayState>;
  onEdit?: (block: NodeBlock | undefined) => void;
  blockEditInfos?: NodeBlock | undefined;
  currentEditorMode?: editorMode;
  onSave?: () => Promise<void>;
  nodeSettings: INodeViewSettings;
  isShowingBlockRules: boolean;
}) => {
  const [isHover, setIsHover] = useState<string>("");

  const blockDraggingIdNotAllowedInColumns = [
    "twoColumns",
    "threeColumns",
    "editNode",
  ];

  const isEditing = (blockId: string) => {
    return blockEditInfos?.blockId === blockId && !isPreview;
  };

  return (
    <Droppable
      droppableId={`${rowIndex}`}
      key={rowIndex}
      direction="horizontal"
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="rows"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            position: "relative",
            transition: ".2s",
            alignItems: "stretch",
          }}
        >
          {rowBlocks
            .sort((a, b) => a.position.column - b.position.column)
            .map((block, columnIndex) => {
              return (
                <div
                  key={columnIndex}
                  className="columns"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    minWidth: "33%",
                    width: "calc((500px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                >
                  {block.blockType !== "placeholder" && (
                    <Draggable
                      index={columnIndex}
                      key={block.blockId}
                      draggableId={block.blockId}
                      isDragDisabled={
                        isPreview || blockEditInfos ? true : false
                      }
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            onPointerOver={() =>
                              !isPreview && setIsHover(block.blockId)
                            }
                            onPointerOut={() => !isPreview && setIsHover("")}
                            onFocus={() =>
                              !isPreview && setIsHover(block.blockId)
                            }
                            onBlur={() => !isPreview && setIsHover("")}
                            style={{
                              border: "2px solid #015D67",
                              borderColor: !isPreview ? "#015D67" : "#FFF",
                              // : isHover === block.blockId
                              // ? "2px solid #EAEAEA"
                              // : "2px solid #FFF",
                              //margin: !isPreview ? "2px" : "0px",
                              margin:
                                (block.blockType === "linkButton" ||
                                  isChoicesBlock(block)) &&
                                isPreview
                                  ? 0
                                  : "4px 0px",
                              borderRadius: 5,
                            }}
                          >
                            <span
                              style={{ display: "none" }}
                              {...provided.dragHandleProps}
                            ></span>
                            {block.blockType !== "placeholder" &&
                              !isPreview && (
                                <OnHoverEditMenu
                                  type={"block"}
                                  dragHandleProp={provided.dragHandleProps}
                                  block={block}
                                  state={state}
                                  onDeleteBlock={onDeleteBlock}
                                  dispatch={dispatch}
                                  onAddBlock={onAddBlock}
                                  rowIndex={rowIndex}
                                  show={isHover === block.blockId}
                                  isEditing={isEditing(block.blockId)}
                                  // show={true}
                                  onEdit={onEdit}
                                  blockEditInfos={blockEditInfos}
                                />
                              )}
                            <div
                              style={{
                                padding: !isPreview ? 3 : 0,
                              }}
                            >
                              <BlockComponent
                                isEditing={isEditing(block.blockId)}
                                block={block}
                                dispatch={dispatch}
                                pathwayEditorDispatch={pathwayEditorDispatch}
                                pathwayState={pathwayState}
                                isPreview={isPreview}
                                state={state}
                                onEdit={onEdit}
                                blockEditInfos={blockEditInfos}
                                onAddBlock={onAddBlock}
                                onSave={onSave}
                                nodeSettings={nodeSettings}
                                nodes={nodes}
                                isShowingBlockRules={isShowingBlockRules}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )}
                  {block.blockType === "placeholder" && (
                    <>
                      {(blockDraggingIdNotAllowedInColumns.includes(
                        blockDraggingId ?? ""
                      ) ||
                        !blockDraggingId) && (
                        <Box
                          sx={{
                            width: "100%",
                            height: "200px",
                            border: "2px solid #9FDFDC",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            color: "#9FDFDC",
                            backgroundColor: snapshot.isDraggingOver
                              ? "rgba(1, 93, 103, 0.08)"
                              : "",
                            my:
                              block.blockType === "placeholder" && isPreview
                                ? 2
                                : 0,
                          }}
                        >
                          <div>
                            {!isPreview
                              ? "Drag a supported block here…"
                              : "This is a Column block"}

                            <br />
                            {showDroppingPosition &&
                            currentEditorMode !== editorMode.TemplateEdit
                              ? "Drop Block Here"
                              : ""}
                            <span style={{ display: "none" }}>
                              {provided.placeholder}
                            </span>
                          </div>
                        </Box>
                      )}
                      {blockDraggingId &&
                        !blockDraggingIdNotAllowedInColumns.includes(
                          blockDraggingId
                        ) && (
                          <Droppable
                            droppableId={`${block.blockId}`}
                            isDropDisabled={blockDraggingIdNotAllowedInColumns.includes(
                              blockDraggingId
                            )}
                          >
                            {(provided, snapshot) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{
                                  height: "200px",
                                  width: "100%",
                                  border: "2px solid #9FDFDC",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  color: "#9FDFDC",
                                  backgroundColor: snapshot.isDraggingOver
                                    ? "rgba(1, 93, 103, 0.08)"
                                    : "",
                                }}
                              >
                                <div>
                                  Column
                                  <br />
                                  {showDroppingPosition
                                    ? "Drop Block Here"
                                    : ""}
                                  <span style={{ display: "none" }}>
                                    {provided.placeholder}
                                  </span>
                                </div>
                              </Box>
                            )}
                          </Droppable>
                        )}
                    </>
                  )}
                </div>
              );
            })}
          <span style={{ display: "none" }}>{provided.placeholder}</span>
          {/* {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
            <div
              style={{
                position: "absolute",
                top: placeholderProps.clientY,
                left: placeholderProps.clientX,
                height: placeholderProps.clientHeight,
                background: "rgba(1, 93, 103, 0.08)",
                width: placeholderProps.clientWidth,
                border: "2px solid #9FDFDC",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#9FDFDC",
              }}
            >
              <p>Drop here</p>
            </div>
          )} */}
        </div>
      )}
    </Droppable>
  );
};

export default RowBlocks;
