import { IWidgetTemplate } from "../../../types/WidgetTemplates";
import useDocuments from "./useDocuments";
import useUser from "./useUser";

const useWidgetTemplates = () => {
  const { activeTenantId } = useUser();

  const { documents: predefinedTemplates } = useDocuments<IWidgetTemplate>(
    `${activeTenantId}/templates/predefinedWidgetTemplates`
  );

  const { documents: userDefinedTemplates } = useDocuments<IWidgetTemplate>(
    `${activeTenantId}/templates/userDefinedWidgetTemplates`
  );

  return {
    widgetTemplates: [...predefinedTemplates, ...userDefinedTemplates],
  };
};

export default useWidgetTemplates;
