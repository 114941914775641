import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import useModules from "features/hooks/useModules";
import { useMediaQuery } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    module: {
      colour: string;
    };
    isMobileView: boolean;
    isTabletView: boolean;
    isDesktopView: boolean;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    module?: {
      colour?: string;
    };
    isMobileView: boolean;
    isTabletView: boolean;
    isDesktopView: boolean;
  }
}

// New typography variant
declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    subtitle3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    subtitle3: true;
  }
}

const DynamicThemeProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { activeModule } = useModules();
  const defaultTheme = createTheme();

  const isMobile = useMediaQuery(defaultTheme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(defaultTheme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(defaultTheme.breakpoints.down(1300));

  const dynamicTheme = useMemo(() => {
    return createTheme({
      palette: {
        primary: { main: "#015D67", light: "#9FDFDC", dark: "#01373D" },
        secondary: { main: "#808080", light: "#C2C2C2", dark: "#4D4D4D" },
      },
      typography: {
        body1: {
          fontSize: "0.875rem", //14px
          lineHeight: "150%",
          padding: 0,
          margin: 0,
        },
        body2: {
          fontSize: "0.75rem", //12px
          lineHeight: "150%",
        },
        body3: {
          fontSize: "1rem", //16px
          lineHeight: "150%",
        },
        subtitle1: {
          fontSize: "0.875rem", //14px
          lineHeight: "150%",
          fontWeight: "500",
        },
        subtitle2: {
          fontSize: "0.75rem", //12px
          lineHeight: "150%",
          fontWeight: "500",
        },
        subtitle3: {
          fontSize: "1rem", //16px
          lineHeight: "150%",
          fontWeight: "500",
        },
        h1: {
          fontSize: "3rem", //48px
          lineHeight: "120%",
          fontWeight: "bold",
        },
        h2: {
          fontSize: "2.5rem", //40px
          lineHeight: "120%",
          fontWeight: "bold",
        },
        h3: {
          fontSize: "2.25rem", //36px
          lineHeight: "120%",
          fontWeight: "bold",
        },
        h4: {
          fontSize: "1.875rem", //30px
          lineHeight: "120%",
          fontWeight: "bold",
        },
        h5: {
          fontSize: "1.5rem", //24px
          lineHeight: "120%",
          fontWeight: "bold",
        },
        h6: {
          fontSize: "1.125rem", //18px
          lineHeight: "150%",
          fontWeight: "bold",
        },
      },
      module: {
        colour: activeModule?.primaryColour ?? "",
      },
      isMobileView: isMobile,
      isTabletView: isTablet,
      isDesktopView: isDesktop,
      components: {
        MuiIconButton: {
          styleOverrides: {
            root: {
              padding: "8px",
              "& > svg": {
                width: "24px",
                height: "24px",
              },
              margin: "0px 8px",
            },
          },
        },
        MuiButton: {
          variants: [
            {
              props: { variant: "outlined" },
              style: {
                backgroundColor: "transparent",
              },
            },
          ],
          styleOverrides: {
            root: {
              mx: "0px",
              my: "0px",
              padding: "8px 16px",
              fontSize: "0.875rem",
              lineHeight: "170%",
              fontWeight: "500",
              textTransform: "capitalize",
              boxShadow: "none",
            },
            sizeSmall: {
              padding: "8px 16px",
              fontSize: "0.75rem",
              lineHeight: "120%",
            },
          },
        },

        MuiRadio: {
          styleOverrides: {
            root: {
              padding: "8px",
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              borderRadius: "4px",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              padding: "8px 12px",
              fontSize: "1rem", //16px
              lineHeight: "150%",
            },
          },
        },

        MuiPaper: {
          styleOverrides: {
            root: {
              padding: 0,
              margin: 0,
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: "0.875rem",
              textTransform: "capitalize",
              lineHeight: "120%",
              padding: "16px",
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: 24,
              width: 24,
            },
          },
        },

        MuiBreadcrumbs: {
          styleOverrides: {
            root: {
              fontSize: "0.75rem",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: isMobile
            ? {
                root: {
                  fontSize: "16px",
                },
              }
            : {
                root: {},
              },
        },
      },
    });
  }, [activeModule?.primaryColour, isMobile, isTablet, isDesktop]);

  return <ThemeProvider theme={dynamicTheme}>{children}</ThemeProvider>;
};

export default DynamicThemeProvider;
