import { Box, Typography } from "@mui/material";
import InsertDocumentLibraryModal from "app/components/InsertDocumentLibraryModalV2";
import {
  IImageBlock,
  IImageBlockData,
  NodeBlock,
} from "../../../../types/Nodes";

import { DynamicNodeEditorActions } from "../dynamicNodeEditorReducer";
import NodeImageView from "./NodeImageViewV2";
import GalleryModal from "../../../features/gallery/GalleryModalV2";

interface INodeGalleryEditorProps {
  block: IImageBlock;
  dispatch?: React.Dispatch<DynamicNodeEditorActions>;
  isEditing: boolean;
  onEdit?: (block: NodeBlock | undefined) => void;
  isPreview: boolean;
}

const NodeImageEditor = ({
  block,
  dispatch,
  isEditing,
  onEdit,
  isPreview,
}: INodeGalleryEditorProps) => {
  const onSelectImageFromGallery = (filePath: string) => {
    const blockData: IImageBlockData = {
      images: [{ pointer: filePath, storageType: "direct" }],
    };

    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: { blockId: block.blockId, blockData: blockData },
    });
    onEdit?.(undefined);
  };

  const integrationObj: IImageBlockData = {
    images: [],
  };

  if ((block.blockData as any).image) {
    integrationObj.images.push({
      pointer: (block.blockData as any).image,
      storageType: "direct",
    });
  }

  if (
    Array.isArray(block.blockData.images) &&
    block.blockData.images.length > 0
  ) {
    integrationObj.images.push(block.blockData.images[0]);
  }

  const hasEmptyPointer = integrationObj.images.length === 0;

  return (
    <Box sx={{ position: "relative" }}>
      {!hasEmptyPointer && <NodeImageView images={integrationObj.images} />}
      {(!block.blockData || hasEmptyPointer || isEditing) && (
        <Box
          sx={{
            background: !hasEmptyPointer
              ? "rgba(0, 0, 0, 0.8)"
              : "rgb(234, 234, 234)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 3,
            position: !hasEmptyPointer ? "absolute" : "relative",
            top: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <svg
            style={{ marginBottom: "16px" }}
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M75.3333 8.66665V58.6666H25.3333V8.66665H75.3333ZM75.3333 0.333313H25.3333C20.7499 0.333313 16.9999 4.08331 16.9999 8.66665V58.6666C16.9999 63.25 20.7499 67 25.3333 67H75.3333C79.9166 67 83.6666 63.25 83.6666 58.6666V8.66665C83.6666 4.08331 79.9166 0.333313 75.3333 0.333313ZM39.9166 40.625L46.9582 50.0416L57.2916 37.125L71.1666 54.5H29.4999L39.9166 40.625ZM0.333252 17V75.3333C0.333252 79.9166 4.08325 83.6666 8.66658 83.6666H66.9999V75.3333H8.66658V17H0.333252Z"
              fill={!hasEmptyPointer ? "white" : "#808080"}
            />
          </svg>

          <Typography
            variant="body3"
            sx={{ mb: 2, color: hasEmptyPointer ? "black" : "white" }}
          >
            This is an Image block. You can add images to this block by
            importing an image from the Document Library, or by selecting an
            image from the CrossCover Image Gallery.
          </Typography>

          {!isPreview && (
            <span
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 8,
              }}
              onClick={() => !isEditing && onEdit?.(block)}
            >
              <GalleryModal
                triggerBtnText="CrossCover Image Gallery"
                onImageSelected={(name, url) => onSelectImageFromGallery(url)}
              ></GalleryModal>
              <InsertDocumentLibraryModal
                selectFunction={(value) => {
                  onSelectImageFromGallery(value.file);
                }}
                handleUpdateNodePreview={() => {}}
                triggerBtnText="Document Library"
                filterBy={"IMAGE"}
                view={"Grid View"}
              />
            </span>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NodeImageEditor;
