import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import EditIcon from "@mui/icons-material/Edit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import {
  BlockTypes,
  NodeBlock,
  NodeBlockData,
  IDynamicNode,
} from "../../../../../types/Nodes";
import EditOffIcon from "@mui/icons-material/EditOff";

import { Dispatch, useState } from "react";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import {
  isChoicesBlock,
  isQuestionBlock,
  isStartBackBlock,
} from "features/pathwaybuilder/utils/pathwayHelperV2";

const OnHoverEditMenu = ({
  type,
  block,
  dragHandleProp,
  state,
  onDeleteBlock,
  dispatch,
  onAddBlock,
  rowIndex,
  rowBlocks,
  show,
  isEditing,
  blockEditInfos,
  onEdit,
}: {
  type: "block" | "row";
  block?: NodeBlock;
  dragHandleProp: DraggableProvidedDragHandleProps | null | undefined;
  state: IDynamicNode;
  onDeleteBlock?: (block: NodeBlock, type: string) => void;
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  onAddBlock?: (
    blockType: BlockTypes,
    data: NodeBlockData,
    row?: number,
    column?: number
  ) => void;
  rowIndex: number;
  rowBlocks?: NodeBlock[];
  show: boolean;
  isEditing: boolean;
  blockEditInfos?: NodeBlock | undefined;
  onEdit?: (block: NodeBlock | undefined) => void;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const onDelete = () => {
    if (type === "block" && block) {
      onDeleteBlock?.(block, "block");
    }
    if (type === "row" && rowBlocks) {
      rowBlocks.forEach((block) => {
        onDeleteBlock?.(block, "row");
      });
    }
    handleCloseMenu();
  };

  const onAdd = () => {
    if (type === "block" && block) {
      onAddBlock?.(
        isChoicesBlock(block) ? block.blockData.type : block.blockType,
        block.blockData,
        rowIndex + 1
      );
    }
    if (type === "row" && rowBlocks) {
      rowBlocks.forEach((block, index) =>
        onAddBlock?.(
          isChoicesBlock(block) ? block.blockData.type : block.blockType,
          block.blockData,
          rowIndex + 1,
          index > 0 ? block.position.column : undefined
        )
      );
    }
    handleCloseMenu();
  };

  const blocksInRow = Object.values(state.blocks).filter(
    (block) => block.position.row === rowIndex
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
    setIsOpenDialog(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        //position: isEditing || type === "row" ? "relative" : "absolute",
        width: "100%",
        // justifyContent: "space-between",
        alignItems: "center",
        //opacity: show || isEditing ? 1 : 0,
        zIndex: 1,
        transition: "opacity .2s",
        transitionDelay: ".2s",
        height: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.54)",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        // top: -30,
        p: 1,
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
        py: "3px",
      }}
    >
      <Typography sx={{ color: "white", width: "33%" }}>
        {block?.blockName}
      </Typography>
      <Box sx={{ width: "33%", textAlign: "center" }}>
        {!blockEditInfos && (
          <IconButton
            aria-label="dragBlock"
            sx={{
              color: "white",
              py: 0,
            }}
            {...dragHandleProp}
          >
            <DragIndicatorIcon sx={{ transform: "rotate(90deg)" }} />
          </IconButton>
        )}
      </Box>

      {(blocksInRow.length === 1 || type === "row") && (
        <Stack justifyContent="flex-end" direction="row" sx={{ width: "33%" }}>
          {type === "block" && (
            <Tooltip title="Edit" arrow placement="top">
              <IconButton
                aria-label="edit"
                sx={{ color: "white", py: 0 }}
                onClick={() =>
                  isEditing ? onEdit?.(undefined) : onEdit?.(block)
                }
              >
                {isEditing ? <EditOffIcon /> : <EditIcon />}
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Duplicate" arrow placement="top">
            <span>
              <IconButton
                disabled={
                  block && (isQuestionBlock(block) || isStartBackBlock(block))
                }
                aria-label="duplicate"
                sx={{ color: "white", py: 0 }}
                onClick={() => onAdd()}
              >
                <ControlPointDuplicateIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete" arrow placement="top">
            <IconButton
              aria-label="delete"
              sx={{ color: "white", py: 0 }}
              onClick={() => setIsOpenDialog(true)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {blocksInRow.length > 1 && type === "block" && (
        <Stack sx={{ width: "33%" }} justifyContent="flex-end" direction="row">
          <Button
            id="basic-button"
            aria-controls={isMenuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            sx={{ color: "white", py: 0 }}
            aria-expanded={isMenuOpen ? "true" : undefined}
            onClick={handleOpenMenu}
          >
            <MoreHorizIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleCloseMenu}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onBlur={() => handleCloseMenu()}
            sx={{ py: 0 }}
          >
            <MenuList sx={{ width: "150px" }}>
              <MenuItem
                // onClick={() => onDelete()}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => setIsOpenDialog(true)}
              >
                <DeleteOutlineIcon />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() =>
                  isEditing ? onEdit?.(undefined) : onEdit?.(block)
                }
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {isEditing ? <EditOffIcon /> : <EditIcon />}
                Edit
              </MenuItem>
              <MenuItem
                disabled={
                  block && (isQuestionBlock(block) || isStartBackBlock(block))
                }
                onClick={() => onAdd()}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <ControlPointDuplicateIcon />
                Duplicate
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      )}
      <Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete the block?"}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpenDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onDelete()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default OnHoverEditMenu;
