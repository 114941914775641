import { useEffect, useState } from "react";

import useUserPrivateProfile from "features/hooks/useUserPrivateProfile";
import useDocument from "features/hooks/useDocument";
import useUser from "features/hooks/useUser";

import NagScreenModal from "./NagScreenModal";
import useNagScreens from "features/auth/ActivateAccount/useNagScreens";

import { IPrivateProfile } from "../../../types/User";
import { INagScreen } from "../../../types/NagScreen";

const NagScreen = () => {
  const [unSeenNagScreens, setUnseenNagScreens] = useState<INagScreen[]>();
  const [privateProfile, setPrivateProfile] = useState<IPrivateProfile>();

  const { userNagScreens } = useUserPrivateProfile();
  const { allNagScreens } = useNagScreens();
  const { profile } = useUser();

  // get document instead of using useUserPrivateProfile hook
  // so private profile updates do not re-trigger useEffect
  const { getDocument } = useDocument<IPrivateProfile>(
    `users/${profile.uid}/private`
  );

  useEffect(() => {
    const loadUserPrivateProfile = async () => {
      const userPrivateProfile = await getDocument(`profile`);
      setPrivateProfile(userPrivateProfile);
    };

    loadUserPrivateProfile();
  }, [getDocument, profile.uid]);

  useEffect(() => {
    if (userNagScreens && allNagScreens && privateProfile) {
      let nagScreensClone: INagScreen[] = JSON.parse(JSON.stringify(allNagScreens));

      const { registeredMFAFactor, eulaAccepted } = privateProfile;

      if (
        registeredMFAFactor === "AUTHENTICATOR" ||
        registeredMFAFactor === "PHONE"
      ) {
        nagScreensClone = nagScreensClone.filter(
          (screen) => screen.component !== "MFAManage"
        );
      }

      if (eulaAccepted) {
        nagScreensClone = nagScreensClone.filter(
          (screen) => screen.component !== "LicenseAgreementContainer"
        );
      }

      nagScreensClone = nagScreensClone.filter(
        (screen) =>
          !userNagScreens.some(
            (userScreen) => userScreen.screenId === screen.id
          )
      );

      setUnseenNagScreens(nagScreensClone);
    }
  }, [allNagScreens, privateProfile, userNagScreens]);

  return (
    <>
      {unSeenNagScreens && unSeenNagScreens.length > 0 && (
        <NagScreenModal unSeenNagScreens={unSeenNagScreens} />
      )}
    </>
  );
};
export default NagScreen;
