import { Segment } from "semantic-ui-react";
import { ILogicRule, INode } from "../../../../types/Nodes";
import React, { Dispatch } from "react";
import { NodeToLogic } from "features/pathwaybuilder/utils/pathwayHelper";
import { isNodeComparison } from "../NodeView";
import { getRuleStrings, IHighlightedNode } from "../NodeComparisonView";
import { Button, ButtonGroup } from "@mui/material";
import NodeActionComparison from "./NodeActionComparison";
import { INodeContext, PathwayActions } from "features/hooks/navigatorReducer";

interface INodeRuleActionProps {
  rule: ILogicRule;
  nodeContext: INodeContext;
  dispatch: Dispatch<PathwayActions>;
  //isWidget?: boolean;
  index?: number;
  parentId?: string;
  nodeId: string;
  showAnswers?: boolean;
  highlightTreeView?: boolean;
  showAllLogicAnswers?: boolean;
}

const NodeRuleAction = ({
  dispatch,
  rule,
  nodeContext,
  index,
  parentId,
  nodeId,
  showAnswers,
  highlightTreeView,
  showAllLogicAnswers,
}: //isWidget,
INodeRuleActionProps) => {
  let isVisible = false;

  // const nodeScore = 0;
  // if (nodeContext) {
  const nodeScore = nodeContext.fields.reduce(
    (total, item) => total + parseInt(item.value + "", 10),
    0
  );

  // }

  //If the operator is Contains then it a toggle operator
  if (rule.condition !== "Contains") {
    if (rule.condition === "is in range") {
      isVisible = nodeScore >= +rule.value && nodeScore <= (rule.maxValue || 0);
    }

    if (rule.condition !== "is in range") {
      //This is not perfect but provides a small bit of protected.  It is ensures that the condition
      //is only 2 characters in length and that the value is a number
      const safeCondition = rule.condition.substring(0, 2);
      const safeValue = parseInt(rule.value);
      if (safeCondition && !isNaN(safeValue) && !isNaN(nodeScore)) {
        // eslint-disable-next-line no-eval
        isVisible = eval(`${nodeScore} ${safeCondition} ${safeValue}`);
      }
    }
  }

  // If the operator is Contains then it a toggle operator
  if (nodeContext && rule.condition === "Contains") {
    isVisible = nodeContext.fields.some((x) => x.name.includes(rule.value));
  }

  // if (isWidget) {
  //   isVisible = true;
  // }

  const handleMouseEnter = () => {
    const edgeId = `__${parentId}__${nodeId}__${index}`.replace(/\s/g, "");
    const targetNodeElements = document.getElementsByClassName(
      `${nodeId} ${parentId}__parent`
    ) as HTMLCollectionOf<HTMLElement>;
    const labelElements = document.getElementsByClassName(
      edgeId + "__label"
    ) as HTMLCollectionOf<HTMLElement>;
    const edgeElements = document.getElementsByClassName(
      edgeId
    ) as HTMLCollectionOf<HTMLElement>;

    if (!!targetNodeElements.length) {
      for (const targetNodeElement of targetNodeElements) {
        targetNodeElement.style.outline = "black solid 4px";
      }
    }

    if (edgeElements) {
      for (const edgeElement of edgeElements) {
        edgeElement.style.stroke = "black";
        edgeElement.style.strokeWidth = "4";
      }
    }

    if (labelElements) {
      for (const labelElement of labelElements) {
        labelElement.style.display = "-webkit-box";
      }
    }

    const svgContainer = (
      document.getElementsByClassName(
        "react-flow__edges react-flow__container"
      ) as HTMLCollectionOf<HTMLElement>
    )[0];

    if (edgeElements) {
      for (const edgeElement of edgeElements) {
        if (edgeElement) {
          const clone = edgeElement.parentElement?.cloneNode(
            true
          ) as HTMLElement;
          clone.setAttribute("class", "duplicateEdge");

          const childEdgeElement = (
            clone.getElementsByClassName(edgeId) as HTMLCollectionOf<SVGElement>
          )[0];
          childEdgeElement.classList.remove(edgeId);

          const childLabelElement = (
            clone.getElementsByClassName(
              `${edgeId}__label`
            ) as HTMLCollectionOf<HTMLElement>
          )[0];
          childLabelElement.classList.remove(`${edgeId}__label`);

          svgContainer.append(clone);
        }
      }
    }
  };

  const onMouseLeave = () => {
    const edgeId = `__${parentId}__${nodeId}__${index}`.replace(/\s/g, "");
    const targetNodeElements = document.getElementsByClassName(
      `${nodeId} ${parentId}__parent`
    ) as HTMLCollectionOf<HTMLElement>;
    const labelElements = document.getElementsByClassName(
      edgeId + "__label"
    ) as HTMLCollectionOf<HTMLElement>;
    const edgeElements = document.getElementsByClassName(
      edgeId
    ) as HTMLCollectionOf<HTMLElement>;

    if (!!targetNodeElements.length) {
      for (const targetNodeElement of targetNodeElements) {
        targetNodeElement.style.outline = "none";
      }
    }

    if (edgeElements) {
      for (const edgeElement of edgeElements) {
        edgeElement.style.stroke = "#808080";
        edgeElement.style.strokeWidth = "4";
      }
    }

    if (labelElements) {
      for (const labelElement of labelElements) {
        if (!showAnswers) {
          labelElement.style.display = "none";
        }
      }
    }

    const clones = document.getElementsByClassName("duplicateEdge");

    while (clones.length > 0) {
      clones[0].parentNode?.removeChild(clones[0]);
    }
  };

  return (
    <>
      {(isVisible || showAllLogicAnswers) && (
        <Button
          onMouseEnter={highlightTreeView ? handleMouseEnter : () => {}}
          onMouseLeave={highlightTreeView ? onMouseLeave : () => {}}
          size="large"
          disabled={
            showAllLogicAnswers
              ? !isVisible
              : rule.nodeId === "I will connect later"
              ? true
              : false
          }
          onClick={() => {
            if (highlightTreeView) {
              onMouseLeave();
            }

            if (
              showAllLogicAnswers &&
              (rule.nodeId === "" || rule.nodeId === "I will connect later")
            ) {
              return;
            }

            dispatch({
              type: "MOVE_TO_NEXT_NODE",
              payload: {
                nodeId: rule.nodeId,
                pathwayId: "",
                answer: rule.linkText,
              },
            });
          }}
          sx={{
            justifyContent: "center",
            textTransform: "none",
            width: "100%",
            fontSize: "1.2rem",
            mb: 1,
          }}
          variant="contained"
        >
          {rule.linkText}
        </Button>
      )}
    </>
  );
};

interface INodeRulesProps {
  node: INode;
  dispatch: Dispatch<PathwayActions>;
  nodeContext: INodeContext;
  showAnswers?: boolean;
  highlightTreeView?: boolean;
  showAllLogicAnswers?: boolean;
  tagLogicAnswersWithActions?: boolean;
  tagText?: string;
  showRuleStrings?: boolean;
  nextEpisodeNodeTargetId?: string;
}

const NodeRules = ({
  node,
  dispatch,
  nodeContext,
  showAnswers,
  highlightTreeView,
  showAllLogicAnswers,
  tagLogicAnswersWithActions,
  tagText,
  showRuleStrings,
  nextEpisodeNodeTargetId,
}: INodeRulesProps) => {
  const logicRules = NodeToLogic(node).rules;

  let ruleStrings: string[] = [];

  if (logicRules.length === 0) {
    return <></>;
  }

  //let widgetLinkIndex = 0;

  // if (node && node.widgets.find((widget) => widget.name === "startback")) {
  //   // TODO need to check how to avoid using those

  //   const totals = calculateTotals(nodeContext);

  //   if (totals.totalScore <= 3) {
  //     widgetLinkIndex = 0;
  //   } else {
  //     totals.subScore >= 4 ? (widgetLinkIndex = 2) : (widgetLinkIndex = 1);
  //   }
  // }

  if (showRuleStrings) {
    ruleStrings = getRuleStrings(node);
  }

  return (
    <Segment data-testid="node-answer-buttons">
      {/* (node as unknown as INodeComparison).rulesStrings[index]
        : "" */}

      {/* {node && node.widgets.find((widget) => widget.name === "startback") && (
          <NodeRuleAction
            dispatch={dispatch}
            rule={logicRules[widgetLinkIndex]}
            isWidget={true}
          />
        )} */}

      {isNodeComparison(node) && (
        <>
          {logicRules.map((rule, index) => (
            <React.Fragment key={index}>
              <h3>
                {(node as unknown as IHighlightedNode).rulesStrings[index]}
              </h3>

              <NodeActionComparison
                linkContent={rule.linkText}
                destinationContent={node.logicLinkDestinations[index]}
              />
            </React.Fragment>
          ))}
        </>
      )}

      {!isNodeComparison(node) && (
        <ButtonGroup orientation="vertical" fullWidth>
          {logicRules.map((rule, index) => (
            <React.Fragment key={index}>
              {showRuleStrings && (
                <h3>
                  {ruleStrings[index]}

                  {tagLogicAnswersWithActions &&
                    tagText &&
                    rule.nodeId !== "I will connect later" && (
                      <span
                        style={{
                          marginLeft: "8px",
                          backgroundColor: "rgb(0, 153, 0)",
                          color: "white",
                          fontSize: "16px",
                          padding: "4px 8px",
                          fontWeight: "normal",
                          borderRadius: "4px",
                          textAlign: "center",
                          fontFamily: "roboto",
                        }}
                      >
                        {tagText}
                      </span>
                    )}
                </h3>
              )}
              <NodeRuleAction
                dispatch={dispatch}
                nodeContext={nodeContext}
                rule={rule}
                key={index}
                index={index}
                parentId={node.id}
                nodeId={node.ruleActions[index]}
                showAnswers={showAnswers}
                highlightTreeView={highlightTreeView}
                showAllLogicAnswers={showAllLogicAnswers}
              />
            </React.Fragment>
          ))}
        </ButtonGroup>
      )}
    </Segment>
  );
};

export default NodeRules;
