import { Box, TextField, Typography } from "@mui/material";
import functions from "app/common/util/functions";
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import LoadingButton from "app/components/LoadingButton";

const VerifyAuthenticator = ({
  code,
  uid,
  onLoginComplete,
}: {
  code: string;
  uid: string;
  onLoginComplete?: () => {};
}) => {
  const [enterCode, setEnterCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onVerify = async () => {
    try {
      setLoading(true);
      const tokenResult = await functions.verifyAuthenticator({
        code: code,
        uid: uid,
        enteredCode: enterCode,
      });

      if (tokenResult && tokenResult.tokenIsValid) {
        firebase.auth().signInWithCustomToken(tokenResult.token);
        if (onLoginComplete) {
          onLoginComplete();
        }
      }
    } catch (ex: any) {
      console.log(ex);
      setError(ex.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ p: 1 }}>
        <Typography>
          Check your chosen Authenticator App for a code, enter the code into
          the field below.
        </Typography>
      </Box>
      <TextField
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onVerify();
          }
        }}
        label="Authenticator Code"
        value={enterCode}
        size="small"
        onChange={(e) => setEnterCode(e.target.value)}
        error={Boolean(error)}
        helperText={error}
      />
      <LoadingButton
        variant="contained"
        onClick={() => onVerify()}
        isLoading={loading}
        buttonText="Verify"
      ></LoadingButton>
    </Box>
  );
};

export default VerifyAuthenticator;
