import { useCrossCoverDispatch, useCrossCoverSelector } from "app/store/hooks";
import { resetState } from "app/store/store";
import { getAuth, signOut } from "firebase/auth";

const appDomains = [
  "@orthopathway.com",
  "@crosscover.app",
  "@primumdigital1.onmicrosoft.com",
  "@entpathway.co.uk",
  //"@primumdigital.com",
];

const useUser = () => {
  const state = useCrossCoverSelector((state) => state);
  const reduxDispatch = useCrossCoverDispatch();

  const hasPermission = (permission: string | string[]) => {
    const localPermissions = [];
    if (!Array.isArray(permission)) {
      localPermissions.push(permission);
    }
    return localPermissions.some((x) =>
      state.users.userPermissions.includes(x)
    );
  };

  const isAuthorised = (role?: string | string[]) => {
    const currentTenant = state.tenants.activeTenant;
    //There is no user profile loaded, so can't be in a role
    if (state.users.userProfile === undefined) {
      return false;
    }

    //If no role is specified then we just need to check they are correct authenticated
    if (role === undefined) {
      return Boolean(state.users.userProfile.uid);
    }

    //The is no current tenant, so cannot be in a role
    if (currentTenant === undefined) {
      return false;
    }

    //Get the users role for this tenant
    const tenantRole =
      state.users.userProfile?.access[currentTenant.id || ""].role;

    //If multiple roles specified check the user has at least one
    if (Array.isArray(role)) {
      return role.includes(tenantRole);
    }

    return role === tenantRole;
  };

  // If a a user name is both readonly and in the appdomain list
  //then is is classified as readonly
  const isSharedAccount = () => {
    const profile = state.users.userProfile;
    if (profile === undefined) {
      return false;
    }

    //If the users role is not readonly, then it cannot be a shared account
    if (!isAuthorised("readonly")) {
      return false;
    }

    //If the domain name is in the app domain list, then it is classified as a shared account
    return appDomains.some((domain) => profile.email.endsWith(domain));
  };

  const logOut = async () => {
    const auth = getAuth();
    signOut(auth);
    reduxDispatch(resetState());
  };

  const isDefaultTenant = () => {
    const currentTenant = state.tenants.activeTenant;
    //The is no current tenant, so cannot be in a role
    if (currentTenant === undefined) {
      return false;
    }
    const tenantAccessRecord =
      state.users.userProfile?.access[currentTenant.id || ""];
    return tenantAccessRecord?.default || false;
  };

  const canEdit = () => {
    return isAuthorised(["editor", "chiefEditor"]);
  };

  return {
    profile: state.users.userProfile!,
    activeTenantId: state.tenants.activeTenant?.id || "",
    hasPermission,
    logOut,
    isAuthorised: isAuthorised,
    isDefaultTenant,
    userCanEdit: canEdit,
    isSharedAccount: isSharedAccount,
  };
};

export default useUser;
