import IconButton from "@mui/material/IconButton";
import GalleryComponent from "features/gallery/GalleryComponent";
import { useState } from "react";
import { Button } from "@mui/material";
import { Modal } from "semantic-ui-react";
import CrosscoverLogo from "../../app/assets/crosscover_logo_portrait.svg";
import CloseIcon from "@mui/icons-material/Close";
import useModules from "features/hooks/useModules";
import CropOriginalOutlinedIcon from "@mui/icons-material/CropOriginalOutlined";

const GalleryModal = ({
  triggerBtnText,
  onImageSelected,
}: {
  triggerBtnText: string;
  onImageSelected?: (imageName: string, imageURL: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { modules } = useModules();
  return (
    <Modal
      size={"large"}
      centered
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      trigger={
        <Button
          variant="contained"
          startIcon={<CropOriginalOutlinedIcon />}
          sx={{ mr: 1 }}
          color="primary"
          onClick={(e) => e.preventDefault()}
        >
          {triggerBtnText}
        </Button>
      }
    >
      <Modal.Header>
        <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
          <img style={{ width: "65px" }} src={CrosscoverLogo} alt="logo" />
          Image Gallery
          <IconButton
            aria-label="close"
            sx={{ position: "absolute", right: 4 }}
            onClick={() => setIsOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <GalleryComponent
          galleryModules={modules}
          onImageSelected={(name, url) => {
            onImageSelected && onImageSelected(name, url);
            setIsOpen(false);
          }}
          isGalleryManager={false}
          showImages={"LIVE"}
        />
      </Modal.Content>
    </Modal>
  );
};

export default GalleryModal;
