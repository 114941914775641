import {
  Paper,
  Button,
  InputAdornment,
  Typography,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
//import styled from "@mui/system/styled";
import SearchIcon from "@mui/icons-material/Search";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {
  TextFieldElement,
  FormContainer,
  DatePickerElement,
  RadioButtonGroup,
} from "react-hook-form-mui";

import { IPDSSearchRequest } from "./PatientContext";
import usePatient from "features/hooks/usePatient";
import useTenants from "features/hooks/useTenants";
import { useState } from "react";

const PatientBanner = () => {
  const { patient, search, unlink, create } = usePatient();
  const { tenantSettings } = useTenants();
  const [createOpen, setCreateOpen] = useState(false);

  if (Boolean(tenantSettings?.patientIntegrationEnabled) === false) {
    return <></>;
  }

  const onViewEncounters = () => {};

  return (
    <>
      {patient === undefined && (
        <Paper
          square={true}
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "16px 24px",
            borderTop: "1px solid #000",
            borderTopColor: "divider",
            gap: 1,
          }}
        >
          <FormContainer<IPDSSearchRequest>
            onSuccess={(data) => search({ nhsNumber: data.nhsNumber })}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <TextFieldElement
                label="NHS number"
                name="nhsNumber"
                placeholder=""
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
                sx={{ flex: 1 }}
              />
              <TextFieldElement
                label="Family name"
                name="familyName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
                sx={{ flex: 1 }}
              />
              <TextFieldElement
                label="Given names"
                name="givenName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
                sx={{ flex: 1 }}
              />
              <DatePickerElement
                name="dateOfBirth"
                label="D.O.B"
                inputProps={{ size: "small" }}
              />
              <RadioButtonGroup
                name="gender"
                options={[
                  { id: "male", label: "Male" },
                  { id: "female", label: "Female" },
                ]}
                row
              />
              <Button type="submit" variant="contained">
                Search
              </Button>
            </Box>
          </FormContainer>
          <Button variant="contained" onClick={() => setCreateOpen(true)}>
            Create
          </Button>
        </Paper>
      )}

      {patient && (
        <Paper
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 4,
            px: 2,
            borderTop: "1px solid black",
            borderTopColor: "divider",
          }}
        >
          <Box sx={{}}>
            <Typography variant="h5">
              {patient.familyName}, {patient.givenName}
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography sx={{ textTransform: "capitalize" }} variant="h6">
                {patient.gender}
              </Typography>
              <Typography variant="h6">20 yo</Typography>
            </Box>
          </Box>
          <div>
            <div>Date of Birth</div>
            <Typography variant="h6">{patient.birthDate}</Typography>
          </div>
          <div>
            <div>NHS Number</div>
            <Typography variant="h6">{patient.nhsNumber}</Typography>
          </div>
          <div style={{ flex: 1 }}></div>

          <div>
            <Button variant="outlined" onClick={() => onViewEncounters()}>
              View Encounters
            </Button>
            <Button
              variant="outlined"
              color="warning"
              startIcon={<LinkOffIcon />}
              onClick={() => unlink()}
            >
              Unlink
            </Button>
          </div>
        </Paper>
      )}

      {createOpen && (
        <Dialog open={createOpen} onClose={() => setCreateOpen(false)}>
          <DialogContent>
            <FormContainer<IPDSSearchRequest>
              onSuccess={(data) =>
                create({
                  patient: {
                    nhsNumber: "",
                    prefix: "",
                    givenName: [],
                    familyName: "",
                    suffix: "",
                  },
                  tenantId: "",
                })
              }
            >
              <TextFieldElement
                label="NHS number"
                name="nhsNumber"
                placeholder=""
                size="small"
                sx={{ flex: 1 }}
              />
              <TextFieldElement
                label="Family Name"
                name="familyName"
                placeholder=""
                size="small"
                sx={{ flex: 1 }}
              />
              <TextFieldElement
                label="Family Name"
                name="familyName"
                placeholder=""
                size="small"
                sx={{ flex: 1 }}
              />
              <Button type="submit" variant="contained">
                Save
              </Button>
            </FormContainer>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default PatientBanner;
