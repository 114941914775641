import React, { ReactNode } from "react";

const childrenWithProps = (children: ReactNode, rest: {}) => {
  return React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...rest });
    }
    return child;
  });
};
export default childrenWithProps;
