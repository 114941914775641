import useUser from "features/hooks/useUser";

const PermissionGate = ({
  children,
  role,
  permission,
}: {
  role?: string | string[];
  permission?: string | string[];
  children: React.ReactNode;
}) => {
  const { profile, hasPermission, isAuthorised } = useUser();

  let isGranted = false;

  if (profile && role !== undefined && isAuthorised(role)) {
    isGranted = true;
  }

  //Authenticated and permission requirement
  if (profile && permission !== undefined && hasPermission(permission || "")) {
    isGranted = true;
  }

  //Authenticated permission only
  if (profile && permission === undefined && role === undefined) {
    isGranted = true;
  }

  if (profile && profile.medicalArtAdmin && role === "medicalArtAdmin") {
    isGranted = true;
  }

  if (isGranted) {
    return <>{children}</>;
  }

  //User is not authorised
  return <></>;
};

export default PermissionGate;
