import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  INodeContext,
  INodeContextItem,
  NodeDecisionAction,
  NodePointer,
  PathwayMoveDirection,
} from "features/hooks/navigatorReducer";

/*
switch (action.type) {
  
    
    case "MOVE_TO_NEXT_NODE":
      let pathwayId = action.payload.pathwayId || draft.currentPathwayId;
      const newPosition = {
        nodeId: action.payload.nodeId,
        pathwayId: pathwayId,
      };

      const isPathwayToPathWayLink =
        newPosition.nodeId.substring(0, 2) === "@@";

      //If this is a pathway link then we need to change pathway
      if (isPathwayToPathWayLink) {
        pathwayId = getPathwayIdFromAction(newPosition.nodeId);
        //newPosition = previousState.activeNode.
      }

      const currentRoute = draft.route;
      const summary = draft.decisionSummary;
      if (draft.activeNode) {
        currentRoute.push(draft.activeNode);

        if (draft.nodeContext.fields.length) {
          const toggledFieldInNodeContext = draft.nodeContext.fields.reduce<
            INodeContextItem[]
          >((newArray, field) => {
            if (field.isToggled && field.addToDecisionSummary) {
              return [...newArray, field];
            }
            return [...newArray];
          }, []);

          summary.push({
            header: action.payload.answer,
            items: [...toggledFieldInNodeContext],
          });
        } else {
          summary.push({ header: action.payload.answer, items: [] });
        }
        //If we are clearing the decision summary then we also need to clear the route
        if (action.payload.clearDecisions) {
          summary.length = 0;
          currentRoute.length = 0;
        }
      }

      draft.route = [...currentRoute];
      draft.activeNode = { ...newPosition };
      draft.decisionSummary = [...summary];
      draft.currentPathwayId = pathwayId;
      draft.nodeContext = { fields: [] };
      draft.actionDirection = "FORWARD";
      break;
    case "NODE_LOGIC_ON":
      //Remove the current answer for the namespace
      draft.nodeContext.fields = draft.nodeContext.fields.filter(
        (x) => x.namespace !== action.payload.namespace
      );
      //Add the new answer
      draft.nodeContext.fields.push({
        ...convertOldNodeContextToNew(action.payload),
      });

      break;
    case "NODE_LOGIC_OFF":
      // Remove the current answer
      draft.nodeContext.fields = draft.nodeContext.fields.filter(
        (x) => x.namespace !== action.payload.namespace
      );

      break;
    case "NODE_BLOCK_LOGIC_ON":
      //Remove the current answer for the namespace
      draft.nodeContext.fields = draft.nodeContext.fields.filter(
        (x) =>
          x.valueId !== action.payload.valueId ||
          x.blockFieldId !== action.payload.blockFieldId
      );

      if (!action.payload.blockFieldId) {
        draft.nodeContext.fields = draft.nodeContext.fields.filter(
          (x) => x.blockId !== action.payload.blockId
        );
      }

      //Add the new answer
      draft.nodeContext.fields.push({ ...action.payload });

      break;
    case "NODE_BLOCK_LOGIC_OFF":
      // Remove the current answer
      draft.nodeContext.fields = draft.nodeContext.fields.filter(
        (x) => x.blockFieldId !== action.payload.blockFieldId
      );

      break;
    case "RESET_NODE_LOGIC":
      draft.nodeContext = {
        fields: [],
      };
      break;
    case "ADD_EPISODE_STEP":
      if (draft.pathwayEpisode === undefined) {
        draft.pathwayEpisode = {
          pathwayId: draft.currentPathwayId,
          collectionId: "",
          tenantId: draft.tenantId,
          entryTime: firebase.firestore.FieldValue.serverTimestamp(),
          route: {},
          //Assume the default will be lest pathway early
          outcomes: [LEFT_EARLY_TEXT],
        };
      }
      const step: IPathwayEpisodeStep = {
        entryTime: firebase.firestore.FieldValue.serverTimestamp(),
        node: action.payload.node,
        pathwayId: draft.currentPathwayId,
        collection: "",
        interaction:
          draft.actionDirection === "FORWARD"
            ? {
                header: draft.decisionSummary.at(-1)?.header ?? "",
                items: draft.decisionSummary.at(-1)?.items ?? [],
              }
            : { header: "GO BACK", items: [] },
      };

      //If we have a previous step then update the exist time
      if (Object.values(draft.pathwayEpisode.route).length > 0) {
        draft.pathwayEpisode.route[
          Object.values(draft.pathwayEpisode.route).length - 1
        ].exitTime = firebase.firestore.FieldValue.serverTimestamp();
      }

      draft.pathwayEpisode.route[
        Object.values(draft.pathwayEpisode.route).length
      ] = step;

      draft.episodeStatus = "UNSAVED";

      break;

    case "REPLACE_EPISODE":
      draft.pathwayEpisode = action.payload.episode;
      draft.episodeStatus = "SAVED";
      break;

    case "ADD_EPISODE_OUTCOME":
      //Clear the default state of our pathway
      if (draft.pathwayEpisode?.outcomes[0] === LEFT_EARLY_TEXT) {
        draft.pathwayEpisode.outcomes = [];
      }
      draft.pathwayEpisode?.outcomes.push(action.payload.outcome);
      draft.episodeStatus = "UNSAVED";
      break;
    default:
      throw Error("Unknown route action");
  }

*/

export type PathwayNavigationContextState = {
  route: NodePointer[];
  activeNode?: NodePointer;
  decisionSummary: NodeDecisionAction[];
  currentPathwayId: string;
  tenantId: string;
  nodeContext: INodeContext;
  actionDirection?: PathwayMoveDirection;
};

const initialState: PathwayNavigationContextState = {
  route: [],
  decisionSummary: [],
  currentPathwayId: "",
  tenantId: "",
  nodeContext: { fields: [] },
};

export const pathwayNavigationSlice = createSlice({
  name: "pathwayNavigation",
  initialState: initialState,
  reducers: {
    moveToNewPathway: (
      state,
      action: PayloadAction<{ nodeId: string; pathwayId: string }>
    ) => {
      console.error("REDUX NAVIGATION BEING USED");
      state.activeNode = {
        nodeId: action.payload.nodeId,
        pathwayId: action.payload.pathwayId,
      };
    },
    moveToNextNode: (
      state,
      action: PayloadAction<
        NodePointer & { clearDecisions?: boolean; answer: string }
      >
    ) => {
      console.error("REDUX NAVIGATION BEING USED");
      const newPosition = {
        nodeId: action.payload.nodeId,
        pathwayId: action.payload.pathwayId || state.currentPathwayId,
      };

      console.log(newPosition);

      const currentRoute = state.route;
      const summary = state.decisionSummary;
      if (state.activeNode) {
        currentRoute.push(state.activeNode);

        if (state.nodeContext.fields.length) {
          summary.push({
            header: action.payload.answer,
            items: [...state.nodeContext.fields],
          });
        } else {
          summary.push({ header: action.payload.answer, items: [] });
        }
        //If we are clearing the decision summary then we also need to clear the route
        if (action.payload.clearDecisions) {
          summary.length = 0;
          currentRoute.length = 0;
        }
      }

      state.route = [...currentRoute];
      state.activeNode = { ...newPosition };
      state.decisionSummary = [...summary];
      state.currentPathwayId = newPosition.pathwayId;
      state.nodeContext = { fields: [] };
      state.actionDirection = "FORWARD";
    },
    moveToPreviousNode: (state, action: PayloadAction<NodePointer>) => {
      console.error("REDUX NAVIGATION BEING USED");
      let lastNodePointer = state.route.pop();

      const lastDecisionSummary = state.decisionSummary;
      const item = lastDecisionSummary.pop();

      const previousNodeContext: INodeContext = {
        fields: [],
      };

      if (item && Boolean(item.items.length)) {
        previousNodeContext.fields = item.items;
      }

      if (!lastNodePointer) {
        return;
      }
      state.actionDirection = "BACKWARD";
      state.route = [...state.route];
      state.activeNode = lastNodePointer;
      state.decisionSummary = [...lastDecisionSummary];
      state.nodeContext = previousNodeContext;
      state.currentPathwayId = lastNodePointer.pathwayId;
    },
    nodeLogicOn: (state, action: PayloadAction<INodeContextItem>) => {
      console.error("REDUX NAVIGATION BEING USED");
      //Remove the current answer for the namespace
      state.nodeContext.fields = state.nodeContext.fields.filter(
        (x) =>
          x.valueId !== action.payload.valueId ||
          x.blockFieldId !== action.payload.blockFieldId
      );

      if (!action.payload.blockFieldId) {
        state.nodeContext.fields = state.nodeContext.fields.filter(
          (x) => x.blockId !== action.payload.blockId
        );
      }

      //Add the new answer
      state.nodeContext.fields.push({ ...action.payload });
    },
    nodeLogicOff: (state, action: PayloadAction<INodeContextItem>) => {
      console.error("REDUX NAVIGATION BEING USED");
      // Remove the current answer
      state.nodeContext.fields = state.nodeContext.fields.filter(
        (x) => x.blockFieldId !== action.payload.blockFieldId
      );
    },
    nodeLogicReset: (state) => {
      console.error("REDUX NAVIGATION BEING USED");
      state.nodeContext = {
        fields: [],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  moveToNewPathway,
  moveToNextNode,
  moveToPreviousNode,
  nodeLogicOff,
  nodeLogicOn,
  nodeLogicReset,
} = pathwayNavigationSlice.actions;

export default pathwayNavigationSlice.reducer;
