import ReferenceDocuments from "app/components/ReferenceDocuments";
import useUser from "features/hooks/useUser";
import CompanyIcon from "app/assets/company_icon.svg";
import LOTIcon from "app/assets/LOT_Icon.svg";
import MDIcon from "app/assets/MD_Icon.svg";
import UKCA from "app/assets/UKCA.png";
import instructions from "app/assets/instructions.png";
import ManufacturerIcon from "app/assets/manufacturerIcon.svg";

import { useTheme, styled, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EULAModal from "app/components/EULAModal";
import { useState } from "react";
import getReleaseDate from "app/common/util/getReleaseDate";
import SafeHtml from "app/components/SafeHtml";

const manufacturerNameAndAddress =
  "Primum Digital Ltd,<br/>Harscombe House,<br/>1 Darklake View,<br/>Estover,<br/>Plymouth,<br/>PL6 7TL,<br/>United Kingdom";

interface TooltipLayoutProps {
  tooltipContent: string | React.ReactNode;
  image: string;
  altAndLabel: string;
}

const TooltipLayout = ({
  tooltipContent,
  image,
  altAndLabel,
}: TooltipLayoutProps) => {
  return (
    <Tooltip title={tooltipContent} arrow>
      <IconButton sx={{ p: 0 }} aria-label={altAndLabel}>
        <img src={image} alt={altAndLabel} height="18" />
      </IconButton>
    </Tooltip>
  );
};

/**
 * @param noAuth If set to true, content is shown as if the user was unauthenticated. If set to false, the authentication status of the active user is used. Defaults to false.
 */
const Footer = ({
  displayFull,
  className,
  noAuth = false,
}: {
  displayFull?: boolean;
  className?: string;
  noAuth?: boolean;
}) => {
  const theme = useTheme();
  const { profile } = useUser();

  const [openEULA, setOpenEULA] = useState<boolean>(false);

  const showAuthContent = noAuth === false && profile !== undefined;

  return (
    <div
      className={className}
      style={{
        width: "100%",
        fontSize: "0.725rem",
        zIndex: 1000,
        bottom: "0",
        boxShadow:
          "0px 5px 5px -3px rgba(1, 55, 61, 0.2), 0px 8px 10px 1px rgba(1, 55, 61, 0.14), 0px 3px 14px 2px rgba(1, 55, 61, 0.12)",
      }}
    >
      <EULAModal open={openEULA} setOpen={setOpenEULA} />
      {displayFull && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "rgb(255,255,255)",
            p: 2,
            mb: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              justifyContent: "space-between",
              flexDirection: theme.isMobileView ? "column" : "row",
              width: "75%",
            }}
          >
            <Box>
              <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
                Contact Details
              </div>

              <Box sx={{ display: "flex", gap: 1 }}>
                <EmailOutlinedIcon fontSize="small" />
                <p>contact@primumdigital.com</p>
              </Box>

              <Box sx={{ display: "flex", gap: 1 }}>
                <CallOutlinedIcon fontSize="small" />
                <p> 07960051761</p>
              </Box>
            </Box>
            <Box>
              <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
                Primum Digital Ltd
              </div>
              Harscombe House, 1 Darklake View, Estover, Plymouth, PL6 7TL,
              United Kingdom
              <br />
              Registered in England and Wales
            </Box>
            <Box>
              <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
                Company Details
              </div>
              <div>
                Company No: <strong>12064793</strong>
              </div>
              <div>
                VAT No: <strong>339 4606 81</strong>
              </div>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          backgroundColor: "rgb(255,255,255)",
          padding: "8px 24px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 2,
            flexDirection: theme.isMobileView ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: theme.isMobileView ? "column" : "row",
              flex: 1,
              gap: theme.isMobileView ? 1 : 3,
              order: 1,
              alignItems: "center",
            }}
          >
            {showAuthContent && (
              <ReferenceDocuments
                as="LINK"
                documentName="CLINICAL SAFETY CASE REPORT"
              />
            )}

            <Box>
              <p>
                <Link
                  href="https://www.crosscover.co.uk/accessibility-statement"
                  target="_blank"
                >
                  <strong
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    Accessibility Statement
                  </strong>
                </Link>
              </p>
            </Box>

            <ReferenceDocuments as="LINK" documentName="PRIVACY POLICY" />

            {showAuthContent && (
              <Box
                onClick={() => setOpenEULA(true)}
                sx={{
                  color: theme.palette.primary.main,
                  textDecoration: "underline",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#436fcd",
                  },
                }}
              >
                <p>
                  <strong>EULA</strong>
                </p>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent:
                theme.isDesktopView && !displayFull ? "flex-end" : "center",
              order: theme.isMobileView ? 3 : 2,
            }}
          >
            Copyright &copy; Primum Digital Ltd, 2018-{new Date().getFullYear()}
          </Box>

          <Box
            sx={{
              flex: theme.isDesktopView ? 0 : 1,
              order: theme.isMobileView ? 2 : 3,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: theme.isMobileView ? "flex-start" : "flex-end",
              }}
            >
              {showAuthContent && (
                <>
                  <TooltipLayout
                    tooltipContent={
                      <Typography variant="subtitle2" display="inline">
                        Please click on the{" "}
                        <ReferenceDocuments
                          sx={{
                            color: "white",
                            cursor: "pointer",
                            display: "inline-block",
                            textDecoration: "underline",
                            "&:hover": {
                              color: theme.palette.primary.main,
                            },
                          }}
                          as="LINK"
                          documentName="USER MANUAL"
                        />{" "}
                        to show the instructions for use
                      </Typography>
                    }
                    image={instructions}
                    altAndLabel={"Instructions Icon"}
                  />

                  <TooltipLayout
                    tooltipContent={
                      <Typography variant="subtitle2">
                        Version {process.env.REACT_APP_VERSION}
                      </Typography>
                    }
                    image={LOTIcon}
                    altAndLabel={"LOT Icon"}
                  />
                </>
              )}

              {showAuthContent ? (
                <TooltipLayout
                  tooltipContent={
                    <Typography variant="subtitle2">
                      Class I Medical Device
                    </Typography>
                  }
                  image={MDIcon}
                  altAndLabel="Medical Device Icon"
                />
              ) : (
                <IconButton sx={{ p: 0 }} aria-label="Medical Device Icon">
                  <img src={MDIcon} alt="Medical Device Icon" height="18" />
                </IconButton>
              )}

              <TooltipLayout
                tooltipContent={
                  <Typography variant="subtitle2">
                    UK Conformity Assessed
                  </Typography>
                }
                image={UKCA}
                altAndLabel="UKCA Icon"
              />

              <TooltipLayout
                tooltipContent={
                  <Typography variant="subtitle2">
                    {getReleaseDate()}
                  </Typography>
                }
                image={CompanyIcon}
                altAndLabel="Company Icon"
              />

              <TooltipLayout
                tooltipContent={
                  <Typography variant="subtitle2">
                    {<SafeHtml html={manufacturerNameAndAddress} allowHtml />}
                  </Typography>
                }
                image={ManufacturerIcon}
                altAndLabel="Manufacturer Icon"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default styled(Footer)({});
