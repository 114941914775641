import { Dropdown, Popup } from "semantic-ui-react";
import { filterOptions, sortOptions } from "./utils/DocumentLibraryHelper";
import { docLibStyles } from "./utils/DocumentLibraryStyles";
import {
  FilterCriteria,
  SortCriteria,
  viewType,
} from "./docLibTypes/DocumentLibraryTypes";
import ButtonGroup from "@mui/material/ButtonGroup";
import PopUp from "app/components/PopUp";
import Button from "@mui/material/Button";

interface ActionBarProps {
  filterBy?: FilterCriteria;
  filterCriteria: FilterCriteria;
  sortCriteria: SortCriteria;
  viewType: viewType;
  setFilterCriteria: (value: FilterCriteria) => void;
  setSortCriteria: (value: SortCriteria) => void;
}

const DocumentLibraryFilters = ({
  filterBy,
  filterCriteria,
  viewType,
  sortCriteria,
  setFilterCriteria,
  setSortCriteria,
}: ActionBarProps) => {
  return (
    <>
      <div style={docLibStyles.actionBar}>
        {!filterBy && (
          <Popup
            content="Filter by"
            trigger={
              <Dropdown
                style={docLibStyles.dropDown}
                selection
                options={filterOptions}
                onChange={(e, { value }) =>
                  setFilterCriteria(value as FilterCriteria)
                }
                value={filterCriteria}
              />
            }
          />
        )}
        {viewType === "Grid View" && (
          <Popup
            content="Sort by"
            trigger={
              <Dropdown
                style={docLibStyles.dropDown}
                selection
                options={sortOptions}
                onChange={(e, { value }) =>
                  setSortCriteria(value as SortCriteria)
                }
                value={sortCriteria}
              />
            }
          />
        )}
        <div style={docLibStyles.actionBarBtnContainer}>
          {filterBy && (
            <>
              <ButtonGroup
                disableElevation
                sx={{ mx: 1 }}
                aria-label="outlined primary button group"
              >
                <PopUp
                  component={
                    <Button
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5px 0 0 5px",
                      }}
                      variant={
                        filterCriteria === filterBy ? "contained" : "outlined"
                      }
                      onClick={() => setFilterCriteria(filterBy)}
                    >
                      <img
                        alt={`Filter by ${
                          filterBy.charAt(0) +
                          filterBy.substring(1).toLowerCase()
                        }`}
                        style={docLibStyles.actionBarFilterToggle}
                        src={`/assets/documentLibrary/${filterBy?.toLowerCase()}.svg`}
                      />
                    </Button>
                  }
                  popUpText={
                    filterBy.charAt(0) + filterBy.substring(1).toLowerCase()
                  }
                  backGroundColour="dark"
                />

                <PopUp
                  component={
                    <Button
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "0 5px 5px 0",
                      }}
                      variant={
                        filterCriteria === filterBy ? "outlined" : "contained"
                      }
                      onClick={() => setFilterCriteria("ALL-FILES")}
                    >
                      <img
                        alt="Show all documents"
                        style={docLibStyles.actionBarFilterToggle}
                        src="/assets/documentLibrary/all-files.svg"
                      />
                    </Button>
                  }
                  popUpText={"All Documents"}
                  backGroundColour="dark"
                />
              </ButtonGroup>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentLibraryFilters;
