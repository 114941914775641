import "./helpers/gallery.css";
import PopupButton from "app/common/PopupButton";
import DateTime from "app/components/DateTime";
import { useState } from "react";
import { Image, Modal, List, Button, Popup } from "semantic-ui-react";
import { IGalleryImage } from "../../../types/Gallery";
import LoadGalleryImage from "./LoadGalleryImage";
import { IModule } from "../../../types/CrossCover";

interface GalleryImageProps {
  galleryItem: IGalleryImage;
  onImageSelected?: (imageName: string, imageURL: string) => void;
  galleryModules: IModule[];
}

const GalleryImage = ({
  galleryItem,
  onImageSelected,
  galleryModules,
}: GalleryImageProps) => {
  const [enlargeImg, setEnlargeImg] = useState(false);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="gallery-fileIcon-hover"
          style={{
            background: "white",
            border: galleryItem.isHidden ? "3px solid #e60000" : "white",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
            height: 180,
            width: 234,
            borderRadius: 8,
            padding: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <img
              alt="gallery"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              src={galleryItem.imageURL}
              onClick={() => setEnlargeImg(true)}
            />
          </div>

          <div className="gallery-actions-overlay">
            <Button.Group>
              <Modal
                closeIcon
                open={enlargeImg}
                trigger={
                  <PopupButton
                    size="medium"
                    icon="eye"
                    color="green"
                    popupContent="Preview"
                  ></PopupButton>
                }
                onClose={() => setEnlargeImg(false)}
                onOpen={() => setEnlargeImg(true)}
              >
                <Modal.Content>
                  <Image fluid src={galleryItem.imageURL} />
                </Modal.Content>
              </Modal>

              {onImageSelected ? (
                <Popup
                  content="Insert"
                  trigger={
                    <Button
                      icon="plus"
                      color="yellow"
                      onClick={() => {
                        onImageSelected(
                          galleryItem.imageName,
                          galleryItem.imageURL
                        );
                      }}
                    />
                  }
                />
              ) : (
                <LoadGalleryImage
                  galleryModules={galleryModules}
                  galleryItem={galleryItem}
                  saveButtonText={"Save"}
                  closeButtonText={"Cancel"}
                  title={"Edit Image"}
                />
              )}
            </Button.Group>
          </div>
        </div>
      </div>
      <List style={{ textAlign: "center" }}>
        <List.Item
          style={{
            wordBreak: "break-all",
            width: 234,
          }}
        >
          <b>{galleryItem.imageName}</b>
        </List.Item>
        <List.Item>
          <DateTime value={galleryItem.updatedAt} as={"date"} />
        </List.Item>
      </List>
    </>
  );
};

export default GalleryImage;
