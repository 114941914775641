import { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import GalleryImage from "./GalleryImage";
import { IGalleryImage, IGalleryImageGroup } from "../../../types/Gallery";
import {
  getActiveGroupsFromModules,
  sortOptions,
} from "./helpers/galleryHelpers";
import useModules from "features/hooks/useModules";
import { collator } from "features/documentLibrary/utils/DocumentLibraryHelper";
import { ShowImages } from "./GalleryManager";
import { IModule } from "../../../types/CrossCover";
import isEmpty from "lodash/isEmpty";

interface GalleryComponentProps {
  galleryModules?: IModule[];
  showImages: ShowImages;
  onImageSelected?: (imageName: string, imageURL: string) => void;
  isGalleryManager: boolean;
}

type SortCriteria = keyof Pick<IGalleryImage, "imageName" | "updatedAt">;

const GalleryComponent = ({
  galleryModules,
  onImageSelected,
  showImages,
  isGalleryManager,
}: GalleryComponentProps) => {
  const [galleryImages, setGalleryImages] = useState<IGalleryImage[]>([]);
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>("imageName");
  const [groupFilter, setGroupFilter] = useState<string[]>([]);
  const [titleFilter, setTitleFilter] = useState<string[]>([]);

  const { activeModule } = useModules();

  if (galleryModules === undefined || activeModule === undefined) {
    throw Error("Modules not found");
  }

  useEffect(() => {
    let gallerySnapshot;
    gallerySnapshot = firebase.firestore().collection("gallery");

    return gallerySnapshot.onSnapshot((gallerySnaps) => {
      const galleryItems: IGalleryImage[] = [];

      if (!gallerySnaps.metadata.hasPendingWrites) {
        gallerySnaps.forEach((gallerySnap) => {
          galleryItems.push({
            ...(gallerySnap.data() as IGalleryImage),
            id: gallerySnap.id,
          });
        });

        setGalleryImages(
          galleryItems
            .concat()
            .sort((a, b) => a.imageName.localeCompare(b.imageName))
        );
      }
    });
  }, []);

  const filterImagesByModule = (image: IGalleryImage) => {
    // show all images if user is in gallery manager
    if (isGalleryManager) {
      return image;
    }

    // when not in gallery manager, display images from module that the tenant is currently in
    if (!isGalleryManager) {
      for (const imageModule of image.modules) {
        if (activeModule.isActive === true && activeModule.id === imageModule) {
          return image;
        }
      }
    }
  };

  const filterImagesByGroup = (image: IGalleryImage) => {
    if (groupFilter.length === 0) {
      return image;
    }

    if (!isEmpty(image.groups)) {
      // if image belongs to more than one group, merging those groups together so ids can be evaluated against
      const imageGroupIds = Object.values(image.groups);
      const mergedGroupIds = imageGroupIds.flat(1);

      return mergedGroupIds.some((group) => groupFilter.includes(group));
    }
  };

  const filterImagesByTitle = (image: IGalleryImage) => {
    if (titleFilter.length === 0) {
      return image;
    }

    if (titleFilter.length > 0) {
      return titleFilter.includes(image.imageName);
    }
  };

  const filterHiddenImages = (image: IGalleryImage) => {
    if (showImages === "ALL") {
      return image;
    }
    if (showImages === "LIVE") {
      return image.isHidden === false;
    }
    if (showImages === "HIDDEN") {
      return image.isHidden === true;
    }
  };

  const sortImages = (a: IGalleryImage, b: IGalleryImage) => {
    switch (sortCriteria) {
      case "imageName":
        return collator.compare(
          a[sortCriteria] as string,
          b[sortCriteria] as string
        );
      case "updatedAt":
        return collator.compare(
          b[sortCriteria] as unknown as string,
          a[sortCriteria] as unknown as string
        );
    }
  };

  const groupDropDownOptions = () => {
    const allGroupOptions =
      getActiveGroupsFromModules(galleryModules)?.filter(
        (options) => options.status === "ACTIVE"
      ) || [];
    let groupOptions: IGalleryImageGroup[] = [];

    if (!isGalleryManager) {
      const moduleSpecificOptions = allGroupOptions.filter(
        (option) => option.moduleId === activeModule.id
      );
      groupOptions.push(...moduleSpecificOptions);
    }

    if (isGalleryManager) {
      groupOptions = allGroupOptions;
    }

    const dropDownOptions = groupOptions.map((group) => {
      return {
        key: group.id || "",
        value: group.id || "",
        text: isGalleryManager
          ? `${group.moduleId} - ${group.groupName}`
          : group.groupName,
      };
    });

    return dropDownOptions.sort((a, b) => a.text.localeCompare(b.text));
  };

  const nameDropDownOptions = () => {
    let nameOptions: IGalleryImage[] = [];

    if (!isGalleryManager) {
      const activeImages = galleryImages.filter(
        (image) => image.isHidden === false
      );
      nameOptions.push(...activeImages);
    }

    if (isGalleryManager) {
      switch (showImages) {
        case "ALL":
          nameOptions.push(...galleryImages);
          break;
        case "LIVE":
          const activeImages = galleryImages.filter(
            (image) => image.isHidden === false
          );
          nameOptions.push(...activeImages);
          break;
        case "HIDDEN":
          const hiddenImages = galleryImages.filter(
            (image) => image.isHidden === true
          );
          nameOptions.push(...hiddenImages);
          break;
        default:
          nameOptions.push(...galleryImages);
      }
    }

    const dropDownOptions = nameOptions.map((image) => {
      return {
        key: image.id,
        value: image.imageName,
        text: image.imageName,
      };
    });
    return dropDownOptions.sort((a, b) => a.text.localeCompare(b.text));
  };

  return (
    <div style={{ minHeight: "50vh" }}>
      <div
        style={{
          display: "flex",
          gap: 16,
          zIndex: 1000,
          paddingTop: 0,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Filter by Title:</label>
          <Dropdown
            clearable
            style={{
              zIndex: 1000,
              maxWidth: 500,
              wordBreak: "break-all",
            }}
            placeholder="Title"
            onChange={(e, d) => setTitleFilter(d.value as string[])}
            search
            selection
            multiple
            options={nameDropDownOptions()}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Filter by Groups:</label>
          <Dropdown
            clearable
            style={{
              zIndex: 1000,
              maxWidth: 500,
              wordBreak: "break-all",
            }}
            placeholder="Groups"
            onChange={(e, d) => setGroupFilter(d.value as string[])}
            search
            selection
            multiple
            options={groupDropDownOptions()}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Order by:</label>
          <Dropdown
            style={{ zIndex: 100 }}
            placeholder="Image name"
            onChange={(e, { value }) => setSortCriteria(value as SortCriteria)}
            value={sortCriteria}
            selection
            options={sortOptions}
          />
        </div>
      </div>

      <div style={{ marginTop: 16, display: "flex", flexWrap: "wrap" }}>
        {galleryImages
          .filter(filterImagesByTitle)
          .filter(filterImagesByGroup)
          .filter(filterImagesByModule)
          .filter(filterHiddenImages)
          .concat()
          .sort(sortImages)
          .map((galleryItem) => (
            <div
              style={{ marginRight: 8, marginBottom: 16 }}
              key={galleryItem.id}
            >
              <GalleryImage
                galleryModules={galleryModules}
                onImageSelected={onImageSelected}
                galleryItem={galleryItem}
                key={galleryItem.id}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
export default GalleryComponent;
