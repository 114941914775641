import { Segment, Modal, Grid, Button, Image, Icon } from "semantic-ui-react";
import { useState } from "react";
import CrosscoverLogo from "../../../app/assets/CC_Logo_Landscape_BG.svg";

const NodeImagePreview = (props: any) => {
  const { node } = props;
  const [openModal, setOpenModal] = useState(false);
  const image = {
    height: "auto",
    maxHeight: "350px",
    maxWidth: "100%",
    cursor: "pointer",
  };
  const modalImage = {
    height: "auto",
    maxWidth: "100%",
    maxHeight: "500px",
    cursor: "pointer",
    textAlign: "center",
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  return (
    <Segment textAlign="center" data-testid="node-preview-image">
      <Modal
        centered
        open={openModal}
        onClose={handleCloseModal}
        onOpen={handleOpenModal}
        trigger={
          <div>
            <p>
              Click image to enlarge <Icon name="search plus" />
            </p>
            <Image style={image} src={node.image} centered />
          </div>
        }
      >
        <Modal.Header>
          <Grid>
            <Grid.Column width={8}>
              <Image size="small" src={CrosscoverLogo} alt="logo" />
            </Grid.Column>
            <Grid.Column width={8}>
              <Button floated="right" color="grey" onClick={handleCloseModal}>
                X
              </Button>
            </Grid.Column>
          </Grid>{" "}
        </Modal.Header>
        <Modal.Content>
          <Image style={modalImage} src={node.image} centered />
        </Modal.Content>
      </Modal>
    </Segment>
  );
};

export default NodeImagePreview;
