import { Box, Button, Checkbox, Chip, Radio, Typography } from "@mui/material";
import { isFieldToggled } from "features/NodeEditorV2/NodeEditorUtils";
import {
  IChoicesLogicField,
  ValueLogicField,
} from "../../../../../types/Nodes";
import { INodeContext } from "features/hooks/navigatorReducer";

const FieldToggleComponentView = ({
  type,
  field,
  showFieldScores,
  multipleAnswers,
  toggleField,
  nodeContext,
  addMarginBottom,
}: {
  type: "checkbox" | "button" | "yesNo";
  field: IChoicesLogicField;
  showFieldScores: boolean;
  multipleAnswers: boolean;
  toggleField: (
    field: IChoicesLogicField,
    valueObject?: ValueLogicField
  ) => void;
  nodeContext: INodeContext | undefined;
  addMarginBottom: boolean;
}) => {
  const isChecked =
    isFieldToggled(
      "field",
      field.values[0].valueId,
      nodeContext ?? undefined
    ) ?? false;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "40px",
        mb: addMarginBottom ? 1 : 0,
      }}
    >
      {type !== "yesNo" && (
        <>
          {showFieldScores && type === "checkbox" && (
            <Chip
              label={
                <span>
                  {field.values[0].value >= 0
                    ? `+${field.values[0].value}`
                    : field.values[0].value}
                </span>
              }
              sx={{
                fontWeight: 500,
                marginRight: "8px",
                width: "50px",
                backgroundColor: "rgba(194, 194, 194, 0.5)",
              }}
            />
          )}
          {multipleAnswers && (
            <Checkbox
              sx={
                type === "button"
                  ? {
                      "& .MuiSvgIcon-root": {
                        height: "50px",
                        width: "auto",
                      },
                      p: 0,
                    }
                  : null
              }
              onClick={() => toggleField(field)}
              checked={isChecked}
              id={field.fieldId}
            />
          )}
          {!multipleAnswers && (
            <Radio
              sx={
                type === "button"
                  ? {
                      "& .MuiSvgIcon-root": {
                        height: "50px",
                        width: "auto",
                      },
                      p: 0,
                    }
                  : null
              }
              onClick={() => toggleField(field)}
              checked={isChecked}
              id={field.fieldId}
            />
          )}
          <label
            style={
              type === "button"
                ? {
                    backgroundColor: isChecked
                      ? "#015D67"
                      : "rgba(1, 93, 103, 0.02)",
                    width: "100%",
                    textAlign: "center",
                    borderRadius: "5px",
                    cursor: "pointer",
                    border: "1px solid #015D67",
                    color: isChecked ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                    fontWeight: "500",
                    minHeight: "40px",
                    wordBreak: "break-word",
                    whiteSpace: "pre-line",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {}
            }
            htmlFor={field.fieldId}
          >
            <span style={{ wordBreak: "break-word", whiteSpace: "pre-line" }}>
              {field.title}
            </span>

            {showFieldScores && type === "button" && (
              <Chip
                label={
                  <span>
                    {field.values[0].value >= 0
                      ? `+${field.values[0].value}`
                      : field.values[0].value}
                  </span>
                }
                sx={{
                  color: isChecked ? "#FFF" : "",
                  ml: 1,
                  fontSize: "14px !important",
                  backgroundColor: "rgba(194, 194, 194, 0.5)",
                  mr: 1,
                }}
              />
            )}
          </label>
        </>
      )}
      {type === "yesNo" && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            wordBreak: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          <Typography
            sx={{
              minWidth: "70%",
              width: "calc((450px - 100%) * 1000)",
              maxWidth: "100%",
            }}
          >
            {field.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              minWidth: "30%",
              width: "calc((450px - 100%) * 1000)",
              maxWidth: "100%",
            }}
          >
            {field.values.map((value) => (
              <Button
                key={value.valueId}
                variant="contained"
                onClick={() => toggleField(field, value)}
                sx={{
                  wordBreak: "break-word",
                  whiteSpace: "pre-line",
                  "&:hover": {
                    color: "#FFF",
                  },
                  color: isFieldToggled(
                    "field",
                    value.valueId,
                    nodeContext ?? undefined
                  )
                    ? "#FFF"
                    : "rgba(0, 0, 0, 0.6)",
                  flexGrow: 1,
                  mr: 1,
                  backgroundColor: isFieldToggled(
                    "field",
                    value.valueId,
                    nodeContext ?? undefined
                  )
                    ? "secondary"
                    : "rgba(194, 194, 194, 1)",
                }}
                endIcon={
                  showFieldScores ? (
                    <Chip
                      label={
                        <span>
                          {value.value >= 0 ? `+${value.value}` : value.value}
                        </span>
                      }
                      sx={{
                        color: isFieldToggled(
                          "field",
                          value.valueId,
                          nodeContext ?? undefined
                        )
                          ? "#FFF"
                          : "",
                        fontSize: "14px !important",
                        backgroundColor: "rgba(194, 194, 194, 0.5)",
                      }}
                    />
                  ) : (
                    ""
                  )
                }
              >
                {value.text}
              </Button>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FieldToggleComponentView;
