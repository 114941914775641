import { useEffect, useState } from "react";
import {
  IPrivateProfile,
  MFAFactors,
  IUserNagScreens,
} from "../../../types/User";
import useDocument from "./useDocument";
import useUser from "./useUser";
import { getDb } from "app/data/DbProvider";
import { DBTimestamp } from "app/data/FireStoreProvider";
import { getAuth, multiFactor, PhoneMultiFactorGenerator } from "firebase/auth";
import functions from "app/common/util/functions";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { doc, setDoc } from "firebase/firestore";

const db = getDb();

const useUserPrivateProfile = () => {
  const [userNagScreens, setUserNagScreens] = useState<IUserNagScreens[]>();

  const { profile } = useUser();

  const { document: privateProfile } = useDocument<IPrivateProfile>(
    `users/${profile.uid}/private`,
    "profile"
  );

  // const { document: userNagScreens } = useDocument<IUserNagScreens>(
  //   `users/${profile.uid}/private`,
  //   "nagScreens"
  // );

  const { getDocument } = useDocument<IUserNagScreens>(
    `users/${profile.uid}/private`
  );

  useEffect(() => {
    const loadUserNagScreens = async () => {
      const userNagScreens = await getDocument("nagScreens");

      const userScreens: IUserNagScreens[] = Object.values(
        userNagScreens
      ).filter((screen) => typeof screen === "object");

      setUserNagScreens(userScreens);
    };

    loadUserNagScreens();
  }, [getDocument]);

  const acceptEULA = async () => {
    if (profile === undefined) {
      throw new Error("AcceptEULA: Unknown user");
    }

    await db.updateDocumentById<IPrivateProfile>(
      `/users/${profile.uid}/private`,
      "profile",
      { eulaAccepted: new DBTimestamp() },
      profile.uid,
      true
    );
  };

  const addMFAFactor = async (factorName: MFAFactors) => {
    if (profile === undefined) {
      throw new Error("AcceptEULA: Unknown user");
    }

    await db.updateDocumentById<IPrivateProfile>(
      `/users/${profile.uid}/private`,
      "profile",
      { registeredMFAFactor: factorName },
      profile.uid,
      true
    );
  };

  const addUserNagScreens = async (nagScreenId: string) => {
    const nagScreenRef = doc(
      firebase.firestore(),
      `users/${profile.uid}/private/nagScreens`
    );

    await setDoc(
      nagScreenRef,
      {
        [nagScreenId]: {
          screenId: nagScreenId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: profile.email,
          updatedBy: profile.email,
        },
      },
      { merge: true }
    );
  };

  const disableMFA = async () => {
    if (privateProfile?.registeredMFAFactor === "AUTHENTICATOR") {
      await functions.disableAuthenticator({});
    }

    if (privateProfile?.registeredMFAFactor === "PHONE") {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const phoneFactor = await multiFactor(user).enrolledFactors.find(
          (factor) => factor.factorId === PhoneMultiFactorGenerator.FACTOR_ID
        );
        if (phoneFactor) {
          await multiFactor(user).unenroll(phoneFactor);
        }

        await db.updateDocumentById<IPrivateProfile>(
          `/users/${profile.uid}/private`,
          "profile",
          { registeredMFAFactor: "" },
          profile.uid,
          true
        );
      }
    }
  };

  // useEffect(() => {
  //   const load = async () => {
  //     const auth = getAuth();
  //     const user = auth.currentUser;
  //     if (user) {
  //       const isPhoneMFAEnabled = await multiFactor(user).enrolledFactors
  //         .length;
  //       if (isPhoneMFAEnabled) {
  //         //setMfaFactorEnabled("PHONE");
  //         return;
  //       }
  //     }
  //   };

  //   if (privateProfile?.registeredMFAFactor) {
  //     //setMfaFactorEnabled(privateProfile?.registeredMFAFactor);
  //     return;
  //   }
  //   load();
  // }, [privateProfile]);

  return {
    privateProfile,
    acceptEULA,
    mfaFactorEnabled: privateProfile?.registeredMFAFactor ?? "",
    disableMFA,
    addMFAFactor,
    userNagScreens,
    addUserNagScreens,
  };
};

export default useUserPrivateProfile;
