import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ITenant,
  ITenantConfiguration,
  ITenantSettings,
} from "../../../types/Tenants";

export type TenantsContextState = {
  activeTenant?: ITenant;
  //userPermissions: string[];
  userTenants?: ITenant[];
  tenants?: ITenant[];
  tenantConfiguration?: ITenantConfiguration;
  tenantSettings?: ITenantSettings;
};

const initialState: TenantsContextState = {};

export const tenantsSlice = createSlice({
  name: "tenants",
  initialState: initialState,
  reducers: {
    updateTenants: (state, action: PayloadAction<ITenant[]>) => {
      state.tenants = action.payload;
    },
    updateActiveTenant: (state, action: PayloadAction<ITenant>) => {
      state.activeTenant = action.payload;
    },
    updateUserTenants: (state, action: PayloadAction<ITenant[]>) => {
      state.userTenants = action.payload;
    },
    updateTenantConfiguration: (
      state,
      action: PayloadAction<ITenantConfiguration>
    ) => {
      state.tenantConfiguration = action.payload;
    },
    updateTenantSettings: (state, action: PayloadAction<ITenantSettings>) => {
      state.tenantSettings = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateActiveTenant,
  updateTenants,
  updateUserTenants,
  updateTenantConfiguration,
  updateTenantSettings,
} = tenantsSlice.actions;

export default tenantsSlice.reducer;
