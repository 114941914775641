import {
  IChoicesLogicField,
  NodeBlock,
  ValueLogicField,
} from "../../../../../types/Nodes";
import { IWidgetTemplate } from "../../../../../types/WidgetTemplates";
import { v4 as uuid } from "uuid";
import {
  isButtonBlock,
  isChoicesBlock,
  isScorerBlock,
  isShowHideBlock,
  isStartBackBlock,
  isYesNoBlock,
} from "features/pathwaybuilder/utils/pathwayHelperV2";

/**
 * Replaces all of the ids in the blocks with new ids and updates the rules for each block with the new ids.
 * @param widgetTemplate The widget template to clone and make the changes to.
 * @returns A new widget template with the updated ids.
 */
export const replaceWidgetTemplateIds = (
  widgetTemplate: IWidgetTemplate
): IWidgetTemplate => {
  // Create a new copy of the widget template with updated block ids
  const newWidgetTemplate: IWidgetTemplate =
    replaceWidgetTemplateBlockIds(widgetTemplate);
  // Iterate over all blocks in the template and update their ids
  Object.entries(newWidgetTemplate.blocks).forEach(([_blockId, block]) => {
    if (isShowHideBlock(block) || isButtonBlock(block)) {
      // Update the button id
      const previousButtonId: string = block.blockData.buttonId;
      const newButtonId: string = uuid();
      block.blockData.buttonId = newButtonId;
      // Update the rules of all blocks with the new id
      replaceWidgetTemplateRuleIds(
        newWidgetTemplate,
        previousButtonId,
        newButtonId
      );
      return;
    }
    if (isScorerBlock(block) || isYesNoBlock(block) || isChoicesBlock(block)) {
      // Update the group id
      const previousGroupId: string = block.blockData.groupId;
      const newGroupId: string = uuid();
      block.blockData.groupId = newGroupId;
      // Update the group ids in the rules strings
      replaceWidgetTemplateRuleIds(
        newWidgetTemplate,
        previousGroupId,
        newGroupId
      );
      // Iterate over all of the fields
      block.blockData.fields.forEach((field: IChoicesLogicField) => {
        // Update the field ids
        const previousFieldId: string = field.fieldId;
        const newFieldId: string = uuid();
        field.fieldId = newFieldId;
        // Update the field ids in the rules strings
        replaceWidgetTemplateRuleIds(
          newWidgetTemplate,
          previousFieldId,
          newFieldId
        );
        // Iterate over all of the values
        field.values.forEach((value: ValueLogicField) => {
          // Update the value ids
          const previousValueId: string = value.valueId;
          const newValueId: string = uuid();
          value.valueId = newValueId;
          // Update the value ids in the rule strings
          replaceWidgetTemplateRuleIds(
            newWidgetTemplate,
            previousValueId,
            newValueId
          );
        });
      });
      return;
    }
    if (isStartBackBlock(block)) {
      // Iterate over all of the widget rules
      block.blockData.widgetRules.forEach(
        (widgetRule: any, _widgetIndex: number) => {
          // Update the widget rule ids
          const previousId: string = widgetRule.id;
          const newId: string = uuid();
          widgetRule.id = newId;
          // Update the widget rule ids in the rules strings
          replaceWidgetTemplateRuleIds(newWidgetTemplate, previousId, newId);
        }
      );
      return;
    }
  });

  // Return the new widget template with the updated ids
  return newWidgetTemplate;
};

/**
 * Updates the rules for each block in the widget template with the new id.
 * @param widgetTemplate The widget template to make the changes to.
 * @param prevId The existing id string to be replaced.
 * @param newId The new id string to be inserted.
 */
export const replaceWidgetTemplateRuleIds = (
  widgetTemplate: IWidgetTemplate,
  prevId: string,
  newId: string
): void => {
  // Iterate over all blocks in the template
  Object.values(widgetTemplate.blocks).forEach((block: NodeBlock) => {
    // Update the rules string for each block
    if (block.blockRules.rule !== null) {
      const newRuleString: string = block.blockRules.rule.replaceAll(
        prevId,
        newId
      );
      block.blockRules.rule = newRuleString;
    }
  });
};

/**
 * Updates the widget templates block ids and updates the rules for each block in the widget template with the new ids.
 * @param widgetTemplate The widget template to clone and make the changes to.
 * @returns A new widget template with updated node ids.
 */
export const replaceWidgetTemplateBlockIds = (
  widgetTemplate: IWidgetTemplate
): IWidgetTemplate => {
  // Remember the relation of old to new block ids
  const oldToNewIdMap: Map<string, string> = new Map();

  // Clone the widget template blocks and update their block ids
  const newBlocks: Record<string, NodeBlock> = {};
  Object.entries(widgetTemplate.blocks).forEach(([blockId, block]) => {
    const newBlockId = uuid();
    newBlocks[newBlockId] = JSON.parse(JSON.stringify(block));
    oldToNewIdMap.set(blockId, newBlockId);
  });

  // Create the new widget template using the new blocks
  const newWidgetTemplate: IWidgetTemplate = {
    id: widgetTemplate.id,
    title: widgetTemplate.title,
    blocks: newBlocks,
    createdAt: widgetTemplate.createdAt,
    createdBy: widgetTemplate.createdBy,
    updatedAt: widgetTemplate.updatedAt,
    updatedBy: widgetTemplate.updatedBy,
  };

  // Update the rule strings for each block in the cloned widget template
  oldToNewIdMap.forEach((newId: string, oldId: string) => {
    replaceWidgetTemplateRuleIds(newWidgetTemplate, oldId, newId);
  });

  // Return the new widget template with updated node ids
  return newWidgetTemplate;
};
