import {
  Box,
  MenuItem,
  Select,
  Stack,
  // Switch,
  // Typography,
} from "@mui/material";

import { Dispatch } from "react";
import { NodeBlock, IDynamicNode } from "../../../../../types/Nodes";
import NodeEditorPanelAdvanceRules from "./NodeEditorPanelAdvanceRules";
import NodeEditorPanelRuleComponent from "./NodeEditorPanelRuleComponent";
import AddIcon from "@mui/icons-material/Add";
import { addRule } from "features/NodeEditorV2/NodeEditorUtils";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";

const NodeEditorPanelRulesContainer = ({
  block,
  dispatch,
  state,
}: {
  block: NodeBlock | undefined;
  dispatch: Dispatch<DynamicNodeEditorActions>;
  state: IDynamicNode;
}) => {
  const toggleAlwaysShown = () => {
    if (block) {
      let rule = null;
      if (!block.blockRules.rule) {
        rule = `{"and":[{"==":[{"var":""}, ""]}]}`;
      }
      dispatch({
        type: "UPDATE_RULES",
        payload: {
          blockId: block.blockId,
          ruleOptions: {
            rule,
            alwaysShow: !block.blockRules?.alwaysShow,
            isAdvancedRules: false,
          },
        },
      });
    }
  };

  const createRule = () => {
    if (block) {
      // let newRulesArray: any[] = [];
      // let conditionalOperator: string = "and";

      // if (block.blockRules.rule) {
      //   newRulesArray = Object.values(
      //     JSON.parse(block.blockRules.rule)
      //   )[0] as any;
      //   conditionalOperator = Object.keys(
      //     JSON.parse(block?.blockRules.rule)
      //   )[0];
      // }

      // let logicArray: (number | Object)[] = [{ var: "" }, ""];
      // let conditional = "==";
      // let newLogic = {
      //   [conditional]: logicArray,
      // };

      // newRulesArray.push(newLogic);

      // const newRuleObject = {
      //   [conditionalOperator]: newRulesArray,
      // };

      const newRule = addRule(block);

      dispatch({
        type: "UPDATE_RULES",
        payload: {
          blockId: block.blockId,
          ruleOptions: {
            ...block.blockRules,
            rule: newRule,
          },
        },
      });
    }
  };

  if (!block) {
    return <></>;
  }

  return (
    <Box sx={{ backgroundColor: "white", p: 1, borderRadius: "5px" }}>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2" fontWeight={700} lineHeight={4}>
          Always show {block.blockType}
        </Typography>
        <Switch
          checked={block.blockRules?.alwaysShow}
          onChange={toggleAlwaysShown}
        />
      </div> */}
      <Select
        sx={{ my: 1 }}
        fullWidth
        value={block.blockRules?.alwaysShow ?? false}
        onChange={(e) =>
          `${block.blockRules.alwaysShow}` !== e.target.value &&
          toggleAlwaysShown()
        }
      >
        <MenuItem value="false">Only show {block.blockName} when</MenuItem>
        <MenuItem value="true">Always show {block.blockName}</MenuItem>
      </Select>
      {!block.blockRules.alwaysShow && (
        <Stack alignItems="flex-start">
          {!block.blockRules.isAdvancedRules && (
            <>
              {block.blockRules.rule &&
                Object.entries(JSON.parse(block.blockRules.rule)).map(
                  (rule: any) =>
                    rule[1].map((ruleElement: any, index: number) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          mb: 1,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <NodeEditorPanelRuleComponent
                          index={index}
                          ruleElement={ruleElement}
                          conditionalOperator={rule[0]}
                          block={block}
                          dispatch={dispatch}
                          state={state}
                        />
                      </Box>
                    ))
                )}
              <Box
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  color: "primary.main",
                  fontWeight: "600",
                  my: 3,
                }}
                onClick={() => createRule()}
              >
                <AddIcon /> Add Rule
              </Box>
            </>
          )}
          <NodeEditorPanelAdvanceRules
            dispatch={dispatch}
            block={block}
            state={state}
          />
        </Stack>
      )}
    </Box>
  );
};

export default NodeEditorPanelRulesContainer;
