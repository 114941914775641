import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { DynamicNodeEditorActions } from "../dynamicNodeEditorReducer";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { getManagementPlanText } from "../NodeEditorUtils";
import { IDynamicNode } from "../../../../types/Nodes";
import { isConnectedNode } from "features/pathwaybuilder/utils/pathwayHelperV2";
import NodeViewJson from "app/components/NodeViewJSON";

interface INodeTitleEditorProps {
  dispatch?: React.Dispatch<DynamicNodeEditorActions>;
  isPreview: boolean;
  tenantId: string;
  currentPathway: string;
  allowHtml?: boolean;
  state: IDynamicNode;
}

const NodeInfoTenantAndNode = ({
  dispatch,
  isPreview,
  allowHtml,
  tenantId,
  currentPathway,
  state,
}: INodeTitleEditorProps) => {
  const [isMissingManagementPlanText, setIsMissingManagementPlanText] =
    useState<boolean>(false);

  // const validator = useCallback(() => {
  //   const errorMessage = "No node title present";
  //   let infos = {
  //     name: "titleValidator",
  //     isValid: true,
  //     errorMessage: [] as string[],
  //   };
  //   if (title.trim() === "") {
  //     infos.isValid = false;
  //     infos.errorMessage.push(errorMessage);
  //   }
  //   return infos;
  // }, [title]);

  // useEffect(() => {
  //   //registerValidator(validator);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onUpdateTitle = (title: string) => {
    dispatch?.({ type: "UPDATE_TITLE", payload: title });
  };

  useEffect(() => {
    if (
      isConnectedNode(state) &&
      getManagementPlanText(state.blocks).length === 0 &&
      state.nodeProperties.isEndNodeFunctionalityEnabled
    ) {
      setIsMissingManagementPlanText(true);
    } else {
      setIsMissingManagementPlanText(false);
    }
  }, [state]);

  return (
    <Box
      sx={{
        position: isPreview ? "block" : "sticky",
        top: "56px",
        zIndex: 2,
        mb: "25px",
      }}
    >
      {isPreview && (
        <Box
          sx={{
            backgroundColor: "#767676",
            color: "white",
            p: "10px",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
          }}
        >
          <h3 style={{ textAlign: "center" }}>
            {tenantId} {currentPathway} Pathway
          </h3>
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: "primary.main",
          p: 1.5,
          width: "100%",
          fontSize: "24px",
          color: "#FFF",
          textAlign: "center",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {isPreview && <p>{state.title}  <NodeViewJson node={state} inverse /></p>}
        {!isPreview && (
          <TextField
            sx={{
              "& input": {
                textAlign: "center",
                color: "#FFF",
              },
              "& label.Mui-focused": {
                color: "#FFF",
              },
              "& label": {
                color: "#FFF",
                lineHeight: "100%",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#FFF",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#FFF",
                },
                "&:hover fieldset": {
                  borderColor: "#FFF",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#FFF",
                },
              },
            }}
            onChange={(e) => onUpdateTitle(e.target.value)}
            label="Click Here to add a title to your node"
            fullWidth
            value={state.title}
          ></TextField>
        )}
       
      </Box>
      {isMissingManagementPlanText && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "2px solid #ff8000",
            borderRadius: "5px",
            background: "#ffe6cc",
            padding: 1,
            mb: 1,
          }}
        >
          <WarningAmberIcon sx={{ marginRight: "10px" }} />
          <p>
            As you have enabled End Node Functionality for this Node, a Text
            Area block is required in order to generate a Management Plan.
            <br />
            <strong>
              You cannot save changes or navigate to other linked Nodes until a
              Text Block has been added.
            </strong>
          </p>
        </Box>
      )}

      {state.title === "" && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "2px solid #ff8000",
            borderRadius: "5px",
            background: "#ffe6cc",
            padding: 1,
          }}
        >
          <WarningAmberIcon sx={{ marginRight: "10px" }} />
          <p>
            Please provide a title for your Node. <br />
            <strong>
              You cannot save changes or navigate to other linked Nodes until a
              title is added.
            </strong>
          </p>
        </Box>
      )}
    </Box>
  );
};

export default NodeInfoTenantAndNode;
