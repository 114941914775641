import { useEffect, useState } from "react";

import { INagScreen } from "../../../types/NagScreen";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import MobileStepper from "@mui/material/MobileStepper";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Tooltip from "@mui/material/Tooltip";

import UserSettingsContainer from "features/auth/ActivateAccount/UserSettingsContainer";
import LicenseAgreementContainer from "features/auth/ActivateAccount/LicenseAgreementContainer";
import MFAManage from "./../auth/Login/ManageMFA";
import useUserPrivateProfile from "features/hooks/useUserPrivateProfile";
import Guidance from "features/auth/ActivateAccount/Guidance";

interface INagModalProps {
  unSeenNagScreens: INagScreen[];
}

const NagScreenModal = ({ unSeenNagScreens }: INagModalProps) => {
  const [open, setOpen] = useState<boolean>(
    unSeenNagScreens.length > 0 ? true : false
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  const [displayedScreen, setDisplayedScreen] = useState<INagScreen>();

  const { addUserNagScreens } = useUserPrivateProfile();

  useEffect(() => {
    if (activeStep === 0) {
      setDisplayedScreen(unSeenNagScreens[0]);
    }
  }, [activeStep, displayedScreen, unSeenNagScreens]);

  const handleNextStep = async () => {
    if (activeStep < unSeenNagScreens.length - 1) {
      await addUserNagScreens(unSeenNagScreens[activeStep].id);
      setActiveStep((prev) => prev + 1);

      setDisplayedScreen(unSeenNagScreens[activeStep + 1]);
    }

    if (activeStep === unSeenNagScreens.length - 1) {
      await addUserNagScreens(unSeenNagScreens[activeStep].id);
      setOpen(false);
    }
  };

  const handlePrevStep = () => {
    setActiveStep((prev) => prev - 1);
    setDisplayedScreen(unSeenNagScreens[activeStep - 1]);
  };

  return (
    <Dialog
      onClose={() => !displayedScreen?.isRequired && setOpen(false)}
      open={open}
      maxWidth="md"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 2,
          ml: 2,
        }}
      >
        <Typography variant="h5">
          Reminder- you need to update your account settings
        </Typography>
      </Box>
      <DialogContent dividers>
        <MobileStepper
          variant="progress"
          steps={unSeenNagScreens.length + 1}
          position="static"
          activeStep={activeStep + 1}
          sx={{
            color: "success.main",
            px: 0,
            width: "100%",
            ".MuiMobileStepper-progress": {
              width: "100%",
              marginRight: 1,
            },
          }}
          nextButton={
            <Typography variant="button" sx={{ fontWeight: 600 }}>
              {activeStep + 1}/{unSeenNagScreens.length}
            </Typography>
          }
          backButton={<div style={{ display: "none" }}></div>}
        />

        {displayedScreen?.isRequired && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <AlertTitle>Required Field</AlertTitle>
            <Typography variant="subtitle1">
              You need to read the information below and add any missing details
              before continuing.
            </Typography>
          </Alert>
        )}

        {displayedScreen && (
          <>
            {displayedScreen.component === "UserSettingsContainer" && (
              <UserSettingsContainer handleNextStep={handleNextStep} />
            )}
            {displayedScreen.component === "LicenseAgreementContainer" && (
              <LicenseAgreementContainer handleNextStep={handleNextStep} />
            )}
            {displayedScreen.component === "MFAManage" && (
              <MFAManage handleNextStep={handleNextStep} skipMFAStep={true} />
            )}

            {displayedScreen.component === "Guidance" && (
              <Guidance handleNextStep={handleNextStep} isNagScreen={true} />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tooltip
          disableHoverListener={!displayedScreen?.isRequired}
          disableFocusListener={!displayedScreen?.isRequired}
          disableTouchListener={!displayedScreen?.isRequired}
          title="You need to read the information above and add any missing details
              before continuing."
          arrow
          placement="top"
        >
          <span>
            <Button
              startIcon={<ArrowBackIcon />}
              disabled={
                activeStep <= 0 ||
                unSeenNagScreens.length === 1 ||
                displayedScreen?.isRequired
              }
              onClick={() => {
                handlePrevStep();
              }}
            >
              Previous
            </Button>
          </span>
        </Tooltip>

        <Tooltip
          disableHoverListener={!displayedScreen?.isRequired}
          disableFocusListener={!displayedScreen?.isRequired}
          disableTouchListener={!displayedScreen?.isRequired}
          title="You need to read the information above and add any missing details
              before continuing."
          arrow
          placement="top"
        >
          <span>
            <Button
              endIcon={<ArrowForwardIcon />}
              disabled={displayedScreen?.isRequired}
              onClick={() => {
                handleNextStep();
              }}
            >
              {activeStep + 1 === unSeenNagScreens.length ? "Finish" : "Next"}
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default NagScreenModal;
