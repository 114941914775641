import {
  IDisplayVariablesData,
  ISTarTBackData,
  RuleConditions,
  RuleIf,
} from "./../../../../types/Nodes";
import {
  IChoicesBoxData,
  IDynamicNode,
  IImageBlockData,
  ILinkButtonData,
  INode,
  IVideoBlockData,
  NodeBlock,
  NodeBlockData,
  BlockTypes,
  INodeBlock,
  ILogicRule,
  ILogicField,
  IChoicesLogicField,
  INodeLogic,
  INodeAction,
  IRichTextAreaData,
  IFilePointer,
  IFileBlockData,
} from "../../../../types/Nodes";

import { DocumentType, IDocument } from "../../../../types/DocumentLibrary";

import { v4 as uuid4 } from "uuid";
import { NODES_ROOT_DOC_NAME, isConnectedNode } from "./pathwayHelperV2";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/storage";

export const getEditorVersion = () => {
  return (globalThis as any).editorVersion ?? "V1";
};

export const setEditorVersion = (version: "V1" | "V2") => {
  return ((globalThis as any).editorVersion = version);
};

const getPathwayPathV1 = (
  tenantId: string,
  pathwayId?: string,
  nodeCollection?: string
) => {
  if (nodeCollection) {
    return `${tenantId}/${pathwayId}/${nodeCollection}`;
  }
  if (pathwayId) {
    return `${tenantId}/${pathwayId}`;
  }
  return `${tenantId}`;
};

export const getPathwayPath = (
  tenantId: string,
  pathwayId?: string,
  branchCollection?: string
) => {
  const editorVersion = getEditorVersion();
  if (editorVersion === "V1") {
    return getPathwayPathV1(tenantId, pathwayId, branchCollection);
  }

  if (branchCollection) {
    return `${tenantId}/pathwaysRootDoc/pathwaysRootCollection/${pathwayId}/${branchCollection}/${NODES_ROOT_DOC_NAME}/nodesCollection`;
  }
  if (pathwayId) {
    return `${tenantId}/pathwaysRootDoc/pathwaysRootCollection/${pathwayId}`;
  }
  return `${tenantId}/pathwaysRootDoc/pathwaysRootCollection/`;
};

export const getNodeOrderPath = (
  tenantId: string,
  pathwayId: string,
  branchCollection: string
) => {
  const editorVersion = getEditorVersion();
  if (editorVersion === "V1") {
    return `${getPathwayPathV1(tenantId)}`;
  }

  return `${tenantId}/pathwaysRootDoc/pathwaysRootCollection/${pathwayId}/${branchCollection}`;
};

export const getMessagesPath = (
  tenantId: string,
  pathwayId: string,
  branchCollection: string
) => {
  const editorVersion = getEditorVersion();
  if (editorVersion === "V1") {
    return `${getPathwayPathV1(tenantId, pathwayId)}/messages`;
  }

  return `${getPathwayPath(
    tenantId,
    pathwayId
  )}/${branchCollection}/messagesDoc/messagesCollection`;
};

export const getReviewsPath = (
  tenantId: string,
  pathwayId: string,
  branchCollection: string
) => {
  const editorVersion = getEditorVersion();
  if (editorVersion === "V1") {
    return `${getPathwayPathV1(tenantId, pathwayId)}/reviews`;
  }

  return `${getPathwayPath(
    tenantId,
    pathwayId
  )}/${branchCollection}/reviewsDoc/reviewsCollection`;
};

export const getEpisodesPath = (
  tenantId: string,
  pathwayId: string,
  branchCollection: string,
  reviewId: string
) => {
  const editorVersion = getEditorVersion();
  if (editorVersion === "V1") {
    return `${getPathwayPathV1(tenantId, pathwayId)}/reviews`;
  }

  return `${getPathwayPath(
    tenantId,
    pathwayId
  )}/${branchCollection}/reviewsDoc/reviewsCollection/${reviewId}/episodes`;
};

export const getDeploymentPath = (
  tenantId: string,
  pathwayId?: string,
  branchCollection?: string
) => {
  const editorVersion = getEditorVersion();
  if (editorVersion === "V1") {
    return `${getPathwayPathV1(tenantId, pathwayId)}/deployments`;
  }

  return `${getPathwayPath(tenantId, pathwayId)}/deployments`;
};

export const getRecentActivityPath = (tenantId: string, moduleId: string) => {
  const editorVersion = getEditorVersion();
  if (editorVersion === "V1") {
    return `${tenantId}/Activity/${
      moduleId === "Ortho" ? "Activity" : moduleId
    }`;
  }

  return `${tenantId}/Activities/Activities`;
};

export const NodeToLogic = (node: INode): INodeLogic => {
  // const logic: INodeLogic = {
  //   groups: [],
  //   rules: [],
  //   showScores: false,
  // };

  if (!Array.isArray(node.logicfields) || node.logicfields.length === 0) {
    return {
      groups: [],
      rules: [],
      showScores: false,
    };
  }

  const logic: INodeLogic = {
    groups: [],
    rules: [],
    showScores: false,
  };

  const groupNumber = new Set(node.logicgroups);

  for (const groupId of node.logicgroups) {
    const existingGroupIndex = logic.groups.findIndex(
      (x) => x.groupId === groupId
    );

    if (existingGroupIndex === -1) {
      logic.groups.push({
        groupId: groupId,
        title: Array.isArray(node.groupNames)
          ? node.groupNames[+groupId] ?? ""
          : "",
        fields: [],
      });
    } else {
      if (
        logic.groups[existingGroupIndex].title === "" &&
        Array.isArray(node.groupNames) &&
        node.groupNames[+groupId] !== ""
      ) {
        logic.groups[existingGroupIndex].title = node.groupNames[+groupId];
      }
    }
  }

  //console.log(JSON.stringify(logic.groups));

  for (const groupId of groupNumber) {
    const fieldsInGroupIndexes = node.logicgroups.reduce<number[]>(
      (acc, element, index) => {
        if (element === groupId) {
          acc.push(index);
        }
        return acc;
      },
      []
    );

    // for (const [indexx, logicfield] of Object.entries(node.logicfields)) {
    for (const logicFieldIndex of fieldsInGroupIndexes) {
      //There is no logic field for this index, so we just skip it
      if (node.logicfields[logicFieldIndex] === "") {
        continue;
      }

      const field: ILogicField = {
        title: node.logicfields[logicFieldIndex],
        score: node.logicscores[logicFieldIndex],
      };
      const groupIndex = logic.groups.findIndex(
        (x) => x.groupId === node.logicgroups[logicFieldIndex]
      );

      logic.groups[groupIndex].fields.push(field);
    }
  }
  //console.log(JSON.stringify(logic.groups));

  //logic.groups.filter((x) => x.fields.length !== 0);

  if (node.ruleNames) {
    for (let i = 0; i < node.ruleNames.length; i++) {
      //Remove empty conditions
      if (node.ruleActions[i] === undefined) {
        continue;
      }
      const nodeAction = actionToRouteItem(
        node.ruleActions[i],
        node.ruleNames[i]
      );

      if (
        node.ruleTypes[i] !== "" &&
        node.ruleOperators[i] !== "" &&
        (node.ruleValues[i] !== "" ||
          node.ruleValuesMin[i] ||
          node.ruleValuesMax[i])
      ) {
        const rule: ILogicRule = {
          if: node.ruleTypes[i] as RuleIf,
          condition: node.ruleOperators[i] as RuleConditions,
          value:
            node.ruleOperators[i] === "is in range"
              ? node.ruleValuesMin[i]
              : node.ruleValues[i],
          maxValue: Array.isArray(node.ruleValuesMax)
            ? node.ruleValuesMax[i]
            : undefined,
          ...nodeAction,
        };

        logic.rules.push(rule);
      }
    }
  }
  logic.showScores = node.showscores ?? false;

  return logic;
};

export const actionToRouteItem = (
  action: string,
  actionText: string
): INodeAction => {
  let pathwayId = "";
  let nodeId = "";

  if (action.substring(0, 2) === "@@") {
    const prefixRemoved = action.slice(2);
    const splitParts = prefixRemoved.split("/");
    pathwayId = splitParts.at(0) ?? "";
    nodeId = splitParts.at(1) ?? "";
  }

  if (action.substring(0, 2) !== "@@") {
    nodeId = action;
  }
  if (nodeId === undefined) {
    // console.log("Node Action", action, nodeId);
  }
  return {
    pathwayId: pathwayId,
    nodeId: nodeId,
    isPathwayToPathwayLink: action.substring(0, 2) === "@@",
    linkText: actionText,
    
  };
};

export const convertLogicFieldsToChoicesLogicFields = (
  fields: ILogicField[]
): IChoicesLogicField[] => {
  return fields.map((field) => ({
    fieldId: uuid4(),
    title: field.title,
    values: [{ value: +field.score, valueId: uuid4(), text: "" }],
  }));
};

// export const convertNodeRuleToRule = (nodeLogic: INodeLogic) => {
//   if (node.ruleNames) {
//     for (let i = 0; i < node.ruleNames.length; i++) {
//       //Remove empty conditions
//       if (node.ruleTypes[i] !== "" && node.ruleOperators[i] !== "") {
//         o.push({
//           linkText: node.ruleNames[i],
//           nodeId: node.ruleActions[i],
//           pathwayId: "",
//           isPathwayToPathwayLink: false,
//           id: uuid4(),
//           if: node.ruleTypes[i],
//           condition: node.ruleOperators[i],
//           value:
//             node.ruleValues[i] !== ""
//               ? node.ruleValues[i]
//               : node.ruleValuesMin[i],
//           maxValue: (node.ruleValuesMax && node.ruleValuesMax[i]) || "",
//         });
//       }
//     }
//   }
//   return o;
// };

export const convertRuleToJsonLogic = (rule: ILogicRule) => {
  let condition;
  switch (rule.condition) {
    case "Contains":
      condition = "==";
      break;
    case "is in range":
      condition = "<=";
      break;
    default:
      condition = rule.condition;
  }

  // create logic array
  let logicArray: (number | Object)[] = [];

  

  if(rule.condition ==="is in range"){
    // add value to logic array
    if (rule.value) {
      logicArray.push(rule.if === "Field toggled" ? true : +rule.value);
    }
    
    // add object with type and var to the logic array
    if (rule.if) {
      let type = rule.if === "Score" ? "node.score" : "block";
      logicArray.push({ var: type });
    }
    

    // add max value if present at the beginning of the logic array
    if (rule.maxValue && rule.maxValue !== "") {
      logicArray.push(+rule.maxValue);
    }
  }
  if(rule.condition !=="is in range"){
      // add object with type and var to the logic array
      if (rule.if) {
        let type = rule.if === "Score" ? "node.score" : "block";
        logicArray.push({ var: type });
      }
      // add value to logic array
      if (rule.value) {
        logicArray.push(rule.if === "Field toggled" ? true : +rule.value);
      }

      // add max value if present at the beginning of the logic array
      if (rule.maxValue && rule.maxValue !== "") {
        logicArray.push(+rule.maxValue);
      }
  }

  let newLogicObject = {
    and: [{ [condition]: logicArray }],
  };

  return JSON.stringify(newLogicObject);
};

export const getTenantFromFilePath = (filePath: string) => {
  //https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/Derriford%2FUHP%20Acute%20Pain.pdf?alt=media&token=5c3e151f-2820-4c09-9d50-0b8944ac67fa
};

export const convertFileStoreLinkToFilePointer = async (
  fileStoreLink: string,
  fileType: DocumentType,
  fileName?: string
): Promise<IFilePointer> => {
  const firestore = firebase.firestore();
  const fileDoc = await firestore
    .collectionGroup(`files`)
    .where("file", "==", fileStoreLink)
    .get();
  //If the file is currently node in a document then we need to create one
  if (!fileDoc.empty) {
    return { pointer: fileDoc.docs[0].id, storageType: "firebase" };
  }
  if (fileDoc.empty) {
    const storageRef = firebase.storage().refFromURL(fileStoreLink);

    const tenantId = storageRef.bucket;
    const name = storageRef.name;
    const metaData = await storageRef.getMetadata();

    const newDoc: IDocument = {
      documentType: fileType,
      file: fileStoreLink,
      fileName: fileName ?? name,
      fileTitle: fileName ?? name,
      folderPath: "/",
      source: "firebase",
      //Due the guids as file names for images set them to be deleted from the document library.
      status: fileType === "DOCUMENT" ? "ACTIVE" : "DELETED",
      createdAt: firebase.firestore.Timestamp.fromDate(
        new Date(parseInt(metaData.timeCreated, 10))
      ),
      createdBy: "automation@crosscover.app",
      updatedAt: firebase.firestore.Timestamp.fromDate(
        new Date(parseInt(metaData.timeCreated, 10))
      ),
      updatedBy: "automation@crosscover.app",
    };

    const docRef = await firestore
      .collection(`${tenantId}/Files/files`)
      .add(newDoc);
    return { pointer: docRef.id, storageType: "firebase" };
  }
  throw Error(`Could not create a file pointer: ${fileStoreLink}`);
};

export const convertContentArea = (node: INode) => {
  if (
    //If the node content area is empty excluded it
    //or if this is an end node add the block
    (node.nodeContentArea?.trim() ?? "") !== "" ||
    !isConnectedNode(node)
  ) {
    return createBlock<IRichTextAreaData>(
      "richTextArea",
      "richTextArea-1",
      {
        content: node.nodeContentArea ?? "",
        type: "richText",
      },
      undefined,
      //Add to management plan, if this is and end node or printing is enabled
      node.print || !isConnectedNode(node)
    );
  }
  return null;
};

export const NodeToBlocks = (
  tenantId: string,
  pathwayId: string,
  node: INode
): IDynamicNode => {
  //const node = OldToNewNodeObject(oldNodeStructure);

  const dynamicNode: IDynamicNode = {
    title: "",
    id: node.id ?? "",
    blocks: {},
    nodeProperties: node.print
      ? { isEndNodeFunctionalityEnabled: true, isStartNode: false }
      : { isEndNodeFunctionalityEnabled: false, isStartNode: false },
    createdAt: node.ImportedPathwayAt ?? node.createdAt ?? 0,
    createdBy: node.ImportedPathwayBy ?? node.createdBy ?? node.updatedBy ?? "",
    updatedAt: node.updatedAt ?? node.createdAt ?? 0,
    updatedBy: node.updatedBy ?? node.createdBy ?? "",
    nodeOrigin: {
      tenantId: tenantId,
      pathwayId: pathwayId,
      createdBy:
        node.createdBy ?? node.updatedBy ?? node.ImportedPathwayBy ?? "",
      createdAt:
        node.createdAt ?? node.updatedAt ?? node.ImportedPathwayAt ?? 0,
    },
  };

  const arrayBlocks: NodeBlock[] = [];

  dynamicNode.title = node.title ?? "";
  //Create File Document for images in firestore, get reference and store filePointer in object
  node.image &&
    node.image.trim() !== "" &&
    arrayBlocks.push(
      createBlock<IImageBlockData>("image", "image-1", {
        images: [{ pointer: node.image, storageType: "direct" }],
      })
    );

  let videoCount = 1;
  if (node.vimeo?.trim()) {
    arrayBlocks.push(
      createBlock<IVideoBlockData>("video", `video-${videoCount}`, {
        filePointer: { pointer: node.vimeo, storageType: "vimeo" },
      })
    );
    videoCount++;
  }

  if (node.youtube?.trim()) {
    arrayBlocks.push(
      createBlock<IVideoBlockData>("video", `video-${videoCount}`, {
        filePointer: { pointer: node.youtube, storageType: "youtube" },
      })
    );
    videoCount++;
  }

  if (node.video?.trim()) {
    arrayBlocks.push(
      createBlock<IVideoBlockData>("video", `video-${videoCount}`, {
        filePointer: { pointer: node.video, storageType: "direct" },
      })
    );
  }
  // Check if node content area has text in it
  const contentBlock = convertContentArea(node);
  if (contentBlock) {
    arrayBlocks.push(contentBlock);
  }

  const logic = NodeToLogic(node);

  // const nodeUseScore = node.showscores
  // node.logicscores && node.logicscores.length > 0 ? true : false;

  logic.groups.forEach((group, index) => {
    const convertedFields = convertLogicFieldsToChoicesLogicFields(
      group.fields
    );

    arrayBlocks.push(
      createBlock<IChoicesBoxData>(
        "choices",
        `scorer-${index + 1}`,
        {
          title: group.title ?? "",
          groupId: uuid4(),
          fields: convertedFields,
          type: "scorer",
          multipleAnswers: convertedFields.length === 1 ? true : false,
          isRequired: false,
          showFieldScores: logic.showScores,
          showBlockScore: false,
          layout: "vertical",
          fieldType: "button",
        },
        undefined,
        undefined,
        true,
        true
      )
    );
  });

  if (logic.showScores && logic.groups.length > 0) {
    arrayBlocks.push(
      createBlock<IDisplayVariablesData>(
        "displayVariables",
        `displayVariables-1`,
        {
          text: "Total Score",
          variableBlockId: "node.score",
        }
      )
    );
  }

  node.question?.trim() &&
    arrayBlocks.push(
      createBlock<IRichTextAreaData>("richTextArea", "question", {
        content: node.question ?? "",
        type: "question",
      })
    );

  //const rules = convertNodeRuleToRule();
  logic.rules.forEach((rule, index) => {
    arrayBlocks.push(
      createBlock<ILinkButtonData>(
        "linkButton",
        `linkButton-${index + 1}`,
        {
          nodeId: rule.nodeId,
          pathwayId: rule.pathwayId !== "" ? rule.pathwayId : pathwayId,
          isPathwayToPathwayLink: rule.isPathwayToPathwayLink,
          linkText: rule.linkText,
        },
        convertRuleToJsonLogic(rule),
        undefined,
        true
      )
    );
  });

  // Convert normal links
  if (
    Array.isArray(node.actions) &&
    node.actions.length > 0 &&
    (!Array.isArray(node.widgets) || node.widgets.length === 0)
  ) {
    let ruleCount = logic.rules.length;
    node.actions.forEach((action, index) => {
      const routeItem = actionToRouteItem(action, node.answers[index]);
      if (!routeItem.pathwayId) {
        routeItem.pathwayId = pathwayId;
      }
      arrayBlocks.push(
        createBlock<ILinkButtonData>(
          "linkButton",
          `linkButton-${ruleCount + index + 1}`,
          routeItem,
          undefined,
          undefined,
          true
        )
      );
    });
  }

  // Convert widget list
  if (
    node.actions &&
    node.actions.length > 0 &&
    Array.isArray(node.widgets) &&
    node.widgets.length > 0 &&
    node.widgets[0].name === "startback"
  ) {
    const widgetRules = node.actions.map((action, index) => {
      const routeItem = actionToRouteItem(action, node.answers[index]);
      if (!routeItem.pathwayId) {
        routeItem.pathwayId = pathwayId;
      }
      return routeItem;
    });

    arrayBlocks.push(
      createBlock<ISTarTBackData>(
        "startback",
        `startback-1`,
        { widgetRules: widgetRules, showGraph: node.widgets[0].showGraph },
        undefined,
        undefined,
        true
      )
    );
  }

  if (node.files && node.files.length > 0) {
    let array: IFilePointer[] = [];
    for (const [index, file] of node.files.entries()) {
      if (file !== "") {
        // const filePointer = await convertFileStoreLinkToFilePointer(
        //   file,
        //   "DOCUMENT",
        //   node.fileNames[index]
        // );
        //Create File Document in firestore, get reference and store in filePointer
        array.push({
          pointer: file,
          storageType: "direct",
          pointerTitle: node.fileNames[index],
        });
      }
    }

    if (array.length > 0) {
      arrayBlocks.push(
        createBlock<IFileBlockData>("files", `files-1`, {
          filePointers: [...array],
        })
      );
    }
  }

  const formattedArrayBlocks: Record<string, NodeBlock> = {};

  arrayBlocks.forEach((block, index: number) => {
    block.position.row = index;
    formattedArrayBlocks[block.blockId] = { ...block };
  });

  dynamicNode.blocks = formattedArrayBlocks;

  return dynamicNode;
};

export const createBlock = <TDATA extends NodeBlockData>(
  type: BlockTypes,
  name: string,
  data: TDATA,
  rules?: string,
  addToManagementPlan?: boolean,
  addToDecisionSummary?: boolean,
  addToNodeScore?: boolean
): INodeBlock<TDATA> => {
  const newBlock: INodeBlock<TDATA> = {
    blockId: uuid4(),
    blockData: data,
    blockName: name,
    blockType: type,
    blockRules: {
      rule: rules ?? null,
      alwaysShow: rules ? false : true,
      isAdvancedRules: false,
    },
    position: {
      column: 0,
      row: 0,
    },
    blockOptions: {
      backgroundColor: "rgb(255,255,255)",
      borderColor: "rgb(255,255,255)",
      borderRadius: "5px",
      fontColor: "rgb(0,0,0)",
      addToManagementPlan: addToManagementPlan ?? false,
      addToDecisionSummary: addToDecisionSummary ?? false,
      addToNodeScore: addToNodeScore ?? false,
      readonlyInTemplate: false,
    },
  };

  return newBlock;
};
