import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Dispatch, useEffect } from "react";
import { IShowHideBlock, IDynamicNode } from "../../../../types/Nodes";
import {
  addRule,
  cleanRule,
  isFieldToggled,
  listBlocksConnected,
} from "../NodeEditorUtils";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DynamicNodeEditorActions } from "../dynamicNodeEditorReducer";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";

const NodeShowHideEditor = ({
  block,
  dispatch,
  isEditing,
  state,
  pathwayState,
  pathwayEditorDispatch,
  isPreview,
}: {
  block: IShowHideBlock;
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  isEditing: boolean;
  state: IDynamicNode;
  pathwayState: Partial<PathwayState>;
  pathwayEditorDispatch: Dispatch<PathwayActions>;
  isPreview: boolean;
}) => {
  useEffect(() => {
    if (pathwayState.nodeContext) {
      const existingBlock = Object.values(pathwayState.nodeContext.fields).find(
        (field) => field.blockId === block.blockId
      );

      if (!existingBlock) {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            blockId: block.blockId,
            blockName: block.blockName,
            value: "",
            isToggled: false,
            fieldName: "",
            blockFieldId: block.blockData?.buttonId ?? "",
            hasMultipleValues: false,
            namespace: "",
            name: "",
            order: 0,
          },
        });
      }
    }
  }, [block, pathwayEditorDispatch, pathwayState]);

  useEffect(() => {
    const connectedBlocksId = listBlocksConnected(
      Object.values(state.blocks),
      block.blockId
    );

    if (connectedBlocksId) {
      const connectedBlocksIdNeedsUpdates =
        connectedBlocksId.filter(
          (id) => !block.blockData?.connectedBlocksId?.includes(id)
        ).length > 0
          ? true
          : false;

      if (isEditing && connectedBlocksIdNeedsUpdates) {
        dispatch?.({
          type: "UPDATE_BLOCK",
          payload: {
            blockId: block.blockId,
            blockData: {
              ...block.blockData,
              connectedBlocksId,
            },
          },
        });
      }
    }
  }, [isEditing, block, state, dispatch]);

  const toggleClickedButton = (blockId: string) => {
    if (pathwayState.nodeContext) {
      const existingBlock = Object.values(pathwayState.nodeContext.fields).find(
        (field) => field.blockId === blockId
      );

      if (existingBlock) {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            ...existingBlock,
            isToggled: !existingBlock.isToggled,
          },
        });
      }
    }
  };

  const addBlockToHide = () => {
    let connectedBlocksId = block.blockData?.connectedBlocksId;

    connectedBlocksId = connectedBlocksId ? [...connectedBlocksId, ""] : [""];

    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData: {
          ...block.blockData,
          connectedBlocksId,
        },
      },
    });
  };

  const updateConnectedBlock = (index: number, blockId: string) => {
    if (block.blockData?.connectedBlocksId) {
      let connectedBlocksId = [...block.blockData.connectedBlocksId];

      const blockToHide = Object.values(state.blocks).find(
        (block) => block.blockId === blockId
      );

      if (blockToHide) {
        connectedBlocksId[index] = blockId;

        dispatch?.({
          type: "UPDATE_BLOCK",
          payload: {
            blockId: block.blockId,
            blockData: {
              ...block.blockData,
              connectedBlocksId,
            },
          },
        });

        const newRule = addRule(
          blockToHide,
          [{ var: `${block.blockId}.${block.blockData.buttonId}` }, true],
          "=="
        );

        dispatch?.({
          type: "UPDATE_RULES",
          payload: {
            blockId,
            ruleOptions: {
              ...blockToHide.blockRules,
              rule: newRule,
              alwaysShow: false,
            },
          },
        });
      }
    }
  };

  const deleteBlockToHide = (index: number) => {
    let connectedBlocksId = block.blockData?.connectedBlocksId;

    if (connectedBlocksId) {
      let blockRuleToDelete = Object.values(state.blocks).find(
        (block) => block.blockId === connectedBlocksId?.[index]
      );

      if (blockRuleToDelete && blockRuleToDelete.blockRules.rule) {
        const cleanedRule = cleanRule(
          JSON.parse(blockRuleToDelete.blockRules.rule),
          block.blockId
        );

        const isObjectEmpty =
          Object.keys(cleanedRule).length > 0 ? false : true;

        dispatch?.({
          type: "UPDATE_RULES",
          payload: {
            blockId: blockRuleToDelete.blockId,
            ruleOptions: {
              ...blockRuleToDelete.blockRules,
              rule: isObjectEmpty ? null : JSON.stringify(cleanedRule),
              alwaysShow: isObjectEmpty ? true : false,
            },
          },
        });
      }

      connectedBlocksId = connectedBlocksId.filter(
        (blockId) => blockId !== blockRuleToDelete?.blockId
      );

      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: {
            ...block.blockData,
            connectedBlocksId,
          },
        },
      });
    }
  };

  return (
    <Box>
      <Button
        disabled={isEditing}
        variant="outlined"
        fullWidth
        onClick={() => toggleClickedButton(block.blockId)}
      >
        {!isPreview &&
          "This is a Show / Hide block. Select the Edit button for this block to edit its functionality."}
        {isPreview && (
          <>
            {isFieldToggled(
              "button",
              block.blockData?.buttonId ?? "",
              pathwayState.nodeContext
            )
              ? "Show Less"
              : "Show More"}
          </>
        )}
        {/* {isPreview &&
        isFieldToggled(
          "button",
          block.blockData?.buttonId ?? "",
          pathwayState.nodeContext
        )
          ? "Show Less"
          : "Show More"} */}
      </Button>
      {isEditing && (
        <Box
          sx={{
            background: "rgba(118, 118, 118, 0.08)",
            mt: 1,
            border: "1px solid rgba(118, 118, 118, 0.5)",
            borderRadius: "4px",
            p: 2,
          }}
        >
          {block.blockData?.connectedBlocksId &&
            block.blockData.connectedBlocksId.map((idBlock, index) => (
              <Box
                key={index}
                sx={{
                  my: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>Select block to hide</InputLabel>
                  <Select
                    value={idBlock}
                    label="Select block to hide"
                    onChange={(e) =>
                      updateConnectedBlock(index, e.target.value)
                    }
                  >
                    {Object.values(state.blocks)
                      .filter(
                        (blockElement) =>
                          blockElement.blockType !== "showHide" &&
                          blockElement.blockType !== "placeholder"
                      )
                      .map((blockToHide) => (
                        <MenuItem
                          disabled={block.blockData?.connectedBlocksId?.includes(
                            blockToHide.blockId
                          )}
                          value={blockToHide.blockId}
                          key={blockToHide.blockId}
                        >
                          {blockToHide.blockName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <DeleteOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  color="error"
                  onClick={() => deleteBlockToHide(index)}
                />
              </Box>
            ))}
          <Button
            variant="contained"
            fullWidth
            onClick={() => addBlockToHide()}
          >
            Add block to hide
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default NodeShowHideEditor;
