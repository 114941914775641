import { getDb } from "app/data/DbProvider";
import React, { createContext, useEffect, useState } from "react";
import { INodePublished } from "../../../types/Nodes";
import {
  IAttributeReferences,
  IPathway,
  IPathwayData,
} from "../../../types/Pathway";
import useModules from "features/hooks/useModules";
import useTenants from "features/hooks/useTenants";
import { Redirect } from "react-router-dom";

export type ModuleStateContext = {
  pathways: IPathway[];
  pathwayGroups: IAttributeReferences[];
  setPathwayFilter: (filter: string[]) => void;
  setTenantBrowser: (tenantId: string | undefined) => void;
  filteredPathways: IPathway[];
  publishedPathways: Record<string, INodePublished>;
  tenantBrowser: string | undefined;
};

const db = getDb();

export const ModuleContext = createContext<ModuleStateContext>({
  pathways: [],
  pathwayGroups: [],
  filteredPathways: [],
  setPathwayFilter: () => {},
  publishedPathways: {},
  setTenantBrowser: () => {},
  tenantBrowser: "",
});

const ModuleProvider = ({ children }: { children: React.ReactNode }) => {
  const { activeModule } = useModules();
  const { activeTenant } = useTenants();
  // const history = useHistory();
  const [referencePathways, setReferencePathways] = useState<IPathway[]>([]);
  const [pathwayGroups, setPathwayGroups] = useState<IAttributeReferences[]>(
    []
  );
  const [pathwayFilter, setPathwayFilter] = useState<string[]>([]);
  const [tenantBrowser, setTenantBrowser] = useState<string>();
  const [publishedPathways, setTenantPublished] = useState<
    Record<string, INodePublished>
  >({});

  const filterPathways = (
    referencePathways: IPathway[],
    pathwayFilters: string[]
  ): IPathway[] => {
    if (pathwayFilters.length === 0) {
      return referencePathways;
    }
    return referencePathways
      .filter((pathway) =>
        pathway.attributes.some((x) => pathwayFilters.includes(x.reference))
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  //Load Reference Pathway Attributes Data
  useEffect(() => {
    if (activeModule?.id) {
      const Load = async () => {
        const pathwayDoc = await db.getDoc<IPathwayData>(
          `referenceData`,
          "pathwayData"
        );
        setPathwayGroups(
          pathwayDoc.attributeReferences.filter(
            (x) => x.module === activeModule.id
          )
        );
      };
      Load();
    }
  }, [activeModule?.id]);

  //Load Reference Pathways
  useEffect(() => {
    if (activeModule?.id) {
      const Load = async () => {
        if (activeModule.id === undefined) {
          throw Error("Invalid active module record");
        }
        const pathwayData = await db.getCollection<IPathway>(
          `referenceData/pathwayData/pathways`,
          {
            where: [
              {
                field: "modules",
                op: "array-contains",
                filter: activeModule.id,
              },
            ],
          }
        );
        setReferencePathways(pathwayData);
      };
      Load();
    }
  }, [activeModule?.id]);

  //Get the published Record for the tenant
  useEffect(() => {
    if (activeTenant !== undefined) {
      return db.watchDoc<Record<string, INodePublished>>(
        activeTenant.id || "",
        "Published",
        (entity) => {
          setTenantPublished(entity);
        }
      );
    }
  }, [activeTenant]);

  return (
    <>
      {activeModule === undefined && <Redirect to="/" />}
      {activeModule !== undefined && (
        <ModuleContext.Provider
          value={{
            publishedPathways: publishedPathways,
            pathways: referencePathways,
            pathwayGroups: pathwayGroups,
            setPathwayFilter: (filter) => setPathwayFilter(filter),
            filteredPathways: filterPathways(referencePathways, pathwayFilter),

            setTenantBrowser: (tenantId) =>
              setTenantBrowser(
                tenantId === activeTenant.id ? undefined : tenantId
              ),
            tenantBrowser,
          }}
        >
          {children}
        </ModuleContext.Provider>
      )}
    </>
  );
};

export default ModuleProvider;
