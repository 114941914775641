import DateTime from "app/components/DateTime";
import { Segment, List } from "semantic-ui-react";
import { INode } from "../../../../types/Nodes";

const NodeSystemDatesView = ({ node }: { node: INode }) => {
  return (
    <Segment>
      <List>
        <List.Item data-testid="nodeCreatedAt">
          Node Created: <DateTime as="datetime" value={node.createdAt} />
        </List.Item>
        {node.updatedAt && (
          <List.Item data-testid="nodeUpdatedAt">
            Node Updated: <DateTime as="datetime" value={node.updatedAt} />
          </List.Item>
        )}
      </List>
    </Segment>
  );
};

export default NodeSystemDatesView;
