import { Box, Button } from "@mui/material";
import { Dispatch, useEffect, useState } from "react";
import { IButtonBlock } from "../../../../types/Nodes";
import { DynamicNodeEditorActions } from "../dynamicNodeEditorReducer";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";

interface INodeVideoEditorProps {
  block: IButtonBlock;
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  isEditing: boolean;
  pathwayEditorDispatch: Dispatch<PathwayActions>;
  pathwayState: Partial<PathwayState>;
}

const NodeButtonEditor = ({
  block,
  dispatch,
  isEditing,
  pathwayEditorDispatch,
  pathwayState,
}: INodeVideoEditorProps) => {
  const [newTextLink, setNewTextLink] = useState<string>(
    block.blockData?.buttonText ?? ""
  );

  useEffect(() => {
    if (pathwayState.nodeContext) {
      const existingBlock = Object.values(pathwayState.nodeContext.fields).find(
        (field) => field.blockId === block.blockId
      );
      if (!existingBlock) {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            blockId: block.blockId,
            blockName: block.blockName,
            value: "",
            isToggled: false,
            fieldName: block.blockData?.buttonText ?? "",
            blockFieldId: block.blockData?.buttonId ?? "",
            hasMultipleValues: false,
            namespace: "",
            name: "",
            order: 0,
          },
        });
      }
    }
  }, [block, pathwayEditorDispatch, pathwayState]);

  const toggleClickedButton = (blockId: string) => {
    if (pathwayState.nodeContext) {
      const existingBlock = Object.values(pathwayState.nodeContext.fields).find(
        (field) => field.blockId === blockId
      );

      if (existingBlock) {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            ...existingBlock,
            isToggled: !existingBlock.isToggled,
          },
        });
      }
    }
  };

  const onUpdateButtonText = () => {
    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData: {
          buttonText: newTextLink.trim(),
        },
      },
    });
    if (pathwayState.nodeContext) {
      const existingBlock = Object.values(pathwayState.nodeContext.fields).find(
        (field) => field.blockId === block.blockId
      );

      if (existingBlock) {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: { ...existingBlock, fieldName: newTextLink.trim() },
        });
      }
    }
  };

  return (
    <Box>
      <Button
        id="input"
        size="large"
        variant="outlined"
        fullWidth={true}
        color="primary"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
          cursor: isEditing ? "text" : "pointer",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }}
        onClick={(e) =>
          isEditing ? e.preventDefault() : toggleClickedButton(block.blockId)
        }
      >
        <span
          contentEditable={isEditing}
          suppressContentEditableWarning={true}
          onInput={(e) => setNewTextLink(e.currentTarget.textContent || "")}
          onBlur={() => onUpdateButtonText()}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            outline: "none",
          }}
        >
          {block.blockData?.buttonText}
        </span>
      </Button>
    </Box>
  );
};

export default NodeButtonEditor;
