import { ModuleContext } from "app/providers/ModuleProvider";
import { useContext } from "react";

const useTenantBrowser = () => {
  const { tenantBrowser, setTenantBrowser } = useContext(ModuleContext);

  return {
    tenantBrowser,
    setTenantBrowser,
  };
};

export default useTenantBrowser;
