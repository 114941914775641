import { Segment, Modal, ButtonGroup } from "semantic-ui-react";
import { INode, INodeAction } from "../../../../types/Nodes";
import { Dispatch, useEffect, useState } from "react";
import { DisplayContext, isNodeComparison } from "../NodeView";
import SafeHtml from "app/components/SafeHtml";
import { Button } from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import NodeActionComparison from "./NodeActionComparison";
import { actionToRouteItem } from "features/pathwaybuilder/utils/pathwayHelper";
import useUser from "features/hooks/useUser";
import { PathwayActions } from "features/hooks/navigatorReducer";
import { getNodeTitle } from "features/pathwaybuilder/utils/pathwayHelperV2";

interface INodeAnswerActionProps {
  index?: number;
  parentId?: string;
  //nodeId: string;
  //answer: string;
  action: INodeAction;
  displayContext?: DisplayContext;
  dispatch: Dispatch<PathwayActions>;
  isActionDisabled?: boolean;
  showAnswers?: boolean;
  highlightTreeView?: boolean;
  showAllWidgetAnswers?: boolean;
  tagWidgetAnswersWithActions?: boolean;
  widgetTagText?: string;
  collectionId: string;
}

const NodeAnswerActionView = ({
  index,
  parentId,
  //answer,
  displayContext,
  //nodeId,
  action,
  dispatch,
  isActionDisabled,
  showAnswers,
  highlightTreeView,
  showAllWidgetAnswers,
  tagWidgetAnswersWithActions,
  widgetTagText,
  collectionId,
}: INodeAnswerActionProps) => {
  const { activeTenantId } = useUser();

  const isNodeConnected =
    action.nodeId !== "I will connect later" &&
    (action.nodeId !== "" || action.pathwayId !== "");
  const [isDecisionSummaryModalOpen, setIsDecisionSummaryModalOpen] =
    useState(false);
  const [isEditorPathwaySwitchModalOpen, setIsEditorPathwaySwitchModalOpen] =
    useState(false);
  const [pathwayNodeTitle, setPathwayNodeTitle] = useState("");

  useEffect(() => {
    if (action.isPathwayToPathwayLink) {
      getNodeTitle(activeTenantId, collectionId, action).then(
        (title) => setPathwayNodeTitle(title)
      );
    }
  }, [action, activeTenantId, collectionId]);

  const handleMouseEnter = () => {
    const edgeId = `__${parentId}__${action.nodeId}__${index}`.replace(
      /\s/g,
      ""
    );
    const targetNodeElements = document.getElementsByClassName(
      `${action.nodeId} ${parentId}__parent`
    ) as HTMLCollectionOf<HTMLElement>;
    const labelElements = document.getElementsByClassName(
      edgeId + "__label"
    ) as HTMLCollectionOf<HTMLElement>;
    const edgeElements = document.getElementsByClassName(
      edgeId
    ) as HTMLCollectionOf<HTMLElement>;

    if (!!targetNodeElements.length) {
      for (const targetNodeElement of targetNodeElements) {
        targetNodeElement.style.outline = "black solid 4px";
      }
    }

    if (edgeElements) {
      for (const edgeElement of edgeElements) {
        edgeElement.style.stroke = "black";
        edgeElement.style.strokeWidth = "4";
      }
    }

    if (labelElements) {
      for (const labelElement of labelElements) {
        labelElement.style.display = "-webkit-box";
      }
    }

    const svgContainer = (
      document.getElementsByClassName(
        "react-flow__edges react-flow__container"
      ) as HTMLCollectionOf<HTMLElement>
    )[0];

    if (edgeElements) {
      for (const edgeElement of edgeElements) {
        if (edgeElement) {
          const clone = edgeElement.parentElement?.cloneNode(
            true
          ) as HTMLElement;
          clone.setAttribute("class", "duplicateEdge");

          const childEdgeElement = (
            clone.getElementsByClassName(edgeId) as HTMLCollectionOf<SVGElement>
          )[0];
          childEdgeElement.classList.remove(edgeId);

          const childLabelElement = (
            clone.getElementsByClassName(
              `${edgeId}__label`
            ) as HTMLCollectionOf<HTMLElement>
          )[0];
          childLabelElement.classList.remove(`${edgeId}__label`);

          svgContainer.append(clone);
        }
      }
    }
  };

  const onMouseLeave = () => {
    const edgeId = `__${parentId}__${action.nodeId}__${index}`.replace(
      /\s/g,
      ""
    );
    const targetNodeElements = document.getElementsByClassName(
      `${action.nodeId} ${parentId}__parent`
    ) as HTMLCollectionOf<HTMLElement>;
    const labelElements = document.getElementsByClassName(
      edgeId + "__label"
    ) as HTMLCollectionOf<HTMLElement>;
    const edgeElements = document.getElementsByClassName(
      edgeId
    ) as HTMLCollectionOf<HTMLElement>;

    if (!!targetNodeElements.length) {
      for (const targetNodeElement of targetNodeElements) {
        targetNodeElement.style.outline = "none";
      }
    }

    if (edgeElements) {
      for (const edgeElement of edgeElements) {
        edgeElement.style.stroke = "#808080";
        edgeElement.style.strokeWidth = "4";
      }
    }

    if (labelElements) {
      for (const labelElement of labelElements) {
        if (!showAnswers) {
          labelElement.style.display = "none";
        }
      }
    }

    const clones = document.getElementsByClassName("duplicateEdge");

    while (clones.length > 0) {
      clones[0].parentNode?.removeChild(clones[0]);
    }
  };

  return (
    <>
      {tagWidgetAnswersWithActions && widgetTagText && isNodeConnected && (
        <span
          style={{
            marginBottom: "8px",
            backgroundColor: "rgb(0, 153, 0)",
            color: "white",
            fontSize: "16px",
            padding: "4px 8px",
            fontWeight: "normal",
            borderRadius: "4px",
            textAlign: "center",
            fontFamily: "roboto",
            marginLeft: "0px",
            marginRight: "auto",
          }}
        >
          {widgetTagText}
        </span>
      )}

      <Button
        onMouseEnter={highlightTreeView ? handleMouseEnter : () => {}}
        onMouseLeave={highlightTreeView ? onMouseLeave : () => {}}
        sx={{
          display: "flex",
          justifyContent: "center",

          textTransform: "none",
          fontSize: "1.2rem",
          mb: 1,
        }}
        variant="contained"
        disabled={
          showAllWidgetAnswers
            ? Boolean(isActionDisabled)
            : !isNodeConnected || Boolean(isActionDisabled)
        }
        onClick={
          action.isPathwayToPathwayLink
            ? () => {
                if (showAllWidgetAnswers && !isNodeConnected) {
                  return;
                }

                if (
                  displayContext === undefined ||
                  displayContext === "VIEWER"
                ) {
                  setIsDecisionSummaryModalOpen(true);
                }
                if (displayContext === "EDITOR") {
                  setIsEditorPathwaySwitchModalOpen(true);
                }

                if (highlightTreeView) {
                  onMouseLeave();
                }
              }
            : () => {
                if (showAllWidgetAnswers && !isNodeConnected) {
                  return;
                }

                dispatch({
                  type: "MOVE_TO_NEXT_NODE",
                  payload: {
                    pathwayId: action.pathwayId,
                    nodeId: action.nodeId,
                    answer: action.linkText,
                  },
                });

                if (highlightTreeView) {
                  onMouseLeave();
                }
              }
        }
      >
        <SafeHtml html={action.linkText} />
        {action.isPathwayToPathwayLink && (
          <>
            <ArrowCircleRightOutlinedIcon sx={{ mx: 1 }} />
            <SafeHtml html={action.pathwayId} />
            <ArrowCircleRightOutlinedIcon sx={{ mx: 1 }} />
            <SafeHtml html={pathwayNodeTitle} />
          </>
        )}
      </Button>

      <Modal size="mini" open={isDecisionSummaryModalOpen} closeIcon>
        <Modal.Header>Keep Decision summary</Modal.Header>
        <Modal.Content>
          You are about to switch into a different Pathway, please confirm if
          you want to keep the decision summary or erase it.
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="error"
            onClick={() => {
              setIsDecisionSummaryModalOpen(false);
              dispatch({
                type: "MOVE_TO_NEXT_NODE",
                payload: {
                  pathwayId: action.pathwayId,
                  nodeId: action.nodeId,
                  clearDecisions: true,
                  answer: action.linkText,
                },
              });
            }}
          >
            Discard
          </Button>
          <Button
            color="success"
            onClick={() => {
              setIsDecisionSummaryModalOpen(false);
              dispatch({
                type: "MOVE_TO_NEXT_NODE",
                payload: {
                  pathwayId: action.pathwayId,
                  nodeId: action.nodeId,
                  answer: action.linkText,
                },
              });
            }}
          >
            Keep
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={isEditorPathwaySwitchModalOpen} closeIcon>
        <Modal.Header>Switch to new pathway</Modal.Header>
        <Modal.Content>
          You are about to switch into a different Pathway, please confirm if
          you want to continue.
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="error"
            onClick={() => {
              setIsEditorPathwaySwitchModalOpen(false);
            }}
          >
            No
          </Button>
          <Button
            color="success"
            onClick={() => {
              setIsEditorPathwaySwitchModalOpen(false);
              dispatch({
                type: "MOVE_TO_NEXT_NODE",
                payload: {
                  pathwayId: action.pathwayId,
                  nodeId: action.nodeId,
                  answer: action.linkText,
                },
              });
            }}
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

interface INodeAnswerViewProps {
  node: INode;
  dispatch: Dispatch<PathwayActions>;
  displayContext?: DisplayContext;
  disableActions?: boolean;
  showAnswers?: boolean;
  highlightTreeView?: boolean;
  showAllWidgetAnswers?: boolean;
  tagWidgetAnswersWithActions?: boolean;
  widgetTagText?: string;
  collectionId: string;
}

const NodeAnswerView = ({
  node,
  dispatch,
  disableActions,
  showAnswers,
  displayContext,
  highlightTreeView,
  showAllWidgetAnswers,
  tagWidgetAnswersWithActions,
  widgetTagText,
  collectionId,
}: INodeAnswerViewProps) => {
  if (node.actions.length === 0) {
    return <></>;
  }

  if (
    Array.isArray(node.actions) &&
    node.actions.every(
      (x) =>
        x === "" &&
        Array.isArray(node.answers) &&
        node.answers.every((x) => x === "")
    )
  ) {
    return <></>;
  }
  return (
    <Segment data-testid="links">
      {isNodeComparison(node) && (
        <>
          {node.actions.map((action, index) => (
            <NodeActionComparison
              key={node.answers[index]}
              linkContent={node.answers[index]}
              destinationContent={node.linkDestinations[index]}
            />
          ))}
        </>
      )}
      {!isNodeComparison(node) && (
        <ButtonGroup vertical fluid data-testid="node-answer-buttons">
          {node.answers &&
            node.actions &&
            node.answers.map((answer, index) => (
              <NodeAnswerActionView
                index={index}
                parentId={node.id}
                key={index}
                action={actionToRouteItem(node.actions[index], answer)}
                //answer={answer}
                dispatch={dispatch}
                displayContext={displayContext}
                //nodeId={node.actions[index]}
                isActionDisabled={disableActions}
                showAnswers={showAnswers}
                highlightTreeView={highlightTreeView}
                showAllWidgetAnswers={showAllWidgetAnswers}
                tagWidgetAnswersWithActions={tagWidgetAnswersWithActions}
                widgetTagText={widgetTagText}
                collectionId={collectionId}
              />
            ))}
        </ButtonGroup>
      )}
    </Segment>
  );
};

export default NodeAnswerView;
