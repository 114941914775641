import { IPathwayEpisode, IPathwayEpisodeStep } from "../../../types/Analytics";
import { IDynamicNode, INode } from "../../../types/Nodes";
import firebase from "firebase/compat/app";
export type PathwayActions =
  | {
      type: "MOVE_TO_NEXT_NODE";
      payload: {
        nodeId: string;
        pathwayId: string;
        clearDecisions?: boolean;
        answer: string;
      };
    }
  | {
      type: "MOVE_TO_NEW_PATHWAY";
      payload: {
        nodeId: string;
        pathwayId: string;
      };
    }
  | { type: "MOVE_TO_PREVIOUS_NODE"; payload: {} }
  | {
      type: "NODE_LOGIC_ON";
      payload: {
        namespace: string;
        name: string;
        order: number;
        groupName?: string;
        value: number | string;
      };
    }
  | {
      type: "NODE_LOGIC_OFF";
      payload: {
        namespace: string;
        name: string;
        order: number;
        groupName?: string;
        value: number | string;
      };
    }
  | { type: "NODE_BLOCK_LOGIC_ON"; payload: INodeContextItem }
  | { type: "NODE_BLOCK_LOGIC_OFF"; payload: INodeContextItem }
  | { type: "RESET_NODE_LOGIC"; payload: {} }
  | { type: "ADD_EPISODE_STEP"; payload: { node: INode | IDynamicNode } }
  | { type: "REPLACE_EPISODE"; payload: { episode: IPathwayEpisode } }
  | { type: "ADD_EPISODE_OUTCOME"; payload: { outcome: string } };

export type PathwayState = {
  route: NodePointer[];
  activeNode: NodePointer | undefined;
  decisionSummary: NodeDecisionAction[];
  currentPathwayId: string;
  tenantId: string;
  nodeContext: INodeContext;
  pathwayEpisode?: IPathwayEpisode;
  actionDirection?: PathwayMoveDirection;
  episodeStatus?: EpisodeState;
};

export const convertOldNodeContextToNew = (props: {
  namespace: string;
  name: string;
  order: number;
  groupName?: string;
  value: number | string;
}): INodeContextItem => {
  return {
    blockId: "",
    blockName: "",
    isToggled: false,
    hasMultipleValues: false,
    ...props,
  };
};

export interface INodeContextItem {
  blockId: string;
  blockFieldId?: string;
  blockName: string;
  fieldName?: string;
  valueName?: string;
  groupName?: string;
  value: number | string;
  valueId?: string;
  addToManagementPlan?: boolean;
  addToDecisionSummary?: boolean;
  addToNodeScore?: boolean;
  isToggled: boolean;
  hasMultipleValues: boolean;
  /* namespace, name and order to be removed after V1 editor decommissioned */
  namespace: string;
  name: string;
  order: number;
}

export interface INodeContext {
  fields: INodeContextItem[];
}

// export const isNodeBlockContextItem = (
//   context: INodeContextItem | INodeContextItem
// ): context is INodeContextItem => {
//   return (context as INodeContextItem).blockId !== undefined;
// };

// export const isNodeBlockContextItemArray = (
//   context: (INodeContextItem | INodeContextItem)[] | undefined
// ): context is INodeContextItem[] => {
//   if (context === undefined) {
//     return true;
//   }
//   if ((context as INodeContextItem[]).length === 0) {
//     return true;
//   }
//   return (context as INodeContextItem[])[0].blockId !== undefined;
// };

// export const isNodeContextItem = (
//   context: INodeContextItem | INodeContextItem
// ): context is INodeContextItem => {
//   return (context as INodeContextItem).blockId === undefined;
// };

// export const isNodeContextItemArray = (
//   context: (INodeContextItem | INodeContextItem)[]
// ): context is INodeContextItem[] => {
//   if ((context as INodeContextItem[]).length === 0) {
//     return true;
//   }
//   return (context as INodeContextItem[])[0].blockId === undefined;
// };

export type NodePointer = {
  pathwayId: string;
  nodeId: string;
};

export type NodeDecisionAction = {
  header: string;
  items: INodeContextItem[] | INodeContextItem[];
};

export type PathwayMoveDirection = "FORWARD" | "BACKWARD";
export type EpisodeState = "SAVED" | "UNSAVED";

const LEFT_EARLY_TEXT = "Left Pathway Early";
const getPathwayIdFromAction = (action: string) => {
  return action.slice(2);
};

export const pathwayReducer = (draft: PathwayState, action: PathwayActions) => {
  switch (action.type) {
    case "MOVE_TO_NEW_PATHWAY":
      draft.activeNode = {
        nodeId: action.payload.nodeId,
        pathwayId: action.payload.pathwayId,
      };
      break;
    case "MOVE_TO_PREVIOUS_NODE":
      let lastNodePointer = draft.route.pop();

      const lastDecisionSummary = draft.decisionSummary;
      const item = lastDecisionSummary.pop();

      const previousNodeContext: INodeContext = {
        fields: [],
      };

      if (item && Boolean(item.items.length)) {
        previousNodeContext.fields = item.items;
      }

      if (!lastNodePointer) {
        break;
      }
      draft.actionDirection = "BACKWARD";
      draft.route = [...draft.route];
      draft.activeNode = lastNodePointer;
      draft.decisionSummary = [...lastDecisionSummary];
      draft.nodeContext = previousNodeContext;
      draft.currentPathwayId = lastNodePointer.pathwayId;
      break;
    case "MOVE_TO_NEXT_NODE":
      let pathwayId = action.payload.pathwayId || draft.currentPathwayId;
      const newPosition = {
        nodeId: action.payload.nodeId,
        pathwayId: pathwayId,
      };

      const isPathwayToPathWayLink =
        newPosition.nodeId.substring(0, 2) === "@@";

      //If this is a pathway link then we need to change pathway
      if (isPathwayToPathWayLink) {
        pathwayId = getPathwayIdFromAction(newPosition.nodeId);
        //newPosition = previousState.activeNode.
      }

      const currentRoute = draft.route;
      const summary = draft.decisionSummary;
      if (draft.activeNode) {
        currentRoute.push(draft.activeNode);

        if (draft.nodeContext.fields.length) {
          const toggledFieldInNodeContext = draft.nodeContext.fields.reduce<
            INodeContextItem[]
          >((newArray, field) => {
            return [...newArray, field];
          }, []);

          summary.push({
            header: action.payload.answer,
            items: [...toggledFieldInNodeContext],
          });
        } else {
          summary.push({ header: action.payload.answer, items: [] });
        }
        //If we are clearing the decision summary then we also need to clear the route
        if (action.payload.clearDecisions) {
          summary.length = 0;
          currentRoute.length = 0;
        }
      }

      draft.route = [...currentRoute];
      draft.activeNode = { ...newPosition };
      draft.decisionSummary = [...summary];
      draft.currentPathwayId = pathwayId;
      draft.nodeContext = { fields: [] };
      draft.actionDirection = "FORWARD";
      break;
    case "NODE_LOGIC_ON":
      //Remove the current answer for the namespace
      draft.nodeContext.fields = draft.nodeContext.fields.filter(
        (x) => x.namespace !== action.payload.namespace
      );
      //Add the new answer
      draft.nodeContext.fields.push({
        ...convertOldNodeContextToNew(action.payload),
      });

      break;
    case "NODE_LOGIC_OFF":
      // Remove the current answer
      draft.nodeContext.fields = draft.nodeContext.fields.filter(
        (x) => x.namespace !== action.payload.namespace
      );

      break;
    case "NODE_BLOCK_LOGIC_ON":
      //Remove the current answer for the namespace
      draft.nodeContext.fields = draft.nodeContext.fields.filter(
        (x) =>
          x.valueId !== action.payload.valueId ||
          x.blockFieldId !== action.payload.blockFieldId
      );

      if (!action.payload.blockFieldId) {
        draft.nodeContext.fields = draft.nodeContext.fields.filter(
          (x) => x.blockId !== action.payload.blockId
        );
      }

      //Add the new answer
      draft.nodeContext.fields.push({ ...action.payload });

      break;
    case "NODE_BLOCK_LOGIC_OFF":
      // Remove the current answer
      draft.nodeContext.fields = draft.nodeContext.fields.filter(
        (x) => x.blockFieldId !== action.payload.blockFieldId
      );

      break;
    case "RESET_NODE_LOGIC":
      draft.nodeContext = {
        fields: [],
      };
      break;
    case "ADD_EPISODE_STEP":
      if (draft.pathwayEpisode === undefined) {
        draft.pathwayEpisode = {
          pathwayId: draft.currentPathwayId,
          collectionId: "",
          tenantId: draft.tenantId,
          entryTime: firebase.firestore.FieldValue.serverTimestamp(),
          route: {},
          //Assume the default will be lest pathway early
          outcomes: [LEFT_EARLY_TEXT],
        };
      }
      const step: IPathwayEpisodeStep = {
        entryTime: firebase.firestore.FieldValue.serverTimestamp(),
        node: action.payload.node,
        pathwayId: draft.currentPathwayId,
        collection: "",
        interaction:
          draft.actionDirection === "FORWARD"
            ? {
                header: draft.decisionSummary.at(-1)?.header ?? "",
                items: draft.decisionSummary.at(-1)?.items ?? [],
              }
            : { header: "GO BACK", items: [] },
      };

      //If we have a previous step then update the exist time
      if (Object.values(draft.pathwayEpisode.route).length > 0) {
        draft.pathwayEpisode.route[
          Object.values(draft.pathwayEpisode.route).length - 1
        ].exitTime = firebase.firestore.FieldValue.serverTimestamp();
      }

      draft.pathwayEpisode.route[
        Object.values(draft.pathwayEpisode.route).length
      ] = step;

      draft.episodeStatus = "UNSAVED";

      break;

    case "REPLACE_EPISODE":
      draft.pathwayEpisode = action.payload.episode;
      draft.episodeStatus = "SAVED";
      break;

    case "ADD_EPISODE_OUTCOME":
      //Clear the default state of our pathway
      if (draft.pathwayEpisode?.outcomes[0] === LEFT_EARLY_TEXT) {
        draft.pathwayEpisode.outcomes = [];
      }
      draft.pathwayEpisode?.outcomes.push(action.payload.outcome);
      draft.episodeStatus = "UNSAVED";
      break;
    default:
      throw Error("Unknown route action");
  }
};
