import { Box, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import ModuleProvider from "app/providers/ModuleProvider";
import PatientProvider from "features/PatientContext/PatientContext";
import React from "react";
import Content from "./Content";
import Footer from "./Footer";
import TopBarMobile from "./mobile/TopBarMobile";
import SideBar from "./SideBar";
import TopBar from "./TopBar";

const AppGrid = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const MobileGrid = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
}));

const AppContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <PatientProvider>
      <ModuleProvider>
        {!theme.isMobileView && (
          <AppGrid>
            <TopBar />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <SideBar />
              <Content>{children}</Content>
            </Box>
            <Footer
              sx={{
                position: "absolute",
              }}
            />
          </AppGrid>
        )}
        {theme.isMobileView && (
          <MobileGrid>
            <TopBarMobile />
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                }}
              >
                <Content>{children}</Content>
              </Box>
              <Footer />
            </Box>
          </MobileGrid>
        )}
      </ModuleProvider>
    </PatientProvider>
  );
};
export default AppContainer;
