import { useState } from "react";

import {
  Utils as QbUtils,
  Fields,
  JsonLogicTree,
  JsonGroup,
  Config,
  ImmutableTree,
} from "react-awesome-query-builder";
import MuiConfig from "react-awesome-query-builder/lib/config/mui";

import { getBlocksThatManipulateRules } from "features/NodeEditorV2/NodeEditorUtils";
import {
  NodeBlock,
  IDynamicNode,
  IChoicesBoxData,
  IButtonData,
  IShowHideData,
} from "../../../types/Nodes";
import SafeHtml from "app/components/SafeHtml";
import {  Box, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMoreLess from "@mui/icons-material/ExpandLess";

const InitialConfig = MuiConfig;

let config: Config = {
  ...InitialConfig,
  settings: {
    ...InitialConfig.settings,
    showNot: false,
  },
  operators: {
    ...InitialConfig.operators,
    like: {
      label: "Contains",
      labelForFormat: "Contains",
      elasticSearchQueryType: "regexp",
      valueSources: ["value"],
      jsonLogic: "contains",
    },
  },
  fields: {
    node: {
      label: "node",
      tooltip: "Group of fields",
      type: "!struct",
      subfields: {
        score: {
          type: "number",
          preferWidgets: ["number"],
          mainWidgetProps: {
            valueLabel: "node.score",
            valuePlaceholder: "0",
          },
          fieldSettings: {
            min: 0,
          },
        },
      },
    },
  },
};

const HumanReadableBlockRule = ({
  block,
  state,
}: {
  block: NodeBlock | undefined;
  state: IDynamicNode;
}) => {
  const queryValue: JsonGroup = {
    id: QbUtils.uuid(),
    type: "group",
  };
  const [ruleDisplayStyle, setRuleDisplayStyle] =
    useState<FormattingType>("COLLAPSED");
  const createCustomSelectorFields = (): Fields => {
    let customFieldsArray: any[] = [];

    const blocksChoices = getBlocksThatManipulateRules(
      block?.blockId,
      Object.values(state.blocks)
    );

    blocksChoices.forEach((block) => {
      let subfieldsObject = {};

      if (block.blockType === "choices") {
        subfieldsObject = {
          score: {
            type: "number",
            preferWidgets: ["number"],
            fieldSettings: {
              min: 0,
            },
          },
          contains: {
            type: "text",
            label: "Any toggled fields",
            operators: ["like"],
          },
        };

        const fieldsObject = (block.blockData as IChoicesBoxData).fields.reduce(
          (newObject: any, field) => {
            let newField = {};
            let subfieldValues = {};

            field.values.forEach((value) => {
              subfieldValues = {
                ...subfieldValues,
                [value.valueId]: {
                  type: "boolean",
                  operators: ["equal"],
                  label:
                    field.values.length > 1
                      ? `${field.title} - ${value.text}`
                      : field.title,
                  valueSources: ["value"],
                },
              };
            });

            newField = {
              ...newField,
              [field.fieldId]: {
                type: "!struct",
                label: block.blockName,
                subfields: subfieldValues,
              },
            };

            return { ...newObject, ...newField };
          },
          {}
        );

        subfieldsObject = { ...subfieldsObject, ...fieldsObject };
      }
      if (block.blockType === "button") {
        subfieldsObject = {
          [(block.blockData as IButtonData).buttonId]: {
            type: "boolean",
            label: (block.blockData as IButtonData).buttonText,
            mainWidgetProps: {
              valueLabel: `${block.blockId}.${
                (block.blockData as IButtonData).buttonId
              }`,
            },
            excludeOperators: ["proximity"],
          },
        };
      }
      if (block.blockType === "showHide") {
        subfieldsObject = {
          [(block.blockData as IShowHideData).buttonId]: {
            type: "boolean",
            label: "show-hide",
            mainWidgetProps: {
              valueLabel: `${block.blockId}.${
                (block.blockData as IShowHideData).buttonId
              }`,
            },
            excludeOperators: ["proximity"],
          },
        };
      }

      customFieldsArray.push({
        [block.blockId]: {
          label: block.blockName,
          type: "!struct",
          subfields: subfieldsObject,
          valueSources: ["value"],
        },
      });
    });

    const customTypeObject: Fields = customFieldsArray.reduce(
      (object: any, field) => {
        return { ...object, ...field };
      },
      {}
    );

    return customTypeObject;
  };

  const updatedConfigWithFields = {
    ...config,
    fields: { ...config.fields, ...createCustomSelectorFields() },
  };

  const [localState] = useState({
    tree: QbUtils.checkTree(
      QbUtils.loadTree(queryValue),
      updatedConfigWithFields
    ),
    config: updatedConfigWithFields,
  });

  let newState = localState;

  if (block?.blockRules.rule) {
    let initialTree: ImmutableTree | undefined = undefined;

    const initialLogic: JsonLogicTree =
      block?.blockRules.rule && Object.keys(block?.blockRules.rule).length > 0
        ? JSON.parse(block.blockRules.rule)
        : undefined;

    if (initialLogic) {
      initialTree = QbUtils.checkTree(
        QbUtils.loadFromJsonLogic(
          initialLogic,
          updatedConfigWithFields
        ) as ImmutableTree,
        updatedConfigWithFields
      );

      newState = {
        tree: initialTree,
        config: updatedConfigWithFields,
      };
    }
  }
  const ruleAsHumanReadable = QbUtils.queryString(
    newState.tree,
    newState.config,
    true
  );

  return (
    
     
      <Box sx={{display:"flex",bgcolor:"rgb(3,169,244,0.2)",borderRadius:1,alignItems:"center",p:1,color:"#000"}} >
        Show when&nbsp;
        <SafeHtml
          html={ruleAsFormattedHtml(
            ruleAsHumanReadable ?? "",
            ruleDisplayStyle
          )}
          allowHtml={true}
        />
         <Box sx={{ flex:1 }}></Box>

        <Button
       
          size="medium"
          onClick={() =>
            setRuleDisplayStyle((prev) =>
              prev === "COLLAPSED" ? "EXPANDED" : "COLLAPSED"
            )
          }
          endIcon={
            ruleDisplayStyle === "COLLAPSED" ? (
              <ExpandMoreIcon />
            ) : (
              <ExpandMoreLess />
            )
          }
        >
          {ruleDisplayStyle === "COLLAPSED" ? "Expand" : "Collapse"}
        </Button>
      </Box>

      
    
  );
};

const peek = (value: string, index: number, peek: number) => {
  if (value.length < index + peek) {
    return "";
  }
  return `${value}`.slice(index, index + peek);
};

/**
 * Checks if the then string is an operator
 * @remarks Returns true if the previous char is a space, then the operator, then a space
 * @param rule The string to check
 * @param index The index of the start of the operator
 * @param operator The operator to check
 * @returns boolean:
 */
const isStartOperator = (rule: string, index: number, operator: string) => {
  if (
    peek(rule, index - 1, 1) === " " &&
    peek(rule, index, operator.length) === operator &&
    peek(rule, index + operator.length, 1) === " "
  ) {
    return true;
  }
  return false;
};

type FormattingType = "EXPANDED" | "COLLAPSED";

const isEndOperator = (rule: string, index: number, operator: string) => {
  const startOperatorIndex = index - (operator.length - 1);
  if (isStartOperator(rule, startOperatorIndex, operator)) {
    return true;
  }
  return false;
};

const startColourise = `<span style="color:teal">`;
const endColourise = `</span>`;
const START_INDENT = `<div style="padding-left:15px">`;
const END_INDENT = `</div>`;
const NEW_LINE = `<br />`;

const ruleAsFormattedHtml = (ruleString: string, style: FormattingType) => {
  const startIndent = style === "EXPANDED" ? START_INDENT : "";
  const newLine = style === "EXPANDED" ? NEW_LINE : "";
  const endIndent = style === "EXPANDED" ? END_INDENT : "";
  const formattedString = [];
  for (let i = 0; i < ruleString.length; i++) {
    switch (ruleString[i]) {
      case "(":
        if (i !== 0) {
          formattedString.push(startIndent);

          formattedString.push(ruleString[i]);

          //formattedString.push(newLine);

          formattedString.push(startIndent);
        }
        break;
      case ")":
        if (i === ruleString.length - 1) {
          formattedString.push(newLine);
          formattedString.push(endIndent);

          formattedString.push(ruleString[i]);

          formattedString.push(endIndent);
        }
        break;
      case "O":
        if (isStartOperator(ruleString, i, "OR")) {
          formattedString.push(newLine);
          formattedString.push(startColourise);
          formattedString.push(ruleString[i]);
        } else {
          formattedString.push(ruleString[i]);
        }
        break;
      case "R":
        if (isEndOperator(ruleString, i, "OR")) {
          formattedString.push(ruleString[i]);
          formattedString.push(endColourise);
        } else {
          formattedString.push(ruleString[i]);
        }
        break;

      case "A":
        if (isStartOperator(ruleString, i, "AND")) {
          formattedString.push(newLine);
          formattedString.push(startColourise);
          formattedString.push(ruleString[i]);
        } else {
          formattedString.push(ruleString[i]);
        }
        break;
      case "D":
        if (isEndOperator(ruleString, i, "AND")) {
          formattedString.push(ruleString[i]);
          formattedString.push(endColourise);
        } else {
          formattedString.push(ruleString[i]);
        }
        break;
      case "B":
        if (isStartOperator(ruleString, i, "BETWEEN")) {
          formattedString.push(startColourise);
          formattedString.push(ruleString[i]);
        } else {
          formattedString.push(ruleString[i]);
        }
        break;
      case "N":
        if (isEndOperator(ruleString, i, "BETWEEN")) {
          formattedString.push(ruleString[i]);
          formattedString.push(endColourise);
        } else {
          formattedString.push(ruleString[i]);
        }

        break;
      default:
        formattedString.push(ruleString[i]);
    }
  }
  return formattedString.join("");
};

export default HumanReadableBlockRule;
