import { useState } from "react";
import { Modal, Form, Message } from "semantic-ui-react";
import { toastr } from "react-redux-toastr";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { v4 as uuidv4 } from "uuid";
import { IFolder } from "../../../types/DocumentLibrary";
import { getDirectChildren } from "./utils/DocumentLibraryHelper";
import { FilterCriteria } from "./docLibTypes/DocumentLibraryTypes";
import useErrorMessages from "./utils/useErrorMessage";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "app/components/LoadingButton";
import useUser from "features/hooks/useUser";

interface UploadFolderProps {
  folderPath: string;
  folders: IFolder[];
  setFilterCriteria: (value: FilterCriteria) => void;
}

const UploadFolder = ({
  folderPath,

  folders,
  setFilterCriteria,
}: UploadFolderProps) => {
  const { activeTenantId, profile } = useUser();

  const db = firebase.firestore();

  if (activeTenantId === undefined) {
    throw Error("FolderActions: tenantId is undefined");
  }

  const [folderTitle, setfolderTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [formError, setFormError] = useState("");

  const { errorMessages } = useErrorMessages();

  const onSave = async () => {
    setFormError("");

    const nameCollision = getDirectChildren(folders, folderPath).filter(
      (folder: IFolder) => {
        return folder.folderTitle === folderTitle;
      }
    );

    if (nameCollision.length > 0) {
      setFormError(errorMessages.matchingTitle);
      return;
    }

    if (folderTitle.trim() === "") {
      setFormError(errorMessages.noFolderTitle);
      return;
    }

    for (const char of folderTitle) {
      if (char === "/" || char === "\\") {
        setFormError(errorMessages.incorrectCharactersTitle);
        return;
      }
    }

    let newFolderPath;

    if (folderPath === "/") {
      newFolderPath = `${folderPath}${uuidv4()}`;
    }
    if (folderPath.length > 1) {
      newFolderPath = `${folderPath}/${uuidv4()}`;
    }

    const folder = {
      [uuidv4()]: {
        createdBy: profile.email,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: profile.email,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        status: "ACTIVE",
        deletedBy: "",
        deletedAt: "timestamp",
        documentType: "FOLDER",
        folderTitle: folderTitle.trim(),
        folderPath: newFolderPath,
        source: "firebase",
      },
    };

    setLoading(true);

    await db
      .collection(activeTenantId)
      .doc("Files")
      .set({ folders: folder }, { merge: true });

    toastr.success("Folder Saved", "Your folder has been created");
    setFilterCriteria("ALL-FILES");
    setLoading(false);
    setfolderTitle("");
    setOpen(false);
  };

  const onCancel = () => {
    setFormError("");
    setfolderTitle("");
    setOpen(false);
  };

  return (
    <Modal
      centered={true}
      closeIcon
      onClose={() => onCancel()}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button variant="outlined" startIcon={<AddIcon />} sx={{ m: 2 }}>
          New Folder
        </Button>
      }
    >
      <Modal.Header>Create a Folder</Modal.Header>
      <Modal.Content>
        <Form error>
          {formError && <Message error content={formError} />}
          <Form.Input
            label="Give your folder a title"
            fluid
            placeholder={folderTitle}
            value={folderTitle}
            onChange={(e) => setfolderTitle(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton
          icon={<CheckIcon />}
          isLoading={loading}
          buttonText="Upload"
          variant="contained"
          onClick={onSave}
          color="success"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default UploadFolder;
