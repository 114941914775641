import { Link } from "react-router-dom";

const NotAuthorised = () => {
  return (
    <div>
      <p>Not Authorised</p>
      <Link to="/">Back to home</Link>
    </div>
  );
};
export default NotAuthorised;
