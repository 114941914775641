import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Widget } from "../../../types/Nodes";

export const getWidgets = async () => {
  const db = firebase.firestore();
  const ref = db.collection("widgets");
  const widgetList: Widget[] = [];
  ref.onSnapshot((widgetSnaps) => {
    widgetSnaps.forEach((widgetSnap) => {
      const widget = widgetSnap.data() as Widget;
      widgetList.push(widget);
    });
  });
  return widgetList;
};

export const tenantWidgetsLicences = async (tenant: string) => {
  const db = firebase.firestore();

  const ref = db.collection(tenant).doc("config");

  let newObjectLicences: any[] = [];
  try {
    const licences = await ref.get();
    let widgetLicences = licences?.data()?.widgetLicences;
    let keys: string[] = Object.keys(widgetLicences);
    let values: any = Object.values(widgetLicences);

    keys.forEach((key, index) => {
      let obj = {
        widgetName: keys[index],
        licenceGranted: values[index].licenceGranted,
      };
      newObjectLicences.push(obj);
    });
  } catch (error) {
    console.log("Error getting licences tenant:", error);
  }
  return newObjectLicences;
};

export const tenantHasLicence = async (widgetName: any, tenant: string) => {
  let getLicences = await tenantWidgetsLicences(tenant);
  return getLicences.some((licence) => licence.widgetName === widgetName);
};
