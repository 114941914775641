import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Form,
  Modal,
  Message,
  Icon,
  Header,
  Popup,
} from "semantic-ui-react";

import { v4 as uuidv4 } from "uuid";

import { IDocument } from "../../../types/DocumentLibrary";

import useErrorMessages from "./utils/useErrorMessage";
import DropZone from "./DropZone";
import useUploadFile from "./utils/useUploadFile";
import { FilterCriteria } from "./docLibTypes/DocumentLibraryTypes";
// import functions from "app/common/util/functions";
import { IFindResult } from "../../../functions/src/http/findandreplace/find";
import FindAndReplaceWarning from "./FindAndReplaceWarning";
import useUser from "features/hooks/useUser";

interface IFileActionsProps {
  documentToUpdate: IDocument;
  documents: IDocument[];
  onSelect?: (value: IDocument) => void;
  handleUpdateNodePreview?: (
    oldFileLink: string,
    newFileLink: unknown,
    fileTitle: string
  ) => void;
  folderPath: string;
  filterBy?: FilterCriteria;
}

const EditFileAction = ({
  documentToUpdate,
  documents,
  onSelect,
  handleUpdateNodePreview,
  folderPath,
  filterBy,
}: IFileActionsProps) => {
  const { activeTenantId, profile } = useUser();
  if (activeTenantId === undefined) {
    throw Error("FolderActions: tenantId is undefined");
  }
  const db = firebase.firestore();

  const [error, setError] = useState<string>("");
  const [fileTitleEdit, setFileTitleEdit] = useState<string>("");

  const {
    changeFileName,
    fileName,
    fileTitle,
    formError,
    loading,
    openEdit,
    setOpenEdit,
    onCancelUpload,
    setFileName,
    setFileTitle,
    setFileSize,
    setLoading,
    onSaveUpload,
    setUploadFile,
  } = useUploadFile(
    profile,
    activeTenantId,
    documents,
    folderPath,
    filterBy,
    documentToUpdate,
    onSelect,
    handleUpdateNodePreview
  );

  const { errorMessages } = useErrorMessages();

  const onClickEdit = (documentToUpdate: IDocument) => {
    setError("");
    setFileTitleEdit(documentToUpdate.fileTitle);
  };

  const updateDocTitle = async () => {
    const replacedDocument = {
      [uuidv4()]: {
        file: documentToUpdate.file,
        updatedBy: profile.email,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        fileTitle: documentToUpdate.fileTitle,
        fileName: documentToUpdate.fileName,
      },
    };

    // Update firestore files collection
    const fireStoreDoc = db
      .collection(`${activeTenantId}/Files/files`)
      .doc(documentToUpdate.id);

    await fireStoreDoc.update({
      fileTitle: fileTitleEdit.trim(),
      updatedBy: profile.email,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    await fireStoreDoc.set(
      { versionHistory: replacedDocument },
      { merge: true }
    );
  };

  const runReplaceTitle = async (
    results: IFindResult[],
    oldFileLink: string
  ) => {
    let newFileLink = documentToUpdate.file;
    let newFileName = fileTitleEdit.trim();

    results.forEach((result) => {
      let nodeFiles: string[] = result.nodeContent.files;

      // use index of node files to update filename
      for (const [index, file] of nodeFiles.entries()) {
        if (file === oldFileLink) {
          result.nodeContent.files[index] = newFileLink;
          result.nodeContent.fileNames[index] = newFileName;
        }
      }

      db.collection(result.nodePath).doc(result.nodeId).update({
        files: result.nodeContent.files,
        fileNames: result.nodeContent.fileNames,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: profile.email,
      });
    });

    updateDocTitle();

    if (onSelect && handleUpdateNodePreview) {
      handleUpdateNodePreview(oldFileLink, newFileLink, newFileName);
    }

    toastr.success(
      "File Saved",
      "Your file has been updated in the Document Library and replaced in the Pathway Nodes."
    );
  };

  const onSaveEdit = async (documentToUpdate: IDocument) => {
    const fileTitle = fileTitleEdit.trim();

    if (fileTitle === "") {
      setError(errorMessages.noFileTitle);
      return;
    }

    if (documentToUpdate.fileTitle === fileTitle) {
      handleCancel();
      return;
    }

    for (const document of documents) {
      if (
        document.fileTitle === fileTitle &&
        document.folderPath === folderPath
      ) {
        setError(errorMessages.matchTitleAtDestination);
        return;
      }
    }

    setLoading(true);

    //let oldFileLink = documentToUpdate.file;

    const results:IFindResult[] = [];
    // const results = await functions.nodeSearch({
    //   searchTerms: [
    //     {
    //       term: oldFileLink,
    //       type: "casesensitive",
    //     },
    //   ],
    //   pathways: [],
    //   columns: ["files"],
    //   tenantId: activeTenantId,
    // });

    //if there are no results and user is not in the node editor where there could be unsaved changes
    if (results.length === 0 && !onSelect) {
      updateDocTitle();

      toastr.success(
        "Changes Saved",
        `The changes to ${fileTitle} have been saved.`
      );
    }

    // if user is in the node editor where there could be unsaved changes or not in the editor and there file is being used in node/s
    if (onSelect || (!onSelect && results.length >= 1)) {
      runReplaceTitle(results, documentToUpdate.file);
    }

    setLoading(false);
    setOpenEdit(false);
  };

  const handleCancel = () => {
    if (fileName) {
      onCancelUpload();
    }

    if (!fileName) {
      setFileTitleEdit("");
      setOpenEdit(false);
    }
  };

  const handleSave = async (documentToUpdate: IDocument) => {
    if (fileName) {
      await onSaveUpload();
    }
    if (!fileName) {
      await onSaveEdit(documentToUpdate);
    }
  };

  return (
    <>
      <Modal
        closeIcon
        onClose={handleCancel}
        onOpen={() => setOpenEdit(true)}
        open={openEdit}
        trigger={
          <Button
            onClick={() => onClickEdit(documentToUpdate)}
            icon
            size="mini"
            color="teal"
          >
            <Popup
              style={{ marginBottom: 20 }}
              position="top right"
              content="Edit"
              trigger={<Icon name="write" />}
            />
          </Button>
        }
      >
        <Modal.Header>Edit File</Modal.Header>
        <Modal.Content>
          {!fileName && (
            <Form error>
              {error && <Message error content={error} />}

              <FindAndReplaceWarning
                title="Editing a File Title"
                warning="Editing the file title will update the title of this file"
                component={
                  <Form.Input
                    label="Change the file title"
                    fluid
                    placeholder={documentToUpdate.fileTitle || ""}
                    value={fileTitleEdit}
                    onChange={(e) => setFileTitleEdit(e.target.value)}
                  />
                }
              />
            </Form>
          )}

          {!fileName ? (
            <Header as="h5">Update this file</Header>
          ) : (
            <Header as="h5">
              Replace {documentToUpdate.fileTitle} with {fileTitle}
            </Header>
          )}

          {!fileName && (
            <FindAndReplaceWarning
              title="Updating a File"
              warning="Updating this file will replace the file"
              component={
                <DropZone
                  filterBy={filterBy}
                  setFileSize={setFileSize}
                  setUploadFile={setUploadFile}
                  setFileName={setFileName}
                  setFileTitle={setFileTitle}
                />
              }
            />
          )}

          {fileName && (
            <>
              <Header as="h5">
                <Icon name="info circle" />
                <Header.Content>
                  Enter a title for your file which will be entered into the
                  title column in the file gallery. Adding a title will allow
                  you to search by title name and make files more recognisable.
                </Header.Content>
              </Header>
              <Form error>
                {formError && <Message error content={formError} />}
                {changeFileName && (
                  <Form.Input
                    label="Change the file name"
                    fluid
                    placeholder={fileName}
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                  />
                )}
                <Form.Input
                  label="Give your file a title"
                  fluid
                  placeholder={fileTitle}
                  value={fileTitle}
                  onChange={(e) => setFileTitle(e.target.value)}
                />
              </Form>
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button type="submit" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            color="green"
            content="Save"
            icon="checkmark"
            labelPosition="right"
            type="submit"
            loading={loading}
            disabled={loading}
            onClick={() => handleSave(documentToUpdate)}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default EditFileAction;
