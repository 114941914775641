import { IDBProviderOptions, QueryFilter } from "app/data/FireStoreProvider";
import { useEffect, useState } from "react";
import { ICrossCoverDocument } from "../../../types/CrossCover";
import { getDb } from "../../app/data/DbProvider";
import useUser from "./useUser";

const db = getDb();

const useDocuments = <T extends ICrossCoverDocument>(
  collectionPath: string,
  filter?: QueryFilter,
  options?: IDBProviderOptions
) => {
  const [documents, setStateDocuments] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [triggerLoadVal, setTriggerLoadVal] = useState(
    options?.disableAutoLoad ? 0 : performance.now()
  );
  const { profile } = useUser();

  if (profile === undefined) {
    throw Error("Unknown user cannot use documents");
  }

  const setDocument = async (doc: T, merge: boolean) => {
    setLoading(true);
    console.debug("setDocument", collectionPath, doc);
    await db.updateDocument(collectionPath, doc, profile.uid, merge);
    setLoading(false);
  };

  const addDocument = async (doc: T) => {
    setLoading(true);
    console.debug("addDocument", collectionPath, doc);
    const newDocId = await db.addDocument(collectionPath, doc, profile.uid);
    setLoading(false);
    return newDocId;
  };

  const deleteDocument = async (docId: string) => {
    setLoading(true);
    console.debug("deleteDocument", collectionPath, docId);
    await db.deleteDocument(collectionPath, docId);
    setLoading(false);
  };

  const triggerLoad = async () => {
    if (triggerLoadVal === 0) {
      setTriggerLoadVal(performance.now());
    }
  };

  useEffect(() => {
    if (triggerLoadVal) {
      const unsubscribe = db.watchCollection<T>(
        collectionPath,
        (entities) => setStateDocuments(entities),
        filter,
        { excludeData: options?.excludeData || false }
      );
      return unsubscribe;
    }
  }, [collectionPath, filter, options?.excludeData, triggerLoadVal]);

  return {
    documents,
    addDocument,
    setDocument,
    loading,
    triggerLoad,
    deleteDocument,
  };
};

export default useDocuments;
