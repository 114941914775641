import { Box, Button } from "@mui/material";

import { Dispatch, useEffect, useState } from "react";
import STarTBackGraph from "features/widgets/STarTBack/assets/STarTBackGraph.svg";
import { Icon, Modal, Segment } from "semantic-ui-react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import {
  INodeContext,
  PathwayActions,
  PathwayState,
} from "features/hooks/navigatorReducer";
import { ILinkButtonData, ISTarTBackBlock } from "../../../../../types/Nodes";
import {
  calculateTotals,
  ISTarTBackResult,
  STarTBackWidgetInfos,
} from "./STarTBackWidgetUtils";
import { isFieldToggled } from "features/NodeEditorV2/NodeEditorUtils";
import { uuid4 } from "@sentry/utils";
import SafeHtml from "app/components/SafeHtml";
import {
  getNodeTitle,
  isEmptyLinkBlock,
} from "features/pathwaybuilder/utils/pathwayHelperV2";
import usePathwayBuilder from "features/pathwaybuilder/PathwayBuilder/usePathwayBuilder";

const STarTBackViewer = ({
  block,
  nodeContext,
  dispatch,
  pathwayState,
  navigationBlocked,
}: {
  block: ISTarTBackBlock;
  nodeContext: INodeContext;
  dispatch: Dispatch<PathwayActions>;
  pathwayState: Partial<PathwayState>;
  navigationBlocked: {
    isDisabled: boolean;
    blockRequiredUntoggled: string[];
  };
}) => {
  const [showFlowchart, setShowFlowChart] = useState(false);
  const [outcome, setOutcome] = useState<ISTarTBackResult>();
  const [pathwayNodeTitle, setPathwayNodeTitle] = useState<string>("");
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [outcomeAction, setOutcomeAction] = useState<ILinkButtonData | null>(
    null
  );

  const { state } = usePathwayBuilder();

  const getActionLinkIndex = (result: ISTarTBackResult) => {
    if (result.totalScore <= 3) {
      return 0;
    } else {
      return result.subScore >= 4 ? 2 : 1;
    }
  };

  const clickButtonEvent = () => {
    if (outcomeAction) {
      if (outcomeAction.isPathwayToPathwayLink) {
        setIsModelOpen(true);
        return;
      }
      dispatch({
        type: "MOVE_TO_NEXT_NODE",
        payload: {
          pathwayId: outcomeAction.pathwayId,
          nodeId: outcomeAction.nodeId,
          answer: outcomeAction.linkText,
        },
      });
    }
  };

  const toggleCheck = (
    index: number,
    value: number,
    fieldId: string,
    valueId: string
  ) => {
    const fieldToToggle = pathwayState.nodeContext?.fields.find(
      (field) =>
        field.blockId === block.blockId &&
        field.blockFieldId === fieldId &&
        field.valueId === valueId
    );

    const toggledFieldInNodeContext = pathwayState.nodeContext?.fields.find(
      (field) =>
        field.blockId === block.blockId &&
        field.blockFieldId === fieldId &&
        field.valueId !== valueId &&
        field.isToggled
    );

    if (toggledFieldInNodeContext) {
      dispatch({
        type: "NODE_BLOCK_LOGIC_ON",
        payload: {
          ...toggledFieldInNodeContext,
          isToggled: false,
        },
      });
    }

    if (fieldToToggle) {
      dispatch({
        type: "NODE_BLOCK_LOGIC_ON",
        payload: { ...fieldToToggle, isToggled: !fieldToToggle.isToggled },
      });
    }

    setOutcome(undefined);
    setOutcomeAction(null);
  };

  useEffect(() => {
    const outputFieldsOnCompletion = [
      "Total Score (all 9)",
      "Sub Score Q5-9",
      "Outcome",
    ];

    if (
      pathwayState.nodeContext?.fields.filter(
        (field) =>
          field.blockId === block.blockId &&
          field.isToggled &&
          !outputFieldsOnCompletion.includes(field.fieldName ?? "")
      ).length === 9 &&
      outcome === undefined
    ) {
      const STarTBackFields = pathwayState.nodeContext.fields.filter(
        (field) =>
          field.blockId === block.blockId &&
          field.isToggled &&
          !outputFieldsOnCompletion.includes(field.fieldName ?? "")
      );
      const results = calculateTotals({ fields: STarTBackFields });

      const hasOutputFieldsCompletion =
        pathwayState.nodeContext.fields.filter(
          (field) =>
            field.blockId === block.blockId &&
            outputFieldsOnCompletion.includes(field.fieldName ?? "")
        ).length < 1;
      if (results && !hasOutputFieldsCompletion) {
        dispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            blockId: block.blockId,
            blockFieldId: uuid4(),
            blockName: block.blockName,
            fieldName: "Total Score (all 9)",
            valueName: `${results.totalScore}`,
            groupName: "STarTBack" || "",
            value: results.totalScore,
            valueId: uuid4(),
            addToDecisionSummary: block.blockOptions.addToDecisionSummary,
            addToManagementPlan: block.blockOptions.addToManagementPlan,
            addToNodeScore: block.blockOptions.addToNodeScore,
            isToggled: true,
            hasMultipleValues: false,
            namespace: "",
            name: "",
            order: 9,
          },
        });
        dispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            blockId: block.blockId,
            blockFieldId: uuid4(),
            blockName: block.blockName,
            fieldName: "Sub Score Q5-9",
            valueName: `${results.subScore}`,
            groupName: "STarTBack" || "",
            value: results.subScore,
            valueId: uuid4(),
            addToDecisionSummary: block.blockOptions.addToDecisionSummary,
            addToManagementPlan: block.blockOptions.addToManagementPlan,
            addToNodeScore: block.blockOptions.addToNodeScore,
            isToggled: true,
            hasMultipleValues: false,
            namespace: "",
            name: "",
            order: 10,
          },
        });
        dispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            blockId: block.blockId,
            blockFieldId: uuid4(),
            blockName: block.blockName,
            fieldName: "Outcome",
            valueName: "",
            groupName: "STarTBack" || "",
            value: 0,
            valueId: uuid4(),
            addToDecisionSummary: block.blockOptions.addToDecisionSummary,
            addToManagementPlan: block.blockOptions.addToManagementPlan,
            addToNodeScore: block.blockOptions.addToNodeScore,
            isToggled: true,
            hasMultipleValues: false,
            namespace: "",
            name: "",
            order: 11,
          },
        });
      }

      if (
        results &&
        block.blockData.widgetRules[getActionLinkIndex(results)]
          .isPathwayToPathwayLink
      ) {
        getNodeTitle(
          pathwayState.tenantId || "",
          state.collection,
          block.blockData.widgetRules[getActionLinkIndex(results)]
        ).then((title) => setPathwayNodeTitle(title));
      }
      setOutcome(results);
      setOutcomeAction(
        block.blockData.widgetRules[getActionLinkIndex(results)]
      );
    }

    if (
      pathwayState.nodeContext &&
      pathwayState.nodeContext?.fields.filter(
        (field) =>
          field.blockId === block.blockId &&
          field.isToggled &&
          !outputFieldsOnCompletion.includes(field.fieldName ?? "")
      ).length < 9
    ) {
      const fieldsToDelete = pathwayState.nodeContext.fields.filter((field) =>
        outputFieldsOnCompletion.includes(field.fieldName || "")
      );

      fieldsToDelete.forEach((field) =>
        dispatch({ type: "NODE_BLOCK_LOGIC_OFF", payload: field })
      );
      setOutcomeAction(null);
    }
  }, [dispatch, outcome, block, pathwayState, state.collection]);

  return (
    <>
      <Box sx={{ borderRadius: 1, border: "1px solid #444" }}>
        <Box
          sx={{ borderBottom: "1px solid #444", borderBottomColor: "divider" }}
        >
          <Box
            style={{
              color: "#fff",
              backgroundColor: "rgb(60,95,180)",
              padding: 8,
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            STarTBack Screening Tool
          </Box>
          <Box sx={{ p: 1 }}>
            Thinking about the last 2 weeks tick your response to the following
            questions:
          </Box>
          <Box sx={{ p: 1 }}>
            After answering ALL of the questions below you will be able to
            proceed to the relevant pathway depending on the final score.
          </Box>
        </Box>
        {STarTBackWidgetInfos.logicFields.map((logicField, index) => (
          <Box key={index}>
            {logicField.type === "yesno" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  gap: 1,
                  borderBottom: "1px solid #444",
                  borderBottomColor: "divider",
                  mb: 1,
                }}
              >
                <p style={{ flex: "0 0 50px", textAlign: "center" }}>
                  {index + 1}.
                </p>

                <p style={{ flex: 4 }}>{logicField.questionLogic}</p>
                <Box sx={{ flex: 1, display: "flex" }}>
                  {Object.values(logicField.options).map((option) => (
                    <Box
                      key={logicField.questionLogic + option.text}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <span>{option.text}</span>
                      <input
                        style={{
                          width: "40px",
                          height: "40px",
                          accentColor: "#3C5FB4",
                        }}
                        type="checkbox"
                        checked={
                          isFieldToggled(
                            "field",
                            `${option.valueId}${block.blockId}`,
                            pathwayState.nodeContext ?? undefined
                          ) ?? false
                        }
                        onChange={() => {
                          toggleCheck(
                            index,
                            option.value,
                            `${logicField.logicId}${block.blockId}`,
                            `${option.valueId}${block.blockId}`
                          );
                        }}
                      />
                      <span>{option.value}</span>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {logicField.type === "subjective" && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    gap: 1,
                    mb: 1,
                  }}
                >
                  <p style={{ flex: "0 0 50px", textAlign: "center" }}>
                    {index + 1}.
                  </p>

                  <p style={{ flex: 4 }}>{logicField.questionLogic}</p>
                </Box>

                <Box sx={{ display: "flex", mb: 2 }}>
                  {Object.values(logicField.options).map((option) => (
                    <Box
                      key={logicField.questionLogic + option.text}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <span>{option.text}</span>
                      <input
                        style={{
                          width: "40px",
                          height: "40px",
                          accentColor: "#3C5FB4",
                        }}
                        type="checkbox"
                        checked={
                          isFieldToggled(
                            "field",
                            `${option.valueId}${block.blockId}`,
                            pathwayState.nodeContext ?? undefined
                          ) ?? false
                        }
                        onChange={() =>
                          toggleCheck(
                            index,
                            option.value,
                            `${logicField.logicId}${block.blockId}`,
                            `${option.valueId}${block.blockId}`
                          )
                        }
                      />
                      <span>{option.value}</span>
                    </Box>
                  ))}
                </Box>
                {outcome === undefined && (
                  <p
                    style={{
                      backgroundColor: "#FCF8C8",
                      margin: "0px 10px",
                      border: "1px solid #F7EA56",
                      padding: "16px 16px",
                      marginBottom: 8,
                    }}
                  >
                    The final score will appear here after answering{" "}
                    <strong>ALL</strong> of the questions above. You will then
                    be able to proceed to the relevant pathway depending on the
                    final score.
                  </p>
                )}

                {outcome && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        border: "1px solid #444",
                        borderRadius: 2,
                        p: 2,
                      }}
                    >
                      <h3>Total Score (all 9):</h3>
                      <h2>{outcome.totalScore}</h2>
                    </Box>
                    <Box
                      sx={{
                        textAlign: "center",
                        border: "1px solid #444",
                        borderRadius: 2,
                        p: 2,
                      }}
                    >
                      <h3>Sub Score (Q5-9):</h3>
                      <h2>{outcome.subScore}</h2>
                    </Box>
                  </Box>
                )}

                <div style={{ textAlign: "center", color: "#3C5FB4" }}>
                  {block.blockData.showGraph && (
                    <>
                      {showFlowchart ? (
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowFlowChart(!showFlowchart)}
                        >
                          Hide STarTBack Scoring System
                        </p>
                      ) : (
                        <p
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => setShowFlowChart(!showFlowchart)}
                          data-testid="startbackExpand"
                        >
                          Show STarTBack Scoring System
                        </p>
                      )}
                    </>
                  )}
                </div>
                {block.blockData.showGraph && showFlowchart && (
                  <img
                    src={STarTBackGraph}
                    style={{ width: "100%" }}
                    alt="Startback graph"
                  />
                )}
                <Segment textAlign="center" basic>
                  <p style={{ marginBottom: "0px" } as any}>
                    Copyright
                    <span>
                      {" "}
                      <Icon name="copyright outline" />
                    </span>
                    Keele University, 2022
                  </p>
                  <p>Licence Agreement</p>
                </Segment>
                {outcomeAction && outcome && (
                  <Box sx={{ p: 2 }}>
                    <Button
                      size="large"
                      variant="contained"
                      fullWidth={true}
                      color="primary"
                      disabled={
                        isEmptyLinkBlock(outcomeAction) ||
                        navigationBlocked.isDisabled
                      }
                      onClick={() => clickButtonEvent()}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                      }}
                    >
                      {!outcomeAction.isPathwayToPathwayLink && (
                        <SafeHtml
                          html={
                            block.blockData.widgetRules[
                              getActionLinkIndex(outcome)
                            ].linkText
                          }
                        />
                      )}
                      {outcomeAction.isPathwayToPathwayLink && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <SafeHtml html={outcomeAction.linkText} />
                          <ArrowCircleRightOutlinedIcon sx={{ mx: 1 }} />
                          <SafeHtml html={outcomeAction.pathwayId} />
                          <ArrowCircleRightOutlinedIcon sx={{ mx: 1 }} />
                          <SafeHtml html={pathwayNodeTitle} />
                        </Box>
                      )}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ))}
      </Box>
      {outcome && outcomeAction && (
        <Modal
          size="mini"
          open={isModalOpen}
          closeIcon
          onClose={() => setIsModelOpen(false)}
        >
          <Modal.Header>Keep Decision summary</Modal.Header>
          <Modal.Content>
            You are about to switch into a different Pathway, please confirm if
            you want to keep the decision summary or erase it.
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="error"
              onClick={() => {
                setIsModelOpen(false);
                dispatch({
                  type: "MOVE_TO_NEXT_NODE",
                  payload: {
                    pathwayId: outcomeAction.pathwayId,
                    nodeId: outcomeAction.nodeId,
                    answer: outcomeAction.linkText,
                  },
                });
              }}
            >
              Discard
            </Button>
            <Button
              color="success"
              onClick={() => {
                setIsModelOpen(false);
                dispatch({
                  type: "MOVE_TO_NEXT_NODE",
                  payload: {
                    pathwayId: outcomeAction.pathwayId,
                    nodeId: outcomeAction.nodeId,
                    answer: outcomeAction.linkText,
                  },
                });
              }}
            >
              Keep
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default STarTBackViewer;
