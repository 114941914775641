import { useCrossCoverDispatch, useCrossCoverSelector } from "app/store/hooks";
import { updateActiveModule } from "app/store/moduleSlice";

const useModules = () => {
  const moduleState = useCrossCoverSelector((state) => state.modules);
  const dispatch = useCrossCoverDispatch();
  const onChangeModule = (moduleId: string) => {
    const module = moduleState.tenantModules?.find(
      (x) => x.id === moduleId && x.isActive
    );

    if (module) {
      dispatch(updateActiveModule(module));
    }
  };

  return {
    modules: moduleState.tenantModules,
    activeModule: moduleState.activeModule,
    setModule: onChangeModule,
  };
};

export default useModules;
