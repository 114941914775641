// Vendor
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
// App
import "./vars.css";
import "./index.css";
import App from "./app/layout/App";
import ScrollToTop from "./app/common/util/ScrollToTop";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as firebaseConfig from "./app/config/firebase";
import CrossCoverProvider from "app/providers/CrossCoverProvider";
import DynamicThemeProvider from "app/providers/DynamicThemeProvider";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CssBaseline from "@mui/material/CssBaseline";
import { getAuth } from "firebase/auth";
import { LocalizationProvider } from "@mui/x-date-pickers";

import "dayjs/locale/en-gb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Provider } from "react-redux";
import { store } from "app/store/store";
import ReduxToastr from "react-redux-toastr";

const redirectDomains = ["orthopathway.com", "ortho-pathway.co.uk"]
if(redirectDomains.includes(window.location.host.replace("www.",""))){
  window.location.replace("http://www.crosscover.app")
}
// Popper JS is now requiring React 18 interfaces. This declaration below will define the
// the missing interfaces in our react 17 context
// https://github.com/mui/material-ui/issues/35287
declare global {
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}

if (process.env.NODE_ENV === "production") {
  const { hostname } = new URL(window.location.href);
  Sentry.init({
    dsn: "https://cc6cb8b4262143c1ac3ff56b15f3a812@o1223636.ingest.sentry.io/6367975",
    integrations: [new BrowserTracing()],
    environment: hostname.substring(0, 64),
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const firestoreApp = firebaseConfig.configure();

const rootEl = document.getElementById("root");

if (![].at) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.at = function (pos) {
    return this.slice(pos, pos + 1)[0];
  };
}

let render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <CrossCoverProvider>
        <DynamicThemeProvider>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <Suspense fallback={<div>Loading...</div>}>
              <BrowserRouter
                getUserConfirmation={() => {
                  /* Empty callback to block the default browser prompt */
                }}
              >
                <CssBaseline />
                <ScrollToTop></ScrollToTop>
                <ReduxToastr
                  position="bottom-right"
                  transitionIn="fadeIn"
                  transitionOut="fadeOut"
                />

                <App />
              </BrowserRouter>
            </Suspense>
          </LocalizationProvider>
        </DynamicThemeProvider>
      </CrossCoverProvider>
    </Provider>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept("./app/layout/App", () => {
    setTimeout(render);
  });
}

const auth = getAuth(firestoreApp);
auth.onAuthStateChanged(() => {
  render();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
