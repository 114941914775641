import { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import {
  BlockTypes,
  ILinkButtonBlock,
  NodeBlock,
  NodeBlockData,
  IDynamicNode,
} from "../../../../types/Nodes";
import "firebase/compat/firestore";
import { DynamicNodeEditorActions } from "../dynamicNodeEditorReducer";
import NodeLinkButtonView from "./NodeLinkButtonView";
import { getDb } from "app/data/DbProvider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  createNode,
  EMPTY_LINK_TEXT,
  // createNode,
  getCollectionOrderName,
  getDynamicEmptyNode,
  // getDynamicEmptyNode,
  getOrderedNodes,
} from "features/pathwaybuilder/utils/pathwayHelperV2";
import DoneIcon from "@mui/icons-material/Done";
import {
  // Backdrop,
  Box,
  Button,
  ButtonGroup,
  // Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import useReferencePathways from "features/hooks/useReferencePathways";
// import useUser from "features/hooks/useUser";
import { ITenantPathway } from "../../../../types/Pathway";

import usePathwayBuilder from "features/pathwaybuilder/PathwayBuilder/usePathwayBuilder";

// import NodeViewPopUp from "./NodeEditorComponents/NodeViewPopUp";
import { getPathwayPath } from "features/pathwaybuilder/utils/editorConverters";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";
import produce from "immer";
import useUser from "features/hooks/useUser";
import { toastr } from "react-redux-toastr";
import { INodeViewSettings } from "features/NodeViewer/NodeView";
import NodeSelectorPopUp from "./NodeEditorComponents/NodeSelectorPopUp";
interface INodeActionEditorProps {
  nodes?: IDynamicNode[];
  block: ILinkButtonBlock;
  isEditing: boolean;
  pathwayEditorDispatch: Dispatch<PathwayActions>;
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  validatorSample?: (fn: Function) => void;
  navigationBlocked: {
    isDisabled: boolean;
    blockRequiredUntoggled: string[];
  };
  pathwayState: Partial<PathwayState>;
  nodeId: string;
  isPreview: boolean;
  onAddBlock?: (
    blockType: BlockTypes,
    data: NodeBlockData,
    row?: number,
    column?: number
  ) => NodeBlock | undefined;
  onEdit?: (block: NodeBlock | undefined) => void;
  onSave?: () => Promise<void>;
  currentNode: IDynamicNode;
  nodeSettings: INodeViewSettings;
}

interface IPathwayNodeOptions {
  text: string;
  value: string;
  isStartNode: boolean;
  node?: IDynamicNode;
}
interface IPathwayOptions {
  text: string;
  value: string;
}

const NodeLinkButtonEditor = ({
  nodes,
  pathwayEditorDispatch,
  // onDeleteBlock,
  block,
  isEditing,
  dispatch,
  validatorSample,
  navigationBlocked,
  pathwayState,
  nodeId,
  isPreview,
  onAddBlock,
  onEdit,
  onSave,
  currentNode,
  nodeSettings,
}: INodeActionEditorProps) => {
  const { referencePathways } = useReferencePathways();
  const { profile } = useUser();
  const { state } = usePathwayBuilder();

  const [nodeOptions, setNodeOptions] = useState<IPathwayNodeOptions[]>([]);

  const [pathwayOptions, setOptionPathways] = useState<IPathwayOptions[]>([]);

  const [pathwayNodes, setPathwayNodes] = useState<
    Record<string, IPathwayNodeOptions[]>
  >({});

  const [isStartNode, setIsStartNode] = useState(true);
  const [createNewNode, setCreateNewNode] = useState(false);

  const [newNodeTitle, setNewNodeTitle] = useState("");

  const anchorRef = useRef<HTMLDivElement | null>(null);

  // const [popupNode, setPopupNode] = useState<IDynamicNode | undefined>(
  //   undefined
  // );
  // const [popupNodeOpen, setPopupNodeOpen] = useState(false);

  //Generate a list of node for the dropdown
  useEffect(() => {
    if (nodes) {
      const sortedNodes: IPathwayNodeOptions[] = nodes
        .filter((node) => node.id !== nodeId)
        .map((node) => ({
          text: node.title,
          value: node.id || "",
          isStartNode: false,
          node,
        }))
        .concat()
        .sort((a, b) =>
          a.text.localeCompare(b.text, "en-GB", { numeric: true })
        );
      sortedNodes.unshift({
        text: EMPTY_LINK_TEXT,
        value: EMPTY_LINK_TEXT,
        isStartNode: false,
      });
      setNodeOptions(sortedNodes);
    }
  }, [nodes, nodeId]);

  //Generate a list of pathways for the dropdown
  useEffect(() => {
    const pathways: IPathwayOptions[] = [];

    referencePathways.forEach((pathway) => {
      pathways.push({
        text: pathway.name,
        value: pathway.name,
      });
    });

    setOptionPathways(pathways);
  }, [referencePathways]);

  const onLoadPathwayNodes = useCallback(
    async (pathwayId: string) => {
      const db = getDb();
      if (pathwayState.tenantId) {
        const nodes = await db.getCollection<IDynamicNode>(
          getPathwayPath(pathwayState.tenantId, pathwayId, state.collection)
        );
        const pathway = (await db.getDoc<ITenantPathway>(
          getPathwayPath(pathwayState.tenantId),
          pathwayId
        )) as unknown as Record<string, string[]>;
        const orderName = getCollectionOrderName(state.collection);
        const orderedNodes = getOrderedNodes(
          pathway[orderName],
          nodes
        ) as IDynamicNode[];
        let options: IPathwayNodeOptions[] = orderedNodes.map((x) => ({
          value: x.id ?? "",
          text: x.title,
          isStartNode: false,
          node: x,
        }));

        if (options.length > 0) {
          options[0].isStartNode = true;
        }

        if (options.length === 0) {
          options = [{ text: "No nodes found", value: "", isStartNode: false }];
        }

        options.unshift({
          text: EMPTY_LINK_TEXT,
          value: EMPTY_LINK_TEXT,
          isStartNode: false,
        });
        setPathwayNodes((prev) => ({ ...prev, [pathwayId]: options }));
      }
    },
    [pathwayState.tenantId, state.collection]
  );

  useEffect(() => {
    if (block.blockData && block.blockData?.pathwayId) {
      onLoadPathwayNodes(block.blockData.pathwayId);
    }
    if (block.blockData?.pathwayId === "") {
      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: {
            ...block.blockData,
            pathwayId: pathwayState.currentPathwayId,
          },
        },
      });
    }
  }, [block, onLoadPathwayNodes, dispatch, pathwayState]);

  // useEffect(() => {
  //   if (
  //     block.blockData?.pathwayId !== "" &&
  //     block.blockData?.nodeId === "" &&
  //     isStartNode
  //   ) {
  //     let startNode: string | undefined = undefined;
  //     if (pathwayNodes[block.blockData.pathwayId]) {
  //       startNode = pathwayNodes[block.blockData.pathwayId].find(
  //         (node) => node.isStartNode
  //       )?.value;

  //       if (startNode && startNode !== nodeId) {
  //         dispatch?.({
  //           type: "UPDATE_BLOCK",
  //           payload: {
  //             blockId: block.blockId,
  //             blockData: {
  //               ...block.blockData,
  //               nodeId: startNode,
  //             },
  //           },
  //         });
  //       }
  //     }
  //   }
  // }, [pathwayNodes, block, dispatch, isStartNode, nodeId]);

  const onLinkTypeChange = (isPathwayToPathwayLink: boolean) => {
    if (block.blockData) {
      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: {
            ...block.blockData,
            isPathwayToPathwayLink,
            nodeId: isPathwayToPathwayLink ? "" : EMPTY_LINK_TEXT,
            pathwayId: isPathwayToPathwayLink
              ? pathwayOptions[0].value
              : pathwayState.currentPathwayId,
          },
        },
      });
    }
  };

  const onLinkTextChanged = (linkText: string) => {
    if (block.blockData) {
      const blockData = produce(block.blockData, (draft) => {
        let newLinkText = linkText;

        // if (newLinkText === "") {
        //   newLinkText =
        //     "This is a link button text, replace this text with your own...";
        // }
        draft.linkText = newLinkText;
      });

      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData,
        },
      });
    }
  };

  const onToggleStartNode = (startNode: boolean) => {
    setIsStartNode(startNode);

    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData: {
          ...block.blockData,
          nodeId: "",
        },
      },
    });
  };

  const onToggleCreateNewNode = (createNewNode: boolean) => {
    setCreateNewNode(createNewNode);

    if (!createNewNode) {
      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: {
            ...block.blockData,
            nodeId: block.blockData.isPathwayToPathwayLink
              ? ""
              : EMPTY_LINK_TEXT,
          },
        },
      });

      setNewNodeTitle("");
    }
  };

  const onCreateNewNode = async () => {
    const newNode = getDynamicEmptyNode();
    newNode.title = newNodeTitle;

    if (newNodeTitle === "" || block.blockData?.linkText === "") {
      toastr.error(
        "Invalid create request",
        "Please enter a title for the child node"
      );
      return;
    }

    if (onSave) {
      await onSave();

      const newNodeCreated = await createNode(
        pathwayState.tenantId ?? "",
        pathwayState.currentPathwayId ?? "",
        state.collection,
        newNode,
        profile.email
      );

      if (newNodeCreated) {
        toastr.success("Child Node Created", "");
        dispatch?.({
          type: "UPDATE_BLOCK",
          payload: {
            blockId: block.blockId,
            blockData: {
              ...block.blockData,
              nodeId: newNodeCreated.id,
            },
          },
        });
        setCreateNewNode(false);
      }
      if (!newNodeCreated) {
        toastr.error("Error creating child node", "");
      }
    }
  };

  const onChangeLinkPathway = (pathwayId: string) => {
    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData: {
          ...block.blockData,
          pathwayId,
          nodeId: "",
        },
      },
    });
    setIsStartNode(true);
  };

  const onAddNewLinkButton = () => {
    const addedBlock = onAddBlock?.(
      "linkButton",
      {
        linkText: "",
        nodeId: "",
        pathwayId: block.blockData?.pathwayId ?? "",
        isPathwayToPathwayLink: false,
      },
      block.position.row + 1
    );
    onEdit?.(addedBlock);
  };

  if (!block.blockData) {
    return <></>;
  }

  // const onClosePopUpNode = () => {
  //   setPopupNode(undefined);
  //   setPopupNodeOpen(false);
  // };

  return (
    <Box>
      <NodeLinkButtonView
        action={block.blockData}
        dispatch={pathwayEditorDispatch}
        onLinkTextChanged={onLinkTextChanged}
        isEditing={isEditing}
        navigationBlocked={navigationBlocked}
        collection={state.collection}
        tenantId={pathwayState.tenantId || ""}
        isPreview={isPreview}
        block={block}
        blockRules={block.blockRules}
        node={currentNode}
        nodeSettings={nodeSettings}
      />
      {isEditing && (
        <>
          <Box
            ref={anchorRef}
            sx={{
              background: "rgba(118, 118, 118, 0.08)",
              mt: 1,
              border: "1px solid rgba(118, 118, 118, 0.5)",
              borderRadius: "4px",
              p: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "700", fontSize: "16px", py: 1 }}
            >
              Link to:
            </Typography>
            <ButtonGroup fullWidth>
              <Button
                onClick={() => onLinkTypeChange(false)}
                variant={
                  !block.blockData.isPathwayToPathwayLink
                    ? "contained"
                    : "outlined"
                }
              >
                Node in This Pathway
              </Button>
              <Button
                onClick={() => onLinkTypeChange(true)}
                variant={
                  block.blockData.isPathwayToPathwayLink
                    ? "contained"
                    : "outlined"
                }
              >
                Another Pathway
              </Button>
            </ButtonGroup>
            {block.blockData.isPathwayToPathwayLink && (
              <FormControl
                fullWidth
                sx={{
                  my: 1,
                }}
              >
                <InputLabel>Select Pathway...</InputLabel>{" "}
                <Select
                  autoWidth
                  size="small"
                  value={block.blockData.pathwayId}
                  label="Select Pathway..."
                  onChange={(e: SelectChangeEvent<any>) =>
                    onChangeLinkPathway(e.target.value)
                  }
                >
                  {pathwayOptions
                    .filter(
                      (pathway) =>
                        pathway.text !== pathwayState.currentPathwayId
                    )
                    .map((pathway, index) => (
                      <MenuItem key={index} value={pathway.value}>
                        {pathway.text}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "700", fontSize: "16px", py: 1 }}
              defaultValue="existing"
            >
              Node type:
            </Typography>
            {block.blockData.isPathwayToPathwayLink && (
              <Box>
                <ButtonGroup fullWidth>
                  <Button
                    variant={isStartNode ? "contained" : "outlined"}
                    onClick={() => onToggleStartNode(true)}
                  >
                    Start Node
                  </Button>
                  <Button
                    variant={!isStartNode ? "contained" : "outlined"}
                    onClick={() => onToggleStartNode(false)}
                  >
                    Custom Node
                  </Button>
                </ButtonGroup>

                {isStartNode && (
                  <Box
                    sx={{
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1",
                      border: "1px solid rgba(0, 106, 204, 0.5)",
                      my: 1,
                      borderRadius: "4px",
                      p: 2,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                      <InfoOutlinedIcon color="info" />
                      <Box
                        sx={{
                          ml: 1,
                          color: "#012A52",
                        }}
                      >
                        <Typography sx={{ fontWeight: 500 }} variant="h6">
                          Start Node option links to whichever node is the start
                          node in the target pathway.
                        </Typography>
                        <Typography>
                          If the start node in the target pathway changes, the
                          link will automatically update to link to the new
                          start node.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                {!isStartNode && block.blockData.pathwayId !== "" && (
                  <Box sx={{ my: 1 }}>
                    <NodeSelectorPopUp
                      linkedNodeId={block.blockData.nodeId}
                      nodeOptions={pathwayNodes[block.blockData.pathwayId]}
                      pathway={
                        pathwayOptions.find(
                          (pathway) =>
                            pathway.value === block.blockData?.pathwayId
                        )?.text ?? ""
                      }
                      onSelect={(nodeId) =>
                        dispatch?.({
                          type: "UPDATE_BLOCK",
                          payload: {
                            blockId: block.blockId,
                            blockData: {
                              ...block.blockData,
                              nodeId,
                            },
                          },
                        })
                      }
                    />
                  </Box>
                )}
              </Box>
            )}
            {!block.blockData.isPathwayToPathwayLink && (
              <>
                <ButtonGroup sx={{ mb: 2 }} fullWidth>
                  <Button
                    onClick={() => onToggleCreateNewNode(false)}
                    variant={!createNewNode ? "contained" : "outlined"}
                  >
                    Existing Node
                  </Button>
                  <Button
                    onClick={() => onToggleCreateNewNode(true)}
                    variant={createNewNode ? "contained" : "outlined"}
                  >
                    New Node
                  </Button>
                </ButtonGroup>
                {createNewNode && (
                  <Box>
                    <Box sx={{ my: 1 }}>
                      <TextField
                        id="outlined-basic"
                        label="Enter node title"
                        variant="outlined"
                        fullWidth
                        value={newNodeTitle}
                        onChange={(e) => setNewNodeTitle(e.target.value)}
                        sx={{ my: 1, backgroundColor: "#FFF" }}
                      />
                    </Box>
                    <Button
                      sx={{ mb: 2 }}
                      color="success"
                      startIcon={<DoneIcon />}
                      variant="contained"
                      onClick={() => onCreateNewNode()}
                    >
                      Save Changes & Create New Child Node
                    </Button>
                  </Box>
                )}
                {!createNewNode && (
                  <Box sx={{ my: 1 }}>
                    <NodeSelectorPopUp
                      linkedNodeId={block.blockData.nodeId}
                      nodeOptions={nodeOptions}
                      pathway={
                        pathwayOptions.find(
                          (pathway) =>
                            pathway.value === block.blockData?.pathwayId
                        )?.text ?? ""
                      }
                      onSelect={(nodeId) =>
                        dispatch?.({
                          type: "UPDATE_BLOCK",
                          payload: {
                            blockId: block.blockId,
                            blockData: {
                              ...block.blockData,
                              nodeId,
                            },
                          },
                        })
                      }
                    />
                  </Box>
                )}
              </>
            )}
            <Button
              fullWidth
              variant="contained"
              color="success"
              onClick={() => onAddNewLinkButton()}
            >
              Add another link button
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NodeLinkButtonEditor;
