import { useState } from "react";
import CrosscoverLogo from "../../../app/assets/CC_Logo_Landscape_BG.svg";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { IFilePointer } from "../../../../types/Nodes";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

const NodeImageView = ({ images }: { images: IFilePointer[] }) => {
  const [modalOpen, setModalIsOpen] = useState(false);

  return (
    <Box
      textAlign="center"
      data-testid="node-preview-image"
      style={{ marginBottom: "0px" }}
    >
      <div>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pb: 1,
          }}
        >
          <ZoomInIcon /> Click image to enlarge
        </Typography>
        {images.map((image, index) => (
          <span key={index}>
            <img
              onClick={() => setModalIsOpen(true)}
              key={image.pointer}
              style={{
                height: "auto",
                maxHeight: "350px",
                maxWidth: "100%",
                cursor: "pointer",
              }}
              src={image.pointer}
              alt={image.pointerTitle}
            />
          </span>
        ))}
      </div>
      <Dialog
        open={modalOpen}
        onClose={() => setModalIsOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "justify",
            p: 0,
            pb: 1,
          }}
        >
          <img src={CrosscoverLogo} alt="logo" height={50} />

          <IconButton
            aria-label="close"
            onClick={() => setModalIsOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {images.map((image) => (
            <img
              key={image.pointer}
              style={{
                height: "auto",
                maxWidth: "100%",
                maxHeight: "82vh",
                objectFit: "contain",
                cursor: "pointer",
                textAlign: "center",
              }}
              src={image.pointer}
              alt={image.pointerTitle}
            />
          ))}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NodeImageView;
