import { useHistory } from "react-router-dom";
import { Tooltip, useTheme } from "@mui/material";

import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ScreenshotHelpDeskHighlight from "app/assets/screenshot-help-desk-highlight.png";
import ScreenshotReportIssueHighlight from "app/assets/screenshot-report-issue-highlight.png";
import ScreenshotNav from "app/assets/screenshot-nav.png";
import ReferenceDocuments from "app/components/ReferenceDocuments";
import getReleaseDate from "app/common/util/getReleaseDate";
import {
  manufacturerAddress,
  responsiblePersonAddress,
} from "app/common/util/constants";
import CompanyIcon from "app/assets/company_icon.svg";
import ManufacturerIcon from "app/assets/manufacturerIcon.svg";
import SafeHtml from "app/components/SafeHtml";
import MDIcon from "app/assets/MD_Icon.svg";
import UKCAIcon from "app/assets/UKCA.png";
import InstructionsIcon from "app/assets/instructions.png";

interface AccordionLayoutProps {
  title: string;
  text: React.ReactNode | string;
  alert?: React.ReactNode;
  image: React.ReactNode;
  isGridLayout: boolean;
}

const AccordionLayout = ({
  title,
  text,
  alert,
  image,
  isGridLayout,
}: AccordionLayoutProps) => {
  const theme = useTheme();

  return (
    <Accordion disableGutters={true} sx={{ p: 0 }}>
      <AccordionSummary
        sx={{ py: 0 }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" component="h3" sx={{ fontWeight: 700 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0 }}>
        {isGridLayout && !theme.isTabletView && (
          <Box sx={{ display: "flex" }}>
            <Box sx={{ marginRight: 2, marginBottom: 1 }}>
              <Typography sx={{ marginBottom: 1 }}>{text}</Typography>
              <Box>{alert}</Box>
            </Box>
            <Box sx={{ maxWidth: "40%" }}>{image}</Box>
          </Box>
        )}

        {(!isGridLayout || theme.isTabletView) && (
          <>
            <Box
              sx={{ marginRight: theme.isTabletView ? 0 : 2, marginBottom: 1 }}
            >
              <Typography sx={{ marginBottom: 1 }}>{text}</Typography>
              <Box>{alert}</Box>
            </Box>
            <Box>{image}</Box>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

interface IGuidance {
  handleNextStep?: () => void;
  isNagScreen?: boolean;
}

const Guidance = ({ handleNextStep, isNagScreen }: IGuidance) => {
  const history = useHistory();

  const onSubmit = () => {
    history.push("/");
    handleNextStep && handleNextStep();
  };

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "100%",
      }}
    >
      <Typography variant="h5" component="h2">
        Getting Started
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <AccordionLayout
          isGridLayout={true}
          title="Reporting Technical Issues"
          text="You can report technical issues with CrossCover by clicking on
                  the Help Desk button in the top-right corner of the screen, in
                  the navigation panel."
          alert={
            <Alert severity="warning">
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                Please do not report issues with the content of Pathways via the
                Help Desk as this content is user created and is not created
                internally. Please raise issues with Pathway content using the
                “Report Issue” button.
              </Typography>
            </Alert>
          }
          image={
            <img
              src={ScreenshotHelpDeskHighlight}
              alt="Screenshot HelpDesk Highlighted"
              style={{ width: "100%", objectFit: "contain" }}
            />
          }
        />

        <AccordionLayout
          isGridLayout={true}
          title="Reporting Pathway Issues"
          text="You can report issues with a Pathway such as missing, incorrect or outdated information in a Node by clicking the “Report Issue” button when inside a Pathway."
          image={
            <img
              src={ScreenshotReportIssueHighlight}
              alt="Screenshot Report Issue Highlighted"
              style={{ width: "100%", objectFit: "contain" }}
            />
          }
        />

        <AccordionLayout
          isGridLayout={false}
          title="User Settings"
          text="You can update your name and avatar later in your Account
                Settings, which can be accessed from the top navigation bar
                shown below."
          image={
            <img
              src={ScreenshotNav}
              alt="Screenshot Navigation Bar"
              style={{ width: "100%", objectFit: "contain" }}
            />
          }
        />

        <AccordionLayout
          isGridLayout={false}
          title=" Multi-Factor Authentication (MFA)"
          text="Multi-Factor Authentication can be enabled or disabled from your
                Account Settings located in the top navigation bar shown below."
          image={
            <img
              src={ScreenshotNav}
              alt="Screenshot Navigation Bar"
              style={{ width: "100%", objectFit: "contain" }}
            />
          }
        />

        <AccordionLayout
          isGridLayout={false}
          title="User Manual"
          text={
            <>
              You can read about the features of CrossCover via the{" "}
              <ReferenceDocuments
                as="LINK"
                documentName="USER MANUAL"
                sx={{
                  display: "inline",
                  color: theme.palette.primary.main,
                  textDecoration: "underline",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#436fcd",
                  },
                }}
              />
              . Alternatively, you can access the{" "}
              <strong>Tutorials page</strong> at any time via the link provided
              in the CrossCover header. If you need to refer to the User Manual
              again at any point, this can also be accessed via the Tutorials
              page.
            </>
          }
          image={
            <img
              src={ScreenshotNav}
              alt="Screenshot Navigation Bar"
              style={{ width: "100%", objectFit: "contain" }}
            />
          }
        />

        <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
          Documents
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: theme.isMobileView ? "column" : "row",
          }}
        >
          <ReferenceDocuments
            as="BUTTON"
            documentName="CLINICAL SAFETY CASE REPORT"
          />
          <ReferenceDocuments as="BUTTON" documentName="PRIVACY POLICY" />
        </Box>

        <Typography variant="body1">
          The Clinical Safety Case Report and the Privacy Policy can be accessed
          at any time via the link provided in the CrossCover footer.
        </Typography>
      </Box>

      <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
        CrossCover Web Application
      </Typography>
      <table>
        <tr>
          <td
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography fontWeight={700}>Version Number</Typography>
          </td>
          <td>
            <Typography>{process.env.REACT_APP_VERSION}</Typography>
          </td>
        </tr>
        <tr>
          <td
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Tooltip title={getReleaseDate()} placement="right" arrow>
              <img src={CompanyIcon} alt="Company Icon" height="20" />
            </Tooltip>
          </td>
          <td>
            <Typography>{getReleaseDate()}</Typography>
          </td>
        </tr>
        <tr>
          <td
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Tooltip
              title={<SafeHtml html={manufacturerAddress} allowHtml />}
              placement="right"
              arrow
            >
              <img src={ManufacturerIcon} alt="Manufacturer Icon" height="20" />
            </Tooltip>
          </td>
          <td>
            <Typography>
              {<SafeHtml html={manufacturerAddress} allowHtml />}
            </Typography>
          </td>
        </tr>
        <tr>
          <td
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Tooltip
              title={<SafeHtml html={responsiblePersonAddress} allowHtml />}
              placement="right"
              arrow
            >
              <Typography fontWeight={700}>UK Responsible Person</Typography>
            </Tooltip>
          </td>
          <td>
            <Typography>
              {<SafeHtml html={responsiblePersonAddress} allowHtml />}
            </Typography>
          </td>
        </tr>
        <tr>
          <td
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography fontWeight={700}>Intended Use</Typography>
          </td>
          <td>
            <Typography>
              CrossCover is a clinical pathway
              <br />
              development and operations
              <br />
              (ClinicalDevOps) web platform
              <br />
              designed to support the Design,
              <br />
              Build, Testing, Deployment,
              <br />
              Operating and Monitoring of
              <br />
              Clinical Pathways to support the
              <br />
              clinical decision making for
              <br />
              presentations to Primary and
              <br />
              Secondary Care.
            </Typography>
          </td>
        </tr>
      </table>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Tooltip title="Class I Medical Device" placement="top" arrow>
          <img src={MDIcon} alt="Medical Device Icon" height="20" />
        </Tooltip>
        <Tooltip title="UK Conformity Assessed" placement="top" arrow>
          <img src={UKCAIcon} alt="UKCA Icon" height="20" />
        </Tooltip>
        <Tooltip
          title={
            <Typography variant="subtitle2" display="inline">
              Please click on the{" "}
              <ReferenceDocuments
                sx={{
                  color: "white",
                  cursor: "pointer",
                  display: "inline-block",
                  textDecoration: "underline",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
                as="LINK"
                documentName="USER MANUAL"
              />{" "}
              to show the instructions for use
            </Typography>
          }
          placement="top"
          arrow
        >
          <img src={InstructionsIcon} alt="Instructions Icon" height="20" />
        </Tooltip>
      </Box>

      <Box
        sx={{
          display: isNagScreen ? "none" : "flex",
          justifyContent: "flex-end",
          flex: theme.isMobileView ? 0 : 1,
          alignItems: "flex-end",
        }}
      >
        <Button
          sx={{ width: theme.isMobileView ? "100%" : "auto" }}
          onClick={onSubmit}
          variant="contained"
        >
          Complete Account Activation
        </Button>
      </Box>
    </Box>
  );
};

export default Guidance;
