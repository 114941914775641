// Vendor
import { useState, useEffect } from "react";

import "firebase/firestore";
import "firebase/auth";
import UsernamePasswordForm from "./UsernamePasswordForm";
import VerificationCodeForm from "./VerificationCodeForm";
import {
  AuthErrorCodes,
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  // UserCredential,
} from "firebase/auth";
import { Paper, styled } from "@mui/material";
import VerifyAuthenticator from "./VerifyAuthenticator";

// type onLoginCompleteFn = (onLoginComplete: {
//   userCredential: UserCredential;
// }) => Promise<void>;

interface ILoginFormProps {
  onLoginComplete: () => Promise<void>;
  className?: string;
  userEmail?: string;
  changeInProgress?: boolean;
  setIsMFA?: (value: boolean) => void;
}
const auth = getAuth();

const LoginForm = ({
  onLoginComplete,
  className,
  userEmail,
  changeInProgress,
  setIsMFA,
}: ILoginFormProps) => {
  const [mfaRequired, setMFARequired] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [resolver, setResolver] = useState<any>(null);
  const [error, setError] = useState("");
  const [onLoginScreen, setOnLoginScreen] = useState(true);
  const [mfaCode, setMFACode] = useState("");
  const [mfaUId, setMFAUId] = useState("");
  const [loading, setLoading] = useState(false);

  //useEffect hooks force recaptchaVerifier to reattach to login button when returning to login screen
  useEffect(() => {
    if (onLoginScreen) {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        "login-button",
        {
          size: "invisible",
        },
        auth
      );
    }
  }, [onLoginScreen]);

  const onLogin = async ({ username, password }: any) => {
    try {
      setLoading(true);
      setError("");

      await signInWithEmailAndPassword(auth, username, password);
      console.log("Login Complete");
      if (onLoginComplete) {
        await onLoginComplete();
      }
    } catch (ex: any) {
      console.log(ex);
      if (ex.code === AuthErrorCodes.MFA_REQUIRED) {
        const resolver = getMultiFactorResolver(auth, ex);
        setResolver(resolver);

        if (
          resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID
        ) {
          var phoneAuthProvider = new PhoneAuthProvider(auth);
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            },
            (window as any).recaptchaVerifier
          );
          setVerificationId(verificationId);
        }
        setOnLoginScreen(false);
        setMFARequired(true);
        setIsMFA && setIsMFA(true);
        return;
      }
      //Cloud blocking functions wrap the error through and convert them to string.  We need to try and
      //parse out the errors to see if we have a JSON object that can be used for MFA.
      if (
        ex.code === "auth/internal-error" &&
        ex.message.indexOf("Cloud Function") > -1 &&
        ex.message.indexOf("MFA_REQUIRED") > -1
      ) {
        const jsonString = ex.message
          .replace("Firebase: HTTP Cloud Function returned an error:", "")
          .replace("(auth/internal-error).", "");
        const responseAsJson = JSON.parse(jsonString);

        console.log(
          responseAsJson,
          responseAsJson.error.message,
          responseAsJson.error.message === "MFA_REQUIRED"
        );
        //At this point we can check what kind of error was returned from blocking function
        if (responseAsJson.error.message === "MFA_REQUIRED") {
          setMFACode(responseAsJson.error.details.code);
          setMFAUId(responseAsJson.error.details.uid);
          setMFARequired(true);
          setIsMFA && setIsMFA(true);
          return;
        }
      }

      const errorDescription = changeInProgress
        ? `password`
        : `username or password`;

      setError(`You have entered an invalid  ${errorDescription}`);
    } finally {
      setLoading(false);
    }
  };

  const onMFALogin = async ({ verificationCode }: any) => {
    setError("");
    try {
      const phoneAuthCredential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
      if (resolver != null) {
        await resolver.resolveSignIn(multiFactorAssertion);
        if (onLoginComplete) {
          await onLoginComplete();
        }
      }
    } catch (ex: any) {
      setError("You have entered an incorrect OTP");
    }
  };

  const onReset = () => {
    setMFARequired(false);
    setVerificationId("");
    setResolver(null);
    setError("");
    setOnLoginScreen(true);
  };

  return (
    <>
      <Paper className={className} elevation={0}>
        {!mfaRequired && (
          <UsernamePasswordForm
            error={error}
            setError={setError}
            userEmail={userEmail}
            changeInProgress={changeInProgress}
            onLogin={onLogin}
            isLoading={loading}
          />
        )}
        {mfaRequired && (
          <>
            {resolver && (
              <VerificationCodeForm
                error={error}
                setError={setError}
                isLoginForm={true}
                onMFALogin={onMFALogin}
                onReset={onReset}
                phoneNumberHint={resolver.hints[0].phoneNumber}
              />
            )}
            {mfaCode && (
              <VerifyAuthenticator
                code={mfaCode}
                uid={mfaUId}
                onLoginComplete={() => onLoginComplete()}
              />
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default styled(LoginForm)({});
