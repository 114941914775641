import { Timestamp } from "@google-cloud/firestore";
import { getApp, initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  FirestoreError,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  setDoc,
  Unsubscribe,
  where,
  serverTimestamp,
  startAfter,
  deleteDoc,
  initializeFirestore,
  Firestore,
  collectionGroup,
} from "firebase/firestore";
import { ICrossCoverDocument, ITimestamp } from "../../../types/CrossCover";

export interface IDBProviderOptions {
  excludeData?: boolean;
  disableAutoLoad?: boolean;
  onError?: (error: FirestoreError) => void;
}

export interface IDBProvider {
  generateId(collectionPath: string): string;

  getCollectionGroup<T>(
    groupIndexName: string,
    filter?: QueryFilter,
    options?: IDBProviderOptions
  ): Promise<T[]>;

  watchCollection<T>(
    collectionPath: string,
    onUpdate: (entities: T[]) => void,
    filter?: QueryFilter,
    options?: IDBProviderOptions
  ): Unsubscribe;

  watchDoc<T>(
    collectionPath: string,
    docId: string,
    onUpdate: (entity: T) => void,
    options?: IDBProviderOptions
  ): Unsubscribe;

  getCollection<T>(
    collectionPath: string,
    filter?: QueryFilter,
    options?: IDBProviderOptions
  ): Promise<T[]>;

  getDoc<T>(
    collectionPath: string,
    docId: string,
    options?: IDBProviderOptions
  ): Promise<T>;

  updateDocument<T extends ICrossCoverDocument>(
    collectionPath: string,
    docData: Partial<T>,
    uid: string,
    merge: boolean
  ): Promise<void>;

  updateDocumentById<T extends ICrossCoverDocument>(
    collectionPath: string,
    documentId: string,
    docData: Partial<T>,
    uid: string,
    merge: boolean
  ): Promise<void>;

  addDocument<T extends ICrossCoverDocument>(
    collectionPath: string,
    docData: T,
    uid: string
  ): Promise<string>;

  deleteDocument(collectionPath: string, docId: string): Promise<void>;
}

type FilterOperators =
  | "<"
  | "<="
  | "=="
  | ">"
  | ">="
  | "!="
  | "array-contains"
  | "array-contains-any"
  | "in"
  | "not-in";

type OrderByDirection = "desc" | "asc";

export type WhereFilter = {
  field: string;
  op: FilterOperators;
  filter: string | Date | number | boolean | string[];
};

export type OrderBy = {
  field: string;
  direction?: OrderByDirection;
};

export type QueryFilter = {
  where?: WhereFilter[];
  orderBy?: OrderBy[];
  startAfter?: any;
  limit?: number;
};

export class DBTimestamp implements ITimestamp {
  isTimestamp = true;
}

export function isTimestamp(obj: ITimestamp | undefined): obj is ITimestamp {
  return obj?.isTimestamp ?? false;
}

export class FireStoreProvider implements IDBProvider {
  db: Firestore;

  constructor() {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    initializeApp(firebaseConfig);
    initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });

    // firebase.initializeApp(firebaseConfig);
    // firebase.firestore().settings({
    //   experimentalAutoDetectLongPolling: true,
    // });
    this.db = getFirestore();
  }

  generateId(collectionPath: string): string {
    const ref = doc(collection(this.db, collectionPath));
    return ref.id;
  }

  async getCollectionGroup<T>(
    groupIndexName: string,
    filter?: QueryFilter,
    options?: IDBProviderOptions
  ): Promise<T[]> {
    const queryConstraints = [];

    if (filter) {
      if (filter.where) {
        filter.where.forEach((restriction) => {
          queryConstraints.push(
            where(restriction.field, restriction.op, restriction.filter)
          );
        });
      }

      if (filter.orderBy) {
        filter.orderBy.forEach((order) =>
          queryConstraints.push(orderBy(order.field, order.direction || "asc"))
        );
      }

      if (filter.startAfter) {
        queryConstraints.push(startAfter(filter.startAfter));
      }
      if (filter.limit) {
        queryConstraints.push(limit(filter.limit));
      }
    }
    const snapShots = await getDocs(
      query(collectionGroup(this.db, groupIndexName), ...queryConstraints)
    );
    const entityList: T[] = [];
    snapShots.forEach((snapShot) => {
      if (options && options.excludeData) {
        entityList.push({ id: snapShot.id } as any as T);
      } else {
        entityList.push({
          ...snapShot.data(),
          id: snapShot.id,
        } as any as T);
      }
    });
    return entityList;
  }

  replaceTimestamps(obj: Record<string, any>) {
    for (const prop in obj) {
      if (isTimestamp(obj[prop])) {
        obj[prop] = serverTimestamp() as Timestamp;
      }
    }
    return obj;
  }

  filterToFireStore(collectionName: string, filter?: QueryFilter) {
    const queryConstraints = [];

    if (!filter) {
      return collection(this.db, collectionName);
    }

    if (filter.where) {
      filter.where.forEach((restriction) => {
        // if (restriction.filter instanceof Date) {
        //   queryConstraints.push(
        //     where(
        //       restriction.field,
        //       restriction.op,
        //       firebase.firestore.Timestamp.fromDate(restriction.filter)
        //     )
        //   );
        // } else {
        queryConstraints.push(
          where(restriction.field, restriction.op, restriction.filter)
        );
        //}
      });
    }

    if (filter.orderBy) {
      filter.orderBy.forEach((order) =>
        queryConstraints.push(orderBy(order.field, order.direction || "asc"))
      );
    }

    if (filter.startAfter) {
      queryConstraints.push(startAfter(filter.startAfter));
    }
    if (filter.limit) {
      queryConstraints.push(limit(filter.limit));
    }

    if (queryConstraints.length) {
      return query(collection(this.db, collectionName), ...queryConstraints);
    }

    //Need this here because the filter object could be empty
    return collection(this.db, collectionName);
  }

  watchCollection<T>(
    collectionPath: string,
    onUpdate: (entities: T[]) => void,
    filter?: QueryFilter,
    options?: IDBProviderOptions
  ): Unsubscribe {
    const unsubscribe = onSnapshot(
      this.filterToFireStore(collectionPath, filter),
      (snapShots: QuerySnapshot<DocumentData>) => {
        const entityList: T[] = [];
        snapShots.forEach((snapShot) => {
          if (options && options.excludeData) {
            entityList.push({ id: snapShot.id } as any as T);
          } else {
            entityList.push({
              ...snapShot.data(),
              id: snapShot.id,
            } as any as T);
          }
        });
        onUpdate(entityList);
      },
      options?.onError
    );
    return unsubscribe;
  }

  watchDoc<T>(
    collectionPath: string,
    docId: string,
    onUpdate: (entity: T) => void,
    options?: IDBProviderOptions
  ): Unsubscribe {
    const unsubscribe = onSnapshot(
      doc(this.db, collectionPath, docId),
      (snapShot) => {
        if (options && options.excludeData) {
          onUpdate({ id: snapShot.id } as any as T);
        } else {
          onUpdate({ ...snapShot.data(), id: snapShot.id } as any as T);
        }
      },
      options?.onError
    );
    return unsubscribe;
  }

  async getCollection<T>(
    collectionPath: string,
    filter?: QueryFilter,
    options?: IDBProviderOptions
  ): Promise<T[]> {
    const snapShots = await getDocs(
      this.filterToFireStore(collectionPath, filter)
    );
    const entityList: T[] = [];
    snapShots.forEach((snapShot) => {
      if (options && options.excludeData) {
        entityList.push({ id: snapShot.id } as any as T);
      } else {
        entityList.push({
          ...snapShot.data(),
          id: snapShot.id,
        } as any as T);
      }
    });
    return entityList;
  }

  async getDoc<T>(
    collectionPath: string,
    docId: string,
    options?: IDBProviderOptions
  ): Promise<T> {
    const snapShot = await getDoc(doc(this.db, collectionPath, docId));
    if (options && options.excludeData) {
      return { id: snapShot.id } as any as T;
    } else {
      return { ...snapShot.data(), id: snapShot.id } as any as T;
    }
  }

  async updateDocument<T extends ICrossCoverDocument>(
    collectionPath: string,
    docData: Partial<T>,
    uid: string,
    merge: boolean
  ): Promise<void> {
    if (docData.id === undefined) {
      throw Error("updateDocument: Document id not set cannot update");
    }

    const payload = {
      ...docData,
      updatedBy: uid,
      updatedAt: serverTimestamp() as Timestamp,
    };
    const firestoreObj = this.replaceTimestamps(payload);

    await setDoc(doc(this.db, collectionPath, docData.id), firestoreObj, {
      merge: merge,
    });
  }

  async updateDocumentById<T extends ICrossCoverDocument>(
    collectionPath: string,
    docId: string,
    docData: Partial<T>,
    uid: string,
    merge: boolean
  ): Promise<void> {
    const payload = {
      ...docData,
      updatedBy: uid,
      updatedAt: serverTimestamp(),
    };

    const firestoreObj = this.replaceTimestamps(payload);

    await setDoc(doc(this.db, collectionPath, docId), firestoreObj, {
      merge: merge,
    });
  }

  async addDocument<T extends ICrossCoverDocument>(
    collectionPath: string,
    docData: T,
    uid: string
  ): Promise<string> {
    const payload = {
      ...docData,
      status: docData.status ?? "ACTIVE",
      createdBy: uid,
      createdAt: serverTimestamp() as Timestamp,
      updatedBy: uid,
      updatedAt: serverTimestamp() as Timestamp,
    };
    const firestoreObj = this.replaceTimestamps(payload);
    const ref = await addDoc(collection(this.db, collectionPath), firestoreObj);
    return ref.id;
  }

  async deleteDocument(collectionPath: string, docId: string) {
    await deleteDoc(doc(collection(this.db, collectionPath), docId));
  }
}
