// A context for managing which edges and nodes should be highlighted in the graph.

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface HighlightGraphState {
  highlightedEdgeId: string | null;
  highlightedNodeId: string | null;
  highlightedNodeParentId: string | null;
  highlightEdge: ((edgeId: string) => void) | null;
  highlightNode: ((nodeId: string, parentId?: string) => void) | null;
  clearHighlights: (() => void) | null;
}

const defaultState: HighlightGraphState = {
  highlightedEdgeId: null,
  highlightedNodeId: null,
  highlightedNodeParentId: null,
  highlightEdge: null,
  highlightNode: null,
  clearHighlights: null,
};

const HighlightGraphContext = createContext(defaultState);

const HighlightGraphProvider = ({ children }: { children: ReactNode }) => {
  const [edgeId, setEdgeId] = useState<string | null>(null);
  const [nodeId, setNodeId] = useState<string | null>(null);
  const [parentId, setParentId] = useState<string | null>(null);

  // Clone edges and their labels after they have been highlighted.
  useEffect(() => {
    if (edgeId !== null) {
      removeEdgeClone();
      cloneEdge(edgeId);
    }
  }, [edgeId]);

  /**
   * Highlight an edge in the graph by id.
   * Requires an edge id of the "__sourceId__targetId__edgeIndex" dagre format.
   * The source and target node ids can be original or expanded view node ids.
   * @param edgeId The id of the edge to be highlighted.
   */
  const highlightEdge = (edgeId: string) => {
    setEdgeId(edgeId);
  };

  /**
   * Highlight a node in the graph by id.
   * The parent node id is required if the expanded node id is unknown.
   * @param nodeId The id of the node to highlight.
   * @param parentId The id of the parent node.
   */
  const highlightNode = (nodeId: string, parentId?: string) => {
    setNodeId(nodeId);
    if (parentId) setParentId(parentId);
  };

  const cloneEdge = (edgeId: string) => {
    let edgeElement = document.getElementById(edgeId);
    if (!edgeElement) {
      // Find the edge in the expanded view with the original edge id.
      const edgeElements = document.getElementsByClassName(
        edgeId
      ) as HTMLCollectionOf<HTMLElement>;
      if (edgeElements.length > 0) edgeElement = edgeElements[0];
    }
    if (edgeElement) {
      const clone = edgeElement.parentElement?.cloneNode(true) as HTMLElement;
      clone.setAttribute("id", "duplicateEdge");
      const svgContainer = (
        document.getElementsByClassName(
          "react-flow__edges react-flow__container"
        ) as HTMLCollectionOf<HTMLElement>
      )[0];
      svgContainer.append(clone);
    }
  };

  const removeEdgeClone = () => {
    const duplicateEdgeElement = document.getElementById("duplicateEdge");
    duplicateEdgeElement?.remove();
  };

  /**
   * Removes the edge and node highlights from the graph.
   */
  const clearHighlights = () => {
    setEdgeId(null);
    setNodeId(null);
    removeEdgeClone();
  };

  const highlightGraphState: HighlightGraphState = {
    highlightedEdgeId: edgeId,
    highlightedNodeId: nodeId,
    highlightedNodeParentId: parentId,
    highlightEdge,
    highlightNode,
    clearHighlights,
  };

  return (
    <HighlightGraphContext.Provider value={highlightGraphState}>
      {children}
    </HighlightGraphContext.Provider>
  );
};

const useHighlightGraphContext = () => {
  return useContext(HighlightGraphContext);
};

export { HighlightGraphProvider, useHighlightGraphContext };
