import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { toastr } from "react-redux-toastr";
import { Button, Modal, Image, Popup } from "semantic-ui-react";

import ConfirmationModal from "app/components/ConfirmationModal";
import { IDocument } from "../../../types/DocumentLibrary";
import { FilterCriteria } from "./docLibTypes/DocumentLibraryTypes";
import EditFileAction from "./EditFileAction";
import PopupButton from "app/common/PopupButton";
import useUser from "features/hooks/useUser";

interface IFileActionsProps {
  document: IDocument;
  documents: IDocument[];
  filterBy?: FilterCriteria;
  folderPath: string;

  handleUpdateNodePreview?: (
    oldFileLink: string,
    newFileLink: unknown,
    fileTitle: string
  ) => void;
  onSelect?: (value: IDocument) => void;
}

const FileActions = ({
  document,
  documents,
  filterBy,
  folderPath,
  handleUpdateNodePreview,
  onSelect,
}: IFileActionsProps) => {
  const db = firebase.firestore();

  const [enlargeImg, setEnlargeImg] = useState<boolean>(false);

  const { activeTenantId, profile } = useUser();
  if (activeTenantId === undefined) {
    throw Error("FolderActions: tenantId is undefined");
  }

  const onDelete = async (docId: string) => {
    await db.collection(`${activeTenantId}/Files/files`).doc(docId).update({
      status: "DELETED",
      deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
      deletedBy: profile.email,
    });
    toastr.info("File Deleted", "Your file has been deleted.");
  };

  const selectBtn = onSelect && (
    <Popup
      content="Insert"
      trigger={
        <Button icon="plus" color="yellow" onClick={() => onSelect(document)} />
      }
    />
  );

  return (
    <>
      <Button.Group size="tiny">
        {!filterBy && selectBtn}
        {document.documentType === filterBy && selectBtn}
        {document.documentType === "IMAGE" ? (
          <Modal
            closeIcon
            open={enlargeImg}
            trigger={
              <PopupButton
                icon="eye"
                color="green"
                popupContent="Preview"
              ></PopupButton>
            }
            onClose={() => setEnlargeImg(false)}
            onOpen={() => setEnlargeImg(true)}
          >
            <Modal.Content>
              <Image fluid src={document.file} />
            </Modal.Content>
          </Modal>
        ) : (
          <Popup
            content="Preview"
            trigger={
              <Button
                icon="eye"
                color="green"
                onClick={() => window.open(document.file)}
              />
            }
          />
        )}

        <EditFileAction
          documentToUpdate={document}
          documents={documents}
          handleUpdateNodePreview={handleUpdateNodePreview}
          onSelect={onSelect}
          folderPath={folderPath}
          filterBy={filterBy}
        />

        <ConfirmationModal
          title={`Delete ${document.fileTitle}`}
          message={`Please confirm that you want to delete this file by typing its name: ${document.fileTitle}`}
          label={`Title: ${document.fileTitle}`}
          confirmText={document.fileTitle}
          buttonText="Delete"
          buttonIcon="trash alternate outline"
          buttonColor={"red"}
          onConfirm={() => onDelete(document.id!)}
          triggerHelperText={"Delete"}
          popupPosition={"top right"}
          disabled={false}
        />
      </Button.Group>
    </>
  );
};

export default FileActions;
