import { List } from "semantic-ui-react";
import { CSSProperties } from "react";
import Dropzone from "react-dropzone";

import "./utils/documentLibrary.css";
import { docLibStyles } from "./utils/DocumentLibraryStyles";
import FileActions from "./FileActions";
import FolderActions from "./FolderActions";
import { IFolder, IDocument } from "../../../types/DocumentLibrary";
import {
  collator,
  getDirectChildren,
  getDocTypeIcon,
  getNestedLevel,
} from "./utils/DocumentLibraryHelper";
import {
  FilterCriteria,
  SortCriteria,
} from "./docLibTypes/DocumentLibraryTypes";
import { DocumentTimestampToMilliseconds } from "app/utils/DocumentTimestampToMilliseconds";

interface DocumentGridProps {
  documents: IDocument[];
  disableDrop: boolean;
  filterBy?: FilterCriteria;
  filterCriteria: FilterCriteria;
  folders: IFolder[];
  folderPath: string;
  sortCriteria: SortCriteria;
  style: CSSProperties | undefined;
  handleUpdateNodePreview?: (
    oldFileLink: string,
    newFileLink: unknown,
    fileTitle: string
  ) => void;
  displayFolderPath: (value: IFolder) => void;
  getDirectParent: () => void;
  handleDrop: (value?: IFolder) => Promise<void>;
  onSelect?: (value: IDocument) => void;
  removeFolderPath: () => void;
  setDraggedFile: (value?: IFolder | IDocument) => void;
  setIsDragging?: (value: boolean) => void;
}

const DocumentGrid = ({
  documents,
  disableDrop,
  filterBy,
  filterCriteria,
  folders,
  folderPath,
  sortCriteria,
  style,
  handleUpdateNodePreview,
  displayFolderPath,
  getDirectParent,
  handleDrop,
  onSelect,
  removeFolderPath,
  setDraggedFile,
  setIsDragging,
}: DocumentGridProps) => {
  const sortGridFolders = (a: IFolder, b: IFolder) => {
    switch (sortCriteria) {
      case "updatedAt":
        return (
          DocumentTimestampToMilliseconds(a.updatedAt) -
          DocumentTimestampToMilliseconds(b.updatedAt)
        );

      default:
        return collator.compare(a.folderTitle, b.folderTitle);
    }
  };

  const sortGridFiles = (a: IDocument, b: IDocument) => {
    return collator.compare(
      a[sortCriteria] as string,
      b[sortCriteria] as string
    );
  };

  const filterFiles = (document: IDocument) => {
    if (filterCriteria !== "ALL-FILES") {
      return (
        document.documentType === filterCriteria &&
        folderPath === document.folderPath
      );
    }
    if (filterCriteria === "ALL-FILES") {
      return true && folderPath === document.folderPath;
    }
  };

  const getFilteredChildren = (folders: IFolder[], folderPath: string) => {
    const allFolders = getDirectChildren(folders, folderPath);

    switch (filterCriteria) {
      case "ALL-FILES":
        return allFolders;

      default:
        const filteredDocs = documents.filter(
          (file: any) => file.documentType === filterCriteria
        );
        const filteredDocPath = filteredDocs.map((doc: IDocument) => {
          return getNestedLevel(doc, folderPath);
        });
        return allFolders.filter((folder: IFolder) =>
          filteredDocPath.includes(getNestedLevel(folder, folderPath))
        );
    }
  };

  return (
    <div style={docLibStyles.allGridsContainer}>
      {folderPath.length > 1 && (
        <div style={{ textAlign: "center" }}>
          <Dropzone
            noClick={true}
            onDrop={() => handleDrop()}
            disabled={disableDrop}
          >
            {({ getRootProps }) => (
              <div style={docLibStyles.gridContainer}>
                <div style={docLibStyles.fileTypeIconContainer}>
                  <img
                    draggable="false"
                    className="fileTypeIcon"
                    alt="parent-folder"
                    {...getRootProps({ style })}
                    onClick={() => {
                      getDirectParent();
                      removeFolderPath();
                    }}
                    src={"/assets/documentLibrary/folder.svg"}
                  />
                </div>
                <List>
                  <List.Item>parent folder</List.Item>
                </List>
              </div>
            )}
          </Dropzone>
        </div>
      )}

      {getFilteredChildren(folders, folderPath)
        .sort(sortGridFolders)
        .map((folder: IFolder, index: number) => (
          <div
            key={index}
            onDragStart={() => {
              setDraggedFile(folder);
              setIsDragging?.(true);
            }}
            onDragEnd={() => {
              setDraggedFile(undefined);
              setIsDragging?.(false);
            }}
          >
            <div style={docLibStyles.gridContainer}>
              <div
                className="docGrid-fileIcon-hover"
                style={docLibStyles.fileTypeIconContainer}
              >
                <Dropzone
                  disabled={disableDrop}
                  noClick={true}
                  onDrop={() => handleDrop(folder)}
                >
                  {({ getRootProps }) => (
                    <img
                      className="fileTypeIcon"
                      alt="folder"
                      {...getRootProps({ style })}
                      onClick={() => displayFolderPath(folder)}
                      src={getDocTypeIcon(folder.documentType)}
                    />
                  )}
                </Dropzone>
                <div className="actions-overlay">
                  <FolderActions
                    folder={folder}
                    folders={folders}
                    folderPath={folderPath}
                  />
                </div>
              </div>
              <List>
                <List.Item>{folder.folderTitle}</List.Item>
              </List>
            </div>
          </div>
        ))}

      {documents
        .filter(filterFiles)
        .sort(sortGridFiles)
        .map((document: IDocument, index: number) => (
          <div
            key={index}
            onDragStart={() => {
              setDraggedFile(document);
              setIsDragging?.(true);
            }}
            onDragEnd={() => {
              setDraggedFile(undefined);
              setIsDragging?.(false);
            }}
          >
            <div style={docLibStyles.gridContainer}>
              <div
                className="docGrid-fileIcon-hover"
                style={docLibStyles.fileTypeIconContainer}
              >
                <img
                  style={{ cursor: "pointer" }}
                  alt="file-type"
                  src={getDocTypeIcon(document.documentType)}
                />
                <div className="actions-overlay">
                  <FileActions
                    document={document}
                    documents={documents}
                    filterBy={filterBy}
                    folderPath={folderPath}
                    handleUpdateNodePreview={handleUpdateNodePreview}
                    onSelect={onSelect}
                  />
                </div>
              </div>

              <List>
                <List.Item>{document.fileTitle}</List.Item>
              </List>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DocumentGrid;
