import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { viewType } from "features/documentLibrary/docLibTypes/DocumentLibraryTypes";
import PopUp from "./PopUp";
import { SvgIconProps } from "@mui/material/SvgIcon";

interface IViewTypeProps {
  viewType: viewType;
  setViewType: (value: viewType) => void;
  leftPopuptext: string;
  rightPopuptext: string;
  iconLeft: React.ReactElement<SvgIconProps>;
  iconRight: React.ReactElement<SvgIconProps>;
}

const ViewType = ({
  viewType,
  setViewType,
  leftPopuptext,
  rightPopuptext,
  iconLeft,
  iconRight,
}: IViewTypeProps) => {
  return (
    <>
      <ButtonGroup
        disableElevation
        sx={{ marginRight: 1 }}
        aria-label="outlined primary button group"
      >
        <PopUp
          component={
            <Button
              sx={{
                width: "40px",
                height: rightPopuptext === "Kanban View" ? "45px" : "40px",

                borderRadius: "5px 0 0 5px",
                background: viewType === "Grid View" ? "transparent" : "outlined",
              }}
              variant={viewType === "List View" ? "contained" : "outlined"}
              onClick={() => setViewType("List View")}
            >
              {iconLeft}
            </Button>
          }
          popUpText={leftPopuptext}
          backGroundColour="dark"
        />

        <PopUp
          component={
            <Button
              sx={{
                width: "40px",
                height: rightPopuptext === "Kanban View" ? "45px" : "40px",
                borderRadius: "0 5px 5px 0",
                background: viewType === "List View" ? "transparent" : "outlined",
              }}
              variant={viewType === "Grid View" ? "contained" : "outlined"}
              onClick={() => setViewType("Grid View")}
            >
              {iconRight}
            </Button>
          }
          popUpText={rightPopuptext}
          backGroundColour="dark"
        />
      </ButtonGroup>
    </>
  );
};

export default ViewType;
