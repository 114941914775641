import { Segment } from "semantic-ui-react";
import { INode } from "../../../../types/Nodes";

import { Dispatch } from "react";

// Component
import STarTBackWidgetViewer from "features/widgets/STarTBack2/STarTBackViewer";
import { INodeViewSettings } from "features/NodeViewer/NodeView";
import { INodeContext, PathwayActions } from "features/hooks/navigatorReducer";

interface IProps {
  node: INode;
  //node: INewNode;
  // widgetList: any[];
  // handleAddDecisionWidget: (
  //   widget: any,
  //   alldecision: object[],
  //   totScore: number,
  //   subScore: number,
  //   outcome: string
  // ) => void;
  // previewType: string;
  // handleGoBack: () => void;
  // viewPreviousNode: () => void;
  // viewNextNode: () => void;
  collectionId: string;
  tenantId: string;
  dispatch: Dispatch<PathwayActions>;
  nodeContext: INodeContext;
  nodeSettings?: INodeViewSettings;
  // editPathway: boolean;
  // navigateToNewStep: () => void;
  // navigateToPreviousStep: () => void;
}

const WidgetComponent = ({
  node,
  tenantId,
  dispatch,
  nodeContext,
  nodeSettings,
  collectionId,
}: // widgetList,
// handleAddDecisionWidget,
// previewType,
// handleGoBack,
// viewNextNode,
// viewPreviousNode,
// editPathway,
// navigateToNewStep,
// navigateToPreviousStep,
IProps) => {
  return (
    <>
      {Array.isArray(node.widgets) &&
        node.widgets.map((widget, index) => {
          if (widget.name === "startback") {
            return (
              <Segment key={index}>
                <STarTBackWidgetViewer
                  node={node}
                  dispatch={dispatch}
                  widget={widget}
                  nodeContext={nodeContext}
                  nodeSettings={nodeSettings}
                  collectionId={collectionId}
                />
              </Segment>
            );
          }
          return "";
        })}
    </>
  );
};

export default WidgetComponent;
