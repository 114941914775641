import { Box } from "@mui/material";

import { Dispatch, useEffect, useState } from "react";
import { INode, NodeWidget } from "../../../../types/Nodes";
import STarTBackGraph from "features/widgets/STarTBack/assets/STarTBackGraph.svg";
import NodeActionView from "features/NodeViewer/components/NodeActionView";
import {
  calculateTotals,
  ISTarTBackResult,
  STarTBackWidgetInfos,
} from "features/widgets/STarTBack/STarTBackWidgetUtils";
import { Icon, Segment } from "semantic-ui-react";
import { INodeViewSettings } from "features/NodeViewer/NodeView";
import { INodeContext, PathwayActions } from "features/hooks/navigatorReducer";

const STarTBackViewer = ({
  widget,
  node,
  nodeContext,
  dispatch,
  nodeSettings,
  collectionId,
}: {
  widget: NodeWidget;
  node: INode;
  nodeContext: INodeContext;
  dispatch: Dispatch<PathwayActions>;
  nodeSettings?: INodeViewSettings;
  collectionId: string;
}) => {
  const [showFlowchart, setShowFlowChart] = useState(false);
  const [outcome, setOutcome] = useState<ISTarTBackResult>();

  const isFieldChecked = (
    index: number,
    question: string,
    text: string,
    value: number
  ) => {
    return nodeContext.fields.some(
      (x) =>
        x.name === text && x.groupName === question && +x.namespace === index
    );
  };

  const getActionLinkIndex = (result: ISTarTBackResult) => {
    if (result.totalScore <= 3) {
      return 0;
    } else {
      return result.subScore >= 4 ? 2 : 1;
    }
  };

  const toggleCheck = (
    index: number,
    question: string,
    text: string,
    value: number
  ) => {
    const currentAnswer = nodeContext.fields.find(
      (x) => +x.namespace === index
    );

    if (currentAnswer !== undefined && currentAnswer.value !== value + "") {
      dispatch({
        type: "NODE_LOGIC_OFF",
        payload: {
          namespace: index + "",
          groupName: question,
          name: text,
          value: value + "",
          order: index,
        },
      });
    }
    dispatch({
      type: "NODE_LOGIC_ON",
      payload: {
        namespace: index + "",
        groupName: question,
        name: text,
        value: value + "",
        order: index,
      },
    });
    setOutcome(undefined);
  };
  useEffect(() => {
    if (
      nodeContext.fields.filter(
        (x) =>
          x.namespace !== "Total Score (all 9)" &&
          x.namespace !== "Sub Score Q5-9" &&
          x.namespace !== "Outcome"
      ).length === 9 &&
      outcome === undefined
    ) {
      const results = calculateTotals(nodeContext);

      dispatch({
        type: "NODE_LOGIC_ON",
        payload: {
          namespace: "Total Score (all 9)",
          groupName: "Total Score (all 9)",
          name: results.totalScore + "",
          value: "",
          order: 10,
        },
      });
      dispatch({
        type: "NODE_LOGIC_ON",
        payload: {
          namespace: "Sub Score Q5-9",
          groupName: "Sub Score Q5-9",
          name: results.subScore + "",
          value: "",
          order: 11,
        },
      });
      dispatch({
        type: "NODE_LOGIC_ON",
        payload: {
          namespace: "Outcome",
          groupName: node.answers[getActionLinkIndex(results)],
          name: "",
          value: "",
          order: 12,
        },
      });

      setOutcome(results);
    }
  }, [dispatch, node.answers, nodeContext, outcome]);

  return (
    <Box sx={{ borderRadius: 1, border: "1px solid #444" }}>
      <Box
        sx={{ borderBottom: "1px solid #444", borderBottomColor: "divider" }}
      >
        <Box
          style={{
            color: "#fff",
            backgroundColor: "rgb(60,95,180)",
            padding: 8,
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          STarTBack Screening Tool
        </Box>
        <Box sx={{ p: 1 }}>
          Thinking about the last 2 weeks tick your response to the following
          questions:
        </Box>
        <Box sx={{ p: 1 }}>
          After answering ALL of the questions below you will be able to proceed
          to the relevant pathway depending on the final score.
        </Box>
      </Box>
      {STarTBackWidgetInfos.logicFields.map((logicField, index) => (
        <Box key={index}>
          {logicField.type === "yesno" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: 1,
                borderBottom: "1px solid #444",
                borderBottomColor: "divider",
                mb: 1,
              }}
            >
              <p style={{ flex: "0 0 50px", textAlign: "center" }}>
                {index + 1}.
              </p>

              <p style={{ flex: 4 }}>{logicField.questionLogic}</p>
              <Box sx={{ flex: 1, display: "flex" }}>
                {Object.values(logicField.options).map((option) => (
                  <Box
                    key={logicField.questionLogic + option.text}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <span>{option.text}</span>
                    <input
                      style={{
                        width: "40px",
                        height: "40px",
                        accentColor: "#3C5FB4",
                      }}
                      type="checkbox"
                      checked={isFieldChecked(
                        index,
                        logicField.questionLogic,
                        option.text,
                        option.value
                      )}
                      onChange={() => {
                        toggleCheck(
                          index,
                          logicField.questionLogic,
                          option.text,
                          option.value
                        );
                      }}
                      // disabled={!isActive && true}
                    />
                    <span>{option.value}</span>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {logicField.type === "subjective" && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  gap: 1,
                  mb: 1,
                }}
              >
                <p style={{ flex: "0 0 50px", textAlign: "center" }}>
                  {index + 1}.
                </p>

                <p style={{ flex: 4 }}>{logicField.questionLogic}</p>
              </Box>

              <Box sx={{ display: "flex", mb: 2 }}>
                {Object.values(logicField.options).map((option) => (
                  <Box
                    key={logicField.questionLogic + option.text}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <span>{option.text}</span>
                    <input
                      style={{
                        width: "40px",
                        height: "40px",
                        accentColor: "#3C5FB4",
                      }}
                      type="checkbox"
                      checked={isFieldChecked(
                        index,
                        logicField.questionLogic,
                        option.text,
                        option.value
                      )}
                      onChange={() =>
                        toggleCheck(
                          index,
                          logicField.questionLogic,
                          option.text,
                          option.value
                        )
                      }
                      //  disabled={!isActive && true}
                    />
                    <span>{option.value}</span>
                  </Box>
                ))}
              </Box>
              {outcome === undefined && (
                <p
                  style={{
                    backgroundColor: "#FCF8C8",
                    margin: "0px 10px",
                    border: "1px solid #F7EA56",
                    padding: "16px 16px",
                    marginBottom: 8,
                  }}
                >
                  The final score will appear here after answering{" "}
                  <strong>ALL</strong> of the questions above. You will then be
                  able to proceed to the relevant pathway depending on the final
                  score.
                </p>
              )}

              {outcome && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      border: "1px solid #444",
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    <h3>Total Score (all 9):</h3>
                    <h2>{outcome.totalScore}</h2>
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      border: "1px solid #444",
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    <h3>Sub Score (Q5-9):</h3>
                    <h2>{outcome.subScore}</h2>
                  </Box>
                </Box>
              )}

              <div style={{ textAlign: "center", color: "#3C5FB4" }}>
                {/* <h3>The STarT Back Tool Scoring System:</h3> */}
                {widget.showGraph && (
                  <>
                    {showFlowchart ? (
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowFlowChart(!showFlowchart)}
                      >
                        Hide STarTBack Scoring System
                      </p>
                    ) : (
                      <p
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setShowFlowChart(!showFlowchart)}
                        data-testid="startbackExpand"
                      >
                        Show STarTBack Scoring System
                      </p>
                    )}
                  </>
                )}
              </div>
              {widget.showGraph && showFlowchart && (
                <img
                  src={STarTBackGraph}
                  style={{ width: "100%" }}
                  alt="Startback graph"
                />
              )}
              <Segment textAlign="center" basic>
                <p style={{ marginBottom: "0px" } as any}>
                  Copyright
                  <span>
                    {" "}
                    <Icon name="copyright outline" />
                  </span>
                  Keele University, 2022
                </p>
                <p>Licence Agreement</p>
              </Segment>
              {outcome && !nodeSettings?.showAllWidgetAnswers && (
                <NodeActionView
                  node={
                    {
                      actions: [node.actions[getActionLinkIndex(outcome)]],
                      answers: [node.answers[getActionLinkIndex(outcome)]],
                    } as INode
                  }
                  collectionId={collectionId}
                  dispatch={dispatch}
                />
              )}
              {nodeSettings?.showAllWidgetAnswers &&
                node.answers.map((answer, index) => {
                  return (
                    <NodeActionView
                      node={
                        {
                          actions: [node.actions[index]],
                          answers: [node.answers[index]],
                        } as INode
                      }
                      dispatch={dispatch}
                      showAllWidgetAnswers={nodeSettings?.showAllWidgetAnswers}
                      tagWidgetAnswersWithActions={
                        nodeSettings?.tagWidgetAnswersWithActions
                      }
                      collectionId={collectionId}
                      widgetTagText={nodeSettings?.widgetTagText}
                      disableActions={
                        !Boolean(outcome) ||
                        (outcome !== undefined &&
                          getActionLinkIndex(outcome) !== index)
                      }
                    />
                  );
                })}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default STarTBackViewer;
