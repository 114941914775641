import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import UndoIcon from "@mui/icons-material/Undo";
import {
  Box,
  // Button,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, {
  Dispatch,
  // , useEffect
  useState,
} from "react";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import TuneIcon from "@mui/icons-material/Tune";
import { NodeBlock, IDynamicNode } from "../../../types/Nodes";

import {
  DynamicNodeEditorActions,
  DynamicNodeEditorState,
} from "./dynamicNodeEditorReducer";
import NodeEditorPanelRulesContainer from "./components/EditorPannelComponents/NodeEditorPanelRulesContainer";
import NodeEditorPanelOptionsContainer from "./components/EditorPannelComponents/NodeEditorPanelOptionsContainer";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";

const EditorPanelControls = ({
  block,
  dispatch,
  state,
  pathwayEditorDispatch,
  pathwayState,
  createTemplateChecked,
}: {
  block: NodeBlock | undefined;
  dispatch: Dispatch<DynamicNodeEditorActions>;
  state: DynamicNodeEditorState;
  pathwayEditorDispatch: Dispatch<PathwayActions>;
  pathwayState: Partial<PathwayState>;
  createTemplateChecked: boolean;
}) => {
  const [selectedMenu, setSelectedMenu] = useState<string>("rules");

  // const getEditorPannelTitle = (type: string) => {
  //   let editorPannelTitle = "";

  //   switch (type) {
  //     case "textArea":
  //       editorPannelTitle = "Free Text Input";
  //       break;
  //     case "action":
  //       editorPannelTitle = "Actions";
  //       break;
  //     case "choices":
  //       editorPannelTitle = "Choices";
  //       break;
  //     case "scorer":
  //       editorPannelTitle = "Scorer";
  //       break;
  //     case "trueFalse":
  //       editorPannelTitle = "True or False";
  //       break;
  //     case "image":
  //       editorPannelTitle = "Image";
  //       break;
  //     case "video":
  //       editorPannelTitle = "Video";
  //       break;
  //     case "files":
  //       editorPannelTitle = "Files";
  //       break;
  //   }

  //   return editorPannelTitle;
  // };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedMenu(newValue);
  };

  if (!block) {
    return <></>;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#EAEAEA",
        textAlign: "center",
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "56px",
          bgcolor: "white",
          borderBottom: "3px solid",
          color: "primary.main",
          alignItems: "center",
          position: "relative",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        <IconButton
          aria-label="exit edit"
          sx={{ zIndex: "2" }}
          onClick={() =>
            // dispatch({
            //   type: "UPDATE_EDIT_PANEL",
            //   payload: { block: undefined },
            // })
            dispatch({ type: "EDIT_BLOCK_OFF", payload: {} })
          }
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            fontWeight: "bold",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            pr: 2,
          }}
        >
          {/* {getEditorPannelTitle(
          block.blockType === "choices"
            ? (block.blockData as IChoicesBoxData).type
            : block.blockType
        )} */}
          {block.blockName}
        </Typography>
      </Box>
      {/* <Typography
        sx={{
          py: "10px",
          fontWeight: "bold",
          borderBottom: "3px solid",
          bgcolor: "white",
        }}
        color="primary"
      >
        {getEditorPannelTitle(
          block.blockType === "choices"
            ? (block.blockData as IChoicesBoxData).type
            : block.blockType
        )}
      </Typography> */}
      <Tabs
        value={selectedMenu}
        onChange={handleChange}
        color="primary"
        sx={{
          mb: 2,
          width: "100%",
        }}
        variant="fullWidth"
      >
        <Tab
          value="options"
          label="options"
          icon={<TuneIcon />}
          iconPosition="start"
        />
        <Tab
          value="rules"
          label="rules"
          icon={<ShuffleIcon />}
          iconPosition="start"
        />
      </Tabs>
      <Box sx={{ m: 1 }}>
        {selectedMenu === "options" && block && (
          <NodeEditorPanelOptionsContainer
            pathwayEditorDispatch={pathwayEditorDispatch}
            block={block}
            dispatch={dispatch}
            pathwayState={pathwayState}
            state={state.node}
            createTemplateChecked={createTemplateChecked}
          />
        )}
        {selectedMenu === "rules" && block && (
          <NodeEditorPanelRulesContainer
            block={block}
            dispatch={dispatch}
            state={state.node}
          />
        )}
      </Box>
    </Box>
  );
};

const NodeEditorPanel = ({
  dispatch,
  state,
  pathwayNodes,
  //node,
  tenantId,
  pathwayEditorDispatch,
  pathwayState,
  createTemplateChecked,
}: {
  dispatch: Dispatch<DynamicNodeEditorActions>;
  state: DynamicNodeEditorState;

  pathwayNodes: IDynamicNode[];
  // node: INewNode;
  tenantId: string;
  pathwayEditorDispatch: Dispatch<PathwayActions>;
  pathwayState: Partial<PathwayState>;
  createTemplateChecked: boolean;
}) => {
  // const [blockSettingOpen, setBlockSettingOpen] = useState<
  //   NodeBlock | undefined
  // >();

  // useEffect(() => {
  //   setBlockSettingOpen(
  //     Object.values(state.blocks).find(
  //       (block) => block.blockId === state.editPanelInfos?.blockId
  //     )
  //   );
  // }, [state]);

  // const onSaveEdit = () => {
  //   dispatch({ type: "UPDATE_EDIT_PANEL", payload: { block: undefined } });

  //   if (blockSettingOpen) {
  //     updateRuleBlockNameOnEachBlockRules(blockSettingOpen.blockName);
  //     updateRuleBlockFieldOnEachBlockRules();
  //   }
  // };

  // const onCancelEdit = () => {
  //   if (blockEditInfos) {
  //     dispatch({
  //       type: "UPDATE_BLOCK",
  //       payload: {
  //         blockId: blockEditInfos.blockId,
  //         blockData: blockEditInfos.blockData
  //           ? { ...blockEditInfos.blockData }
  //           : undefined,
  //       },
  //     });
  //     dispatch({
  //       type: "UPDATE_RULES",
  //       payload: {
  //         blockId: blockEditInfos.blockId,
  //         ruleOptions: blockEditInfos.blockRules,
  //       },
  //     });
  //     dispatch({
  //       type: "UPDATE_BLOCK_NAME",
  //       payload: {
  //         blockId: blockEditInfos.blockId,
  //         newBlockName: blockEditInfos.blockName,
  //       },
  //     });
  //     dispatch({
  //       type: "UPDATE_BLOCK_OPTIONS",
  //       payload: {
  //         blockId: blockEditInfos.blockId,
  //         blockOptions: blockEditInfos.blockOptions,
  //       },
  //     });
  //   }
  //   // dispatch({ type: "UPDATE_EDIT_PANEL", payload: { block: undefined } });
  //   onEdit(undefined);
  // };
  // const updateRuleBlockNameOnEachBlockRules = (name: string) => {
  //   Object.values(state.blocks).forEach((blockElement: NodeBlock) => {
  //     if (blockElement.blockRules.rule && state.editPanelInfos?.blockName) {
  //       if (
  //         blockElement.blockRules.rule.includes(state.editPanelInfos?.blockName)
  //       ) {
  //         const regex = new RegExp(':"' + state.editPanelInfos?.blockName, "g");

  //         const newString = blockElement.blockRules.rule.replaceAll(
  //           regex,
  //           ':"' + name ?? ""
  //         );
  //         dispatch({
  //           type: "UPDATE_RULES",
  //           payload: {
  //             blockId: blockElement.blockId,
  //             ruleOptions: {
  //               ...blockElement.blockRules,
  //               rule: newString,
  //             },
  //           },
  //         });
  //       }
  //     }
  //   });
  // };

  // const updateRuleBlockFieldOnEachBlockRules = () => {
  //   const updatedBlock = Object.values(state.blocks).find(
  //     (block) => block.blockId === state.editPanelInfos?.blockId
  //   );
  //   Object.values(state.blocks).forEach((blockElement: NodeBlock) => {
  //     if (
  //       blockElement.blockRules.rule &&
  //       state.editPanelInfos?.blockName &&
  //       interactiveBlocksTypes.includes(state.editPanelInfos?.blockType)
  //     ) {
  //       if (
  //         blockElement.blockRules.rule.includes(state.editPanelInfos?.blockName)
  //       ) {
  //         let objectChangedFields;
  //         // if (state.editPanelInfos.blockType === "choices") {

  //         // }
  //         if (state.editPanelInfos.blockType === "button") {
  //           if (
  //             (state.editPanelInfos.blockData as IButtonData).buttonText !==
  //             (updatedBlock?.blockData as IButtonData).buttonText
  //           ) {
  //             objectChangedFields = {
  //               newFieldName: (updatedBlock?.blockData as IButtonData)
  //                 .buttonText,
  //               oldFieldName: (state.editPanelInfos.blockData as IButtonData)
  //                 .buttonText,
  //             };
  //           }
  //         }

  //         const regex = new RegExp(
  //           ':"' +
  //             state.editPanelInfos?.blockName +
  //             "." +
  //             objectChangedFields?.oldFieldName,
  //           "g"
  //         );
  //         const newString = blockElement.blockRules.rule.replaceAll(
  //           regex,
  //           ':"' +
  //             state.editPanelInfos?.blockName +
  //             "." +
  //             objectChangedFields?.newFieldName ?? ""
  //         );

  //         dispatch({
  //           type: "UPDATE_RULES",
  //           payload: {
  //             blockId: blockElement.blockId,
  //             ruleOptions: {
  //               ...blockElement.blockRules,
  //               rule: newString,
  //             },
  //           },
  //         });
  //       }
  //     }
  //   });
  // };

  return (
    <>
      {
        // state.editPanelInfos
        state.editingBlock && (
          <Box
          // sx={{ margin: 2 }}
          >
            <EditorPanelControls
              pathwayEditorDispatch={pathwayEditorDispatch}
              // block={state.editPanelInfos}
              block={Object.values(state.node.blocks).find(
                (block) => block.blockId === state.editingBlock?.blockId
              )}
              state={state}
              dispatch={dispatch}
              pathwayState={pathwayState}
              createTemplateChecked={createTemplateChecked}
            />
            {/* <Box sx={{ display: "flex", my: 1, float: "right" }}>
              <Button
                startIcon={<UndoIcon />}
                variant="contained"
                onClick={() => onCancelEdit()}
                sx={{ backgroundColor: "#757575", marginRight: "10px" }}
              >
                Undo
              </Button>
            </Box> */}
          </Box>
        )
      }
    </>
  );
};

export default NodeEditorPanel;
