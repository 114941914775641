import { NodeDecisionAction } from "features/hooks/navigatorReducer";
import { List } from "semantic-ui-react";
import { getStartbackDecisionSummary } from "../NodeEditorUtils";

const decisionToIgnore = ["continue to full pathway", "yes", "no", "continue"];

const isDecisionIgnored = (decision: string) => {
  return decisionToIgnore.includes(decision.toLowerCase());
};

const DecisionSummaryList = ({
  decisionSummary,
}: {
  decisionSummary: NodeDecisionAction[];
}) => {
  console.log(decisionSummary);

  let hasSTartBackDecision = false;

  return (
    <List>
      {decisionSummary &&
        decisionSummary.map((decision, index) => (
          <List.Item key={index}>
            {!isDecisionIgnored(decision.header) && decision.header && (
              <>
                <List.Icon name="caret right" />
                <List.Content> {decision.header}</List.Content>
                {Boolean(decision.items.length) && (
                  <List.List style={{ marginLeft: "10px" }}>
                    {decision.items.concat().map((item, indexItem) => {
                      if (
                        item.groupName === "STarTBack" &&
                        decision.header.includes("STarT Back Tool Outcome") &&
                        !hasSTartBackDecision
                      ) {
                        const startbackDecisionSummary =
                          getStartbackDecisionSummary(decision.items);
                        hasSTartBackDecision = true;
                        return (
                          <div key={indexItem}>
                            {startbackDecisionSummary.map((item, index) => (
                              <List.Item
                                key={index}
                                style={{
                                  display: "flex",
                                  marginBottom: "10px",
                                }}
                              >
                                <List.Icon name="angle double right" />
                                {item?.fieldName === "Outcome" && (
                                  <List.Content>{decision.header}</List.Content>
                                )}
                                {item?.fieldName !== "Outcome" && (
                                  <List.Content>
                                    {item?.fieldName} - {item?.valueName}
                                  </List.Content>
                                )}
                              </List.Item>
                            ))}
                          </div>
                        );
                      }

                      if (item.groupName !== "STarTBack") {
                        return (
                          <div key={indexItem}>
                            {item.addToDecisionSummary &&
                              item.isToggled &&
                              item.groupName !== "STarTBack" && (
                                <List.Item
                                  style={{
                                    display: "flex",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <List.Icon name="angle double right" />
                                  <List.Content>
                                    {(!item.hasMultipleValues &&
                                      item.groupName) ??
                                      ""}
                                    {item.groupName &&
                                      !item.hasMultipleValues &&
                                      item.fieldName &&
                                      " - "}
                                    {item.fieldName ?? ""}
                                    {item.fieldName &&
                                      item.hasMultipleValues &&
                                      " - "}
                                    {item.valueName ?? ""}
                                  </List.Content>
                                </List.Item>
                              )}
                          </div>
                        );
                      }
                      return <></>;
                    })}
                  </List.List>
                )}
              </>
            )}
          </List.Item>
        ))}
    </List>
  );
};
export default DecisionSummaryList;
