import {
  // Alert,
  Box,
} from "@mui/material";
import NodeViewer from "./NodeViewer";
import { IDynamicNode } from "../../../../../types/Nodes";

export interface INodeViewPopUpProps {
  node?: IDynamicNode;
  open?: boolean;
  top?: number;
  left?: number;
  // anchorEl: HTMLDivElement | null;
  banner?: string;
  pathway: string;
}

const NodeViewPopUp = ({
  node,
  open,
  // anchorEl,
  left,
  top,
  banner,
  pathway,
}: INodeViewPopUpProps) => {
  // const refCoords = anchorEl?.getBoundingClientRect();
  // const calculatedLeft = (refCoords?.left ?? 0) + (refCoords?.width ?? 0) + 50;
  // const width = window.innerWidth - calculatedLeft - 20;

  return (
    <>
      {node && open && (
        <Box
          sx={{
            // width: width < 700 ? 700 : width,
            // maxWidth: window.innerWidth / 2.5,
            // maxHeight: 800,
            overflowY: "auto",
            // ml: 5,
            // position: "fixed",
            // top: top ?? 100,
            // left: left ?? calculatedLeft,
            // left: 20,
            // zIndex: 1800,
          }}
        >
          {/* {" "} */}
          {/* {banner && <Alert severity="info">{banner}</Alert>} */}
          <NodeViewer
            isPreview={true}
            // state={NodeToBlocks(node)}
            state={node}
            pathwayEditorDispatch={() => {}}
            pathwayState={{
              route: [],
              activeNode: undefined,
              decisionSummary: [],
              currentPathwayId: pathway,
              tenantId: "",
              nodeContext: { fields: [] },
              pathwayEpisode: undefined,
              actionDirection: undefined,
              episodeStatus: undefined,
            }}
            nodeSettings={{ disablePrintControls: true }}
          />
        </Box>
      )}
    </>
  );
};

export default NodeViewPopUp;
