import { Dispatch } from "react";
import { Icon } from "semantic-ui-react";
import { INodeViewSettings } from "../NodeView";
import { Button } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { NodePointer, PathwayActions } from "features/hooks/navigatorReducer";

interface IGoBackButtonProps {
  dispatch: Dispatch<PathwayActions>;
  settings?: INodeViewSettings;
  route: NodePointer[];
  pathwayId: string;
  isEndNode: boolean;
}

const GoBackButton = ({
  dispatch,
  settings,
  route,
  pathwayId,
  isEndNode,
}: IGoBackButtonProps) => {
  const onGoBack = () => {
    //Order is important here

    // We are allow to navigate but have not previous items. So call exit
    if (!Boolean(settings?.disableNavigation) && route.length === 0) {
      if (settings?.onExitPathway) {
        settings.onExitPathway();
      }
    }

    // Actually go back to previous node because we have route items
    if (!Boolean(settings?.disableNavigation) && route.length > 0) {
      dispatch({ type: "MOVE_TO_PREVIOUS_NODE", payload: {} });
    }
  };

  return (
    <div data-testid="go-back-to-previous-node-button">
      {Boolean(route.length) && (
        <Button
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid white",
            textTransform: "none",
            width: "100%",
            backgroundColor: "#767676",
          }}
          color="secondary"
          variant="contained"
          onClick={() => onGoBack()}
          startIcon={<ArrowCircleLeftIcon />}
        >
          Go back to previous Node
        </Button>
      )}

      {
        Boolean(settings?.onExitPathway) && (
          // (!Boolean(route.length) || isEndNode) && (
          <Button
            size="large"
            onClick={() =>
              !Boolean(settings?.disableNavigation) &&
              settings?.onExitPathway &&
              settings.onExitPathway()
            }
            style={{
              display: "flex",
              justifyContent: "center",
              borderBottom: "1px solid white",
              textTransform: "none",
              width: "100%",
            }}
            variant="contained"
          >
            <Icon name="reply" />
            Exit Pathway
          </Button>
        )
        //)
      }
    </div>
  );
};

export default GoBackButton;
