import { Box, IconButton, Paper, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// App
import childrenWithProps from "../react/ChildrenWithProps";
import { forwardRef } from "react";

interface IPageContainerProps {
  title: string;
  disableBack?: boolean;
  startActionBar?: React.ReactNode;
  actionBar?: React.ReactNode;
  subBar?: React.ReactNode;
  pageContainerRef?: React.Ref<HTMLDivElement>;
  children: React.ReactNode;
}

const PageContainer = forwardRef<
  HTMLDivElement | undefined,
  IPageContainerProps
>(
  (
    {
      title,
      disableBack,
      startActionBar,
      actionBar,
      subBar,
      children,
      pageContainerRef,
      ...rest
    }: IPageContainerProps,
    ref
  ) => {
    const history = useHistory();
    const theme = useTheme();

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: theme.isMobileView ? "auto" : "calc(100vh - 76px - 37px)",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: 0,
            padding: "12px 8px",
          }}
        >
          {(disableBack === undefined || disableBack === false) && (
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackIcon
                fontSize={theme.isMobileView ? "medium" : "large"}
              />
            </IconButton>
          )}
          <span style={{ fontSize: "large", fontWeight: "bolder" }}>
            {title}
          </span>
          <>{startActionBar}</>
          <div style={{ flex: 1 }}></div>
          <>{actionBar}</>
        </Paper>
        {subBar}
        <Box
          ref={ref}
          sx={{
            mx: 0,
            my: 0,
            p: theme.isMobileView ? 0 : 0,
            overflowY: theme.isMobileView ? "hidden" : "auto",
          }}
        >
          {childrenWithProps(children, rest)}
        </Box>
      </div>
    );
  }
);

export default PageContainer;
