const getReleaseDate = () => {
  const version = process.env.REACT_APP_VERSION;

  if (version === undefined) {
    return "";
  }

  const firstPart = Number(version.split(".")[0]);
  const secondPart = version.split(".")[1];

  return `${secondPart} ${2020 + firstPart}`;
};

export default getReleaseDate;
