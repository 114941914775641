import { useCrossCoverDispatch, useCrossCoverSelector } from "app/store/hooks";

const useTenants = () => {
  const tenantsState = useCrossCoverSelector((state) => state.tenants);
  const dispatch = useCrossCoverDispatch();

  if (tenantsState.activeTenant === undefined) {
    throw Error("useTenants: Tenant not set or invalid tenant");
  }

  const onChangeTenant = (tenantId: string) => {
    const tenant = tenantsState.tenants?.find((x) => x.id === tenantId);
    if (!tenant) {
      throw Error(`Tenant: ${tenantId} not Found`);
    }
    dispatch({ type: "UPDATE_ACTIVE_TENANT", payload: { tenant: tenant } });
  };

  return {
    activeTenant: tenantsState.activeTenant,
    setActiveTenant: onChangeTenant,
    userTenants: tenantsState.userTenants,
    tenants: tenantsState.tenants ?? [],
    tenantSettings: tenantsState.tenantSettings,
    tenantConfiguration: tenantsState.tenantConfiguration,
  };
};

export default useTenants;
