import { ModuleContext } from "app/providers/ModuleProvider";

import { useContext } from "react";

const useReferencePathways = () => {
  const { pathways } = useContext(ModuleContext);

  return {
    referencePathways: pathways,
  };
};

export default useReferencePathways;
