import React, { useCallback, useEffect, useRef, useState } from "react";
import { getDb } from "app/data/DbProvider";
import useUser from "features/hooks/useUser";
import usePathwayBuilder from "features/pathwaybuilder/PathwayBuilder/usePathwayBuilder";

import {
  Alert,
  // Backdrop,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
} from "@mui/material";
import { Button, Form, Header, Icon, Popup } from "semantic-ui-react";
// import NodeViewPopUp from "./NodeViewPopUp";
import { getPathwayPath } from "features/pathwaybuilder/utils/editorConverters";
import {
  getCollectionOrderName,
  getOrderedNodes,
  // isDynamicNode,
} from "features/pathwaybuilder/utils/pathwayHelperV2";
import {
  IDynamicNode,
  ILinkButtonData,
  INode,
  INodeAction,
} from "../../../../../types/Nodes";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import { ITenantPathway } from "../../../../../types/Pathway";
// import NodeViewPopUp from "../NodeEditorComponents/NodeViewPopUp";
// import NodeSelectorPopUp from "../NodeEditorComponents/NodeSelectorPopUp";

interface INodeActionEditorProps {
  nodes?: (INode | IDynamicNode)[];
  nodeActions: (INodeAction | ILinkButtonData)[];
  locked?: boolean;
  dispatch?: React.Dispatch<DynamicNodeEditorActions>;
  onLinkTypeChange: (
    isPathwayToPathwayLink: boolean,
    linkIndex: number
  ) => void;
  pathwayOptions: IPathwayOptions[];
  onChangePathwayLink: (pathwayId: string, linkIndex: number) => void;
  onChangeNodeLink: (nodeId: string, linkIndex: number) => void;
  currentPathway: string;
}

interface IPathwayNodeOptions {
  text: string;
  value: string;
  isStartNode: boolean;
  node?: IDynamicNode | INode;
}

interface IPathwayOptions {
  text: string;
  value: string;
}

interface IActionEditor {
  nodeOptions: IPathwayNodeOptions[];
  nodeAction: INodeAction | ILinkButtonData;
  pathwayNodes: Record<string, IPathwayNodeOptions[]>;
  pathwayOptions: IPathwayOptions[];
  locked?: boolean;
  onLoadPathwayNodes: (pathwayId: string) => void;
  dispatch?: React.Dispatch<DynamicNodeEditorActions>;
  onLinkTypeChange: (
    isPathwayToPathwayLink: boolean,
    linkIndex: number
  ) => void;
  index: number;
  onChangePathwayLink: (pathwayId: string, linkIndex: number) => void;
  onChangeNodeLink: (nodeId: string, linkIndex: number) => void;
  currentPathway: string;
}

const ActionEditor = ({
  nodeOptions,
  nodeAction,
  pathwayNodes,
  pathwayOptions,
  locked,
  onLinkTypeChange,
  index,
  onChangePathwayLink,
  onChangeNodeLink,
  currentPathway,
}: IActionEditor) => {
  const [isPathwayToPathwayLink, setIsPathwayToPathwayLink] = useState(
    nodeAction.isPathwayToPathwayLink
  );

  // const [nodeActionType, setNodeActionType] = useState<
  //   "NEW_NODE" | "EXISTING_NODE"
  // >("EXISTING_NODE");

  const isCustomNodeSet =
    nodeAction.pathwayId !== "" &&
    nodeAction.nodeId !== "" &&
    nodeAction.isPathwayToPathwayLink;

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const [startNodeToggle, setStartNodeToggle] = useState(isCustomNodeSet);

  // const [popupNode, setPopupNode] = useState<IDynamicNode>();
  // const [popupNodeOpen, setPopupNodeOpen] = useState(false);

  useEffect(() => {
    setIsPathwayToPathwayLink(nodeAction.isPathwayToPathwayLink);
  }, [nodeAction.isPathwayToPathwayLink]);

  //   const onLinkTextChanged = (linkId: string, linkText: string) => {
  //     onNodeActionUpdated(linkId, { ...nodeAction, linkText: linkText });
  //   };

  // const onNodeSelected = (linkId: string, nodeId: string) => {
  //   onNodeActionUpdated(linkId, { ...nodeAction, nodeId: nodeId });
  // };

  //   const onPathwaySelected = (linkId: string, pathwayId: string) => {
  //     onNodeActionUpdated(linkId, {
  //       ...nodeAction,
  //       pathwayId: pathwayId,
  //       nodeId: "",
  //       isPathwayToPathwayLink: true,
  //     });
  //   };

  // const onClosePopUpNode = () => {
  //   setPopupNode(undefined);
  //   setPopupNodeOpen(false);
  // };

  return (
    <Box
      sx={{
        bgcolor: "grey.200",
        p: 2,
        my: 2,
        borderRadius: 1,
        border: "1px solid red",
        borderColor: "grey.500",
        color: "black",
      }}
      ref={anchorRef}
    >
      <Header sub>Link Name</Header>
      <Popup
        trigger={
          <Form.Group>
            <Form.Input
              //   onChange={(e) => onLinkTextChanged(nodeAction.id, e.target.value)}
              style={{ width: "100%", marginBottom: "16px" }}
              value={nodeAction.linkText}
              placeholder="Insert a name for the Link"
              disabled={locked}
            />
            {!locked && (
              <Button
                icon
                color="red"
                width={1}
                // onClick={() => onDeleteLink(nodeAction.id)}
              >
                <Icon name="trash alternate" />
              </Button>
            )}
          </Form.Group>
        }
        content="Add the name of the link here. This can be an answer to a question for example. This is the information that appears on a users decision summary log as they navigate your pathway, so its best not to leave unintelligible answers like 'yes' or 'no'. The following words/phrases will not appear on a users decision log summary if used: 'Continue', 'Continue to full pathway', 'Yes', 'No'"
        wide
      />

      <Form.Group>
        <Stack direction="row" alignItems="center">
          <Form.Field>
            <label style={{ marginRight: "8px" }}>Link Type:</label>
            <Button.Group size="mini">
              <Button
                toggle
                style={
                  !isPathwayToPathwayLink
                    ? { backgroundColor: "#00B5AD", color: "white" }
                    : {}
                }
                onClick={() => {
                  onLinkTypeChange(false, index);
                }}
                type="button"
              >
                Node
              </Button>
              <Button.Or />
              <Button
                toggle
                style={
                  isPathwayToPathwayLink
                    ? { backgroundColor: "#00B5AD", color: "white" }
                    : {}
                }
                onClick={() => {
                  setStartNodeToggle(false);
                  onLinkTypeChange(true, index);
                }}
                type="button"
              >
                Pathway
              </Button>
            </Button.Group>
          </Form.Field>

          {!isPathwayToPathwayLink && (
            <FormControl fullWidth>
              <InputLabel>Link To A Node In This Pathway</InputLabel>
              <Select
                // onOpen={() => {
                //   setPopupNodeOpen(true);
                // }}
                // onClose={() => {
                //   setPopupNodeOpen(false);
                // }}
                value={nodeAction.nodeId}
                label="Link To A Node In This Pathway"
                onChange={(e) => {
                  // onNodeSelected(nodeAction.id, e.target.value);
                  onChangeNodeLink(e.target.value, index);
                }}
                sx={{
                  bgcolor: "#fff",
                  "& .MuiSelect-select": { display: "flex" },
                }}
              >
                {nodeOptions.map((item, index) => (
                  <MenuItem
                    key={index}
                    style={{ display: "flex" }}
                    value={item.value}
                    // onMouseEnter={(e) => {
                    //   item.node &&
                    //     isDynamicNode(item.node) &&
                    //     // setPopupNode(item.node);
                    // }}
                  >
                    <div>{item.text}</div>
                    <div style={{ flex: 1 }}></div>
                    {item.isStartNode && (
                      <Chip label="Start node" color="success" size="small" />
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            // <Box sx={{ my: 1 }}>
            //   <NodeSelectorPopUp
            //     addIWillConnectLater={true}
            //     linkedNodeId={nodeAction.nodeId}
            //     nodeOptions={nodeOptions}
            //     pathway={
            //       pathwayOptions.find(
            //         (pathway) => pathway.value === nodeAction.pathwayId
            //       )?.text ?? ""
            //     }
            //     onSelect={(nodeId) => onChangeNodeLink(nodeId, index)}
            //   />
            // </Box>
          )}

          {isPathwayToPathwayLink && (
            <FormControl fullWidth>
              <InputLabel>Link To Another Pathway</InputLabel>
              <Select
                autoWidth
                onChange={(e, data) => {
                  setStartNodeToggle(false);
                  onChangePathwayLink(e.target.value, index);
                }}
                value={nodeAction.pathwayId}
                label="Link To Another Pathway"
                sx={{
                  bgcolor: "#fff",
                  "& .MuiSelect-select": { display: "flex" },
                }}
              >
                {pathwayOptions
                  .filter((pathway) => pathway.value !== currentPathway)
                  .map((item, index) => (
                    <MenuItem
                      key={index}
                      style={{ display: "flex" }}
                      value={item.value}
                      onMouseEnter={(e) => {
                        // setPopupNode(item.node);
                      }}
                    >
                      <div>{item.text}</div>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </Form.Group>
      {Array.isArray(pathwayNodes[nodeAction.pathwayId]) && (
        <>
          <Alert severity="warning" style={{ marginTop: "10px" }}>
            Only specify a custom target node if you do not want to use the
            start node of the target pathway.
          </Alert>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  setStartNodeToggle((prev) => !prev);
                }}
                checked={startNodeToggle}
              />
            }
            label="Use custom target node"
          />
          {startNodeToggle && (
            <FormControl fullWidth>
              <InputLabel>Custom Node</InputLabel>
              <Select
                autoWidth
                // onOpen={() => {
                //   setPopupNodeOpen(true);
                // }}
                // onClose={() => {
                //   setPopupNodeOpen(false);
                // }}
                value={nodeAction.nodeId}
                label="Custom Node"
                onChange={(e) =>
                  // onNodeSelected(nodeAction.id, e.target.value)
                  onChangeNodeLink(e.target.value, index)
                }
                sx={{
                  bgcolor: "#fff",
                  "& .MuiSelect-select": { display: "flex" },
                }}
              >
                {pathwayNodes[nodeAction.pathwayId]
                  .concat()
                  .sort((a, b) =>
                    a.text.localeCompare(b.text, "en-GB", { numeric: true })
                  )
                  .map((item, index) => (
                    <MenuItem
                      key={index}
                      style={{ display: "flex" }}
                      value={item.value}
                      // onMouseEnter={(e) => {
                      //   item.node &&
                      //     isDynamicNode(item.node) &&
                      //     setPopupNode(item.node);
                      // }}
                    >
                      <div>{item.text}</div>
                      <div style={{ flex: 1 }}></div>
                      {item.isStartNode && (
                        <Chip label="Start node" color="success" size="small" />
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            // <Box sx={{ my: 1 }}>
            //   <NodeSelectorPopUp
            //     linkedNodeId={nodeAction.nodeId}
            //     nodeOptions={pathwayNodes[nodeAction.pathwayId]
            //       .concat()
            //       .sort((a, b) =>
            //         a.text.localeCompare(b.text, "en-GB", { numeric: true })
            //       )}
            //     pathway={
            //       pathwayOptions.find(
            //         (pathway) => pathway.value === nodeAction.pathwayId
            //       )?.text ?? ""
            //     }
            //     onSelect={(nodeId) => onChangeNodeLink(nodeId, index)}
            //   />
            // </Box>
          )}
        </>
      )}
      {/* {popupNodeOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: 2 }}
          open={popupNodeOpen}
          onClick={() => onClosePopUpNode()}
        ></Backdrop>
      )} */}
      {/* <NodeViewPopUp
        anchorEl={anchorRef.current}
        node={popupNode}
        open={popupNodeOpen}
        pathway={
          pathwayOptions.find(
            (pathway) => pathway.value === nodeAction.pathwayId
          )?.text ?? ""
        }
      /> */}
    </Box>
  );
};

const NodeActionsEditor = ({
  nodes,
  locked,
  nodeActions,
  dispatch,
  onLinkTypeChange,
  pathwayOptions,
  onChangePathwayLink,
  onChangeNodeLink,
  currentPathway,
}: INodeActionEditorProps) => {
  const { activeTenantId } = useUser();
  const { state } = usePathwayBuilder();
  const [nodeOptions, setNodeOptions] = useState<IPathwayNodeOptions[]>([]);

  //const [nodeActions, setNodeAction] = useState<INodeAction[]>([]);

  const [pathwayNodes, setPathwayNodes] = useState<
    Record<string, IPathwayNodeOptions[]>
  >({});

  //Generate a list of node for the dropdown
  useEffect(() => {
    if (nodes) {
      const sortedNodes: IPathwayNodeOptions[] = nodes
        .map((node) => ({
          text: node.title,
          value: node.id || "",
          isStartNode: false,
          node: node,
        }))
        .concat()
        .sort((a, b) =>
          a.text.localeCompare(b.text, "en-GB", { numeric: true })
        );
      sortedNodes.unshift({
        text: "I will connect later",
        value: "I will connect later",
        isStartNode: false,
      });
      setNodeOptions(sortedNodes);
    }
  }, [nodes]);

  //Generate a list of pathways for the dropdown
  //   useEffect(() => {
  //     const pathways: IPathwayOptions[] = [];

  //     referencePathways.forEach((pathway) => {
  //       pathways.push({
  //         text: pathway.name,
  //         value: pathway.name,
  //       });
  //     });

  //     setOptionPathways(pathways);
  //   }, [referencePathways]);

  //   const onAddLink = () => {
  //     nodeActions.push({
  //       pathwayId: "",
  //       nodeId: EMPTY_LINK_TEXT,
  //       linkText: "",
  //       isPathwayToPathwayLink: false,
  //       id: "",
  //     });

  //     dispatch({
  //       type: "UPDATE_LINKS",
  //       payload: nodeActions,
  //     });
  //   };

  const onLoadPathwayNodes = useCallback(
    async (pathwayId: string) => {
      const db = getDb();
      const nodes = await db.getCollection<INode>(
        getPathwayPath(activeTenantId, pathwayId, state.collection)
      );
      const pathway = (await db.getDoc<ITenantPathway>(
        getPathwayPath(activeTenantId),
        pathwayId
      )) as unknown as Record<string, string[]>;
      const orderName = getCollectionOrderName(state.collection);
      const orderedNodes = getOrderedNodes(pathway[orderName], nodes);
      let options: IPathwayNodeOptions[] = orderedNodes.map((x) => ({
        value: x.id ?? "",
        text: x.title,
        isStartNode: false,
        node: x,
      }));

      if (options.length > 0) {
        options[0].isStartNode = true;
      }

      if (options.length === 0) {
        options = [{ text: "No nodes found", value: "", isStartNode: false }];
      }

      setPathwayNodes((prev) => ({ ...prev, [pathwayId]: options }));
    },
    [activeTenantId, state.collection]
  );

  useEffect(() => {
    nodeActions.forEach((action) => {
      if (action.isPathwayToPathwayLink) {
        onLoadPathwayNodes(action.pathwayId);
      }
    });
  }, [nodeActions, onLoadPathwayNodes]);

  // useEffect(() => {
  //   nodeActions.forEach((action, index) => {
  //     if (action.isPathwayToPathwayLink && pathwayNodes[action.pathwayId]) {
  //       const startNodeId = pathwayNodes[action.pathwayId].find(
  //         (node) => node.isStartNode
  //       )?.value;
  //       if (startNodeId && action.nodeId === "") {
  //         onChangeNodeLink(startNodeId, index);
  //       }
  //     }
  //   });
  // }, [pathwayNodes, nodeActions, onChangeNodeLink]);

  return (
    <>
      {!locked && <Box>Add links to other nodes in the pathway</Box>}

      {nodeActions.map((action, index) => (
        <ActionEditor
          key={index}
          index={index}
          nodeAction={action}
          nodeOptions={nodeOptions}
          pathwayOptions={pathwayOptions}
          pathwayNodes={pathwayNodes}
          locked={locked}
          onLoadPathwayNodes={onLoadPathwayNodes}
          dispatch={dispatch}
          onLinkTypeChange={onLinkTypeChange}
          onChangePathwayLink={onChangePathwayLink}
          onChangeNodeLink={onChangeNodeLink}
          currentPathway={currentPathway}
        />
      ))}

      {/* {!locked && (
        <Button fluid color="green" onClick={onAddLink}>
          Add Link
        </Button>
      )} */}
    </>
  );
};

export default NodeActionsEditor;
