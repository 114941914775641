// import PageContainer from "app/layout/PageContainer";
import { ICrossCoverPatient } from "../../../functions/src/http/nhsdigital/pds";

import { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import functions from "../../app/common/util/functions";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
} from "@mui/material";

interface IPatientLookupProps {
  onPDSSkipped: () => void;
  onPDSConfirmed: (patient: ICrossCoverPatient) => void;
  externalNHSNumber?: string;
}

const PatientLookup = ({
  externalNHSNumber,
  onPDSSkipped,
  onPDSConfirmed,
}: IPatientLookupProps) => {
  const [NHSNumber, setNHSNumber] = useState(externalNHSNumber || "");
  const [patient, setPatient] = useState<ICrossCoverPatient>();
  const [error, setError] = useState<string>("");

  const onPDSLookup = async () => {
    if (NHSNumber) {
      console.log("Searhcing", NHSNumber);
      const lookupResult = await functions.pdsLookup({
        NHSNumber: NHSNumber,
      });
      if (lookupResult.resultType === "VALIDATION_ERROR") {
        setError(lookupResult.message);
      }
      if (lookupResult.resultType === "OK") {
        setPatient(lookupResult.patientData);
      }
    }
  };

  useEffect(() => {
    setNHSNumber("9449305552");
  }, []);

  return (
    <>
      <TextField
        label="NHS Number"
        onChange={(e: any) => setNHSNumber(e.target.value)}
        value={NHSNumber}
        error={error !== ""}
        helperText={error}
      />

      <Button sx={{ color: "pallet.primary" }} onClick={onPDSLookup}>
        Find
      </Button>
      <Button onClick={onPDSSkipped}>Skip</Button>

      {patient && (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Prefix</TableCell>
              <TableCell>Given Names</TableCell>
              <TableCell>Family Name</TableCell>
              <TableCell>Date Of Birth</TableCell>
              <TableCell>Gender</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={patient?.prefix}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {patient?.prefix}
              </TableCell>
              <TableCell>{patient?.givenName.join(" ")}</TableCell>
              <TableCell>{patient?.familyName}</TableCell>
              <TableCell>{patient?.birthDate}</TableCell>
              <TableCell>{patient?.gender}</TableCell>
              <TableCell>
                <Button
                  sx={{ color: "pallet.primary" }}
                  onClick={() => onPDSConfirmed(patient as ICrossCoverPatient)}
                >
                  Select
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default PatientLookup;
