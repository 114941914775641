import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { toastr } from "react-redux-toastr";
import { Button, Form, Modal, Message, Icon, Popup } from "semantic-ui-react";

import ConfirmationModal from "app/components/ConfirmationModal";
import { IFolder } from "../../../types/DocumentLibrary";
import { getDirectChildren } from "./utils/DocumentLibraryHelper";
import useErrorMessages from "./utils/useErrorMessage";
import useUser from "features/hooks/useUser";

interface FolderActionsProps {
  folder: IFolder;
  folders: IFolder[];
  folderPath: string;
}

const FolderActions = ({ folder, folders, folderPath }: FolderActionsProps) => {
  const { activeTenantId, profile } = useUser();

  const db = firebase.firestore();

  if (activeTenantId === undefined) {
    throw Error("FolderActions: tenantId is undefined");
  }

  const [openEdit, setOpenEdit] = useState(false);
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const [folderEdits, setFolderEdits] = useState({
    folderTitle: "",
  });

  const { errorMessages } = useErrorMessages();

  const onClickEdit = (folder: IFolder) => {
    setFolderEdits({
      folderTitle: folder.folderTitle,
    });
  };

  const onSave = async (folder: IFolder, e: any) => {
    e.preventDefault();
    setFormError("");

    const folderTitle = folderEdits.folderTitle.trim();

    if (folder.folderTitle === folderTitle) {
      onCancel();
      return;
    }

    const nameCollision = getDirectChildren(folders, folderPath).filter(
      (folder: IFolder) => {
        return folder.folderTitle === folderTitle;
      }
    );

    if (nameCollision.length >= 1) {
      setFormError(errorMessages.matchingTitle);
      return;
    }

    if (folderTitle === "") {
      setFormError(errorMessages.noFolderTitle);
      return;
    }

    for (const char of folderTitle) {
      if (char === "/" || char === "\\") {
        setFormError(errorMessages.incorrectCharactersTitle);
        return;
      }
    }

    setLoading(true);

    const updatedFolder = {
      [folder.id as string]: {
        ...folder,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: profile.email,
        folderTitle: folderEdits.folderTitle.trim(),
      },
    };

    await db
      .collection(activeTenantId)
      .doc("Files")
      .set({ folders: updatedFolder }, { merge: true });

    toastr.success(
      "Changes Saved",
      `The changes to ${folderEdits.folderTitle}, have been saved.`
    );

    setLoading(false);
    setOpenEdit(false);
  };

  const onCancel = () => {
    setFormError("");
    setFolderEdits({
      folderTitle: "",
    });
    setOpenEdit(false);
  };

  const onDelete = async (folder: IFolder, folders: IFolder[]) => {
    const currentFolderPath = folder.folderPath;

    // delete descendent files
    const snapshots = await db
      .collection(`${activeTenantId}/Files/files`)
      .where("status", "==", "ACTIVE")
      .where("folderPath", ">=", currentFolderPath)
      .where("folderPath", "<=", currentFolderPath + "\uf8ff")
      .get();

    snapshots.forEach((doc) => {
      doc.ref.update({
        status: "DELETED",
        deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        deletedBy: profile.email,
      });
    });

    const decendentFolders = folders.filter((folder: IFolder) => {
      return folder.folderPath.startsWith(currentFolderPath);
    });

    // delete descendent folders
    await decendentFolders.forEach((folder) => {
      const updatedFolder = {
        [folder.id as string]: {
          ...folder,
          status: "DELETED",
          deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
          deletedBy: profile.email,
        },
      };

      db.collection(activeTenantId)
        .doc("Files")
        .set({ folders: updatedFolder }, { merge: true });
    });

    toastr.info("Folder Deleted", "Your Folder has been deleted.");
  };

  return (
    <>
      <Button.Group size="tiny">
        <Modal
          closeIcon
          onClose={() => setOpenEdit(false)}
          onOpen={() => setOpenEdit(true)}
          open={openEdit}
          trigger={
            <Button
              onClick={(e) => {
                onClickEdit(folder);
              }}
              icon
              size="mini"
              color="teal"
            >
              <Popup
                style={{ marginBottom: 20 }}
                position="top left"
                content="Edit"
                trigger={<Icon name="write" />}
              />
            </Button>
          }
        >
          <Modal.Header>Edit Folder</Modal.Header>
          <Modal.Content>
            <Form error>
              {formError && <Message error content={formError} />}
              <Form.Input
                label="Change the folder title"
                fluid
                placeholder={folder.folderTitle}
                value={folderEdits.folderTitle}
                onChange={(e) =>
                  setFolderEdits({
                    ...folderEdits,
                    folderTitle: e.target.value,
                  })
                }
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button type="submit" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              color="green"
              content="Save"
              icon="checkmark"
              labelPosition="right"
              type="submit"
              loading={loading}
              disabled={loading}
              onClick={(e) => onSave(folder, e)}
            />
          </Modal.Actions>
        </Modal>

        <ConfirmationModal
          title={`Delete ${folder.folderTitle}`}
          message={`Please confirm that you want to delete this folder by typing its name: ${folder.folderTitle}`}
          label={`Title: ${folder.folderTitle}`}
          confirmText={folder.folderTitle}
          buttonText="Delete"
          buttonIcon="trash alternate outline"
          buttonColor={"red"}
          triggerHelperText={"Delete"}
          popupPosition={"top right"}
          onConfirm={() => onDelete(folder, folders)}
          disabled={false}
        />
      </Button.Group>
    </>
  );
};

export default FolderActions;
