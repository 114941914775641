import { FieldValue, Timestamp } from "firebase/firestore";
export const DocumentTimestampToMilliseconds = (
  value: number | Timestamp | undefined | FieldValue | Date | null
): number => {
  if (value === undefined || value === null) {
    return 1;
  }

  if (value instanceof Timestamp) {
    return value.toDate().getTime();
  }

  if (value instanceof Date) {
    return value.getTime();
  }
  if (value instanceof FieldValue || value.hasOwnProperty("_delegate")) {
    //If is most likely a timestamp that has not yet been written to firestore
    return Date.now();
  }

  //It is a timestamp but we have deep clone the object and the time stamps class
  //has not been recreated
  if (value.hasOwnProperty("_seconds")) {
    return new Date((value as any)._seconds * 1000).getTime();
  }
  return value;
};
