import Alert from "@mui/material/Alert";
import { isConnectedNode } from "features/pathwaybuilder/utils/pathwayHelperV2";
import { UnregisterCallback } from "history";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalActions, ModalHeader } from "semantic-ui-react";
import { IDynamicNode } from "../../../types/Nodes";

interface INavigationPromptBlocker {
  state: IDynamicNode;
  shouldBlockOnNavigation: boolean;
  handleSaveNode: (value?: boolean) => Promise<void>;
  unblockRef: React.MutableRefObject<UnregisterCallback | undefined>;
}

export const UnsavedChangesPrompt = ({
  state,
  shouldBlockOnNavigation: shouldWarnOnNavigation,
  handleSaveNode,
  unblockRef,
}: INavigationPromptBlocker) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (shouldWarnOnNavigation) {
      unblockRef.current = history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    }

    return () => {
      if (unblockRef.current) {
        unblockRef.current();
      }
    };
  }, [history, shouldWarnOnNavigation, unblockRef]);

  const handleSaveChangesAndLeave = async () => {
    const isLeavingNodeEditor = true;

    await handleSaveNode(isLeavingNodeEditor);
    handleLeaveWithoutSaving();
  };

  const handleLeaveWithoutSaving = () => {
    if (unblockRef.current) {
      unblockRef.current();
    }

    history.push(currentPath);
    setShowPrompt(false);
  };

  const handleCancel = () => {
    setShowPrompt(false);
  };

  const hasManagementPlan = Object.values(state.blocks).find(
    (block) => block.blockOptions.addToManagementPlan
  );

  const needsManageMentPlanTexblock =
    isConnectedNode(state) &&
    state.nodeProperties.isEndNodeFunctionalityEnabled &&
    !hasManagementPlan;

  return (
    <Modal open={showPrompt}>
      <ModalHeader>
        There are unsaved changes in the Node, are you sure you want to leave?
      </ModalHeader>
      {state.title === "" && (
        <Alert severity="error" sx={{ m: 2 }}>
          Your Node does not have a title so it cannot be saved. Please provide
          a title for your Node if you wish to save it.
        </Alert>
      )}
      {needsManageMentPlanTexblock && (
        <Alert severity="error" sx={{ m: 2 }}>
          Your Node is marked as an End Node and requires a Text Block for the
          Management Plan. Please provide a Text Block for your Node if you wish
          to save it.
        </Alert>
      )}
      <ModalActions>
        <Button
          style={{ backgroundColor: "#009900", color: "#FFF" }}
          onClick={handleSaveChangesAndLeave}
          disabled={state.title === "" || needsManageMentPlanTexblock}
        >
          Save Changes & Leave
        </Button>
        <Button
          style={{ backgroundColor: "#C62828", color: "#FFF" }}
          onClick={handleLeaveWithoutSaving}
        >
          Leave Without Saving
        </Button>
        <Button
          style={{ backgroundColor: "#767676", color: "#FFF" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  );
};
export default UnsavedChangesPrompt;
