import {
  NodeBlock,
  NodeBlockData,
  IBlockRules,
  IBlockOptions,
  BlockTypes,
  IDynamicNode,
  IChoicesBoxData,
  IRichTextAreaData,
  IChoicesBlock,
  IShowHideData,
  IDisplayVariablesData,
  IButtonData,
  ILinkButtonData,
  IEmptyBlockData,
  IImageBlockData,
  IVideoBlockData,
  IFileBlockData,
  ISTarTBackBlock,
} from "../../../types/Nodes";
import React, { useEffect, useState, Dispatch, useRef } from "react";
import { v4 as uuid4 } from "uuid";
import { Box, Grid, SxProps, Tab, Tabs, Theme, Tooltip } from "@mui/material";
import {
  BeforeCapture,
  DragDropContext,
  Draggable,
  DragStart,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import NodeViewer from "./components/NodeEditorComponents/NodeViewer";

import NodeEditorPanel from "./NodeEditorPanelV2";
import {
  cleanRule,
  createNewBlock,
  getManagementPlanText,
  listBlocksConnected,
  updateUidsOnBlockData,
} from "./NodeEditorUtils";
import NodeEditorHeader from "../NodeEditorV2/components/NodeEditorHeader";
import NodeEditorSectionHeader from "../NodeEditorV2/components/NodeEditorComponents/NodeEditorSectionHeader";

import BlockElement, {
  //  BlocksEditorPanel,
  blockTypes,
} from "./components/EditorPannelComponents/BlockElement";
import DecisionSummary from "./components/DecisionSummaryV2";
import WidgetTemplatesPanel from "./components/EditorPannelComponents/WidgetTemplatesPanel";
import {
  DynamicNodeEditorActions,
  DynamicNodeEditorState,
} from "./dynamicNodeEditorReducer";
import {
  INodeContext,
  NodeDecisionAction,
  NodePointer,
  PathwayActions,
} from "features/hooks/navigatorReducer";
import UnsavedChangesPrompt from "./UnsavedChangesPrompt";
import { UnregisterCallback } from "history";
import {
  isChoicesBlock,
  isQuestionBlock,
  isRichTextAreaBlock,
  isStartBackBlock,
} from "features/pathwaybuilder/utils/pathwayHelperV2";

export interface IBlockRegistration {
  blockId: string;
  blockType: string;
  block?: NodeBlock;
}

type NodeContextPropertyType = "number" | "string" | "boolean";

export interface INodeContextProperty {
  value: string;
  type: NodeContextPropertyType;
}

interface INodeEditorProps {
  editorState: DynamicNodeEditorState;
  pathwayNodes: IDynamicNode[];
  tenantId: string;
  pathwayId: string;
  onSave: (value?: boolean) => void;
  pathwayNavigatorDispatch: Dispatch<PathwayActions>;
  editorDispatch: Dispatch<DynamicNodeEditorActions>;
  nodeContext: INodeContext;
  decisionSummary: NodeDecisionAction[];
  viewType: "edit" | "view";
  route: NodePointer[];
  setViewType: (viewType: "edit" | "view") => void;
  hideSaveTemplate?: boolean;
  showHeader?: boolean;
  showPreviewEditToggle?: boolean;
  showDecisionSummary?: boolean;
  showNodeInfo?: boolean;
  showNodeEditorSectionHeader?: boolean;
  containerStyles?: SxProps<Theme>;
}

export enum editorMode {
  BlockEdit = "BlockEdit",
  TemplateEdit = "TemplateEdit",
  VariableEdit = "VariableEdit",
}

const NodeEditor = ({
  editorState,
  pathwayNodes,
  tenantId,
  pathwayId,
  onSave,
  pathwayNavigatorDispatch,
  editorDispatch,
  nodeContext,
  decisionSummary,
  viewType,
  route,
  setViewType,
  hideSaveTemplate,
  showHeader = true,
  showPreviewEditToggle,
  showDecisionSummary = true,
  showNodeInfo,
  showNodeEditorSectionHeader = true,
  containerStyles,
}: INodeEditorProps) => {
  const [blockDraggingId, setBlockDraggingId] = useState<string>("");

  // const [placeholderProps, setPlaceholderProps] = useState<any>({});
  const [showDroppingPositions, setShowDroppingPositions] =
    useState<boolean>(false);
  const [currentEditorMode, setCurrentEditorMode] = useState<editorMode>(
    editorMode.BlockEdit
  );
  const [createTemplateChecked, setCreateTemplateChecked] = useState(false);
  const [selectedBlockIds, setSelectedBlockIds] = useState<Set<string>>(
    new Set()
  );

  const nodeViewerGridRef = useRef<HTMLDivElement>(null);
  const nodeViewerRef = useRef<HTMLDivElement>(null);
  const unblockRef = useRef<UnregisterCallback>();

  const [scrollToBottom, setScrollToBottom] = useState<boolean>(false);

  const onEdit = (block: NodeBlock | undefined) => {
    if (block) {
      editorDispatch({ type: "EDIT_BLOCK_ON", payload: block });
    }
    if (!block) {
      editorDispatch({ type: "EDIT_BLOCK_OFF", payload: {} });
    }
  };

  const changeEditorMode = (state: editorMode) => {
    setCurrentEditorMode(state);
  };

  const onInnerSave = async (isLeavingNodeEditor?: boolean) => {
    // for (const [, fnArray] of Object.entries<Function[]>(register)) {
    //   for (const fn of fnArray) {
    //     fn(editorState.node.blocks);
    //   }
    // }
    await onSave(isLeavingNodeEditor);
  };

  const onAddBlock = (
    blockType: BlockTypes,
    data: NodeBlockData,
    row?: number,
    column?: number,
    blockRules?: IBlockRules,
    blockName?: string,
    blockOptions?: IBlockOptions,
    blockId?: string
  ) => {
    // Select all existing blocks in the row for the template excluding any block being replaced
    if (createTemplateChecked && column !== undefined) {
      const allBlocks = Object.values(editorState.node.blocks);
      const rowBlocks = allBlocks.filter((block) => {
        return block.position.row === row && block.position.column !== column;
      });
      const rowBlockIds = rowBlocks.map((block) => block.blockId);
      const blockIds = new Set([...rowBlockIds]);
      selectBlocks(blockIds);
    }

    if (column === undefined) {
      editorDispatch({
        type: "UPDATE_ROWS_ORDER",
        payload: {
          sourceRowIndex: row || 0,
          type: "addRow",
        },
      });
    }

    if (blockType === "twoColumns" || blockType === "threeColumns") {
      const numberPlaceholders = blockType === "twoColumns" ? 2 : 3;

      for (let i = 0; i < numberPlaceholders; i++) {
        const action: DynamicNodeEditorActions = {
          type: "ADD_BLOCK",
          payload: {
            ...createNewBlock<IEmptyBlockData>({
              blockType: "placeholder",
              column: i,
              row,
              data: {},
            }),
          },
        };
        editorDispatch(action);
        selectBlocks(new Set([action.payload.blockId]));
      }
      return;
    }

    if (row === -1) {
      row =
        Object.values(editorState.node.blocks).reduce(
          (totalRowNumber: number, block: NodeBlock) => {
            if (totalRowNumber < block.position.row) {
              totalRowNumber = block.position.row;
            }
            return totalRowNumber;
          },
          0
        ) + 1;
    }

    let newBlock: NodeBlock | null = null;

    const blockTypeToUpdateUids = [
      "choices",
      "yesNo",
      "scorer",
      "showHide",
      "button",
    ];

    // update uids if data is duplicated
    if (
      data &&
      blockTypeToUpdateUids.includes(blockType) &&
      currentEditorMode !== editorMode.TemplateEdit
    ) {
      data = updateUidsOnBlockData(data as any);
    }

    switch (blockType) {
      case "richTextArea":
        const addToManagementPlan =
          editorState.node.nodeProperties.isEndNodeFunctionalityEnabled &&
          !Object.values(editorState.node.blocks).find((block) =>
            isRichTextAreaBlock(block)
          )
            ? true
            : false;

        newBlock = createNewBlock<IRichTextAreaData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  content:
                    "This is a Text Area block. Select the Edit button for this block to enter text into this block.",
                  type: "richText",
                },
          blockName,
          blockRules,
          blockOptions,
          addToManagementPlan,
        });

        break;
      case "question":
        blockType = "richTextArea";
        newBlock = createNewBlock<IRichTextAreaData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  content:
                    "This is a Question block. Select the Edit button for this block to enter text into this block. Only one question block can exist on a node.",
                  type: "question",
                },

          blockName,
          blockRules,
          blockOptions,
        });

        break;
      case "linkButton":
        newBlock = createNewBlock<ILinkButtonData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  nodeId: "I will connect later",
                  linkText: "",
                  pathwayId: pathwayId,
                  isPathwayToPathwayLink: false,
                },

          blockName,
          blockRules,
          blockOptions,
        });

        break;
      case "button":
        newBlock = createNewBlock<IButtonData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  buttonText:
                    "This is an Action Button block. Select the Edit button for this block to edit its title and functionality.",
                  buttonId: uuid4(),
                },

          blockName,
          blockRules,
          blockOptions,
        });

        break;
      case "choices":
        newBlock = createNewBlock<IChoicesBoxData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  groupId: uuid4(),
                  title: "",
                  fields: [
                    {
                      values: [{ text: "", value: 0, valueId: uuid4() }],
                      title:
                        "Choice 1 (Press the Edit button to amend this title)",
                      fieldId: uuid4(),
                    },
                    {
                      values: [{ text: "", value: 0, valueId: uuid4() }],
                      title:
                        "Choice 2 (Press the Edit button to amend this title)",
                      fieldId: uuid4(),
                    },
                  ],
                  type: "choices",
                  multipleAnswers: true,
                  isRequired: false,
                  showBlockScore: false,
                  showFieldScores: false,
                  layout: "vertical",
                  fieldType: "button",
                },

          blockName,
          blockRules,
          blockOptions,
        });

        break;
      case "scorer":
        blockType = "choices";
        newBlock = createNewBlock<IChoicesBoxData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  groupId: uuid4(),
                  title: "",
                  fields: [
                    {
                      values: [{ text: "", value: 1, valueId: uuid4() }],
                      title:
                        "Score 1 (Press the Edit button to amend this title)",
                      fieldId: uuid4(),
                    },
                    {
                      values: [{ text: "", value: 2, valueId: uuid4() }],
                      title:
                        "Score 2 (Press the Edit button to amend this title)",
                      fieldId: uuid4(),
                    },
                  ],
                  type: "scorer",
                  multipleAnswers: false,
                  isRequired: false,
                  showBlockScore: true,
                  showFieldScores: true,
                  layout: "vertical",
                  fieldType: "button",
                },

          blockName,
          blockRules,
          blockOptions,
        });

        break;
      case "yesNo":
        blockType = "choices";
        newBlock = createNewBlock<IChoicesBoxData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  groupId: uuid4(),
                  title: "",
                  fields: [
                    {
                      values: [
                        { text: "Yes", value: 0, valueId: uuid4() },
                        { text: "No", value: 0, valueId: uuid4() },
                      ],
                      title:
                        "Yes / No Option 1 (Press the Edit button to amend this title)",
                      fieldId: uuid4(),
                    },
                    {
                      values: [
                        { text: "Yes", value: 0, valueId: uuid4() },
                        { text: "No", value: 0, valueId: uuid4() },
                      ],
                      title:
                        "Yes / No Option 2 (Press the Edit button to amend this title)",
                      fieldId: uuid4(),
                    },
                  ],
                  type: "yesNo",
                  multipleAnswers: false,
                  isRequired: false,
                  showBlockScore: false,
                  showFieldScores: false,
                  layout: "vertical",
                  fieldType: "yesNo",
                },
        });
        break;
      case "displayVariables":
        newBlock = createNewBlock<IDisplayVariablesData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  text: "",
                  variableBlockId: "",
                },

          blockName,
          blockRules,
          blockOptions,
        });

        break;
      case "showHide":
        newBlock = createNewBlock<IShowHideData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  buttonId: uuid4(),
                  connectedBlocksId: undefined,
                },

          blockName,
          blockRules,
          blockOptions,
        });
        break;
      case "video":
        newBlock = createNewBlock<IVideoBlockData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  filePointer: { pointer: "", storageType: "direct" },
                },
          blockName,
          blockRules,
          blockOptions,
        });
        break;
      case "image":
        newBlock = createNewBlock<IImageBlockData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  images: [],
                },
          blockName,
          blockRules,
          blockOptions,
        });
        break;
      case "files":
        newBlock = createNewBlock<IFileBlockData>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  filePointers: [],
                },
          blockName,
          blockRules,
          blockOptions,
        });
        break;
      case "placeholder":
        newBlock = createNewBlock<IEmptyBlockData>({
          blockType,
          column,
          row,
          data: {},
          blockName,
          blockRules,
          blockOptions,
        });
        break;
      case "startback":
        newBlock = createNewBlock<ISTarTBackBlock>({
          blockType,
          column,
          row,
          data:
            Object.keys(data).length > 0
              ? (data as any)
              : {
                  widgetRules: [
                    {
                      nodeId: "I will connect later",
                      linkText:
                        "STarT Back Tool Outcome: Low Risk of developing long term problems",
                      pathwayId,
                      isPathwayToPathwayLink: false,
                    },
                    {
                      nodeId: "I will connect later",
                      linkText:
                        "STarT Back Tool Outcome: Medium Risk of developing long term problems",
                      pathwayId,
                      isPathwayToPathwayLink: false,
                    },
                    {
                      nodeId: "I will connect later",
                      linkText:
                        "STarT Back Tool Outcome: High Risk of developing long term problems",
                      pathwayId,
                      isPathwayToPathwayLink: false,
                    },
                  ],
                  showGraph: false,
                },
          blockName,
          blockRules,
          blockOptions,
        });

        break;
    }

    if (newBlock === null) {
      throw Error("Unable to add block because it was null");
    }

    if (currentEditorMode === editorMode.TemplateEdit) {
      newBlock.blockData = data;
      if (blockId) {
        newBlock.blockId = blockId;
      }
    }

    const action: DynamicNodeEditorActions = {
      type: "ADD_BLOCK",
      payload: newBlock,
    };
    editorDispatch(action);
    selectBlocks(new Set([action.payload.blockId]));

    return newBlock;
  };

  useEffect(() => {
    if (
      editorState.node.nodeProperties.isEndNodeFunctionalityEnabled &&
      getManagementPlanText(editorState.node.blocks).length === 0
    ) {
      const paragraphBlockArray = Object.values(editorState.node.blocks).filter(
        (block) =>
          block.blockType === "richTextArea" &&
          (block.blockData as IRichTextAreaData).type === "richText"
      );

      if (paragraphBlockArray?.length > 0) {
        paragraphBlockArray.forEach((paragraphBlock) => {
          editorDispatch({
            type: "UPDATE_BLOCK_OPTIONS",
            payload: {
              blockId: paragraphBlock.blockId,
              blockOptions: {
                ...paragraphBlock.blockOptions,
                addToManagementPlan: true,
              },
            },
          });
        });
        return;
      }
      onAddBlock(
        "richTextArea",
        {
          content: "This is a text block, replace this text with your own…",
          type: "richText",
        },
        undefined,
        undefined,
        undefined,
        undefined,
        {
          addToDecisionSummary: false,
          addToNodeScore: false,
          addToManagementPlan: true,
          backgroundColor: "rgb(255,255,255)",
          borderColor: "rgb(255,255,255)",
          borderRadius: "5px",
          fontColor: "rgb(0,0,0)",
          readonlyInTemplate: false,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState.node.nodeProperties.isEndNodeFunctionalityEnabled]);

  const onDeleteBlock = (block: NodeBlock, type: string) => {
    if (createTemplateChecked) {
      setSelectedBlockIds((previousIds) => {
        const newIds = new Set([...previousIds.values()]);
        newIds.delete(block.blockId);
        return newIds;
      });
    }

    const blockRowLength = Object.values(editorState.node.blocks).filter(
      (blockElement) => blockElement.position.row === block.position.row
    ).length;

    const fieldsInNodeContextToDelete = Object.values(
      nodeContext.fields
    ).filter((field) => field.blockId === block.blockId);

    fieldsInNodeContextToDelete.forEach((field) => {
      pathwayNavigatorDispatch({
        type: "NODE_BLOCK_LOGIC_OFF",
        payload: {
          ...field,
        },
      });
    });

    if (blockRowLength > 1) {
      editorDispatch({
        type: "REMOVE_BLOCK",
        payload: { ...block },
      });
      if (block.blockType !== "placeholder" && type === "block") {
        onAddBlock(
          "placeholder",
          {},
          block.position.row,
          block.position.column
        );
      }
      return;
    }
    editorDispatch({
      type: "UPDATE_ROWS_ORDER",
      payload: {
        sourceRowIndex: block.position.row,
        type: "deleteRow",
      },
    });
    onEdit(undefined);

    const listBlocksWithRuleConnected = listBlocksConnected(
      Object.values(editorState.node.blocks),
      block.blockId
    );

    if (listBlocksWithRuleConnected) {
      listBlocksWithRuleConnected.forEach((blockId) => {
        let blockToUpdate = Object.values(editorState.node.blocks).find(
          (block) => block.blockId === blockId
        );

        if (blockToUpdate && blockToUpdate?.blockRules.rule) {
          const cleanedRule = cleanRule(
            JSON.parse(blockToUpdate.blockRules.rule),
            block.blockId
          );

          const isObjectEmpty =
            Object.keys(cleanedRule).length > 0 ? false : true;

          editorDispatch?.({
            type: "UPDATE_RULES",
            payload: {
              blockId: blockToUpdate.blockId,
              ruleOptions: {
                ...blockToUpdate.blockRules,
                rule: isObjectEmpty ? null : JSON.stringify(cleanedRule),
                alwaysShow: isObjectEmpty ? true : false,
              },
            },
          });
        }
      });
    }
  };

  const onDragEnd = (dropResult: DropResult) => {
    // setPlaceholderProps({});
    setBlockDraggingId("");
    setShowDroppingPositions(false);

    const { source, destination } = dropResult;

    if (!destination) {
      return;
    }

    if (destination.droppableId.includes("add-row")) {
      if (dropResult.source.droppableId === "block-library") {
        const indexRow = destination.droppableId.substring(
          destination.droppableId.length,
          destination.droppableId.lastIndexOf("-") + 1
        );
        onAddBlock(dropResult.draggableId as BlockTypes, {}, +indexRow);
        return;
      }
      const existingElementToReplacePlaceholder = Object.values(
        editorState.node.blocks
      ).find((block) => block.blockId === dropResult.draggableId);

      if (existingElementToReplacePlaceholder) {
        onDeleteBlock(existingElementToReplacePlaceholder, "block");
        onAddBlock(
          isChoicesBlock(existingElementToReplacePlaceholder)
            ? existingElementToReplacePlaceholder.blockData.type
            : existingElementToReplacePlaceholder.blockType,
          existingElementToReplacePlaceholder.blockData,
          +destination.droppableId.substring(
            destination.droppableId.length,
            destination.droppableId.lastIndexOf("-") + 1
          ),
          undefined,
          existingElementToReplacePlaceholder.blockRules,
          existingElementToReplacePlaceholder.blockName,
          existingElementToReplacePlaceholder.blockOptions
        );
        return;
      }
    }

    if (dropResult.type === "choicesBlock") {
      if (destination?.index === source.index) {
        return;
      }
      let choicesBlock = Object.values(editorState.node.blocks).find(
        (block) => block.blockId === source.droppableId
      ) as IChoicesBlock;

      if (choicesBlock) {
        let fields = choicesBlock.blockData.fields;

        const newFieldsOrder = Array.from(fields);
        newFieldsOrder.splice(source.index, 1);
        newFieldsOrder.splice(destination.index, 0, fields[source.index]);

        editorDispatch?.({
          type: "UPDATE_BLOCK",
          payload: {
            blockId: choicesBlock.blockId,
            blockData: {
              ...choicesBlock.blockData,
              fields: newFieldsOrder,
            },
          },
        });
      }
      return;
    }

    const destinationBlock = Object.values(editorState.node.blocks).find(
      (block) => block.blockId === destination.droppableId
    );
    if (destinationBlock?.blockType === "placeholder") {
      onDeleteBlock(destinationBlock, "block");

      if (source.droppableId === "block-library") {
        onAddBlock(
          dropResult.draggableId as BlockTypes,
          {},
          destinationBlock.position.row,
          destinationBlock.position.column
        );
        return;
      }
      const existingElementToReplacePlaceholder = Object.values(
        editorState.node.blocks
      ).find((block) => block.blockId === dropResult.draggableId);

      if (existingElementToReplacePlaceholder) {
        onAddBlock(
          isChoicesBlock(existingElementToReplacePlaceholder)
            ? existingElementToReplacePlaceholder.blockData.type
            : existingElementToReplacePlaceholder.blockType,
          existingElementToReplacePlaceholder.blockData,
          destinationBlock.position.row,
          destinationBlock.position.column,
          existingElementToReplacePlaceholder.blockRules,
          existingElementToReplacePlaceholder.blockName,
          existingElementToReplacePlaceholder.blockOptions
        );
        onDeleteBlock(existingElementToReplacePlaceholder, "block");

        // Unselect the destination block again since the onAddBlock function may reselect it
        setSelectedBlockIds((previousIds) => {
          const newIds = new Set([...previousIds.values()]);
          newIds.delete(destinationBlock.blockId);
          return newIds;
        });
      }
    }

    if (destination.droppableId === "editNode") {
      editorDispatch({
        type: "UPDATE_ROWS_ORDER",
        payload: {
          sourceRowIndex: source.index,
          destinationRowIndex: destination.index,
          type: "rearrange",
        },
      });
    }
  };

  // const onDragUpdate = (update: DragUpdate) => {
  //   if (update.source.droppableId !== "editNode") {
  //     return;
  //   }
  //   if (!update.destination) {
  //     return;
  //   }

  //   const draggableId = update.draggableId;
  //   const destinationIndex = update.destination.index;

  //   const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
  //   const draggedDOM = document.querySelector(domQuery);

  //   if (!draggedDOM) {
  //     return;
  //   }

  //   if (draggedDOM) {
  //     const childrenArray = [...(draggedDOM.parentNode as Element).children];
  //     const sourceIndex = update.source.index;

  //     const movedItem = childrenArray[sourceIndex];
  //     childrenArray.splice(sourceIndex, 1);

  //     const updatedArray = [
  //       ...childrenArray.slice(0, destinationIndex),
  //       movedItem,
  //       ...childrenArray.slice(destinationIndex + 1),
  //     ];

  //     let { clientHeight, clientWidth } = draggedDOM;

  //     let clientY =
  //       parseFloat(
  //         window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop
  //       ) +
  //       updatedArray.slice(0, destinationIndex).reduce((total, curr: any) => {
  //         const style = curr.currentStyle || window.getComputedStyle(curr);
  //         const marginBottom = parseFloat(style.marginBottom);
  //         return total + curr.clientHeight + marginBottom;
  //       }, 0);

  //     let clientX = parseFloat(
  //       window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft
  //     );

  //     setPlaceholderProps({
  //       clientHeight,
  //       clientWidth,
  //       clientY,
  //       clientX,
  //     });
  //   }
  // };

  const onDragStart = (dragElement: DragStart) => {
    if (dragElement.source.droppableId === "editNode") {
      setBlockDraggingId(dragElement.source.droppableId);
    }
  };

  const onBeforeCapture = (onBeforeCaptureElement: BeforeCapture) => {
    if (
      onBeforeCaptureElement.draggableId === "editNode" ||
      onBeforeCaptureElement.draggableId.includes("choicesBlock")
    ) {
      return;
    }

    setShowDroppingPositions(true);
    setBlockDraggingId(onBeforeCaptureElement.draggableId);
  };

  const onScrollEditor = () => {
    if (nodeViewerGridRef.current) {
      let isScrolledToBottom =
        Math.round(
          nodeViewerGridRef.current.scrollHeight -
            nodeViewerGridRef.current.scrollTop
        ) === nodeViewerGridRef.current.clientHeight;

      setScrollToBottom(isScrolledToBottom ?? false);
    }
  };

  const selectBlocks = (blockIds: Set<string>) => {
    setSelectedBlockIds((previousIds) => {
      return new Set([...previousIds.values(), ...blockIds.values()]);
    });
  };

  const onRowSelectedChange = (selected: boolean, blockIds: Set<string>) => {
    if (selected) {
      selectBlocks(blockIds);
    }
    if (!selected) {
      setSelectedBlockIds((previousIds) => {
        const newSet = new Set([...previousIds.values()]);
        for (const blockId of blockIds) {
          newSet.delete(blockId);
        }
        return newSet;
      });
    }
  };

  const handleCreateTemplateToggled = (checked: boolean) => {
    setCreateTemplateChecked(checked);
    setSelectedBlockIds(new Set());
  };

  useEffect(() => {
    console.log("EDITOR STATE", editorState);
  }, [editorState]);

  useEffect(() => {
    if (showDroppingPositions && scrollToBottom && nodeViewerRef.current) {
      nodeViewerRef.current.scrollIntoView({
        block: "end",
      });
    }
  }, [showDroppingPositions, scrollToBottom]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
        borderBottom: "1px solid #C2C2C2",
        ...containerStyles,
      }}
    >
      {showHeader && (
        <NodeEditorHeader
          pathwayState={{ tenantId, currentPathwayId: pathwayId }}
        />
      )}
      <DragDropContext
        onDragEnd={(props) => onDragEnd(props)}
        onDragStart={(props) => onDragStart(props)}
        // onDragUpdate={(props) => onDragUpdate(props)}
        onBeforeCapture={(props) => onBeforeCapture(props)}
      >
        <Grid
          container
          sx={{ overflowY: "hidden", height: "100%" }}
          item={true}
        >
          {viewType === "edit" && (
            <Grid
              item={true}
              xs={2.5}
              sx={{
                borderRight: "1px solid #C2C2C2",
                height: "100%",
                overflowY: "auto",
                maxWidth: "400px",
              }}
            >
              {!editorState.editingBlock && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    height: "56px",
                    position: "sticky",
                    top: 0,
                    borderBottom: "1px solid #C4C4C4",
                    zIndex: 2,
                  }}
                >
                  <Tabs
                    value={
                      editorState.editingBlock
                        ? "Element"
                        : currentEditorMode === editorMode.VariableEdit
                        ? "Variables"
                        : currentEditorMode === editorMode.TemplateEdit
                        ? "Templates"
                        : "Blocks"
                    }
                    color="primary"
                    sx={{ width: "100%" }}
                    variant="fullWidth"
                  >
                    <Tab
                      value="Blocks"
                      label="Blocks"
                      onClick={() => {
                        onEdit?.(undefined);
                        changeEditorMode(editorMode.BlockEdit);
                      }}
                    />
                    <Tab
                      value="Templates"
                      label="Templates"
                      onClick={() => {
                        changeEditorMode(editorMode.TemplateEdit);
                      }}
                    />
                    {/* <Tab
                      value="Variables"
                      label="Variables"
                      onClick={() => {
                        changeEditorMode(editorMode.VariableEdit);
                      }}
                    /> */}
                  </Tabs>
                </Box>
              )}
              {currentEditorMode === editorMode.BlockEdit &&
                !editorState.editingBlock && (
                  <Droppable
                    droppableId="block-library"
                    isDropDisabled={true}
                    renderClone={(provided, snapshot, rubric) => {
                      const draggingBlock = blockTypes.find(
                        (block) => block.blockType === rubric.draggableId
                      );

                      return (
                        <span
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {draggingBlock && (
                            <BlockElement
                              state={editorState.node}
                              block={draggingBlock}
                            />
                          )}
                        </span>
                      );
                    }}
                  >
                    {(provided, snapshot) => (
                      <Box
                        onMouseEnter={() =>
                          blockDraggingId === "" &&
                          setShowDroppingPositions(true)
                        }
                        onMouseLeave={() =>
                          blockDraggingId === "" &&
                          setShowDroppingPositions(false)
                        }
                        ref={provided.innerRef}
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          p: 1,
                        }}
                      >
                        {blockTypes.map((block, index) => {
                          const shouldRenderClone =
                            block.blockType === snapshot.draggingFromThisWith;

                          const questionBlockPresent = Object.values(
                            editorState.node.blocks
                          ).find((block) => isQuestionBlock(block))
                            ? true
                            : false;

                          const startbackBlockPresent = Object.values(
                            editorState.node.blocks
                          ).find((block) => isStartBackBlock(block))
                            ? true
                            : false;

                          return (
                            <Box
                              key={block.blockType}
                              sx={{
                                minWidth: "100px",
                                width: "33.33%",
                              }}
                            >
                              {shouldRenderClone ? (
                                <Box>
                                  <BlockElement
                                    state={editorState.node}
                                    block={block}
                                  />
                                </Box>
                              ) : (
                                <Draggable
                                  draggableId={block.blockType}
                                  index={index}
                                  isDragDisabled={
                                    (questionBlockPresent &&
                                      block.blockType === "question") ||
                                    (startbackBlockPresent &&
                                      block.blockType === "startback")
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <span
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {((questionBlockPresent &&
                                        block.blockType === "question") ||
                                        (startbackBlockPresent &&
                                          block.blockType === "startback")) && (
                                        <Tooltip
                                          title={`This block is deactivated as there is already a ${block.blockType} block in the node.`}
                                        >
                                          <span>
                                            <BlockElement
                                              state={editorState.node}
                                              block={block}
                                            />
                                          </span>
                                        </Tooltip>
                                      )}
                                      {((block.blockType !== "question" &&
                                        block.blockType !== "startback") ||
                                        (block.blockType === "question" &&
                                          !questionBlockPresent) ||
                                        (block.blockType === "startback" &&
                                          !startbackBlockPresent)) && (
                                        <BlockElement
                                          state={editorState.node}
                                          block={block}
                                        />
                                      )}
                                    </span>
                                  )}
                                </Draggable>
                              )}
                            </Box>
                          );
                        })}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                )}
              {currentEditorMode === editorMode.TemplateEdit &&
                !editorState.editingBlock && (
                  <WidgetTemplatesPanel
                    draggingId={blockDraggingId}
                    setShowDroppingPositions={setShowDroppingPositions}
                    addBlockToEditor={onAddBlock}
                    editorState={editorState}
                  />
                )}
              {currentEditorMode === editorMode.VariableEdit &&
                !editorState.editingBlock && <p>Bingpot!</p>}
              {editorState.editingBlock && (
                <NodeEditorPanel
                  dispatch={editorDispatch}
                  pathwayNodes={pathwayNodes}
                  tenantId={tenantId}
                  state={editorState}
                  pathwayEditorDispatch={pathwayNavigatorDispatch}
                  pathwayState={{ nodeContext }}
                  createTemplateChecked={createTemplateChecked}
                />
              )}
            </Grid>
          )}
          <Grid
            item={true}
            xs={viewType === "edit" ? 9.5 : 12}
            sx={{
              height: "100%",
              overflowY: "auto",
            }}
            id="node-viewer"
            onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              let target = e.target as Element;
              if (e.button === 0 && target.id === "node-viewer") {
                onEdit(undefined);
              }
            }}
            onScroll={() => onScrollEditor()}
            ref={nodeViewerGridRef}
          >
            {showNodeEditorSectionHeader && (
              <NodeEditorSectionHeader
                onSave={() => onInnerSave()}
                dispatch={editorDispatch}
                state={editorState}
                onEdit={onEdit}
                setViewType={setViewType}
                viewType={viewType}
                hideSaveTemplate={hideSaveTemplate}
                showPreviewEditToggle={showPreviewEditToggle}
                handleCreateTemplateToggled={handleCreateTemplateToggled}
                createTemplateChecked={createTemplateChecked}
                selectedBlockIds={selectedBlockIds}
              />
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
              id="node-viewer"
              ref={nodeViewerRef}
            >
              <NodeViewer
                isPreview={viewType === "edit" ? false : true}
                state={editorState.node}
                dispatch={editorDispatch}
                onAddBlock={onAddBlock}
                blockDraggingId={blockDraggingId}
                pathwayEditorDispatch={pathwayNavigatorDispatch}
                pathwayState={{
                  tenantId,
                  currentPathwayId: pathwayId,
                  nodeContext,
                  decisionSummary,
                  route,
                }}
                onEdit={onEdit}
                nodes={pathwayNodes}
                // placeholderProps={placeholderProps}
                onDeleteBlock={onDeleteBlock}
                showDroppingPositions={showDroppingPositions}
                blockEditInfos={editorState.editingBlock}
                showNodeInfo={showNodeInfo}
                createTemplateChecked={createTemplateChecked}
                onRowSelectedChange={onRowSelectedChange}
                selectedBlockIds={selectedBlockIds}
                currentEditorMode={currentEditorMode}
                onSave={() => onInnerSave()}
              />
              {showDecisionSummary && viewType === "view" && (
                <Box sx={{ maxWidth: 350 }}>
                  <DecisionSummary decisionSummary={decisionSummary} />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </DragDropContext>
      <UnsavedChangesPrompt
        state={editorState.node}
        shouldBlockOnNavigation={editorState.isDirty}
        handleSaveNode={onInnerSave}
        unblockRef={unblockRef}
      />
    </Box>
  );
};

export default NodeEditor;
