export type AccessibleColour = {
  background: string;
  text: string;
};

export const AccessibleColourParis: AccessibleColour[] = [
  // { background: "#FFFFFF", text: "#000" },
  { background: "#C0C0C0", text: "#000" },
  { background: "#000000", text: "#fff" },
  { background: "#8A0F15", text: "#fff" },
  { background: "#B9151A", text: "#fff" },
  { background: "#F22D0D", text: "#000" },
  { background: "#F56300", text: "#000" },
  { background: "#FFBD00", text: "#000" },
  { background: "#F9E01F", text: "#000" },
  { background: "#8FE51A", text: "#000" },
  { background: "#00B23F", text: "#000" },
  { background: "#00806B", text: "#fff" },
  { background: "#80D2FF", text: "#000" },
  { background: "#1A9CFF", text: "#000" },
  { background: "#0051CC", text: "#fff" },
  { background: "#001C80", text: "#fff" },
  { background: "#9500FF", text: "#fff" },
  { background: "#6A00FF", text: "#fff" },
  { background: "#6A0091", text: "#fff" },
  { background: "#FF99FF", text: "#000" },
  { background: "#E830E8", text: "#000" },
  { background: "#C200C2", text: "#fff" },
  { background: "#FFB199", text: "#000" },
  { background: "#4D2300", text: "#fff" },
];
