//import { oauth2 as SMART } from "fhirclient";
import { useEffect } from "react";

const Launcher = () => {
  console.log("Hitting Launcher");
  useEffect(() => {
    console.log(window.location);
    // SMART.authorize({
    //   clientId: "3a895046-ddce-4dd6-a7b9-fe85d9a92dbd",
    //   scope: [
    //     "patient/*.read", // Read patient data
    //     "fhirUser openid profile launch",
    //     "practitioner/*.read",
    //   ].join(" "),
    //   redirectUri: "http://localhost:3000",
    //   completeInTarget: true,
    // });
  }, []);
  return null;
};

export default Launcher;
