import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import {
  IFindResult,
  ISearchRequest,
} from "../../../../functions/src/http/findandreplace/find";
import { IChangeDeploymentStatus } from "../../../../functions/src/http/deployments/changeDeploymentStatus.function";
import { IDeployPathway } from "../../../../functions/src/http/deployments/startDeployment.function";
import { IPublishSnapshotToLive } from "../../../../functions/src/http/deployments/publishSnapshotToLive.function";
import { IPublishSnapshot } from "../../../../functions/src/http/deployments/publishSnapshot.function";
import { ITriggerBackupRequest } from "../../../../functions/src/http/backupManager/triggerBackup.function";
import { IRollbackSnapshot } from "../../../../functions/src/http/deployments/rollback.function";
import { ITenantRestoreRequest } from "../../../../functions/src/utils/backupManager";
import {
  IPDSLookupRequest,
  IPDSLookupResult,
} from "../../../../functions/src/http/nhsdigital/pds";
// import {
//   IOperationDashboardRequest,
//   IModuleMetadata,
// } from "../../../../functions/src/http/pathwayMetadata/getOperationDashboardData.function";
import {
  IPathwaySearchResult,
  IPathwaySearchRequest,
} from "../../../../functions/src/http/findandreplace/pathwaysearch";

import { toastr } from "react-redux-toastr";
import {
  IManuallySendActivationEmailRequest,
  IManuallySendActivationSuccessResponse,
} from "../../../../functions/src/http/userManagement/manuallySendActivationEmail";
import {
  IVerifyAuthenticationTokenRequest,
  IVerifyAuthenticationTokenResponse,
} from "../../../../functions/src/http/userManagement/verifyAuthenticator";
import {
  IDisableAuthenticatorRequest,
  IDisableAuthenticatorResponse,
} from "../../../../functions/src/http/userManagement/disableAuthenticator";
import { IRegisterAuthenticatorResponse } from "../../../../functions/src/http/userManagement/registerAuthenticator";
import { ITenantUser } from "../../../../types/User";
import { IListUsersOfTenantRequest } from "../../../../functions/src/userManagement/listUsersOfTenant";
import {
  IConfirmAuthenticationTokenRequest,
  IConfirmAuthenticationTokenResponse,
} from "../../../../functions/src/http/userManagement/confirmAuthenticator";

import {
  IRemoveUserFromTenantRequest,
  IRemoveUserFromTenantResponse,
} from "../../../../functions/src/userManagement/removeUserFromTenant";
import { IEditUserRoleRequest } from "../../../../functions/src/userManagement/editUserRole";
import {
  IInviteUserToTenantRequest,
  IInviteUserToTenantResponse,
} from "../../../../functions/src/userManagement/inviteUserToTenant";

import {
  IQueryPathwayDataRequest,
  IQueryPathwayDataResponse,
} from "../../../../functions/src/businessIntelligence/queryPathwayData";
import {
  IListPatientEncounterRequest,
  IListPatientEncounterResponse,
} from "../../../../functions/src/http/patientIntergration/issueList";

import {
  IEpisodeCreateRequest,
  IEpisodeCreateResponse,
} from "../../../../functions/src/http/patientIntergration/episodeCreate";

import {
  IPatientCreateRequest,
  IPatientCreateResponse,
} from "../../../../functions/src/http/patientIntergration/patientCreate";

export const createFunction = <T = any, R = any>(
  name: string
): ((data: T) => Promise<R>) => {
  return async (data: T) => {
    const callable = firebase.app().functions("europe-west2").httpsCallable(name);
    try {
      const result = (await callable(data)).data;
      if (result && result.hasOwnProperty("messageType")) {
        toastr.success("", result.message);
      }
      return result;
    } catch (ex: any) {
      toastr.error("", ex.message);
      throw new Error(ex.message);
    }
  };
};

const functionTypes = {
  addNewDocFields: createFunction<void, void>("addNewDocFields"),

  nodeSearch: createFunction<ISearchRequest, IFindResult[]>("nodeSearch"),
  nodeSearchRefresh: createFunction<void, void>(
    "refreshFindAndReplaceDatabase"
  ),
  setDeploymentStatus: createFunction<IChangeDeploymentStatus, void>(
    "changeDeploymentStatus"
  ),
  deployPathway: createFunction<IDeployPathway, void>("startDeployPathway"),
  publishSnapshotToLive: createFunction<IPublishSnapshotToLive, void>(
    "publishSnapshotToLive"
  ),
  publishSnapshot: createFunction<IPublishSnapshot, void>("publishSnapshot"),
  rollbackSnapshot: createFunction<IRollbackSnapshot, void>("rollbackSnapshot"),
  triggerBackups: createFunction<ITriggerBackupRequest, string[]>(
    "triggerBackups"
  ),
  restoreBackup: createFunction<ITenantRestoreRequest, string>("restoreBackup"),
  refreshPathwayMetadata: createFunction<void, void>("refreshPathwayMetadata"),
  addUnresolvedCommentCount: createFunction<void, void>(
    "addUnresolvedCommentCount"
  ),
  // getOperationDashboardData: createFunction<
  //   IOperationDashboardRequest,
  //   IModuleMetadata
  // >("getOperationDashboardData"),
  pdsLookup: createFunction<IPDSLookupRequest, IPDSLookupResult>("pdsRequest"),
  publishKeys: createFunction<void, void>("keyRotate"),
  addStatusToMessages: createFunction<void, void>("addStatusToMessages"),
  addNotificationTypeToMessages: createFunction<void, void>(
    "addNotificationTypeToMessages"
  ),
  countNodesOnPathways: createFunction<void, void>("countNodesOnPathways"),
  triggerRecount: createFunction<void, void>("triggerRecount"),
  pathwaySearch: createFunction<IPathwaySearchRequest, IPathwaySearchResult[]>(
    "pathwaySearch"
  ),
  updateDirtyNodeCollection: createFunction<void, void>(
    "updateDirtyNodeCollection"
  ),
  listTenantUsers: createFunction<IListUsersOfTenantRequest, ITenantUser[]>(
    "listUsersOfTenant"
  ),
  manuallySendActivationEmail: createFunction<
    IManuallySendActivationEmailRequest,
    IManuallySendActivationSuccessResponse
  >("manuallySendActivationEmail"),
  registerAuthenticator: createFunction<void, IRegisterAuthenticatorResponse>(
    "registerAuthenticator"
  ),
  verifyAuthenticator: createFunction<
    IVerifyAuthenticationTokenRequest,
    IVerifyAuthenticationTokenResponse
  >("verifyAuthenticator"),
  disableAuthenticator: createFunction<
    IDisableAuthenticatorRequest,
    IDisableAuthenticatorResponse
  >("disableAuthenticator"),
  confirmAuthenticator: createFunction<
    IConfirmAuthenticationTokenRequest,
    IConfirmAuthenticationTokenResponse
  >("confirmAuthenticator"),
  buildMFAProfiles: createFunction<void, void>("buildMFARecords "),
  editUserRole: createFunction<IEditUserRoleRequest>("editUserRole"),
  inviteUserToTenant: createFunction<
    IInviteUserToTenantRequest,
    IInviteUserToTenantResponse
  >("inviteUserToTenant"),
  removeUserFromTenant: createFunction<
    IRemoveUserFromTenantRequest,
    IRemoveUserFromTenantResponse
  >("removeUserFromTenant"),
  queryPathwayData: createFunction<
    IQueryPathwayDataRequest,
    IQueryPathwayDataResponse
  >("queryPathwayData"),
  listEncounters: createFunction<
    IListPatientEncounterRequest,
    IListPatientEncounterResponse
  >("listPatientEncounters"),
  createEpisode: createFunction<IEpisodeCreateRequest, IEpisodeCreateResponse>(
    "createEpisode"
  ),
  createPatient: createFunction<IPatientCreateRequest, IPatientCreateResponse>(
    "patientCreate"
  ),
};

export default functionTypes;
