import { Alert, Paper } from "@mui/material";
import PageContainer from "app/layout/PageContainer";

const ErrorPage = ({ message }: { message: string }) => {
  return (
    <PageContainer title="Error" disableBack={true}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Alert severity="error">{message}</Alert>
        </div>
      </Paper>
    </PageContainer>
  );
};
export default ErrorPage;
