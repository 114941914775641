import { Button } from "@mui/material";
import SafeHtml from "app/components/SafeHtml";

const NodeActionComparison = ({
  linkContent,
  destinationContent,
}: {
  linkContent: string;
  destinationContent: string;
}) => {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: "2px",
        alignItems: "center",
      }}
    >
      <div style={{ flex: 1, alignSelf: "stretch" }}>
        <Button
          style={{
            height: "100%",
            backgroundColor: "#01A29B",
            width: "100%",
          }}
        >
          <SafeHtml html={linkContent} allowHtml />
        </Button>
      </div>
      <div style={{ flex: 1, alignSelf: "stretch" }}>
        <Button
          style={{
            height: "100%",
            backgroundColor: "#01A29B33",
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='1' stroke-dasharray='1%2c 3' stroke-dashoffset='2' stroke-linecap='round'/%3e%3c/svg%3e")`,
            borderRadius: "4px",
            width: "100%",
          }}
        >
          <SafeHtml html={destinationContent} allowHtml />
        </Button>
      </div>
      <svg
        style={{ position: "absolute", left: "calc(50% - 22px)" }}
        width="50"
        height="30"
        viewBox="0 0 50 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M49.0237 18.7263C49.6174 19.1221 49.6174 19.9946 49.0237 20.3904L22.5296 38.0531C21.865 38.4962 20.9749 38.0198 20.9749 37.2211V29.5671C20.9749 29.0149 20.5271 28.5671 19.9749 28.5671H1.27173C0.719444 28.5671 0.271729 28.1194 0.271729 27.5671L0.271729 11.5496C0.271729 10.9973 0.719444 10.5496 1.27173 10.5496H19.9749C20.5271 10.5496 20.9749 10.1018 20.9749 9.54956V1.89561C20.9749 1.09692 21.865 0.620529 22.5296 1.06356L49.0237 18.7263Z"
          fill="white"
          stroke="black"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeDasharray="2 2"
        />
      </svg>
    </div>
  );
};

export default NodeActionComparison;
