import { ChangeEvent, Dispatch, useMemo, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  BlockTypes,
  NodeBlock,
  NodeBlockData,
  IDynamicNode,
} from "../../../../../types/Nodes";

import RowBlocks from "./RowBlocks";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import NodeInfoTenantAndNode from "../NodeInfoTenantAndNodeV2";
import { INodeViewSettings } from "features/NodeViewer/NodeView";
import GoBackButton from "../GoBackButtonView";
import PlaceholderAddBlockOnRow from "./PlaceholderAddBlockOnRow";
import CopyToClipboardView from "../CopyToClipboardViewV2";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import { Icon } from "semantic-ui-react";
import NodePrint from "../NodePrintV2";
import OnHoverEditMenu from "./OnHoverEditMenu";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";
import { editorMode } from "features/NodeEditorV2/NodeEditorV2";
import { getManagementPlanText } from "features/NodeEditorV2/NodeEditorUtils";
import { isConnectedNode } from "features/pathwaybuilder/utils/pathwayHelperV2";

const NodeViewer = ({
  state,
  dispatch,
  onAddBlock,
  blockDraggingId,
  pathwayEditorDispatch,
  nodes,
  pathwayState,
  isPreview,
  nodeSettings,
  // placeholderProps,
  onDeleteBlock,
  showDroppingPositions,
  onEdit,
  blockEditInfos,
  showNodeInfo = true,
  createTemplateChecked,
  onRowSelectedChange,
  selectedBlockIds,
  currentEditorMode,
  onSave,
  isShowingBlockRules,
}: {
  state: IDynamicNode;
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  onAddBlock?: (
    blockType: BlockTypes,
    data: NodeBlockData,
    row?: number,
    column?: number
  ) => NodeBlock | undefined;

  blockDraggingId?: string;
  pathwayEditorDispatch: Dispatch<PathwayActions>;
  nodes?: IDynamicNode[];
  isPreview: boolean;
  nodeSettings?: INodeViewSettings;
  pathwayState: Partial<PathwayState>;
  // placeholderProps?: any;
  onDeleteBlock?: (block: NodeBlock, type: string) => void;
  showDroppingPositions?: boolean;
  onEdit?: (block: NodeBlock | undefined) => void;
  blockEditInfos?: NodeBlock | undefined;
  showNodeInfo?: boolean;
  createTemplateChecked?: boolean;
  onRowSelectedChange?: (selected: boolean, blockIds: Set<string>) => void;
  selectedBlockIds?: Set<string>;
  currentEditorMode?: editorMode;
  onSave?: () => Promise<void>;
  isShowingBlockRules?: boolean;
}) => {
  const [menuOpenIndex, setMenuOpenIndex] = useState<number>(-1);

  const defaultSettings: INodeViewSettings = {
    disableNavigation: false,
    disablePrintControls: false,
    hideCreatedAt: false,
    allowHtml: false,
  };

  const combinedSettings = { ...defaultSettings, ...nodeSettings };

  const handleRowCheckedChange = (
    event: ChangeEvent<HTMLInputElement>,
    rowBlocks: NodeBlock[]
  ) => {
    if (onRowSelectedChange) {
      const blockIds = new Set(rowBlocks.map((block) => block.blockId));
      onRowSelectedChange(event.target.checked, blockIds);
    }
  };

  const rowChecked = (rowBlocks: NodeBlock[]) => {
    const blockIds = rowBlocks.map((block) => block.blockId);
    for (const blockId of blockIds) {
      if (!selectedBlockIds?.has(blockId)) {
        return false;
      }
    }
    return true;
  };

  const blocksLength = useMemo(() => {
    const blocksLength = Object.values(state.blocks).length;
    return blocksLength;
  }, [state.blocks]);

  const allChecked = selectedBlockIds?.size === blocksLength;

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onRowSelectedChange) {
      const blockIds = Object.values(state.blocks).map(
        (block) => block.blockId
      );
      onRowSelectedChange(event.target.checked, new Set(blockIds));
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "900px",
        backgroundColor: "white",
        borderRadius: "4px",
        pb: "8px",
      }}
    >
      {showNodeInfo && (
        <NodeInfoTenantAndNode
          isPreview={isPreview}
          tenantId={pathwayState.tenantId || ""}
          currentPathway={pathwayState.currentPathwayId || ""}
          allowHtml={combinedSettings.allowHtml}
          dispatch={dispatch}
          state={state}
        />
      )}
      {createTemplateChecked && blocksLength > 0 && !isPreview && (
        <Box
          sx={{
            px: 3.4,
            pb: 2,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            disableTypography
            control={
              <Checkbox checked={allChecked} onChange={handleSelectAllChange} />
            }
            label={
              <Typography fontWeight={600}>
                {allChecked ? "Deselect All" : "Select All"}
              </Typography>
            }
            labelPlacement="start"
            sx={{ display: "flex", gap: 1 }}
          />
        </Box>
      )}
      <Box sx={{ px: 2 }}>
        <Droppable
          droppableId="editNode"
          isDropDisabled={
            blockDraggingId === "editNode" && !isPreview ? false : true
          }
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ position: "relative" }}
            >
              {Object.values(state.blocks).length < 1 &&
                !isPreview &&
                blockDraggingId !== "editNode" && (
                  <PlaceholderAddBlockOnRow
                    showDroppingPositions={showDroppingPositions}
                    rowIndex={0}
                    blockDraggingId={blockDraggingId}
                  />
                )}
              {Object.values(state.blocks)
                .reduce((acc: NodeBlock[][], block) => {
                  if (!Array.isArray(acc[block.position.row])) {
                    acc[block.position.row] = [];
                  }

                  acc[block.position.row].push(block);
                  return acc;
                }, [])
                .map((rowBlocks, rowIndex) => {
                  return (
                    <Box key={rowIndex}>
                      {!isPreview && blockDraggingId !== "editNode" && (
                        <PlaceholderAddBlockOnRow
                          showDroppingPositions={showDroppingPositions}
                          rowIndex={rowIndex}
                          blockDraggingId={blockDraggingId}
                        />
                      )}
                      <Draggable
                        draggableId={`${rowIndex}`}
                        index={rowIndex}
                        key={rowIndex}
                        isDragDisabled={
                          isPreview ||
                          // state.editPanelInfos
                          blockEditInfos
                            ? true
                            : false
                        }
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                          >
                            <Box
                              onPointerOver={() =>
                                !isPreview &&
                                rowBlocks.length > 1 &&
                                setMenuOpenIndex(rowIndex)
                              }
                              onPointerOut={() =>
                                !isPreview && setMenuOpenIndex(-1)
                              }
                              onFocus={() =>
                                !isPreview &&
                                rowBlocks.length > 1 &&
                                setMenuOpenIndex(rowIndex)
                              }
                              onBlur={() => !isPreview && setMenuOpenIndex(-1)}
                              // sx={{
                              //   border:
                              //     menuOpenIndex === rowIndex && !isPreview
                              //       ? "2px solid #EAEAEA"
                              //       : "",
                              // }}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box sx={{ flex: 1 }}>
                                <span
                                  style={{ display: "none" }}
                                  {...provided.dragHandleProps}
                                ></span>
                                {!isPreview && rowBlocks.length > 1 && (
                                  <OnHoverEditMenu
                                    type={"row"}
                                    dragHandleProp={provided.dragHandleProps}
                                    state={state}
                                    onDeleteBlock={onDeleteBlock}
                                    dispatch={dispatch}
                                    onAddBlock={onAddBlock}
                                    rowIndex={rowIndex}
                                    rowBlocks={rowBlocks}
                                    show={menuOpenIndex === rowIndex}
                                    isEditing={false}
                                    // show={rowBlocks.length > 1}
                                  />
                                )}
                                <RowBlocks
                                  isPreview={isPreview}
                                  pathwayState={pathwayState}
                                  nodes={nodes}
                                  rowBlocks={rowBlocks}
                                  rowIndex={rowIndex}
                                  blockDraggingId={blockDraggingId}
                                  dispatch={dispatch}
                                  onAddBlock={onAddBlock}
                                  state={state}
                                  pathwayEditorDispatch={pathwayEditorDispatch}
                                  // placeholderProps={placeholderProps}
                                  onDeleteBlock={onDeleteBlock}
                                  showDroppingPosition={showDroppingPositions}
                                  onEdit={onEdit}
                                  blockEditInfos={blockEditInfos}
                                  currentEditorMode={currentEditorMode}
                                  onSave={onSave}
                                  nodeSettings={combinedSettings}
                                  isShowingBlockRules={isShowingBlockRules ?? false}
                                />
                              </Box>
                              {!isPreview && createTemplateChecked && (
                                <Box sx={{ bgcolor: "rgba(1, 93, 103, 0.08)" }}>
                                  <Checkbox
                                    checked={rowChecked(rowBlocks)}
                                    onChange={(event) =>
                                      handleRowCheckedChange(event, rowBlocks)
                                    }
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                      {!isPreview &&
                        showDroppingPositions &&
                        Object.values(state.blocks).reduce(
                          (acc: NodeBlock[][], block) => {
                            if (!Array.isArray(acc[block.position.row])) {
                              acc[block.position.row] = [];
                            }
                            acc[block.position.row].push(block);
                            return acc;
                          },
                          []
                        ).length ===
                          rowIndex + 1 &&
                        blockDraggingId !== "editNode" && (
                          <PlaceholderAddBlockOnRow
                            showDroppingPositions={showDroppingPositions}
                            rowIndex={rowIndex + 1}
                            blockDraggingId={blockDraggingId}
                          />
                        )}
                    </Box>
                  );
                })}
              {/* <span style={{ display: "none" }}> */}
              {provided.placeholder}
              {/* </span> */}
              {/* {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <div
                  style={{
                    position: "absolute",
                    top: placeholderProps.clientY,
                    left: placeholderProps.clientX,
                    height: placeholderProps.clientHeight,
                    background: "rgba(1, 93, 103, 0.08)",
                    width: placeholderProps.clientWidth,
                    border: "2px solid #9FDFDC",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#9FDFDC",
                  }}
                >
                  <p>Drop here</p>
                </div>
              )} */}
            </div>
          )}
        </Droppable>
        {(state.nodeProperties.isEndNodeFunctionalityEnabled ||
          !isConnectedNode(state)) && (
          <Box sx={{ p: 0.5 }}>
            {!combinedSettings.disablePrintControls && (
              <Box sx={{ pb: 0.25 }}>
                <CopyToClipboardView
                  tenantId={pathwayState.tenantId || ""}
                  title={state.title}
                  currentPathway={pathwayState.currentPathwayId || ""}
                  decisionSummary={pathwayState.decisionSummary || []}
                  contentArray={getManagementPlanText(state.blocks)}
                  isDisabled={false}
                />
              </Box>
            )}
            {!combinedSettings.disablePrintControls && (
              <Modal
                trigger={
                  <Box sx={{ pb: 0.25 }}>
                    <Button fluid color="green">
                      <Icon name="print" />
                      Summary Print Preview
                    </Button>
                  </Box>
                }
                closeIcon
              >
                <Modal.Header>Print Preview</Modal.Header>
                <Modal.Content>
                  <NodePrint
                    outcome={state.title}
                    blocks={state.blocks}
                    decisionSummary={pathwayState.decisionSummary || []}
                    tenantId={pathwayState.tenantId || ""}
                    currentPathway={pathwayState.currentPathwayId || ""}
                  />
                </Modal.Content>
              </Modal>
            )}
          </Box>
        )}

        <GoBackButton
          settings={combinedSettings}
          dispatch={pathwayEditorDispatch}
          route={pathwayState.route || []}
          pathwayId={pathwayState.currentPathwayId || ""}
          isEndNode={
            !Boolean(
              Object.values(state.blocks).filter(
                (block) => block.blockType === "action"
              ).length ?? false
            )
          }
        />
      </Box>
    </Box>
  );
};

export default NodeViewer;
