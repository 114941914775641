// @ts-ignore
import { Editor } from "react-draft-wysiwyg";

//@ts-ignore
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//@ts-ignore
import draftToHtml from "draftjs-to-html";
// @ts-ignore
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import {
  IRichTextAreaBlock,
  IRichTextAreaData,
  NodeBlock,
} from "../../../../types/Nodes";
import SafeHtml from "app/components/SafeHtml";
import { Box } from "@mui/material";
import { DynamicNodeEditorActions } from "../dynamicNodeEditorReducer";
import { isQuestionBlock } from "features/pathwaybuilder/utils/pathwayHelperV2";

interface INodeContentAreaEditorProps {
  block: IRichTextAreaBlock;
  dispatch?: React.Dispatch<DynamicNodeEditorActions>;
  isEditing: boolean;
  onEdit?: (block: NodeBlock | undefined) => void;
}

const NodeTextAreaEditor = ({
  block,
  dispatch,
  isEditing,
  onEdit,
}: INodeContentAreaEditorProps) => {
  let initialEditorState = EditorState.createEmpty();

  const [editorState, setEditorState] = useState(initialEditorState);

  const onEditorChange = () => {
    let htmlContent: string;
    if (editorState.getCurrentContent().hasText()) {
      htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    } else {
      htmlContent = "";
    }

    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData: { ...block.blockData, content: htmlContent },
      },
    });
  };

  useEffect(() => {
    if (!isEditing) {
      const contentBlock = htmlToDraft(block.blockData?.content);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [isEditing, block.blockData?.content]);

  return (
    <Box
      sx={{
        backgroundColor: "inherit",
        overflowWrap: "break-word",
        maxWidth: 800,
      }}
    >
      {isEditing && (
        <span onClick={() => !isEditing && onEdit?.(block)}>
          <Editor
            editorState={editorState}
            wrapperClassName=""
            editorClassName="editor"
            toolbarClassName="wrapper"
            onEditorStateChange={setEditorState}
            spellCheck={true}
            onChange={() => onEditorChange()}
            toolbar={{
              options: [
                "link",
                "inline",
                "fontSize",
                "list",
                "textAlign",
                "colorPicker",
                "remove",
                "history",
              ],
              inline: { inDropdown: false },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
            stripPastedStyles={true}
          />
        </span>
      )}
      {!isEditing && (
        <Box sx={{ overflowWrap: "break-word" }}>
          {block.blockData?.content.trim() !== "" && (
            <SafeHtml
              html={(block.blockData as IRichTextAreaData).content}
              allowHtml={true}
            />
          )}
          {block.blockData?.content.trim() === "" && (
            <p>
              {isQuestionBlock(block)
                ? "This is a Question block. Select the Edit button for this block to enter text into this block. Only one question block can exist on a node."
                : "This is a Text Area block. Select the Edit button for this block to enter text into this block."}
            </p>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NodeTextAreaEditor;
