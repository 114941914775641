import { useState } from "react";
import { useTheme } from "@mui/material";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "app/components/LoadingButton";

interface IVerificationCodeFormProps {
  onMFALogin: ({
    verificationCode,
  }: {
    verificationCode: string;
  }) => Promise<void>;
  onReset: () => void;
  phoneNumberHint: string;
  isLoginForm: boolean;
  error: string;
  setError: (value: string) => void;
}
const VerificationCodeForm = ({
  onMFALogin,
  onReset,
  phoneNumberHint,
  isLoginForm,
  error,
  setError,
}: IVerificationCodeFormProps) => {
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const theme = useTheme();

  const handleOnMFALogin = async () => {
    try {
      setIsLoading(true);
      await onMFALogin({ verificationCode });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        "& .MuiTextField-root": { mb: 1 },
      }}
    >
      <Box sx={{ mb: 2, p: 1 }}>
        A verification code has been sent via CloudOTP SMS to the phone number
        ending in ...
        {phoneNumberHint.length > 4
          ? phoneNumberHint.substring(phoneNumberHint.length - 4)
          : phoneNumberHint}
        . <br />
        <br />
        Please enter the code in the box below.
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: theme.isMobileView ? undefined : "flex-start",
          justifyContent: "space-between",
          flexDirection: theme.isMobileView ? "column" : "row",
        }}
      >
        <TextField
          autoFocus
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleOnMFALogin();
            }
          }}
          placeholder="Verification Code"
          type="number"
          size="small"
          name="Verificationcode"
          value={verificationCode}
          sx={{ bgcolor: "#fff" }}
          onChange={(e) => {
            setVerificationCode(e.target.value);
            setError("");
          }}
          helperText={error}
        />

        <LoadingButton
          isLoading={isLoading}
          variant="contained"
          type="button"
          sx={{
            backgroundColor: isLoginForm ? "#C25100" : "",
            "&:hover": { backgroundColor: "#A33900" },
            minWidth: "120px",
            mb: 2,
            whiteSpace: "nowrap",
          }}
          onClick={handleOnMFALogin}
        >
          Verify code
        </LoadingButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1">
          It can take up to minute for the code to arrive. If you did not
          receive this code:
        </Typography>
        <Button
          sx={{ fontSize: "0.875rem", padding: "0", ml: 1 }}
          onClick={(e) => {
            e.preventDefault();
            onReset();
            return false;
          }}
        >
          Resend Code
        </Button>
      </Box>
    </Box>
  );
};

export default VerificationCodeForm;
