import { ColorResult, SketchPicker } from "@hello-pangea/color-picker";
import { Box, Button } from "@mui/material";
import { useState } from "react";

const ColorPickerComponent = ({
  text,
  onPickColor,
  selectedColor,
  onOpenPicker,
}: {
  text: string;
  onPickColor: (color: ColorResult) => void;
  selectedColor: string;
  onOpenPicker?: (value: boolean) => void;
}) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  const onShowColorPicker = (value: boolean) => {
    setShowColorPicker(value);
    onOpenPicker?.(value);
  };

  return (
    <Box sx={{ marginY: 1 }}>
      <Button
        onClick={() => onShowColorPicker(true)}
        sx={{
          display: "flex",
          backgroundColor: "white",
          color: "#757575",
          "&:hover": {
            backgroundColor: "#C4C4C4",
          },
        }}
        variant="contained"
      >
        {text}:
        <Box
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor: selectedColor,
            marginLeft: "10px",
            borderRadius: "5px",
            border: "2px solid #757575",
          }}
        ></Box>
      </Button>
      {showColorPicker ? (
        <div style={{ position: "absolute", zIndex: "2" }}>
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={() => onShowColorPicker(false)}
          />
          <SketchPicker
            color={selectedColor}
            onChange={(color) => onPickColor(color)}
          />
        </div>
      ) : null}
    </Box>
  );
};

export default ColorPickerComponent;
