import { Alert, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { Button } from "semantic-ui-react";

import PathwayDisclaimer from "features/NodeViewer/PathwayDisclaimer";
import useUserPrivateProfile from "features/hooks/useUserPrivateProfile";

interface LicenseAgreementProps {
  handleNextStep: () => void;
}

const LicenseAgreementContainer = ({
  handleNextStep,
}: LicenseAgreementProps) => {
  const { acceptEULA } = useUserPrivateProfile();
  const theme = useTheme();

  const onAccept = () => {
    acceptEULA();
    handleNextStep();
  };

  const onDecline = () => {
    handleNextStep();
  };

  const ulaFooterRef =
    "You can find this End User License Agreement at a later stage in the footer.";

  return (
    <>
      <Typography
        variant="h5"
        component="h2"
        sx={{ fontWeight: 700, marginBottom: 2 }}
      >
        End User License Agreement
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: theme.isMobileView ? "58vh" : "65vh",
          gap: 1,
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
          }}
        >
          <PathwayDisclaimer
            isActivateAccount={true}
            displayButtons={false}
            hideSegmentBoarder={true}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: theme.isMobileView ? "column" : "row",
            justifyContent: "space-between",
            flex: 1,
            gap: 1,
          }}
        >
          {theme.isMobileView ? (
            <Alert severity="info" sx={{ fontWeight: 500 }}>
              {ulaFooterRef}
            </Alert>
          ) : (
            <Typography variant="body1" sx={{ color: "grey" }}>
              {ulaFooterRef}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Button
              fluid={theme.isMobileView ? true : false}
              compact
              positive
              onClick={onAccept}
            >
              Accept
            </Button>
            <Button
              fluid={theme.isMobileView ? true : false}
              compact
              negative
              onClick={onDecline}
            >
              Reject
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LicenseAgreementContainer;
