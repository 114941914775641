import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTheme } from "@mui/material";
import { IReferenceDocuments } from "../../../types/CrossCover";
import Box, { BoxProps } from "@mui/system/Box";
import { useCrossCoverSelector } from "app/store/hooks";

type CLinicalSafteyReportProps = BoxProps & {
  as: "BUTTON" | "LINK";
  documentName:
    | "PRIVACY POLICY"
    | "CLINICAL SAFETY CASE REPORT"
    | "USER MANUAL";
};

const ReferenceDocuments = (props: CLinicalSafteyReportProps) => {
  const { as, documentName, ...filteredProps } = props;

  const theme = useTheme();

  const referenceDocuments = useCrossCoverSelector(
    (state) => state.crossCover.referenceDocuments
  );

  const lowerCaseDocumentName = documentName.toLocaleLowerCase();

  const displayDocument = () => {
    const name = lowerCaseDocumentName.replace(/ /g, "");

    if (referenceDocuments) {
      for (const referenceDoc of Object.keys(referenceDocuments)) {
        if (name === referenceDoc.toLowerCase()) {
          return window.open(
            referenceDocuments[referenceDoc as keyof IReferenceDocuments]
          );
        }
      }
    }
  };

  return (
    <>
      {as === "BUTTON" && (
        <Button
          sx={{
            width: theme.isMobileView ? "100%" : "auto",
            textTransform: "capitalize",
          }}
          onClick={() => displayDocument()}
          variant="outlined"
          endIcon={<OpenInNewIcon />}
        >
          {lowerCaseDocumentName}
        </Button>
      )}

      {as === "LINK" && (
        <Box
          sx={{
            color: theme.palette.primary.main,
            textDecoration: "underline",
            cursor: "pointer",
            textTransform: "capitalize",
          }}
          {...filteredProps}
        >
          <strong onClick={() => displayDocument()}>
            {lowerCaseDocumentName}
          </strong>
        </Box>
      )}
    </>
  );
};

export default ReferenceDocuments;
