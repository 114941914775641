// Vendor
import { useEffect, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useLocation } from "react-router-dom";

const useServiceWorkerUpdate = () => {
  const handleUpdateServiceWorker = () => {
    navigator.serviceWorker.getRegistrations().then((regs) =>
      regs.forEach((reg) => {
        reg.waiting.postMessage({ type: "SKIP_WAITING" });
      })
    );
    window.localStorage.setItem("updateingSW", true);
    window.location.reload();
  };

  const handleAwaitingServiceWorker = useCallback((reg) => {
    const waitingWorker = reg.waiting;
    if (waitingWorker == null) {
      return;
    }
    if (navigator.serviceWorker.controller) {
      toastr.confirm(
        `A new version of CrossCover is available; click OK to update. If any issues, restart your browser.`,
        {
          onOk: handleUpdateServiceWorker,
        }
      );
    }
  }, []);
  const location = useLocation();
  useEffect(() => {
    // check for sw updates on page change
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((regs) => {
        regs.forEach((reg) => {
          reg.onupdatefound = handleAwaitingServiceWorker;
          handleAwaitingServiceWorker(reg);
          reg.update();
        });
      });
    }
  }, [handleAwaitingServiceWorker, location]);
};

export default useServiceWorkerUpdate;
