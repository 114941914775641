//Copyright 2018 Nathan Moore

import { Segment, Icon } from "semantic-ui-react";
import DecisionSummaryList from "./DecisionSummaryListV2";
import { NodeDecisionAction } from "features/hooks/navigatorReducer";

interface IDecisionSummaryProps {
  decisionSummary: NodeDecisionAction[];
}

const DecisionSummary = ({ decisionSummary }: IDecisionSummaryProps) => {
  // let decisionSummaryList = this.props.getDecisionSummaryList(decisionSummary);

  return (
    <Segment.Group>
      <Segment inverted color="grey" data-testid="decisionSummary">
        <h3>
          <Icon name="user md" />
          Decision Summary
        </h3>
      </Segment>
      <Segment data-testid="decision-summary-list">
        <DecisionSummaryList decisionSummary={decisionSummary} />
      </Segment>
    </Segment.Group>
  );
};

export default DecisionSummary;
