import { Box } from "@mui/material";
import {
  checkIfRuleIsMet,
  disableNavigation,
} from "features/NodeEditorV2/NodeEditorUtils";

import { Dispatch } from "react";
import {
  ILinkButtonBlock,
  IDisplayVariablesBlock,
  IFileBlock,
  IImageBlock,
  IVideoBlock,
  NodeBlock,
  IChoicesBlock,
  IButtonBlock,
  IDynamicNode,
  NodeBlockData,
  IShowHideBlock,
  BlockTypes,
  IRichTextAreaBlock,
  ISTarTBackBlock,
} from "../../../../../types/Nodes";
import NodeLinkButtonEditor from "../NodeLinkButtonEditor";
import NodeButtonEditor from "../NodeButtonEditor";
import NodeChoicesEditor from "../NodeChoicesEditorComponents/NodeChoicesEditor";
import NodeDisplayVariablesEditor from "../NodeDisplayVariablesEditor";
import NodeFileEditor from "../NodeFileEditor";
import NodeImageEditor from "../NodeImageEditor";
import NodeVideoEditor from "../NodeVideoEditor";
import NodeShowHideEditor from "../NodeShowHideEditor";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";
import NodeTextAreaEditor from "../NodeTextAreaEditor";
import {
  isChoicesBlock,
  isFileBlock,
  isLinkBlock,
} from "features/pathwaybuilder/utils/pathwayHelperV2";
import STarTBackBlock from "../STarTBack/STarTBackBlock";
import { INodeViewSettings } from "features/NodeViewer/NodeView";

const BlockComponent = ({
  isEditing,
  block,
  dispatch,
  pathwayEditorDispatch,
  nodes,
  pathwayState,
  isPreview,
  state,
  onEdit,
  blockEditInfos,
  onAddBlock,
  onSave,
  nodeSettings,
  isShowingBlockRules,
}: {
  isEditing: boolean;
  block: NodeBlock;
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  pathwayEditorDispatch: Dispatch<PathwayActions>;
  nodes?: IDynamicNode[];
  pathwayState: Partial<PathwayState>;
  isPreview: boolean;
  state: IDynamicNode;
  onEdit?: (block: NodeBlock | undefined) => void;
  blockEditInfos?: NodeBlock | undefined;
  onAddBlock?: (
    blockType: BlockTypes,
    data: NodeBlockData,
    row?: number,
    column?: number
  ) => NodeBlock | undefined;
  onSave?: () => Promise<void>;
  nodeSettings: INodeViewSettings;
  isShowingBlockRules: boolean;
}) => {
  // const [isRuleMet, setIsRuleMet] = useState<boolean>(false);

  // useEffect(() => {
  //   if (pathwayState.nodeContext && block.blockRules) {
  //     setIsRuleMet(
  //       checkIfRuleIsMet(block.blockRules, pathwayState.nodeContext)
  //     );
  //   }
  // }, [pathwayState.nodeContext, block.blockRules]);

  const isRuleMet = checkIfRuleIsMet(
    block.blockRules,
    pathwayState.nodeContext ?? { fields: [] }
  );

  const isButtonType =
    block.blockType === "linkButton" || isChoicesBlock(block);

  return (
    <Box
      onClick={(e: any) =>
        e.detail === 2 && !isEditing && !isPreview && onEdit?.(block)
      }
      sx={{
        backgroundColor: block.blockOptions.backgroundColor,
        border: `2px solid ${block.blockOptions.borderColor}`,
        borderRadius: `${block.blockOptions.borderRadius}`,
        color: block.blockOptions.fontColor,
        opacity:
          isEditing || (isRuleMet && !isPreview) || isPreview ? "1" : ".4",
        display: nodeSettings.showAllLogicAnswers
          ? "block"
          : (isPreview && isRuleMet) || !isPreview
          ? "block"
          : "none",
        p: isButtonType && isPreview ? 0 : 0.25,
      }}
    >
      {block.blockType === "image" && (
        <NodeImageEditor
          key={block.blockId}
          block={block as IImageBlock}
          dispatch={dispatch}
          isEditing={isEditing}
          onEdit={onEdit}
          isPreview={isPreview}
        />
      )}
      {block.blockType === "video" && (
        <NodeVideoEditor
          block={block as IVideoBlock}
          dispatch={dispatch}
          isEditing={isEditing}
          onEdit={onEdit}
          isPreview={isPreview}
        />
      )}

      {block.blockType === "richTextArea" && (
        <NodeTextAreaEditor
          key={block.blockId}
          block={block as IRichTextAreaBlock}
          dispatch={dispatch}
          onEdit={onEdit}
          isEditing={isEditing}
        />
      )}

      {isChoicesBlock(block) && (
        <NodeChoicesEditor
          key={block.blockId}
          block={block as IChoicesBlock}
          dispatch={dispatch}
          isEditing={isEditing}
          disableBlockInteractivity={
            // state.editPanelInfos
            blockEditInfos ? true : false
          }
          pathwayEditorDispatch={pathwayEditorDispatch}
          pathwayState={pathwayState}
          blockEditInfos={blockEditInfos}
          onEdit={onEdit}
          state={state}
          isPreview={isPreview}
          nodeSettings={nodeSettings}
        />
      )}
      {isFileBlock(block) && (
        <NodeFileEditor
          key={block.blockId}
          block={block as IFileBlock}
          dispatch={dispatch}
          isEditing={isEditing}
          onEdit={onEdit}
          isPreview={isPreview}
        />
      )}
      {isLinkBlock(block) && (
        <NodeLinkButtonEditor
          block={block as ILinkButtonBlock}
          pathwayEditorDispatch={pathwayEditorDispatch}
          isEditing={isEditing}
          nodes={nodes}
          pathwayState={pathwayState}
          dispatch={dispatch}
          navigationBlocked={disableNavigation(
            state,
            blockEditInfos,
            pathwayState.nodeContext ?? null,
            block
          )}
          nodeId={state.id ?? ""}
          isPreview={isPreview}
          onAddBlock={onAddBlock}
          onEdit={onEdit}
          onSave={onSave}
          currentNode={state}
          nodeSettings={nodeSettings}
        />
      )}
      {block.blockType === "button" && (
        <NodeButtonEditor
          block={block as IButtonBlock}
          dispatch={dispatch}
          isEditing={isEditing}
          pathwayEditorDispatch={pathwayEditorDispatch}
          pathwayState={pathwayState}
        />
      )}
      {block.blockType === "displayVariables" && (
        <NodeDisplayVariablesEditor
          block={block as IDisplayVariablesBlock}
          pathwayState={pathwayState}
          dispatch={dispatch}
          isEditing={isEditing}
          state={state}
        />
      )}
      {block.blockType === "showHide" && (
        <NodeShowHideEditor
          block={block as IShowHideBlock}
          dispatch={dispatch}
          isEditing={isEditing}
          state={state}
          pathwayState={pathwayState}
          pathwayEditorDispatch={pathwayEditorDispatch}
          isPreview={isPreview}
        />
      )}
      {block.blockType === "startback" && (
        <STarTBackBlock
          block={block as ISTarTBackBlock}
          isEditing={isEditing}
          nodes={nodes}
          dispatch={dispatch}
          pathwayEditorDispatch={pathwayEditorDispatch}
          pathwayState={pathwayState}
          navigationBlocked={disableNavigation(
            state,
            blockEditInfos,
            pathwayState.nodeContext ?? null,
            block
          )}
        />
      )}
    </Box>
  );
};

export default BlockComponent;
