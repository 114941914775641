import { NodeDecisionAction } from "features/hooks/navigatorReducer";
import { List } from "semantic-ui-react";

const decisionToIgnore = ["continue to full pathway", "yes", "no", "continue"];

const isDecisionIgnored = (decision: string) => {
  return decisionToIgnore.includes(decision.toLowerCase());
};

const DecisionSummaryList = ({
  decisionSummary,
}: {
  decisionSummary: NodeDecisionAction[];
}) => {
  return (
    <List>
      {decisionSummary &&
        decisionSummary.map((decision, index) => (
          <List.Item key={index}>
            {!isDecisionIgnored(decision.header) && decision.header && (
              <>
                <List.Icon name="caret right" />
                <List.Content> {decision.header}</List.Content>
                {Boolean(decision.items.length) && (
                  <List.List style={{ marginLeft: "10px" }}>
                    {decision.items
                      .concat()
                      .sort((a, b) => a.order - b.order)
                      .map((item) => (
                        <List.Item key={`${item.namespace}$$${item.name}`}>
                          <List.Icon name="angle double right" />
                          <List.Content>
                            {item.groupName
                              ? `${item.groupName} - ${item.name}`
                              : item.name}
                          </List.Content>
                        </List.Item>
                      ))}
                  </List.List>
                )}
              </>
            )}
          </List.Item>
        ))}
    </List>
  );
};
export default DecisionSummaryList;
