import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  SwipeableDrawer,
} from "@mui/material";
import { styled } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import React, { useState } from "react";
import useUser from "features/hooks/useUser";
import { Link, useHistory } from "react-router-dom";
import SearchPathways from "app/components/PathwaySearchDropdown/SearchPathways";
import MenuIcon from "@mui/icons-material/Menu";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SearchIcon from "@mui/icons-material/Search";
import useModules from "features/hooks/useModules";
import CloseIcon from "@mui/icons-material/Close";
import { hexToRgb } from "@mui/system";
import SharedAccountGate from "app/components/SharedAccountGate";

const hexToRgbToValue = (hexValue: string) =>
  hexToRgb(hexValue).replace("rgb(", "").replace(")", "");
//const AppBar = styled(MuiAppBar, {

export const AppBar = styled("div")(({ theme }) => ({
  height: "56px",
  justifyContent: "space-between",
  "& svg": {
    color: "white",
  },
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 3px 5px -1px rgba(${hexToRgbToValue(
    theme.module.colour
  )},0.2), 0px 5px 8px 0px rgba(${hexToRgbToValue(
    theme.module.colour
  )},0.14), 0px 1px 14px 0px rgba(${hexToRgbToValue(
    theme.module.colour
  )},0.12)`,
  color: "white",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 3, 2, 3),
  borderBottom: "4px solid #fff",
  borderBottomColor: theme.module.colour,
  zIndex: 10,
}));

export const DrawerHeader = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "center",
  "> #logotext": {
    display: "block",
    color: theme.palette.primary.contrastText,
  },
}));

const TopBarMobile = () => {
  const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { profile, logOut } = useUser();
  const history = useHistory();

  const { modules, setModule, activeModule } = useModules();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    await logOut();
    history.push("/");
  };

  const onModuleSelect = (moduleId: string) => {
    setModule(moduleId);
    history.push("/launchpads");
  };

  const list = () => (
    <Box
      role="presentation"
      onClick={() => setToggleDrawer((prev) => !prev)}
      onKeyDown={() => setToggleDrawer((prev) => !prev)}
    >
      <List>
        <ListItemButton
          sx={{
            minHeight: 48,
            px: 2.5,
            width: "100%",
          }}
        >
          <CloseIcon sx={{ color: "primary.main" }} />
        </ListItemButton>
        {modules &&
          modules
            .concat()
            .sort((a, b) => a.order - b.order)
            .map((module, index) => (
              <ListItemButton
                key={module.id}
                sx={{
                  minHeight: 48,
                  justifyContent: "initial",
                  px: 2.5,
                  backgroundColor:
                    activeModule && activeModule.id === module.id
                      ? "#E6F8F7"
                      : "",

                  opacity: module.isActive ? 1 : 0.5,
                }}
                onClick={() => onModuleSelect(module.id || "")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "150px",
                    mr: 3,
                    justifyContent: "flex-start",
                  }}
                >
                  <img src={module.logoFull} alt={module.title} height="50px" />
                </ListItemIcon>
              </ListItemButton>
            ))}
      </List>
    </Box>
  );

  return (
    <AppBar>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: searchOpen ? "100%" : "inherit",
          height: "56px",
        }}
      >
        <React.Fragment>
          <IconButton onClick={() => setToggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor="left"
            open={toggleDrawer}
            onClose={() => setToggleDrawer(false)}
            onOpen={() => setToggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
        </React.Fragment>

        <IconButton onClick={() => setSearchOpen((prev) => !prev)}>
          {searchOpen ? <CloseIcon /> : <SearchIcon />}
        </IconButton>
        {searchOpen && (
          <SearchPathways
            onSelect={(pathwayId) => {
              history.push(`/pathway/${pathwayId}`);
              setSearchOpen((prev) => !prev);
            }}
          />
        )}
      </div>
      {!searchOpen && (
        <>
          <DrawerHeader to="/launchpads">
            <IconButton color="inherit" aria-label="menu" sx={{ m: 0 }}>
              <img
                style={{ height: "25px" }}
                src="https://firebasestorage.googleapis.com/v0/b/orthopathwaytestdatabase.appspot.com/o/assets%2Freverse_crosscover_logo_portrait.svg?alt=media&token=3df345b1-dc42-4ad7-8b24-c16a8238ad6e"
                alt="Crosscover logo"
              />
            </IconButton>
          </DrawerHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="help desk"
              href="https://primumdigital.atlassian.net/servicedesk/customer/portals"
              target="_blank"
              rel="noreferrer"
            >
              <HelpOutlineIcon />
            </IconButton>
            {Boolean(profile) && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  elevation={0}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <SharedAccountGate allowed={false}>
                    <MenuItem onClick={handleClose}>
                      <Link style={{ color: "inherit" }} to="/user-profile">
                        Profile
                      </Link>
                    </MenuItem>
                  </SharedAccountGate>
                  <MenuItem onClick={onLogout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </>
      )}
    </AppBar>
  );
};

export default TopBarMobile;
