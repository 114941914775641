import SafeHtml from "app/components/SafeHtml";
import { Dispatch } from "react";
import { Button, Embed, Icon, Modal, Segment } from "semantic-ui-react";
import { INode } from "../../../types/Nodes";
import CopyToClipboardView from "./components/CopyToClipboardView";
import GoBackButton from "./components/GoBackButtonView";
import NodeActionView from "./components/NodeActionView";
import NodeSystemDates from "./components/NodeSystemDatesView";
import NodeFilesPreview from "./components/NodeFilesView";
import NodeImagePreview from "./components/NodeImageView";
import NodeInfoTenantAndNode from "./components/NodeInfoTenantAndNode";
import NodePrint from "./components/NodePrint";
import NodeRules from "./components/NodeRules";
import { NodeToLogic } from "../pathwaybuilder/utils/pathwayHelper";
import NodeLogicView from "./components/NodeLogicView";

import { Alert, styled, useTheme } from "@mui/material";
import { IHighlightedNode } from "./NodeComparisonView";
import WidgetComponent from "./components/WidgetComponent";
import {
  NodeDecisionAction,
  PathwayActions,
  PathwayState,
} from "features/hooks/navigatorReducer";

export type DisplayContext = "EDITOR" | "VIEWER";

export type NodeViewSettings =
  | "DISABLE_NAVIGATE_NODES"
  | "DISABLE_PRINT_CONTROLS"
  | "SHOW_CREATED_AT"
  | "GOBACK_TO_TABLE"
  | "GOBACK_TO_TREE"
  | "GOBACK_TO_HOME";

export interface INodeViewSettings {
  disableNavigation?: boolean;
  disablePrintControls?: boolean;
  hideCreatedAt?: boolean;
  onExitPathway?: () => void;
  allowHtml?: boolean;
  showAllLogicAnswers?: boolean;
  showAllWidgetAnswers?: boolean;
  tagLogicAnswersWithActions?: boolean;
  tagText?: string;
  showRuleStrings?: boolean;
  tagWidgetAnswersWithActions?: boolean;
  widgetTagText?: string;
  alwaysShowScores?: boolean;
  nextEpisodeNodeTargetId?: string;
}

export interface INodeViewProps {
  node: INode;
  pathwayNodes?: INode[];
  dispatch: Dispatch<PathwayActions>;
  nodeSettings?: INodeViewSettings;
  pathwayState: PathwayState;
  displayContext?: DisplayContext;
  decisionSummary: NodeDecisionAction[];
  className?: string;
  showAnswers?: boolean;
  highlightTreeView?: boolean;
  collectionId: string;
}

const isEndNode = (node: INode) => {
  return (
    !Boolean(
      Array.isArray(node.actions) && node.actions.filter((x) => x).length
    ) &&
    !Boolean(
      Array.isArray(node.ruleActions) &&
        node.ruleActions.filter((x) => x).length
    )
  );
};

export const isNodeComparison = (object: any): object is IHighlightedNode => {
  return "rulesStrings" in object;
};

const NodeView = ({
  node,
  dispatch,
  nodeSettings,
  pathwayState,
  decisionSummary,
  className,
  displayContext,
  pathwayNodes,
  showAnswers,
  highlightTreeView,
  collectionId,
}: INodeViewProps) => {
  const defaultSettings: INodeViewSettings = {
    disableNavigation: false,
    disablePrintControls: false,
    hideCreatedAt: false,
    allowHtml: false,
  };

  const combinedSettings = { ...defaultSettings, ...nodeSettings };

  const theme = useTheme();

  return (
    <>
      <div className={className}>
        {!Boolean(node.id) && displayContext === "EDITOR" && (
          <Alert severity="warning">
            This node does not current exist. Click Save to create it.
          </Alert>
        )}
        {!Boolean(node.id) && displayContext === "VIEWER" && (
          <Alert severity="error">
            This node does exist. Please report this issue via the report issue
            button
          </Alert>
        )}
        <Segment.Group>
          <NodeInfoTenantAndNode
            node={node}
            allowHtml={combinedSettings.allowHtml}
          />
          {node.youtube && (
            <Segment data-testid="youtubeVideo">
              <Embed defaultActive={true} id={node.youtube} source="youtube" />
            </Segment>
          )}
          {node.vimeo && (
            <Segment data-testid="vimeoVideo">
              <Embed defaultActive={true} id={node.vimeo} source="vimeo" />
            </Segment>
          )}
          {node.video && (
            <Segment data-testid="videoVideo">
              <Embed defaultActive={true} url={node.video} source={undefined} />
            </Segment>
          )}
          {node.image && <NodeImagePreview node={node} />}
          {node.nodeContentArea && (
            <Segment data-testid="richTextArea">
              <div>
                <SafeHtml html={node.nodeContentArea} allowHtml={true} />
              </div>
            </Segment>
          )}
          {node.question && (
            <Segment data-testid="question">
              <p>
                Question:{" "}
                <SafeHtml
                  html={node.question}
                  allowHtml={combinedSettings.allowHtml}
                />
              </p>
            </Segment>
          )}
          {/* // *Widget Component */}
          {node.widgets?.length !== 0 && (
            <WidgetComponent
              node={node}
              tenantId={""}
              dispatch={dispatch}
              nodeContext={pathwayState.nodeContext}
              nodeSettings={nodeSettings}
              collectionId={collectionId}
            />
          )}

          {(node.widgets === undefined || node.widgets.length === 0) &&
            Boolean(node.logicfields && node.logicfields.length) && (
              <>
                <NodeLogicView
                  logic={NodeToLogic(node)}
                  nodeContext={pathwayState.nodeContext}
                  dispatch={dispatch}
                  allowHtml={nodeSettings?.allowHtml}
                  alwaysShowScores={nodeSettings?.alwaysShowScores}
                />
                <NodeRules
                  dispatch={dispatch}
                  nodeContext={pathwayState.nodeContext}
                  node={node}
                  showAnswers={showAnswers}
                  highlightTreeView={highlightTreeView}
                  showAllLogicAnswers={nodeSettings?.showAllLogicAnswers}
                  tagLogicAnswersWithActions={
                    nodeSettings?.tagLogicAnswersWithActions
                  }
                  tagText={nodeSettings?.tagText}
                  showRuleStrings={nodeSettings?.showRuleStrings}
                  nextEpisodeNodeTargetId={
                    nodeSettings?.nextEpisodeNodeTargetId
                  }
                />
              </>
            )}
          {/* // *hide if widget is present */}
          {(node.widgets === undefined || node.widgets.length === 0) &&
            node.answers && (
              <NodeActionView
                node={node}
                dispatch={dispatch}
                disableActions={combinedSettings.disableNavigation}
                showAnswers={showAnswers}
                highlightTreeView={highlightTreeView}
                displayContext={displayContext}
                collectionId={collectionId}
              />
            )}
          {node.files && node.files[0] && (
            <NodeFilesPreview
              node={node}
              allowHtml={combinedSettings.allowHtml}
            />
          )}

          {node.print && (
            <div>
              <CopyToClipboardView
                tenantId={pathwayState.tenantId}
                node={node}
                currentPathway={""}
                decisionSummary={decisionSummary}
                rawContent={node.nodeContentArea}
                isDisabled={
                  combinedSettings.disablePrintControls || theme.isMobileView
                }
                dispatch={dispatch}
              />
              {(combinedSettings.disablePrintControls ||
                theme.isMobileView) && (
                <Button fluid color="green">
                  <Icon name="print" />
                  Summary Print Preview
                </Button>
              )}

              {!combinedSettings.disablePrintControls && !theme.isMobileView && (
                <Modal
                  trigger={
                    <Button
                      fluid
                      color="green"
                      onClick={() =>
                        dispatch({
                          type: "ADD_EPISODE_OUTCOME",
                          payload: { outcome: "Clicked to Print Summary" },
                        })
                      }
                    >
                      <Icon name="print" />
                      Summary Print Preview
                    </Button>
                  }
                  closeIcon
                >
                  <Modal.Header>Print Preview</Modal.Header>
                  <Modal.Content>
                    <NodePrint
                      outcome={node.title}
                      managementPlan={node.nodeContentArea}
                      decisionSummary={pathwayState.decisionSummary}
                      tenantId={pathwayState.tenantId}
                      currentPathway={pathwayState.currentPathwayId}
                    />
                  </Modal.Content>
                </Modal>
              )}
            </div>
          )}
          <GoBackButton
            settings={combinedSettings}
            dispatch={dispatch}
            route={pathwayState.route}
            pathwayId={pathwayState.currentPathwayId}
            isEndNode={isEndNode(node)}
          />
          {node.createdAt && !combinedSettings.hideCreatedAt && (
            <NodeSystemDates node={node} />
          )}
        </Segment.Group>
      </div>
    </>
  );
};
export default styled(NodeView)({});
