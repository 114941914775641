import { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";

import { useTheme } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import GoogleAuth from "app/assets/google-auth.png";
import MicrosoftAuth from "app/assets/microsoft-auth.png";
import AppleAppStore from "app/assets/logos-apple-app-store.png";
import GooglePlay from "app/assets/logos-google-play-icon.png";

import functions from "app/common/util/functions";
import LoadingButton from "app/components/LoadingButton";

import { IRegisterAuthenticatorResponse } from "../../../../functions/src/http/userManagement/registerAuthenticator";
import { MFAFactors } from "../../../../types/User";

interface IRegisterForAuthenticatorProps {
  onRegistrationComplete: (factor: MFAFactors) => void;
  onRegistrationError: (error: string) => void;
  enableMFALater?: () => void;
}
const RegisterForAuthenticator = ({
  enableMFALater,
  onRegistrationComplete,
}: IRegisterForAuthenticatorProps) => {
  const [registrationData, setRegistrationData] =
    useState<IRegisterAuthenticatorResponse>();
  const [codeConfirmation, setCodeConfirmation] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const theme = useTheme();

  const onConfirmCode = async () => {
    setLoading(true);
    try {
      const result = await functions.confirmAuthenticator({
        enteredCode: codeConfirmation,
      });

      if (result.tokenIsValid) {
        onRegistrationComplete("AUTHENTICATOR");
        return;
      }
      setError("Invalid code entered");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const generateRegistrationData = async () => {
      const registrationResponse = await functions.registerAuthenticator();
      setRegistrationData(registrationResponse);
    };
    generateRegistrationData();
  }, []);

  const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    }
    if ("clipboard"! in navigator) {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyCode = async (text: string) => {
    try {
      await copyTextToClipboard(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {registrationData === undefined && <LinearProgress></LinearProgress>}
      {registrationData && (
        <>
          <Box
            sx={{
              border: "1px solid green",
              borderColor: "primary.main",
              borderRadius: 1,
              padding: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography textAlign="center" variant="h5" component="h4">
                Register Authenticator
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    mt: 1,
                    minWidth: "calc(100% - 208px)",
                    width: "calc((450px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                >
                  <Typography>
                    1. Install one of the supported authenticators listed below
                    from your mobile devices app store and scan the displayed QR
                    code. Alternatively, you can manually enter the code
                    displayed below the QR code when requested in the
                    authenticator app.
                  </Typography>
                  <Typography>
                    2. Once CrossCover is displayed within the authenticator
                    app, enter the code into the “Enter code” box displayed on
                    screen and press the “Complete Registration” button.
                  </Typography>

                  <Accordion disableGutters={true} sx={{ mb: 1 }}>
                    <AccordionSummary
                      sx={{ py: 0 }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Supported Authenticators</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ py: 0 }}>
                      <List sx={{ pt: 0 }}>
                        <ListItem disablePadding sx={{ mb: 1 }}>
                          <ListItemIcon sx={{ mr: 2 }}>
                            <img
                              src={GoogleAuth}
                              alt={"Google Authenticator"}
                              height="30"
                            />
                          </ListItemIcon>
                          <ListItemText primary="Google Authenticator" />
                        </ListItem>

                        <ListItem disablePadding>
                          <ListItemIcon sx={{ mr: 2 }}>
                            <img
                              src={MicrosoftAuth}
                              alt={"Microsoft Authenticator"}
                              height="30"
                            />
                          </ListItemIcon>
                          <ListItemText primary="Microsoft Authenticator" />
                        </ListItem>
                      </List>

                      <Typography>
                        <i>
                          You can install apps on to your mobile for free via
                          the App Store on iOS and the Google Play Store on
                          Android.
                        </i>
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          p: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Link
                          underline="none"
                          target="_blank"
                          rel="noopener"
                          href="https://www.apple.com/uk/app-store/"
                        >
                          <Box
                            sx={{
                              border: "1px solid #C2C2C2",
                              borderRadius: "4px",
                              p: 1,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <img
                              src={AppleAppStore}
                              alt={"Google Authenicator"}
                              height="40"
                            />
                            <Typography>Apple Store</Typography>
                          </Box>
                        </Link>

                        <Link
                          underline="none"
                          target="_blank"
                          rel="noopener"
                          href="https://play.google.com/store/apps"
                        >
                          <Box
                            sx={{
                              border: "1px solid #C2C2C2",
                              borderRadius: "4px",
                              p: 1,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <img
                              src={GooglePlay}
                              alt={"Google Authenicator"}
                              height="40"
                            />
                            <Typography>Google Play</Typography>
                          </Box>
                        </Link>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  {theme.isMobileView && (
                    <>
                      <Typography>
                        3. Click on the field below to copy the code and paste
                        it into a field provided in your authenticator app.
                      </Typography>

                      <Tooltip
                        arrow
                        placement="top"
                        open={isCopied}
                        title="Link Copied!"
                      >
                        <FormControl
                          variant="outlined"
                          onClick={() =>
                            handleCopyCode(registrationData.secret)
                          }
                        >
                          <OutlinedInput
                            size="small"
                            inputProps={{
                              readOnly: true,
                            }}
                            id="outlined-adornment-registration-data"
                            type="text"
                            value={registrationData.secret}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                >
                                  <ContentCopyIcon
                                    sx={{ color: theme.palette.primary.main }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Tooltip>

                      <Typography>
                        4. Once CrossCover appears in your authenticator app,
                        enter the six-digit OTP code into the field provided to
                        complete your registration.
                      </Typography>
                    </>
                  )}
                </Box>

                {!theme.isMobileView && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      minWidth: "max-content",
                      width: "calc((450px - 100%) * 1000)",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      src={registrationData.qrCode}
                      alt="QRCode to register with authenticator"
                      width={200}
                    />
                    <div>{registrationData.secret}</div>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 1,
                }}
              >
                <TextField
                  sx={{
                    minWidth: "calc(100% - 208px)",
                    width: "calc((450px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    setCodeConfirmation(e.target.value);
                    setError("");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && codeConfirmation) {
                      e.preventDefault();
                      onConfirmCode();
                    }
                  }}
                  value={codeConfirmation}
                  label="Enter code"
                  error={error !== ""}
                  helperText={error}
                />
                <LoadingButton
                  disabled={!codeConfirmation}
                  sx={{
                    minWidth: "200px",
                    width: "calc((450px - 100%) * 1000)",
                    maxWidth: "100%",
                  }}
                  onClick={() => onConfirmCode()}
                  variant="contained"
                  isLoading={loading}
                  buttonText="Complete Registration"
                ></LoadingButton>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={enableMFALater}>I will enable later</Button>
          </Box>
        </>
      )}
    </>
  );
};

export default RegisterForAuthenticator;
