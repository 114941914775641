import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Segment from "app/components/SemanticUIMirrors/Segement";
import usePatient from "features/hooks/usePatient";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const ClinicalClerkingForm = () => {
  const { isPatientLoaded } = usePatient();
  if (!isPatientLoaded) return null;

  return (
    <Paper sx={{ width: 500 }}>
      <Segment data-testid="node-preview-header">
        <h3 style={{ textAlign: "center" }}>Clinical Clerking</h3>
      </Segment>
      <Box sx={{ display: "flex", flexDirection: "column", m: 2 }}>
        <TextField label="Presenting complaint" sx={{ my: 2 }} />
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ bgcolor: "divider" }}
          >
            <Typography>History</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: "flex", flexDirection: "column", m: 2, gap: 2 }}
          >
            <TextField
              multiline
              label="History of presenting complaint"
              rows={8}
            />
            <TextField multiline label="Past medical history" rows={4} />
            <TextField multiline label="Family history" rows={4} />
            <TextField multiline label="Drug history" rows={4} />
            <TextField multiline label="Allergies" rows={4} />
            <TextField multiline label="Social history" rows={4} />
          </AccordionDetails>
        </Accordion>
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Examination and Investigation findings</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: "flex", flexDirection: "column", m: 2, gap: 2 }}
          >
            <TextField multiline label="Examination findings" rows={4} />
            <TextField multiline label="Investigation finding" rows={4} />
          </AccordionDetails>
        </Accordion>
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Ideas, concerns and expectations</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: "flex", flexDirection: "column", m: 2, gap: 2 }}
          >
            <TextField multiline label="Ideas" rows={4} />
            <TextField multiline label="Concerns" rows={4} />
            <TextField multiline label="Expectations" rows={4} />
          </AccordionDetails>
        </Accordion>
        <TextField multiline label="Additional notes" rows={4} sx={{ my: 2 }} />
        <Button variant="contained" startIcon={<AttachFileIcon />}>
          Attach File(s)
        </Button>
      </Box>
    </Paper>
  );
};

export default ClinicalClerkingForm;
