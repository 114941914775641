import { Box } from "@mui/material";
import { Button, Header, Segment } from "semantic-ui-react";
interface IPathwayDisclaimerProps {
  onAccept?: () => void | Promise<void>;
  onDecline?: () => void | Promise<void>;
  isActivateAccount?: boolean;
  displayButtons?: boolean;
  hideSegmentBoarder?: boolean;
  hideTCAgreement?: boolean;
}
const PathwayDisclaimer = ({
  isActivateAccount,
  displayButtons,
  onAccept,
  onDecline,
  hideSegmentBoarder,
  hideTCAgreement,
}: IPathwayDisclaimerProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mr: 1 }}>
      {displayButtons && (
        <Segment>
          <Button positive onClick={onAccept}>
            Accept
          </Button>
          <Button negative onClick={onDecline}>
            Reject
          </Button>
        </Segment>
      )}

      <Segment
        basic={hideSegmentBoarder ? true : false}
        style={{
          overflow: "auto",
          maxHeight: isActivateAccount ? "" : "70vh",
        }}
      >
        {!hideTCAgreement && (
          <Header as={isActivateAccount ? "h5" : "h3"}>
            BY CLICKING ON THE "ACCEPT" BUTTON BELOW YOU AGREE TO THE TERMS OF
            THIS AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS, CLICK ON THE
            "REJECT" BUTTON BELOW.
          </Header>
        )}

        <p>
          <b>Who we are and what this Agreement does</b>
        </p>
        <p>
          We are Primum Digital Ltd of Harscombe House, 1 Darklake View,
          Estover, Plymouth, PL6 7TL. This Agreement is a license for you to
          use:
        </p>
        <ul>
          <li>
            The CrossCover application software via which the Documentation and
            Service are delivered, the data supplied with the software, and any
            updates or supplements to it (the <b>App</b>);
          </li>
          <li>
            The related online and electronic documentation (
            <b>Documentation</b>
            ); and
          </li>
          <li>
            The service you connect to via the App and the content we provide to
            you through it, including the Documentation and pathways (
            <b>Service</b>), as permitted in these terms.
          </li>
        </ul>

        <p>
          <b>What is CrossCover?</b>
        </p>
        <p>
          CrossCover is a decision support tool, designed to provide educational
          reference information to help you (<b> the Healthcare Professional</b>
          ) to manage patients by delivering user-generated decision support
          pathways (<b>“pathways”</b>). The <b>“pathways”</b> support you as a
          healthcare professional to use your own knowledge and judgement to
          make clinical decisions. Each pathway has decision points and options
          are explained, but you as the healthcare professional decide which
          path to take.{" "}
        </p>
        <p>
          {" "}
          These pathways are created by the Pathway Editors in your Trust (as
          defined below), and made available via the App.{" "}
        </p>
        <p>
          The App does not give specific advice on individual patients and
          should only be used by medical practitioners acting within their
          clinical competencies. The App is not a substitute for professional
          medical qualifications and is used at your own risk. Your professional
          responsibility may be engaged if you rely on the App and fail to use
          your discretion or refer matters to the supervising consultant as
          appropriate.
        </p>
        <p>
          {" "}
          For the avoidance of doubt, to accept these terms and use the App you
          must be:
        </p>
        <ul>
          <li>18 or over; and</li>
          <li>
            a registered medical practitioner and employed by an NHS Trust with
            whom we have a licence agreement in place for the use of the App (
            <b>Trust</b>).
          </li>
        </ul>
        <p>
          In addition to the above, your permission to use the App,
          Documentation and Services is subject to our ongoing contractual
          relationship with your Trust and we may terminate this Agreement
          without prior notice to you.
        </p>
        <p>
          <b>App Users</b>
        </p>
        <p>
          You may use the App, Documentation and Services in accordance with
          this Agreement in the course of your employment within a Trust. If you
          are a Pathway Editor or Chief Editor (as defined below), you will have
          additional permissions and responsibilities under the terms of this
          Agreement.
        </p>
        <p>
          {" "}
          A <b>Pathway Editor</b> is a consultant, appointed by their Trust, to
          create pathways for use within their Trust, as set out in this
          Agreement. Pathway Editors cannot make the pathways they create live.
        </p>
        <p>
          {" "}
          The <b>Chief Editor</b> is a Pathway Editor nominated by your Trust to
          have day-to-day management of the Trust’s relationship with us. A
          Chief Editor has all of the same controls as a Pathway Editor. In
          addition, only a Chief Editor is authorised to approve and make live
          or takedown pathways in the App on behalf of the Trust.
        </p>
        <p>
          <b>Your Privacy</b>
        </p>
        <p>
          Under data protection legislation, we are required to provide you with
          certain information about who we are, how we process your personal
          data and for what purposes and your rights in relation to your
          personal data and how to exercise them. This information is provided
          in the Privacy Policy and it is important that you read that
          information.
        </p>
        <p>
          Please be aware that internet transmissions are never completely
          private or secure and that any message or information you send using
          the App may be read or intercepted by others, even if there is a
          special notice that a particular transmission is encrypted.
        </p>
        <p>
          <b>Operating system requirements.</b> This App requires a device with
          a minimum of 5 Megabytes of memory available and access via the Google
          Chrome web browser version 75 or above.
        </p>
        <p>
          <b>Support for the App and how to tell us about problems.</b> If you
          experience difficulties in accessing the App or the Service, you
          should approach your Chief Editor in the first instance.
        </p>
        <p>
          For support in relation to faults with the App or any technical
          difficulties, the Chief Editor should contact us as soon as possible
          by email at{" "}
          <a href="mailto:support@primumdigital.com">
            {" "}
            support@primumdigital.com
          </a>{" "}
          The Trust’s IT team is not permitted to make any amendments to the
          App.
        </p>
        <p>
          We do not provide support in respect of the content of the pathways or
          their use and implementation. Please address any concerns in this
          regard to your Chief Editor.
        </p>
        <p>
          If you want to learn more about the App or the Service or have any
          problems using them please take a look at our support resources at{" "}
          <a
            href="https://www.crosscover.co.uk"
            target="_blank"
            rel="noreferrer"
          >
            www.crosscover.co.uk
          </a>
        </p>
        <p>
          <b>
            How you may use the App, including how many devices you may use it
            on
          </b>
        </p>
        <p>
          In return for your agreeing to comply with these terms you may, in the
          course of your employment only:
        </p>
        <ul>
          <li>
            download or stream a copy of the App onto any number of devices and
            view, use and display the App, the Services and the Documentation on
            such devices;
          </li>
          <li>
            use any Documentation to support your permitted use of the App and
            the Service; and
          </li>
          <li>
            receive and use any free supplementary software code or update of
            the App incorporating "patches" and corrections of errors as we may
            provide to you.
          </li>
        </ul>
        <p>
          <b>How you may use the App if you are a Pathway Editor</b>
        </p>
        <p>
          If you are a Pathway Editor, in return for agreeing to comply with
          these terms you may, in addition to the rights set out above:
        </p>
        <ul>
          <li>
            access, view, import, amend and create pathways on behalf of your
            service within the Trust; and
          </li>
          <li>amend imported pathways for use within your Trust.</li>
        </ul>
        <p>Pathway Editors are responsible for ensuring:</p>
        <ul>
          <li>
            the accuracy and completeness of the pathways they create, including
            where they make amendments to an existing pathway imported from
            another Trust;
          </li>
          <li>
            that they and/or their Trust are the sole legal and beneficial
            owner(s) of all rights and interests, including the intellectual
            property rights, in the pathways and associated imagery which they
            incorporate into a pathway, including where they make amendments to
            an imported pathway; and
          </li>
          <li>
            that they have not licensed or assigned any of the rights or
            interests referred to above and, where applicable, they have the
            Trust’s permission to use its rights in the creation of pathways.
            Where the Trust is sole or joint legal and/or beneficial owner of
            the rights and interests in the materials submitted by a Pathway
            Editor to the Chief Editor, it is the Pathway Editor’s
            responsibility to ensure they have the Trust’s consent to the
            pathway being made live on the App.
          </li>
        </ul>
        <p>
          <b>How you may use the App if you are a Chief Editor.</b>
        </p>
        <p>
          In addition to the rights of a Pathway Editor, a Chief Editor may make
          pathways live on the App for use within their Trust. Once the Chief
          Editor makes a pathway live on the App, it may be accessed by all
          users inside their Trust and to other Trusts who use the App. The
          Chief Editor may also unpublish/withdraw pathways from the App.
        </p>
        <p>The Chief Editor is responsible for ensuring:</p>
        <ul>
          <li>
            the accuracy and completeness of the pathways used within their
            Trust;
          </li>
          <li>
            the accuracy and completeness of the pathways made live in the App,
            including where a Pathway Editor makes amendments to an imported
            pathway;
          </li>
          <li>
            that the Pathway Editor and/or their Trust are:
            <ul>
              <li>
                the sole legal and beneficial owner(s) of all rights and
                interests, including the intellectual property rights, in the
                pathways and associated imagery which they submit to the Chief
                Editor, including where a Pathway Editor makes amendments made
                to an imported pathway; or
              </li>
              <li>
                otherwise authorised to submit the materials to the Chief Editor
                to make live on the App
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Where the Trust is sole or joint legal and/or beneficial owner of the
          rights and interests in the materials submitted to the Chief Editor by
          a Pathway Editor, it is the Chief Editor’s responsibility to ensure,
          together with the Pathway Editor, that the Pathway Editor has the
          Trust’s consent to use submit the materials to the Chief Editor to be
          made live on the App.
        </p>
        <p>
          The Chief Editor is the point of contact for all enquiries regarding
          the use and content of pathways within the Trust and is responsible
          for replying to any such enquiries in a timely manner. We do not
          accept any liability to the Trust for any loss or damage suffered as a
          result of the Chief Editor’s failure to do so.{" "}
        </p>
        <p>
          The Chief Editor is responsible for liaising with us in relation to
          any faults within the App or any technical difficulties experienced by
          the Trust in respect of their use of the App. We do not accept any
          liability to the Trust for any loss or damage suffered as a result of
          the Chief Editor’s failure to report faults or technical difficulties
          to use in a timely manner.
        </p>
        <p>
          <b>You may not transfer the App to someone else.</b> We are giving you
          personally the right to use the App and the Service as set out above.
        </p>
        <p>
          You may not otherwise transfer the App or the Service to someone else,
          whether for money, for anything else or for free. If you sell any
          device on which the App is installed, you must remove the App from it.
        </p>
        <p>
          <b>Changes to these terms.</b> We may need to change these terms to
          reflect changes in law or best practice or to deal with additional
          features which we introduce.
        </p>
        <p>
          Wherever possible, we will give you notice of any change to these
          terms by notifying you of a change when you next start the App.
        </p>
        <p>
          If you do not accept the notified changes you will not be permitted to
          continue to use the App and the Service.
        </p>
        <p>
          <b>Update to the App and changes to the Service.</b> From time to time
          we may automatically update the App and change the Service to improve
          performance, enhance functionality, reflect changes to the operating
          system or address security issues. Alternatively we may ask you to
          update the App for these reasons.
        </p>
        <p>
          If you choose not to install such updates or if you opt out of
          automatic updates you may not be able to continue using the App and
          the Services.
        </p>
        <p>
          <b>If someone else owns the device you are using.</b> If you download
          or stream the App onto a device not owned by your Trust, you must have
          the Trust and the device owner's permission to do so. You will be
          responsible for complying with these terms, whether or not you or your
          Trust own the other device.
        </p>
        <p>
          <b>We may collect technical data about your device.</b> By using the
          App or any of the Services, you agree to us collecting and using
          technical information about the devices which you use the App on and
          related software, hardware and peripherals to improve our products and
          to provide any Services to you.
        </p>
        <p>
          <b>We are not responsible for other websites you link to.</b> The App
          or any Service may contain links to other independent websites which
          are not provided by us. Such independent sites are not under our
          control, and we are not responsible for and have not checked and
          approved their content or their privacy policies (if any).
        </p>
        <p>
          You will need to make your own independent judgement about whether to
          use any such independent sites, including whether to buy any products
          or services offered by them.
        </p>
        <p>
          <b>Licence restrictions</b>
        </p>
        <p>You agree that you will:</p>
        <ul>
          <li>
            not rent, lease, sub-license, loan, provide, or otherwise make
            available, the App or the Services in any form, in whole or in part
            to any person without prior written consent from us;
          </li>
          <li>
            not copy the App, Documentation or Services, except as part of the
            normal use of the App or where it is necessary for the purpose of
            back-up or operational security;
          </li>
          <li>
            not, except as expressly permitted to Pathway Editors under this
            Agreement, translate, merge, adapt, vary, alter or modify, the whole
            or any part of the App, Documentation or Services nor permit the App
            or the Services or any part of them to be combined with, or become
            incorporated in, any other programs, except as necessary to use the
            App and the Services on devices as permitted in these terms;
          </li>
          <li>
            not disassemble, de-compile, reverse engineer or create derivative
            works based on the whole or any part of the App or the Services nor
            attempt to do any such things, except to the extent that (by virtue
            of sections 50B and 296A of the Copyright, Designs and Patents Act
            1988) such actions cannot be prohibited because they are necessary
            to decompile the App to obtain the information necessary to create
            an independent program that can be operated with the App or with
            another program (<b>Permitted Objective</b>), and provided that the
            information obtained by you during such activities:
            <ul>
              <li>
                is not disclosed or communicated without the Licensor's prior
                written consent to any third party to whom it is not necessary
                to disclose or communicate it in order to achieve the Permitted
                Objective; and
              </li>
              <li>
                is not used to create any software that is substantially similar
                in its expression to the App;
              </li>
              <li>is kept secure; and</li>
              <li>is used only for the Permitted Objective;</li>
            </ul>
          </li>
          <li>
            comply with all applicable technology control or export laws and
            regulations that apply to the technology used or supported by the
            App or any Service.
          </li>
        </ul>
        <p>
          <b>Acceptable use restrictions</b>
        </p>
        <p>You must not:</p>
        <ul>
          <li>
            use the App or any Service in any unlawful manner, for any unlawful
            purpose, or in any manner inconsistent with these terms, or act
            fraudulently or maliciously, for example, by hacking into or
            inserting malicious code, such as viruses, or harmful data, into the
            App, any Service or any operating system;
          </li>
          <li>
            infringe our intellectual property rights or those of any third
            party in relation to your use of the App or any Service;
          </li>
          <li>
            transmit any material that is defamatory, offensive or otherwise
            objectionable in relation to your use of the App or any Service;
          </li>
          <li>
            use the App or any Service in a way that could damage, disable,
            overburden, impair or compromise our systems or security or
            interfere with other users;{" "}
          </li>
          <li>
            use the App or any Services as a substitute for medical knowledge
            and experience; and
          </li>
          <li>
            collect or harvest any information or data from any Service or our
            systems or attempt to decipher any transmissions to or from the
            servers running any Service.{" "}
          </li>
        </ul>
        <p>
          We are not liable to you or to your Trust for any loss or damage
          arising from your failure to comply with these restrictions.
        </p>
        <p>
          <b>Intellectual Property Rights</b>
        </p>
        <p>
          All intellectual property rights in the App, the Documentation and the
          Services throughout the world belong to us (or our licensors) and the
          rights in the App and the Services are licensed (not sold) to you. You
          have no intellectual property rights in, or to, the App, the
          Documentation or the Services other than the right to use them in
          accordance with these terms.{" "}
        </p>
        <p>
          In consideration of our granting you access to see, use and amend
          pathways from the App, you:
        </p>
        <ul>
          <li>
            assign to us with full title guarantee all your rights, title and
            interests in the pathways you create and/or make live in the App,
            including any rights, title and interest in amendments you make to
            pathways you have imported;{" "}
          </li>
          <li>
            agree that you have no rights in the pathways and that all pathways
            imported, created, amended, made live or otherwise available in the
            App belong to us both legally and beneficially; and{" "}
          </li>
          <li>
            agree that we may, at our sole discretion, make the pathways you
            make live in the App available to other Trusts or third parties.{" "}
          </li>
        </ul>
        <p>
          <b>Our responsibility for loss or damage suffered by you</b>
        </p>
        <p>
          <b>Medical Imagery.</b> All pathway imagery and anatomical drawings in
          the App are provided for illustration purposes only. We cannot
          guarantee that these images will be an accurate illustration of your
          patient’s anatomy. You should rely on your own medical knowledge and
          experience and we will not be liable to you for any loss or damage
          caused by your failure to do so.
        </p>
        <p>
          <b>We do not review pathways.</b> We do not review the pathways
          submitted by Pathway Editors. It is your responsibility to ensure you
          are using an appropriate pathway on a case by case basis. You should
          rely on your own medical knowledge and experience and we will not be
          liable to you for any loss or damage caused by your failure to do so.
        </p>
        <p>
          <b>
            We do not exclude or limit in any way our liability to you where it
            would be unlawful to do so.{" "}
          </b>
          This includes liability for death or personal injury caused by our
          negligence or the negligence of our employees, agents or
          subcontractors or for fraud or fraudulent misrepresentation.
        </p>
        <p>
          <b>
            We are responsible to you for foreseeable loss and damage caused by
            us.{" "}
          </b>
          If we fail to comply with these terms, we are responsible for loss or
          damage you suffer that is a foreseeable result of our breaking these
          terms or our failing to use reasonable care and skill. We are not
          responsible for any loss or damage that is not foreseeable.{" "}
        </p>
        <p>
          Loss or damage is foreseeable if either it is obvious that it will
          happen or if, at the time you accepted these terms, both we and you
          knew it might happen.
        </p>
        <p>
          <b>When we are liable for damage to property.</b> The App,
          Documentation and Services are solely for use in the course of your
          employment and we are not responsible for any loss or damage caused to
          personal devices or digital content. However, for the avoidance of
          doubt, we will not be liable for damage that could have been avoided
          by following our advice to apply an update offered or for damage that
          was caused by failing to correctly follow installation instructions or
          to have in place the minimum system requirements advised by us. We are
          not liable for user-generated digital content which you or your Trust
          download to your device(s).
        </p>
        <p>
          <b>We are not liable for business losses. </b>The App is for use as
          decision support tool to help you manage patients by delivering
          user-generated content created by you, Pathway Editors or third
          parties. Neither the App, nor the Services or Documentation as
          provided by us give specific advice on individual patients and should
          only be used by medical practitioners operating within their clinical
          competencies. If you use the App for any commercial, business or
          resale purpose we will have no liability to you for any loss of
          profit, loss of business, business interruption, or loss of business
          opportunity.{" "}
        </p>
        <p>
          <b>Limitations to the App, the Documentation and the Services. </b>
          The App, the Documentation and the Services are provided for general
          information purposes only as a decision support tool. They do not give
          specific advice on individual patients and should only be used by
          medical practitioners acting within their clinical competencies. They
          are not a substitute for professional medical qualifications and
          experience and they are used at your own risk. Whenever reasonably
          necessary, at your professional discretion, you must obtain specialist
          advice from your consultant or an appropriate member of your Trust
          before taking, or refraining from, any action on the basis of
          information obtained from the App, the Documentation or the Service.
          Although we make reasonable efforts to update the information provided
          by the App and the Service, we make no representations, warranties or
          guarantees, whether express or implied, that such information is
          accurate, complete or up to date. We are not liable for any loss or
          damage caused as a result of the use of content generated or
          downloaded by you, a Pathway Editor, your Trust or third parties.
        </p>
        <p>
          <b>
            Check that the App, the Documentation and the Services are suitable
            for you.
          </b>
          The App and the Services have not been developed to meet your
          individual requirements or manage and diagnose individual patients.
          Please check that the facilities and functions of the App, the
          Documentation and the Services available meet your requirements before
          use.
        </p>
        <p>
          <b>We are not responsible for events outside our control. </b>If our
          provision of the Services or support for the App or the Services is
          delayed by an event outside our control then we will contact your
          Trust as soon as possible to let them know. We will take steps to
          minimise the effect of the delay. Provided we do this we will not be
          liable for delays caused by the event.
        </p>
        <p>
          <b>
            We may end your rights to use the App, the Documentation and the
            Services if you break these terms.{" "}
          </b>
          We may end your rights to use the App, the Documentation and Services
          at any time by contacting your Trust if you have broken these terms in
          a serious way. If what you have done can be put right we will give you
          a reasonable opportunity to do so.
        </p>
        <p>
          If we end your rights to use the App, the Documentation and Services:
        </p>
        <ul>
          <li>
            You must stop all activities authorised by these terms, including
            your use of the App;
          </li>
          <li>
            You must delete or remove the App from all devices in your
            possession and immediately destroy all copies of the App which you
            have and confirm to us that you have done this; and
          </li>
          <li>We may deactivate your access to the Services.</li>
        </ul>
        <p>
          <b>We may transfer this Agreement to someone else. </b>We may transfer
          some or all of our rights and obligations under these terms to another
          organisation.{" "}
        </p>
        <p>
          <b>You may not transfer your rights to someone else.</b>You may not
          transfer your rights or your obligations under these terms to another
          person.
        </p>
        <p>
          <b>No rights for third parties.</b>A person who is not a party to this
          Agreement shall not have any rights under or in connection with it.
        </p>
        <p>
          <b>
            If a court finds part of this contract illegal, the rest will
            continue in force.
          </b>
          Each of the paragraphs of these terms operates separately. If any
          court or relevant authority decides that any of them are unlawful, the
          remaining paragraphs will remain in full force and effect.
        </p>
        <p>
          <b>
            Even if we delay in enforcing this contract, we can still enforce it
            later.{" "}
          </b>
          Even if we delay in enforcing this contract, we can still enforce it
          later. If we do not insist immediately that you do anything you are
          required to do under these terms, or if we delay in taking steps
          against you in respect of your breaking this contract, that will not
          mean that you do not have to do those things and it will not prevent
          us taking steps against you at a later date.
        </p>
        <p>
          <b>
            Which laws apply to this contract and where you may bring legal
            proceedings.{" "}
          </b>
          The validity, construction and performance of this agreement shall be
          governed by English law and shall be subject to the exclusive
          jurisdiction of the English courts to which both parties submit.
        </p>
      </Segment>
    </Box>
  );
};
export default PathwayDisclaimer;
