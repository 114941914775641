import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { createContext, Dispatch, useEffect, useReducer } from "react";
import { ChatMessage } from "../../../../types/Chat";
import {
  IDynamicNode,
  INode,
  INodePublished,
  INodeRootDoc,
} from "../../../../types/Nodes";

import { getWidgets } from "../../widgets/FetchWidgetsFunctions";
import {
  IPathwayStatus,
  IPathwayTag,
  ITenantPathway,
} from "../../../../types/Pathway";
import { ITenantSettings } from "../../../../types/Tenants";

import isEmpty from "lodash/isEmpty";
import useTenants from "features/hooks/useTenants";
import {
  getEditorVersion,
  getMessagesPath,
  getNodeOrderPath,
  getPathwayPath,
} from "../utils/editorConverters";
import { getDb } from "app/data/DbProvider";
import { NODES_ROOT_DOC_NAME } from "../utils/pathwayHelperV2";

export type EditorViewType =
  | "EPISODE"
  | "TABLE"
  | "TREE"
  | "FILE_UPLOAD"
  | "IMPORT_CONTENT"
  | "DEPLOYMENT_HISTORY";
export type EditorMode = "READONLY" | "EDITABLE";

type PathwayBuilderContextReducerState = {
  loaded: boolean;
  isCommentSectionCollapsed: boolean;
  viewType: EditorViewType;
  editorMode: EditorMode;
  collection: string;
  nodes: IDynamicNode[];
  altNodes?: (IDynamicNode | INode)[];
  nodeOrder: string[];
  messages: ChatMessage[];
  tenantId?: string;
  pathwayId?: string;
  currentUserEmail?: string;
  currentUserUID?: string;
  published: Record<string, INodePublished>;
  isCurrentPathwayPublished: boolean;
  isDeploymentPolicyOpen: boolean;
  nodePreviewId?: string;
  widgetList: any[];
  altNodePreviewId?: string;
  useAltState?: boolean; // altState is state used by deployment hisotry and import content
  pathwayStatus: string;
  pathwayTags: string[];
  pathwayTagTemplates: IPathwayTag[];
  pathwayStatusTemplates: IPathwayStatus[];
};

type PathwayBuilderContextType = {
  state: PathwayBuilderContextReducerState;
  dispatch: Dispatch<PathwayBuilderActions>;
};

const fetchWidgets = async (dispatch: Dispatch<PathwayBuilderActions>) => {
  const widgets = await getWidgets();
  dispatch({ type: "GET_WIDGET_LIST", payload: { widgetList: widgets } });
};

const handleIsDeploymentPolicyOpen = (
  state: PathwayBuilderContextReducerState,
  isDeploymentPolicyOpen: boolean
) => {
  state.isDeploymentPolicyOpen = isDeploymentPolicyOpen;

  return state.isDeploymentPolicyOpen;
};

export type PathwayBuilderActions =
  | {
      type: "TOGGLE_COMMENTS_COLLAPSED";
    }
  | { type: "CHANGE_VIEW_TYPE"; payload: { viewType: EditorViewType } }
  | { type: "SET_PATHWAY_EDIT_MODE"; payload: { mode: EditorMode } }
  | { type: "SET_PATHWAY_COLLECTION"; payload: { collection: string } }
  | { type: "UPDATE_NODES"; payload: { nodes: IDynamicNode[] } }
  | { type: "UPDATE_ALT_NODES"; payload: { nodes: (IDynamicNode | INode)[] } }
  | {
      type: "SET_PATHWAY_VALUES";
      payload: {
        tenantId: string;
        pathwayId: string;
        uid: string;
        email: string;
        initalCollection: string;
        defaultView: EditorViewType;
      };
    }
  | { type: "CONTEXT_READY"; payload: { loaded: boolean } }
  | { type: "UPDATE_NODE_ORDER"; payload: { nodeOrder: string[] } }
  | { type: "UPDATE_MESSAGES"; payload: { messages: ChatMessage[] } }
  | {
      type: "UPDATE_PATHWAY_PUBLISHED";
      payload: { published: Record<string, INodePublished> };
    }
  | {
      type: "UPDATE_CURRENT_PATHWAY_PUBLISHED";
      payload: { pathwayPublished: boolean };
    }
  | {
      type: "TOGGLE_IS_DEPLOYMENT_POLICY_OPEN";
      payload: { isDeploymentPolicyOpen: boolean };
    }
  | {
      type: "USE_ALT_STATE";
      payload: { useAltState: boolean };
    }
  | { type: "SET_NODE_PREVIEW_ID"; payload: { nodeId?: string } }
  | { type: "SET_ALT_NODE_PREVIEW_ID"; payload: { nodeId?: string } }
  | { type: "GET_WIDGET_LIST"; payload: { widgetList: any[] } }
  | { type: "GET_WIDGET_LIST"; payload: { widgetList: any[] } }
  | { type: "UPDATE_PATHWAY_STATUS"; payload: { statusId: string } }
  | { type: "UPDATE_PATHWAY_TAGS"; payload: { tagIds: string[] } }
  | {
      type: "SET_STATUS_TEMPLATE";
      payload: { statusTemplates: IPathwayStatus[] };
    }
  | { type: "SET_TAG_TEMPLATES"; payload: { tagTemplates: IPathwayTag[] } };

const pathwayBuilderReducer = (
  previousState: PathwayBuilderContextReducerState,
  action: PathwayBuilderActions
): PathwayBuilderContextReducerState => {
  switch (action.type) {
    case "TOGGLE_COMMENTS_COLLAPSED":
      return {
        ...previousState,
        isCommentSectionCollapsed: !previousState.isCommentSectionCollapsed,
      };
    case "SET_PATHWAY_EDIT_MODE":
      return {
        ...previousState,
        editorMode: action.payload.mode,
      };
    case "CHANGE_VIEW_TYPE":
      return {
        ...previousState,
        viewType: action.payload.viewType,
      };
    case "SET_PATHWAY_COLLECTION":
      return {
        ...previousState,
        collection: action.payload.collection,
      };
    case "USE_ALT_STATE":
      return {
        ...previousState,
        useAltState: action.payload.useAltState,
      };
    case "SET_PATHWAY_VALUES":
      return {
        ...previousState,
        tenantId: action.payload.tenantId,
        pathwayId: action.payload.pathwayId,
        currentUserEmail: action.payload.email,
        currentUserUID: action.payload.uid,
        collection: action.payload.initalCollection,
        viewType: action.payload.defaultView,
      };
    case "CONTEXT_READY":
      return {
        ...previousState,
        loaded: action.payload.loaded,
      };
    case "UPDATE_NODES":
      return {
        ...previousState,
        nodes: action.payload.nodes,
      };
    case "UPDATE_ALT_NODES":
      return {
        ...previousState,
        altNodes: action.payload.nodes,
      };
    case "UPDATE_NODE_ORDER":
      return {
        ...previousState,
        nodeOrder: action.payload.nodeOrder,
      };
    case "UPDATE_MESSAGES":
      return {
        ...previousState,
        messages: action.payload.messages,
        isCommentSectionCollapsed:
          action.payload.messages.filter(
            (x) =>
              (x.status === "UNRESOLVED" &&
                !x.nodeId &&
                x.type === "Message" &&
                !x.conversationId) ||
              x.notificationType === "Rejected" ||
              x.notificationType === "Blocked"
          ).length === 0,
      };
    case "UPDATE_PATHWAY_PUBLISHED":
      return {
        ...previousState,
        published: action.payload.published,
      };
    case "UPDATE_CURRENT_PATHWAY_PUBLISHED":
      return {
        ...previousState,
        isCurrentPathwayPublished: action.payload.pathwayPublished,
      };

    case "TOGGLE_IS_DEPLOYMENT_POLICY_OPEN":
      return {
        ...previousState,
        isDeploymentPolicyOpen: handleIsDeploymentPolicyOpen(
          previousState,
          action.payload.isDeploymentPolicyOpen
        ),
      };
    case "SET_NODE_PREVIEW_ID":
      return {
        ...previousState,
        nodePreviewId: action.payload.nodeId,
      };
    case "SET_ALT_NODE_PREVIEW_ID":
      return {
        ...previousState,
        altNodePreviewId: action.payload.nodeId,
      };
    case "GET_WIDGET_LIST":
      return {
        ...previousState,
        widgetList: action.payload.widgetList,
      };
    case "UPDATE_PATHWAY_STATUS":
      return {
        ...previousState,
        pathwayStatus: action.payload.statusId,
      };
    case "UPDATE_PATHWAY_TAGS":
      return {
        ...previousState,
        pathwayTags: [...action.payload.tagIds],
      };
    case "SET_STATUS_TEMPLATE":
      return {
        ...previousState,
        pathwayStatusTemplates: action.payload.statusTemplates,
      };
    case "SET_TAG_TEMPLATES":
      return {
        ...previousState,
        pathwayTagTemplates: action.payload.tagTemplates,
      };
    default:
      throw Error(`Invalid PathwayBuilder Action`);
  }
};

const PathwayBuilderContext = createContext<PathwayBuilderContextType>({
  state: {
    loaded: false,
    isCommentSectionCollapsed: false,
    viewType: "EPISODE",
    editorMode: "READONLY",
    collection: "nodes",
    nodes: [],
    nodeOrder: [],
    messages: [],
    published: {},
    isCurrentPathwayPublished: false,
    isDeploymentPolicyOpen: false,
    widgetList: [],
    pathwayStatus: "",
    pathwayTags: [],
    pathwayStatusTemplates: [],
    pathwayTagTemplates: [],
  },
  dispatch: () => null,
});

const PathwayBuilderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(pathwayBuilderReducer, {
    isCommentSectionCollapsed: true,
  } as PathwayBuilderContextReducerState);
  const { tenantConfiguration } = useTenants();
  const db = firebase.firestore();
  const dbProvider = getDb();
  //Load Nodes for the tenant, pathway, collection
  useEffect(() => {
    if (state.tenantId && state.pathwayId && state.collection) {
      const unsubscribe = db
        .collection(
          getPathwayPath(state.tenantId, state.pathwayId, state.collection)
        )
        .onSnapshot((nodeSnaps) => {
          

          if (getEditorVersion() === "V2") {
            const nodes: IDynamicNode[] = [];
            nodeSnaps.forEach((nodeSnap) => {
              const node = {
                ...nodeSnap.data(),
                id: nodeSnap.id,
              } as IDynamicNode;

              nodes.push(node);
            });

            dispatch({ type: "UPDATE_NODES", payload: { nodes: nodes } });
          }
        });

      fetchWidgets(dispatch);

      return unsubscribe;
    }
  }, [
    db,
    state.pathwayId,
    state.tenantId,
    state.collection,
    tenantConfiguration?.editorVersion,
  ]);

  useEffect(() => {
    if (state.tenantId && state.pathwayId && state.collection && state.nodes) {
      return dbProvider.watchDoc<INodeRootDoc>(
        getNodeOrderPath(state.tenantId, state.pathwayId, state.collection),
        NODES_ROOT_DOC_NAME,
        (doc) => {
          dispatch({
            type: "UPDATE_NODE_ORDER",
            payload: { nodeOrder: doc.nodeOrder ?? [] },
          });
        }
      );
    }
  }, [
    dbProvider,
    state.collection,
    state.nodes,
    state.pathwayId,
    state.tenantId,
  ]);

  //Load the pathway document
  useEffect(() => {
    if (state.tenantId && state.pathwayId && state.collection && state.nodes) {
      const unsubscribe = db
        .collection(getPathwayPath(state.tenantId))
        .doc(state.pathwayId)
        .onSnapshot((pathwaySnap) => {
          const pathwayDoc = pathwaySnap.data() ?? {};

          const pathwayStatus = (pathwayDoc as unknown as ITenantPathway)
            .pathwayStatus;
          const pathwayTags = (pathwayDoc as unknown as ITenantPathway).tags;
          dispatch({
            type: "UPDATE_PATHWAY_STATUS",
            payload: { statusId: pathwayStatus || "" },
          });

          dispatch({
            type: "UPDATE_PATHWAY_TAGS",
            payload: { tagIds: pathwayTags || [] },
          });
        });

      return unsubscribe;
    }
  }, [
    db,
    state.pathwayId,
    state.tenantId,
    state.collection,
    state.nodes,
    tenantConfiguration?.editorVersion,
  ]);

  //Context Ready
  useEffect(() => {
    if (
      state.tenantId &&
      state.currentUserEmail &&
      state.pathwayId &&
      state.nodes &&
      state.nodeOrder &&
      state.messages &&
      state.pathwayTags &&
      state.pathwayTagTemplates &&
      state.pathwayStatusTemplates
    ) {
      dispatch({ type: "CONTEXT_READY", payload: { loaded: true } });
    }
  }, [
    state.currentUserEmail,
    state.pathwayId,
    state.tenantId,
    state.nodes,
    state.nodeOrder,
    state.messages,
    state.pathwayTags,
    state.pathwayTagTemplates,
    state.pathwayStatusTemplates,
  ]);
  //Watch Messages
  useEffect(() => {
    if (state.tenantId && state.pathwayId) {
      const unsubscribe = db
        .collection(
          getMessagesPath(state.tenantId, state.pathwayId, state.collection)
        )
        .where("status", "==", "UNRESOLVED")
        .onSnapshot((snapshot) => {
          const listMessages: ChatMessage[] = [];
          snapshot.forEach((doc) => {
            listMessages.push(doc.data() as ChatMessage);
          });
          dispatch({
            type: "UPDATE_MESSAGES",
            payload: { messages: listMessages },
          });
        });
      return unsubscribe;
    }
  }, [
    db,
    state.collection,
    state.pathwayId,
    state.tenantId,
    tenantConfiguration?.editorVersion,
  ]);

  //Watch Published Settings
  useEffect(() => {
    if (state.tenantId) {
      const unsubscribe = db
        .collection(state.tenantId)
        .doc("Published")
        .onSnapshot((publishedSnap) => {
          const published = publishedSnap.data() as Record<
            string,
            INodePublished
          >;
          dispatch({
            type: "UPDATE_PATHWAY_PUBLISHED",
            payload: { published: published },
          });
        });
      return unsubscribe;
    }
  }, [db, state.tenantId]);

  useEffect(() => {
    if (state.pathwayId && state.published) {
      dispatch({
        type: "UPDATE_CURRENT_PATHWAY_PUBLISHED",
        payload: {
          pathwayPublished:
            state.published[state.pathwayId]?.published || false,
        },
      });
    }
  }, [state.published, state.pathwayId]);

  //Laod Tenant Settings
  useEffect(() => {
    if (state.tenantId) {
      const unsubscribe = db
        .collection(`${state.tenantId}/config/config`)
        .doc("settings")
        .onSnapshot((doc) => {
          const settings = doc.data() as ITenantSettings | undefined;

          if (settings?.defaultDevNodesCollection !== undefined) {
            dispatch({
              type: "SET_PATHWAY_COLLECTION",
              payload: { collection: settings.defaultDevNodesCollection },
            });
          }

          if (isEmpty(settings?.pathwayStatusTemplates)) {
            dispatch({
              type: "SET_STATUS_TEMPLATE",
              payload: {
                statusTemplates: [],
              },
            });
          }

          if (isEmpty(settings?.pathwayTags)) {
            dispatch({
              type: "SET_TAG_TEMPLATES",
              payload: { tagTemplates: [] },
            });
          }

          if (settings?.pathwayStatusTemplates) {
            for (const templateId in settings.pathwayStatusTemplates) {
              const template = settings.pathwayStatusTemplates[templateId];
              if (template.status === "ACTIVE") {
                dispatch({
                  type: "SET_STATUS_TEMPLATE",
                  payload: { statusTemplates: template.pathwayStatuses },
                });
              }
            }
          }

          if (settings?.pathwayTags) {
            const tags = [];
            for (const tagId in settings.pathwayTags) {
              const tag = settings.pathwayTags[tagId];
              if (tag.status !== "DELETED") {
                tags.push({ ...tag, id: tagId });
              }
            }
            dispatch({
              type: "SET_TAG_TEMPLATES",
              payload: { tagTemplates: tags },
            });
          }
        });
      return unsubscribe;
    }
  }, [db, state.tenantId]);

  return (
    <PathwayBuilderContext.Provider value={{ state, dispatch }}>
      {children}
    </PathwayBuilderContext.Provider>
  );
};

export { PathwayBuilderProvider, PathwayBuilderContext };
