import { styled } from "@mui/material";

const Segment = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  borderRadius: "5px",
}));

export default Segment;
