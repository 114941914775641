import {
  Box,
  //  Tooltip,
  Typography,
} from "@mui/material";

// import { Draggable, Droppable } from "react-beautiful-dnd";
import { IDynamicNode } from "../../../../../types/Nodes";

import { ReactComponent as VideoIcon } from "app/assets/videoblockicon.svg";
import { ReactComponent as ImageIcon } from "app/assets/imageblockicon.svg";
import { ReactComponent as ParagraphIcon } from "app/assets/paragraphblockicon.svg";
import { ReactComponent as FilesBlockIcon } from "app/assets/filesblockicon.svg";
import { ReactComponent as ThreeColumnsBlockIcon } from "app/assets/threecolumnsblockicon.svg";
import { ReactComponent as TwoColumnsBlockIcon } from "app/assets/twocolumnsblockicon.svg";
import { ReactComponent as LinkButtonBlockIcon } from "app/assets/linkblockicon.svg";
import { ReactComponent as ActionButtonBlockIcon } from "app/assets/actionblockicon.svg";
import { ReactComponent as ChoicesBlockIcon } from "app/assets/choicesBlockIcon.svg";
import { ReactComponent as ScorerBlockIcon } from "app/assets/scorerBlockIcon.svg";
import { ReactComponent as YesNoBlockIcon } from "app/assets/yesnoblockicon.svg";
import { ReactComponent as QuestionBlockIcon } from "app/assets/questionblockicon.svg";
import { ReactComponent as DisplayVariablesIcon } from "app/assets/displayvariablesicon.svg";
import { ReactComponent as ShowHideBlockIcon } from "app/assets/showhideicon.svg";
import { ReactComponent as STarTBackBlockIcon } from "app/assets/startbackblockicon.svg";
import {
  isQuestionBlock,
  isStartBackBlock,
} from "features/pathwaybuilder/utils/pathwayHelperV2";

export const blockTypes = [
  {
    blockName: "Video",
    blockType: "video",
    blockIcon: <VideoIcon />,
  },
  {
    blockName: "Image",
    blockType: "image",
    blockIcon: <ImageIcon />,
  },
  {
    blockName: "Text Area",
    blockType: "richTextArea",
    blockIcon: <ParagraphIcon />,
  },
  {
    blockName: "Question",
    blockType: "question",
    blockIcon: <QuestionBlockIcon />,
  },
  {
    blockName: "Attachment",
    blockType: "files",
    blockIcon: <FilesBlockIcon />,
  },
  {
    blockName: "2 Columns",
    blockType: "twoColumns",
    blockIcon: <TwoColumnsBlockIcon />,
  },
  {
    blockName: "3 Columns",
    blockType: "threeColumns",
    blockIcon: <ThreeColumnsBlockIcon />,
  },
  {
    blockName: "Link Button",
    blockType: "linkButton",
    blockIcon: <LinkButtonBlockIcon />,
  },
  {
    blockName: "Choices",
    blockType: "choices",
    blockIcon: <ChoicesBlockIcon />,
  },
  { blockName: "Scorer", blockType: "scorer", blockIcon: <ScorerBlockIcon /> },
  {
    blockName: "Yes / No",
    blockType: "yesNo",
    blockIcon: <YesNoBlockIcon />,
  },
  {
    blockName: "Display Variables",
    blockType: "displayVariables",
    blockIcon: <DisplayVariablesIcon />,
  },
  {
    blockName: "Action Button",
    blockType: "button",
    blockIcon: <ActionButtonBlockIcon />,
  },

  {
    blockName: "Show / Hide",
    blockType: "showHide",
    blockIcon: <ShowHideBlockIcon />,
  },
  {
    blockName: "STarTBack",
    blockType: "startback",
    blockIcon: <STarTBackBlockIcon />,
  },
];

const BlockElement = ({
  state,
  block,
}: {
  state: IDynamicNode;
  block: {
    blockName: string;
    blockType: string;
    blockIcon: JSX.Element;
  };
}) => {
  return (
    <Box
      sx={{
        opacity:
          (Object.values(state.blocks).find((block) =>
            isQuestionBlock(block)
          ) &&
            block.blockType === "question") ||
          (Object.values(state.blocks).find((block) =>
            isStartBackBlock(block)
          ) &&
            block.blockType === "startback")
            ? "0.3"
            : "1",
        backgroundColor: "#FFFF",
        height: "116px",
        borderRadius: "8px",
        textAlign: "center",
        p: 1,
        m: 0.2,
        border: "1px solid #C2C2C2",
      }}
    >
      <Box
        sx={{
          backgroundColor:
            block.blockType === "startback"
              ? "#3C5FB4"
              : "rgba(118, 118, 118, 0.12)",
          borderRadius: "5px",
          height: "58px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {block.blockIcon}
      </Box>
      <Typography
        sx={{
          fontWeight: 700,
          lineHeight: "100%",
          pt: 1,
        }}
      >
        {block.blockName}
      </Typography>
    </Box>
  );
};

export default BlockElement;
