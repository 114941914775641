import { useState } from "react";
import { getActiveGroupsFromModules } from "./helpers/galleryHelpers";
import LoadingButton from "app/components/LoadingButton";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { v4 as uuidv4 } from "uuid";

import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/system/Box";
import Tooltip from "@mui/material/Tooltip";
import { DialogActions, Divider, TextField } from "@mui/material";
import { toastr } from "react-redux-toastr";
import { IGalleryImageGroup } from "../../../types/Gallery";
import UndoIcon from "@mui/icons-material/Undo";
import { IModule } from "../../../types/CrossCover";

interface ImageGroupManagerProps {
  moduleName: string;
  galleryModules: IModule[];
}

const ImageGroupManager = ({
  moduleName,
  galleryModules,
}: ImageGroupManagerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageGroupOptions, setImageGroupOptions] = useState<
    IGalleryImageGroup[]
  >([]);
  const [deletedGroupOptions, setDeletedGroupOptions] = useState<
    IGalleryImageGroup[]
  >([]);

  const db = firebase.firestore();

  if (galleryModules === undefined) {
    throw Error("Modules not found");
  }

  const displayGroupOptionsForModule = () => {
    setOpen(true);

    const allModuleOptions = getActiveGroupsFromModules(galleryModules);

    const moduleOptions = allModuleOptions
      ?.filter((moduleImageOption) => moduleImageOption.moduleId === moduleName)
      .concat()
      .sort((a, b) => a.groupName.localeCompare(b.groupName));

    const activeOptions = moduleOptions?.filter(
      (module) => module.status === "ACTIVE"
    );

    const deletedOptions = moduleOptions?.filter(
      (module) => module.status === "DELETED"
    );

    setDeletedGroupOptions(deletedOptions || []);

    setImageGroupOptions(activeOptions || []);
  };

  const onNameChange = (
    newGroupName: string,
    groupOption: IGalleryImageGroup,
    index: number
  ) => {
    groupOption.groupName = newGroupName;

    const newGroups = [...imageGroupOptions];
    newGroups[index].groupName = newGroupName;

    setImageGroupOptions(newGroups);
  };

  const onDelete = (imageGroupOption: IGalleryImageGroup, index: number) => {
    if (imageGroupOption.groupName !== "") {
      const newGroups = [...imageGroupOptions];
      newGroups[index].status = "DELETED";

      setImageGroupOptions(newGroups);
    }

    if (imageGroupOption.groupName === "") {
      const filteredOfEmptyGroups = imageGroupOptions.filter(
        (groupOption) => groupOption.groupName !== ""
      );
      setImageGroupOptions(filteredOfEmptyGroups);
    }
  };

  const onUndoDelete = (
    imageGroupOption: IGalleryImageGroup,
    index: number
  ) => {
    if (imageGroupOption.groupName !== "") {
      const newGroups = [...imageGroupOptions];
      newGroups[index].status = "ACTIVE";

      setImageGroupOptions(newGroups);
    }
  };

  const onAddGroup = () => {
    const newGroup: IGalleryImageGroup = {
      groupName: "",
      id: uuidv4(),
      moduleId: moduleName,
      status: "ACTIVE",
    };

    setImageGroupOptions((prev) => [...prev, newGroup]);
  };

  const onSave = async () => {
    setIsLoading(true);
    const newImageGroupOptions = imageGroupOptions
      .concat(deletedGroupOptions)
      .filter((group) => group.groupName.trim() !== "");

    if (Array.isArray(newImageGroupOptions)) {
      await db.collection("modules").doc(moduleName).set(
        {
          groups: newImageGroupOptions,
        },
        { merge: true }
      );
    }

    setIsLoading(false);
    setOpen(false);
    toastr.success("Success!", "The new image groups have been added.");
  };

  const onClose = () => {
    setOpen(false);
    setImageGroupOptions([]);
  };

  return (
    <>
      <Tooltip
        disableInteractive
        arrow
        title={
          <span style={{ fontSize: "12px", fontWeight: "normal" }}>
            Edit {moduleName} image groups
          </span>
        }
      >
        <Button
          onClick={displayGroupOptionsForModule}
          style={{
            background: "rgba(0, 133, 127, 0.02)",
            border: "1px solid rgba(0, 133, 127, 0.5)",
            borderRadius: "4px",
          }}
        >
          <EditIcon fontSize="medium" color="primary" />
        </Button>
      </Tooltip>

      <Dialog maxWidth="md" open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            py: 2.5,
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            component={"h1"}
            sx={{ fontWeight: 900, marginLeft: 2, marginRight: 16 }}
          >
            {moduleName} Pathway Image Group Manager
          </Typography>
          <IconButton
            aria-label="close"
            sx={{ position: "absolute", right: 4 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 2,
            p: 2,
          }}
        >
          {imageGroupOptions.map((groupOption, index) => (
            <Box
              key={index.toLocaleString()}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                id="image-group"
                style={{
                  textDecorationLine:
                    groupOption.status === "DELETED" ? "line-through" : "none",
                  color: groupOption.status === "DELETED" ? "red" : "black",
                }}
                inputProps={{ maxLength: 70 }}
                fullWidth
                value={groupOption.groupName}
                variant="outlined"
                onChange={(e) =>
                  onNameChange(e.target.value, groupOption, index)
                }
              />
              {groupOption.status === "ACTIVE" ? (
                <IconButton
                  aria-label="delete"
                  onClick={() => onDelete(groupOption, index)}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={() => onUndoDelete(groupOption, index)}
                >
                  <UndoIcon color="info" />
                </IconButton>
              )}
            </Box>
          ))}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box>
            <Button
              color="info"
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => onAddGroup()}
            >
              Add a Group
            </Button>
          </Box>
          <Box>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton
              isLoading={isLoading}
              icon={<CheckIcon />}
              buttonText="Save"
              disabled={isLoading}
              variant="contained"
              onClick={() => onSave()}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageGroupManager;
