import {
  IChoicesBlock,
  NodeBlock,
  ValueLogicField,
  IChoicesLogicField,
  IDynamicNode,
} from "../../../../../types/Nodes";

import AddIcon from "@mui/icons-material/Add";
import { v4 as uuid4 } from "uuid";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import {
  convertNodeContextToJsonData,
  getNameBlockWithNumberInstancesOfBlockType,
} from "../../NodeEditorUtils";
import FieldComponentEdit from "./FieldComponentEdit";
import FieldToggleComponentView from "./FieldToggleComponentView";
import { Droppable } from "react-beautiful-dnd";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import produce from "immer";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";
import {
  isScorerBlock,
  isYesNoBlock,
} from "features/pathwaybuilder/utils/pathwayHelperV2";
import { INodeViewSettings } from "features/NodeViewer/NodeView";

interface INodeLogicEditorProps {
  block: IChoicesBlock;
  dispatch?: React.Dispatch<DynamicNodeEditorActions>;

  isEditing: boolean;
  disableBlockInteractivity: boolean;
  pathwayEditorDispatch: React.Dispatch<PathwayActions>;
  pathwayState: Partial<PathwayState>;
  blockEditInfos?: NodeBlock | undefined;
  onEdit?: (block: NodeBlock | undefined) => void;
  state: IDynamicNode;
  isPreview: boolean;
  nodeSettings: INodeViewSettings;
}

export const checkIfNameFieldIsPresent = (name: string, fields: string[]) => {
  let count = 0;

  fields.forEach((field) => {
    field.trim() === name.trim() && count++;
  });

  return count > 1 ? true : false;
};

const NodeChoicesEditor = ({
  block,
  dispatch,
  isEditing,
  disableBlockInteractivity,
  pathwayEditorDispatch,
  pathwayState,
  blockEditInfos,
  onEdit,
  state,
  isPreview,
  nodeSettings,
}: INodeLogicEditorProps) => {
  const [totalBlockScore, setTotalBlockScore] = useState(0);

  useEffect(() => {
    const getTotalScore = () => {
      if (pathwayState.nodeContext) {
        const nodeContextFields = convertNodeContextToJsonData(
          pathwayState.nodeContext
        );
        setTotalBlockScore(
          (nodeContextFields[block.blockId]?.score as number) ?? 0
        );
      }
    };

    if (block.blockData.showBlockScore) {
      getTotalScore();
    }
  }, [block, pathwayState.nodeContext]);

  const onCheckIfNameFieldIsPresent = (name: string) => {
    const arrayNameLogicField: string[] = block.blockData?.fields.map(
      (field) => field.title
    ) || [""];
    return checkIfNameFieldIsPresent(name, arrayNameLogicField);
  };

  const deleteField = (index: number) => {
    if (block.blockData) {
      const logicToDelete = pathwayState.nodeContext?.fields.find(
        (field) => field.blockFieldId === block.blockData.fields[index].fieldId
      );

      if (logicToDelete) {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_OFF",
          payload: {
            ...logicToDelete,
          },
        });
      }

      const blockData = produce(block.blockData, (draft) => {
        draft.fields.splice(index, 1);
      });

      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: { ...blockData },
        },
      });
    }
  };

  const addField = () => {
    if (block.blockData) {
      let newFieldId = uuid4();
      let values: ValueLogicField[] = [];

      if (block.blockData.type === "yesNo") {
        values.push(
          {
            text: "Yes",
            value: 1,
            valueId: uuid4(),
          },
          {
            text: "No",
            value: 0,
            valueId: uuid4(),
          }
        );
      }

      if (block.blockData.type !== "yesNo") {
        values.push({ text: "", value: 0, valueId: uuid4() });
      }

      const blockData = produce(block.blockData, (draft) => {
        draft.fields.push({ title: "", values, fieldId: newFieldId });
      });

      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: { ...blockData },
        },
      });

      values.forEach((value, index) => {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            blockId: block.blockId,
            blockFieldId: newFieldId,
            blockName: block.blockName,
            fieldName: "",
            valueName: value.text,
            groupName: block.blockData?.title,
            value: value.value,
            valueId: value.valueId,
            addToDecisionSummary: block.blockOptions.addToDecisionSummary,
            addToManagementPlan: block.blockOptions.addToManagementPlan,
            addToNodeScore: block.blockOptions.addToNodeScore,
            isToggled: false,
            hasMultipleValues: block.blockData?.type === "yesNo" ? true : false,
            namespace: "",
            name: "",
            order: 0,
          },
        });
      });
    }
  };

  const updateField = (
    type: "score" | "field" | "valueText" | "groupName",
    indexField: number,
    value: string,
    indexValue: number
  ) => {
    if (block.blockData) {
      const blockData = produce(block.blockData, (draft) => {
        type === "score" &&
          (draft.fields[indexField].values[indexValue].value = +value);
        type === "field" && (draft.fields[indexField].title = value);
        type === "valueText" &&
          (draft.fields[indexField].values[indexValue].text = value);
        type === "groupName" && (draft.title = value);
      });

      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: { ...blockData },
        },
      });

      if (type !== "groupName") {
        const fieldToUpdate = pathwayState.nodeContext?.fields.filter(
          (fieldNodeContext) =>
            fieldNodeContext.valueId ===
            blockData.fields[indexField].values[indexValue].valueId
        );

        if (fieldToUpdate) {
          const flagType = type === "score" ? "value" : "fieldName";

          if (type !== "valueText") {
            fieldToUpdate.forEach((field) => {
              pathwayEditorDispatch({
                type: "NODE_BLOCK_LOGIC_ON",
                payload: {
                  ...field,
                  [flagType]: type === "score" ? +value : value,
                },
              });
            });
            return;
          }
          const fieldWithMatchingValue = fieldToUpdate.find(
            (field) =>
              field.valueId ===
              blockData.fields[indexField].values[indexValue].valueId
          );
          if (fieldWithMatchingValue) {
            pathwayEditorDispatch({
              type: "NODE_BLOCK_LOGIC_ON",
              payload: {
                ...fieldWithMatchingValue,
                valueName: value,
              },
            });
          }
        }
      }
      if (type === "groupName") {
        const fieldsToUpdate = pathwayState.nodeContext?.fields.filter(
          (fieldNodeContext) => fieldNodeContext.blockId === block.blockId
        );

        if (fieldsToUpdate) {
          fieldsToUpdate.forEach((field) => {
            pathwayEditorDispatch({
              type: "NODE_BLOCK_LOGIC_ON",
              payload: { ...field, groupName: value },
            });
          });
        }
      }
    }
  };

  const toggleField = (
    field: IChoicesLogicField,
    valueObject?: ValueLogicField
  ) => {
    const fieldToAdd = pathwayState.nodeContext?.fields.find(
      (fieldNodeContext) => fieldNodeContext.blockFieldId === field.fieldId
    );

    let toggledFieldInNodeContext = pathwayState.nodeContext?.fields.find(
      (fieldNodeContext) =>
        fieldNodeContext.blockId === block.blockId && fieldNodeContext.isToggled
    );

    if (!valueObject) {
      if (!block.blockData.multipleAnswers) {
        if (toggledFieldInNodeContext) {
          pathwayEditorDispatch({
            type: "NODE_BLOCK_LOGIC_ON",
            payload: {
              ...toggledFieldInNodeContext,
              isToggled: !toggledFieldInNodeContext.isToggled,
            },
          });
        }
      }

      if (block.blockData && !disableBlockInteractivity && fieldToAdd) {
        console.log("B");
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            ...fieldToAdd,
            isToggled: !fieldToAdd.isToggled,
          },
        });
      }
    }

    if (valueObject && block.blockData) {
      const valueToAdd = pathwayState.nodeContext?.fields.find(
        (fieldNodeContext) =>
          fieldNodeContext.blockFieldId === field.fieldId &&
          fieldNodeContext.valueId === valueObject.valueId
      );

      const toggledValueInNodeContext = pathwayState.nodeContext?.fields.find(
        (fieldNodeContext) =>
          fieldNodeContext.blockFieldId === valueToAdd?.blockFieldId &&
          fieldNodeContext.isToggled
      );

      if (toggledValueInNodeContext) {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            ...toggledValueInNodeContext,
            isToggled: !toggledValueInNodeContext.isToggled,
          },
        });
      }
      if (valueToAdd) {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            ...valueToAdd,
            isToggled: !valueToAdd.isToggled,
          },
        });
      }
    }
  };

  const toggleMultipleAnswers = () => {
    if (block.blockData.multipleAnswers) {
      const nodeContextFields = pathwayState.nodeContext?.fields.filter(
        (field) => field.blockId === block.blockId
      );

      nodeContextFields?.forEach((field) => {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_OFF",
          payload: {
            ...field,
          },
        });
      });
    }

    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData: {
          ...block.blockData,
          multipleAnswers: !block.blockData.multipleAnswers,
        },
      },
    });
  };

  const toggleAddScore = () => {
    if (block && block.blockData) {
      const type = !isScorerBlock(block)
        ? isYesNoBlock(block)
          ? "yesNo"
          : "scorer"
        : isYesNoBlock(block)
        ? "yesNo"
        : "choices";

      let newBlockName = "";
      if (
        block.blockData?.type &&
        type !== block.blockData?.type &&
        block.blockName.startsWith(block.blockData.type)
      ) {
        newBlockName = getNameBlockWithNumberInstancesOfBlockType(
          Object.values(state.blocks),
          { ...block, blockData: { ...block.blockData, type } }
        );

        dispatch?.({
          type: "UPDATE_BLOCK_NAME",
          payload: {
            blockId: block.blockId,
            newBlockName,
          },
        });
      }

      dispatch?.({
        type: "UPDATE_BLOCK_OPTIONS",
        payload: {
          blockId: block.blockId,
          blockOptions: {
            ...block.blockOptions,
            addToNodeScore:
              type === "choices"
                ? false
                : type === "yesNo" && block.blockData.showFieldScores
                ? false
                : true,
          },
        },
      });

      const updatedFieldsValues = produce(block.blockData.fields, (draft) => {
        draft.map((field) => {
          const valuesArray = field.values.map((value) => (value.value = 0));
          return valuesArray;
        });
      });

      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: {
            ...block.blockData,
            showFieldScores:
              type === "choices" ? false : !block.blockData.showFieldScores,
            showBlockScore:
              type === "choices" ? false : !block.blockData.showFieldScores,
            fields: updatedFieldsValues,
            type,
          },
        },
      });

      const nodeContextField = pathwayState.nodeContext?.fields.filter(
        (field) => field.blockId === block.blockId
      );

      if (nodeContextField) {
        nodeContextField.forEach((field) => {
          pathwayEditorDispatch({
            type: "NODE_BLOCK_LOGIC_ON",
            payload: {
              ...field,
              addToNodeScore: type === "choices" ? false : true,
              value: 0,
              blockName: newBlockName,
            },
          });
        });
      }
    }
  };

  const toggleBlockType = (toggleType: "checkbox" | "button" | "yesNo") => {
    if (!block.blockData) {
      return;
    }

    let updatedBlockData = { ...block.blockData };
    const currentBlockType = block.blockData.type;
    let newListFields: IChoicesLogicField[] = [];

    if (
      (toggleType === "button" || toggleType === "checkbox") &&
      updatedBlockData.type === "yesNo"
    ) {
      updatedBlockData.fields.forEach((field) => {
        let newField = { ...field, values: [field.values[0]] };
        let valueToMove = { ...field.values[1] };
        let fieldToAdd: IChoicesLogicField = {
          title: `${newField.title} - ${valueToMove.text}`,
          fieldId: uuid4(),
          values: [valueToMove],
        };

        const nodeContextFieldToRemove = pathwayState.nodeContext?.fields.find(
          (fieldElement) =>
            fieldElement.blockFieldId === newField.fieldId &&
            fieldElement.valueId === valueToMove.valueId
        );

        if (nodeContextFieldToRemove) {
          pathwayEditorDispatch({
            type: "NODE_BLOCK_LOGIC_OFF",
            payload: {
              ...nodeContextFieldToRemove,
            },
          });
        }

        newListFields.push(newField, fieldToAdd);
      });
      updatedBlockData.fields = newListFields;
    }

    if (toggleType === "checkbox") {
      updatedBlockData.fieldType = toggleType;
      updatedBlockData.type = isScorerBlock({
        ...block,
        blockData: updatedBlockData,
      })
        ? "scorer"
        : "choices";
    }
    if (toggleType === "button") {
      updatedBlockData.fieldType = toggleType;
      updatedBlockData.type = isScorerBlock({
        ...block,
        blockData: updatedBlockData,
      })
        ? "scorer"
        : "choices";
    }

    if (toggleType === "yesNo") {
      updatedBlockData.type = toggleType;
      updatedBlockData.fieldType = toggleType;
      updatedBlockData.multipleAnswers = false;
      updatedBlockData.fields = updatedBlockData.fields.map((field) => {
        let newValues: ValueLogicField[] = [];

        field.values.forEach((value) => {
          let newValue = { ...value };

          newValue.text = newValue.text === "" ? "Yes" : newValue.text;
          newValue.value = 0;

          newValues.push(newValue);
        });

        let valueToAdd = {
          valueId: uuid4(),
          text: newValues[0].text !== "No" ? "No" : "Yes",
          value: 0,
        };
        newValues[0].text === "Yes"
          ? newValues.push(valueToAdd)
          : newValues.unshift(valueToAdd);

        return { ...field, values: newValues };
      });
    }

    if (
      block.blockName.startsWith(currentBlockType) &&
      currentBlockType !== updatedBlockData.type
    ) {
      let newBlockName = getNameBlockWithNumberInstancesOfBlockType(
        Object.values(state.blocks),
        {
          ...block,
          blockData: { ...block.blockData, type: updatedBlockData.type },
        }
      );

      updatedBlockData.showBlockScore = isScorerBlock({
        ...block,
        blockData: updatedBlockData,
      });
      updatedBlockData.showFieldScores = isScorerBlock({
        ...block,
        blockData: updatedBlockData,
      });

      dispatch?.({
        type: "UPDATE_BLOCK_NAME",
        payload: {
          blockId: block.blockId,
          newBlockName,
        },
      });
    }

    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData: updatedBlockData,
      },
    });

    updatedBlockData.fields.forEach((field) => {
      field.values.forEach((value) => {
        pathwayEditorDispatch({
          type: "NODE_BLOCK_LOGIC_ON",
          payload: {
            blockId: block.blockId,
            blockFieldId: field.fieldId,
            blockName: block.blockName,
            fieldName: field.title,
            valueName: value.text,
            groupName: updatedBlockData.title,
            value: value.value,
            valueId: value.valueId,
            addToDecisionSummary: block.blockOptions.addToDecisionSummary,
            addToManagementPlan: block.blockOptions.addToManagementPlan,
            addToNodeScore: block.blockOptions.addToNodeScore,
            isToggled: false,
            hasMultipleValues: field.values.length > 1 ? true : false,
            namespace: "",
            name: "",
            order: 0,
          },
        });
      });
    });
  };

  useEffect(() => {
    if (
      !pathwayState.nodeContext?.fields.find(
        (field) => field.blockId === block.blockId
      )
    ) {
      block.blockData?.fields.forEach((field) => {
        field.values.forEach((value) => {
          pathwayEditorDispatch({
            type: "NODE_BLOCK_LOGIC_ON",
            payload: {
              blockId: block.blockId,
              blockFieldId: field.fieldId,
              blockName: block.blockName,
              fieldName: field.title,
              valueName: value.text,
              groupName: block.blockData?.title || "",
              value: value.value,
              valueId: value.valueId,
              addToDecisionSummary: block.blockOptions.addToDecisionSummary,
              addToManagementPlan: block.blockOptions.addToManagementPlan,
              addToNodeScore: block.blockOptions.addToNodeScore,
              isToggled: false,
              hasMultipleValues: field.values.length > 1 ? true : false,
              namespace: "",
              name: "",
              order: 0,
            },
          });
        });
      });
    }
  }, [block, pathwayEditorDispatch, pathwayState.nodeContext?.fields]);

  return (
    <Box>
      {!isEditing && block.blockData && block.blockData.fields.length > 0 && (
        <>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "18px",
              wordBreak: "break-word",
              whiteSpace: "pre-line",
            }}
          >
            {block.blockData?.title}
          </Typography>
          <FormGroup
            sx={{
              display: "flex",
              flexDirection:
                block.blockData?.layout === "vertical" ? "columns" : "row",
              justifyContent: "space-around",
            }}
          >
            {block.blockData?.fields.map((field, index) => (
              <FieldToggleComponentView
                showFieldScores={
                  block.blockData.showFieldScores ||
                  (nodeSettings.alwaysShowScores ?? false)
                }
                key={field.fieldId}
                field={field}
                type={block.blockData?.fieldType ?? "button"}
                multipleAnswers={block.blockData.multipleAnswers}
                nodeContext={pathwayState.nodeContext}
                toggleField={toggleField}
                addMarginBottom={block.blockData.fields.length !== index + 1}
              />
            ))}
          </FormGroup>
          {block.blockData.showBlockScore && (
            <Chip label={`Score: ${totalBlockScore}`} />
          )}
        </>
      )}
      {((block.blockData && block.blockData.fields.length <= 0 && !isPreview) ||
        isEditing) && (
        <Box sx={{ color: "inherit" }}>
          <TextField
            id="outlined-basic"
            label="Enter Group Name (optional)..."
            variant="outlined"
            size="small"
            value={block.blockData?.title}
            sx={{ mt: "10px" }}
            fullWidth={true}
            onChange={(e) =>
              // dispatch?.({
              //   type: "UPDATE_BLOCK",
              //   payload: {
              //     blockId: block.blockId,
              //     blockData: {
              //       ...block.blockData,
              //       title: e.target.value,
              //     },
              //   },
              // })
              updateField("groupName", 0, e.target.value, 0)
            }
            onClick={() => !isEditing && onEdit?.(block)}
          />
          <Droppable droppableId={`${block.blockId}`} type="choicesBlock">
            {(provided, snapshot) => (
              <FormGroup
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  my: "20px",
                  display: "flex",
                  flexDirection:
                    block.blockData?.layout === "vertical" ? "columns" : "row",
                  justifyContent: "space-around",
                }}
              >
                {block.blockData?.fields.map((field, indexField) => (
                  <FieldComponentEdit
                    key={indexField}
                    field={field}
                    showFieldScores={
                      (block.blockData?.showFieldScores ||
                        isScorerBlock(block)) ??
                      false
                    }
                    type={block.blockData?.fieldType || "button"}
                    multipleAnswers={block.blockData.multipleAnswers}
                    indexField={indexField}
                    deleteField={deleteField}
                    updateField={updateField}
                    nodeContext={pathwayState.nodeContext}
                    isFieldNamePresent={onCheckIfNameFieldIsPresent(
                      field.title
                    )}
                  />
                ))}
                {provided.placeholder}
              </FormGroup>
            )}
          </Droppable>
          {isEditing && (
            <>
              <Typography sx={{ display: "flex", color: "#767676", mb: 2 }}>
                <span
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={() => addField()}
                >
                  <AddIcon /> Add Choice
                </span>
              </Typography>
              {(block.blockData?.showFieldScores ||
                block.blockData?.showBlockScore ||
                isScorerBlock(block)) && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  {/* {block.blockData.showTotalScore && (
                    <Chip label={`Score: ${totalBlockScore}`} />
                  )} */}
                  <FormControlLabel
                    sx={{ ml: 1 }}
                    checked={block.blockData?.showBlockScore}
                    onChange={() =>
                      dispatch?.({
                        type: "UPDATE_BLOCK",
                        payload: {
                          blockId: block.blockId,
                          blockData: {
                            ...block.blockData,
                            showBlockScore: !block.blockData?.showBlockScore,
                          },
                        },
                      })
                    }
                    control={<Switch />}
                    label="Show Block Score"
                  />
                  {isScorerBlock(block) && (
                    <FormControlLabel
                      sx={{ ml: 1 }}
                      checked={block.blockData?.showFieldScores}
                      onChange={() =>
                        dispatch?.({
                          type: "UPDATE_BLOCK",
                          payload: {
                            blockId: block.blockId,
                            blockData: {
                              ...block.blockData,
                              showFieldScores:
                                !block.blockData?.showFieldScores,
                            },
                          },
                        })
                      }
                      control={<Switch />}
                      label="Show Field Scores"
                    />
                  )}
                </Box>
              )}
              <Divider variant="fullWidth" />
              <Box
                sx={{
                  my: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  {/* <ToggleButtonGroup
                    color="primary"
                    value={block.blockData?.layout}
                    exclusive
                    onClick={() =>
                      dispatch?.({
                        type: "UPDATE_BLOCK",
                        payload: {
                          blockId: block.blockId,
                          blockData: {
                            ...block.blockData,
                            layout:
                              block.blockData?.layout === "vertical"
                                ? "horizontal"
                                : "vertical",
                          },
                        },
                      })
                    }
                    aria-label="layout"
                  >
                    <ToggleButton value="vertical">
                      <BorderVerticalIcon />
                    </ToggleButton>
                    <ToggleButton value="horizontal">
                      <BorderHorizontalIcon />
                    </ToggleButton>
                  </ToggleButtonGroup> */}
                  <FormControl sx={{ my: 1 }}>
                    <InputLabel>Type...</InputLabel>{" "}
                    <Select
                      size="small"
                      value={block.blockData?.fieldType}
                      label="Type..."
                      sx={{ width: "220px" }}
                      onChange={(e: SelectChangeEvent<any>) =>
                        toggleBlockType(e.target.value)
                      }
                    >
                      <MenuItem value="yesNo">Yes/No</MenuItem>
                      <MenuItem value="checkbox">Checkbox</MenuItem>
                      <MenuItem value="button">Selector Button</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Box>
                  <FormControlLabel
                    checked={
                      isScorerBlock(block) ||
                      (block as IChoicesBlock).blockData?.showFieldScores
                    }
                    onChange={() => toggleAddScore()}
                    control={<Switch />}
                    label="Scores"
                  />
                  <FormControlLabel
                    control={<Switch />}
                    label="Required"
                    checked={block.blockData?.isRequired}
                    onChange={() =>
                      dispatch?.({
                        type: "UPDATE_BLOCK",
                        payload: {
                          blockId: block.blockId,
                          blockData: {
                            ...block.blockData,
                            isRequired: !block.blockData?.isRequired,
                          },
                        },
                      })
                    }
                  />
                  <FormControlLabel
                    disabled={block.blockData?.type === "yesNo"}
                    onChange={() => toggleMultipleAnswers()}
                    checked={block.blockData.multipleAnswers}
                    control={<Switch />}
                    label="Multiple Answers"
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NodeChoicesEditor;
