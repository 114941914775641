import { useEffect, useState } from "react";
import highlightString from "../pathwaybuilder/PathwayRecentActivity/highlightString";

import NodeView from "features/NodeViewer/NodeView";
import { INode } from "../../../types/Nodes";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import "features/pathwaybuilder/PathwayRecentActivity/styles.css";
import { Button } from "@mui/material";
//@ts-ignore
import HtmlDiff from "htmldiff-js";

export interface IHighlightedNode extends INode {
  linkDestinations: string[];
  logicLinkDestinations: string[];
  rulesStrings: string[];
  highlightedLogicScores: string[];
  [key: string]: any;
}

const highlightStringFields = (
  previousNode: IHighlightedNode,
  currentNode: IHighlightedNode,
  fieldNames: string[],
  isInline: boolean
) => {
  fieldNames.forEach((field) => {
    const highLightedPreviousNode = highlightString(
      previousNode[field],
      currentNode[field],
      true,
      isInline
    );
    currentNode[field] = highlightString(
      previousNode[field],
      currentNode[field],
      false,
      isInline
    );
    previousNode[field] = highLightedPreviousNode;
  });
};

const splitHtmlHighlight = (combinedHtml: string, isPrevious: boolean) => {
  const div = document.createElement("div");
  div.innerHTML = combinedHtml;
  const tagName = isPrevious ? "ins" : "del";
  const elements = div.getElementsByTagName(tagName);

  while (elements[0]) {
    elements[0].parentNode?.removeChild(elements[0]);
  }
  return div.innerHTML;
};

const highlightNodeContentArea = (
  previousNode: IHighlightedNode,
  currentNode: IHighlightedNode,
  isInline: boolean
) => {
  const combinedHtml = HtmlDiff.execute(
    previousNode.nodeContentArea,
    currentNode.nodeContentArea
  );

  if (isInline) {
    previousNode.nodeContentArea = combinedHtml;
    currentNode.nodeContentArea = combinedHtml;
  } else {
    previousNode.nodeContentArea = splitHtmlHighlight(combinedHtml, true);
    currentNode.nodeContentArea = splitHtmlHighlight(combinedHtml, false);
  }
};

const highlightArrayFields = (
  previousNode: IHighlightedNode,
  currentNode: IHighlightedNode,
  fieldNames: string[],
  isInline: boolean
) => {
  fieldNames.forEach((optionalField) => {
    if (previousNode[optionalField] && currentNode[optionalField]) {
      for (let i = 0; i < currentNode[optionalField].length; i++) {
        const previousValue = previousNode[optionalField][i];
        const updatedValue = currentNode[optionalField][i];

        if (previousValue && updatedValue) {
          previousNode[optionalField][i] = highlightString(
            previousValue,
            updatedValue,
            true,
            isInline
          );
          currentNode[optionalField][i] = highlightString(
            previousValue,
            updatedValue,
            false,
            isInline
          );
        }
      }
    }
  });

  if (
    previousNode.logicscores &&
    previousNode.logicscores &&
    currentNode.logicscores
  ) {
    const highlightedLogicScores = new Array(currentNode.logicscores.length);

    for (let i = 0; i < currentNode.logicscores.length; i++) {
      const previousLogicScore = previousNode.logicscores[i];
      const updatedLogicScore = currentNode.logicscores[i];

      if (previousLogicScore && updatedLogicScore) {
        highlightedLogicScores[i] = highlightString(
          previousLogicScore,
          updatedLogicScore,
          true,
          isInline
        );
      }
    }

    currentNode.highlightedLogicScores = highlightedLogicScores;
  }
};

export const getRuleStrings = (node: IHighlightedNode | INode) => {
  if (node === undefined) {
    throw Error("getRuleString: Invalid new node");
  }
  let ruleStrings: string[] = [];

  if (node.ruleNames && node.ruleActions) {
    //ruleStrings = new Array(newNode.ruleNames.length);

    for (let i = 0; i < node.ruleNames.length; i++) {
      const nodeRuleType = node.ruleTypes[i].toLowerCase();
      const nodeRuleOperator = node.ruleOperators[i];
      const nodeRuleValue = node.ruleValues[i];
      const nodeRuleValueMin = node.ruleValuesMin[i];
      const nodeRuleValueMax = node.ruleValuesMax[i];

      const ruleString =
        node.ruleOperators[i] === "is in range"
          ? `If ${nodeRuleType} ${nodeRuleOperator} ${nodeRuleValueMin} to ${nodeRuleValueMax}`
          : nodeRuleOperator === "Contains"
          ? `If ${nodeRuleType} ${nodeRuleOperator.toLowerCase()} "${nodeRuleValue}"`
          : `If ${nodeRuleType} ${nodeRuleOperator} ${nodeRuleValue}`;

      ruleStrings.push(ruleString);
    }
  }

  return ruleStrings;
};

const createNodeComparisons = async (
  previousNode: IHighlightedNode,
  currentNode: IHighlightedNode,
  isInline: boolean,
  tenantId: string,
  pathwayId: string
) => {
  if (previousNode === undefined || currentNode === undefined) {
    throw Error("Cannot compare nodes when one is undefined");
  }
  let previousNodeClone = JSON.parse(JSON.stringify(previousNode)) as IHighlightedNode;
  let currentNodeClone = JSON.parse(JSON.stringify(currentNode)) as IHighlightedNode;
  currentNodeClone.createdAt = currentNode.createdAt;
  currentNodeClone.updatedAt = currentNode.updatedAt;
  previousNodeClone.createdAt = previousNode.createdAt;
  previousNodeClone.updatedAt = previousNode.updatedAt;

  //previousNodeClone = getRuleStrings(previousNodeClone);
  //currentNodeClone = getRuleStrings(currentNodeClone);

  //Highlight simple strings [title, question]
  highlightStringFields(
    previousNodeClone,
    currentNodeClone,
    ["title", "question"],
    isInline
  );
  //Highlight contentArea
  highlightNodeContentArea(previousNodeClone, currentNodeClone, isInline);

  for (let i = 0; i < currentNodeClone.answers.length; i++) {
    const previousAnswer = previousNode.answers[i];
    const updatedAnswer = currentNodeClone.answers[i];

    // if (previousAnswer && updatedAnswer) {
    currentNodeClone.answers[i] = highlightString(
      previousAnswer,
      updatedAnswer,
      false,
      isInline
    );

    previousNodeClone.answers[i] = highlightString(
      previousAnswer,
      updatedAnswer,
      true,
      isInline
    );
    // }
  }

  // //Highlight Array based fields
  highlightArrayFields(
    previousNodeClone,
    currentNodeClone,
    ["groupNames", "logicfields", "ruleNames", "ruleStrings", "fileNames"],
    isInline
  );

  previousNodeClone.rulesStrings = getRuleStrings(previousNodeClone);
  currentNodeClone.rulesStrings = getRuleStrings(currentNodeClone);

  await highlightActions(
    previousNodeClone,
    currentNodeClone,
    isInline,
    tenantId,
    pathwayId
  );

  return {
    previousNode: previousNodeClone,
    currentNode: currentNodeClone,
    inlineNode: "",
  };
};

const getNodeTitle = async (
  tenantId: string,
  pathwayId: string,
  nodeId: string
) => {
  if (!nodeId) {
    return "No node connected";
  }

  if (nodeId === "I will connect later") {
    return nodeId;
  }

  let nodeTitle = "";
  const nodeRef = firebase
    .firestore()
    .collection(`${tenantId}/${pathwayId}/nodes`)
    .doc(`${nodeId}`);

  try {
    const nodeSnapshot = await nodeRef.get();
    const nodeData = nodeSnapshot.data();
    nodeTitle = nodeData ? nodeData.title : "No node title found";
  } catch (e) {
    nodeTitle = "No node found";
  }

  return nodeTitle;
};

const highlightActions = async (
  previousNode: IHighlightedNode,
  currentNode: IHighlightedNode,
  isInline: boolean,
  tenantId: string,
  pathwayId: string
) => {
  // const previousNode: INodeComparision = JSON.parse(JSON.stringify(
  //   activityData.previousNode
  // ));
  // const updatedNode: INodeComparision = JSON.parse(JSON.stringify(
  //   activityData.currentNode
  // ));
  // const highlightedPreviousNode = JSON.parse(JSON.stringify(
  //   activityData.highlightedPreviousNode
  // ));
  // const highlightedUpdatedNode = JSON.parse(JSON.stringify(
  //   activityData.highlightedUpdatedNode
  // ));
  // const highlightedInlineNode = JSON.parse(JSON.stringify(
  //   activityData.highlightedInlineNode
  // ));
  // let newNode = isPrevious
  //   ? highlightedPreviousNode
  //   : isInline
  //   ? highlightedInlineNode
  //   : highlightedUpdatedNode;

  if (previousNode) {
    const previousDestinations = await getNodeActions(
      tenantId,
      pathwayId,
      previousNode
    );
    previousNode.linkDestinations = previousDestinations.linkDestinations;
    previousNode.logicLinkDestinations =
      previousDestinations.logicLinkDestinations;
  }

  if (currentNode) {
    const updatedDestinations = await getNodeActions(
      tenantId,
      pathwayId,
      currentNode
    );
    currentNode.linkDestinations = updatedDestinations.linkDestinations;
    currentNode.logicLinkDestinations =
      updatedDestinations.logicLinkDestinations;
  }

  //Normal Links
  for (let i = 0; i < previousNode.answers.length; i++) {
    const previousDestination = previousNode.linkDestinations[i];
    const updatedDestination = currentNode.linkDestinations[i];

    if (previousDestination && updatedDestination) {
      previousNode.linkDestinations[i] = highlightString(
        previousDestination,
        updatedDestination,
        true,
        isInline
      );
      currentNode.linkDestinations[i] = highlightString(
        previousDestination,
        updatedDestination,
        false,
        isInline
      );
    }
  }
  //Logic Links
  for (let i = 0; i < previousNode.logicLinkDestinations.length; i++) {
    const previousDestination = previousNode.logicLinkDestinations[i];
    const updatedDestination = currentNode.logicLinkDestinations[i];

    if (previousDestination && updatedDestination) {
      previousNode.logicLinkDestinations[i] = highlightString(
        previousDestination,
        updatedDestination,
        true,
        isInline
      );
      currentNode.logicLinkDestinations[i] = highlightString(
        previousDestination,
        updatedDestination,
        false,
        isInline
      );
    }
  }
};

const getNodeActions = async (
  tenantId: string,
  pathwayId: string,
  node: INode
) => {
  const linkDestinations: string[] = [];
  const logicLinkDestinations: string[] = [];

  if (node.ruleNames && node.ruleActions) {
    for (let i = 0; i < node.ruleNames.length; i++) {
      const nodeId = node.ruleActions[i];
      const nodeTitle = await getNodeTitle(tenantId, pathwayId, nodeId);
      logicLinkDestinations.push(nodeTitle);
    }
  }

  for (let i = 0; i < node.answers.length; i++) {
    if (node.actions[i] && node.actions[i].substring(0, 2) === "@@") {
      linkDestinations.push(
        node.actions[i].substring(2, node.actions[i].length)
      );
    } else {
      const nodeId = node.actions[i];
      const nodeTitle = await getNodeTitle(tenantId, pathwayId, nodeId);
      linkDestinations.push(nodeTitle);
    }
  }

  return { logicLinkDestinations, linkDestinations };
};

// const fetchLinkDestinations = async (
//   activity: IActivityItem,
//   tenantId: string
// ) => {
//   if (activity.previousNode !== undefined && activity.currentNode) {
//     activity.previousNode = await getNodeActions(
//       tenantId,
//       activity.pathwayId,
//       activity.previousNode
//     );
//     activity.currentNode = await getNodeActions(
//       tenantId,
//       activity.pathwayId,
//       activity.currentNode
//     );
//   }

//   activity.previousNode = await highlightActions(activity, true, false);
//   activity.currentNode = await highlightActions(activity, false, false);
//   activity.highlightedInlineNode = await highlightActions(
//     activity,
//     false,
//     true
//   );
// };

export interface INodeComparison {
  previousNode: IHighlightedNode | undefined;
  currentNode: IHighlightedNode | undefined;
}

interface INodeComparisonProps {
  nodesToCompare: INodeComparison;
  tenantId: string;
  editorState: boolean;
  pathwayId: string;
  viewTypeLocked?: boolean;
  defaultView?: "inline" | "sideBySide" | "before" | "after";
}

const NodeComparisonView = ({
  nodesToCompare,
  tenantId,
  editorState,
  pathwayId,
  viewTypeLocked,
  defaultView,
}: INodeComparisonProps) => {
  const [viewType, setViewType] = useState<
    "inline" | "sideBySide" | "before" | "after"
  >(defaultView ?? "sideBySide");
  const [diffedNodes, setDiffedNodes] = useState({
    currentNode: nodesToCompare.currentNode,
    previousNode: nodesToCompare.previousNode,
  });

  const isUpdateView =
    nodesToCompare.currentNode !== undefined &&
    nodesToCompare.previousNode !== undefined;

  useEffect(() => {
    const Load = async () => {
      if (
        nodesToCompare.currentNode !== undefined &&
        nodesToCompare.previousNode !== undefined
      ) {
        const results = await createNodeComparisons(
          nodesToCompare.previousNode,
          nodesToCompare.currentNode,
          viewType === "inline",
          tenantId,
          pathwayId
        );
        setDiffedNodes({
          currentNode: results.currentNode,
          previousNode: results.previousNode,
        });
      }
    };
    Load();
  }, [
    nodesToCompare.currentNode,
    nodesToCompare.previousNode,
    viewType,
    pathwayId,
    tenantId,
  ]);

  useEffect(() => {
    if (defaultView) {
      setViewType(defaultView);
    }
  }, [defaultView]);

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: 8 }}>
      {!viewTypeLocked && isUpdateView && (
        <div>
          <Button
            variant="outlined"
            onClick={() =>
              setViewType((prev) =>
                prev === "inline" ? "sideBySide" : "inline"
              )
            }
          >
            {viewType === "inline"
              ? "Show Side-by-Side Comparison"
              : "Show Inline Comparison"}
          </Button>
        </div>
      )}
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: 8,
          }}
        >
          {viewType === "inline" && (
            <>
              {diffedNodes.currentNode && (
                <NodeView
                  sx={{ flex: 1 }}
                  node={diffedNodes.currentNode}
                  dispatch={() => {}}
                  pathwayState={{
                    route: [],
                    activeNode: undefined,
                    decisionSummary: [],
                    currentPathwayId: "",
                    tenantId: "",
                    nodeContext: { fields: [] },
                  }}
                  decisionSummary={[]}
                  nodeSettings={{
                    disableNavigation: true,
                    allowHtml: true,
                  }}
                  collectionId=""
                />
              )}
            </>
          )}
          {(viewType === "sideBySide" || viewType === "before") &&
            nodesToCompare.previousNode && (
              <>
                {diffedNodes.previousNode && (
                  <NodeView
                    sx={{ flex: 1 }}
                    node={diffedNodes.previousNode}
                    dispatch={() => {}}
                    pathwayState={{
                      route: [],
                      activeNode: undefined,
                      decisionSummary: [],
                      currentPathwayId: "",
                      tenantId: "",
                      nodeContext: { fields: [] },
                    }}
                    decisionSummary={[]}
                    nodeSettings={{
                      disableNavigation: true,
                      allowHtml: true,
                    }}
                    collectionId=""
                  />
                )}
              </>
            )}

          {(viewType === "sideBySide" || viewType === "after") &&
            nodesToCompare.currentNode && (
              <>
                {diffedNodes.currentNode && (
                  <NodeView
                    sx={{ flex: 1 }}
                    node={diffedNodes.currentNode}
                    dispatch={() => {}}
                    pathwayState={{
                      route: [],
                      activeNode: undefined,
                      decisionSummary: [],
                      currentPathwayId: "",
                      tenantId: "",
                      nodeContext: { fields: [] },
                    }}
                    decisionSummary={[]}
                    nodeSettings={{
                      disableNavigation: true,
                      allowHtml: true,
                    }}
                    collectionId=""
                  />
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default NodeComparisonView;
