import { Box } from "@mui/material";
import { INodeContext } from "features/hooks/navigatorReducer";

const NodeLogicScore = ({ nodeContext }: { nodeContext: INodeContext }) => {
  const nodeScore = nodeContext.fields.reduce(
    (total, item) =>
      total +
      (!isNaN(parseInt(item.value + "", 10))
        ? parseInt(item.value + "", 10)
        : 0),
    0
  );

  // if (!nodeScore) {
  //   return <></>;
  // }
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "primary.contrastText",
        p: 2,
        borderRadius: 1,
        textAlign: "center",
        mt: 1,
      }}
    >
      <b className="label">Total Score: {nodeScore}</b>
    </Box>
  );
};

export default NodeLogicScore;
