import React from "react";
import { Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import useServiceWorkerUpdate from "../data/useServiceWorkerUpdate";
import AppReadyGate from "./AppReadyGate";
import ModuleSelector from "./ModuleSelector";
import SecureRoute from "app/components/SecureRoute";
import AppContainer from "./AppContainer";
import NotFound from "./NotFound";
import PathwayViewer from "features/NodeViewer/PathwayViewer";
import PathwayViewerV2 from "features/NodeEditorV2/PathwayViewerV2";
import PatientBanner from "features/PatientContext/PatientBanner";

import { styled } from "@mui/material";
import usePatient from "features/hooks/usePatient";
import NotAuthorised from "./NotAuthorised";
import Launcher from "features/SmartOnFHIR/Launcher";
import NagScreen from "features/NagScreen/NagScreen";
import { getEditorVersion } from "features/pathwaybuilder/utils/editorConverters";
import SharedAccountGate from "app/components/SharedAccountGate";

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.log("ErrorEB", error, info);
};

function ErrorFallback({ error }: any) {
  console.log("EB");
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

// const makeLoadable = (loader: any, props?: any) =>
//   Loadable({
//     loader,
//     loading: LoadingComponent,
//     render(loaded: any) {
//       const Component = loaded.default;
//       return <Component {...props} />;
//     },
//   });

const AsyncGalleryComponent = React.lazy(
  // () => import("features/superAdmin/manageGallery/GalleryComponent")
  () => import("features/gallery/GalleryManager")
);

const AsyncFindAndReplacePage = React.lazy(
  () => import("features/pathwaybuilder/FindAndReplace/FindReplacePage")
);

const AsyncBackupRestore = React.lazy(
  () => import("../../features/superAdmin/BackupRestore")
);
const AsyncBackupManager = React.lazy(
  () => import("../../features/superAdmin/BackupManager")
);

const AsyncTutorial = React.lazy(
  () => import("../../features/Tutorials/Tutorials")
);

const UserProfile = React.lazy(
  () => import("../../features/user/Settings/AccountSettings")
);

const UserProfileSecurity = React.lazy(
  () => import("../../features/user/Settings/mobile/SecurityMobile")
);

const UserProfileInformation = React.lazy(
  () => import("../../features/user/Settings/mobile/UserInformationMobile")
);

const RecentActivityPage = React.lazy(
  () =>
    import(
      "../../features/pathwaybuilder/PathwayRecentActivity/RecentActivityContainer"
    )
);

// const AsyncNodePreviewContainer = React.lazy(
//   () => import("../../features/pathwaybuilder/NodePreview/NodePreviewContainer")
// );
// const AsyncNodeForm = React.lazy(
//   () => import("../../features/pathwaybuilder/NodeForm/NodeFormContainer")
// );
// const AsyncModalManager = React.lazy(() => import("../../features/modals"));

const AsyncOperationsPage = React.lazy(
  () => import("../../features/operations/OperationsPage")
);

const AsyncImportContentPage = React.lazy(
  () => import("../../features/importing/ImportContainer")
);
const AsyncDashboard = React.lazy(
  () => import("../../features/dashboard/Dashboard")
);
const ActivateAccount = React.lazy(
  () => import("../../features/auth/ActivateAccount/ActivateAccount")
);
const ResetPassword = React.lazy(
  () => import("../../features/auth/Login/ResetPassword")
);
const AsyncUserManagement = React.lazy(
  () => import("../../features/userManagement/UserManagement")
);

const AsyncSuperAdminTools = React.lazy(
  () => import("../../features/superAdmin/SuperAdminTools")
);
// const AsyncManageGallery = makeLoadable(
//   () => import("../../features/superAdmin/manageGallery/GalleryComponent")
// );

const IssueLog = React.lazy(() => import("../../features/issues/issues"));

const ApprovalList = React.lazy(
  () => import("../../features/approvalManager/ApprovalPolicyList")
);

const ApprovalEdit = React.lazy(
  () => import("../../features/approvalManager/ApprovalPolicyEditor")
);

const AsyncRoleManager = React.lazy(
  () => import("features/userManagement/RoleManager")
);

const LaunchPadContainer = React.lazy(
  () => import("features/LaunchPad/LaunchPadContaner")
);

const LaunchPadContainerMobile = React.lazy(
  () => import("features/LaunchPad/mobile/LaunchPadContainerMobile")
);

const PathwayBuilder = React.lazy(
  () => import("features/pathwaybuilder/PathwayBuilder/PathwayBuilder")
);

const DocumentLibraryPage = React.lazy(
  () => import("features/documentLibrary/DocumentLibraryPage")
);

const PathwayEditor = React.lazy(
  () => import("features/NodeEditor/PathwayEditor")
);

const PathwayEditorV2 = React.lazy(
  () => import("features/NodeEditorV2/PathwayEditorV2")
);

const Login = React.lazy(() => import("features/auth/Login/Login"));

const TenantManager = React.lazy(
  () => import("features/TenantManager/TenantManager")
);

const PathwayAnalytics = React.lazy(
  () => import("features/analytics/PathwayAnalytics")
);

// const AnalyticsDashboard = React.lazy(
//   () => import("features/analytics/Analytics")
// );

const PathwayUsage = React.lazy(
  () => import("features/analytics/PathwayUsage")
);

const AppInner = () => {
  useServiceWorkerUpdate();

  const { isPatientLoaded } = usePatient();

  const MainConsoleWrapper = styled("div")(({ theme }) => ({
    // padding: theme.spacing(2),
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    "& > div": {
      width: "100%",
    },
  }));

  return (
    <div>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/activate-account"
          render={() => <ActivateAccount title={"Account Activation"} />}
        />

        <Route exact path="/reset-password" render={() => <ResetPassword />} />
        <Route exact path="/launch" component={Launcher}></Route>
        <Route exact path="/not-authorised" component={NotAuthorised} />
        {/* Everything inside SecureRoute require the user profile to be loaded */}
        <SecureRoute path="/(.*)">
          {/*  Checks that the default providers have finished loading after login */}
          <AppReadyGate>
            {/* Default Route that does not require a module to be loaded */}
            <Route exact path="/" component={ModuleSelector} />

            {/* All components past this point require that the module context be loaded. */}
            <Route path="/(.+)">
              <AppContainer>
                <div
                  style={{
                    flexGrow: 1,
                    maxWidth: "100vw",
                  }}
                >
                  {isPatientLoaded && <PatientBanner />}

                  <SharedAccountGate allowed={false}>
                    <NagScreen />
                  </SharedAccountGate>

                  <MainConsoleWrapper>
                    <Switch>
                      <Route
                        path="/recent-activity/:pathwayId?/:collection?"
                        component={RecentActivityPage}
                      />
                      <SecureRoute
                        roles={["editor", "chiefEditor"]}
                        path="/analytics"
                        exact
                      >
                        <AsyncDashboard />
                      </SecureRoute>
                      <SecureRoute
                        roles={["editor", "chiefEditor"]}
                        path="/analytics/pathway-usage"
                        exact
                      >
                        <PathwayAnalytics />
                      </SecureRoute>
                      <SecureRoute
                        roles={["editor", "chiefEditor"]}
                        path="/analytics/pathway-usage/:pathwayId"
                      >
                        <PathwayUsage />
                      </SecureRoute>
                      <Route
                        path="/manage-users"
                        component={AsyncUserManagement}
                      />
                      <Route path="/issue-log" component={IssueLog} />
                      <Route
                        path="/document-library"
                        component={DocumentLibraryPage}
                      />
                      <Route path="/user-profile" component={UserProfile} />

                      <Route
                        path="/user-profile-security"
                        component={UserProfileSecurity}
                      />
                      <Route
                        path="/user-profile-information"
                        component={UserProfileInformation}
                      />

                      <Route
                        path="/operations"
                        component={AsyncOperationsPage}
                      />
                      <Route
                        path="/import/:targetPathway?/:targetCollection?"
                        component={AsyncImportContentPage}
                      />
                      <Route path="/tutorials" component={AsyncTutorial} />
                      <Route
                        path="/superadmintools"
                        component={AsyncSuperAdminTools}
                      />
                      <Route
                        path="/find-and-replace"
                        component={AsyncFindAndReplacePage}
                      />
                      <Route
                        path="/backupManager/:backupId"
                        component={AsyncBackupRestore}
                      />
                      <Route
                        path="/backupManager"
                        component={AsyncBackupManager}
                      />
                      <Route
                        path="/manage-gallery"
                        component={AsyncGalleryComponent}
                      />
                      <Route
                        path="/approval-policy-edit/:id?"
                        component={ApprovalEdit}
                      />
                      <Route
                        path="/approval-policies"
                        component={ApprovalList}
                      />
                      <Route path="/roles" component={AsyncRoleManager} />
                      <Route path="/launchpads/:mode?">
                        <LaunchPadContainer />
                      </Route>
                      <Route path="/mobile/launchpads/:launchpadId?/:groupId?">
                        <LaunchPadContainerMobile />
                      </Route>
                      {/* <Route
                        path="/node/:id"
                        component={AsyncNodePreviewContainer}
                      /> */}
                      {/* <Route
                      path="/pathway-builder/:pathwayId"
                      component={PathwayBuilder}
                    /> */}
                      {/* <Route path="/designer" component={AsyncDesigner} />
                    <Route path="/manage/:id" component={AsyncNodeForm} /> */}
                      {/* <Route
                        path="/stagemanage/:id"
                        component={AsyncNodeForm}
                      />

                      <Route
                        path="/nextnode/:title"
                        component={AsyncNodePreviewContainer}
                      /> */}
                      {/* <Route path="/editPathway" component={AsyncEditPathway} /> */}
                      {/* <Route path="/createNode" component={AsyncNodeForm} /> */}
                      <Route
                        path="/pathway-builder/:pathwayId/:viewType?"
                        component={PathwayBuilder}
                      />
                      <Route
                        path="/pathway-editor/:pathwayId/:collectionId/:nodeId?/:viewType?"
                        component={
                          getEditorVersion() === "V2"
                            ? PathwayEditorV2
                            : PathwayEditor
                        }
                      />
                      <Route
                        path="/pathway/:pathwayId/:collectionId?/:nodeId?"
                        component={
                          getEditorVersion() === "V2"
                            ? PathwayViewerV2
                            : PathwayViewer
                        }
                      />
                      <Route path="/tenant-manager" component={TenantManager} />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </MainConsoleWrapper>
                </div>
              </AppContainer>
            </Route>
          </AppReadyGate>
        </SecureRoute>
      </Switch>
    </div>
  );
};

// const state = [{ title: "profile", path: "firebase.profile" }];

const App = (props: any) => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
    {/* <FireStoreContainer state={state}> */}
    <AppInner />
    {/* </FireStoreContainer> */}
  </ErrorBoundary>
);

export default App;
