import { Header, Icon, Popup } from "semantic-ui-react";

interface FAndReWarningProps {
  component: React.ReactNode;
  warning: string;
  title: string;
}

const FindAndReplaceWarning = ({
  component,
  warning,
  title,
}: FAndReWarningProps) => {
  return (
    <Popup
      basic
      position="top center"
      wide="very"
      trigger={<div>{component}</div>}
    >
      <Popup.Header style={{ color: "orange", textAlign: "center" }}>
        <Icon name="info circle" color="orange" />
        Warning {title}
      </Popup.Header>
      <Popup.Content>
        <Header as="h5" style={{ color: "orange", marginTop: 3 }}>
          <Header.Content>
            {warning} in the Document Library and on ALL Nodes and Pathways
            where this file is currently present within the Development
            Environment. <br />
            The new file will be visible to all users viewing the Development
            Environment in its current state. In order for your newly updated
            file to be reflected within the Live Environment you will need to
            complete a deployment in the Pathway Editor on ALL affected Pathways
            following the update.
          </Header.Content>
        </Header>
      </Popup.Content>
    </Popup>
  );
};

export default FindAndReplaceWarning;
