import { IDocument, IFolder } from "../../../../types/DocumentLibrary";
import { DocumentType } from "../../../../types/DocumentLibrary";

export const filterOptions = [
  {
    key: "all-files",
    image: { src: "/assets/documentLibrary/all-files.svg" },
    text: "All Documents",
    value: "ALL-FILES",
  },
  {
    key: "document",
    image: { src: "/assets/documentLibrary/file.svg" },
    text: "Files",
    value: "DOCUMENT",
  },
  {
    key: "image",
    image: { src: "/assets/documentLibrary/image.svg" },
    text: "Images",
    value: "IMAGE",
  },
  {
    key: "video",
    image: { src: "/assets/documentLibrary/video.svg" },
    text: "Videos",
    value: "VIDEO",
  },
];

export const sortOptions = [
  {
    key: "dateModified",
    text: "Last Modified",
    value: "updatedAt",
  },
  {
    key: "fileTitle",
    text: "Title",
    value: "fileTitle",
  },
];

export const getDocTypeIcon = (documentType: DocumentType) => {
  switch (documentType) {
    case "IMAGE":
      return "/assets/documentLibrary/image.svg";
    case "DOCUMENT":
      return "/assets/documentLibrary/file.svg";
    case "VIDEO":
      return "/assets/documentLibrary/video.svg";
    case "FOLDER":
      return "/assets/documentLibrary/folder.svg";
    default:
      throw Error("Unknown document type");
  }
};

export const getTargetFolderPath = (
  source: string,
  target: string,
  folderPath: string,
  targetFolder: IFolder | undefined
) => {
  if (source === target) {
    return source;
  }

  const suffixSourcePath = source
    .split("")
    .filter((item, i) => item !== folderPath[i]);

  let divergenceFound = false;

  const c = source.split("").filter((item, i) => {
    if (item !== target[i]) {
      divergenceFound = true;
    }
    return item !== target[i] || divergenceFound;
  });

  if (target !== "/" && typeof targetFolder !== "undefined") {
    target = `${target}/${c.join("")}`;
  }

  if (target !== "/" && typeof targetFolder === "undefined") {
    target = `${target}${suffixSourcePath.join("")}`;
  }

  if (target === "/" && typeof targetFolder === "undefined") {
    target = `${suffixSourcePath.join("")}`;
  }

  return target;
};

export const getDirectChildren = (folders: IFolder[], folderPath: string) => {
  return folders
    .filter(
      (folder: IFolder) =>
        folder.folderPath.startsWith(folderPath) &&
        folder.folderPath.substring(folderPath.length + 1).indexOf("/") === -1
    )
    .filter((x: IFolder) => x.folderPath !== folderPath);
};

export const getNestedLevel = (
  docOrFolder: IFolder | IDocument,
  folderPath: string
) => {
  const folderPathLevel = folderPath.split("/").filter((e) => e);
  return docOrFolder.folderPath.split("/").at(folderPathLevel.length + 1);
};

export const collator = new Intl.Collator("en-GB", {
  numeric: true,
  sensitivity: "base",
});
