import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDynamicNode, NodeBlock } from "../../../types/Nodes";
import { getDynamicEmptyNode } from "features/pathwaybuilder/utils/pathwayHelperV2";

export type DynamicNodeEditorContextState = {
  node: IDynamicNode;
  isDirty: boolean;
  editingBlock?: NodeBlock;
};

const initialState: DynamicNodeEditorContextState = {
  node: getDynamicEmptyNode(),
  isDirty: false,
};

export const dynamicNodeEditorSlice = createSlice({
  name: "dynamicNodeEditor",
  initialState: initialState,
  reducers: {
    reloadNode: (state, action: PayloadAction<IDynamicNode>) => {
      state.node = action.payload;
    },
    enableBlockEdit: (state, action: PayloadAction<NodeBlock>) => {
      state.editingBlock = action.payload;
    },
    cancelBlockEdit: (state) => {
      state.editingBlock = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { reloadNode, enableBlockEdit, cancelBlockEdit } =
  dynamicNodeEditorSlice.actions;

export default dynamicNodeEditorSlice.reducer;
