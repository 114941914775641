import { useState } from "react";
import {
  Button,
  Form,
  Icon,
  Modal,
  Popup,
  SemanticCOLORS,
  SemanticFLOATS,
  SemanticICONS,
} from "semantic-ui-react";

const ConfirmationModal = ({
  title,
  message,
  label,
  confirmText,
  buttonText,
  buttonColor,
  onConfirm,
  disabled,
  deleteCode,
  triggerButtonText,
  buttonIcon,
  triggerHelperText,
  triggerButtonFloated,
  popupPosition,
  customTrigger,
}: {
  title: string;
  message: string;
  label: string;
  confirmText: string;
  buttonText: string;
  buttonColor: SemanticCOLORS;
  onConfirm: () => void;
  disabled?: boolean;
  deleteCode?: string;
  triggerButtonText?: string;
  buttonIcon?: SemanticICONS;
  triggerHelperText?: string;
  popupPosition?:
    | "top left"
    | "top right"
    | "bottom right"
    | "bottom left"
    | "right center"
    | "left center"
    | "top center"
    | "bottom center"
    | undefined;
  triggerButtonFloated?: SemanticFLOATS;
  customTrigger?: JSX.Element;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    if (input.toLocaleLowerCase() !== confirmText.toLocaleLowerCase()) {
      setError({
        content: `Please enter ${deleteCode ? deleteCode : label}`,
        pointing: "below",
      });
      return;
    }

    setLoading(true);
    onConfirm();
    setOpen(false);
    setLoading(false);
    setInput("");
  };
  let triggerNode: React.ReactNode = buttonIcon && <Icon name={buttonIcon} />;

  if (triggerHelperText) {
    triggerNode = (
      <Popup
        style={{ marginBottom: 20 }}
        position={popupPosition}
        content={triggerHelperText}
        trigger={buttonIcon && <Icon name={buttonIcon} />}
      />
    );
  }

  // if (triggerButtonText === undefined) {
  //   triggerButtonText = buttonText;
  // }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        customTrigger ? (
          customTrigger
        ) : (
          <Button
            floated={triggerButtonFloated || undefined}
            icon={buttonIcon !== undefined}
            color={buttonColor}
            disabled={disabled}
          >
            {triggerButtonText && triggerButtonText}
            {triggerNode}
          </Button>
        )
      }
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Form>
          <p>{message}</p>
          <Form.Input
            placeholder={confirmText}
            error={error}
            required
            label={label}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setInput("");
            setOpen(false);
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          content={buttonText}
          onClick={onSubmit}
          color={buttonColor}
          loading={loading}
          disabled={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmationModal;
