import { useCallback, useEffect, useState } from "react";
import { ICrossCoverDocument } from "../../../types/CrossCover";
import { getDb } from "../../app/data/DbProvider";
import useUser from "./useUser";

const db = getDb();

const useDocument = <T extends ICrossCoverDocument>(
  collectionPath: string,
  docId?: string,
  excludeData?: boolean
) => {
  const [document, setDocument] = useState<T>();
  const [documentId, setDocumentId] = useState<string>(docId || "");

  const { profile } = useUser();

  if (profile === undefined) {
    throw Error("Unknown user cannot use documents");
  }

  const mergePartial = useCallback(
    async (docId: string, docPart: Partial<T>) => {
      await db.updateDocument(
        collectionPath,
        { id: docId, ...docPart },
        profile.uid,
        true
      );
    },
    [collectionPath, profile.uid]
  );

  const mergeDocument = useCallback(
    async (doc: Partial<T>) => {
      await db.updateDocument(collectionPath, doc, profile.uid, true);
    },
    [collectionPath, profile.uid]
  );

  const overwriteDocument = async (doc: T) => {
    await db.updateDocument(collectionPath, doc, profile.uid, false);
  };

  const addDocument = useCallback(
    async (doc: T) => {
      const docRef = await db.addDocument(collectionPath, doc, profile.uid);
      setDocumentId(docRef);
      return docRef;
    },
    [collectionPath, profile.uid]
  );

  const changeDocument = useCallback((docId: string) => {
    setDocumentId(docId);
  }, []);

  const getDocument = useCallback(
    async (docId: string) => {
      return await db.getDoc<T>(collectionPath, docId);
    },
    [collectionPath]
  );

  useEffect(() => {
    if (collectionPath && documentId && db) {
      const unsubscribe = db.watchDoc<T>(
        collectionPath,
        documentId,
        (entity) => setDocument(entity),
        { excludeData: excludeData }
      );
      return unsubscribe;
    }
  }, [collectionPath, documentId, excludeData]);

  return {
    document,
    mergeDocument,
    mergePartial,
    overwriteDocument,
    addDocument,
    getDocument,
    changeDocument,
    attachedPathwayId: collectionPath,
  };
};

export default useDocument;
