import { Box, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button/Button";
import useModules from "features/hooks/useModules";
import SearchPathways from "app/components/PathwaySearchDropdown/SearchPathways";
import PatientLookup from "features/PatientContext/PatientLookup";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ICrossCoverPatient } from "../../../functions/src/http/nhsdigital/pds";
import BannerHeader from "./BannerHeader";
import Footer from "./Footer";
import BannerHeaderMobile from "./mobile/BannerHeaderMobile";

const ModuleSelector = () => {
  const { modules, setModule, activeModule } = useModules();
  const { push } = useHistory();
  const [pathwayId, setPathwayId] = useState<string>();
  const [patient, setPatient] = useState<ICrossCoverPatient | undefined>();

  const theme = useTheme();

  const onModuleSelect = (moduleId: string) => {
    console.log("Redirect");
    setModule(moduleId);
    push("/launchpads");
  };

  const onStartButton = () => {
    const target: string[] = [];
    target.push(`/pathway/${pathwayId}`);
    if (patient) {
      target.push(`?nhsNumber=${patient.nhsNumber}`);
    }
    push(target.join(""));
  };
  useEffect(() => {
    if (
      !Boolean(activeModule) &&
      modules &&
      modules.filter((x) => x.isActive).length === 1
    ) {
      setModule(modules.filter((x) => x.isActive)[0].id || "");
      push("/launchpads");
    }
  }, [setModule, modules, activeModule, push]);

  return (
    <>
      {theme.isMobileView ? <BannerHeaderMobile /> : <BannerHeader />}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "56px",
          height: theme.isMobileView ? "calc(100vh  - 56px)" : "auto",
        }}
      >
        <Typography variant="h1" sx={{ margin: "24px 0px" }}>
          Quick Start
        </Typography>
        <div>
          <Typography variant="h5" sx={{ margin: "16px 0px" }}>
            Select a Specialty
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: theme.isMobileView ? "column" : "row",
              alignItems: "center",
              flexWrap:"wrap",
              maxWidth: 1300
            }}
          >
            {modules &&
              modules
                .filter((x) => x.isActive)
                .sort((a, b) => a.order - b.order)
                .map((m) => (
                  <Box
                    key={m.id}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      marginRight: theme.isMobileView ? "0px" : "24px",
                      border: "2px solid #C2C2C2",
                      "&:hover": {
                        borderColor: "primary.main",
                        borderWidth: "2px",
                        backgroundColor: "#F5F9F9",
                      },
                      marginBottom: theme.isMobileView ? "24px" : "0px",
                      width: "290px",
                      textAlign: "center",
                      padding: "0px",
                      mb:2
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        height: "100%",
                        padding: "16px",
                        "&:hover": {
                          backgroundColor: "rgba(1, 93, 103, 0)",
                        },
                      }}
                      onClick={() => onModuleSelect(m.id || "")}
                    >
                      <img src={m.logoFull} alt={m.title} height="70" />
                    </Button>
                  </Box>
                ))}
          </div>
        </div>
        {process.env.PDS_ENABLED && (
          <>
            <div style={{ flexGrow: 1 }}>
              <h1>Search for a pathway</h1>
              <h2>Pathway {pathwayId} Selected</h2>
              <SearchPathways
                onSelect={(pathwayId) => {
                  setPathwayId(pathwayId);
                }}
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <h1>Search for a patient</h1>
              <PatientLookup
                onPDSSkipped={() => {}}
                onPDSConfirmed={(patient: ICrossCoverPatient) => {
                  setPatient(patient);
                }}
              />
            </div>

            <div style={{ flexGrow: 1 }}>
              <Button onClick={() => onStartButton()}>Start</Button>
            </div>
          </>
        )}
      </div>
      <Footer
        displayFull={false}
        sx={{
          position: theme.isMobileView ? undefined : "absolute",
          bottom: theme.isMobileView ? undefined : 0,
        }}
      />
    </>
  );
};

export default ModuleSelector;
