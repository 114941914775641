import { Box, Button, Chip, TextField, Tooltip } from "@mui/material";
import SafeHtml from "app/components/SafeHtml";
import { Dispatch, useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import {
  IBlockRules,
  IDynamicNode,
  ILinkButtonData,
  INodeAction,
  NodeBlock,
} from "../../../../types/Nodes";
import {
  getNodeLinksAsActions,
  getNodeTitle,
} from "features/pathwaybuilder/utils/pathwayHelperV2";
import { INodeContext, PathwayActions } from "features/hooks/navigatorReducer";
import { isEmptyLinkBlock } from "../../pathwaybuilder/utils/pathwayHelperV2";
import HumanReadableBlockRule from "../HumanReadableBlockRule";
import { INodeViewSettings } from "features/NodeViewer/NodeView";
import { useHighlightGraphContext } from "features/pathwaybuilder/Designer/HighlightGraphContext";
import { routeItemToAction } from "features/pathwaybuilder/utils/pathwayHelper";


interface INodeAnswerActionProps {
  action: ILinkButtonData;
  dispatch: Dispatch<PathwayActions>;
  navigationBlocked: {
    isDisabled: boolean;
    blockRequiredUntoggled: string[];
  };
  nodeContext?: INodeContext;
  isWidget?: boolean;
  onLinkTextChanged: (linkText: string) => void;
  isEditing: boolean;
  collection: string;
  tenantId: string;
  isPreview: boolean;
  blockRules: IBlockRules;
  block: NodeBlock;
  node?: IDynamicNode;
  nodeSettings: INodeViewSettings;
}

const NodeLinkButtonView = ({
  action,
  dispatch,
  navigationBlocked,
  nodeContext,
  isWidget,
  isEditing,
  collection,
  tenantId,
  onLinkTextChanged,
  isPreview,
  blockRules,
  block,
  node,
  nodeSettings,
}: INodeAnswerActionProps) => {
  const isEmptyLink = isEmptyLinkBlock(action);

  const [isModalOpen, setIsModelOpen] = useState(false);
  const [pathwayNodeTitle, setPathwayNodeTitle] = useState<string>("");

  const { highlightNode, highlightEdge, clearHighlights } =
    useHighlightGraphContext();

  useEffect(() => {
    if (action.isPathwayToPathwayLink) {
      getNodeTitle(tenantId || "", collection, action as INodeAction).then(
        (title) => setPathwayNodeTitle(title)
      );
    }
  }, [action, collection, tenantId]);

  const clickButtonEvent = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (isEditing) {
      e.preventDefault();
      return;
    }

    // Remove all of the graph highlights after clicking the button
    handleMouseLeave();

    if (action.isPathwayToPathwayLink) {
      setIsModelOpen(true);
      return;
    }
    if (nodeSettings.nextEpisodeNodeTargetId) {
      if (action.nodeId === nodeSettings.nextEpisodeNodeTargetId) {
        dispatch({
          type: "MOVE_TO_NEXT_NODE",
          payload: {
            pathwayId: action.pathwayId,
            nodeId: action.nodeId,
            answer: action.linkText,
          },
        });
      }
      return;
    }
    dispatch({
      type: "MOVE_TO_NEXT_NODE",
      payload: {
        pathwayId: action.pathwayId,
        nodeId: action.nodeId,
        answer: action.linkText,
      },
    });
  };

  // Highlight the edge and target node when hovering over a button in the designer
  const handleMouseEnter = () => {
    if (node && node.id) {
      // Create the dagre edge id and remove spaces from pathway ids
      const targetId = routeItemToAction(action);
      const linkIndex = getNodeLinksAsActions(node).indexOf(targetId);
      const edgeId = `__${node.id}__${targetId}__${linkIndex}`.replace(
        /\s/g,
        ""
      );

      if (highlightNode && highlightEdge) {
        highlightNode(targetId, node.id);
        highlightEdge(edgeId);
      }
    }
  };

  // Remove all of the graph highlights after leaving the button
  const handleMouseLeave = () => {
    if (clearHighlights) {
      clearHighlights();
    }
  };

  return (
    <>
      {(nodeSettings.showAllLogicAnswers || ((!isEmptyLink && isPreview) || !isPreview)) && (
        <>
          {!isEditing &&
            navigationBlocked.blockRequiredUntoggled.length > 0 && (
              <Tooltip
                title={`The navigation is blocked as the following required ${
                  navigationBlocked.blockRequiredUntoggled.length > 1
                    ? "blocks"
                    : "block"
                } ${navigationBlocked.blockRequiredUntoggled.join(", ")} ${
                  navigationBlocked.blockRequiredUntoggled.length > 1
                    ? "are"
                    : "is"
                } not toggled`}
              >
                <span>
                  <Button
                    id="input"
                    size="large"
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    disabled={true}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      cursor: isEditing ? "text" : "pointer",
                      wordBreak: "break-word",
                      whiteSpace: "pre-wrap",
                      fontSize: 16,
                    }}
                  >
                    {!action.isPathwayToPathwayLink && (
                      <SafeHtml html={action.linkText} />
                    )}
                    {action.isPathwayToPathwayLink && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <SafeHtml html={action.linkText} />
                        <ArrowCircleRightOutlinedIcon sx={{ mx: 1 }} />
                        <SafeHtml html={action.pathwayId} />
                        <ArrowCircleRightOutlinedIcon sx={{ mx: 1 }} />
                        <SafeHtml html={pathwayNodeTitle} />
                      </Box>
                    )}
                  </Button>
                </span>
              </Tooltip>
            )}

          {nodeSettings.nextEpisodeNodeTargetId === action.nodeId && (
            <Chip
              icon={<ArrowCircleDownOutlinedIcon />}
              label={nodeSettings.tagText}
              color="success"
              sx={{ my: 1 }}
            />
          )}
          {blockRules.rule && node && nodeSettings.showRuleStrings && (
            <HumanReadableBlockRule block={block} state={node} />
          )}

          {!isEditing &&
            navigationBlocked.blockRequiredUntoggled.length <= 0 && (
              <Button
                id="input"
                size="large"
                variant="contained"
                fullWidth={true}
                color="primary"
                disabled={navigationBlocked.isDisabled || isEmptyLink}
                onClick={(e) => clickButtonEvent(e)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  cursor: isEditing ? "text" : "pointer",
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                  fontSize: 16,
                }}
              >
                {!action.isPathwayToPathwayLink && (
                  <SafeHtml html={action.linkText ||  "This is a Link Button block. Select the Edit button for this block to edit its title and functionality."} />
                )}
                {action.isPathwayToPathwayLink && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <SafeHtml html={action.linkText} />
                    <ArrowCircleRightOutlinedIcon sx={{ mx: 1 }} />
                    <SafeHtml html={action.pathwayId} />
                    <ArrowCircleRightOutlinedIcon sx={{ mx: 1 }} />
                    <SafeHtml html={pathwayNodeTitle} />
                  </Box>
                )}
              </Button>
            )}
          {isEditing && (
                  <Box
                  sx={{
                    backgroundColor: "primary.main",
                    p: 1.5,
                    width: "100%",
                    fontSize: "24px",
                    color: "#FFF",
                    textAlign: "center",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
          <TextField
            sx={{
              "& input": {
                textAlign: "center",
                color: "#FFF",
              },
              "& label.Mui-focused": {
                color: "#FFF",
              },
              "& label": {
                color: "#FFF",
                lineHeight: "100%",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#FFF",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#FFF",
                },
                "&:hover fieldset": {
                  borderColor: "#FFF",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#FFF",
                },
              },
            }}
            onChange={(e) => {
              onLinkTextChanged(e.target.value || "");
            }}
            label="Click Here to add text to your link button"
            placeholder="This is a Link Button block. Select the Edit button for this block to edit its title and functionality."
            fullWidth
            value={action.linkText}
          ></TextField>
          </Box>
        )}
        </>
      )}

      <Modal
        size="mini"
        open={isModalOpen}
        closeIcon
        onClose={() => setIsModelOpen(false)}
      >
        <Modal.Header>Keep Decision summary</Modal.Header>
        <Modal.Content>
          You are about to switch into a different Pathway, please confirm if
          you want to keep the decision summary or erase it.
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="error"
            onClick={() => {
              setIsModelOpen(false);
              dispatch({
                type: "MOVE_TO_NEXT_NODE",
                payload: {
                  pathwayId: action.pathwayId,
                  nodeId: action.nodeId,
                  answer: action.linkText,
                },
              });
            }}
          >
            Discard
          </Button>
          <Button
            color="success"
            onClick={() => {
              setIsModelOpen(false);
              dispatch({
                type: "MOVE_TO_NEXT_NODE",
                payload: {
                  pathwayId: action.pathwayId,
                  nodeId: action.nodeId,
                  answer: action.linkText,
                },
              });
            }}
          >
            Keep
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default NodeLinkButtonView;
