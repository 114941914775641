import { Box, IconButton, Typography } from "@mui/material";
import ViewTypeSwitch from "features/pathwaybuilder/PathwayBuilder/ViewTypeSwitch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { PathwayState } from "features/hooks/navigatorReducer";

const NodeEditorHeader = ({
  pathwayState,
}: {
  pathwayState: Partial<PathwayState>;
}) => {
  const history = useHistory();
  return (
    <Box
      sx={{
        backgroundColor: "white",
        p: 2,
        borderBottom: "1px solid #C2C2C2",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {pathwayState.currentPathwayId} Pathway
        </Typography>
      </Box>
      <ViewTypeSwitch
        isFromNode={true}
        pathwayId={pathwayState.currentPathwayId}
      />
    </Box>
  );
};

export default NodeEditorHeader;
