import { Menu, Form, List, Icon } from "semantic-ui-react";
import { useEffect, useRef, useState } from "react";
import functions from "app/common/util/functions";
import SearchPathwaysDropdown from "./SearchPathwaysDropdown";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useThrottledCallback } from "use-debounce";
import useUser from "features/hooks/useUser";
import { useMediaQuery, useTheme } from "@mui/material";

export const columns = [
  "title",
  "nodeContentArea",
  "question",
  "answers",
  "logicfields",
  "groupNames",
  "ruleNames",
];

const ListHeader = ({ text }: { text: string }) => {
  return (
    <List.Item
      style={{
        color: "white",
        fontWeight: "bold",
        padding: "8px 15px",
        borderRadius: "0px",
        backgroundColor: "rgb(0, 181, 173)",
      }}
      onClick={() => {}}
    >
      <List.Content>
        <p>{text}</p>
      </List.Content>
    </List.Item>
  );
};

const NoResults = () => {
  return (
    <List.Item
      style={{
        color: "black",
        padding: "10px 15px",
        borderRadius: "0px",
        backgroundColor: "white",
      }}
      onClick={() => {}}
    >
      <List.Content>
        <p>No Results</p>
      </List.Content>
    </List.Item>
  );
};



const SearchPathways = ({
  onSelect,
}: {
  onSelect: (pathwayId: string) => void;
}) => {
  const db = firebase.firestore();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [pathways, setPathways] = useState<Array<string | JSX.Element>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [titleLoading, setTitleLoading] = useState<boolean>(false);
  const { activeTenantId } = useUser();

  if (activeTenantId === undefined) {
    throw Error("Invalid active tenant");
  }

  const dropdownRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handler = (event: any) => {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [dropdownRef]);

  const onSearch = async (enter: boolean): Promise<void> => {
    try {
      if (searchTerm.length === 0 || contentLoading) {
        return;
      }

      let nodeSearchResults;

      setTitleLoading(true);
      if (enter) {
        setSearching(true);
        setOpen(false);
        setContentLoading(true);

        nodeSearchResults = await functions.nodeSearch({
          searchTerms: [
            {
              term: searchTerm,
              type: "caseinsensitive",
            },
          ],
          pathways: [],
          columns,
          tenantId: activeTenantId,
        });

        nodeSearchResults = nodeSearchResults.sort((a, b) =>
          a.nodePath
            .substring(a.nodePath.indexOf("/") + 1, a.nodePath.lastIndexOf("/"))
            .toLowerCase()
            .localeCompare(
              b.nodePath
                .substring(
                  b.nodePath.indexOf("/") + 1,
                  b.nodePath.lastIndexOf("/")
                )
                .toLowerCase()
            )
        );
      }

      let pathwaySearchResults = await functions.pathwaySearch({
        searchTerms: searchTerm,
        tenantId: activeTenantId,
      });

      pathwaySearchResults = pathwaySearchResults.sort((a, b) =>
        a.pathwayName.toLowerCase().localeCompare(b.pathwayName.toLowerCase())
      );

      let firstSet = new Set<string | JSX.Element>();
      let secondSet = new Set<string | JSX.Element>();

      firstSet.add(
        <ListHeader text="By Pathway Title" key={"By Pathway Title"} />
      );

      if (enter) {
        secondSet.add(
          <ListHeader text="By Node Content" key={"By Node Content"} />
        );
      }

      const published = await db
        .collection(`${activeTenantId}`)
        .doc("Published")
        .get();

      if (published.exists) {
        const data = published.data();

        if (data) {
          for (const result of pathwaySearchResults) {
            const pathwayName = result.pathwayName;
            if (!firstSet.has(pathwayName) && data[pathwayName]?.published) {
              firstSet.add(pathwayName);
            }
          }

          if (enter && nodeSearchResults) {
            for (const result of nodeSearchResults) {
              const nodePath = result.nodePath;
              const firstSlash = nodePath.indexOf("/");
              const lastSlash = nodePath.lastIndexOf("/");
              const pathwayName = nodePath.substring(firstSlash + 1, lastSlash);

              if (!secondSet.has(pathwayName) && data[pathwayName]?.published) {
                secondSet.add(pathwayName);
              }
            }
          }
        }
      }

      if (firstSet.size < 2) {
        firstSet.add(<NoResults key={"No Results By Pathway Title"} />);
      }

      if (enter && secondSet.size < 2) {
        secondSet.add(<NoResults key={"No Results By Node Content"} />);
      }

      if (!enter) {
        secondSet.add(
          <ListHeader
            text="Press Enter to Search by Node Content"
            key={"Press Enter to Search by Node Content"}
          />
        );
      }

      setPathways(Array.from(firstSet).concat(Array.from(secondSet)));
      setOpen(true);

      setTitleLoading(false);
      if (enter) {
        setSearching(false);
        setContentLoading(false);
      }
    } catch (ex: any) {
      console.log(ex.message);
    }
  };

  const debounced = useThrottledCallback(async () => {
    await onSearch(false);
  }, 500);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Menu.Item style={{ flexGrow: "1", maxWidth: isMobileView ? "90%" : 600 }}>
      <Form
        onSubmit={() => onSearch(true)}
        style={{ flexGrow: "1", height: "40px" }}
      >
        <Form.Input
          style={{ height: "40px" }}
          loading={contentLoading || titleLoading}
          icon={
            <Icon
              color="grey"
              name="search"
              link
              onClick={() => onSearch(true)}
            />
          }
          placeholder="Search pathways..."
          onChange={(e) => {
            if (searching) {
              return;
            }

            setSearchTerm(e.target.value);
            debounced();
          }}
          value={searchTerm}
        />
      </Form>
      {open && !searching && (
        <SearchPathwaysDropdown
          pathways={pathways}
          customRef={dropdownRef}
          onSelect={onSelect}
        />
      )}
    </Menu.Item>
  );
};

export default SearchPathways;
