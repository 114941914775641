import { Dispatch, useEffect, useState } from "react";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import { IDynamicNode, ISTarTBackBlock } from "../../../../../types/Nodes";
import { Divider, Grid, Header, Segment } from "semantic-ui-react";
import { PathwayState } from "features/hooks/navigatorReducer";
import useReferencePathways from "features/hooks/useReferencePathways";
import produce from "immer";
import NodeActionsEditor from "./NodeActionEditor";
import { EMPTY_LINK_TEXT } from "features/pathwaybuilder/utils/pathwayHelperV2";
interface IProps {
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  block: ISTarTBackBlock;
  nodes?: IDynamicNode[];
  pathwayState: Partial<PathwayState>;
}

interface IPathwayOptions {
  text: string;
  value: string;
}

const STarTBackWidgetEditor = ({
  dispatch,
  nodes,
  block,
  pathwayState,
}: IProps) => {
  const { referencePathways } = useReferencePathways();
  const [pathwayOptions, setOptionPathways] = useState<IPathwayOptions[]>([]);

  //Generate a list of pathways for the dropdown
  useEffect(() => {
    const pathways: IPathwayOptions[] = [];

    referencePathways.forEach((pathway) => {
      pathways.push({
        text: pathway.name,
        value: pathway.name,
      });
    });

    setOptionPathways(pathways);
  }, [referencePathways]);

  useEffect(() => {
    if (
      block.blockData.widgetRules.find(
        (widgetRule) => widgetRule.pathwayId === ""
      )
    ) {
      const blockData = produce(block.blockData, (draft) => {
        draft.widgetRules.map((widgetRule) => {
          if (widgetRule.pathwayId === "") {
            widgetRule.pathwayId = pathwayState.currentPathwayId ?? "";
          }
          return widgetRule;
        });
      });

      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData,
        },
      });
    }
  }, [block, dispatch, pathwayState]);

  const handleShowGraph = (): void => {
    if (Array.isArray(block.blockData.widgetRules)) {
      dispatch?.({
        type: "UPDATE_BLOCK",
        payload: {
          blockId: block.blockId,
          blockData: {
            ...block.blockData,
            showGraph: !block.blockData.showGraph,
          },
        },
      });
    }
  };

  const onLinkTypeChange = (
    isPathwayToPathwayLink: boolean,
    linkIndex: number
  ) => {
    const blockData = produce(block.blockData, (draft) => {
      draft.widgetRules[linkIndex].isPathwayToPathwayLink =
        isPathwayToPathwayLink;
      draft.widgetRules[linkIndex].pathwayId = isPathwayToPathwayLink
        ? pathwayOptions[0].value
        : pathwayState.currentPathwayId ?? "";
      draft.widgetRules[linkIndex].nodeId = isPathwayToPathwayLink
        ? ""
        : EMPTY_LINK_TEXT;
    });

    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData,
      },
    });
  };

  const onChangePathwayLink = (pathwayId: string, linkIndex: number) => {
    const blockData = produce(block.blockData, (draft) => {
      draft.widgetRules[linkIndex].pathwayId = pathwayId;
      draft.widgetRules[linkIndex].nodeId = "";
    });

    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData,
      },
    });
  };

  const onChangeNodeLink = (nodeId: string, linkIndex: number) => {
    const blockData = produce(block.blockData, (draft) => {
      draft.widgetRules[linkIndex].nodeId = nodeId;
    });

    dispatch?.({
      type: "UPDATE_BLOCK",
      payload: {
        blockId: block.blockId,
        blockData,
      },
    });
  };

  return (
    <Segment inverted color="grey">
      <Header inverted>STarTBack Tool</Header>
      <Divider />
      {/* Show Startback scoring system */}
      <Segment basic horizontal="true">
        <Grid columns={2}>
          <p style={{ color: "white" }}>Show Scoring System:</p>
          <input
            type="checkbox"
            style={{ width: "40px", height: "40px", accentColor: "#3C5FB4" }}
            onChange={() => handleShowGraph()}
            data-testid="startbackExpand"
            checked={block.blockData.showGraph}
          ></input>
        </Grid>
      </Segment>

      {
        <NodeActionsEditor
          locked={true}
          nodes={nodes}
          nodeActions={block.blockData.widgetRules}
          dispatch={dispatch}
          onLinkTypeChange={onLinkTypeChange}
          pathwayOptions={pathwayOptions}
          onChangePathwayLink={onChangePathwayLink}
          onChangeNodeLink={onChangeNodeLink}
          currentPathway={pathwayState.currentPathwayId ?? ""}
        />
      }
    </Segment>
  );
};

export default STarTBackWidgetEditor;
