import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, Dispatch, useEffect, useState } from "react";
import { IDisplayVariablesBlock, IDynamicNode } from "../../../../types/Nodes";
import { convertNodeContextToJsonData } from "../NodeEditorUtils";
import { DynamicNodeEditorActions } from "../dynamicNodeEditorReducer";
import { PathwayState } from "features/hooks/navigatorReducer";

const NodeDisplayVariablesViewer = ({
  block,
  pathwayState,
}: {
  block: IDisplayVariablesBlock;
  pathwayState: Partial<PathwayState>;
}) => {
  const [totalScoreVariable, setTotalScoreVariable] = useState<number>(0);

  useEffect(() => {
    const getTotalScoreVariable = () => {
      if (pathwayState.nodeContext) {
        const nodeContextFields = convertNodeContextToJsonData(
          pathwayState.nodeContext
        );

        const fieldVariable = Object.entries(nodeContextFields).find(
          (field) => field[0] === block.blockData.variableBlockId
        )?.[1];

        if (fieldVariable) {
          setTotalScoreVariable(+fieldVariable.score);
        }
      }
    };
    getTotalScoreVariable();
  }, [block, pathwayState.nodeContext]);

  return (
    <Box
      sx={{
        border: "2px solid #c4c4c4",
        borderRadius: "8px",
        minHeight: "20px",
        p: 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      {(block.blockData.text === "" ||
        block.blockData.variableBlockId === "") && (
        <Typography>
          This is a Variables block. Select the Edit button for this block to
          edit its contained variables.
        </Typography>
      )}
      {block.blockData.text !== "" &&
        block.blockData.variableBlockId !== "" && (
          <>
            <Typography
              sx={{ mr: 1, wordBreak: "break-word", whiteSpace: "pre-line" }}
            >{`${block.blockData.text} ${
              block.blockData.text !== "" ? ":" : ""
            }`}</Typography>
            {block.blockData.text !== "" && <Chip label={totalScoreVariable} />}
          </>
        )}
    </Box>
  );
};

const NodeDisplayVariablesEditor = ({
  block,
  pathwayState,
  dispatch,
  isEditing,
  state,
}: {
  block: IDisplayVariablesBlock;
  pathwayState: Partial<PathwayState>;
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  isEditing: boolean;
  state: IDynamicNode;
}) => {
  return (
    <>
      {isEditing && (
        <Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            label="Display variable text..."
            variant="outlined"
            value={block.blockData.text}
            onChange={(
              e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              block &&
              dispatch?.({
                type: "UPDATE_BLOCK",
                payload: {
                  blockId: block.blockId,
                  blockData: {
                    ...block.blockData,
                    text: e.target.value,
                  },
                },
              })
            }
          />
          <Typography variant="h4" sx={{ mx: 2 }}>
            :
          </Typography>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label">
              Select Variable...
            </InputLabel>
            <Select
              autoWidth
              value={block.blockData.variableBlockId}
              label="Select Variable..."
              onChange={(e: SelectChangeEvent<any>) =>
                block &&
                dispatch?.({
                  type: "UPDATE_BLOCK",
                  payload: {
                    blockId: block.blockId,
                    blockData: {
                      ...block.blockData,
                      variableBlockId: e.target.value,
                    },
                  },
                })
              }
            >
              <MenuItem value={"node"}>node.score</MenuItem>
              {Object.values(state.blocks)
                .filter((block) => block.blockType === "choices")
                .map((block, index) => (
                  <MenuItem key={index} value={block.blockId}>
                    {block.blockName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {!isEditing && (
        <NodeDisplayVariablesViewer
          block={block as IDisplayVariablesBlock}
          pathwayState={pathwayState}
        />
      )}
    </>
  );
};

export default NodeDisplayVariablesEditor;
