import { Box } from "@mui/material";
import { PathwayActions, PathwayState } from "features/hooks/navigatorReducer";
import { DynamicNodeEditorActions } from "features/NodeEditorV2/dynamicNodeEditorReducer";
import { Dispatch, useEffect } from "react";
import { IDynamicNode, ISTarTBackBlock } from "../../../../../types/Nodes";
import STarTBackEditor from "./STarTBackEditor";
import STarTBackViewer from "./STarTBackViewer";
import { STarTBackWidgetInfos } from "./STarTBackWidgetUtils";

const STarTBackBlock = ({
  isEditing,
  nodes,
  dispatch,
  block,
  pathwayEditorDispatch,
  pathwayState,
  navigationBlocked,
}: {
  isEditing: boolean;
  nodes?: IDynamicNode[];
  dispatch?: Dispatch<DynamicNodeEditorActions>;
  block: ISTarTBackBlock;
  pathwayEditorDispatch: Dispatch<PathwayActions>;
  pathwayState: Partial<PathwayState>;
  navigationBlocked: {
    isDisabled: boolean;
    blockRequiredUntoggled: string[];
  };
}) => {
  useEffect(() => {
    if (
      !pathwayState.nodeContext?.fields.find(
        (field) => field.blockId === block.blockId
      )
    ) {
      STarTBackWidgetInfos.logicFields.forEach((field, index) => {
        field.options.forEach((option) => {
          pathwayEditorDispatch({
            type: "NODE_BLOCK_LOGIC_ON",
            payload: {
              blockId: block.blockId,
              blockFieldId: `${field.logicId}${block.blockId}`,
              blockName: block.blockName,
              fieldName: field.questionLogic,
              valueName: option.text,
              groupName: "STarTBack" || "",
              value: option.value,
              valueId: `${option.valueId}${block.blockId}`,
              addToDecisionSummary: block.blockOptions.addToDecisionSummary,
              addToManagementPlan: block.blockOptions.addToManagementPlan,
              addToNodeScore: block.blockOptions.addToNodeScore,
              isToggled: false,
              hasMultipleValues: true,
              namespace: "",
              name: "",
              order: index,
            },
          });
        });
      });
    }
  }, [pathwayEditorDispatch, pathwayState.nodeContext?.fields, block]);

  return (
    <Box>
      {isEditing && (
        <STarTBackEditor
          block={block}
          dispatch={dispatch}
          nodes={nodes}
          pathwayState={pathwayState}
        />
      )}
      {!isEditing && pathwayState.nodeContext && (
        <STarTBackViewer
          block={block}
          nodeContext={pathwayState.nodeContext}
          dispatch={pathwayEditorDispatch}
          pathwayState={pathwayState}
          navigationBlocked={navigationBlocked}
        />
      )}
    </Box>
  );
};

export default STarTBackBlock;
