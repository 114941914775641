const Diff = require("diff");

type ChangeObject = {
  added: boolean;
  value: string;
  removed: boolean;
};

const highlightRed = (part: ChangeObject): string => {
  const span = document.createElement("span");
  span.style.backgroundColor = "crimson";
  span.style.textDecoration = "line-through";
  span.textContent = part.value;

  return span.outerHTML;
};

const highlightGreen = (part: ChangeObject) => {
  const span = document.createElement("span");
  span.style.backgroundColor = "limegreen";
  span.textContent = part.value;
  return span.outerHTML;
};

const highlightString = (
  previousString: string,
  updatedString: string,
  isDeletions: boolean,
  isInline: boolean
): string => {
  const diff = Diff.diffWords(previousString, updatedString);
  let result = "";
  // console.log(diff);
  diff.forEach((part: ChangeObject) => {
    if (part.added && isInline) {
      result += highlightGreen(part);
      return;
    }

    if (part.removed && isInline) {
      result += highlightRed(part);
      return;
    }

    if (part.added && isDeletions) {
      return;
    }

    if (part.added) {
      result += highlightGreen(part);
      return;
    }

    if (part.removed && isDeletions) {
      result += highlightRed(part);
      return;
    }

    if (part.removed) {
      return;
    }

    const span = document.createElement("span");
    span.textContent = part.value;
    result += span.outerHTML;
  });

  return result;
};

export default highlightString;
