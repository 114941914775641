import SafeHtml from "app/components/SafeHtml";
import { List } from "semantic-ui-react";
import { IFilePointer } from "../../../../types/Nodes";

const NodeFilesPreview = ({
  filePointers,
}: {
  filePointers: IFilePointer[];
}) => {
  return (
    <List>
      {filePointers.map((filePointer, index) => (
        <List.Item
          key={index}
          style={{ display: "flex", alignItems: "center" }}
        >
          <svg
            style={{ marginRight: 4 }}
            width="25"
            height="25"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.1999 11.9H22.9499C21.544 11.9 20.3999 10.7559 20.3999 9.35V3.4H6.79993V30.6H27.1999V11.9Z"
              fill="white"
            />
            <path
              d="M27.2 10.2L22.1 4.6019V9.35C22.1 9.8175 22.4825 10.2 22.95 10.2H27.2Z"
              fill="white"
            />
            <path
              opacity="0.6"
              d="M28.4019 8.9981L22.1 2.1981C21.7812 1.87927 21.3489 1.7001 20.8981 1.7H6.79998C6.34911 1.7 5.91671 1.87911 5.59789 2.19792C5.27908 2.51673 5.09998 2.94913 5.09998 3.4V30.6C5.09998 31.0509 5.27908 31.4833 5.59789 31.8021C5.91671 32.1209 6.34911 32.3 6.79998 32.3H27.2C27.6508 32.3 28.0832 32.1209 28.4021 31.8021C28.7209 31.4833 28.9 31.0509 28.9 30.6V10.2C28.8999 9.74917 28.7207 9.31684 28.4019 8.9981V8.9981ZM27.2 10.2H22.95C22.7247 10.1996 22.5087 10.1099 22.3494 9.95054C22.1901 9.79124 22.1004 9.5753 22.1 9.35V4.6019L27.2 10.2ZM6.79998 30.26V3.74C6.79998 3.553 6.95298 3.4 7.13998 3.4H20.4V9.35C20.4 10.0263 20.6686 10.6749 21.1469 11.1531C21.6251 11.6313 22.2737 11.9 22.95 11.9H27.2V30.26C27.2 30.3502 27.1642 30.4367 27.1004 30.5004C27.0366 30.5642 26.9501 30.6 26.86 30.6H7.13998C7.0498 30.6 6.96332 30.5642 6.89956 30.5004C6.8358 30.4367 6.79998 30.3502 6.79998 30.26V30.26Z"
              fill="#767676"
            />
            <path
              opacity="0.6"
              d="M13.7452 25.5H9.2548C8.84 25.5 8.5 25.1192 8.5 24.65C8.5 24.1808 8.8383 23.8 9.2548 23.8H13.7435C14.1617 23.8 14.5 24.1808 14.5 24.65C14.5 25.1192 14.1617 25.5 13.7452 25.5ZM22.7452 22.1H9.2548C8.84 22.1 8.5 21.7192 8.5 21.25C8.5 20.7808 8.8383 20.4 9.2548 20.4H22.7435C23.1617 20.4 23.5 20.7808 23.5 21.25C23.5 21.7192 23.1617 22.1 22.7452 22.1ZM19.7452 18.7H9.2548C8.84 18.7 8.5 18.3192 8.5 17.85C8.5 17.3808 8.8383 17 9.2548 17H19.7435C20.1617 17 20.5 17.3808 20.5 17.85C20.5 18.3192 20.1617 18.7 19.7452 18.7ZM24.7452 15.3H9.2548C8.84 15.3 8.5 14.9192 8.5 14.45C8.5 13.9808 8.8383 13.6 9.2548 13.6H24.7435C25.1617 13.6 25.5 13.9808 25.5 14.45C25.5 14.9192 25.1617 15.3 24.7452 15.3Z"
              fill="#01A29B"
            />
          </svg>
          <a
            href={filePointer.pointer}
            target="_blank"
            rel="noopener noreferrer"
            download
            style={{
              wordBreak: "break-word",
              whiteSpace: "pre-wrap",
            }}
          >
            <SafeHtml html={filePointer.pointerTitle ?? ""} />
          </a>
        </List.Item>
      ))}
    </List>
  );
};

export default NodeFilesPreview;
