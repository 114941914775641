/***************************************************************************/
// Hook to add incremental autoscrolling while dragging.
// Pass in the id of the element where scrolling needs to take place.
// Ensure the element has a scrollbar, not that the window has a scrollbar.
// Use like a normal hook: const {setIsDragging} = useAutoscroll("modal")
/***************************************************************************/

import { useCallback, useEffect, useRef, useState } from "react";

const useAutoscroll = (elementId: string) => {
  const [mouseCoords, setMouseCoords] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const autoScrollInterval = useRef<ReturnType<typeof setInterval>>();

  const element = document.getElementById(elementId);

  useEffect(() => {
    const handleWindowMouseMove = (event: any) => {
      setMouseCoords(event.clientY);
    };

    window.addEventListener("drag", handleWindowMouseMove);

    return () => {
      window.removeEventListener("drag", handleWindowMouseMove);
    };
  }, []);

  const executeScroll = useCallback(
    (scrollNum: number) => {
      if (element) {
        element.scrollBy(0, scrollNum);
      }
    },
    [element]
  );

  useEffect(() => {
    if (element) {
      let elementHeight = {
        top: element.getBoundingClientRect().top,
        bottom: element.getBoundingClientRect().bottom,
      };

      const isInsideBottomScrollBox =
        mouseCoords < elementHeight.bottom &&
        mouseCoords > elementHeight.bottom - 120;

      const isInsideTopScrollBox =
        mouseCoords > elementHeight.top &&
        mouseCoords < elementHeight.top + 120;

      if (isInsideBottomScrollBox && autoScrollInterval.current === undefined) {
        executeScroll(10);
        const intervalId = setInterval(() => executeScroll(10), 100);
        autoScrollInterval.current = intervalId;
      }

      if (isInsideTopScrollBox && autoScrollInterval.current === undefined) {
        executeScroll(-10);
        const intervalId = setInterval(() => executeScroll(-10), 100);
        autoScrollInterval.current = intervalId;
      }

      if (
        !isInsideBottomScrollBox &&
        !isInsideTopScrollBox &&
        autoScrollInterval.current
      ) {
        clearInterval(autoScrollInterval.current);
        autoScrollInterval.current = undefined;
      }

      if (isDragging === false && autoScrollInterval.current) {
        clearInterval(autoScrollInterval.current);
        autoScrollInterval.current = undefined;
      }
    }
  }, [element, mouseCoords, executeScroll, isDragging]);

  return { setIsDragging };
};

export default useAutoscroll;
