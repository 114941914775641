import {
  AnyAction,
  combineReducers,
  configureStore,
  createAction,
  Middleware,
} from "@reduxjs/toolkit";
import usersReducer from "./userSlice";
import modulesReducer from "./moduleSlice";
import tenantsReducer from "./tenantSlice";
import crossCoverReducer from "./crossCoverSlice";
import dynamicNodeEditorReducer from "./nodeEditorSlice";
import pathwayNavigationReducer from "./pathwayNavigationSlice";
import { reducer as toastrReducer } from "react-redux-toastr";

export const resetState = createAction("RESET_STATE");

export const resetStateMiddleware: Middleware =
  ({ getState }) =>
  (next) => {
    const initialState = getState();
    return (action) => {
      if (action.type === "RESET_STATE") {
        const actionWithInitialAppState = {
          ...action,
          payload: initialState,
        };

        return next(actionWithInitialAppState);
      }
      return next(action);
    };
  };

const combinedReducers = combineReducers({
  users: usersReducer,
  modules: modulesReducer,
  tenants: tenantsReducer,
  crossCover: crossCoverReducer,
  dynamicNodeEditor: dynamicNodeEditorReducer,
  pathwayNavigation: pathwayNavigationReducer,
  toastr: toastrReducer,
});

const proxyReducer = (
  state: ReturnType<typeof combinedReducers> | undefined,
  action: AnyAction
) => {
  if (action.type === "RESET_STATE") {
    return combinedReducers(action.payload, action);
  }

  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: proxyReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
