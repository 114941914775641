import { styled } from "@mui/material";

const ContentArea = styled("main")(() => ({
  gridArea: "content",
  display: "flex",
  alignItems: "stretch",
  flexGrow: 1,
}));

const Content = ({ children }: any) => {
  return <ContentArea>{children}</ContentArea>;
};

export default Content;
