import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { doc, setDoc } from "firebase/firestore";

import { IPublicProfile } from "../../../types/User";
import useDocument from "./useDocument";
import useUser from "./useUser";

const useUserPublicProfile = () => {
  const { profile } = useUser();

  const { document: publicProfile } = useDocument<IPublicProfile>(
    `users/${profile.uid}/public`,
    "profile"
  );

  const db = firebase.firestore();
  const publicProfileRef = doc(db, `users/${profile.uid}/public/profile`);

  const addUserSettings = async (
    name: string,
    initials: string,
    avatarColour: string | undefined
  ) => {
    await setDoc(
      publicProfileRef,
      {
        uid: profile.uid,
        email: profile.email,
        avatarColour: avatarColour ? avatarColour : "#BEBEBE",
        name: name.trim(),
        initials: initials.toLocaleUpperCase(),
      },
      { merge: true }
    );
  };

  const editName = async (name: string, initials: string) => {
    await setDoc(
      publicProfileRef,
      {
        name: name.trim(),
        initials: initials.toLocaleUpperCase(),
      },
      { merge: true }
    );
  };

  const editAvatarColour = async (avatarColour: string) => {
    await setDoc(
      publicProfileRef,
      {
        avatarColour: avatarColour,
      },
      { merge: true }
    );
  };

  return {
    publicProfile,
    addUserSettings,
    editName,
    editAvatarColour,
  };
};

export default useUserPublicProfile;
