import { CopyToClipboard } from "react-copy-to-clipboard";
import { toastr } from "react-redux-toastr";
import { Button, Icon } from "semantic-ui-react";
import { INode } from "../../../../types/Nodes";
import dateToString from "app/utils/dateToString";
import { Dispatch } from "react";
import {
  NodeDecisionAction,
  PathwayActions,
} from "features/hooks/navigatorReducer";

interface ICopyToClipboardViewProps {
  node: INode;
  //node: INewNode;
  tenantId: string;
  currentPathway: string;
  decisionSummary: NodeDecisionAction[];
  rawContent: string;
  isDisabled: boolean;
  dispatch: Dispatch<PathwayActions>;
}

const CopyToClipboardView = ({
  node,
  tenantId,
  currentPathway,
  decisionSummary,
  rawContent,
  isDisabled,
  dispatch,
}: ICopyToClipboardViewProps) => {
  let copy = "";

  const localDate = dateToString(new Date());

  const div = document.createElement("div");
  div.innerHTML = rawContent;
  const lineItems = div.innerText?.split(/\r?\n/) || [];
  const managementPlan = lineItems
    .filter((x) => x)
    .map((x) => `- ${x}`)
    .join("\n");

  const decisionSummaryContent = decisionSummary
    .filter((x) => x.header)
    .map((x) => `- ${x.header}`)
    .join("\n");

  copy = `CrossCover Export
      Date: ${localDate}
      Pathway: ${tenantId} ${currentPathway} Pathway
      Decision Summary:\n${decisionSummaryContent}
      Outcome: ${node.title}
      Management Plan:\n${managementPlan}`;

  return (
    <>
      {isDisabled && (
        <Button fluid color="blue">
          <Icon name="copy" />
          Copy Summary to Clipboard
        </Button>
      )}
      {!isDisabled && (
        <CopyToClipboard text={copy} options={{ format: "text/plain" }}>
          <Button
            onClick={() => {
              dispatch({
                type: "ADD_EPISODE_OUTCOME",
                payload: { outcome: "Copied Summary to Clipboard" },
              });
              toastr.success("Summary Copied to Clipboard", "");
            }}
            fluid
            color="blue"
          >
            <Icon name="copy" />
            Copy Summary to Clipboard
          </Button>
        </CopyToClipboard>
      )}
    </>
  );
};

export default CopyToClipboardView;
