import { Box, styled, TextField, Typography } from "@mui/material";
import LoadingButton from "app/components/LoadingButton";
import { BaseSyntheticEvent, useEffect, useState } from "react";

export interface IUserCredentials {
  username: string;
  password: string;
}
interface IUsernamePasswordForm {
  onLogin: (credential: IUserCredentials) => void;
  className?: string;
  isLoading: boolean;
  userEmail?: string;
  changeInProgress?: boolean;
  error: string;
  setError: (value: string) => void;
}
const UsernamePasswordForm = ({
  onLogin,
  className,
  isLoading,
  userEmail,
  changeInProgress,
  error,
  setError,
}: IUsernamePasswordForm) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (changeInProgress && userEmail) {
      setUsername(userEmail);
    }
  }, [changeInProgress, userEmail]);

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        "& .MuiTextField-root": { mb: 1 },
      }}
      className={className}
    >
      {changeInProgress ? (
        <Typography gutterBottom variant="h6" noWrap>
          {userEmail}
        </Typography>
      ) : (
        <TextField
          id="username"
          name="username"
          size="small"
          placeholder="Email Address"
          sx={{ bgcolor: "#fff" }}
          value={username}
          autoComplete="username"
          role="textbox"
          onChange={(e) => setUsername(e.target.value)}
        />
      )}

      <TextField
        id="password"
        name="password"
        placeholder="Password"
        size="small"
        type="password"
        sx={{ bgcolor: "#fff" }}
        value={password}
        autoComplete="current-password"
        onChange={(e) => {
          setPassword(e.target.value);
          setError("");
        }}
        onPaste={(e: BaseSyntheticEvent) => {
          setPassword(e.target.value);
          setError("");
        }}
        role="textbox"
        error={error !== ""}
        helperText={error}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onLogin({ username, password });
          }
        }}
      />
      <br />
      <LoadingButton
        variant="contained"
        id="login-button"
        sx={{
          marginTop: "8px",
          backgroundColor: "#C25100",
          "&:hover": { backgroundColor: "#A33900" },
        }}
        onClick={() => onLogin({ username, password })}
        isLoading={isLoading}
      >
        Login
      </LoadingButton>
    </Box>
  );
};

export default styled(UsernamePasswordForm)({});
