import {
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { ChangeEvent, Dispatch, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { getManagementPlanText } from "features/NodeEditorV2/NodeEditorUtils";
import SaveNewTemplateDialog from "../SaveNewTemplateDialog";
import {
  DynamicNodeEditorActions,
  DynamicNodeEditorState,
} from "features/NodeEditorV2/dynamicNodeEditorReducer";
import { isConnectedNode } from "features/pathwaybuilder/utils/pathwayHelperV2";

interface INodeEditorSectionHeaderProps {
  onSave: () => Promise<void>;
  dispatch: Dispatch<DynamicNodeEditorActions>;
  state: DynamicNodeEditorState;
  onEdit?: (block: undefined) => void;
  setViewType: (viewType: "edit" | "view") => void;
  viewType: "edit" | "view";
  hideSaveTemplate?: boolean;
  showPreviewEditToggle?: boolean;
  handleCreateTemplateToggled: (createTemplateChecked: boolean) => void;
  createTemplateChecked: boolean;
  selectedBlockIds: Set<string>;
}

const NodeEditorSectionHeader = ({
  onSave,
  dispatch,
  state,
  onEdit,
  setViewType,
  viewType,
  hideSaveTemplate = false,
  showPreviewEditToggle,
  handleCreateTemplateToggled,
  createTemplateChecked,
  selectedBlockIds,
}: INodeEditorSectionHeaderProps) => {
  const [saveTemplateTooltipOpen, setSaveTemplateTooltipOpen] = useState(false);
  const [saveNewTemplateDialog, setSaveNewTemplateDialogOpen] = useState(false);
  const handleOpenSaveTemplateTooltip = () => {
    if (selectedBlockIds.size === 0) {
      setSaveTemplateTooltipOpen(true);
    }
  };

  const handleCloseSaveTemplateTooltip = () => {
    setSaveTemplateTooltipOpen(false);
  };

  const onSwitchView = (newView: "edit" | "view") => {
    if (newView) {
      onEdit?.(undefined);
      setViewType(newView);
    }
  };

  const onCreateTemplateToggled = (event: ChangeEvent<HTMLInputElement>) => {
    handleCreateTemplateToggled(event.target.checked);
  };

  const onSaveTemplateClick = () => {
    setSaveNewTemplateDialogOpen(true);
  };

  const handleCloseSaveNewTemplateDialog = () => {
    setSaveNewTemplateDialogOpen(false);
  };

  const checkEndNodeLinkHasManagementPlan = () => {
    const connectedNode = isConnectedNode(state.node);

    if (
      connectedNode &&
      state.node.nodeProperties.isEndNodeFunctionalityEnabled
    ) {
      const managementPlan = getManagementPlanText(state.node.blocks);
      if (managementPlan.length > 0) {
        return true;
      }
      if (managementPlan.length === 0) {
        return false;
      }
    }
    // if no links, no need to check if there is a text area
    if (!connectedNode) {
      return true;
    }
  };

  return (
    <Box
      sx={{ backgroundColor: "white", position: "sticky", top: 0, zIndex: 2 }}
    >
      <Box
        sx={{
          height: "56px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
          position: "sticky",
          top: 0,
          zIndex: 1,
          borderBottom: "1px solid #C4C4C4",
        }}
      >
        <Box>
          {viewType === "edit" && (
            <FormControlLabel
              checked={state.node.nodeProperties.isEndNodeFunctionalityEnabled}
              disabled={!isConnectedNode(state.node)}
              onChange={() =>
                dispatch({
                  type: "UPDATE_NODE_PROPERTIES",
                  payload: {
                    ...state.node.nodeProperties,
                    isEndNodeFunctionalityEnabled:
                      !state.node.nodeProperties.isEndNodeFunctionalityEnabled,
                  },
                })
              }
              control={<Switch color="primary" />}
              label="End Node Functionality"
              labelPlacement="start"
            />
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          {!hideSaveTemplate && viewType === "edit" && (
            <>
              {createTemplateChecked && (
                <Tooltip
                  arrow
                  disableInteractive
                  onOpen={handleOpenSaveTemplateTooltip}
                  onClose={handleCloseSaveTemplateTooltip}
                  open={saveTemplateTooltipOpen}
                  title="Select the blocks you wish to save as a template"
                  placement="top"
                >
                  <span>
                    <Button
                      disableElevation
                      onClick={onSaveTemplateClick}
                      variant="contained"
                      startIcon={<SaveIcon />}
                      color="primary"
                      disabled={selectedBlockIds.size === 0}
                    >
                      Save Template
                    </Button>
                  </span>
                </Tooltip>
              )}
              <FormControlLabel
                disableTypography
                labelPlacement="start"
                label={
                  <Typography fontWeight={600}>Create Template</Typography>
                }
                control={
                  <Switch
                    value={createTemplateChecked}
                    onChange={onCreateTemplateToggled}
                  />
                }
              />
            </>
          )}
          {viewType === "edit" && (
            <Button
              onClick={() => onSave()}
              variant="contained"
              startIcon={<SaveIcon />}
              color="success"
              disabled={
                (checkEndNodeLinkHasManagementPlan() ||
                  !state.node.nodeProperties.isEndNodeFunctionalityEnabled) &&
                state.isDirty &&
                state.node.title.length > 0
                  ? false
                  : true
              }
            >
              Save Changes
            </Button>
          )}
          {showPreviewEditToggle && (
            <ButtonGroup
              size="small"
              aria-label="editPreviewNode"
              sx={{
                ml: 2,
              }}
            >
              <Button
                value="view"
                variant={viewType === "view" ? "contained" : "outlined"}
                onClick={() => onSwitchView("view")}
              >
                <VisibilityIcon sx={{ mr: 1 }} /> Preview
              </Button>

              <Button
                value="edit"
                variant={viewType === "edit" ? "contained" : "outlined"}
                onClick={() => onSwitchView("edit")}
              >
                <EditIcon sx={{ mr: 1 }} /> Edit
              </Button>
            </ButtonGroup>
          )}
        </Box>
      </Box>
      <SaveNewTemplateDialog
        open={saveNewTemplateDialog}
        onClose={handleCloseSaveNewTemplateDialog}
        dynamicNode={state.node}
        selectedBlockIds={selectedBlockIds}
      />
    </Box>
  );
};

export default NodeEditorSectionHeader;
