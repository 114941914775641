import { useHistory } from "react-router-dom";
import { Button, Popup, SemanticCOLORS } from "semantic-ui-react";
import { EditorViewType } from "./PathwayBuilderProvider";
import usePathwayBuilder from "./usePathwayBuilder";

export const IconType = ({
  color,
  type,
}: {
  color: SemanticCOLORS;
  type: string;
}) => {
  return (
    <>
      {type === "review" && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2347 19.5805C5.10802 19.5805 0.888916 15.3614 0.888916 10.2347C0.888916 5.10802 5.10802 0.888916 10.2347 0.888916C15.3614 0.888916 19.5805 5.10802 19.5805 10.2347C19.5795 12.2901 18.8961 14.287 17.6375 15.9119L22.754 21.0285C22.9825 21.2569 23.1111 21.5673 23.1111 21.8909C23.1111 22.5606 22.5606 23.1111 21.8909 23.1111C21.5673 23.1111 21.2569 22.9825 21.0285 22.754L15.9119 17.6375C14.287 18.8961 12.2901 19.5795 10.2347 19.5805ZM15.4308 14.9014C16.5881 13.6224 17.2299 11.9596 17.232 10.2347C17.232 6.39613 14.0733 3.23745 10.2347 3.23745C8.1279 3.23745 6.22615 4.18872 4.93882 5.6819H9.03628V7.59847H3.75909C3.56805 8.06116 3.42791 8.54327 3.34116 9.03628H8.078V10.9536H3.27488C3.34272 11.6125 3.50646 12.2581 3.75909 12.871H7.11972V14.7876H4.93882C6.22615 16.2807 8.1279 17.232 10.2347 17.232C12.0203 17.232 13.6593 16.5482 14.9014 15.4308C15.0191 15.204 15.204 15.0191 15.4308 14.9014ZM8.078 14.7876H13.8293V12.871H8.078V14.7876ZM12.871 10.9536H16.7049V9.03628H12.871V10.9536ZM9.03628 10.9536H11.4332V9.03628H9.03628V10.9536ZM10.4741 7.59847H14.3088V5.6819H10.4741V7.59847Z"
            fill={color}
          />
        </svg>
      )}
      {type === "tree" && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H6.66667V4.44444H0V0ZM13.3333 7.77778H20V12.2222H13.3333V7.77778ZM13.3333 15.5556H20V20H13.3333V15.5556ZM11.1111 11.1111H4.44444V16.6667H11.1111V18.8889H2.22222V6.66667H4.44444V8.88889H11.1111V11.1111Z"
            fill={color}
          />
        </svg>
      )}
      {type === "list" && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.2223 17.8889H6.88894V14.9259H25.2223V17.8889ZM3.83339 17.8889H0.777832V14.9259H3.83339V17.8889ZM25.2223 10.4815H6.88894V7.51849H25.2223V10.4815ZM3.83339 10.4815H0.777832V7.51849H3.83339V10.4815ZM25.2223 3.07405H6.92408V0.111084H25.2223V3.07405ZM3.83339 3.07405H0.777832V0.111084H3.83339V3.07405Z"
            fill={color}
          />
        </svg>
      )}
    </>
  );
};

const ViewTypeSwitch = ({
  isFromNode,
  pathwayId,
}: {
  isFromNode: boolean;
  pathwayId?: string;
}) => {
  const { state, dispatch } = usePathwayBuilder();
  const history = useHistory();

  const onNavigate = (destination: EditorViewType) => {
    if (isFromNode) {
      let destinationString = `/pathway-builder/${pathwayId}`;
      if (destination !== "TABLE") {
        destinationString += destination === "TREE" ? "/tree" : "/episode";
      }
      history.push(destinationString);
    }
    if (!isFromNode) {
      dispatch({
        type: "CHANGE_VIEW_TYPE",
        payload: { viewType: destination },
      });
    }
  };
  return (
    <Button.Group
      style={{
        outline: "1px solid #01A29B",
        borderRadius: "5px",
        marginLeft: "24px",
      }}
    >
      <Popup
        content="List view"
        trigger={
          <Button
            onClick={() => {
              onNavigate("TABLE");
            }}
            compact
            style={{
              borderRight: "1px solid #01A29B",
              backgroundColor:
                state.viewType === "TABLE" && !isFromNode
                  ? "#01A29B"
                  : "rgba(1, 162, 155, 0.04)",
              color: state.viewType === "TABLE" ? "#FFF" : "#01A29B",
            }}
          >
            <IconType
              color={
                (state.viewType === "TABLE" && !isFromNode
                  ? "#FFF"
                  : "#01A29B") as SemanticCOLORS
              }
              type="list"
            />
          </Button>
        }
      />
      <Popup
        content="Tree view"
        trigger={
          <Button
            onClick={() => {
              onNavigate("TREE");
            }}
            compact
            style={{
              borderRight: "1px solid #01A29B",
              backgroundColor:
                state.viewType === "TREE" && !isFromNode
                  ? "#01A29B"
                  : "rgba(1, 162, 155, 0.04)",
              color: state.viewType === "TREE" ? "#FFF" : "#01A29B",
            }}
          >
            <IconType
              color={
                (state.viewType === "TREE" && !isFromNode
                  ? "#FFF"
                  : "#01A29B") as SemanticCOLORS
              }
              type="tree"
            />
          </Button>
        }
      />
      {/* <Popup
        content="Episode view"
        trigger={
          <Button
            onClick={() => onNavigate("EPISODE")}
            compact
            style={{
              backgroundColor:
                state.viewType === "EPISODE" && !isFromNode
                  ? "#01A29B"
                  : "rgba(1, 162, 155, 0.04)",
            }}
          >
            <IconType
              color={
                (state.viewType === "EPISODE" && !isFromNode
                  ? "#FFF"
                  : "#01A29B") as SemanticCOLORS
              }
              type="review"
            />
          </Button>
        }
      /> */}
    </Button.Group>
  );
};

export default ViewTypeSwitch;
