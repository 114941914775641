import useUser from "features/hooks/useUser";

const SharedAccountGate = ({
  allowed,
  children,
}: {
  allowed: boolean;
  children: React.ReactNode;
}) => {
  const { isAuthorised, isSharedAccount } = useUser();
  let areWeShared = false;

  // Are we a shared account?
  areWeShared = isAuthorised("readonly") && isSharedAccount();

  if(areWeShared) {
    // process rules for shared accounts.
    // As a shared user are we allowed to see this?
    return allowed?<>{children}</>:<></>;
  }

  // Fall through and show if not allowed.
  // Sounds a little odd, but gets us the correct behaviour.  
  // Maybe this needs to be named better?
  return allowed?<></>:<>{children}</>;

};
export default SharedAccountGate;
