import { Grid, Table } from "semantic-ui-react";
import Dropzone from "react-dropzone";

import FileActions from "./FileActions";
import FolderActions from "./FolderActions";
import { IDocument, IFolder } from "../../../types/DocumentLibrary";
import {
  FilterCriteria,
  SortCriteria,
} from "./docLibTypes/DocumentLibraryTypes";
import {
  getDocTypeIcon,
  collator,
  getDirectChildren,
  getNestedLevel,
} from "./utils/DocumentLibraryHelper";
import DateTime from "app/components/DateTime";
import { SortIcon } from "../../app/components/SortIcon";
import { CSSProperties, useState } from "react";
import VersionHistory from "./VersionHistory";
import { DocumentTimestampToMilliseconds } from "app/utils/DocumentTimestampToMilliseconds";

interface DocumentListProps {
  documents: IDocument[];
  disableDrop: boolean;
  filterBy?: FilterCriteria;
  filterCriteria: FilterCriteria;
  folders: IFolder[];
  folderPath: string;
  isAscendingSort: boolean;
  sortCriteria: SortCriteria;
  style: CSSProperties | undefined;
  handleUpdateNodePreview?: (
    oldFileLink: string,
    newFileLink: unknown,
    fileTitle: string
  ) => void;
  displayFolderPath: (value: IFolder) => void;
  getDirectParent: () => void;
  handleDrop: (value?: IFolder) => Promise<void>;
  onSelect?: (value: IDocument) => void;
  removeFolderPath: () => void;
  setDraggedFile: (value?: IFolder | IDocument) => void;
  setSortCriteria: (value: SortCriteria) => void;
  setIsAscendingSort: (value: boolean) => void;
  setIsDragging?: (value: boolean) => void;
}

const DocumentList = ({
  documents,
  disableDrop,
  filterBy,
  filterCriteria,
  folders,
  folderPath,
  isAscendingSort,
  sortCriteria,
  style,
  handleUpdateNodePreview,
  displayFolderPath,
  getDirectParent,
  handleDrop,
  onSelect,
  removeFolderPath,
  setDraggedFile,
  setSortCriteria,
  setIsAscendingSort,
  setIsDragging,
}: DocumentListProps) => {
  const [vHistoryOpen, setVHistoryOpen] = useState<boolean>(false);

  const sortListFolders = (a: IFolder, b: IFolder) => {
    if (vHistoryOpen) {
      return 0;
    } else {
      if (isAscendingSort && sortCriteria === "updatedAt") {
        return (
          DocumentTimestampToMilliseconds(a.updatedAt) -
          DocumentTimestampToMilliseconds(b.updatedAt)
        );
      }
      if (!isAscendingSort && sortCriteria === "updatedAt") {
        return (
          DocumentTimestampToMilliseconds(b.updatedAt) -
          DocumentTimestampToMilliseconds(a.updatedAt)
        );
      }
      if (!isAscendingSort && sortCriteria === "fileTitle") {
        return collator.compare(b.folderTitle, a.folderTitle);
      } else {
        return collator.compare(a.folderTitle, b.folderTitle);
      }
    }
  };

  const sortListFiles = (a: IDocument, b: IDocument) => {
    if (vHistoryOpen) {
      return 0;
    } else {
      switch (isAscendingSort) {
        case true:
          return collator.compare(
            a[sortCriteria] as string,
            b[sortCriteria] as string
          );
        default:
          return collator.compare(
            b[sortCriteria] as string,
            a[sortCriteria] as string
          );
      }
    }
  };

  const filterFiles = (document: IDocument) => {
    if (filterCriteria !== "ALL-FILES") {
      return (
        document.documentType === filterCriteria &&
        folderPath === document.folderPath
      );
    }
    if (filterCriteria === "ALL-FILES") {
      return true && folderPath === document.folderPath;
    }
  };

  const getFilteredChildren = (folders: IFolder[], folderPath: string) => {
    const allFolders = getDirectChildren(folders, folderPath);

    switch (filterCriteria) {
      case "ALL-FILES":
        return allFolders;

      default:
        const filteredDocs = documents.filter(
          (file: any) => file.documentType === filterCriteria
        );
        const filteredDocPath = filteredDocs.map((doc: IDocument) => {
          return getNestedLevel(doc, folderPath);
        });
        return allFolders.filter((folder: IFolder) =>
          filteredDocPath.includes(getNestedLevel(folder, folderPath))
        );
    }
  };

  return (
    <>
      <Grid style={{ marginBottom: 60 }}>
        <Grid.Row centered>
          <Grid.Column>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Type
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Title
                    <SortIcon
                      id="fileTitle"
                      setSortCriteria={setSortCriteria}
                      setIsAscendingSort={setIsAscendingSort}
                      sortCriteria={sortCriteria}
                      isAscendingSort={isAscendingSort}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    File Name
                    <SortIcon
                      id="fileName"
                      setSortCriteria={setSortCriteria}
                      setIsAscendingSort={setIsAscendingSort}
                      sortCriteria={sortCriteria}
                      isAscendingSort={isAscendingSort}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Last Modified
                    <SortIcon
                      id="updatedAt"
                      setSortCriteria={setSortCriteria}
                      setIsAscendingSort={setIsAscendingSort}
                      sortCriteria={sortCriteria}
                      isAscendingSort={isAscendingSort}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>Modified By</Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Version
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: "center" }}>
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {folderPath.length > 1 && (
                  <Table.Row>
                    <Table.Cell style={{ textAlign: "center" }}>
                      <Dropzone
                        disabled={disableDrop}
                        noClick={true}
                        onDrop={() => handleDrop()}
                      >
                        {({ getRootProps }) => (
                          <img
                            draggable="false"
                            className="doc-type-icon-list-sizing"
                            alt="parent-folder"
                            {...getRootProps({ style })}
                            onClick={() => {
                              getDirectParent();
                              removeFolderPath();
                            }}
                            src={"/assets/documentLibrary/folder.svg"}
                          />
                        )}
                      </Dropzone>
                    </Table.Cell>
                    <Table.Cell>Parent Folder</Table.Cell>
                  </Table.Row>
                )}

                {getFilteredChildren(folders, folderPath)
                  .sort(sortListFolders)
                  .map((folder: IFolder, index: number) => (
                    <Table.Row
                      key={index}
                      onDragStart={() => {
                        setDraggedFile(folder);
                        setIsDragging?.(true);
                      }}
                      onDragEnd={() => {
                        setDraggedFile(undefined);
                        setIsDragging?.(false);
                      }}
                    >
                      <Table.Cell style={{ textAlign: "center" }}>
                        <Dropzone
                          disabled={disableDrop}
                          noClick={true}
                          onDrop={() => handleDrop(folder)}
                        >
                          {({ getRootProps }) => (
                            <img
                              className="doc-type-icon-list-sizing"
                              alt="folder"
                              {...getRootProps({ style })}
                              onClick={() => displayFolderPath(folder)}
                              src={getDocTypeIcon(folder.documentType)}
                            />
                          )}
                        </Dropzone>
                      </Table.Cell>
                      <Table.Cell
                        style={{ cursor: "pointer" }}
                        onClick={() => displayFolderPath(folder)}
                      >
                        {folder.folderTitle}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>
                        <DateTime value={folder.updatedAt} as={"datetime"} />
                      </Table.Cell>
                      <Table.Cell>{folder.updatedBy}</Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign="center">
                        <FolderActions
                          folder={folder}
                          folders={folders}
                          folderPath={folderPath}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}

                {documents
                  .sort(sortListFiles)
                  .filter(filterFiles)
                  .map((document: IDocument, index: number) => (
                    <Table.Row key={index}>
                      {!Boolean(document.updatedAt)}
                      <Table.Cell
                        style={{ textAlign: "center", cursor: "pointer" }}
                        onDragStart={() => {
                          setDraggedFile(document);
                          setIsDragging?.(true);
                        }}
                        onDragEnd={() => {
                          setDraggedFile(undefined);
                          setIsDragging?.(false);
                        }}
                      >
                        <img
                          className="doc-type-icon-list-sizing"
                          alt="document-type"
                          src={getDocTypeIcon(document.documentType)}
                        />
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {document.fileTitle}
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {document.fileName}
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime value={document.updatedAt} as={"datetime"} />
                      </Table.Cell>
                      <Table.Cell>{document.updatedBy}</Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {document.versionHistory ? (
                          <VersionHistory
                            document={document}
                            setSortCriteria={setSortCriteria}
                            setIsAscendingSort={setIsAscendingSort}
                            setVHistoryOpen={setVHistoryOpen}
                            sortCriteria={sortCriteria}
                            isAscendingSort={isAscendingSort}
                          />
                        ) : (
                          "1"
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <FileActions
                          document={document}
                          documents={documents}
                          filterBy={filterBy}
                          folderPath={folderPath}
                          onSelect={onSelect}
                          handleUpdateNodePreview={handleUpdateNodePreview}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default DocumentList;
