import { useTheme } from "@mui/material";
import React from "react";
import { List } from "semantic-ui-react";
import SearchPathwaysListItem from "./SearchPathwaysListItem";

const SearchPathwaysDropdown = ({
  pathways,
  customRef,
  onSelect,
}: {
  pathways: Array<string | JSX.Element>;
  customRef: any;
  onSelect: (pathwayId: string) => void;
}) => {
  const theme = useTheme();

  return (
    <div
      ref={customRef}
      style={{
        position: "absolute",
        top: "56px",
        backgroundColor: "white",
        color: "black",
        borderRadius: "3px",
        overflow: "hidden",
        outline: "1px solid rgba(34,36,38,.15)",
        zIndex: 1800,
        width: theme.isMobileView ? "65%" : 600,
      }}
    >
      {pathways.length > 0 ? (
        <div
          style={{
            maxHeight: "300px",
            overflow: "scroll",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <List
            selection
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              height: "100%",
            }}
          >
            {pathways.map((pathway, index) =>
              React.isValidElement(pathway) ? (
                pathway
              ) : (
                <SearchPathwaysListItem
                  key={index}
                  pathwayId={pathway as string}
                  onSelect={onSelect}
                />
              )
            )}
          </List>
        </div>
      ) : (
        <p
          style={{
            color: "black",
            padding: "10px 15px",
          }}
        >
          No Results
        </p>
      )}
    </div>
  );
};

export default SearchPathwaysDropdown;
