import { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import {
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from "firebase/auth";

import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Form } from "semantic-ui-react";

import VerificationCodeForm from "./VerificationCodeForm";
import { MFAFactors } from "../../../../types/User";

interface IRegisterPhoneNumber {
  onPhoneRegistrationComplete?: ({
    verificationId,
    phoneNumber,
  }: {
    verificationId: string;
    phoneNumber: string;
  }) => void;
  onRegistrationComplete: (factor: MFAFactors) => void;
  setRegistrationType: (value: MFAFactors) => void;
  enableMFALater?: () => void;
  setRecentLogin?: (value: boolean) => void;
}

const auth = getAuth();

const RegisterPhoneNumber = ({
  enableMFALater,
  setRecentLogin,
  onRegistrationComplete,
  setRegistrationType,
}: IRegisterPhoneNumber) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isPhoneNumberValid, setPhoneNumberValid] = useState<boolean>(false);
  const [verificationId, setVerificationId] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      "registerphonenumber",
      {
        size: "invisible",
      },
      auth
    );
  }, []);

  useEffect(() => {
    setPhoneNumberValid(isValidPhoneNumber(phoneNumber || "", "GB"));
  }, [phoneNumber]);

  const onRegisterPhoneNumber = async () => {
    if (auth.currentUser === null) {
      throw Error("Invalid user session");
    }

    const multiFactorSession = await multiFactor(auth.currentUser).getSession();
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      { phoneNumber: phoneNumber, session: multiFactorSession },
      (window as any).recaptchaVerifier
    );

    setVerificationId(verificationId);
  };

  /*
if (ex.code === AuthErrorCodes.MFA_REQUIRED) {
        const resolver = getMultiFactorResolver(auth, ex);
        setResolver(resolver);

        if (
          resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID
        ) {
          var phoneAuthProvider = new PhoneAuthProvider(auth);
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            },
            (window as any).recaptchaVerifier
          );
          setVerificationId(verificationId);
        }
  */
  const onVerifyOTPCode = async ({
    verificationCode,
  }: {
    verificationCode: string;
  }) => {
    if (auth.currentUser === null) {
      throw Error("Invalid user session");
    }
    setError("");
    try {
      const phoneAuthCredential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion);
      onRegistrationComplete("PHONE");
      // if (setActiveStep && error === "") {
      //   setActiveStep && setActiveStep((prev) => prev + 1); // if activating account, move onto the next step
      // }
    } catch (ex: any) {
      setError("You have entered an incorrect OTP");
    }
  };

  const onReset = () => {
    setRecentLogin && setRecentLogin(false);
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid green",
          borderColor: "primary.main",
          borderRadius: 1,
          p: 2,

          maxWidth: "400px",
        }}
      >
        {!verificationId && (
          <>
            <Typography
              variant="h5"
              component="h2"
              align="center"
              sx={{ fontWeight: 700, marginBottom: 2 }}
            >
              SMS Authentication
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="h6" component="h3" sx={{ fontWeight: 700 }}>
                Mobile Phone number
              </Typography>

              {error && (
                <>
                  <Alert severity="error">{error}</Alert>
                </>
              )}

              <Form>
                <Form.Field>
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="GB"
                    placeholder="Enter phone number"
                    value={phoneNumber as any}
                    onChange={(value: any) => setPhoneNumber(value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.key === "Enter" && isPhoneNumberValid) {
                        e.preventDefault();
                        onRegisterPhoneNumber();
                      }
                    }}
                  />
                </Form.Field>
              </Form>

              <Alert severity="info" icon={false}>
                <AlertTitle>Phone Number Requirements</AlertTitle>

                {isPhoneNumberValid
                  ? "✅ correct number of digits"
                  : "❌ correct number of digits"}
              </Alert>

              <Box>
                <Button
                  type="submit"
                  disabled={!isPhoneNumberValid}
                  variant="contained"
                  id="registerphonenumber"
                  onClick={onRegisterPhoneNumber}
                >
                  Submit Phone Number for verification
                </Button>
              </Box>
            </Box>
          </>
        )}

        {verificationId && (
          <VerificationCodeForm
            error={error}
            setError={setError}
            isLoginForm={false}
            phoneNumberHint={phoneNumber}
            onMFALogin={onVerifyOTPCode}
            onReset={onReset}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={enableMFALater}>I will enable later</Button>
      </Box>
    </>
  );
};

export default RegisterPhoneNumber;
