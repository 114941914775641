// import { getDb } from "app/data/DbProvider";
import { createContext, Dispatch } from "react";
import { useImmerReducer } from "use-immer";
import { ICrossCoverPatient } from "../../../functions/src/http/nhsdigital/pds";
import { IPatientCreateRequest } from "../../../functions/src/http/patientIntergration/patientCreate";
import functions from "../../app/common/util/functions";
//import { oauth2 as SMART } from "fhirclient";
//import Client from "fhirclient/lib/Client";

export type PatientContextState = {
  patient?: ICrossCoverPatient;
  fhirClient?: any;
};

type PatientContextType = {
  state: PatientContextState;
  dispatch: Dispatch<PatientContextActions>;
  patientCreate: (request: IPatientCreateRequest) => void;
  patientSearch: (searchRequest: IPDSSearchRequest) => void;
  isPatientLoaded: boolean;
  fhirClient?: Client;
};

export type PatientContextActions =
  | {
      type: "PATIENT_LINK";
      payload: { patient: ICrossCoverPatient };
    }
  | { type: "SET_LOOKUP_ERROR"; payload: { message: string } }
  | { type: "PATIENT_UNLINK"; payload: {} }
  | {
      type: "SET_FHIRCLIENT";
      payload: { fhirClient: any };
    };

const emptyContext: PatientContextState = {};

const patientProviderReducer = (
  draft: PatientContextState,
  action: PatientContextActions
) => {
  switch (action.type) {
    case "PATIENT_LINK":
      draft.patient = action.payload.patient;
      break;
    case "PATIENT_UNLINK":
      draft.patient = undefined;
      break;
    case "SET_FHIRCLIENT":
      draft.fhirClient = action.payload.fhirClient;
      break;
    default:
      console.error("Unknown patient reducer action", action);
    //throw Error("Unknown patient reducer action");
  }
};

export const PatientContext = createContext<PatientContextType>({
  state: emptyContext,
  dispatch: () => null,
  patientCreate: (request: IPatientCreateRequest) => {},
  patientSearch: (searchRequest: IPDSSearchRequest) => {},
  isPatientLoaded: false,
});

export interface IPDSSearchRequest {
  nhsNumber?: string;
  dateOfBirth?: string;
  givenName?: string;
  familyName?: string;
  gender?: string;
}

const PatientProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useImmerReducer<
    PatientContextState,
    PatientContextActions
  >(patientProviderReducer, {
    ...emptyContext,
  });
  // const db = getDb();

  const patientSearch = async (request: IPDSSearchRequest) => {
    if (request.nhsNumber) {
      console.log("Searhcing", request.nhsNumber);
      const lookupResult = await functions.pdsLookup({
        NHSNumber: request.nhsNumber,
      });
      console.log(lookupResult);
      if (lookupResult.resultType === "VALIDATION_ERROR") {
        // setError(lookupResult.message);
        dispatch({
          type: "SET_LOOKUP_ERROR",
          payload: { message: lookupResult.message },
        });
      }

      if (lookupResult.patientData === undefined) {
        dispatch({
          type: "SET_LOOKUP_ERROR",
          payload: { message: "Patient not found" },
        });
        return;
      }

      if (lookupResult.resultType === "OK") {
        /// setPatient(lookupResult.patientData);
        dispatch({
          type: "PATIENT_LINK",
          payload: { patient: lookupResult.patientData },
        });
      }
    }
  };

  const patientCreate = async (request: IPatientCreateRequest) => {
    //const result = functions.createPatient(request);
  };

  // useEffect(() => {
  //     SMART.ready().then(
  //       async (client) => {
  //         console.log(
  //           client.getAuthorizationHeader(),
  //           client.getIdToken(),
  //           client.user.fhirUser, // A string lik "Practitioner/abc" or null
  //           client.getFhirUser(), // Getter for the above
  //           client.user.id, // A string like "abc" or null
  //           client.getUserId(), // Getter for the above
  //           client.user.resourceType, // String like "Practitioner", "Patient" etc, or null
  //           client.getUserType(), // Getter for the above,
  //           client.getPatientId()
  //         ); // rejects if there is no user
  //         console.log(await client.state);

  //         // Real example:
  //         if (client.user.id) {
  //           try {
  //             const userResource = await client.user.read();
  //             console.log(userResource);
  //           } catch (ex) {
  //             console.log("Failed to get Practitioner");
  //           }
  //           try {
  //             console.log(await client.patient.read());
  //           } catch {
  //             console.log("Failed to patient");
  //           }
  //         } else {
  //           console.log("no user");
  //         }

  //         dispatch({ type: "SET_FHIRCLIENT", payload: { fhirClient: client } });
  //       },
  //       (error) => console.log(error)
  //     );
  //   }, [dispatch]);
  return (
    <PatientContext.Provider
      value={{
        state,
        dispatch,
        patientSearch,
        patientCreate,
        isPatientLoaded: state.patient !== undefined,
        fhirClient: state.fhirClient,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
export default PatientProvider;
