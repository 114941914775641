import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useMemo, useState } from "react";
import NodeEditor from "../NodeEditorV2";
import { DynamicNodeEditorActions } from "../dynamicNodeEditorReducer";
import { IDynamicNode, NodeBlock } from "../../../../types/Nodes";
import { PathwayActions } from "features/hooks/navigatorReducer";
import { Button, CircularProgress, useTheme } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { getDb } from "app/data/DbProvider";
import useUser from "features/hooks/useUser";
import { IWidgetTemplate } from "../../../../types/WidgetTemplates";
import { toastr } from "react-redux-toastr";

interface ISaveNewTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  dynamicNode: IDynamicNode;
  selectedBlockIds: Set<string>;
}

const SaveNewTemplateDialog = ({
  open,
  onClose,
  dynamicNode,
  selectedBlockIds,
}: ISaveNewTemplateDialogProps) => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const trimmedTitle = useMemo(() => title.trim(), [title]);
  const theme = useTheme();
  const { activeTenantId, profile } = useUser();
  const validTitle = trimmedTitle.length <= 38 && trimmedTitle.length !== 0;

  const newDynamicNode = useMemo(() => {
    const selectedBlocks: Record<string, NodeBlock> = Object.fromEntries(
      Object.entries(dynamicNode.blocks).filter(([blockId]) =>
        selectedBlockIds.has(blockId)
      )
    );
    const newDynamicNode: IDynamicNode = {
      ...dynamicNode,
      blocks: selectedBlocks,
    };
    return newDynamicNode;
  }, [dynamicNode, selectedBlockIds]);

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleClose = () => {
    setTitle("");
    setLoading(false);
    onClose();
  };

  const handleSaveTemplate = async () => {
    if (!validTitle) {
      return;
    }

    try {
      setLoading(true);

      const db = getDb();
      const collectionPath = `${activeTenantId}/templates/userDefinedWidgetTemplates`;

      // Collapse unused row positions between blocks
      // Generate a map of row numbers to block ids
      const rowsOfBlocks = new Map<number, string[]>();
      Object.entries(newDynamicNode.blocks).forEach(([blockId, block]) => {
        const rowNumber = block.position.row;
        const existingBlockIds = rowsOfBlocks.get(rowNumber);
        rowsOfBlocks.set(
          rowNumber,
          existingBlockIds ? [...existingBlockIds, blockId] : [blockId]
        );
      });
      console.log("rowOfBlocks", rowsOfBlocks);
      // Sort the entries in the map according to their row numbers
      // Update row numbers of the blocks in each row to be equal to the entries index in the map

      // Save the new user defined widget template
      const newTemplate: IWidgetTemplate = {
        id: "",
        title: trimmedTitle,
        blocks: newDynamicNode.blocks,
        createdAt: 0,
        createdBy: "",
        updatedAt: 0,
        updatedBy: "",
      };
      const templateId = await db.addDocument<IWidgetTemplate>(
        collectionPath,
        newTemplate,
        profile.uid
      );

      // Update the id property of the widget template
      const partialTemplate: Partial<IWidgetTemplate> = {
        id: templateId,
      };
      await db.updateDocumentById<IWidgetTemplate>(
        collectionPath,
        templateId,
        partialTemplate,
        profile.uid,
        true
      );

      toastr.success("Success", "New widget template saved");
    } catch (error: any) {
      toastr.error("Error", "Error saving widget template");
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Save New Template</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Alert severity="info">
          <AlertTitle>
            Template Title is what you and others will see in the Templates list
            in the node editor. Try to give it a descriptive and meaningful name
            so that the purpose of this template is clear.
          </AlertTitle>
        </Alert>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Typography variant="subtitle1" fontWeight={700}>
            Template Title
          </Typography>
          <TextField
            label="Enter template title..."
            size="small"
            fullWidth
            value={title}
            onChange={handleTitleChange}
            error={!validTitle}
            helperText={`Characters: ${trimmedTitle.length}/38`}
            sx={{ fontSize: "14px" }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Typography variant="subtitle1" fontWeight={700}>
            Template Preview
          </Typography>
          <Box
            sx={{
              border: `1px solid ${theme.palette.secondary.light}`,
              borderRadius: "4px",
              p: 1,
              maxHeight: "500px",
              overflow: "auto",
            }}
          >
            <NodeEditor
              editorState={{
                node: newDynamicNode,
                isDirty: false,
                editorMode: "READONLY",
              }}
              pathwayNodes={[]}
              tenantId={""}
              pathwayId={""}
              onSave={() => {}}
              pathwayNavigatorDispatch={(_value: PathwayActions) => {}}
              nodeContext={{ fields: [] }}
              decisionSummary={[]}
              viewType={"view"}
              route={[]}
              setViewType={(_viewType: "view" | "edit") => {}}
              showHeader={false}
              showPreviewEditToggle={false}
              showDecisionSummary={false}
              showNodeInfo={false}
              showNodeEditorSectionHeader={false}
              containerStyles={{ height: "auto", borderBottom: "none" }}
              editorDispatch={(_value: DynamicNodeEditorActions) => {}}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 3, pb: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          disabled={!validTitle}
          onClick={handleSaveTemplate}
          startIcon={
            loading ? (
              <CircularProgress sx={{ color: "white" }} size={20} />
            ) : (
              <SaveOutlinedIcon />
            )
          }
        >
          Save Template
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveNewTemplateDialog;
