import ErrorPage from "app/components/ErrorPage";

import { useCrossCoverSelector } from "app/store/hooks";
import useUser from "features/hooks/useUser";
import React from "react";
import LoadingComponent from "./LoadingComponent";

const AppReadyGate = ({ children }: { children: React.ReactNode }) => {
  const { profile } = useUser();
  const crossCover = useCrossCoverSelector((state) => state.crossCover);

  if (profile) {
    if (!crossCover.isProviderLoaded) {
      return <LoadingComponent />;
    }
    if (crossCover.providerError) {
      return <ErrorPage message={crossCover.providerError} />;
    }
    return <>{children}</>;
  }

  return <></>;
};

export default AppReadyGate;
