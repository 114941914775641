import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useEffect, useRef, useState } from "react";
import { IDynamicNode, INode } from "../../../types/Nodes";
import { IPathwayEpisode } from "../../../types/Analytics";
import { useImmerReducer } from "use-immer";
import useModules from "./useModules";
import * as Sentry from "@sentry/react";
import useTenantBrowser from "features/hooks/useTenantBrowser";
import {
  getEmptyNode,
  getNodeOrder,
  getOrderedNodes,
  getStartNode,
} from "features/pathwaybuilder/utils/pathwayHelperV2";
import {
  PathwayActions,
  PathwayState,
  pathwayReducer,
} from "./navigatorReducer";

// export type NodePointer = {
//   pathwayId: string;
//   nodeId: string;
// };

// export type NodeDecisionAction = {
//   header: string;
//   items: INodeContextItem[];
// };

// export type PathwayMoveDirection = "FORWARD" | "BACKWARD";
// export type EpisodeState = "SAVED" | "UNSAVED";

// export type PathwayState = {
//   route: NodePointer[];
//   activeNode: NodePointer | undefined;
//   decisionSummary: NodeDecisionAction[];
//   currentPathwayId: string;
//   tenantId: string;
//   nodeContext: INodeContext;
//   pathwayEpisode?: IPathwayEpisode;
//   actionDirection?: PathwayMoveDirection;
//   episodeStatus?: EpisodeState;
// };

// export interface INodeContextItem {
//   namespace: string;
//   groupName: string;
//   name: string;
//   value: string;
//   order: number;
// }
// export interface INodeContext {
//   fields: INodeContextItem[];
// }

// export type PathwayActions =
//   | {
//       type: "MOVE_TO_NEXT_NODE";
//       payload: {
//         nodeId: string;
//         pathwayId: string;
//         clearDecisions?: boolean;
//         answer: string;
//       };
//     }
//   | {
//       type: "MOVE_TO_NEW_PATHWAY";
//       payload: {
//         nodeId: string;
//         pathwayId: string;
//       };
//     }
//   | { type: "MOVE_TO_PREVIOUS_NODE"; payload: {} }
//   | { type: "NODE_LOGIC_ON"; payload: INodeContextItem }
//   | { type: "NODE_LOGIC_OFF"; payload: INodeContextItem }
//   | { type: "RESET_NODE_LOGIC"; payload: {} }
//   | { type: "ADD_EPISODE_STEP"; payload: { node: INode } }
//   | { type: "REPLACE_EPISODE"; payload: { episode: IPathwayEpisode } }
//   | { type: "ADD_EPISODE_OUTCOME"; payload: { outcome: string } };

// const LEFT_EARLY_TEXT = "Left Pathway Early";

// export const pathwayReducer = (draft: PathwayState, action: PathwayActions) => {
//   switch (action.type) {
//     case "MOVE_TO_NEW_PATHWAY":
//       draft.activeNode = {
//         nodeId: action.payload.nodeId,
//         pathwayId: action.payload.pathwayId,
//       };
//       break;
//     case "MOVE_TO_PREVIOUS_NODE":
//       let lastNodePointer = draft.route.pop();

//       const lastDecisionSummary = draft.decisionSummary;
//       const item = lastDecisionSummary.pop();

//       const previousNodeContext: INodeContext = {
//         fields: [],
//       };

//       if (item && Boolean(item.items.length)) {
//         previousNodeContext.fields = item.items;
//       }

//       if (!lastNodePointer) {
//         break;
//       }

//       draft.actionDirection = "BACKWARD";
//       draft.route = [...draft.route];
//       draft.activeNode = lastNodePointer;
//       draft.decisionSummary = [...lastDecisionSummary];
//       draft.nodeContext = previousNodeContext;
//       draft.currentPathwayId = lastNodePointer.pathwayId;
//       break;
//     case "MOVE_TO_NEXT_NODE":
//       //let pathwayId = action.payload.pathwayId || draft.currentPathwayId;
//       const newPosition = {
//         nodeId: action.payload.nodeId,
//         pathwayId: action.payload.pathwayId || draft.currentPathwayId,
//       };
//       // const routeItem = actionToRouteItem(action.payload.nodeId, "");
//       // const newPosition = {
//       //   nodeId: routeItem.nodeId,
//       //   pathwayId: routeItem.pathwayId,
//       // };

//       console.log(newPosition);

//       // const isPathwayToPathWayLink =
//       //   newPosition.nodeId.substring(0, 2) === "@@";

//       //If this is a pathway link then we need to change pathway
//       //if (isPathwayToPathWayLink) {
//       //  const pathwayLink = getPathwayIdFromAction(newPosition.nodeId);
//       //  pathwayId = newPosition.nodeId;

//       //   //newPosition = previousState.activeNode.
//       //}

//       const currentRoute = draft.route;
//       const summary = draft.decisionSummary;
//       if (draft.activeNode) {
//         currentRoute.push(draft.activeNode);

//         if (draft.nodeContext.fields.length) {
//           summary.push({
//             header: action.payload.answer,
//             items: [...draft.nodeContext.fields],
//           });
//         } else {
//           summary.push({ header: action.payload.answer, items: [] });
//         }
//         //If we are clearing the decision summary then we also need to clear the route
//         if (action.payload.clearDecisions) {
//           summary.length = 0;
//           currentRoute.length = 0;
//         }
//       }

//       draft.route = [...currentRoute];
//       draft.activeNode = { ...newPosition };
//       draft.decisionSummary = [...summary];
//       draft.currentPathwayId = newPosition.pathwayId;
//       draft.nodeContext = { fields: [] };
//       draft.actionDirection = "FORWARD";
//       break;
//     case "NODE_LOGIC_ON":
//       //Remove the current answer for the namespace
//       draft.nodeContext.fields = draft.nodeContext.fields.filter(
//         (x) => x.namespace !== action.payload.namespace
//       );
//       //Add the new answer
//       draft.nodeContext.fields.push({ ...action.payload });
//       break;
//     case "NODE_LOGIC_OFF":
//       const filteredContext: INodeContext = {
//         fields: [],
//       };
//       // Remove the current answer
//       filteredContext.fields = draft.nodeContext.fields.filter(
//         (x) => x.namespace !== action.payload.namespace
//       );
//       draft.nodeContext = filteredContext;
//       break;
//     case "RESET_NODE_LOGIC":
//       draft.nodeContext = {
//         fields: [],
//       };
//       break;
//     case "ADD_EPISODE_STEP":
//       if (draft.pathwayEpisode === undefined) {
//         draft.pathwayEpisode = {
//           pathwayId: draft.currentPathwayId,
//           collectionId: "",
//           tenantId: draft.tenantId,
//           entryTime: firebase.firestore.FieldValue.serverTimestamp(),
//           route: {},
//           //Assume the default will be lest pathway early
//           outcomes: [LEFT_EARLY_TEXT],
//         };
//       }
//       const step: IPathwayEpisodeStep = {
//         entryTime: firebase.firestore.FieldValue.serverTimestamp(),
//         node: action.payload.node,
//         pathwayId: draft.currentPathwayId,
//         collection: "",
//         interaction:
//           draft.actionDirection === "FORWARD"
//             ? {
//                 header: draft.decisionSummary.at(-1)?.header ?? "",
//                 items: draft.decisionSummary.at(-1)?.items ?? [],
//               }
//             : { header: "GO BACK", items: [] },
//       };

//       //If we have a previous step then update the exist time
//       if (Object.values(draft.pathwayEpisode.route).length > 0) {
//         draft.pathwayEpisode.route[
//           Object.values(draft.pathwayEpisode.route).length - 1
//         ].exitTime = firebase.firestore.FieldValue.serverTimestamp();
//       }

//       draft.pathwayEpisode.route[
//         Object.values(draft.pathwayEpisode.route).length
//       ] = step;

//       draft.episodeStatus = "UNSAVED";

//       break;

//     case "REPLACE_EPISODE":
//       draft.pathwayEpisode = action.payload.episode;
//       draft.episodeStatus = "SAVED";
//       break;

//     case "ADD_EPISODE_OUTCOME":
//       //Clear the default state of our pathway
//       if (draft.pathwayEpisode?.outcomes[0] === LEFT_EARLY_TEXT) {
//         draft.pathwayEpisode.outcomes = [];
//       }
//       draft.pathwayEpisode?.outcomes.push(action.payload.outcome);
//       draft.episodeStatus = "UNSAVED";
//       break;
//     default:
//       throw Error("Unknown route action");
//   }
// };

// const emptyNodeTemplate: INode = {
//   id: "",
//   image: "",
//   nodeContentArea: "",
//   print: false,
//   question: "",
//   title: "",
//   vimeo: "",
//   youtube: "",
//   widgets: [],
//   controlledElements: [],
//   video: "",
//   createdAt: Date.now(),
//   createdBy: "",
//   updatedAt: Date.now(),
//   updatedBy: "",
//   files: [],
//   actions: [],
//   answers: [],
//   groupNames: [],
//   logicgroups: [],
//   logicfields: [],
//   logicscores: [],
//   ruleOperators: [],
//   ruleValues: [],
//   ruleValuesMin: [],
//   ruleValuesMax: [],
//   ruleNames: [],
//   ruleActions: [],
//   ruleTypes: [],
//   showscores: false,
//   fileNames: [],
// };

const readCountName = (moduleId: string) =>
  moduleId === "Ortho" ? "reads" : `${moduleId}Reads`;

const totalReadCountName = (moduleId: string) =>
  moduleId === "Ortho" ? "totalcount" : `${moduleId}TotalCount`;

export const incrementReadCount = async (
  tenantId: string,
  moduleId: string,
  currentPathway: string
) => {
  const db = firebase.firestore();

  const read = {
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    pathway: currentPathway,
  };
  const increment = {
    [totalReadCountName(moduleId)]: firebase.firestore.FieldValue.increment(1),
  };

  await db
    .collection(`${tenantId}/--readcount--/${readCountName(moduleId)}`)
    .add(read);

  await db.collection(tenantId).doc("--readcount--").update(increment);
};

const usePathwayNavigator = ({
  pathwayId: initialPathwayId,
  tenantId,
  nodeCollection,
  startNode,
}: {
  pathwayId: string;
  tenantId: string;
  nodeCollection: string;
  startNode?: string;
}) => {
  const db = firebase.firestore();
  const { tenantBrowser } = useTenantBrowser();

  const [pathwayNodes, setPathwayNodes] = useState<(IDynamicNode | INode)[]>(
    []
  );
  const [activeNode, setActiveNode] = useState<INode | IDynamicNode>(
    getEmptyNode()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [, setLoadedTime] = useState<number>();
  const episodeRefId = useRef<string>("");
  const { activeModule } = useModules();
  const [state, dispatch] = useImmerReducer<PathwayState, PathwayActions>(
    pathwayReducer,
    {
      route: [],
      activeNode: undefined,
      decisionSummary: [],
      currentPathwayId: initialPathwayId,
      tenantId: tenantId || "",
      nodeContext: { fields: [] },
    }
  );

  const isDefaultTenant = tenantBrowser === undefined || tenantBrowser === "";

  // add to read count only if user if browsing own tenancy and live environment
  useEffect(() => {
    if (nodeCollection === "livenodes" && isDefaultTenant) {
      incrementReadCount(tenantId, activeModule?.id ?? "", initialPathwayId);
    }
  }, [
    activeModule?.id,
    isDefaultTenant,
    initialPathwayId,
    nodeCollection,
    tenantBrowser,
    tenantId,
  ]);

  useEffect(() => {
    const saveEpisode = async () => {
      try {
        if (
          isDefaultTenant &&
          state.pathwayEpisode !== undefined &&
          state.episodeStatus === "UNSAVED" &&
          nodeCollection === "livenodes"
        ) {
          //New Episode, create inital document
          if (episodeRefId.current === "") {
            const episodeRef = await db
              .collection(`${tenantId}/--readcount--/${activeModule?.id}`)
              .add({
                ...state.pathwayEpisode,
                exitTime: firebase.firestore.FieldValue.serverTimestamp(),
              });
            episodeRefId.current = episodeRef.id;
          }

          if (episodeRefId.current !== "") {
            await db
              .collection(`${tenantId}/--readcount--/${activeModule?.id}`)
              .doc(episodeRefId.current)
              .set(
                {
                  ...state.pathwayEpisode,
                  exitTime: firebase.firestore.FieldValue.serverTimestamp(),
                },
                { merge: true }
              );
          }
          //We need to reload the episode with the newly populated timestamps
          const episodeRef = await db
            .collection(`${tenantId}/--readcount--/${activeModule?.id}`)
            .doc(episodeRefId.current)
            .get();
          const episode = episodeRef.data() as IPathwayEpisode;

          dispatch({
            type: "REPLACE_EPISODE",
            payload: { episode: episode },
          });
        }
      } catch (ex: any) {
        console.error("usePathwayNavigator", ex);
        Sentry.withScope((scope) => {
          scope.setExtra(
            "pathwayEpisode",
            JSON.stringify(state.pathwayEpisode, (k, v) =>
              v === undefined ? "undefinedError" : v
            )
          );
          Sentry.captureException(ex);
        });
      }
    };
    saveEpisode();
  }, [
    activeModule?.id,
    db,
    isDefaultTenant,
    dispatch,
    nodeCollection,
    state.episodeStatus,
    state.pathwayEpisode,
    tenantId,
  ]);

  //Set the active node object
  useEffect(() => {
    const node = pathwayNodes.find((x) => x.id === state.activeNode?.nodeId);

    if (
      isDefaultTenant &&
      node !== undefined &&
      nodeCollection === "livenodes"
    ) {
      dispatch({ type: "ADD_EPISODE_STEP", payload: { node: node } });
    }
    setActiveNode(node ?? getEmptyNode());
  }, [
    isDefaultTenant,
    dispatch,
    nodeCollection,
    pathwayNodes,
    state.activeNode,
  ]);

  // Get a reference to a mutable copy of the route array
  const routeRef = useRef(state.route);

  // Keep the route array copy up to date
  useEffect(() => {
    routeRef.current = state.route;
  }, [state.route]);

  useEffect(() => {
    console.log("Active Node", state.activeNode);
  }, [state.activeNode]);

  useEffect(() => {
    console.log("Current Pathway", state.currentPathwayId);
  }, [state.currentPathwayId]);

  //Load the pathway data
  useEffect(() => {
    let pathwayId = state.currentPathwayId; // || initialPathwayId;

    let pathwayToPathwayNodeId = state.activeNode?.nodeId;

    if (tenantId && pathwayId) {
      setLoading(true);
      const unsubscribe = db
        .collection(`${tenantId}/${pathwayId}/${nodeCollection}`)
        .onSnapshot(async (nodeSnaps) => {
          const nodes: INode[] = [];
          nodeSnaps.forEach((nodeSnap) => {
            const node = nodeSnap.data() as INode;
            nodes.push({ ...node, id: nodeSnap.id });
          });

          const nodeOrder = await getNodeOrder(
            tenantId,
            pathwayId,
            nodeCollection
          );
          const pathwayStartNode = await getStartNode(
            tenantId,
            pathwayId,
            nodeCollection
          );
          const orderedNodes = getOrderedNodes(nodeOrder, nodes);

          setPathwayNodes(orderedNodes);
          setLoadedTime(performance.now());

          //If the route is empty then we are jumping into a pathway. So if we have a startNode provided
          //we set it here. The startNode allows overriding where in the pathway we are starting from
          let initialNodeId = pathwayStartNode.id ?? "";
          if (routeRef.current.length === 0 && startNode) {
            initialNodeId = startNode;
          }

          if (pathwayToPathwayNodeId) {
            initialNodeId = pathwayToPathwayNodeId;
          }

          // if (state.route.length > 0) {
          //   initialNodeId = state.route.at(-1)?.nodeId ?? "";
          // }

          if (state.currentPathwayId !== routeRef.current.at(-1)?.pathwayId) {
            dispatch({
              type: "MOVE_TO_NEW_PATHWAY",
              payload: {
                nodeId: initialNodeId,
                pathwayId: pathwayId,
              },
            });
          }
        });
      setLoading(false);
      return unsubscribe;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tenantId,
    db,
    state.currentPathwayId,
    nodeCollection,
    startNode,
    initialPathwayId,
    // state.activeNode?.nodeId,
    dispatch,
  ]);

  return {
    dispatch,
    state,
    activeNode,
    pathwayNodes,
    loading,
    setStartNode: (nodeId: string) =>
      dispatch({
        type: "MOVE_TO_NEW_PATHWAY",
        payload: {
          nodeId: nodeId,
          pathwayId: initialPathwayId,
        },
      }),
  };
};

export default usePathwayNavigator;
