import { useRef } from "react";
import { Segment, Icon, Image, Button, Ref } from "semantic-ui-react";
import { useReactToPrint } from "react-to-print";
import SafeHtml from "app/components/SafeHtml";
import DecisionSummaryList from "./DecisionSummaryListV2";
import dateToString from "app/utils/dateToString";
import CrossCoverLogo from "../../../app/assets/CC_Logo_Landscape_BG.svg";
import { INodeBlockBase } from "../../../../types/Nodes";
import { getManagementPlanText } from "../NodeEditorUtils";
// import { List } from "immutable";
import { Box } from "@mui/material";
import { NodeDecisionAction } from "features/hooks/navigatorReducer";

const center = {
  textAlign: "center",
};

const logo = {
  height: "70px",
  width: "auto",
  marginLeft: "0px",
  position: "absolute",
  zIndex: "0",
};

const westDevonMIULogo = {
  height: "100px",
  width: "auto",
  marginLeft: "650px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const rdeLogo = {
  height: "75px",
  width: "auto",
  marginLeft: "560px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const plymouthLogo = {
  height: "100px",
  width: "auto",
  marginLeft: "480px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const rchLogo = {
  height: "80px",
  width: "auto",
  marginLeft: "480px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const cornwallMIULogo = {
  height: "80px",
  width: "auto",
  marginLeft: "570px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const northDevonLogo = {
  height: "80px",
  width: "auto",
  marginLeft: "550px",
  marginTop: "0px",
  position: "absolute",
  zIndex: "0",
};

const patientLabel = {
  height: "140px",
  width: "auto",
  marginLeft: "420px",
  marginTop: "85px",
  position: "absolute",
  zIndex: "0",
};

export interface IComponentToPrintProps {
  decisionSummary: NodeDecisionAction[];
  tenantId: string;
  outcome: string;
  blocks: Record<string, INodeBlockBase>;
  currentPathway: string;
  ref: any;
}

const getTenantImage = (tenantId: string) => {
  return (
    <>
      {tenantId === "West Devon MIU" && (
        <Image
          style={westDevonMIULogo}
          src="https://firebasestorage.googleapis.com/v0/b/orthopathwaytestdatabase.appspot.com/o/West%20Devon%20MIU%2Flivewell%20logo.png?alt=media&token=3e1772e3-a548-4aa2-a45e-98d1ab329445"
          alt="logo"
        />
      )}
      {tenantId === "Exeter" && (
        <Image
          style={rdeLogo}
          src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/Exeter%2FRDE%20logo.png?alt=media&token=3a6871ce-56d2-42a9-942d-3c4703058355"
          alt="logo"
        />
      )}
      {tenantId === "Derriford" && (
        <Image
          style={plymouthLogo}
          src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/plymouthFlatLogo.png?alt=media&token=b4abb048-5802-48cf-af79-189d8d908830"
          alt="logo"
        />
      )}
      {tenantId === "Cornwall MIU" && (
        <Image
          style={cornwallMIULogo}
          src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/cornwallMIUlogo.png?alt=media&token=11af1ebc-54d0-4e15-a995-97ac04bcfc21"
          alt="logo"
        />
      )}
      {tenantId === "North Devon" && (
        <Image
          style={northDevonLogo}
          src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/ndht-logo.jpg?alt=media&token=c228d732-593b-40cb-8fb9-982bd2a22115"
          alt="logo"
        />
      )}
      {tenantId === "RCH" && (
        <Image
          style={rchLogo}
          src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/rchtlogo.png?alt=media&token=510820ce-9975-4780-9644-60da7bcdbe2a"
          alt="logo"
        />
      )}
      <Image
        style={patientLabel}
        src="https://firebasestorage.googleapis.com/v0/b/ortho-pathway.appspot.com/o/patientLabel.png?alt=media&token=23d5caec-4008-40ad-a70d-ef4c31aa7e89"
        alt="logo"
      />
    </>
  );
};

//const decisionToIgnore = ["continue to full pathway", "yes", "no", "continue"];

// const isDecisionIgnored = (decision: string) => {
//   return decisionToIgnore.includes(decision.toLowerCase());
// };

const ComponentToPrint = ({
  decisionSummary,
  tenantId,
  blocks,
  currentPathway,
  outcome,
  ref,
}: IComponentToPrintProps) => {
  const localDate = dateToString(new Date());

  return (
    <Ref innerRef={ref}>
      <Segment>
        <Image style={logo} src={CrossCoverLogo} alt="logo" />
        {getTenantImage(tenantId)}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Segment compact>
          <h3>Date: {localDate} </h3>
        </Segment>

        <br></br>

        <Segment.Group>
          <Segment style={center} compact>
            <h2>
              {tenantId} {currentPathway} Pathway
            </h2>
          </Segment>
          <Segment compact>
            <h4>Decision Summary</h4>
          </Segment>
          <Segment compact>
            <DecisionSummaryList decisionSummary={decisionSummary} />
          </Segment>
          <Segment compact>
            <h4>Outcome</h4>
          </Segment>
          <Segment>{outcome}</Segment>
          <Segment compact>
            <h4>Management plan</h4>
          </Segment>
          <Segment compact>
            {getManagementPlanText(blocks).map((content, index) => (
              <Box key={index}>
                {/* <Box sx={{ display: "flex" }}> */}
                {/* <Icon name="caret right" /> */}
                {/* {block.title} */}
                {/* </Box> */}
                {/* <Box sx={{ pl: 4 }}> */}
                <SafeHtml html={content} allowHtml />
                {/* </Box> */}
              </Box>
            ))}
          </Segment>
        </Segment.Group>
        <Segment>
          <p>
            Healthcare Professional Name
            (Print):...........................................
          </p>
          <br></br>
          <p>
            Healthcare Professional
            Signature:...................................................
          </p>
        </Segment>
      </Segment>
    </Ref>
  );
};

interface INodePrintProps {
  tenantId: string;
  decisionSummary: NodeDecisionAction[];
  currentPathway: string;
  outcome: string;
  blocks: Record<string, INodeBlockBase>;
}

const NodePrint = ({
  tenantId,
  decisionSummary,
  currentPathway,
  outcome,
  blocks,
}: INodePrintProps) => {
  const componentRef = useRef(null);
  const onPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Button fluid color="green" onClick={onPrint}>
        <Icon name="print" />
        Print this out!
      </Button>

      <Ref innerRef={componentRef}>
        <ComponentToPrint
          outcome={outcome}
          blocks={blocks}
          decisionSummary={decisionSummary}
          tenantId={tenantId}
          currentPathway={currentPathway}
          ref={componentRef}
        />
      </Ref>
    </div>
  );
};

export default NodePrint;
