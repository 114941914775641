import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

interface IPopUpProps {
  component: React.ReactNode;
  popUpText: string | JSX.Element[];
  backGroundColour: "dark" | "light";
}

const PopUp = ({ component, popUpText, backGroundColour }: IPopUpProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let bgcolur = "rgba(0, 0, 0, 0.7)";

  backGroundColour === "light"
    ? (bgcolur = "#FFFFFF")
    : (bgcolur = "rgba(0, 0, 0, 0.7)");

  return (
    <div>
      <Box
        aria-owns={open ? "mouse-over-popup" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {component}
      </Box>
      <Popover
        id="mouse-over-popup"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          padding: "0",
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            p: 1,
            color: "primary.contrastText",
            backgroundColor: bgcolur,
            maxWidth: "425px",
            height: "100%",
            m: 0
          }}
        >
          {popUpText}
        </Typography>
      </Popover>
    </div>
  );
};

export default PopUp;
