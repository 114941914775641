import { Box } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";

const PlaceholderAddBlockOnRow = ({
  showDroppingPositions,
  rowIndex,
  blockDraggingId,
}: {
  showDroppingPositions?: boolean;
  rowIndex: number;
  blockDraggingId?: string;
}) => {
  return (
    <Box
      sx={{
        py: 2,
        display: showDroppingPositions ? "block" : "none",
        textAlign: "center",
      }}
    >
      <Droppable
        droppableId={`add-row-${rowIndex}`}
        isDropDisabled={!showDroppingPositions}
      >
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              display: "flex",
              alignItems: "center",
              height: snapshot.isDraggingOver ? "200px" : "30px",
              border: "2px solid #9FDFDC",
              justifyContent: "center",
              color: "#9FDFDC",
              background: snapshot.isDraggingOver
                ? "rgba(1, 93, 103, 0.08)"
                : "",
              transition: ".2s",
            }}
          >
            <span style={{ display: "none" }}>{provided.placeholder}</span>
            {blockDraggingId === ""
              ? "Add block in a new row"
              : "Drop Block Here"}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default PlaceholderAddBlockOnRow;
