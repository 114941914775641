import { useEffect, useState } from "react";
import { INagScreen } from "../../../../types/NagScreen";

import { collection, query, where, onSnapshot } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const useNagScreens = () => {
  const [allNagScreens, setAllNagScreens] = useState<INagScreen[]>();

  const db = firebase.firestore();

  useEffect(() => {
    const currentDate = new Date();
    const currentUnixTime = Date.now();

    const validFromQuery = query(
      collection(db, "nagScreens"),
      where("validFrom", "<=", currentDate)
    );

    const unsubscribe = onSnapshot(validFromQuery, (querySnapshot) => {
      const nagScreenItems: INagScreen[] = [];

      querySnapshot.forEach((doc) => {
        nagScreenItems.push(doc.data() as INagScreen);
      });

      const validToNagScreens = nagScreenItems
        .filter(
          (screen) =>
            !screen.validTo || screen.validTo.toMillis() >= currentUnixTime
        )
        .concat()
        .sort((a, b) => a.order - b.order);

      setAllNagScreens(validToNagScreens);

      unsubscribe();
    });
  }, [db]);

  return { allNagScreens };
};

export default useNagScreens;
