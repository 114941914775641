import { useState, useEffect, useMemo } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { toastr } from "react-redux-toastr";

import DocumentsList from "./DocumentsList";
import DocumentGrid from "./DocumentGrid";
import {
  getDirectChildren,
  getTargetFolderPath,
} from "./utils/DocumentLibraryHelper";
import { IProfile } from "../../../types/User";
import { IDocument, IFolder, IFiles } from "../../../types/DocumentLibrary";
import { docLibStyles } from "./utils/DocumentLibraryStyles";

import DocumentLibraryActions from "./DocumentLibraryActions";
import {
  FilterCriteria,
  SortCriteria,
  viewType,
} from "./docLibTypes/DocumentLibraryTypes";
import useErrorMessages from "./utils/useErrorMessage";
import { LinearProgress } from "@mui/material";
import { Button, Grid, Icon } from "semantic-ui-react";
import DocumentLibraryFilters from "./DocumentLibraryFilters";
import useUser from "features/hooks/useUser";

interface DocLibProps {
  filterBy?: FilterCriteria;
  onSelect?: (value: IDocument) => void;
  handleUpdateNodePreview?: (
    oldFileLink: string,
    newFileLink: unknown,
    fileTitle: string
  ) => void;
  profile?: IProfile;
  view?: viewType;
  setIsDragging?: (value: boolean) => void;
}

const DocumentLibraryContainer = ({
  filterBy,
  onSelect,
  handleUpdateNodePreview,
  view,
  setIsDragging,
}: DocLibProps) => {
  const { activeTenantId, profile } = useUser();

  if (activeTenantId === undefined) {
    throw Error("FolderActions: tenantId is undefined");
  }

  const db = firebase.firestore();

  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>(
    filterBy ? filterBy : "ALL-FILES"
  );
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>("fileTitle");
  const [isAscendingSort, setIsAscendingSort] = useState<boolean>(true);
  const [viewType, setViewType] = useState<viewType>(view ? view : "List View");
  const [folderPath, setFolderPath] = useState<string>("/");
  const [folders, setFolders] = useState<IFolder[]>([]);
  const [displayPath, setDisplayPath] = useState<string[]>([]);
  const [draggedFile, setDraggedFile] = useState<
    IDocument | IFolder | undefined
  >();
  const [disableDrop, setDisableDrop] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [displayProgressBar, setDisplayProgressBar] = useState<boolean>(false);

  const { errorMessages } = useErrorMessages();

  // Initial Calls to Firestore
  useEffect(() => {
    let filesSnapshot = db
      .collection(`${activeTenantId}/Files/files`)
      .where("status", "==", "ACTIVE");

    return filesSnapshot.onSnapshot((documentSnaps) => {
      const documentItems: IDocument[] = [];
      documentSnaps.forEach((documentSnap) => {
        documentItems.push({
          ...(documentSnap.data() as IDocument),
          id: documentSnap.id,
        });
      });

      setDocuments(documentItems);
    });
  }, [db, filterCriteria, folderPath, activeTenantId]);

  useEffect(() => {
    return db
      .collection(activeTenantId)
      .doc("Files")
      .onSnapshot((documentSnap) => {
        if (!documentSnap.metadata.hasPendingWrites) {
          const files = documentSnap.data() as IFiles;

          if (files) {
            const folders = Object.entries(files.folders || []);

            const folderArray = folders
              .map((folder) => {
                return { id: folder[0], ...folder[1] };
              })
              .filter((file) => file.status === "ACTIVE");

            setFolders(folderArray);
          }
        }
      });
  }, [db, filterCriteria, activeTenantId, onSelect]);

  useEffect(() => {
    if (progress > 0) {
      setDisplayProgressBar(true);
    }
    if (progress === 100) {
      setTimeout(() => {
        setDisplayProgressBar(false);
      }, 1600);
    }
  }, [progress]);

  // Folder Path
  const getDirectParent = () => {
    const folderSegments = folderPath.split("/").filter((x) => x);
    folderSegments.pop();
    setDocuments([]);
    setFolderPath(`/${folderSegments.join("/")}`);
  };

  const removeFolderPath = () => {
    displayPath.pop();
    setDisplayPath(displayPath);
  };

  const displayFolderPath = (folder: IFolder) => {
    setDocuments([]);
    setFolderPath(folder.folderPath);

    const folderTitle = `/${folder.folderTitle}`;
    setDisplayPath((prevPaths) => [...prevPaths, folderTitle]);
  };

  const style = useMemo(
    () => ({
      ...docLibStyles.dropzoneMoveFilesStyle,
      ...(draggedFile ? docLibStyles.dropzoneAcceptedMove : {}),
    }),
    [draggedFile]
  );

  const handleDrop = async (folder?: IFolder) => {
    setProgress(0);
    setDisableDrop(true);
    const targetFolder = folder;
    let targetPath: string = "";

    const resetState = () => {
      setDraggedFile(undefined);
      setDisableDrop(false);
    };

    //child folder
    if (typeof folder !== "undefined") {
      targetPath = folder.folderPath;
    }

    // parent folder
    if (typeof folder === "undefined") {
      const folderSegments = folderPath.split("/").filter((x) => x);
      folderSegments.pop();

      const pathToParent = `/${folderSegments.join("/")}`;
      targetPath = pathToParent;
    }

    if (draggedFile?.documentType !== "FOLDER") {
      const draggedDocument = draggedFile as IDocument;
      const docTitles = documents
        .filter((document) => {
          return targetPath === document.folderPath;
        })
        .map((document) => {
          return document.fileTitle;
        });

      for (const title of docTitles) {
        if (title === draggedDocument.fileTitle) {
          toastr.error(
            `${title} already exists.`,
            errorMessages.matchTitleAtDestination
          );
          resetState();
          return;
        }
      }

      await db
        .collection(`${activeTenantId}/Files/files`)
        .doc(draggedDocument?.id)
        .update({
          updatedBy: profile.email,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          folderPath: targetPath,
        });
    }

    if (draggedFile?.documentType === "FOLDER") {
      const draggedFolder = draggedFile as IFolder;
      const currentFolderPath = draggedFolder.folderPath;

      if (currentFolderPath === targetPath) {
        resetState();
        return;
      }

      const directChildren = getDirectChildren(folders, targetPath);

      const folderTitles = directChildren.map((folder: IFolder) => {
        return folder.folderTitle;
      });

      for (const title of folderTitles) {
        if (title === draggedFolder.folderTitle) {
          toastr.error(
            `${title} already exists.`,
            errorMessages.matchTitleAtDestination
          );
          resetState();
          return;
        }
      }

      // update descendent files
      const snapshots = await db
        .collection(`${activeTenantId}/Files/files`)
        .where("status", "==", "ACTIVE")
        .where("folderPath", ">=", currentFolderPath)
        .where("folderPath", "<=", currentFolderPath + "\uf8ff")
        .get();

      const totalDocs = snapshots.docs.length;
      const upDatingDocs: IDocument[] = [];

      snapshots.forEach((doc) => {
        const data = { ...(doc.data() as IDocument) };
        upDatingDocs.push(data);

        doc.ref.update({
          updatedBy: profile.email,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          folderPath: getTargetFolderPath(
            data.folderPath,
            targetPath,
            folderPath,
            targetFolder
          ),
        });

        totalDocs > 10
          ? setProgress((upDatingDocs.length / totalDocs) * 100)
          : setProgress(0);
      });

      const decendentFolders = folders.filter((folder: IFolder) => {
        return folder.folderPath.startsWith(currentFolderPath);
      });

      // update descendent folders
      await decendentFolders.forEach((folder) => {
        const updatedFolder = {
          [folder.id as string]: {
            ...folder,

            folderPath: getTargetFolderPath(
              folder.folderPath,
              targetPath,
              folderPath,
              targetFolder
            ),
            updatedBy: profile.email,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
        };

        db.collection(activeTenantId)
          .doc("Files")
          .set({ folders: updatedFolder }, { merge: true });
      });
    }

    let movedItem =
      draggedFile?.documentType === "FOLDER" ? "Folder" : "Document";

    toastr.success(` ${movedItem} Moved`, ` Your ${movedItem} has been moved.`);
    resetState();
  };

  return (
    <div style={{ margin: "24px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DocumentLibraryFilters
          filterBy={filterBy}
          filterCriteria={filterCriteria}
          viewType={viewType}
          sortCriteria={sortCriteria}
          setFilterCriteria={setFilterCriteria}
          setSortCriteria={setSortCriteria}
        />
        <DocumentLibraryActions
          documents={documents}
          filterBy={filterBy}
          folders={folders}
          folderPath={folderPath}
          viewType={viewType}
          setFilterCriteria={setFilterCriteria}
          setViewType={setViewType}
        />
      </div>

      {displayProgressBar && (
        <LinearProgress variant="determinate" value={progress} />
      )}

      <div style={docLibStyles.folderPathContainer}>
        {folderPath.length > 1 && (
          <Button
            size="mini"
            icon
            style={{ marginRight: 6 }}
            onClick={() => {
              getDirectParent();
              removeFolderPath();
            }}
          >
            <Icon name="arrow up" />
          </Button>
        )}

        <Grid.Column floated="left" style={docLibStyles.folderPath}>
          Current Folder: {displayPath.join("")}
        </Grid.Column>
      </div>
      {viewType === "List View" && (
        <DocumentsList
          documents={documents}
          disableDrop={disableDrop}
          filterBy={filterBy}
          filterCriteria={filterCriteria}
          folders={folders}
          folderPath={folderPath}
          isAscendingSort={isAscendingSort}
          sortCriteria={sortCriteria}
          style={style}
          handleUpdateNodePreview={handleUpdateNodePreview}
          displayFolderPath={displayFolderPath}
          getDirectParent={getDirectParent}
          handleDrop={handleDrop}
          onSelect={onSelect}
          removeFolderPath={removeFolderPath}
          setDraggedFile={setDraggedFile}
          setSortCriteria={setSortCriteria}
          setIsAscendingSort={setIsAscendingSort}
          setIsDragging={setIsDragging}
        />
      )}
      {viewType === "Grid View" && (
        <DocumentGrid
          documents={documents}
          disableDrop={disableDrop}
          filterBy={filterBy}
          filterCriteria={filterCriteria}
          folders={folders}
          folderPath={folderPath}
          sortCriteria={sortCriteria}
          style={style}
          handleUpdateNodePreview={handleUpdateNodePreview}
          displayFolderPath={displayFolderPath}
          getDirectParent={getDirectParent}
          handleDrop={handleDrop}
          onSelect={onSelect}
          removeFolderPath={removeFolderPath}
          setDraggedFile={setDraggedFile}
          setIsDragging={setIsDragging}
        />
      )}
    </div>
  );
};

export default DocumentLibraryContainer;
