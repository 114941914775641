//Copyright 2018 Nathan Moore

import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

const LoadingComponent = ({
  inverted,
  debugMessage,
  loadingMessage,
}: {
  inverted?: boolean;
  debugMessage?: string;
  loadingMessage?: string;
}) => {
  debugMessage && console.log("Loading", debugMessage);
  return (
    <Dimmer inverted={inverted || true} active={true}>
      {!loadingMessage && <Loader />}
      {loadingMessage && <Loader>{loadingMessage}</Loader>}
    </Dimmer>
  );
};

export default LoadingComponent;
