import { useEffect, useState } from "react";

import { CirclePicker } from "@hello-pangea/color-picker";
import { AccessibleColourParis } from "app/components/AccessibleColoursParis";
import Box from "@mui/system/Box";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import useUserPublicProfile from "features/hooks/useUserPublicProfile";

interface AvatarProps {
  initials: string;
  avatarColour: string | undefined;
  setAvatarColour: (value: string | undefined) => void;
  isAccountSettings?: boolean;
}

const UserSettings = ({
  initials,
  avatarColour,
  setAvatarColour,
  isAccountSettings,
}: AvatarProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const { publicProfile, editAvatarColour } = useUserPublicProfile();

  useEffect(() => {
    if (publicProfile?.avatarColour) {
      setAvatarColour(publicProfile.avatarColour);
    }
  }, [publicProfile?.avatarColour, setAvatarColour]);

  const getColoursForAvatar = () => {
    const colourArray: string[] = [];

    AccessibleColourParis.forEach((colour) => {
      colourArray.push(colour.background);
    });

    return colourArray;
  };

  const getColoursForInitials = () => {
    if (avatarColour === undefined) {
      return "#f8f8ff";
    }

    if (AccessibleColourParis) {
      for (const colours of AccessibleColourParis) {
        const coloursFromList = colours.background.toLocaleUpperCase();
        const selectedColour = avatarColour.toLocaleUpperCase();

        if (selectedColour === coloursFromList) {
          return colours.text;
        }
      }
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          elevation={4}
          onClose={handleClose}
          severity="success"
          sx={{ border: "#2E7D32 solid 1px" }}
        >
          <AlertTitle>Success!</AlertTitle>
          Your avatar has been changed.
        </Alert>
      </Snackbar>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "relative",
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          <Box
            sx={{
              minWidth: "calc(67% - 16px)",
              width: "calc((450px - 100%) * 1000)",
              maxWidth: "100%",
            }}
          >
            <Typography
              variant={isAccountSettings ? "subtitle1" : "h6"}
              component="h3"
              sx={{ fontWeight: 700, mb: isAccountSettings ? 2 : "" }}
            >
              Avatar Customisation
            </Typography>
            <Box sx={{ marginBottom: 1 }}>
              Select the colour of your avatar.
            </Box>
            <CirclePicker
              colors={getColoursForAvatar()}
              width="100%"
              onChange={(e) => setAvatarColour(e.hex)}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: "33%",
              width: "calc((450px - 100%) * 1000)",
              maxWidth: "100%",
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: "grey", textAlign: "center", marginBottom: 1 }}
            >
              Preview
            </Typography>
            <Box
              sx={{
                borderRadius: "50%",
                width: "157px",
                height: "157px",
                background: avatarColour ? avatarColour : "#BEBEBE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 0.5,
              }}
            >
              <Box sx={{ color: getColoursForInitials() }}>
                {isAccountSettings ? (
                  <Box
                    sx={{
                      fontSize:
                        publicProfile?.initials &&
                        publicProfile.initials.length < 3
                          ? "80px"
                          : "60px",
                    }}
                  >
                    {publicProfile?.initials}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      fontSize: initials.length < 3 ? "80px" : "60px",
                    }}
                  >
                    {initials === "" ? "IN" : initials.toLocaleUpperCase()}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {isAccountSettings && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flex: 1,
              gap: 1,
              flexWrap: "wrap-reverse",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                minWidth: "max-content",
                width: "calc((450px - 100%) * 1000)",
                maxWidth: "100%",
              }}
              onClick={() => setAvatarColour(publicProfile?.avatarColour)}
            >
              Cancel
            </Button>
            <Button
              startIcon={<SaveIcon />}
              sx={{
                minWidth: "max-content",
                width: "calc((450px - 100%) * 1000)",
                maxWidth: "100%",
              }}
              variant="contained"
              onClick={() => {
                if (publicProfile?.avatarColour !== avatarColour) {
                  editAvatarColour(avatarColour ? avatarColour : "");
                  setOpen(true);
                }
              }}
            >
              Save Avatar
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserSettings;
