import { DocumentTimestampToMilliseconds } from "app/utils/DocumentTimestampToMilliseconds";
import { FieldValue, Timestamp } from "firebase/firestore";

interface DateTimeProps {
  value: Date | number | Timestamp | FieldValue | null | undefined;
  as: "date" | "time" | "datetime";
  defaultValue?: string;
}

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const timeOptions: Intl.DateTimeFormatOptions = {
  timeStyle: "short",
};

const DateTime = ({ as, value, defaultValue }: DateTimeProps) => {
  if (!value && defaultValue) {
    return <>{defaultValue}</>;
  }
  value = DocumentTimestampToMilliseconds(value);

  const d = new Date(value);

  return (
    <>
      {as === "date" && d.toLocaleDateString("en-GB", dateOptions)}

      {as === "time" && d.toLocaleTimeString("en-GB", timeOptions)}

      {as === "datetime" &&
        d.toLocaleDateString("en-GB", dateOptions) +
          " at " +
          d.toLocaleTimeString("en-GB", timeOptions)}
    </>
  );
};

export default DateTime;
