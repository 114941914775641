import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { toastr } from "react-redux-toastr";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { FilterCriteria } from "../docLibTypes/DocumentLibraryTypes";
import { IDocument } from "../../../../types/DocumentLibrary";
import functions from "app/common/util/functions";
import useErrorMessages from "features/documentLibrary/utils/useErrorMessage";
import { IFindResult } from "../../../../functions/src/http/findandreplace/find";
import { IProfile } from "../../../../types/User";

const useUploadFile = (
  profile: IProfile,
  tenantId: string,
  documents: IDocument[],
  folderPath: string,
  filterBy: FilterCriteria | undefined,
  documentToUpdate?: IDocument,
  onSelect?: (value: IDocument) => void,
  handleUpdateNodePreview?: (
    oldFileLink: string,
    newFileLink: unknown,
    fileTitle: string
  ) => void
) => {
  if (profile === undefined) {
    throw Error("User profile missing");
  }

  const db = firebase.firestore();
  const storage = firebase.storage();

  const [uploadFile, setUploadFile] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [fileTitle, setFileTitle] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState("");
  const [changeFileName, setChangeFileName] = useState<boolean>(false);
  const [fileSize, setFileSize] = useState(0);

  const { errorMessages } = useErrorMessages(fileName, fileSize, fileTitle);

  const onUploadFile = (file: any): Promise<string> => {
    return new Promise((resolve) => {
      const fileExtension = file.name.split(".").pop();
      const randomId = uuidv4();
      const fileId = `${randomId}.${fileExtension}`;
      const docFolder = storage.ref();
      const fileRef = docFolder.child(`/${tenantId}/${fileId}`);
      const uploadTask = fileRef.put(file);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {},
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const runUploadFile = async () => {
    setLoading(true);
    let fileDownloadURL = await onUploadFile(uploadFile);

    const file = {
      createdBy: profile.email,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy: profile.email,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      status: "ACTIVE",
      deletedBy: "",
      deletedAt: "",
      documentType: fileType,
      file: fileDownloadURL,
      fileName: fileName.trim(),
      fileTitle: fileTitle.trim() ? fileTitle : fileName,
      folderPath: folderPath,
      source: "firebase",
    };

    await db.collection(`${tenantId}/Files/files`).add(file);
    setLoading(false);
    toastr.success("File Saved", "Your file has been created.");
    onCancelUpload();
  };

  const runReplaceFile = async (
    results: IFindResult[],
    oldFileLink: string
  ) => {
    if (documentToUpdate) {
      let newFileLink = await onUploadFile(uploadFile);
      let newFileName = fileTitle;

      results.forEach((result) => {
        let nodeFiles: string[] = result.nodeContent.files;

        // use index of node files to update filename
        for (const [index, file] of nodeFiles.entries()) {
          if (file === oldFileLink) {
            result.nodeContent.files[index] = newFileLink;
            result.nodeContent.fileNames[index] = newFileName;
          }
        }

        db.collection(result.nodePath)
          .doc(result.nodeId)
          .set(
            {
              video:
                result.nodeContent.video === oldFileLink
                  ? newFileLink
                  : result.nodeContent.video,
              image:
                result.nodeContent.image === oldFileLink
                  ? newFileLink
                  : result.nodeContent.image,
              files: result.nodeContent.files,
              fileNames: result.nodeContent.fileNames,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              updatedBy: profile.email,
            },
            { merge: true }
          );
      });

      const replacedDocument = {
        [uuidv4()]: {
          file: documentToUpdate.file,
          updatedBy: profile.email,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          fileTitle: documentToUpdate.fileTitle,
          fileName: documentToUpdate.fileName,
        },
      };

      // Update firestore files collection
      const fireStoreDoc = db
        .collection(`${tenantId}/Files/files`)
        .doc(documentToUpdate.id);

      await fireStoreDoc.update({
        documentType: fileType,
        fileName: fileName,
        fileTitle: fileTitle,
        file: newFileLink,
        updatedBy: profile.email,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      await fireStoreDoc.set(
        { versionHistory: replacedDocument },
        { merge: true }
      );

      if (handleUpdateNodePreview) {
        handleUpdateNodePreview(oldFileLink, newFileLink, newFileName);
      }
    }

    toastr.success(
      "File Saved",

      results.length === 0 && !onSelect
        ? "Your file has been updated in the Document Library."
        : "Your file has been updated in the Document Library and replaced in the Pathway Nodes."
    );

    setLoading(false);
  };

  useEffect(() => {
    const getFileType = () => {
      const fileExtension = fileName.split(".").pop();
      switch (fileExtension) {
        case "mp4":
          setFileType("VIDEO");
          break;
        case "docx":
        case "doc":
        case "pdf":
        case "xlsx":
        case "xls":
        case "pptx":
        case "txt":
          setFileType("DOCUMENT");
          break;
        case "gif":
        case "jpg":
        case "jpeg":
        case "png":
          setFileType("IMAGE");
          break;
        default:
          setFileType("");
      }
    };
    getFileType();
  }, [fileName]);

  const onSaveUpload = async () => {
    setFormError("");

    let isFindAndReplace;

    fileName && documentToUpdate && fileTitle
      ? (isFindAndReplace = true)
      : (isFindAndReplace = false);

    for (const document of documents) {
      if (document.fileName === fileName) {
        setChangeFileName(true);
        setFormError(errorMessages.matchingName);
        return;
      }

      if (document.fileTitle === fileTitle) {
        setFormError(errorMessages.matchingTitle);
        return;
      }
    }

    if (fileSize > 50) {
      setFormError(errorMessages.fileSizeOverLimit);
      return;
    }

    if (fileType === "") {
      setFormError(errorMessages.incorrectFileExtGeneral);
      return;
    }

    if (filterBy === "IMAGE" && fileType !== "IMAGE") {
      setFormError(errorMessages.incorrectFileExtImages);
      return;
    }

    if (filterBy === "VIDEO" && fileType !== "VIDEO") {
      setFormError(errorMessages.incorrectFileExtVideos);
      return;
    }

    if (!isFindAndReplace) {
      runUploadFile();
    }

    if (isFindAndReplace && documentToUpdate) {
      setLoading(true);
      let oldFileLink = documentToUpdate.file;

      const results = await functions.nodeSearch({
        searchTerms: [
          {
            term: oldFileLink,
            type: "casesensitive",
          },
        ],
        pathways: [],
        columns: ["files", "image", "video"],
        tenantId: tenantId,
      });

      for (const result of results) {
        if (result.image === oldFileLink && fileType !== "IMAGE") {
          setFormError(errorMessages.incorrectReplacementImg);
          setLoading(false);
          return;
        }
      }

      for (const result of results) {
        if (result.video === oldFileLink && fileType !== "VIDEO") {
          setFormError(errorMessages.incorrectReplacementVideo);
          setLoading(false);
          return;
        }
      }

      await runReplaceFile(results, oldFileLink);
      onCancelUpload();
      setOpenEdit(false);
    }
  };

  const onCancelUpload = () => {
    setFormError("");
    setUploadFile("");
    setFileName("");
    setFileTitle("");
    setChangeFileName(false);
  };

  return {
    changeFileName,
    fileName,
    fileTitle,
    formError,
    loading,
    openEdit,
    setOpenEdit,
    onCancelUpload,
    setFileName,
    setFileTitle,
    setFileSize,
    setLoading,
    onSaveUpload,
    setUploadFile,
  };
};

export default useUploadFile;
