import { SortCriteria } from "features/documentLibrary/docLibTypes/DocumentLibraryTypes";
import { Icon, SemanticICONS } from "semantic-ui-react";

interface SortIconProps {
  id: SortCriteria;
  setSortCriteria: (value: SortCriteria) => void;
  setIsAscendingSort: (value: boolean) => void;
  sortCriteria: SortCriteria;
  isAscendingSort: boolean;
}

export const SortIcon = ({
  id,
  setSortCriteria,
  setIsAscendingSort,
  sortCriteria,
  isAscendingSort,
}: SortIconProps) => {
  const handleSort = () => {
    if (id === sortCriteria) {
      setIsAscendingSort(!isAscendingSort);
      return;
    }
    setSortCriteria(id);
    setIsAscendingSort(true);
  };

  const getSortIcon = (): SemanticICONS => {
    if (id === sortCriteria) {
      return isAscendingSort ? "sort up" : "sort down";
    }
    return "sort";
  };

  return (
    <Icon
      style={{ cursor: "pointer" }}
      id={id}
      name={getSortIcon()}
      onClick={() => handleSort()}
      color={id === sortCriteria ? "black" : "grey"}
    />
  );
};
