import useAutoscroll from "features/hooks/useAutoscroll";
import DocumentLibraryContainer from "features/documentLibrary/DocumentLibraryContainer";
import {
  FilterCriteria,
  viewType,
} from "features/documentLibrary/docLibTypes/DocumentLibraryTypes";
import { useState } from "react";
import { Modal } from "semantic-ui-react";
import { IDocument } from "../../../types/DocumentLibrary";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";

interface IModalProps {
  filterBy?: FilterCriteria;
  handleUpdateNodePreview: (
    oldFileLink: string,
    newFileLink: unknown,
    fileTitle: string
  ) => void;
  selectFunction: (value: IDocument) => void;
  triggerBtnText: string;
  view?: viewType;
}

const InsertDocumentLibraryModal = ({
  filterBy,
  handleUpdateNodePreview,
  selectFunction,
  triggerBtnText,
  view,
}: IModalProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const { setIsDragging } = useAutoscroll("modal");

  const onSelect = (document: IDocument) => {
    setOpen(false);
    selectFunction(document);
  };

  return (
    <Modal
      size="large"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Button
          color="primary"
          variant="contained"
          startIcon={<LibraryAddOutlinedIcon />}
        >
          {triggerBtnText}
        </Button>
      }
    >
      <Modal.Content scrolling id="modal">
        <IconButton
          aria-label="close"
          sx={{ position: "absolute", right: 4 }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <DocumentLibraryContainer
          onSelect={onSelect}
          handleUpdateNodePreview={handleUpdateNodePreview}
          view={view}
          filterBy={filterBy}
          setIsDragging={setIsDragging}
        />
      </Modal.Content>
    </Modal>
  );
};

export default InsertDocumentLibraryModal;
