import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IModule } from "../../../types/CrossCover";

export type ModuleContextState = {
  // modules: IModule[];
  activeModule?: IModule;
  tenantModules: IModule[];
};

const initialState: ModuleContextState = {
  //modules: [],
  tenantModules: [],
};

export const moduleSlice = createSlice({
  name: "modules",
  initialState: initialState,
  reducers: {
    // updateModules: (state, action: PayloadAction<IModule[]>) => {
    //   state.modules = action.payload;
    // },
    updateActiveModule: (state, action: PayloadAction<IModule>) => {
      state.activeModule = action.payload;
    },
    updateTenantModules: (state, action: PayloadAction<IModule[]>) => {
      state.tenantModules = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateActiveModule,
  // updateModules,
  updateTenantModules,
} = moduleSlice.actions;

export default moduleSlice.reducer;
