import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Radio,
  TextField,
} from "@mui/material";
import {
  getNumericValueFromInput,
  isFieldToggled,
} from "features/NodeEditorV2/NodeEditorUtils";
import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { IChoicesLogicField } from "../../../../../types/Nodes";
import { INodeContext } from "features/hooks/navigatorReducer";

const FieldComponentEdit = ({
  type,
  field,
  showFieldScores,
  multipleAnswers,
  indexField,
  deleteField,
  updateField,
  isFieldNamePresent,
  nodeContext,
}: {
  type: "checkbox" | "button" | "yesNo";
  field: IChoicesLogicField;
  showFieldScores: boolean;
  multipleAnswers: boolean;
  indexField: number;
  deleteField: (index: number) => void;
  updateField: (
    type: "score" | "field" | "valueText" | "groupName",
    indexField: number,
    value: string,
    indexValue: number
  ) => void;
  isFieldNamePresent: boolean;
  nodeContext: INodeContext | undefined;
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  useEffect(() => {
    if (!isInputFocused) {
      if (field.title.trim() === "") {
        setErrorMessage("Please provide a title for your choice");
        return;
      }
      if (isFieldNamePresent) {
        setErrorMessage(
          "Choices cannot have duplicate titles, please enter a new title"
        );
        return;
      }
      setErrorMessage(null);
    } else {
      setErrorMessage(null);
    }
  }, [isFieldNamePresent, field.title, isInputFocused]);

  const isChecked =
    isFieldToggled(
      "field",
      field.values[0].valueId,
      nodeContext ?? undefined
    ) ?? false;

  return (
    <Draggable
      draggableId={`choicesBlock.${field.fieldId}`}
      index={indexField}
      key={field.fieldId}
    >
      {(provided, snapshot) => (
        <React.Fragment>
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              width: "100%",
              display: "flex",
              height: "40px",
              alignItems: "center",
              my: 1,
              backgroundColor: "#FFF",
            }}
          >
            <span {...provided.dragHandleProps}>
              <DragIndicatorIcon />
            </span>
            {showFieldScores && type === "checkbox" && (
              <TextField
                type="text"
                id="outlined-basic"
                label="Score"
                variant="outlined"
                size="small"
                value={field.values[0].value}
                onChange={(e) =>
                  updateField(
                    "score",
                    indexField,
                    getNumericValueFromInput(e.target.value),
                    0
                  )
                }
                sx={{
                  width: "80px",
                }}
              />
            )}
            {type !== "yesNo" && (
              <>
                {multipleAnswers && (
                  <Checkbox
                    sx={
                      type === "button"
                        ? {
                            "& .MuiSvgIcon-root": {
                              height: "50px",
                              width: "auto",
                            },
                            p: 0,
                          }
                        : null
                    }
                    disabled
                    checked={isChecked}
                  />
                )}
                {!multipleAnswers && (
                  <Radio
                    sx={
                      type === "button"
                        ? {
                            "& .MuiSvgIcon-root": {
                              height: "50px",
                              width: "auto",
                            },
                            p: 0,
                          }
                        : null
                    }
                    disabled
                    checked={isChecked}
                  />
                )}
              </>
            )}
            <FormControl
              sx={{
                width:
                  type === "yesNo"
                    ? "50%"
                    : type === "checkbox"
                    ? "auto"
                    : "100%",
              }}
            >
              <OutlinedInput
                fullWidth
                placeholder={
                  type === ("button" || undefined)
                    ? "Enter Button Text"
                    : "Checkbox Label"
                }
                value={field.title}
                size="small"
                onChange={(e) =>
                  updateField("field", indexField, e.target.value, 0)
                }
                onBlur={() => setIsInputFocused(false)}
                onFocus={() => setIsInputFocused(true)}
                error={errorMessage ? true : false}
              />
            </FormControl>
            {showFieldScores && type === "button" && (
              <TextField
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                type="text"
                id="outlined-basic"
                label="Score"
                variant="outlined"
                size="small"
                value={field.values[0].value}
                onChange={(e) =>
                  updateField(
                    "score",
                    indexField,
                    getNumericValueFromInput(e.target.value),
                    0
                  )
                }
                sx={{
                  width: "80px",
                }}
              />
            )}
            {type === "yesNo" && (
              <Box sx={{ display: "flex", width: "50%" }}>
                {field.values.map((value, indexValue) => (
                  <Box
                    key={value.valueId}
                    sx={{
                      display: "flex",
                      mr: 1,
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <TextField
                      value={value.text}
                      size="small"
                      sx={{ mr: 1 }}
                      label="Button Text"
                      onChange={(e) =>
                        updateField(
                          "valueText",
                          indexField,
                          e.target.value,
                          indexValue
                        )
                      }
                    />
                    {showFieldScores && (
                      <TextField
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        type="text"
                        id="outlined-basic"
                        label="Score"
                        variant="outlined"
                        size="small"
                        value={value.value}
                        onChange={(e) =>
                          updateField(
                            "score",
                            indexField,
                            getNumericValueFromInput(e.target.value),
                            indexValue
                          )
                        }
                        sx={{
                          width: "90px",
                        }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
            <DeleteOutlineIcon
              sx={{ cursor: "pointer" }}
              color="error"
              onClick={() => deleteField(indexField)}
            />
          </Box>
          {errorMessage && (
            <FormHelperText sx={{ m: 0 }} error={true}>
              {errorMessage}
            </FormHelperText>
          )}
        </React.Fragment>
      )}
    </Draggable>
  );
};

export default FieldComponentEdit;
