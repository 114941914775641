import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import PathwayDisclaimer from "features/NodeViewer/PathwayDisclaimer";
import Typography from "@mui/material/Typography";

interface EULAModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const EULAModal = ({ open, setOpen }: EULAModalProps) => {
  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)} scroll="paper">
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" component={"h1"} sx={{ fontWeight: 900 }}>
            End User License Agreement
          </Typography>

          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <PathwayDisclaimer
            displayButtons={false}
            hideSegmentBoarder={true}
            hideTCAgreement={true}
          />
        </DialogContent>
        {/* <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Subscribe</Button>
          </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default EULAModal;
