import { IModule } from "./../../../../types/CrossCover";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export const onUploadFile = (file: any): Promise<string> => {
  return new Promise((resolve) => {
    const storage = firebase.storage();
    const fileExtension = file.name.split(".").pop();
    const randomId = uuidv4();
    const fileId = `${randomId}.${fileExtension}`;
    const docFolder = storage.ref();

    const fileRef = docFolder.child(`/OPGallery/gallery/${fileId}`);
    const uploadTask = fileRef.put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {},
      (error) => {},
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};

export const getModuleTitles = (modules: IModule[]) => {
  if (modules) {
    const modulesCopy = JSON.parse(JSON.stringify(modules)) as IModule[];

    const moduleOptions = modulesCopy.map((module) => {
      return {
        text: module.id,
        value: module.id,
      };
    });

    return moduleOptions;
  }
};

export const getActiveGroupsFromModules = (modules: IModule[]) => {
  if (modules) {
    const modulesCopy = JSON.parse(JSON.stringify(modules)) as IModule[];

    const groupOptions = modulesCopy
      .flatMap((module) => {
        return module.groups && Object.values(module.groups);
      })
      .filter((x) => x);

    return groupOptions;
  }
};

export const sortOptions = [
  {
    key: "imageName",
    text: "Title",
    value: "imageName",
  },
  {
    key: "updatedAt",
    text: "Upload Date",
    value: "updatedAt",
  },
];
