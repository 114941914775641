import {
  styled,
  List,
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@mui/material";

import useModules from "features/hooks/useModules";
import EditorConsole from "./EditorConsole";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const SideBar = () => {
  const { modules, setModule, activeModule } = useModules();
  const [isModuleBarOpen, setIsModuleBarOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    history.push("/launchpads");
  }, [activeModule?.id, history]);

  const Drawer = styled("div")(() => ({
    backgroundColor: "#fff",
    // gridArea: "sidebar",
    height: "calc(100vh - 100px)",
    boxShadow:
      "0px 5px 5px -3px rgba(1, 55, 61, 0.2), 0px 8px 10px 1px rgba(1, 55, 61, 0.14), 0px 3px 14px 2px rgba(1, 55, 61, 0.12)",
    zIndex: "4",
  }));

  return (
    <>
      <Drawer sx={{ display: "flex" }}>
        <List sx={{ mt: 0, pt: 0 }}>
          <ListItemButton
            onClick={() => setIsModuleBarOpen((prev) => !prev)}
            sx={{
              mt: 0,
              px: 3,
              py: "22px",
              justifyContent: isModuleBarOpen ? "initial" : "center",
            }}
          >
            <Typography sx={{ fontWeight: "500", mr: 1 }}>
              {!isModuleBarOpen ? "" : "Applications"}
            </Typography>
            {!isModuleBarOpen && (
              <ArrowForwardIosIcon
                sx={{ color: "rgba(0, 0, 0, 0.54)", width: 14, height: 21 }}
              />
            )}
            {isModuleBarOpen && (
              <ArrowBackIosIcon
                sx={{ color: "rgba(0, 0, 0, 0.54)", width: 14, height: 21 }}
              />
            )}
          </ListItemButton>

          {/* {isModuleBarOpen && (
            <div
              onClick={() => setIsModuleBarOpen((prev) => !prev)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "rgba(0, 0, 0, 0.54)",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "500" }}>Applications</Typography>
              <ArrowBackIosIcon
                sx={{ color: "rgba(0, 0, 0, 0.54)", width: 14, height: 14 }}
              />
            </div>
          )} */}

          {modules &&
            modules
              .concat()
              .sort((a, b) => a.order - b.order)
              .map((module, index) => (
                <Tooltip
                  key={module.id}
                  title={
                    module.isActive
                      ? `Switch to ${module.shortName} module`
                      : `The ${module.shortName} licence is currently not activated`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{
                      maxHeight: 48,
                      px: 3,

                      justifyContent: isModuleBarOpen ? "initial" : "center",
                      backgroundColor:
                        activeModule && activeModule.id === module.id
                          ? "#E6F8F7"
                          : "",

                      opacity: module.isActive ? 1 : 0.5,
                    }}
                    onClick={() => setModule(module.id || "")}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 32,
                      }}
                    >
                      <img
                        src={
                          isModuleBarOpen ? module.logoFull : module.logoIcon
                        }
                        alt={module.title}
                        height="32px"
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              ))}
        </List>
      </Drawer>

      <EditorConsole />
    </>
  );
};

export default SideBar;
